<template>
    <div>
        <v-card
            flat
            class="rounded-lg mb-2 px-0"
            :class="isDraft ? 'card border1' : 'card'"
        >
            <v-card-title
                class="py-2 pointer"
                @click="() => { expanded = !expanded }"
            >

                <v-container class="px-0">
                    <v-row
                        align="center"
                        justify="start"
                        class="flex-nowrap"
                    >
                        <v-avatar
                            size="42"
                            class="tileBackgroundColor darken-1 "
                        >
                            <v-img
                                v-if="isInternal"
                                :src="getRealmIcon"
                                :lazy-src="getRealmIcon"
                                max-height="100%"
                                max-width="100%"
                                cover
                                class="rounded-xl elevation-2"
                            />
                            <div
                                v-else
                                class="text-uppercase "
                            >
                                {{
                initials
            }}
                            </div>

                        </v-avatar>
                        <v-container class="ma-0 pl-2">
                            <v-row
                                no-gutters
                                align="center"
                            >
                                <!-- <p
                            v-if="getAccount"
                            class="ma-0 text-body-2 font-weight-mediumBold"
                        >
                            {{ getAccount.FullName }}
                        </p> -->

                                <p
                                    class="ma-0 text-body-2 font-weight-mediumBold text-truncate"
                                    style="max-width: 70%;"
                                >
                                    {{ isEmpty(object.FromName) ? object.FromEmail : object.FromName }}
                                </p>
                                <v-spacer />
                                <p class="ma-0 text-body-2 text-caption gray--text">
                                    {{ formatDate(this.object.Date) }}
                                </p>


                            </v-row>
                            <p
                                v-if="!expanded"
                                class="ma-0 text-body-2 gray--text"
                            >
                                {{ object.Snippet }}
                            </p>
                            <p
                                v-else
                                class="ma-0 text-body-2 gray--text"
                            >
                                To: {{ object.ToEmail }}
                            </p>
                        </v-container>



                    </v-row>
                </v-container>
            </v-card-title>
            <v-card-text
                v-if="expanded"
                class="px-0"
            >
                <div class="white rounded-lg pa-4">
                    <v-skeleton-loader
                        v-if="loading"
                        light
                        type="article"
                    ></v-skeleton-loader>
                    <div
                        v-if="mailBody && mailBody !== false"
                        v-html="mailBody"
                        ref="htmlbody"
                        style="border: none"
                        class="mailbody kingSize"
                        crossorigin="anonymous"
                    ></div>
                    <textarea
                        v-if="mailBody === false"
                        v-model="getBodyText"
                        ref="htmlText"
                        disabled
                        style="border: none;resize: none;"
                        class="mailbody kingSize"
                        v-on:input="updateTextHeight"
                    />
                </div>
                <v-container
                    class="px-0 pt-4 pb-2"
                    v-if="!isEmptyArray(attachments)"
                >
                    <div class="text-caption text-left px-2"><v-icon small>hi-paper-clip-outline</v-icon> Attachments
                    </div>
                    <v-row
                        no-gutters
                        class="pt-4 px-0"
                    >

                        <inbox-attachment
                            v-for="item in attachments"
                            class="mx-1"
                            :key="item.name"
                            :object="item"
                            style="max-width:54px; min-width:54px;"
                        />
                    </v-row>
                </v-container>


            </v-card-text>
            <!-- <v-card-actions
                v-if="showReply"
                class="pt-0 pb-3 px-0"
            >

                <v-spacer /> -->
            <!-- <v-btn
                icon
                class="card"
            >
                <v-icon class="configLight">hi-clipboard-document-check-outline</v-icon>
            </v-btn>
            <v-btn
                icon
                class="card"
            >
                <v-icon class="configLight">hi-calendar-days-outline</v-icon>
            </v-btn> -->
            <!-- </v-card-actions> -->
        </v-card>
        <div>
            <v-divider></v-divider>
        </div>
        <v-row
            v-if="showReply && !replyActive"
            no-gutters
            align="center"
            justify="center"
            class="pt-4"
        >
            <v-btn
                :disabled="!repliable"
                :color="repliable ? 'primary' : 'iconButton'"
                class="px-6 lighten-5 primary--text"
                elevation="0"
                block
                @click="reply"
            >
                Reply
            </v-btn>
        </v-row>

    </div>
</template>

<script>
import moment from 'moment';
import { b64decode, convertClassesToInline, extractEmail, isEmpty, isEmptyArray, isTrue, urlsafe_b64decode } from '../api/utilities';
import { ACCOUNT_MAIN, ALERT_TYPE_ERROR, ATTACHMENT, INBOX_ITEM } from '../store/constants';
import { mapGetters } from 'vuex';
import { DRAFT } from './components/MailContainer.vue';
import Attachment from './components/Attachment.vue';
import Axios from 'axios';
import { getAccessToken } from '../store/authorizationContext';
import { API_BASE_URL, BASE_URL } from '../api/constants';
import InboxAttachment from './components/InboxAttachment.vue';


export default {
    components: { Attachment, InboxAttachment },
    props: {
        viewconfig: {
            type: String,
            default: ""
        },
        height: {
            type: String,
            default: null
        },
        object: {
            type: Object,
            default: null
        },
        parent: {
            type: Object,
            default: null
        },
        showReply: {
            type: Boolean,
            default: false
        },
        replyActive: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            myListWidth: 0,
            expanded: true,
            attachments: null,
            mailBody: '',
            loading: false,
            repliable: false
        };
    },
    computed: {
        ...mapGetters(['getActiveInboxEmails', 'getInboxEmails', 'getRealmIcon']),
        initials() {
            return this.getInitialsFromEmail(this.object.FromEmail)

        },
        getAttachments() {
            if (this.object && this.object.Source === 'Swiftio') {
                return this.getSwiftioAttachments
            } else return this.attachments
        },
        getViewConfig() {
            return this.$store.getters.getViewConfigForID(this.viewconfig);
        },
        getObjectType() {
            return this.getViewConfig.objectType;
        },
        getAccount() {
            if (this.object && this.object.Account) {
                var accountId = this.object.Account
                var filter = (account) => account.Guid === accountId
                var accounts = this.$store.getters.getObjectsForFilter(ACCOUNT_MAIN, filter)
                return isEmptyArray(accounts) ? null : accounts[0]
            } return null
        },

        getBodyText() {
            // if (this.object.Source === 'Swiftio') {
            //     this.updateTextHeight()
            //     return this.object.BodyText
            // }
            if (this.object && !isEmpty(this.object.BodyText)) {
                var data = this.object.BodyText

                const html = this.decodeMail(data)
                return html
            }
            return null
        },
        isInternal() {
            const email = extractEmail(this.object.FromEmail)
            return this.getInboxEmails && this.getInboxEmails.includes(email)
        },
        getEmailFromInbox() {
            var email = extractEmail(this.object.FromEmail)
            var inboxEmails = this.getInboxEmails
            if (inboxEmails && inboxEmails.includes(email)) {
                return email
            }
            email = extractEmail(this.object.ToEmail)
            if (inboxEmails && inboxEmails.includes(email)) {
                return email
            }
            email = extractEmail(this.object.Cc)
            if (inboxEmails && inboxEmails.includes(email)) {
                return email
            }
            return null
        },
        isDraft() {
            return this.object.Status === DRAFT
        }
    },
    mounted() {
        this.expanded = this.showReply
        if (this.expanded) {
            this.loadEmail()
            this.updateTextHeight()
        }

    },
    watch: {
        expanded(value) {
            if (this.expanded) {
                this.loadEmail()
                this.updateTextHeight()
            }
        },
        // getBodyText(value) {
        //     this.updateTextHeight()
        // }
    },
    methods: {
        isEmpty,
        isEmptyArray,
        loadEmail() {
            this.getEmailBody()

        },
        getBodyHtml(body) {
            if (body) {
                let inline = convertClassesToInline(body)
                return inline.replace(/<img /g, '<img crossorigin="anonymous"')

            }
            return null
        },
        async getEmailBody() {
            if (this.object.Source === 'Swiftio') {
                this.mailBody = this.getBodyHtml(this.object.BodyHtml) || false
                this.getSwiftioAttachments()
                return
            }
            if (!isEmpty(this.object.BodyHtml)) {
                this.mailBody = this.getBodyHtml(this.object.BodyHtml)
                return
            }
            if (!isEmpty(this.mailBody)) {
                return
            }
            this.loading = true
            var config = {
                headers: {}
            };
            var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

            if (isEmpty(bearer)) {
                var error = "problem with bearer During Operation.";
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
                return;
            }
            config.headers.Authorization = "Bearer ".concat(bearer);
            const id = encodeURIComponent(this.object.MessageId)
            const inboxId = this.object.Inbox
            const source = this.object.Source
            const url = `${API_BASE_URL}/mailBody?id=${id}&inboxId=${inboxId}&source=${source}`
            Axios.get(url, config).then(response => {
                this.loading = false

                if (response && response.data) {
                    var { body, attachments, text } = response.data
                    this.attachments = attachments.map(item => {
                        item.decoded = this.decodeAttachment(item)
                        return item
                    })
                    if (!isEmpty(body)) {
                        this.mailBody = this.getBodyHtml(body)
                    } else {
                        this.mailBody = text
                    }


                    this.updateTextHeight()
                    if (isTrue(this.object.Unread)) {
                        this.markAsRead()
                    }
                }

            }, error => {
                this.loading = false
                // this.$store.commit("showAlert", {
                //     type: ALERT_TYPE_ERROR,
                //     message: error
                // });
            })
        },
        async getSwiftioAttachments() {
            const payload = {
                objectType: ATTACHMENT,
                filterGuid: '81899f2d-d946-6150-c413-1f1319c7517f',
                relatedGuid: this.object && this.object.Guid,
                relatedType: INBOX_ITEM
            }
            const objects = await this.$store.dispatch('fetchObjectsForFilter', payload)
            if (!isEmptyArray(objects)) {
                this.attachments = objects

            }
        },

        decodeAttachment(item) {
            if (item && item.file) {
                const encoded = item.file
                const decodedData = b64decode(encoded);
                const type = item.mimeType
                const blob = new Blob([decodedData], { type });
                return URL.createObjectURL(blob);
            }
            return item

        },
        decodeMail(encoded) {
            return urlsafe_b64decode(encoded)
        },
        updateTextHeight() {
            this.repliable = true
            setTimeout(() => {
                if (this.$refs.htmlText) {
                    this.$refs.htmlText.style.height =
                        this.$refs.htmlText.scrollHeight + 'px'
                }
            }, 10)
        },
        markAsRead() {
            const oldObject = structuredClone(this.object)
            this.object.Unread = false
            var payload = {
                objectType: INBOX_ITEM,
                object: this.object,
                oldObject
            };

            this.$store.dispatch("saveObjectForObjectType", payload);
        },
        getInitialsFromString(string) {
            if (isEmpty(string)) {
                return ''
            }
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
            let initials = [...string.matchAll(rgx)] || [];
            initials = (
                (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();
            return initials
        },
        getInitialsFromEmail(email) {
            if (isEmpty(email)) {
                return ''
            }
            let rgx = new RegExp(/[a-zA-Z]/, 'gu');
            let initials = email.match(rgx)
            return initials[0]
        },
        formatDate(date) {
            const mailDate = moment(date)
            if (mailDate.isSame(new Date(), "day")) {
                return mailDate.format('HH:mm')
            } else {
                return mailDate.format('DD MMM YYYY HH:mm')
            }
        },
        formatEmail(mail) {
            if (mail) {
                const re =
                    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
                return re.exec(mail)[0];
            }
            return null;
        },
        reply() {
            let newObject = structuredClone(this.object)
            newObject.BodyHtml = structuredClone(this.mailBody)
            this.$emit('reply', newObject)
        }
    }
};
</script>

<style>
.mailbody {
    color: black;
    overflow: auto;
    max-height: 80vh;

}

.mailbody table {
    width: 100% !important;
    min-width: none !important;
}

/* .mailbody img {
    max-width: 100% !important;
} */
</style>