<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-dialog
      v-model="dialog.visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="dialog.visible">
          <v-btn
            color="primary"
            icon
            @click="cancelSelection()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
          <viewConfigView
            :object="selectedField"
            :editmode="false"
            :dialog="dialog"
            :key="componentKey"
            :viewconfig="viewConfigOfSelection"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <v-card
      flat
      class="pa-0 ma-0 transparent"
    >

      <v-container
        ref="myListOuter1"
        class="px-0 py-3 ma-0 removeScroll xs12 "
        fluid
      >
        <v-row
          no-gutters
          class="justify-space-around"
        >

          <v-card
            v-for="(object, index) in getFilteredObjects"
            :key="index"
            flat
            class="transparent rounded-lg  mx-n2 kingSize"
          >
            <Details
              v-if="!isListEditMode(object)"
              :viewconfig="viewconfig"
              :object="object"
              :operationid="null"
              :height="height"
              :fieldsize="getFieldSize"
              :editmode="false"
              :dense="false"
            />
            <Details
              v-else
              :viewconfig="viewconfig"
              :object="object"
              :operationid="getOperationIdForItem(object)"
              :height="height"
              :fieldsize="getFieldSize"
              :editmode="true"
              :dense="false"
            />
            <v-divider
              class="line my-2"
              v-if="index < getFilteredObjects.length - 1"
            ></v-divider>

          </v-card>
        </v-row>
      </v-container>
      <slot name="footer" />
    </v-card>
    <massUpdatingView
      v-if="!ismass"
      :viewconfig="viewconfig"
    />
  </v-container>
</template>

<script>
import * as UTIL from "../../api/utilities.js";
import filterView from "./FilterView";
import listViewAction from "./ListViewActions";
import * as theme from "../../theme/themeDefault.js";
import massUpdatingView from "./MassUpdatingViews/MassUpdatingView"
import GenericInputSimple from "../components/GenericInputSimple.vue";
import Details from "../splitCard/Details.vue"


export default {
  components: { listViewAction, filterView, massUpdatingView, GenericInputSimple, Details },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    ismass: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: String,
      default: null
    },
    selectable: {
      type: Boolean,
      default: true
    },
    onCurrentItems: {
      type: Function,
      default: () => (null)
    },

  },
  data() {
    return {
      fields: undefined,
      hover: false,
      myListWidth: 0,
      showFilter: true,
      counter: 0,
      componentKey: "myListViewComponentKey",
      viewConfigOfSelection: null,
      selectedField: null,
      fieldsize: "4",
      keyObject: {
        key: 1
      },
      dialog: {
        visible: false
      },
      currentItems: []
    };
  },
  computed: {
    //do no know what this does.
    selected: {
      get() {
        return this.$store.getters.getSelectedItemsForViewConfig(this.viewconfig)
      }, set(items) {
        this.$store.commit('setSelectedItemsForViewConfig', { viewconfig: this.viewconfig, items })
      }
    },
    allSelected() {
      return this.selected.length === this.getFilteredObjects.length
    },
    getHeight() {
      return "300px";
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject,
        isEdit: this.isEditMode
      };

      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getClassOfHeader() {
      if (this.isConfigMode) {
        return "normalCell";
      }
      return "lowCell";
    },
    hidePagination() {
      return this.disablePagination === true || this.isEditMode === true
    },
    disablePagination() {
      if (this.noPagination === true) return true;
      if (!(this.getFilteredObjects && this.getFilteredObjects.length > this.getNumberOfRows)) return true
      return false
    },
    getFieldSize() {
      var customSize = this.$store.getters.getCustomSize(this.viewconfig);
      if (!UTIL.isEmpty(customSize)) {
        if (customSize.size > 500) {
          return "5"
        }
      }
      return "4";
    },
    getFieldListType() {
      if (this.isEditMode) {
        return "listEdit";
      }
      return "listDisplay";
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: this.fieldsize,
          list: this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getAllFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: 12,
          list: this.getFieldListType
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.filter(element => element.type !== "Title");
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "left"; //right
        }
        field.sortable = true;
      }
      return fields;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getRowHeight() {
      return 48;
    },
    getNumberOfRows() {
      if (this.groupBy) return 20
      var height = UTIL.pixelStringToInt(this.height);
      if (height > 200) {
        var numberOfRows = (height - 140) / this.getRowHeight;
        return Math.floor(numberOfRows);
      }
      return 10;
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    getMinWidth(field) {
      if (field.type ===
        "actions" || field.type ===
        "progressBar" || field.type ===
        "currency") {
        return 0
      }
      if (field.width === 12) {
        return '250px'
      }
      if (field.width === 11) {
        return '220px'
      }
      if (field.width < 11 && field.width >= 6) {
        return '100px'
      }
      return 0
    },
    onDoubleClicked() {
      this.$emit('onDoubleClick')
    },
    isListEditMode(item) {
      const operationId = this.getOperationIdForItem(item)
      return this.isEditMode && !UTIL.isEmpty(this.$store.getters.getOperationContainerForId(operationId))
    },
    isEmptyArray(value) {
      return UTIL.isEmptyArray(value)
    },
    getFieldType(field) {
      return field
    },
    deselect() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.getFilteredObjects
      }
    },
    massUpdate() {
      var payload = {
        massUpdateViewConfig: this.viewconfig,
        massUpdate: true
      };
      this.$store.dispatch(
        "goToMassUpdate",
        payload
      );
    },
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    setFields(fields) {
      this.fields = fields;
    },
    getkey(item) {
      var objectConfig = this.getObjectConfig;
      var keys = this.getObjectConfig.getIds();

      var itemKey = "";
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        itemKey = itemKey.concat(item[key]);
      }
      return itemKey;
    },
    getCellColor(object) {
      var currentObject = this.$store.getters.getCurrentObject;
      if (object === currentObject) {
        return theme.getTheme()[theme.ROW_HIGHLIGHTED];
      }
      return theme.getTheme()[theme.ROW_BACKGROUND];
    },

    getOperationIdForItem(item) {
      var payload = {
        object: item,
        viewconfig: this.viewconfig
      };
      const opId = this.$store.getters.getOperationIdForObjectAndConfig(payload);
      return opId
    },
    getItem(item) {
      var operationId = this.getOperationIdForItem(item);
      var operationContainer = this.$store.getters.getOperationContainerForId(
        operationId
      );
      if (!UTIL.isEmpty(operationContainer)) {
        return operationContainer.newObject;
      }
      return item;
    },
    getHeader(value) {
      return this.getAllFields.find(item => item.value === value);
    },

    getAlign(align) {
      if (align === null || align === "" || align === undefined) {
        return "right";
      } else {
        return align;
      }
    },
    openDialog(field) {
      this.selectedField = field;
      this.viewConfigOfSelection = "ViewFieldviewConfig2";
      this.dialog.visible = true;
    },
    getColorForfield(field, type) {
      if (UTIL.isTrue(field[type])) {
        return "primary";
      }
      return "gray";
    },
    objectSelected(selectedObject) {
      //this.object[this.selectedField.value] = selectedObject.objectType;
      this.closeDialog();
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.dialog.visible = false;
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}

.lowCell {
  height: 5px !important;
}

.normalCell {
  height: 30px !important;
}
</style>
