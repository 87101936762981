<template>
  <v-card class="ma-0 pa-0 flat">
    <v-container
      v-if="displayComponent('ProductCatalog')"
      class="ma-0 pa-0"
      fluid
    >
      <productCatalog :viewconfig="productCatalog" />
    </v-container>
    <v-container
      v-if="displayComponent('TilesView')"
      class="ma-0 pa-0 no-scroll"
      fluid
    >
      <tilesView
        :viewconfig="viewconfig"
        class="ma-0 pa-0"
      />
    </v-container>

    <v-container
      v-if="displayComponent('visitReportDemo')"
      class="ma-0 pa-0 no-scroll"
      fluid
    >
      <visitReportDemo
        :viewconfig="viewconfig"
        class="ma-0 pa-0"
      />
    </v-container>


    <!-- custom views -->

    <QuotationCalculationTile
      v-if="displayComponent('quotationSolar')"
      :viewconfig="viewconfig"
      :object="getObject"
      :height="getHeight"
    />


  </v-card>
</template>

<script>
import * as Constants from "../../store/constants.js";
import productCatalog from "../catalog/ProductCatalog";
import tilesView from "../catalog/TilesView";
import visitReportDemo from "../../demo/Components/VisitProductSelectionDemo";
import QuotationCalculationTile from "../components/custom/QuotationCalculationTile.vue";
export default {
  components: {
    productCatalog,
    tilesView,
    visitReportDemo,
    QuotationCalculationTile
  },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  computed: {
    getHeight() {
      return this.height;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode;
    },
    getConfigType() {
      return Constants.VIEW_CONFIG;
    },
    getOuterClass() {
      if (this.isConfigMode === true) {
        return "ma-0 pa-3 no-scroll elevation-10";
      }
      return "ma-0 pa-3 no-scroll";
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getSubViewConfig() {
      return this.getViewConfig.subViewConfig;
    },
    getType() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.type;
    },
    getObject() {
      var logViewConfig = this.getViewConfig;
      if (this.operationId !== null) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.getOperationId()
        );
        if (operationContainer !== null && operationContainer !== undefined)
          return operationContainer.newObject;
      }
      //hier stond this.currentObject voor de 3 objecten
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
  },
  mounted: function () { },
  methods: {
    displayComponent(type) {
      return this.getType === type;
    }
  }
};
</script>
