/**
 * Inbox context
 */

import { isTrue } from "../api/utilities";
import { INBOX_SETTING } from "./constants";

const initState = {
  addInboxDialog: false,
  composeMailDialog: false,
  composeMailRelated: null,
  composeMailViewconfig: null,
};

export default {
  state: initState,
  getters: {
    getInboxSettings: (state, getters) => {
      return getters.getDataObjectsForObjectType(INBOX_SETTING);
    },
    getActiveInboxSettings: (state, getters) => {
      return getters.getInboxSettings.filter((item) => isTrue(item.Active));
    },
    getReplyInbox: (state, getters) => (inboxGuid) => {
      const myInboxes = getters.getMyInboxes;
      if (inboxGuid) {
        const inbox = myInboxes.find((item) => item.Guid === inboxGuid);
        if (inbox) {
          return inbox;
        }
      }
      if (myInboxes && myInboxes.length > 0) {
        return myInboxes[0];
      }
      return null;
    },
    getMyInboxes: (state, getters) => {
      const myGuid = getters.getCurrentEmployeeGuid;
      return getters.getInboxSettings.filter(
        (item) =>
          isTrue(item.Active) && (item.Employee === myGuid || item.Employee),
      );
    },
    getInboxEmails: (state, getters) => {
      return getters.getInboxSettings.map((item) => item.Email);
    },
    getActiveInboxEmails: (state, getters) => {
      return getters.getActiveInboxSettings.map((item) => item.Email);
    },

    getaddInboxDialog: (state) => {
      return state.addInboxDialog;
    },
    getComposeMailDialog: (state) => {
      return state.composeMailDialog;
    },
    getComposeMailRelated: (state) => {
      return state.composeMailRelated;
    },
    getComposeMailViewconfig: (state) => {
      return state.composeMailViewconfig;
    },
  },
  mutations: {
    showAddInboxDialog(state, payload) {
      state.addInboxDialog = true;
    },
    hidAddInboxDialog(state, payload) {
      state.addInboxDialog = false;
    },
    showComposeMailDialog(state, payload) {
      state.composeMailRelated = payload.object;
      state.composeMailViewconfig = payload.viewconfig;
      state.composeMailDialog = true;
    },
    hideComposeMailDialog(state, payload) {
      state.composeMailRelated = null;
      state.composeMailViewconfig = null;
      state.composeMailDialog = false;
    },
  },
};
