import { render, staticRenderFns } from "./LogComponent.vue?vue&type=template&id=bdc2e1b4"
import script from "./LogComponent.vue?vue&type=script&lang=js"
export * from "./LogComponent.vue?vue&type=script&lang=js"
import style0 from "./LogComponent.vue?vue&type=style&index=0&id=bdc2e1b4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports