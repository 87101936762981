<template>
  <v-card flat>
    <v-card
      flat
      class="ma-3"
    >
      <h5 text-xs-center>Next Navigations</h5>
    </v-card>
    <v-list>
      <v-list-item
        v-for="(item, index) in getActions"
        :key="index"
        @click="clickMenu(item)"
      >
        <v-list-item-action>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog
      :max-width="dialogWidth"
      v-model="dialog.show"
      content-class="rounded-lg"
      persistent
    >
      <v-card
        :max-width="dialogWidth"
        class="pl-2 pr-5 no-scroll rounded-lg"
      >
        <v-card
          class="ma-4 pa-4"
          flat
        >
          <h2>{{ dialogTitle }}</h2>
          <viewConfigView
            :viewconfig="viewConfigOfSelection"
            :object="selectedObject"
            :operationid="operationId"
            :editmode="true"
            :dialog="dialog"
            :key="componentKey"
          />
        </v-card>
        <!--<v-card-actions>
          <v-spacer/>
          <v-btn 
            color="primary" 
            @click="saveObject()">save</v-btn>
          <v-btn 
            color="primary" 
            @click="cancelSelection()">Cancel</v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import * as Constants from "../../../../store/constants.js";
import * as Utilities from "../../../../api/utilities.js";

export default {
  props: {
    viewconfig: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogTitle: "Title",
      dialog: {
        show: null
      },
      dialogWidth: 800,
      hasSelectionView: false,
      selectedObject: null,
      viewConfigOfSelection: "",
      operationId: null,
      componentKey: 0
    };
  },
  computed: {
    getActions() {
      var actions = [];

      var action = {
        title: "Overview Page: Engie",
        icon: "mdi-calendar-check",
        viewConfig: "viewConfig",
        filter: "",
        method: "addObject"
      };
      actions.push(action);
      var action = {
        title: "Recent Team's appointments",
        icon: "mdi-calendar-multiple-check",
        viewConfig: "viewConfig",
        filter: "",
        method: "toggleFieldList"
      };
      actions.push(action);
      var action = {
        title: "Overview: Lease iphone 12 2021",
        icon: "mdi-file-clock-outline",
        filter: "",
        method: "upload"
      };
      actions.push(action);
      return actions;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    }
  },
  methods: {
    upload() {
      this.viewConfigOfSelection = "ExpenseMainCVSUploader";
      this.dialogWidth = 1800;
      this.dialogTitle = "Upload ".concat(this.getViewConfig.objectType);
      this.hasSelectionView = true;
      this.dialog.show = true;
      this.componentKey += 1;
    },
    clickMenu(item) {
      this[item.method]();
    },
    /**
     * Expand the list
     */
    toggleFieldList() {
      this.$store.commit("toggleCustomSize", this.viewconfig);
    },

    /**
     * Add the option.
     */
    addObject() {
      var config = this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
      // Get the viewConfig
      this.viewConfigOfSelection = this.getViewConfig.objectType.concat(
        "ViewDetailsconfig"
      );

      // Create the operation object
      this.operationId = Utilities.getGuid();
      var payload = {
        object: null,
        config: config,
        id: this.operationId
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);

      // var retrieve the object from the operation and do the modifications.
      var newObject = this.$store.getters.getOperationContainerForId(
        this.operationId
      ).newObject;
      var relatedObject = {
        object: this.object,
        viewConfig: this.getViewConfig
      },
        newObject = config.afterCreate(newObject, relatedObject, this.$store);
      this.dialogWidth = 800;
      this.dialogTitle = "New ".concat(this.getViewConfig.objectType);
      this.hasSelectionView = true;
      this.dialog.show = true;
      this.componentKey += 1;
    },

    closeDialog() {
      this.dialog.show = false;
    },
    launchAction(action) {
      if (action.method !== null && action.method !== undefined) {
        this.openView(action);
      }
    },
    openView(action) {
      if (action.title === "Add") {
        this.addObject(action);
      }
    }
  }
};
</script>
