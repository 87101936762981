//import EMPTY_VALUE from "../store/constants"

import Vue from "vue";
import {
  isEmpty,
  isEmptyArray,
  isEmptyObj,
  isNumeric,
  isTrue,
} from "./utilities";

export const applyFilterOld = (objects, filters) => {
  if (isEmpty(filters) || isEmpty(objects)) {
    return objects;
  }
  return objects.filter((item) => checkItem(item, filters));
};

const checkItem = (item, filter) => {
  const filters = isEmpty(filter.lcomparator) ? [filter] : filter.value;

  for (let index = 0, l = filters.length; index < l; index++) {
    const filter = filters[index];
    const comperator = filter.comparator;
    // fix boolean formatting
    if (
      filter.value &&
      (String(filter.value).toLowerCase() === "true" ||
        String(filter.value).toLowerCase() === "false")
    ) {
      filter.value = String(filter.value).toLowerCase() === "true";
    }

    switch (comperator) {
      case "==":
        if (item[filter.field] != filter.value) {
          return false;
        }
        break;
      case "!=":
        if (item[filter.field] == filter.value) {
          return false;
        }
        break;
      case "<":
        if (item[filter.field] >= filter.value) {
          return false;
        }
        break;
      case ">":
        if (item[filter.field] <= filter.value) {
          return false;
        }
        break;
      case "<=":
        if (item[filter.field] > filter.value) {
          return false;
        }
        break;
      case ">=":
        if (item[filter.field] < filter.value) {
          return false;
        }
        break;
      case "empty":
        if (!isEmpty(item[filter.field])) {
          return false;
        }
        break;
      case "notEmpty":
        if (isEmpty(item[filter.field])) {
          return false;
        }
        break;
      case "in":
        if (item[filter.field].indexOf(filter.value) < 0) {
          return false;
        }
        break;
      case "nin":
        if (item[filter.field].indexOf(filter.value) > -1) {
          return false;
        }
        break;
    }
  }
  return true;
};

function copyObject(mainObj) {
  if (mainObj) {
    return JSON.parse(JSON.stringify(mainObj));
  }
  return mainObj;
  let objCopy = {}; // objCopy will store a copy of the mainObj
  let key;
  for (key in mainObj) {
    objCopy[key] = mainObj[key]; // copies each property to the objCopy object
  }
  return objCopy;
}

function isSingle(filter) {
  return (
    filter && "comparator" in filter && "field" in filter && "value" in filter
  );
}

function isComposite(filter) {
  return filter && "lcomparator" in filter;
}

//https://stackoverflow.com/questions/31170630/js-array-filter-with-dynamic-filter-criterion

function createBody(filter) {
  if (isComposite(filter)) {
    var bdy = "";
    if (filter.value.length > 1) {
      var comparator = filter.lcomparator;
      return (
        "(" +
        createBody(filter.value.shift()) +
        " " +
        comparator +
        " " +
        createBody({ lcomparator: filter.lcomparator, value: filter.value }) +
        ")"
      );
    } else if (filter.value.length == 1) {
      return createBody(filter.value.shift());
    }
    return bdy;
  } else if (isSingle(filter)) {
    var comparator = filter.comparator;
    if (typeof filter.value == "string")
      filter.value = "'" + filter.value + "'";
    return "item." + filter.field + " " + comparator + "  " + filter.value;
  }
}

var createFunc = function (filter) {
  var copyFilter = copyObject(filter);
  var body = createBody(copyFilter);
  var f = new Function("item", " return " + body + ";");
  return f;
};

// /**
//  * Apply the filter
//  * @param {*} input
//  * @param {*} filter
//  */
// export const applyFilter = function(input, filter) {
//   if (filter === undefined) {
//     return input;
//   }
//   var fun = createFunc(filter);
//   var output = input.filter(fun);
//   return output;
// };

/**
 * Apply the sorting
 * @param {*} input
 * @param {*} filter
 */
export const applySorting = function (list, sort) {
  if (list && sort && sort.field) {
    const field = sort.field;
    if (!isEmpty(sort.sortingFunction)) {
      return list.sort(func);
    }
    return list.sort(sortFunctionMaker(!isTrue(sort.isDown), sort.field));
  }
};

function sortFunctionMaker(ascending, field) {
  function isNumber(str) {
    return Number.isNaN(Number(str)) === false;
  }

  function isEnglish(str) {
    return /^[a-zA-Z]+$/.test(str);
  }

  return function (a, b) {
    var aw, bw;

    if (a[field] === b[field]) {
      return 0;
    }
    if (isEmpty(a[field])) {
      // Change this values if you want to put `null` values at the end of the array
      return 1;
    }

    if (isEmpty(b[field])) {
      // Change this values if you want to put `null` values at the end of the array
      return -1;
    }
    if (isNumber(a[field])) {
      aw = 1;
    } else if (isEnglish(a[field])) {
      aw = 2;
    } else {
      aw = 3;
    }
    if (isNumber(b[field])) {
      bw = 1;
    } else if (isEnglish(b[field])) {
      bw = 2;
    } else {
      bw = 3;
    }

    if (aw !== bw) {
      // a and b belong to different categories
      // no further comparison is needed
      return aw - bw;
    } else if (aw === 1) {
      // both are numbers
      // sort mathematically
      return (ascending ? 1 : -1) * (a[field] - b[field]);
    } else {
      // both are english or otherwise
      // sort using localeCompare
      return (ascending ? 1 : -1) * a[field].localeCompare(b[field]);
    }
  };
}

/**
 * Fill in the value of the object.
 * @param {*} object
 * @param {*} filterFields
 */
export const convertFilters = function (object, filterFields) {
  if (isEmptyArray(filterFields)) {
    return null;
  }
  var convertedFilterFields = {
    $and: [],
  };

  filterFields.forEach((filterField) => {
    convertedFilterFields.$and.push({
      [filterField.field]: {
        [unMappedComparator[filterField.comparator] || "$eq"]: isEmpty(
          filterField.linkedObjectField,
        )
          ? filterField.value
          : `$related.${filterField.linkedObjectField}`,
      },
    });
    // if (
    //   filterField.linkedObjectField !== undefined &&
    //   filterField.linkedObjectField !== null &&
    //   filterField.linkedObjectField !== "" &&
    //   object !== undefined
    // ) {
    //   if (object[filterField.linkedObjectField]) {
    //     filterField.value = object[filterField.linkedObjectField];
    //   } else {
    //     filterField.value = "_EMPTY_";
    //   }
    // }
    // if (
    //   filterField.field !== null &&
    //   filterField.field !== undefined &&
    //   filterField.field.length > 0 && //(Field not empty)  and (Value not empty or filterFunction not empty)
    //   filterField.value !== null &&
    //   filterField.value !== undefined &&
    //   (filterField.value !== "" ||
    //     filterField.comparator === "empty" ||
    //     filterField.comparator === "notEmpty")
    // ) {
    //   var convertedFilterField = copyObject(filterField);
    //   convertedFilterFields.push(convertedFilterField);
    // }
  });

  return convertedFilterFields;
  if (convertedFilterFields.length < 2) {
    return convertedFilterFields[0];
  }

  return {
    lcomparator: "&&",
    value: convertedFilterFields,
  };

  /*
  filterFields.forEach(filter => {
    filter.value = object[filter.linkedObjectField];
  });
  */
};

const mappedComparator = {
  $and: "&&",
  $or: "||",
  $eq: "==",
  $ne: "!=",
  $gte: ">=",
  $lte: "<=",
  $gt: ">",
  $lt: "<",
  $em: "empty",
  $nem: "notEmpty",
  $nin: "nin",
  $in: "in",
  $all: "all",
};

const unMappedComparator = {
  "&&": "$and",
  "||": "$or",
  "==": "$eq",
  "!=": "$ne",
  ">=": "$gte",
  "<=": "$lte",
  ">": "$gt",
  "<": "$lt",
  empty: "$em",
  notEmpty: "$nem",
  nin: "$nin",
  in: "$in",
  all: "$all",
};

const cleanValue = (value) => {
  if (
    value &&
    (String(value).toLowerCase() === "true" ||
      String(value).toLowerCase() === "false")
  ) {
    value = String(value).toLowerCase() === "true";
  }

  if (typeof value === "string" || value instanceof String) {
    //check if string has quotes, if not set them
    const regex = /^['"](.*)['"]$/;
    if (!regex.test(value)) {
      value = `'${value}'`;
    }
  }
  if (Array.isArray(value)) {
    if (isEmptyArray(value)) {
      value = "[]";
    } else {
      value = JSON.stringify(value);
    }
  }
  return value;
};

export const prepareFilter = (
  filter,
  getters,
  related,
  result,
  comparator,
  parent,
) => {
  var comp = comparator || "&&";
  var finalFilter = [];
  if (isEmpty(filter)) {
    return {};
  }
  const filters = Object.keys(filter);
  filters.forEach((curFilter) => {
    if (curFilter == "$and" || curFilter == "$or") {
      let newFinalfilters = [];
      for (let index = 0; index < filter[curFilter].length; index++) {
        const item = filter[curFilter][index];
        newFinalfilters.push(
          `(${prepareFilter(
            item,
            getters,
            related,
            result,
            mappedComparator[curFilter],
            parent,
          )})`,
        );
      }
      finalFilter.push(newFinalfilters.join(mappedComparator[curFilter]));
    } else if (mappedComparator[curFilter] && parent) {
      let value = filter[curFilter];
      if (value && String(value).includes("$result.")) {
        const field = value.replace("$result.", "");
        value = !isEmptyArray(result)
          ? result.map((item) => item[field])
          : [""];
      } else if (value && String(value).includes("$related.")) {
        const field = value.replace("$related.", "");
        value = (related && related[field]) || "item";
      } else if (value && String(value).includes("$filterFunction.")) {
        const func = value.replace("$filterFunction.", "");
        value = getters.getFilterFunctionValue(func);
      }
      value = cleanValue(value);

      let addFilter = `item.${parent} === ${value}`;
      if (curFilter === "$em") {
        addFilter = `(typeof item.${parent} === "undefined" || item.${parent} === null || item.${parent} === "")`;
      } else if (curFilter === "$nem") {
        addFilter = `!(typeof item.${parent} === "undefined" || item.${parent} === null || item.${parent} === "")`;
      } else if (curFilter === "$in") {
        addFilter = `${JSON.stringify(value)}.includes(item.${parent})`;
      } else if (curFilter === "$nin") {
        addFilter = `!(${JSON.stringify(value)}.includes(item.${parent}))`;
      } else if (curFilter === "$all") {
        addFilter = `${value}.some( arrItem => item.${parent}.includes(arrItem))`;
      } else {
        addFilter = `item.${parent} ${mappedComparator[curFilter]} ${value}`;
      }
      finalFilter.push(addFilter);
    } else if (typeof filter[curFilter] === "object") {
      finalFilter.push(
        prepareFilter(
          filter[curFilter],
          getters,
          related,
          result,
          null,
          curFilter,
        ),
      );
    } else if (filter[curFilter]) {
      finalFilter.push(`item.${curFilter} == "${filter[curFilter]}"`);
    }
  });

  return finalFilter.join(` ${comp} `);
};

export const applyFilter = (
  objects,
  getters,
  filter,
  related,
  result,
  customFilter,
) => {
  if (isEmptyObj(filter) && isEmptyObj(customFilter)) {
    return objects;
  }
  let newFilter = filter || {};
  if (!isEmptyObj(customFilter)) {
    newFilter = Object.assign(filter || {}, customFilter);
  }

  let preparedFilter = prepareFilter(newFilter, getters, related, result);
  console.log(preparedFilter);

  const f = new Function("item", " return " + preparedFilter + ";");
  return objects.filter(f);
};
