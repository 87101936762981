<template>
  <v-card
    flat
    color="tileBackgroundColor"
    class="ma-0 pa-2"
  >
    <v-row
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-col
        cols="3"
        style="min-width: 400px;"
        class="flex-grow-0 flex-shrink-1"
      >
        <v-card
          class="pa-2"
          color="tileBackgroundColor"
          flat
        >
          <viewConfigView
            :object="currentObject"
            :viewconfig="getDetailConfig"
          />
        </v-card>
      </v-col>
      <v-col
        cols="9"
        style="min-width: 100px; max-width: 100%;"
        class="flex-grow-1 flex-shrink-0"
      >
        <v-card
          class="pa-2"
          color="tileBackgroundColor"
          flat
        >
          <viewConfigView
            :object="currentObject"
            :viewconfig="getExtendedConfig"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import * as UTIL from "../../../api/utilities";
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    editmode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null,
      fields: null
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    title() {
      return this.getViewConfig.title;
    },
    currentObject() {
      return this.object;
    },
    getDetailConfig() {
      if (UTIL.isEmpty(this.getViewConfig)) {
        return "";
      }
      return this.getViewConfig.objectType + "viewConfig2";
    },
    getExtendedConfig() {
      if (UTIL.isEmpty(this.getViewConfig)) {
        return "";
      }
      return this.getViewConfig.objectType + "Extended";
    }
  },
  methods: {
    getObjectConfigForType(type) {
      if (type === "Tabs") {
        return Constants.TAB_CONFIG;
      } else if (type === "Dynamic") {
        return Constants.CARD_CONFIG;
      } else if (type === undefined) {
        return Constants.VIEW_CONFIG;
      }
      return type;
    }
  }
};
</script>
