export const myModule = {};

import { TENANT } from "../../api/constants.js";
import * as Utilities from "../../api/utilities.js";
import * as coreObjects from "./coreObjectScripts.js";
let customScripts = null;
let crmScripts = null;
import(`/static/tenants/${TENANT}/scripts/index.js`).then((script) => {
  customScripts = script;
});
import(`/static/tenants/crm/scripts/index.js`).then((script) => {
  crmScripts = script;
});
let templateScripts = {};

export const loadTemplateScripts = async (templates) => {
  if (templates) {
    for (let index = 0; index < templates.length; index++) {
      const template = templates[index];
      import(`/static/tenants/${template}/scripts/index.js`).then((script) => {
        templateScripts[template] = script;
      });
    }
  }
};

export const getScriptForObject = (objectType, context) => {
  console.log("customScripts", customScripts);
  let customScript = null;
  if (customScripts) {
    customScript = customScripts.getScriptForObject(objectType);
    if (customScript) {
      return customScript;
    }
  }

  const templates = Object.keys(templateScripts);
  for (let index = 0; index < templates.length; index++) {
    const templateScript = templateScripts[templates[index]];
    if (templateScript) {
      const templateObjectScript =
        templateScript.getScriptForObject(objectType);
      if (templateObjectScript) {
        return templateObjectScript;
      }
    }
  }

  if (crmScripts) {
    return crmScripts.getScriptForObject(objectType);
  }

  return null;
};

export const beforeSave = (objectConfig, object, context, options) => {
  console.log("BeforeSave");
  if (Utilities.isEmpty(object.Guid)) {
    object.Guid = Utilities.getGuid();
  }

  if (coreObjects["beforeSave"]) {
    coreObjects["beforeSave"](objectConfig, object, context, options);
  }

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.beforeSave)
    ) {
      return objectScripts.beforeSave(objectConfig, object, context, options);
    }
  }

  return true;
};

export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("After Create");
  if (Utilities.isEmpty(object.Guid)) {
    object.Guid = Utilities.getGuid();
  }

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.afterCreate)
    ) {
      return objectScripts.afterCreate(
        objectConfig,
        object,
        relatedObject,
        context,
        options,
      );
    }
  }

  return object;
};

export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("After modify Custom");

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.afterModify)
    ) {
      return objectScripts.afterModify(
        objectConfig,
        object,
        relatedObject,
        context,
        options,
      );
    }
  }

  return object;
};

export const getTile = (objectConfig, object, context, options) => {
  if (objectConfig.objectType === "OpportunityMain") {
    return opportunityScripts.getTile(objectConfig, object, context, options);
  }

  var description = "No description";
  if (
    object.description !== undefined &&
    object.description !== null &&
    object.description !== ""
  ) {
    description = object.description;
  }
  return [
    {
      text: description,
      row: 1,
    },
  ];
};

/**
 * Tabs
 */
export const getFieldsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  fields,
  context,
  options,
) => {
  return fields;
};

export const getTabsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.getTabsForObjectAndConfig)
    ) {
      return objectScripts.getTabsForObjectAndConfig(
        objectConfig,
        object,
        viewConfig,
        tabs,
        context,
        options,
      );
    }
  }
  return tabs;
};

export const hasCustomAuthorisation = (
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts.hasCustomAuthorisation)
    ) {
      return objectScripts.hasCustomAuthorisation(
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    }
  }
  return options.hasAuthorisation;
};

export const launchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  console.log("Launch Custom Action for Core Object", action);

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts[action])
    ) {
      return objectScripts[action](
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    }
  }
  if (coreObjects[action]) {
    return coreObjects[action](
      objectConfig,
      object,
      viewConfig,
      context,
      options,
    );
  }
};

export const canLaunchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  console.log("Can Launch Custom Action for Core Object", action);

  if (coreObjects["canLaunchActionForObjectAndConfig"]) {
    const check = coreObjects["canLaunchActionForObjectAndConfig"](
      action,
      objectConfig,
      object,
      viewConfig,
      context,
      options,
    );

    if (check === false) {
      return false;
    }
  }

  if (!Utilities.isEmpty(objectConfig.objectType)) {
    var objectScripts = getScriptForObject(objectConfig.objectType, context);
    if (
      !Utilities.isEmpty(objectScripts) &&
      !Utilities.isEmpty(objectScripts["canLaunchActionForObjectAndConfig"])
    ) {
      return objectScripts["canLaunchActionForObjectAndConfig"](
        action,
        objectConfig,
        object,
        viewConfig,
        context,
        options,
      );
    } else {
      return true;
    }
  }
};
