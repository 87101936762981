<template>
  <v-app-bar
    ref="myToolBar"
    :height="getHeightOfBar"
    :color="isMobile ? 'card' : 'transparent'"
    class="pt-0 blur"
    app
    clipped-right
    elevation="0"
    hide-on-scroll
  >
    <v-container
      :class="isMobile ? 'px-2' : 'px-6'"
      fluid
    >

      <v-row
        align="center"
        class="flex-nowrap"
      >


        <v-row
          no-gutters
          align="center"
          class="gap-1"
          v-if="getCurrentObject == null"
        >
          <h1 class="pl-1 pl-sm-2  text-sm-h6 text-h5 font-weight-mediumBold text-capitalize">{{ getTitle }}</h1>

        </v-row>



        <v-row
          no-gutters
          :style="isMobile && 'max-width: calc(100% - 140px);'"
          class="flex1 min-w-0 flex-nowrap"
          :class="isMobile && 'flex-column'"
          :align="isMobile ? 'start' : 'center'"
          v-else
        >


          <v-btn
            v-if="!isMobile"
            class="iconButton ml-2 mr-4 "
            icon
            small
            @click.stop="onBack"
          >
            <v-icon
              small
              class="configLight"
            >hi-arrow-left-outline</v-icon>
          </v-btn>
          <h1 class="gray--text text-md-body-1 text-sm-subtitle-2 text-caption font-weight-mediumBold">{{ getTitle }}
          </h1>
          <v-icon
            v-if="!isMobile"
            size="small"
            style="padding-top: 2px;"
            class="gray--text px-4"
          >hi-chevron-right</v-icon>
          <h1
            class="flex1 text-body-1 font-weight-mediumBold text-truncate"
            :class="isMobile && 'kingSize'"
          >{{ getCurrentObject }}</h1>

        </v-row>



        <!-- <v-menu
          :rounded="'lg'"
          v-model="showSearch"
          offset-y
        >
          <template v-slot:activator="on">
            <v-text-field
              slot="activator"
              v-model="search"
              :style="getStyleForSearch()"
              class="text-field-transparent search transparent"
              filled
              dense
              clearable
              prepend-inner-icon="hi-magnifying-glass"
              hide-details="true"
              placeholder="Search for Leads, Contacts, Tasks,..."
              @input="onEdit"
              v-on="on"
            />

          </template>
          <searchview :search="search" />
        </v-menu> -->

        <div class="d-flex flex-nowrap ">
          <recent-items-menu
            v-if="!isMobile"
            :object="getCurrentObject"
          />

          <v-btn
            v-if="isConfigMode"
            color="warning"
            class="ml-2 mr-4 "
            @click="exitConfigMode"
          >
            Exit config mode
          </v-btn>


          <pinneditems />
          <notifications-menu />

          <settings-menu />

          <configurationDialogStarter />
          <SyncButton />
        </div>
        <!-- <v-app-bar-nav-icon
      ic
      align="right" 
      @click.native="toggleRightDrawer()"/> -->
        <!-- <v-dialog v-model="getLogState">
          <v-card v-if="getLogState">
            <logview />
          </v-card>
        </v-dialog> -->
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import * as Constants from "../../store/constants.js";
import { mapGetters } from "vuex";
import logview from "../components/LogComponent";
import * as UTIL from "../../api/utilities";
import searchview from "../splitCard/SearchView/SearchView";
import configurationDialogStarter from "../splitCard/ConfigurationViews/configurationDialogStarter";
import demo from "../../demo/Images/demoImages.js";
import Breadcrumb from "../components/Breadcrumb.vue";
import NotificationsMenu from "./NotificationsMenu.vue";
import SettingsMenu from "./SettingsMenu.vue";
import SyncButton from "./SyncButton.vue";
import pinneditems from "./BarComponents/PinnedItemsBar.vue"
import RecentItemsMenu from './Menus/RecentItemsMenu.vue';

export default {
  components: { logview, searchview, configurationDialogStarter, Breadcrumb, NotificationsMenu, SettingsMenu, SyncButton, pinneditems, RecentItemsMenu },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    mytoolBarWidth: 0,
    oldSize: "",
    dialog: false,
    showSearch: false,
    showMenu: false,
    search: "",
    demo
  }),
  computed: {
    ...mapGetters(["getCurrentUser", "getLeftMenu", "getConfigMode"]),
    getTitle() {
      var route = this.$store.getters["getCurrentRoute"]
      if (route) {
        var currentRoute = this.getLeftMenu.find(function (o) {
          return o.viewConfig === route && o.menuGroup !== "core" && UTIL.isTrue(o.isActive);
        });
        if (currentRoute) {
          return currentRoute.title
        }
      }

      var path = /[^/]*$/.exec(window.location.pathname)[0];
      return path
    },
    getCurrentObject() {
      if (this.$store.getters.getCurrentSplitcard) {
        var currentDetailState = this.$store.getters.getStateForSplitCard(this.$store.getters.getCurrentSplitcard.splitCard);
        if (currentDetailState > (this.isMobile ? 3 : 4)) {

          var currentObject = this.$store.getters["getCurrentObject"]
          if (currentObject) {
            if (!UTIL.isEmpty(currentObject.Subject)) return currentObject.Subject
            if (!UTIL.isEmpty(currentObject.Company)) return currentObject.Company
            if (!UTIL.isEmpty(currentObject.FullName)) return currentObject.FullName
          }
        }
      }
      return null
    },
    getSyncState() {
      return this.$store.getters.getSyncProgress;
    },
    getNumberOfOperationsToSend() {
      return this.$store.getters.getNumberOfOperationsToSend;
    },
    getHeightOfBar() {
      var toppadding = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--sat");
      return UTIL.addIntToPixelString(toppadding, 64);
    },
    getAvatar() {
      var user = this.$store.getters.getCurrentUser;
      var image = this.demo[user.image];
      if (image === undefined || image === null) {
        image = this.demo["noImage"];
      }
      return image;
    },
    isConfigMode() {
      return this.getConfigMode > 0
    }
  },
  methods: {
    onBack() {

      this.$store.dispatch("changeViewState", 0);

    },
    exitConfigMode() {
      this.$store.commit('setConfigMode', 0)
    },
    onResizeComponent() {
      var container = this.$refs.myToolBar;
      var value = 0;
      if (container !== undefined && container !== null) {
        value = container.clientWidth;
        if (value === undefined) {
          value = container.$el.clientWidth;
        }
        this.mytoolBarWidth = value;
      }
      this.SizeChanged();
    },
    getStyleForSearch() {
      var width = this.mytoolBarWidth;
      if (width === undefined) {
        width = 500;
      }
      var buttonWidth = 150;
      width = width - buttonWidth;
      return "width: ".concat(width).concat("px;");
    },
    SizeChanged() {
      var newSize = this.getSize();
      if (newSize !== this.oldSize) {
        // Set the values
        if (newSize === "Tiny") {
          this.clipped = false;
          this.fixed = false;
          this.disableRouteWatcher = false;
          this.footerFixed = false;
        } else if (newSize === "Big") {
          this.clipped = true;
          this.fixed = true;
          this.disableRouteWatcher = true;
          this.footerFixed = true;
        }
        this.oldSize = newSize;
      }
    },
    getSize() {
      var width = this.mytoolBarWidth;
      if (width > 1 && width < 800) {
        return "Tiny";
      }
      return "Big";
    },
    /**
      Actions
    */
    synchronize() {
      this.$store.commit(Constants.SET_SYNC_PROGRESS, 0);
      setTimeout(this.startSynchronize, 250);
    },
    startSynchronize() {
      this.$store.dispatch("synchronizeAllData");
    },
    onEdit(value) {
      if (value && value.length > 0) {
        this.showSearch = true
      } else this.showSearch = false
    }
  }
};
</script>
