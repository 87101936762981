<template>
  <v-container
    ref="outerListContainerCard white quotation"
    class="pa-0 ma-0"
    fluid
  >
    <v-row
      flat
      class="pa-1 ma-0 flex-nowrap"
      align="center"
    >
      <v-container
        class="pa-0 px-4 ma-0"
        fluid
      >
        <FilterView
          :viewconfig="viewconfig"
          :object="object"
          :is-compact-view="false"
          :pdf-element="pdfElement"
          remove-search
        />
      </v-container>
    </v-row>
    <div
      v-if="object"
      ref="pdfElement"
      style="width: 100%; display: flex; justify-content: center;"
    >
      <div
        class="padding-doc"
        style="width: 793.70px; font-family: 'Open Sans', 'Roboto', sans-serif !important;"
      >
        <div class="d-flex w-10 flex-space-between">
          <div class="px-8 flex-grow-1">
            <h1 class="w-10 pb-4">
              {{ options && options.title ? options.title : 'Quotation' }}
            </h1>
            <div class="d-flex w-5 pb-1">
              <h3 class="w-5">
                ID:
              </h3>
              <h3 class="w-5">
                {{ object.QuotationID }}
              </h3>
            </div>
            <div class="d-flex w-5 pb-1">
              <h3 class="w-5">
                Date:
              </h3>
              <h6 class="w-5">
                {{ object.Start }}
              </h6>
            </div>
            <div class="d-flex w-5 pb-1">
              <h3 class="w-5">
                Due date:
              </h3>
              <h6 class="w-5">
                {{ object.End }}
              </h6>
            </div>

          </div>
          <div class="px-8">
            <img
              :src="getRealmIcon"
              class="br"
              width="64pt"
              height="64pt"
            >
          </div>
        </div>

        <div
          class="d-flex pa-4"
          style="width: 100%;"
        >
          <div class="flex6 pa-2">
            <div class="pa-4 br soft-grey h-10">
              <h3 class="w-10 pb-2">
                To:
              </h3>
              <h3 class="w-10 pb-1">
                {{ getAccount.FullName }}
              </h3>
              <h6 class="w-5 pb-1">
                VAT id: {{ object.VATNo }}
              </h6>
              <h6 class="w-5 pb-1">
                Reg.no: {{ object.RegNo }}
              </h6>
              <h6 class="w-5 pb-1">
                {{ getAccount.Location }}
              </h6>
            </div>
          </div>
          <div class="flex6 pa-2 ">
            <div class="pa-4 br soft-grey h-10">
              <h3 class="w-10 pb-2">
                From:
              </h3>
              <h3 class="w-10 pb-1">
                {{ object.Account }}
              </h3>
            </div>
          </div>
        </div>

        <table style="width:100%; padding: 32px;">
          <tr>
            <th
              v-for="field in getFields"
              :key="field.value"
              :style="getTenantTheme ? { backgroundColor: `${getTenantTheme.primary}!important`, color: `${getTenantTheme.text}!important` } : {}"
              class="primary primary--text-dark"
            >{{ field.value }}</th>
          </tr>
          <tr
            v-for="item in getFilteredObjects"
            :key="item.id"
          >
            <td
              v-for="field in getFields"
              :key="field.value"
            >
              {{ getValue(field, item) }}
            </td>
          </tr>
        </table>

        <div class="d-flex w-10 flex-space-between pb-8">
          <h6 class="pa-4 px-8 flex-grow-1">
            Please add the invoice number to your payment description.
          </h6>
          <div class="px-8 flex6">
            <div class="d-flex pa-2 ">
              <h2 class="w-5">
                Summary
              </h2>
              <h2 class="w-5 text-align-right">
                {{ formatNumber(calculateSubTotal(getFilteredObjects, getStartPrice)) }}
              </h2>
            </div>
            <div class="d-flex pa-2 ">
              <h2 class="w-5">
                VAT ({{ getVAT ? getVAT * 100 : '0' }}%)
              </h2>
              <h2
                class="w-5 text-align-right"
                v-text="`${formatNumber(calculateTotalVAT(calculateSubTotal(getFilteredObjects, getStartPrice), getVAT))}`"
              />
            </div>
            <div
              :style="getTenantTheme ? { backgroundColor: `${getTenantTheme.primary}!important`, color: `${getTenantTheme.text}!important` } : {}"
              class="d-flex pa-2 primary primary--text-dark br "
            >
              <h2 class="w-5">
                Total
              </h2>
              <h2
                class="w-5 text-align-right"
                v-text="formatNumber(calculateTotal(getFilteredObjects, getStartPrice, getVAT))"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-space-between">
          <div />
          <div
            v-for="item in getAttachments"
            :key="item.Guid"
          >
            <img
              :src="item.url ? item.url : item.thumbnail"
              crossorigin="anonymous"
              contain
              style="height:200px; max-width: 100%; aspect-ratio: 1;"
            >
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatNumber, isEmpty, isNumeric } from '../../../api/utilities';
import { ATTACHMENT, GET_VIEW_CONFIG_FOR_ID, QUOTATION, ACCOUNT_MAIN, ATTACHMENT_CATEGORY_SIGNATURE } from '../../../store/constants';
import { TENANTS_THEME } from '../../../theme/tenants'
import FilterView from '../../splitCard/FilterView.vue';
import ListView from '../../splitCard/ListView.vue';
import Signature from '../Signature.vue';


export default {
  components: { FilterView, ListView, Signature },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      myListWidth: 0,
      pdfElement: null,
      keyObject: {
        key: 1
      },
    };
  },
  computed: {
    ...mapGetters(['getRealmIcon']),
    getFieldListType() {
      return "listDisplay";
    },
    getTenantTheme() {
      const tenant = this.$store.getters.getRealm;
      if (tenant) {
        return TENANTS_THEME[tenant]
      }
    },
    getAttachments() {
      var attachments = this.$store.getters.getDataObjectsForObjectType(ATTACHMENT);
      var guid = this.object.Guid
      var signatures = attachments.filter(function (o) {
        return o.parent === guid && o.parentType === QUOTATION && o.category === ATTACHMENT_CATEGORY_SIGNATURE;
      });
      return signatures
    },
    getAccount() {
      var accounts = this.$store.getters.getDataObjectsForObjectType(ACCOUNT_MAIN);
      var guid = this.object.Account
      var accounts = accounts.filter(function (o) {
        return o.Guid === guid;
      });
      return accounts[0]
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: 10,
          list: this.getFieldListType
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.filter(element => element.type !== "Title");
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "left"; //right
        }
        field.sortable = true;
      }
      return fields;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },

    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.object,
        isEdit: false
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters[GET_VIEW_CONFIG_FOR_ID](this.viewconfig);
    },
    items() {
      return [
        { id: '080938', amount: 1, product: 'product 1' },
        { id: '083438', amount: 2, product: 'product 2' }
      ]
    },

    getStartPrice() {
      return 0
    },
    getVAT() {
      if (this.object) {
        if (this.object.Btw) {
          return isEmpty(this.object.Btw) ? 0 : Number(this.object.Btw)
        }
        if (this.object.Vat) {
          return isEmpty(this.object.Vat) ? 0 : Number(this.object.Vat)
        }
      }
      return 0
    },
  },
  mounted() {
    this.pdfElement = this.$refs.pdfElement;
  },
  methods: {
    formatNumber,
    getValueListItem(listCode, value) {
      return this.$store.getters.getValueListForId(listCode).find(item => item.value === value);
    },
    getValue(field, item) {
      if (field.type === 'currency') {
        return formatNumber(item[field.value])
      }
      if (field.type === 'valueList') {
        const element = this.getValueListItem(field.dropdown, item[field.value])
        if (!isEmpty(element)) {
          return element.descr
        }
      }
      return item[field.value]
    },
    calculateTotalVAT(total, vat) {
      return Number(total) * Number(vat)
    },
    calculateSubTotal(items, startPrice) {
      var total = startPrice && isNumeric(startPrice) && items && items.length > 0 ? Number(startPrice) : 0
      if (items) {
        items.forEach(item => {
          total = Number(total) + Number(item.Price)
        });
      }

      return total
    },
    calculateTotal(items, startPrice, vat) {
      var subTotal = this.calculateSubTotal(items, startPrice)
      var total = subTotal + this.calculateTotalVAT(subTotal, vat)
      return total
    },
  },

};
</script>

<style>
.padding-doc {
  padding-bottom: 40pt;
  padding-left: 32pt;
  padding-right: 32pt;
  padding-top: 60pt;
}

.primary {
  background-color: var(--v-primary-base);
}

.primary--text-dark {
  color: #ffffff;
}

h6 {
  font-weight: 500;
  font-size: 9pt;
}

.text-align-right {
  text-align: end;
}

.d-flex {
  display: flex !important;
  display: -webkit-box;
  display: -ms-flexbox;
}

.w-10 {
  width: 100%;
}

.h-10 {
  height: 100%;
}

.w-5 {
  width: 50%;
  min-width: 50%;
}


.br {
  border-radius: 8pt;
}

.soft-grey {
  background-color: #fafafa;
}

.flex6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.flex-space-between {
  justify-content: space-between;
}



th {
  border: none;
  text-align: start;
  padding-top: 4pt;
  padding-bottom: 4pt;
  font-size: 10nppt;
}

.padding-doc th:first-of-type {
  border-radius: 8pt 0 0 8pt;
}

.padding-doc th:last-of-type {
  border-radius: 0 8pt 8pt 0;
}

.quotation td {
  border: none;
  text-align: start;
  padding-top: 2pt;
  padding-bottom: 2pt;
  font-size: 10pt;
}

.quotation th,
.quotation td {
  padding-left: 8px;
  padding-right: 8px;
  text-align: right !important;
}

/* tr > th, tr{
  text-align: right !important;
} */

.pa-2 {
  padding: 8pt !important;
}

.pa-4 {
  padding: 16pt !important;
}

.pa-8 {
  padding: 32pt !important;
}

.px-4 {
  padding-left: 16pt !important;
  padding-right: 16pt !important;
}

.px-8 {
  padding-left: 32pt !important;
  padding-right: 32pt !important;
}

.pb-4 {
  padding-bottom: 8pt !important;
}

.pb-2 {
  padding-bottom: 4pt !important;
}

.pb-1 {
  padding-bottom: 2pt !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.test {
  color: red;
}
</style>

