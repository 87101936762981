<template>
  <v-container
    fill-height
    class="pa-0 ma3"
    fluid
    white
  >
    <v-layout
      row
      wrap
      full-with
      fill-height
    >
      <v-flex
        v-for="image in images"
        :key="image.name"
        xs12
      >
        <v-card
          :class="getCardClassForImage(image)"
          flat
          tile
        >
          <img
            v-observe-visibility="visibilityChanged"
            v-if="image.type !== 'video'"
            :class="getCardClassForImage(image)"
            :src="image.src"
            :height="image.height"
            :width="image.width"
          >
          <video
            v-observe-visibility="visibilityChanged"
            v-if="image.type === 'video'"
            :class="getCardClassForImage(image)"
            :src="image.src"
            width="1200"
            height="500"
            controls
          >
            <source
              :src="image.src"
              type="video/mp4"
            >
            Your browser does not support the video tag.
          </video>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import image5 from "../../assets/ProductIphone/iphone5.jpg";
import image2 from "../../assets/ProductIphone/iphone2.jpg";
import image3 from "../../assets/ProductIphone/iphone3.jpg";
import image4 from "../../assets/ProductIphone/iphone4.jpg";
import image1 from "../../assets/ProductIphone/iphone1.jpg";
// import video1 from '../../assets/ProductIphone/video-iphone1.mp4'  // disabled for faster building.
// This component serves for demo purposes. We will change this to be modular and based on configuration.
export default {
  data() {
    return {
      show: false,
      image1: false,
      image2: false,
      image3: false,
      image5: false,
      video1: false,
      tempImages: {},
      image4: false,
      images: [
        {
          src: image1,
          name: "image1",
          height: "580px",
          width: "1173px"
        },
        {
          src: image2,
          name: "image2",
          height: "645px",
          width: "1129px"
        },
        {
          src: image3,
          name: "image3",
          height: "800px",
          width: "1013px"
        },
        // {
        //   src: video1,
        //   type: 'video',
        //   name: 'video1',
        //   height: '800px',
        //   width: '1013px'
        // },
        {
          src: image4,
          name: "image4",
          height: "950px",
          width: "1220px"
        },
        {
          src: image5,
          name: "image5",
          height: "930px",
          width: "1129px"
        }
      ]
    };
  },
  computed: {},
  mounted() { },
  methods: {
    getCardClassForImage(image) {
      if (this.show === true) {
        return "productImageVisible";
      }
      return "productImageInvisible";
    },
    visibilityChanged(isVisible, entry, image) {
      setTimeout(this.setVisible, 700);
    },
    setVisible() {
      this.show = true;
    }
  }
};
</script>

<style>
.productImageVisible {
  opacity: 1;
  transition: all 0.6s ease-out;
  text-align: center;
  -webkit-transition: all 0.6s ease-out;
}

.productImageInvisible {
  opacity: 1;
  text-align: center;
  transition: opacity 0.6s ease-out;
  -webkit-transition: opacity 0.6s ease-out;
}</style>
