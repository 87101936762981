<template>

  <v-card
    class="pa-1 pt-0"
    flat
  >
    <v-container
      fluid
      class="pa-0"
    >
      <v-row
        align="center"
        class="py-4 px-0"
      >

        <v-col
          class="py-0"
          cols="auto"
        >
          <h2 text-xs-center>{{ getTitle }}</h2>
        </v-col>
        <v-spacer />
        <v-text-field
          v-if="showSearchBar && isSearchFull"
          v-model="searchField"
          prepend-inner-icon="hi-magnifying-glass"
          label="Search"
          class="text-field-transparent search mr-2"
          filled
          dense
          style="min-width: 270px; margin-top: 0px; padding-top: 0;"
          single-line
          hide-details
          autofocus
          @input="onSearch"
          @blur="blurSearch"
        />
        <v-btn
          v-if="showSearchBar && !isSearchFull"
          icon
          class="configLight"
          @click="toggleSearch"
        >
          <v-icon>hi-magnifying-glass</v-icon>
        </v-btn>
        <ListViewActions
          v-if="showActions"
          :viewconfig="viewconfig"
          :object="object"
          :issmall="isCompactView"
          :read-only="readOnly"
          :dropzone-element="dropzoneElement"
        />
      </v-row>
    </v-container>
  </v-card>

</template>
<script>
import * as Constants from "../../store/constants.js";
import filterConfigurationView from "./FilterConfigurationView";
import * as Utilities from "../../api/utilities.js";
import ListViewActions from "./ListViewActions.vue";

export default {
  components: { filterConfigurationView, ListViewActions },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ""
    },
    isCompactView: {
      type: Boolean,
      default: true
    },
    onSearch: {
      type: Function,
      default: () => ('')
    },
    removeSearch: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    dropzoneElement: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      selectedFilter: {},
      oldSelectedFilter: null,
      searchField: "",
      dialog: false,
      displayFilter: false,
      isSearchFull: false
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    showSearchBar() {
      return !this.removeSearch;
    },
    showActions() {
      return !this.noActions;
    },
    getTitle() {
      var config = this.getViewConfig;
      if (config === undefined) {
        return "";
      }
      return this.$store.getters.getViewConfigForID(this.viewconfig).title;
    },
  },
  methods: {
    toggleSearch() {
      this.isSearchFull = true
    },
    blurSearch() {
      if (this.searchField === '') {
        this.isSearchFull = false
      }
    }
  }
};
</script>
