<template>
  <v-card class="rounded-lg card">
    <v-list dense>
      <v-list-item
        v-for="item in getMenuItems"
        :key="item.viewConfig"
        :class="{ 'opaque-7': !isSelected(item), }"
        :router="item.router"
        @click="() => { onClick(item) }"
      >
        <v-list-item-action>
          <v-icon
            :color="isSelected(item) && 'menuColorHighlight'"
            class="material-icons-sharp"
          >{{
            item.icon
          }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <label
              class=" font-weight-mediumBold"
              :class="isSelected(item) && 'menuColorHighlight--text'"
            >
              {{ item.title }}
            </label>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import * as Api from "../../api/objectApi.js";
import * as Utilities from "../../api/utilities";
import router from '../../router/index.js';

export default {
  props: {
    group: {
      type: String,
      default: "core"
    }
  },
  data: () => ({}),
  computed: {
    ...mapGetters({ drawerState: "getLeftDrawer", getLeftMenu: "getLeftMenu" }),
    getMenuItems() {
      var menuItems = this.getLeftMenu;

      var menuItemsFiltered = menuItems.filter((o) => {
        return o.menuGroup === this.group;
      });
      return menuItemsFiltered.sort(function (a, b) { return a.position - b.position });
    },
    getColor() {
      if (this.group === "core") {
        return "black"
      }
      return "primary"
    },
    getColorOfText() {
      if (this.group === "core") {
        return "menuColorHighlight"
      }
      return "white"
    }
  },
  methods: {
    onClick(item) {
      router.push(item.href)
    },
    getColorOfMenu(item) {
      if (item === null) {
        //return "menuItemColor";
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      if (item.viewConfig === currentRoute) {
        return this.getColorOfText;
      } else {
        return "menuItemColor";
      }
    },

    isSelected(item) {
      if (item === null) {
        return false;
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      return item.viewConfig === currentRoute;
    }
  }
};
</script>

<style>
.v-list__tile {
  padding: 8px;
}

.primary_text {
  color: "blue";
}
</style>
