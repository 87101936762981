<template>
  <v-hover
    v-slot="{ hover }"
    :disabled="true"
  >
    <v-card
      class="ma-0 pa-0 transparent"
      flat
      style="height:100%;"
    >
      <v-card
        v-if="isVisible"
        class="transparent"
        :class="getOuterClass"
        :flat="true"
        :elevation="hover ? 16 : 0"
        style="height:100%;"
      >
        <v-card v-show="false">
          {{ operationId }}
        </v-card>
        <v-card
          v-if="hasTitle || hasEditButton"
          class="d-flex justify-space-between ma-0 px-3 pt-3 pb-4 transparent"
          flat
          tile
        >
          <v-card
            flat
            class="ma-2 transparent flex-1 min-w-0"
          >
            <h2
              v-show="hasTitle"
              class="text-ellipsis"
            >{{ getViewConfig.title }}</h2>
          </v-card>
          <v-card
            v-if="hasEditButton"
            class="transparent "
            flat
          >
            <v-card
              v-if="!hideActions"
              class="transparent d-flex flex-nowrap"
              flat
            >
              <v-btn
                v-show="showMaximizeButton"
                class="iconButton mx-1"
                icon
                @click.stop="maximizeClick"
              >
                <v-icon
                  :class="fieldSize === '5' ? 'primary--text' : ''"
                  class="configLight"
                >{{ fieldSize === '5' ? 'hi-eye-outline' : 'hi-eye-slash-outline' }}</v-icon>
              </v-btn>
              <v-btn
                v-if="!isEditMode"
                class="iconButton mx-1"
                icon
                @click.stop="goToEditMode"
              >
                <v-icon class="configLight">hi-pencil-square-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditMode"
                class="iconButton mx-1"
                icon
                @click.stop="cancelClick"
              >
                <v-icon class="configLight">hi-x-mark-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditMode"
                class="primary mx-1"
                icon
                @click.stop="saveClick"
              >
                <v-icon class="configLight white--text">hi-check-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="isEditMode"
                class="sideBarColor rounded-md saveAndGo"
                elevation="0"
                @click.stop="saveAndGo"
              >
                Save and View
              </v-btn>

              <v-dialog
                v-model="maximized"
                height="1000"
                width="1800"
              >
                <viewConfigView
                  :object="object"
                  :viewconfig="viewconfig"
                  :operationid="operationId"
                  :editmode="editmode"
                  height="900px"
                />
              </v-dialog>

            </v-card>
          </v-card>
        </v-card>
        <!--
      <configurationButtons
        v-if="isConfigMode && displayComponent('Dynamic')"
        :viewconfig="getViewConfig"
        :viewconfigid="viewconfig"
        :type="getConfigType"
        color="iconButton"
      />
      -->
        <!--
        <tileView
          v-if="displayComponent('Details')"
          :viewconfig="viewconfig"
          :object="getObject"
          :operationid="operationId"
          :height="getHeight"
          :fieldsize="fieldSize"
          :editmode="getMode"
          :dense="false"
        />-->
        <tileView
          v-if="displayComponent('Tile')"
          :viewconfig="viewconfig"
          :object="getObject"
          :operationid="operationId"
          :height="getHeight"
          :fieldsize="fieldSize"
          :editmode="getMode"
          :dense="true"
        />
        <detailsPage
          v-if="displayComponent('Details')"
          :viewconfig="viewconfig"
          :object="getObject"
          :operationid="operationId"
          :height="getHeight"
          :fieldsize="fieldSize"
          :editmode="getMode"
          :dense="false"
          @onDoubleClick="goToEditMode"
        />

        <detailsImage
          v-if="displayComponent('Image')"
          :viewconfig="viewconfig"
          :object="getObject"
          :operationid="operationId"
          :height="getHeight"
          :editmode="getMode"
        />

        <dynamicCard
          v-if="displayComponent('Dynamic')"
          :viewconfig="viewconfig"
          :editmode="getMode"
          :object="getObject"
          :search="search"
        />

        <tabView
          v-if="displayComponent('Tabs')"
          :viewconfig="viewconfig"
          :object="getObject"
        />

        <listView
          v-if="displayComponent('List')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
          :search="search"
        />

        <chartContainer
          v-if="displayComponent('Chart')"
          :height="getHeight"
          :viewconfig="viewconfig"
          title="Title"
        />

        <weatherView
          v-if="displayComponent('Weather')"
          :height="getHeight"
          :viewconfig="viewconfig"
          title="Weather"
        />

        <actionList
          v-if="displayComponent('Action')"
          :viewconfig="viewconfig"
          title="Title"
        />

        <kanbanView
          v-if="displayComponent('Board')"
          :height="getHeight"
          :object="getObject"
          :viewconfig="viewconfig"
          :fieldsize="maximized ? '5' : '4'"
          title="Title"
        />

        <textEditor
          v-if="displayComponent('TextEdit')"
          :viewconfig="viewconfig"
          :editmode="isEditMode"
          :height="getHeight"
          container
          title="Title"
        />

        <customType
          v-if="displayComponent('CustomType')"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <calendarView
          v-if="displayComponent('Calendar')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
        />

        <mapViewContainer
          v-if="displayComponent('MapContainer')"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <stepperView
          v-if="displayComponent('Stepper')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <mapView
          v-if="displayComponent('Map')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <mapViewInteractive
          v-if="displayComponent('MapInteractive')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <videoPlayer
          v-if="displayComponent('Video')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <webView
          v-if="displayComponent('Web')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <statView
          v-if="displayComponent('Stat')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <ClientIntergrations
          v-if="displayComponent('ClientIntegrations')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
          :dialog="dialog"
        />

        <csvImporter
          v-if="displayComponent('CSV')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
          :dialog="dialog"
        />

        <fileUploader
          v-if="displayComponent('FileUploader')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <AttachmentUploaderList
          v-if="displayComponent('AttachmentUploaderList')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <HtmlPdf
          v-if="displayComponent('HtmlPdf')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />


        <listSubView
          v-if="displayComponent('ListSub')"
          :viewconfig="viewconfig"
          :object="getObject"
        />

        <TileListViewContainer
          v-if="displayComponent('TileList')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
        />

        <timeline
          v-if="displayComponent('Timeline')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <MailContainer
          v-if="displayComponent('Mail')"
          :object="getObject"
          :viewconfig="viewconfig"
          :height="getHeight"
        />

        <DraftMail
          v-if="displayComponent('DraftMail')"
          :object="getObject ? getObject.draftMail : {}"
          :viewconfig="viewconfig"
          :height="getHeight"
          :parentType="getObject ? getObject.parentType : ''"
          :parent="getObject ? getObject.parent : {}"
        />

        <CalendarTile
          v-if="displayComponent('CalendarTile')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
        />

        <Cart
          v-if="displayComponent('Cart')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
          :search="search"
          :operationid="operationId"
        />

        <CommentTile
          v-if="displayComponent('CommentTile')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
          :search="search"
        />

        <CustomFrame
          v-if="displayComponent('iFrame')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
        />

        <!-- custom views -->

        <QuotationCalculationTile
          v-if="displayComponent('quotationSolar')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
          :operationid="operationId"
        />

        <!-- custom views -->

        <detail-list-view-container
          v-if="displayComponent('detailList')"
          :viewconfig="viewconfig"
          :object="getObject"
          :height="getHeight"
          :search="search"
        />



      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
import * as Constants from "../../store/constants.js";
import detailsPage from "./Details.vue";
import tileView from "./TileView.vue";
import detailsImage from "./DetailsImage.vue";
import dynamicCard from "./DynamicCard";
import tabView from "./TabView";
import listViewSmall from "./ListView";
import listView from "./ListViewContainer";
import actionList from "./ActionList";
import chartContainer from "./ChartContainer";
import textEditor from "../components/textEditor";
import calendarView from "../calendar/CalendarContainer";
import mapViewContainer from "./MapViewContainer";
import weatherView from "../components/WeatherView";
import listSubView from "./ListSubView";
import customType from "./CustomViewConfig";
import kanbanView from "./KanbanContainer.vue";
import mapView from "../components/MapView";
import mapViewInteractive from "../components/MapViewInteractive";
import stepperView from "../components/StepperView.vue";
import csvImporter from "../components/CVSImporter.vue";
import fileUploader from "../components/FileUploader.vue";
import statView from "../components/StatView.vue";
import videoPlayer from "../components/videoPlayer.vue";
import webView from "./viewComponents/webView.vue";

import * as Theme from "../../theme/themeDefault.js";
import * as Utilities from "../../api/utilities";
import ClientIntergrations from "../components/ClientIntergrations.vue";
import AttachmentUploaderList from "../components/AttachmentUploaderList.vue";
import TileListViewContainer from "./TileListViewContainer.vue";
import CalendarTile from "../components/customTiles/CalendarTile.vue";
import QuotationCalculationTile from "../components/custom/QuotationCalculationTile.vue";
import Cart from "../components/customTiles/Cart.vue";
import { CAN_EDIT } from "../../store/authorizationObjectContext"
import DetailListViewContainer from '../splitCard/DetailListViewContainer.vue'
import Timeline from '../components/Timeline.vue';
import DraftMail from '../components/DraftMail.vue'
import MailContainer from "../components/MailContainer.vue";
import CommentTile from '../components/CommentTile.vue';
import CustomFrame from '../components/Iframe.vue';
import HtmlPdf from "../HtmlPdf.vue";

export default {
  components: {
    detailsPage,
    tileView,
    detailsImage,
    dynamicCard,
    tabView,
    listView,
    actionList,
    chartContainer,
    textEditor,
    customType,
    calendarView,
    mapViewContainer,
    weatherView,
    listSubView,
    kanbanView,
    mapView,
    stepperView,
    csvImporter,
    fileUploader,
    statView,
    videoPlayer,
    webView,
    ClientIntergrations,
    AttachmentUploaderList,
    TileListViewContainer,
    CalendarTile,
    QuotationCalculationTile,
    Cart,
    DetailListViewContainer,
    mapViewInteractive,
    Timeline,
    MailContainer,
    DraftMail,
    CommentTile,
    CustomFrame,
    HtmlPdf
  },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    editmode: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    },
    operationid: {
      type: String,
      default: null
    },
    dialog: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: "card"
    },
    search: {
      type: String,
      default: ""
    },
    inCard: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      isVisible: false,
      fadeClass: " fadedOut ",
      color: Theme.ICON_COLOR_LIGHT,
      maximized: false,
      fieldSize: "4"
      //currentObject: null,
    };
  },
  computed: {
    operationId() {
      if (Utilities.isEmpty(this.operationid)) {
        return this.$store.getters.getOperationIdForViewConfig(this.viewconfig)
      } else
        return this.operationid
    },
    isEditMode() {
      if (this.editmode && this.editmode === true) return this.editmode
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getHeight() {
      return this.height;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getConfigType() {
      return Constants.VIEW_CONFIG;
    },
    hasTitle() {
      if (this.getViewConfig !== null && this.getViewConfig !== undefined) {
        if (
          this.getViewConfig.title !== undefined &&
          this.getViewConfig.title !== null &&
          this.getViewConfig.title !== "" &&
          this.getViewConfig.type !== "Dynamic" &&
          this.getViewConfig.type !== "Stat" &&
          this.getViewConfig.type !== "Chart"
        ) {
          return this.getViewConfig;
        }
      }
      return false;
    },
    getOuterClass() {
      var margins = "outerClass transparent ma-0 pa-0 ";
      if (this.$store.getters.getDeviceSize === 1) {
        margins = " ma-0 pa-0 ";
      }
      if (!Utilities.isEmpty(this.getViewConfig) && this.getViewConfig.type === 'Map') {
        margins = " ma-0 pa-0 ";
      }

      var cssClass = margins + " no-scroll " + this.fadeClass;
      if (this.getType === "Dynamic" || this.getType === "Tabs") {
        cssClass = cssClass + " tileBackgroundColor ";
      }

      return cssClass;
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getObjectType() {
      return this.getViewConfig.objectType
    },
    getSubViewConfig() {
      return this.getViewConfig.subViewConfig;
    },
    getType() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      if (
        this.getViewConfig.type === "TilesView" ||
        this.getViewConfig.type === "ProductCatalog" ||
        Utilities.isEmpty(this.getViewConfig.type) ||
        this.getViewConfig.type.includes("Demo")
      ) {
        return "CustomType";
      }
      return this.getViewConfig.type;
    },
    getObject() {
      var logViewConfig = this.getViewConfig;
      if (this.operationId !== null) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.operationId
        );
        if (operationContainer !== null && operationContainer !== undefined)
          return operationContainer.newObject;
      }
      //hier stond this.currentObject voor de 3 objecten
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },

    /**------------------------------------------------------------
     *  Edit mode
     ------------------------------------------------------------*/
    canEdit() {
      if (this.getType === "Details" || this.getType === "TextEdit") {
        var payload = {
          objectType: this.getViewConfig.objectType,
          action: CAN_EDIT,
          viewConfig: this.viewconfig,
          object: this.getObject
        }
        return this.$store.getters.userHasAuthorisation(payload)
      };
      return false
    },
    hasEditButton() {
      return this.canEdit
    },
    showMaximizeButton() {
      if (Utilities.isEmpty(this.dialog) || this.dialog.show !== true) {
        return true;
      }
      return false;
    },
    getMode() {
      if (this.hasEditButton === false) {
        return this.editmode;
      }
      return this.isEditMode;
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.init()
    });
  },
  methods: {
    displayComponent(type) {
      return this.getType === type;
    },
    /*----------------------------------------------------------------------------
      Edit/Display modes change
    ----------------------------------------------------------------------------*/
    async saveClick() {
      var operationContainer = this.$store.getters.getOperationContainerForId(
        this.operationId
      );
      var canSave = await this.$store.dispatch(
        "saveOperationContainerForId",
        this.operationId
      );
      if (canSave) {
        this.goToDisplayMode();
      }
    },
    async saveAndGo() {

      var canSave = await this.$store.dispatch(
        "saveOperationContainerForId",
        this.operationId
      );
      if (canSave) {
        this.goToDisplayMode();
        setTimeout(this.goToObject, 300)
        // this.goToObject()
      }
    },
    goToObject() {
      if (this.getObjectType && this.getObject) {
        var payload = {
          objectType: this.getObjectType,
          objectId: this.getObject.Guid
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        var payload = {
          splitCardId: this.getObjectType + 's',
          object: navigationObject
        };
        this.$store.dispatch(Constants.SELECT_OBJECT, payload);
      }
    },
    cancelClick() {
      this.goToDisplayMode();
    },
    goToEditMode() {
      if (!this.canEdit) {
        return
      }
      var opId = Utilities.getGuid();
      var config = this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
      var payload = {
        object: this.getObject,
        config: config,
        id: opId
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
      var payload = {
        viewconfig: this.viewconfig,
        operationId: opId
      };
      this.$store.commit("changeEditMode", payload);
    },
    goToDisplayMode() {
      if (this.dialog !== undefined && this.dialog !== null) {
        this.dialog.show = false;
      }
      var payload = {
        viewconfig: this.viewconfig,
        edit: null
      };
      this.$store.commit("changeEditMode", payload);
    },
    maximizeClick() {
      if (this.displayComponent("Details")) {
        if (this.fieldSize === "5") {
          this.fieldSize = "4";
        } else {
          this.fieldSize = "5";
        }
      } else {
        this.maximized = true;
      }
    },
    minimizeClick() {
      this.maximized = false;
    },
    init() {
      this.fadeClass = " fadedOut ";
      this.$nextTick(() => {
        this.setVisible();
      });

    },
    setVisible() {
      this.isVisible = true;
      this.fadeClass = " fadedIn ";
    }
  }
};
</script>

<style>
.fadedOut {
  opacity: 0;
  transition: opacity 0.12s ease-in-out;
  -moz-transition: opacity 0.12s ease-in-out;
  -webkit-transition: opacity 0.12s ease-in-out;
}

.fadedIn {
  opacity: 1;
  transition: opacity 0.12s ease-in-out;
  -moz-transition: opacity 0.12s ease-in-out;
  -webkit-transition: opacity 0.12s ease-in-out;
}
</style>
