import { copyObject, isEmpty, isTrue } from "../../api/utilities.js";
import Vue from "vue";
import { ALERT_TYPE_ERROR, VALUE_LIST_CONFIG } from "../../store/constants.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  return true;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("after create in ViewField");
  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.auth)) {
    Vue.set(object, "auth", relatedObject.auth);
  }

  return object;
};

/**********************************************
 * Actions
 **********************************************/
const getValueListToUpdate = (object, context) => {
  var list = context.getters.getObjectsForFilter(VALUE_LIST_CONFIG, o => {
    return o.valueList === object.valueList && isEmpty(o.auth);
  });
  console.log("setValue ", list);
  return list;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options
) {
  // console.log("Can Launch action for action", object);
  if (isEmpty(object)) {
    return false;
  }
  if (action === "setAuthForRelatedObjects") {
    if (
      isEmpty(object.valueList) ||
      isEmpty(object.auth) ||
      getValueListToUpdate(object, context).length < 1
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Cannot lauch set Auth"
        });
      }
      return false;
    }
  }
  return true;
}

export const setAuthForRelatedObjects = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  console.log("setAuth");
  if (isEmpty(object.valueList) && isEmpty(object.auth)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "value not filled"
    });
    return;
  }
  var valueListFields = getValueListToUpdate(object, context);
  for (var i = 0; i < valueListFields.length; i++) {
    var valueListField = valueListFields[i];
    const oldObject = copyObject(valueListField);
    Vue.set(valueListField, "auth", object.auth);

    await context.dispatch("saveObjectForObjectType", {
      objectType: VALUE_LIST_CONFIG,
      object: valueListField,
      oldObject
    });
  }
};
