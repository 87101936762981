<template>
  <v-card
    elevation="2"
    class="no-scroll mx-0 pa-4 rounded-lg"
    color="card"
  >
    <v-card-title>Personal Information</v-card-title>
    <v-card-subtitle>You can change your personal information here</v-card-subtitle>
    <v-container>
      <v-row
        no-gutters
        align="center"
        class="py-4"
      >
        <v-avatar
          color="tileBackgroundColor"
          size="128"
        >
          <img
            v-if="!isEmpty(getImage)"
            :src="getImage"
            crossorigin="anonymous"
            aspect-ratio="1"
            width="128px"
            height="128px"
          />
          <v-icon
            v-else
            size="80"
          >hi-user</v-icon>
        </v-avatar>
        <v-row
          no-gutters
          class="pa-4"
        >
          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            style="min-height: 0;"
            class="pa-0 no-border"
            @vdropzone-sending="sendingMultiple"
          >
            <div class="dropzone-custom-content card">
              <v-btn
                id="upload-avatar"
                :loading="loading"
                color="primary"
                depressed
                class="rounded-lg mr-3"
              >
                Upload picture
              </v-btn>
            </div>
          </vue-dropzone>
          <v-btn
            :disabled="isEmpty(getImage)"
            color="tileBackgroundColor"
            depressed
            class="rounded-lg"
            @click="deletePicture"
          >
            Delete
          </v-btn>
        </v-row>
      </v-row>
      <v-row align="center">
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            ref="inputRef"
            v-model="employee.UserId"
            :clearable="false"
            readonly
            disabled
            filled
            label="Email"
            class="text-field-transparent"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            ref="inputRef"
            v-model="employee.FirstName"
            :clearable="false"
            filled
            label="First name"
            class="text-field-transparent"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            ref="inputRef"
            v-model="employee.LastName"
            :clearable="false"
            filled
            label="Last name"
            class="text-field-transparent"
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions
      v-if="needSave"
      class="px-4"
    >
      <v-btn
        color="primary"
        depressed
        large
        class="rounded-lg px-4 mr-3"
        @click="saveProfile"
      >
        Save changes
      </v-btn>
      <v-btn
        color="tileBackgroundColor"
        depressed
        large
        class="rounded-lg "
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-spacer />
    </v-card-actions>

  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { isBase64, isEmpty, isEmptyArray, isValidHttpUrl } from '../../api/utilities';
import vue2Dropzone from "vue2-dropzone";
import { API_BASE_URL } from '../../api/constants';
import { getAccessToken } from '../../store/authorizationContext';
import Axios from 'axios';
import imageCompression from 'browser-image-compression';
import { EMPLOYEE_MAIN } from '../../store/constants';


export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data() {
    return {
      dialog: false,
      employee: {},
      employeeOld: {},
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        maxFilesize: 10.0,
        addRemoveLinks: true,
        clickable: `#upload-avatar`,
        previewsContainer: false,
        // autoProcessQueue: false,
        acceptedFiles: 'image/*,',
        maxFiles: 10,
        uploadMultiple: true,
        parallelUploads: 10
        // acceptedFiles: 'image/*,application/pdf'
      },
      loading: false,
      processor: null
    };
  },
  computed: {
    ...mapGetters(['getCurrentEmployee', 'getCurrentUser']),
    getImage() {
      if (this.employee.Image) {
        if (isValidHttpUrl(this.employee.Image)) {
          return this.employee.Image
        }
      } else if (this.employee.thumbnail) {
        if (isBase64(this.employee.thumbnail)) {
          return this.employee.thumbnail
        }
      }
      return null;
    },
    needSave() {
      return JSON.stringify(this.employeeOld) !== JSON.stringify(this.employee)
    }
  },
  mounted() {
    this.employee = JSON.parse(JSON.stringify(this.getCurrentEmployee))
    this.employeeOld = JSON.parse(JSON.stringify(this.getCurrentEmployee))
  },
  methods: {
    isEmpty,
    cancel() {
      this.employee = JSON.parse(JSON.stringify(this.getCurrentEmployee))
    },
    deletePicture() {
      this.employee.Image = null
      this.employee.thumbnail = null
      this.saveProfile()
    },
    async sendingMultiple(file) {
      this.loading = true
      if (!isEmpty(file)) {
        let compressedImage = null
        this.employee.thumbnail = await this.compress(file, 120)
        compressedImage = await this.compress(file, 600)
        if (compressedImage) {
          const image = {
            name: file.name,
            attachmentType: file.type,
            file: compressedImage,
            account: this.getCurrentEmployee.Guid
          }
          this.upload(image)
        }
      }

    },
    async upload(file) {

      var config = {
        headers: { "Access-Control-Allow-Origin": "*" }
      };
      var bearer = await getAccessToken(this.getCurrentUser);
      if (bearer) {
        config.headers.Authorization = "Bearer ".concat(bearer);
        await Axios
          .post(API_BASE_URL + 'upload', file, config)
          .then(response => {
            if (response && response.data) {
              this.$set(this.employee, 'Image', response.data)

              this.saveProfile(response.data)
            }
            this.loading = false
          }).catch(error => {
            console.log(error)
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    saveProfile() {
      const oldObject = JSON.parse(JSON.stringify(this.getCurrentEmployee))
      const newEmployee = JSON.parse(JSON.stringify(this.employee))
      var payload = {
        objectType: EMPLOYEE_MAIN,
        object: newEmployee,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.employeeOld = this.employee
    },
    async compress(file, maxWidth) {
      return new Promise(async (resolve, reject) => {
        const options = {
          maxSizeMB: 0.3,
          maxWidthOrHeight: maxWidth || 1500,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(file, options);
          var reader = new FileReader();
          reader.onloadend = function () {
            var base64data = reader.result;
            resolve(base64data)
          }
          reader.readAsDataURL(compressedFile);
          // write your own logic
        } catch (error) {
          console.log(error);
          reject(error)
        }
      })
    },
  }
};
</script>

<style scoped></style>
