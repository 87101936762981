<template>
  <div
    style="max-width: 424px;"
    class="flex-column flex1 justify-self-center"
  >
    <div v-if="success === false">
      <v-container>

        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div
            class="text-h4 font-weight-medium pb-2"
            v-text="'Set a new password'"
          />
          <div
            class="text-body-2 pb-6"
            v-text="`Please choose a secure passward with min 8 charactars`"
          />

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'hi-eye-slash' : 'hi-eye'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Password"
            hint="At least 8 characters"
            class="text-field-transparent no-border "
            solo
            @click:append="showPassword = !showPassword"
          />

        </v-form>
      </v-container>
      <v-card-actions>
        <v-layout
          class="px-4"
          align-center
          justify-center="true"
          row
          wrap
        >
          <v-btn
            :loading="isLoading"
            :disabled="!isValid"
            color="primary"
            large
            depressed
            block
            @click.native="resetPassword()"
          >Reset Password</v-btn>
        </v-layout>
      </v-card-actions>
    </div>
    <div v-if="success === true">
      <v-container class="pt-6">
        <v-row
          align="center"
          justify="center"
          no-gutters
        >
          <div style="position:relative; max-width: 200px;">
            <LottieAnimation
              :animation-data="successJSON"
              :auto-play="true"
              :loop="false"
              :speed="1"
              ref="anim"
            />

          </div>

        </v-row>
        <v-form>

          <div
            class="text-h5 text-center font-weight-medium pb-10"
            v-text="'Password successfully reset!'"
          />
          <!-- <div
            class="text-body-2 pb-6"
            v-text="`You can now go to login`"
          /> -->


        </v-form>
      </v-container>
      <v-card-actions>
        <v-layout
          class="px-4"
          align-center
          justify-center="true"
          row
          wrap
        >
          <v-btn
            :loading="isLoading"
            color="tileBackgroundColor"
            large
            depressed
            block
            @click.native="logout"
          >Back to login</v-btn>
        </v-layout>
      </v-card-actions>
    </div>
  </div>
</template>
<script>
import { isEmpty } from '../../api/utilities';
import { USER_NOT_FOUND } from '../../store/authorizationContext';
import LottieAnimation from "lottie-web-vue"
import successJSON from "../../assets/animations/success.json"
import { mapActions } from 'vuex';
export default {
  components: {

    LottieAnimation
  },
  props: {
    onBack: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      successJSON,
      valid: false,
      password: "",
      success: false,
      isLoading: false,
      showPassword: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters"
      },
    };
  },
  computed: {
    isValid() {
      return !isEmpty(this.password) && this.valid
    }
  },
  created() {
    this.$store.dispatch('setPassword')
    this.code = this.$route.query.oobCode
    console.log('code', this.code)
    this.$store.dispatch('verifyCode', { code: this.code }).then(success => {
      // this.success = true;
      // this.isLoading = false;
    }, error => {
      console.log(error)
      let errorMessage = `Something went wrong: ${error.code}`;
      if (
        error.code === USER_NOT_FOUND
      ) {
        errorMessage = "User was not found. Please verify the spelling and try again";
      }
      this.$store.commit("showAlert", { type: "error", message: errorMessage });
      this.isLoading = false;
    });
  },
  methods: {
    logout() {
      this.$router.push('/')
    },
    resetPassword() {
      this.isLoading = true;
      this.$store.dispatch('setPassword', { password: this.password, code: this.code }).then(success => {
        this.success = true;
        this.isLoading = false;
      }, error => {
        console.log(error)
        let errorMessage = `Something went wrong: ${error.code}`;
        if (
          error.code === USER_NOT_FOUND
        ) {
          errorMessage = "User was not found. Please verify the spelling and try again";
        }
        this.$store.commit("showAlert", { type: "error", message: errorMessage });
        this.isLoading = false;
      });
    },

  }
}
</script>
<style lang="">
    
</style>