/*
    Rest configuration to link the tool with The mongo DB backend.
*/
import { buildRestGetCoreFromUrl } from "../synchronizationApi";
import { API_BASE_URL } from "../constants";
import * as UTIL from "../utilities";

export const urlCore = API_BASE_URL;

export const buildRestGetCore = synchronizations => {
  const url = urlCore + "configs";
  return buildRestGetCoreFromUrl(url, synchronizations);
};

export const updateSyncObjectAndHeadersCore = (
  syncObject,
  headers,
  objectConfig,
  response
) => {
  console.log("Nothing todo here");
};

export const updateSyncObjectAndHeadersForGetCore = (
  syncObject,
  headers,
  objectConfig,
  response
) => {
  console.log("Nothing todo here");
};

export const buildRestUpdateCore = (object, objectConfig) => {
  if (UTIL.isEmpty(object)) {
    console.log("object is empty");
    return;
  }
  var newObject = {};
  var body = {};
  var method = "post";

  objectConfig.fieldsConfig.forEach(field => {
    if (
      field.backendField !== null &&
      field.backendField !== undefined &&
      field.backendField !== "" &&
      object[field.field] !== undefined &&
      object[field.field] !== null &&
      field.edit !== false
    ) {
      newObject[field.backendField] = object[field.field];
    }
  });

  body = {
    objectConfig: objectConfig.objectConfig,
    id: object.Guid,
    update: newObject,
    reqId: UTIL.getGuid()
  };

  return {
    body: body,
    method: method,
    url: urlCore + "configs"
  };
};
