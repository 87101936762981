import { VALUE_LIST_CONFIG } from "../store/constants";

/**
 * App State
 */

export default {
  state: {},
  mutations: {},
  getters: {
    /*
        CardConfigs
    */
    getValueLists: (state, getters) => {
      return getters.getDataObjectsForObjectType(VALUE_LIST_CONFIG);
    },
    getValueListForId: (state, getters) => dropdown => {
      if (getters.getIsCacheActive(VALUE_LIST_CONFIG)) {
        return getters.getCachedObjectsForType(VALUE_LIST_CONFIG, dropdown);
      }
      return getters.getValueLists.filter(o => {
        return o.valueList === dropdown;
      });
    }
  },
  actions: {}
};
