<template>
  <v-container fluid>
    <v-row>
      <v-container
        class="pa-6"
        fluid
      >
        <v-row align="center">
          <h2 text-xs-center>{{ getTitle }}</h2>
          <v-spacer />
          <v-switch
            v-model="advanced"
            :label="`Advanced`"
            class="v-input--reverse"
          />
          <v-btn
            v-if="needSave(curObj)"
            icon
            @click="reset"
          >
            <v-icon>hi-arrow-uturn-left-outline</v-icon>
          </v-btn>

          <v-btn
            :disabled="!needSave(curObj)"
            elevation="0"
            class="ml-2 px-8"
            color="primary"
            @click="onSave"
          >Save</v-btn>
        </v-row>
      </v-container>
    </v-row>
    <v-row v-if="(curObj !== null && curObj !== undefined)">

      <v-col
        cols="12"
        md="6"
      >
        <v-card
          flat
          color="tileBackgroundColor"
          rounded="lg"
          class="d-flex flex-column"
          height="100%"
        >
          <div>
            <v-row class="px-4 pt-4 pb-0">
              <v-col
                class="pt-0"
                cols="12"
              >
                <div class="text-h6 pa-2 pt-4 pb-0 font-weight-bold">Without solar
                  <v-btn
                    icon
                    @click="openInfo"
                  ><v-icon>hi-question-mark-circle-outline</v-icon></v-btn>
                </div>
              </v-col>
              <v-col>
                <genericInput
                  :object="curObj"
                  :header="getField('YearlyConsumption')"
                  :operationid="operationid"
                  :viewconfig="viewconfig"
                  :showlabel="true"
                  :readonly="!advanced"
                  :step="100"
                  solo
                  dense
                  navigateidlink
                  is-not-list
                  append-text="Kwh"
                  @change="onChange"
                />
              </v-col>
              <v-col>
                <genericInput
                  :object="curObj"
                  :header="getField('UnitPriceElec')"
                  :operationid="operationid"
                  :viewconfig="viewconfig"
                  :showlabel="true"
                  :readonly="!advanced"
                  solo
                  dense
                  navigateidlink
                  is-not-list
                  append-text="€/Kwh"
                  @change="onChange"
                />
              </v-col>
              <v-col v-if="advanced">
                <v-card
                  color="white"
                  rounded="lg"
                  flat
                >
                  <v-row
                    class="pa-0 rounded-lg slider"
                    no-gutters
                  >
                    <v-col
                      class="py-2 px-3"
                      cols="12"
                    >
                      <v-label class="text-caption">
                        Years
                      </v-label>
                    </v-col>
                    <v-col
                      class="py-0 px-3"
                      cols="12"
                    >
                      <div class="text-h6 font-weight-bold text-field-transparent">
                        <div> {{ years }} <span class="text-caption">years</span>
                        </div>
                      </div>
                    </v-col>
                    <v-col
                      class="py-2 px-0"
                      cols="12"
                    >
                      <v-slider
                        v-model="years"
                        :min="20"
                        :max="40"
                        :step="1"
                        color="primary"
                        track-color="blue-grey lighten-5"
                        @change="change"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

            </v-row>
            <v-col>
              <v-row class="pa-6">
                <v-label class="patext-caption ">
                  Yearly
                </v-label>
                <v-container
                  class="pt-6"
                  fluid
                >
                  <v-row
                    align="start"
                    justify="space-between"
                    class="flex-nowrap"
                  >
                    <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                      Electricity Cost
                    </div>
                    <div
                      class="text-h6 font-weight-bold"
                      v-text="formatNumber(calculateYearlyCost(curObj['YearlyConsumption'], curObj['UnitPriceElec'], 1, null))"
                    />
                  </v-row>
                </v-container>
              </v-row>
            </v-col>

            <v-col>
              <v-row class="pa-6">
                <v-label class="patext-caption ">
                  On {{ years }} years
                </v-label>
                <v-container
                  class="pt-6"
                  fluid
                >
                  <v-row
                    align="start"
                    justify="space-between"
                    class="flex-nowrap"
                  >
                    <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                      Electricity Cost (incl 2,5% index)
                    </div>
                    <div
                      class="text-h6 font-weight-bold"
                      v-text="formatNumber(calculateYearlyCost(curObj['YearlyConsumption'], curObj['UnitPriceElec'], years, null))"
                    />
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </div>
          <v-row
            class="pa-6 mt-auto"
            no-gutters
            align="end"
          >
            <v-row class="py-8 px-6 menuColorHighlightBackground rounded-lg">

              <div class="text-caption">
                Total cost on {{ years }} years
              </div>
              <v-container
                class="pt-6"
                fluid
              >
                <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >

                  <div
                    class="text-h4 font-weight-bold"
                    v-text="formatNumber(calculateYearlyCost(curObj['YearlyConsumption'], curObj['UnitPriceElec'], years, null))"
                  />
                </v-row>
              </v-container>

            </v-row>

          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          flat
          color="tileBackgroundColor"
          class="d-flex flex-column"
          rounded="lg"
        >
          <v-row
            class="px-4 pt-0 pb-0"
            no-gutters
            align="center"
            justify="space-between"
          >
            <v-col class="pa-0">
              <div class="text-h6 pa-2 pt-4 pb-0 font-weight-bold primary--text">With solar </div>
            </v-col>
            <v-col class="pa-2 pt-6 pb-0">

              <v-select
                v-model="curObj['ProductSolar']"
                :items="getSolarProducts"
                item-text="Name"
                item-value="Guid"
                class="noline filterTitle"
                offset-y
              />

            </v-col>
          </v-row>
          <v-row class="px-4 mt-0">
            <v-col>
              <genericInput
                :object="curObj"
                :header="getField('SolarAmount')"
                :operationid="operationid"
                :viewconfig="viewconfig"
                :showlabel="true"
                :readonly="!advanced"
                :step="1"
                :append-text="`(${roundNumber(calculateSolarPowerNet(curObj['SolarAmount']), 0)}Kwh)`"
                solo
                dense
                navigateidlink
                is-not-list
                @change="onChange"
              />
            </v-col>
            <v-col>
              <genericInput
                :object="curObj"
                :header="getField('BatteryAmount')"
                :operationid="operationid"
                :viewconfig="viewconfig"
                :showlabel="true"
                :readonly="!advanced"
                :step="5"
                append-text="Kwh"
                solo
                dense
                navigateidlink
                is-not-list
                @change="onChange"
              />
            </v-col>
            <v-col v-if="advanced">
              <genericInput
                :object="curObj"
                :header="getField('NetAfname')"
                :operationid="operationid"
                :viewconfig="viewconfig"
                :showlabel="true"
                :readonly="!advanced"
                solo
                dense
                navigateidlink
                is-not-list
                append-text="%"
                @change="onChange"
              />
            </v-col>
          </v-row>
          <v-col>
            <v-row class="pa-6">
              <v-label class="patext-caption ">
                Yearly
              </v-label>
              <v-container
                class="pt-6"
                fluid
              >
                <!-- <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Solar power
                    <b>({{ roundNumber(calculateSolarPower(curObj['YearlyConsumption'], curObj['SolarAmount']), 1)
                    }}</b>Kwh<b>)</b>
                  </div>

                </v-row> -->
                <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Electricity Cost <b>({{ curObj['NetAfname'] }}%)</b>
                  </div>
                  <div
                    class="text-h6 font-weight-bold"
                    v-text="formatNumber(calculateYearlyCost(curObj['YearlyConsumption'], curObj['UnitPriceElec'], 1, curObj['NetAfname']))"
                  />
                </v-row>
              </v-container>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="pa-6">
              <v-label class="text-caption ">
                On {{ years }} years
              </v-label>
              <v-container
                class="pt-6"
                fluid
              >
                <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Electricity Cost (incl. 2.5% index)
                  </div>
                  <div
                    class="text-h6 font-weight-bold"
                    v-text="formatNumber(calculateYearlyCost(curObj['YearlyConsumption'], curObj['UnitPriceElec'], years, curObj['NetAfname']))"
                  />

                </v-row>
                <!-- <v-row
                  align="center"
                  justify="space-between"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Solar cost (one off)
                  </div>
                  <div class="text-h6 font-weight-bold">
                    {{ formatNumber(calcSolar(curObj['SolarAmount'], curObj['BatteryAmount'])) }}
                  </div>
                </v-row> -->
                <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Solar cost (one off)
                  </div>
                  <div class="text-h6 font-weight-bold">
                    {{ formatNumber(calcSolar(curObj['SolarAmount'])) }}
                  </div>
                </v-row>
                <v-row
                  align="start"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Batteries
                  </div>
                  <div class="text-h6 font-weight-bold">
                    {{ formatNumber(calcBateries(curObj['BatteryAmount'])) }}
                  </div>
                </v-row>
                <v-row
                  align="center"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Solar Premies
                  </div>
                  <genericInput
                    v-if="advanced"
                    :object="curObj"
                    :header="getField('PremieSolar')"
                    :operationid="operationid"
                    :viewconfig="viewconfig"
                    :showlabel="true"
                    :readonly="false"
                    solo
                    dense
                    is-not-list
                    @change="onChange"
                  />
                  <div
                    v-if="!advanced"
                    class="text-h6 font-weight-bold"
                  >
                    -{{ formatNumber(curObj['PremieSolar']) }}
                  </div>
                </v-row>
                <v-row
                  align="center"
                  justify="space-between"
                  class="flex-nowrap"
                >
                  <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Battery Premies
                  </div>
                  <genericInput
                    v-if="advanced"
                    :object="curObj"
                    :header="getField('PremieBatterij')"
                    :operationid="operationid"
                    :viewconfig="viewconfig"
                    :showlabel="true"
                    :readonly="false"
                    solo
                    dense
                    is-not-list
                    @change="onChange"
                  />
                  <div
                    v-if="!advanced"
                    class="text-h6 font-weight-bold"
                  >
                    -{{ formatNumber(curObj['PremieBatterij']) }}
                  </div>
                </v-row>

                <v-row
                  align="center"
                  justify="space-between"
                  class="flex-nowrap pt-2"
                >
                  <div class="text-caption">
                    Total
                  </div>
                  <!-- <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Monthly
                  </div> -->
                  <genericInput
                    :object="curObj"
                    :header="getField('isMonthly')"
                    :operationid="operationid"
                    :viewconfig="viewconfig"
                    :showlabel="true"
                    :readonly="false"
                    solo
                    dense
                    is-not-list
                    @change="onChange"
                  />


                </v-row>
                <v-row
                  align="center"
                  justify="space-between"
                  class="flex-nowrap pt-2"
                >
                  <div
                    v-if="!curObj['isMonthly']"
                    class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1"
                  >
                    Total cost
                  </div>
                  <genericInput
                    v-if="curObj['isMonthly']"
                    :object="curObj"
                    :header="getField('NbMonths')"
                    :operationid="operationid"
                    :viewconfig="viewconfig"
                    :showlabel="true"
                    :readonly="false"
                    class="v-input--reverse"
                    solo
                    dense
                    is-not-list
                    @change="onChange"
                  />
                  <!-- <div class="text-subtitle-1 font-weight-mediumBold pt-1 pr-1">
                    Monthly
                  </div> -->
                  <span
                    v-if="!curObj['isMonthly']"
                    class="text-h6 font-weight-bold"
                    v-text="formatNumber(calcTotalBelsol(curObj['SolarAmount'], curObj['BatteryAmount']))"
                  />
                  <span
                    v-if="curObj['isMonthly']"
                    class="text-h6 font-weight-bold"
                    v-text="formatNumber(calcTotalBelsolMonthly(curObj['SolarAmount'], curObj['BatteryAmount'], curObj['NbMonths']))"
                  />

                </v-row>

              </v-container>
            </v-row>
          </v-col>
          <v-row
            class="pa-6 mt-auto"
            no-gutters
            align="end"
          >
            <v-row
              class="py-8 px-6 primary rounded-lg"
              align="center"
              justify="space-between"
            >

              <div class="white--text text-caption">
                Total cost on {{ years }} years
              </div>

              <v-container
                class="pt-6"
                fluid
              >
                <v-row
                  align="center"
                  justify="space-between"
                >

                  <span
                    class="text-h4 white--text font-weight-bold"
                    v-text="formatNumber(calcTotal(curObj['YearlyConsumption'], curObj['UnitPriceElec'], curObj['NetAfname'], curObj['SolarAmount'], curObj['BatteryAmount']))"
                  />
                  <span class="text-h6 white--text">
                    Save
                    <span
                      class="font-weight-mediumBold"
                      v-text="calcPercent(curObj['YearlyConsumption'], curObj['UnitPriceElec'], curObj['NetAfname'], curObj['SolarAmount'], curObj['BatteryAmount'])"
                    />%
                  </span>
                </v-row>


              </v-container>

            </v-row>

          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="infoDialog">
      <v-card
        flat
        color="white"
      >
        <v-img
          :src="imageUrl"
          :lazy-src="imageUrl"
          :eager="true"
          contain
          style="max-height:90vh; max-width: 100%;"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </v-row>
          </template>
        </v-img>
        <v-btn
          color="primary"
          target="blank"
          style="position:absolute; top: 16px; left: 16px;"
          href="https://dashboard.vreg.be/report/DMR_Prijzen_elektriciteit.html"
        >Go to website <v-icon>hi-arrow-top-right-on-square-outline</v-icon></v-btn>
      </v-card>
    </v-dialog>
    <!-- <v-row
      class="py-2"
      no-gutters
      align="end"
    >
      <v-spacer />

      <v-btn
        flat
        elevation="0"
        class="px-8"
        color="primary"
      >Save</v-btn>
    </v-row> -->
  </v-container>
</template>

<script>
import { isEmpty, formatNumber, roundNumber, isNumeric } from "../../../api/utilities";
import * as Constants from "../../../store/constants.js";
import CardToolbar from "../../splitCard/CardToolbar.vue";
import image from "../../../assets/belsol/elec_prices.png"

export default {
  components: { CardToolbar },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    },
    operationid: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      fields: undefined,
      keyObject: {
        key: 1
      },
      batteries: {
        0: 0,
        5: 4395,
        10: 7395,
        15: 8895
      },
      advanced: false,
      solarPremies: 1500,
      batteryPremies: 1725,
      originalQuotation: null,
      maxUse: 1,
      selectedSolar: 'Belinus 410WP 07-2022',
      years: 35,
      infoDialog: false,
      imageUrl: image
    };
  },
  computed: {
    getSolarCoe() {
      return 1.75
    },

    getSolarProducts() {
      var products = this.$store.getters.getDataObjectsForObjectType(Constants.PRODUCT);
      var solarProducts = products.filter(function (o) {
        return o.Category === Constants.PRODUCT_CATEGORY_SOLAR
      });
      return solarProducts
    },
    getTitle() {
      var config = this.getViewConfig;
      if (config === undefined) {
        return "";
      }
      return this.$store.getters.getViewConfigForID(this.viewconfig).title;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.curObj,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 5,
          list: "edit" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    title() {
      return this.getViewConfig.title;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    curObj() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(this.operationid);
        return operationContainer.newObject;
      }
      if (this.object !== null) {
        this.setOriginalObject(this.object)
        return this.object;
      }
      this.setOriginalObject(this.$store.getters.getcurrentObject)
      return this.$store.getters.getcurrentObject;
    },
    getMode() {
      if (this.isReadOnly) {
        return "display";
      }
      return "edit";
    },
    isReadOnly() {
      return !this.hasCurrentOperation;
    },
    getOperationIdForItem(item) {
      var payload = {
        object: item,
        viewconfig: this.viewconfig
      };
      return this.$store.getters.getOperationIdForObjectAndConfig(payload); //todo.
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },

  },
  methods: {
    isEmpty,
    formatNumber,
    roundNumber,
    reset() {
      this.object = JSON.parse(JSON.stringify(this.originalQuotation))
    },
    onChange() {
      if (!this.isEditMode) {
        this.goToEditMode
      }
    },
    openInfo() {
      this.infoDialog = true
    },
    setOriginalObject(object) {
      if (object && this.originalQuotation === null) {
        this.originalQuotation = JSON.parse(JSON.stringify(object))
      }
    },
    needSave(object) {
      return JSON.stringify(object) !== JSON.stringify(this.originalQuotation)
    },
    getField(field) {
      var index = this.getFields.map(item => item.value).indexOf(field);
      if (index > -1) {
        return this.getFields[index];
      }
      return {};
    },
    calculateYearlyCost(YearlyConsumption, UnitPriceElec, years, reduction) {
      const indexing = 1.025
      const redux = reduction !== null && reduction !== undefined ? (reduction / 100) : 1
      let price = parseFloat(UnitPriceElec)
      const yearly = parseFloat(YearlyConsumption) * parseFloat(UnitPriceElec) * redux
      let totalPrice = yearly
      let currentYearly = yearly
      if (years > 1) {
        for (let index = 0; index < years; index++) {
          totalPrice = totalPrice + currentYearly
          currentYearly = currentYearly * indexing
        }
      }

      return totalPrice
    },
    getSolarProduct() {
      return {
        Guid: "52679f89-3604-8100-2700-4512231b0360"
      }
    },
    getSolarPrice(numPanels) {
      var solarProduct = this.getSolarProduct()
      var prices = this.$store.getters.getDataObjectsForObjectType("PriceItem")
      var price = prices.find(function (o) {
        return o.Product === solarProduct.Guid && o.Min <= numPanels && o.Max >= numPanels;
      });
      if (isEmpty(price)) {
        return 0
      } else {
        return price.Price
      }
    },
    calcSolar(numPanels) {
      return ((this.getSolarPrice(numPanels) * numPanels) + (this.curObj['StartPrice'] && isNumeric(this.curObj['StartPrice']) && numPanels > 0 ? Number(this.curObj['StartPrice']) : 0))
    },
    calcBateries(numBatteries) {
      return (this.batteries[numBatteries])
    },
    calculateSolarPowerNet(numPanels) {
      return this.maxUse * 410 * numPanels;
    },
    calculateSolarPower(YearlyConsumption, numPanels) {
      return this.calculateSolarPowerNet(numPanels) > YearlyConsumption * this.maxUse ? YearlyConsumption * this.maxUse : this.calculateSolarPowerNet(numPanels);
    },
    calcTotalBelsolMonthly(numPanels, numBatteries, months) {
      const total = this.calcTotalBelsol(numPanels, numBatteries)
      if (!isEmpty(months) && Number(months) > 0) {
        return total / Number(months)
      }
      return total
    },
    calcTotalBelsol(numPanels, numBatteries) {
      const solar = this.calcSolar(numPanels)
      const batteries = this.calcBateries(numBatteries)
      return solar + batteries
    },
    calcTotal(YearlyConsumption, UnitPriceElec, reduction, numPanels, numBatteries) {
      const elec = this.calculateYearlyCost(YearlyConsumption, UnitPriceElec, this.years, reduction)
      const calcBelsol = this.calcTotalBelsol(numPanels, numBatteries)
      const solarPremies = this.curObj['PremieSolar']
      const batteryPremies = this.curObj['PremieBatterij']
      return elec + calcBelsol - solarPremies - batteryPremies
    },
    calcPercent(YearlyConsumption, UnitPriceElec, reduction, numPanels, numBatteries) {
      if (!this.advanced) {

        const solarEnergy = this.calculateSolarPower(YearlyConsumption, numPanels)
        var surSolarPercentage = (this.calculateSolarPowerNet(numPanels) / YearlyConsumption) / 100
        const battery = numBatteries > 0 ? (numBatteries * 1.2 * surSolarPercentage) : 0

        const afname = ((1 - (solarEnergy / YearlyConsumption) * 0.7) - surSolarPercentage - battery) * 100
        this.curObj['NetAfname'] = this.roundNumber(afname < 0 ? 0 : afname > 100 ? 100 : afname, 1);
      }
      const totalWithout = this.calculateYearlyCost(YearlyConsumption, UnitPriceElec, this.years, null)
      const totalWith = this.calcTotal(YearlyConsumption, UnitPriceElec, reduction, numPanels, numBatteries)
      // const solar = this.calcSolar(numPanels, numBatteries)
      // const batteries = this.calcBateries(numBatteries)
      // this.curObj['NettoTotaal'] = this.formatNumber(solar + batteries - this.solarPremies - this.batteryPremies)
      if (totalWithout && totalWithout > 0)
        return this.roundNumber((1 - (totalWith / totalWithout)) * 100, 0)
      return 0
    },
    goToEditMode() {
      //Set edit mode for the view.
      this.operationId = this.$store.getters.generateOperationId;
      var payload = {
        viewconfig: this.viewconfig,
        operationId: this.operationId
      };
      this.$store.commit("changeEditMode", payload);
      //Go to create operationContainers.
      // Retrieve the objects
      var objects = this.getFilteredObjects;

      var objectPayload = {
        objects: objects,
        operationId: this.operationId,
        config: this.getObjectConfig,
        viewconfig: this.viewconfig
      };
      this.$store.dispatch(
        "createOperationContainersForObjects",
        objectPayload
      );
    },
    onSave() {
      var objectConfig = this.$store.getters.getObjectConfigForType(
        "Quotation"
      );
      var payload = {
        action: "saveQuotationCalculation",
        objectConfig: objectConfig,
        object: this.curObj,
        viewConfig: this.getViewConfig,
      };
      this.$store.dispatch("launchActionForObjectAndConfig", payload);
      this.originalQuotation = JSON.parse(JSON.stringify(this.curObj))
    }
  }
};
</script>

