import { isEmpty, isEmptyObj } from "../../api/utilities";
import { EMPLOYEE_MAIN, SUBSCRIPTION_PLAN } from "../../store/constants";

//Employee beforesave
export const beforeSave = (objectConfig, object, context, options) => {
  if (!isEmpty(objectConfig.objectType) && !isEmpty(object)) {
    if (objectConfig.objectType === EMPLOYEE_MAIN) {
      object.FullName = object.FirstName + " " + object.LastName;
    }
  }
  return object;
};

//Employee beforesave
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  return object;
};

export const launchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  if (action === "getTemplateData") {
    return getTemplateData(objectConfig, object, viewConfig, context, options);
  }
  console.log("Launch Custom Action for Core Object", action);
};

export const canLaunchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) => {
  console.log("Can Launch Custom Action for Core Object", action);

  if (!isEmpty(objectConfig.objectType) && !isEmpty(object)) {
    if (objectConfig.objectType === EMPLOYEE_MAIN) {
      var filter = (item) => item.Type === "P002";
      var plans = context.getters.getObjectsForFilter(
        SUBSCRIPTION_PLAN,
        filter,
      );
      const inActivePlan = plans && plans[0] && plans[0].Guid;
      console.log("inActivePlan", object.SubscriptionPlan, inActivePlan);
      if (
        action === "enableAccess" &&
        !(
          isEmpty(object.SubscriptionPlan) ||
          object.SubscriptionPlan === inActivePlan
        )
      ) {
        return false;
      }
      if (
        action === "disableAccess" &&
        (isEmpty(object.SubscriptionPlan) ||
          object.SubscriptionPlan === inActivePlan)
      ) {
        console.log("disableAccess", object.SubscriptionPlan, inActivePlan);

        return false;
      }
    }
  }

  return true;
};

export const getTemplateData = (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  console.log("CUSTOM_TEMPlATE", object, objectConfig);
  let data = {};
  if (!isEmptyObj(object)) {
    data = context.getters.getReadableObjects(objectConfig.objectType, object);
  }
  if (isEmpty(data)) {
    data = {};
  }
  data.TenantBaseUrl = window.location.origin;
  return data;
};
