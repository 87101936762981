<template>
  <v-card
    flat
    color="white"
    class="ma-3 pa-3"
  >
    <!-- <div ref="googleLoginBtn" /> -->
    <v-container>
      <v-row justify="center">
        <v-btn
          class="pa-5"
          style="height: auto;"
          elevation="0"
          rounded
          @click="googleSignIn"
        >
          <img
            width="64px"
            height="64px"
            src="../../../static/img/icons/google-drive.svg"
          >
        </v-btn>
      </v-row>
      <v-row
        justify="center"
        class="pt-2"
      >
        <h2>Google Drive</h2>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

import * as Utilities from "../../api/utilities"
import * as objectApi from "../../api/objectApi"

export default {
  mounted() {

  },
  methods: {
    googleSignIn() {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: '639169510919-93iefbqqg18jh4jhsr7muo0evdo14f19.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/drive.file',
        access_type: 'offline',
        ux_mode: 'popup',
        callback: (response) => {
          // const xhr = new XMLHttpRequest();
          // xhr.open('POST', code_receiver_uri, true);
          // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          // // Set custom header for CRSF
          // xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest');
          // xhr.onload = function () {
          //   console.log('Auth code response: ' + xhr.responseText);
          // };
          // xhr.send('code=' + code);
          var code = response.code
          this.saveCode(code)
        },
      });
      client.requestCode();
    },

    saveCode(code) {
      if (code.length < 10) {
        console.log("Code not long enough")
        return
      }
      var objectType = "ClientAttribute"
      var googleAuthCode = "googleDriveAuthCode"
      var attributes = this.$store.getters.getDataObjectsForObjectType(objectType);
      var attribute = attributes.find(function (o) {
        return o.attribute === googleAuthCode;
      });
      let oldObject = {}
      if (Utilities.isEmpty(attribute)) {
        attribute = objectApi.newObjectWithGuid()
        attribute.attribute = googleAuthCode
      } else {
        oldObject = Utilities.copyObject(attribute)
      }
      attribute.valueFrom = code
      var payload = {
        objectType: objectType,
        object: attribute,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
    }
  }
}
</script>
