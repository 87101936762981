<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-card
      flat
      class="pa-0 ma-0"
    >
      <v-card
        class="d-flex justify-space-between mb-0 align-center"
        flat
      >
        <v-card flat>
          <filterView :viewconfig="viewconfig" />
        </v-card>
        <v-card
          :class="`d-flex justify-end`"
          flat
        >
          <v-card
            v-show="showSearchBar"
            :class="`d-flex`"
            flat
          >
            <v-text-field
              v-show="showSearchBar"
              v-model="search"
              append-icon="hi-magnifying-glass"
              label="Search"
              style="min-width: 300px;"
              single-line
              hide-details
            />
          </v-card>
          <listViewAction :viewconfig="viewconfig" />
        </v-card>
      </v-card>
      <v-card
        v-if="!hasFilter"
        class="ma-3 pa-0"
        flat
      >
        <h2>{{ getTitle }}</h2>
      </v-card>
      <v-layout row>
        <v-flex
          xs12
          class="pl-3"
        >
          <v-container
            ref="myListOuter1"
            class="pa-0 ma-0 removeScroll xs12"
            fluid
          >
            <v-card flat>
              <mapview
                :viewconfig="viewconfig"
                :height="getHeight"
                :object="object"
                :markers="getFilteredObjects"
              />
            </v-card>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import filterView from "./FilterView";
import listViewAction from "./ListViewActions";
import mapview from "../components/MapView";

export default {
  components: { listViewAction, filterView, mapview },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    object: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fields: undefined,
      search: "",
      myListWidth: 0
    };
  },
  computed: {
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height + "px";
      }
      return "600px";
    },
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    hasFilter() {
      return (
        this.getViewConfig.queryFilter !== null &&
        this.getViewConfig.queryFilter !== undefined
      );
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getTitle() {
      var title = this.getViewConfig.title;
      if (title === undefined || title === null) {
        title = "No title";
      }
      return title;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
    showSearchBar() {
      return !this.isCompactView;
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    getClassOfFilters(type) {
      if (type === "spacer") {
        if (this.getWidth === Constants.SMALL_SIZE) {
          return "xs0";
        } else {
          return "";
        }
      }
      if (this.getWidth === Constants.SMALL_SIZE) {
        return "xs12";
      }
      if (this.getWidth === Constants.MEDIUM_SIZE) {
        return "xs5";
      }
      if (this.getWidth === Constants.BIG_SIZE) {
        return "xs4";
      }
      return "xs3";
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
