import { initBackend } from "absurd-sql/dist/indexeddb-main-thread";

let worker = new Worker(new URL("../index.worker.js", import.meta.url));
initBackend(worker);

// Wait for worker to ready.
const ready = new Promise(resolve => {
  function setReady({ data }) {
    if (data !== "ready") {
      return;
    }
    resolve(true);
    worker.removeEventListener("message", setReady);
  }
  worker.addEventListener("message", setReady);
});

export const execQuery = async (
  sql,
  params,
  onSucces,
  onError,
  action = "exec"
) => {
  await ready;
  const newSql = sql;
  const newParams = params;
  const newAction = action;
  const newOnSucces = onSucces;
  const newOnError = onError;
  return new Promise(resolve => {
    const queryId = Math.floor(Math.random() * 100000);

    function listener({ data }) {
      const { id, results } = data;
      if (id !== queryId) {
        return;
      }
      worker.removeEventListener("message", listener);
      newOnSucces(results);
    }

    worker.addEventListener("message", listener);
    worker.postMessage({
      id: queryId,
      action: newAction,
      sql: newSql,
      params: newParams
    });
  });
};

export const execBulk = async (sql, params, onSucces, onError) => {
  await ready;
  const newSql = sql;
  const newParams = params;
  const newOnSucces = onSucces;
  const newOnError = onError;
  return new Promise(resolve => {
    const queryId = Math.floor(Math.random() * 100000);

    function listener({ data }) {
      const { id, results } = data;
      if (id !== queryId) {
        return;
      }
      worker.removeEventListener("message", listener);
      newOnSucces(results);
    }

    worker.addEventListener("message", listener);
    worker.postMessage({
      id: queryId,
      action: "bulk",
      sql: newSql,
      params: newParams
    });
  });
};

export async function openDatabase(dbName) {
  await ready;
  const databaseName = dbName;
  return new Promise(resolve => {
    const queryId = Math.floor(Math.random() * 100000);
    function listener({ data }) {
      const { ready } = data;
      worker.removeEventListener("message", listener);

      resolve(data);
    }
    console.log("databaseNamePromise", databaseName);
    worker.addEventListener("message", listener);
    worker.postMessage({
      id: queryId,
      action: "open",
      dbName: databaseName
    });
  });
}

// Format SQL return results to JSON.
export function toJSON(data) {
  return data.map(({ columns, values }) => {
    return values.map(val => {
      const row = {};
      for (let i = 0; i < columns.length; i++) {
        row[columns[i]] = val[i];
      }
      return row;
    });
  });
}
