<template>
  <v-card
    flat
    class="transparent"
  >
    <v-row
      class="py-4 px-5"
      align="center"
    >
      <v-card
        flat
        class="ma-3 transparent"
      >
        <h2 text-xs-center>{{ getTitle }}</h2>
      </v-card>
      <v-spacer />
      <v-btn
        icon
        class="iconButton mx-1"
        @click.native="expand()"
      >
        <v-icon class="configLight">hi-chart-bar-square-outline</v-icon>
      </v-btn>
    </v-row>
    <v-row class="px-2">
      <v-flex xs12>
        <v-card
          flat
          class="transparent"
        >
          <chartView
            :height="height"
            :viewconfig="viewconfig"
            title="Title"
          />

        </v-card>
      </v-flex>
    </v-row>
    <v-dialog v-model="showConfig">
      <chartContainerBig
        :viewconfig="viewconfig"
        height="800"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import chartConfig from "./ChartConfig";
import chartContainerBig from "./ChartContainerBig";
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities";

export default {
  components: { chartConfig, chartContainerBig },
  props: {
    height: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: " "
    },
    viewconfig: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      showConfig: false
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getChartId() {
      return this.getViewConfig.subViewConfig;
    },
    isDateChart() {
      var fields = this.getFieldsForType(Constants.CHART_X);
      var field = fields[0];
      if (
        !UTIL.isEmpty(field) &&
        (field.calculationTypeX === "lineDate" ||
          field.calculationTypeX === "barDate")
      ) {
        return true;
      }
    },
    getTitle() {
      var config = this.getViewConfig;
      if (config === undefined) {
        return "";
      }
      return this.$store.getters.getViewConfigForID(this.viewconfig).title;
    }
  },

  mounted: function () { },
  methods: {
    expand() {
      this.showConfig = !this.showConfig;
    },
    /**
     * Fields
     */
    getFieldsForType(type) {
      var payload = {
        type: type,
        chartId: this.getChartId
      };
      return this.$store.getters.getChartFieldsForChartIdAndType(payload);
    }
  }
};
</script>
