import * as Constants from "../store/constants";
import * as UTIL from "../api/utilities.js";

/**
 * App State
 */

export default {
  state: {},
  mutations: {},
  getters: {
    /*
        CardConfigs
    */
    getTabsConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.TAB_CONFIG);
    },
    getTabsConfigsForViewConfig: (state, getters) => payload => {
      var tabs = getters.getTabsConfigs;

      tabs = tabs.filter(function(o) {
        return o.viewConfig === payload.viewConfig.subViewConfig;
      });

      if (!UTIL.isEmpty(payload.object)) {
        payload.objectConfig.getTabsForObjectAndConfig(
          payload.object,
          payload.viewConfig,
          tabs,
          null,
          "display"
        );
      }

      tabs = tabs.filter(function(o) {
        return o.isHidden !== true;
      });

      tabs.sort(function(tab1, tab2) {
        if (tab2.position > tab1.position) {
          return -1;
        }
        if (tab1.position > tab2.position) {
          return 1;
        }
        return 0;
      });

      return tabs;
    },
    getTabsForCardConfigID: (state, getters) => cardConfigID => {
      return getters.getTabsConfigs.find(function(o) {
        return o.cardConfig === cardConfigID;
      });
    }
  },
  actions: {}
};
