<template>
    <v-container>
        <v-row
            v-if="items.length == 0 && loading"
            class="py-8 px-4"
            align="center"
            justify="center"
            style="height: 100%"
        >
            <v-progress-circular
                indeterminate
                color="primary"
            >
            </v-progress-circular>
        </v-row>
        <v-container
            class="pa-0"
            v-else
        >

            <mail
                v-for="(item, index) in getNonDrafts"
                :showReply="index === items.length - 1"
                :replyActive="isReplying"
                :key="item.Guid"
                :object="item"
                :parent="object"
                @reply="addReply"
            />
            <div v-if="getDraftObject">
                <DraftMail
                    :viewconfig="viewconfig"
                    :object="getDraftObject"
                    :parent="object"
                    isReply
                ></DraftMail>
            </div>

        </v-container>
    </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import { getAccessToken } from '../../store/authorizationContext.js';
import { ALERT_TYPE_ERROR, INBOX_ITEM } from '../../store/constants.js';
import { extractEmail, isEmpty, isEmptyArray } from '../../api/utilities.js';
import { API_BASE_URL } from '../../api/constants.js';
import Axios from 'axios';
import Mail from '../Mail.vue';
import { newObjectWithGuid } from '../../api/objectApi.js';
import { parseJSON } from '../../api/synchronizationApi.js';
import moment from 'moment';
import DraftMail from "./DraftMail.vue";

export const DRAFT = 'S001'
export const TO_VALIDATE = 'S002'
export const TO_SEND = 'S003'
export const SENT = 'S004'
export const RECEIVED = 'S005'




export default {
    components: { Mail, DraftMail },
    props: {
        viewconfig: {
            type: String,
            default: ""
        },
        height: {
            type: String,
            default: null
        },
        object: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            items: [],
            loading: false,
            draftItem: null,
            currentObject: null
        }
    },
    computed: {
        getNonDrafts() {
            const mails = this.items.filter(item => item.Status === SENT || item.Status === RECEIVED)
            return mails.sort((a, b) => {
                return new Date(a.Date) - new Date(b.Date)
            })
        },
        getSubviewConfig() {
            if (this.getViewConfig === undefined) {
                return "";
            }
            return this.getViewConfig.subViewConfig;
        },
        getViewConfig() {
            return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
                this.viewconfig
            );
        },
        isReplying() {
            return !isEmpty(this.getDraftObject)
        },
        getDraftReply() {
            if (this.getDraftObject) {
                console.log('object', this.object)
                var filter = item => (!(item.Status === 'S001' || item.Status === 'S002' || item.Status === 'S003')) && item.ThreadId === this.object.ThreadId
                const nondrafts = this.$store.getters.getObjectsForFilter(INBOX_ITEM, filter)
                if (isEmptyArray(nondrafts)) {
                    return null
                }
                let lastNonDraft = nondrafts[0]
                for (let index = 0; index < nondrafts.length; index++) {
                    const nondraft = nondrafts[index];
                    if (moment(nondraft.Date).isAfter(lastNonDraft.Date)) {
                        lastNonDraft = nondraft
                    }
                }
                console.log('Draft changed!!')
                // if (isEmpty(drafts[0])) {
                //     this.getMailsforThread()
                // }
                return lastNonDraft
            }
            return null
        },
        getDraftObject() {
            console.log('getDraftObject', this.object)
            if (this.object) {
                console.log('object', this.object)
                var filter = item => (item.Status === 'S001' || item.Status === 'S002' || item.Status === 'S003') && item.ThreadId === this.object.ThreadId
                const drafts = this.$store.getters.getObjectsForFilter(INBOX_ITEM, filter)
                console.log('Draft changed!!')
                if (drafts && !isEmpty(drafts[0])) {
                    return drafts[0]
                }

            }
            return null
        },
        getObjectConfig() {
            return this.$store.getters.getObjectConfigForType(INBOX_ITEM);
        },
    },
    mounted() {
        if (this.object && this.object.ThreadId) {
            this.getMailsforThread()
            this.currentObject = this.object && this.object.Guid
        }
    },
    watch: {
        object(value) {
            console.log('mail', JSON.stringify(value), JSON.stringify(this.currentObject))

            if (value && value.Guid !== this.currentObject) {
                this.getMailsforThread()
                this.currentObject = value.Guid
            }

        }
    },
    methods: {

        async getMailsforThread() {
            this.loading = true
            var config = {
                headers: {}
            };
            var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

            if (isEmpty(bearer)) {
                var error = "problem with bearer During Operation.";
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
                return;
            }
            config.headers.Authorization = "Bearer ".concat(bearer);
            if (isEmpty(this.object)) {
                return
            }
            const id = encodeURIComponent(this.object.ThreadId)
            const url = `${API_BASE_URL}inboxItems?threadId=${id}`
            console.log('fetching activity items')
            Axios.get(url, config).then(async response => {
                console.log('activity items', response.data)
                this.loading = false
                this.items = response.data
                if (this.items) {
                    this.items.sort((a, b) => a.Date - b.Date)
                    await this.clearCachedDrafts()
                    const drafts = this.items.filter(item => (item.Status === 'S001' || item.Status === 'S002' || item.Status === 'S003'))
                    const draftsToSave = parseJSON(drafts, this.getObjectConfig, this.$store, this.getObjectConfig.syncGroup)
                    this.saveItems(draftsToSave)
                }

                // console.log('mails', JSON.stringify(this.items[0]))
            }, error => {
                this.loading = false
                // this.$store.commit("showAlert", {
                //     type: ALERT_TYPE_ERROR,
                //     message: error
                // });
            })
        },
        async clearCachedDrafts() {
            var database = this.$store.getters.getDatabase;
            const objectConfig = this.getObjectConfig
            await objectConfig.clearItemsInDbForQuery(database, `ThreadId = '${this.object.ThreadId}'`);
            this.$store.commit("setDataObjects", {
                objectConfig,
                objects: []
            });
        },
        saveItems(items) {
            const payload = {
                objects: items,
                objectConfig: this.getObjectConfig
            }
            this.$store.dispatch('updateDataStore', payload)
        },
        addReply(message) {
            // create reply message
            const toEmail = extractEmail(this.object.FromEmail)
            const replyInbox = this.$store.getters.getReplyInbox(message.Inbox)
            let newObject = newObjectWithGuid();
            const mailBody = this.getBodyDraft(message)
            console.log(mailBody)
            const references = isEmpty(message.Refs) ? message.MessageId : message.Refs + ` ${message.MessageId}`
            const subject = message.Subject && !message.Subject.startsWith('Re:') ? `Re: ${message.Subject}` : message.Subject
            const initMail = {
                ToEmail: toEmail,
                Account: message.Account,
                FromEmail: replyInbox ? replyInbox.Email : '',
                Subject: subject,
                Cc: message.Cc,
                Bcc: message.Bcc,
                BodyHtml: mailBody,
                Snippet: '',
                Source: "Swiftio",
                ThreadId: message.ThreadId,
                Inbox: replyInbox ? replyInbox.Guid : '',
                BodyText: message.BodyText,
                Unread: false,
                Status: 'S001',
                Refs: references
            }
            newObject = { ...initMail }
            var payload = {
                object: newObject,
                oldObject: {},
                objectType: INBOX_ITEM
            };
            this.$store.dispatch('saveObjectForObjectType', payload);
        },
        getBodyDraft(message) {
            if (message && message.BodyHtml) {

                let inline = message.BodyHtml
                const replyData = `On ${moment(message.Date).format('ddd Do MMM YYYY [at] HH:MM')}, ${message.FromEmail} wrote:`
                return `<div style="font-family: 'ui-sans-serif', 'system-ui', '-apple-system',
    'Roboto', sans-serif; font-size: 14px;"><div></div><div></div><div><br></div><div><br></div><div><br></div><div><br></div></div><blockquote class="gmail_quote" style="margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex"><div style="font-family: 'ui-sans-serif', 'system-ui', '-apple-system'; padding-bottom: 8px; font-size: 12px;">${replyData}</div>${inline}</blockquote>`
            } else return `<div style="font-family: 'ui-sans-serif', 'system-ui', '-apple-system',
    'Roboto', sans-serif; font-size: 14px;"><div></div></div>`

        },

    }
};
</script>