<template>
    <div>
        <div
            class="rounded-lg overflow-hidden"
            id="editorContainer"
        ></div>
    </div>
</template>
<script>
import Vueditor from 'vueditor'

export default {
    components: { Vueditor, },
    props: {
        value: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            editor: null,
            timer: null,
            content: null
        };
    },

    mounted() {

        this.editor = Vueditor.createEditor('#editorContainer', {
            toolbar: [
            ],
            fontName: [{ val: 'Arial' }],
            uploadUrl: '',
            id: '',
            classList: []
        });
        this.content = this.value
        this.editor.setContent(this.value);
        this.timer = setInterval(this.update, 300)

    },
    methods: {
        update: function update() {
            const newContent = this.editor.getContent()
            if (this.content !== newContent) {
                this.content = newContent
                this.$emit(
                    "input",
                    newContent
                );
            }

        },
    },
};
</script>