<template>
  <v-container
    ref="outerListContainerCard"
    class="pa-0 ma-0"
    fluid
  >
    <v-row
      flat
      class="pa-1 ma-0 flex-nowrap"
      align="center"
    >
      <v-container
        class="pa-0 ma-0"
        fluid
      >
        <FilterView
          :viewconfig="viewconfig"
          :object="object"
          :is-compact-view="false"
          :pdf-element="pdfElement"
          remove-search
        />
      </v-container>
    </v-row>
    <v-row
      ref="pdfElement"
      class="pt-0"
      style="min-height: 80px;"
    >

      <ListView
        :viewconfig="viewconfig"
        :object="object"
        :height="height"
        :search="search"
        no-pagination
      >
        <template v-slot:footer>
          <v-container
            fluid
            class="border-top"
          >
            <v-row
              class="px-6 py-1 pt-6"
              justify="space-between"
            >
              <div class="text-h6">Summary</div>
              <div class="text-h6">
                {{ formatNumber(calculateSubTotal(getFilteredObjects, getStartPrice)) }}
              </div>
            </v-row>
            <v-row
              class="px-6 py-1"
              justify="space-between"
            >
              <div class="text-h6">VAT ({{ getVAT ? getVAT * 100 : '0' }}%)</div>
              <div
                class="text-h6"
                v-text="`${formatNumber(calculateTotalVAT(calculateSubTotal(getFilteredObjects, getStartPrice), getVAT))}`"
              />

            </v-row>
            <v-row
              class="px-6 py-1 pb-6 "
              justify="space-between"
            >
              <div class="text-h6 font-weight-bold">Total</div>
              <div class="text-h6 font-weight-bold">
                {{ formatNumber(calculateTotal(getFilteredObjects, getStartPrice, getVAT)) }}
              </div>
            </v-row>
          </v-container>
          <v-container
            fluid
            class="border-top mx-3"
          >
            <Signature
              :object="object"
              :viewconfig="viewconfig"
            />
          </v-container>

          <v-btn
            class="mx-6 mb-8 mt-2"
            elevation="0"
            tile
            @click="quotationDialog = true"
          >
            View as Pdf
          </v-btn>
          <v-dialog
            v-model="quotationDialog"
            fullscreen
          >
            <v-card>
              <QuotationTemplate
                :viewconfig="viewconfig"
                :object="object"
              />
            </v-card>
          </v-dialog>


        </template>

      </ListView>

    </v-row>
  </v-container>
</template>

<script>
import * as Constants from "../../../store/constants.js";
import ListView from "../../splitCard/ListView.vue";
import FilterView from "../../splitCard/FilterView.vue";
import { formatNumber, isEmpty, isNumeric } from "../../../api/utilities";
import Signature from "../Signature.vue";
import QuotationTemplate from "./QuotationTemplate.vue";

export default {
  components: { ListView, FilterView, Signature, QuotationTemplate, QuotationTemplate },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: null
    },
    operationid: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      pdfElement: null,
      quotationDialog: false
    }
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    curObj() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(this.operationid);
        return operationContainer.newObject;
      }
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getcurrentObject;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.curObj
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getStartPrice() {
      return 0
    },
    getVAT() {
      if (this.curObj) {
        if (this.curObj.Btw) {
          return isEmpty(this.curObj.Btw) ? 0 : Number(this.curObj.Btw)
        }
        if (this.curObj.Vat) {
          return isEmpty(this.curObj.Vat) ? 0 : Number(this.curObj.Vat)
        }
      }
      return 0
    },
  },
  mounted() {
    this.pdfElement = this.$refs.pdfElement;
  },
  methods: {
    formatNumber,
    displayComponent(type) {
      return this.getSubviewConfig === type;
    },
    calculateTotalVAT(total, vat) {
      return Number(total) * Number(vat)
    },
    calculateSubTotal(items, startPrice) {
      var total = startPrice && isNumeric(startPrice) && items && items.length > 0 ? Number(startPrice) : 0
      if (items) {
        items.forEach(item => {
          total = Number(total) + Number(item.Price)
        });
      }

      return total
    },
    calculateTotal(items, startPrice, vat) {
      var subTotal = this.calculateSubTotal(items, startPrice)
      var total = subTotal + this.calculateTotalVAT(subTotal, vat)
      return total
    },
  }
};
</script>

