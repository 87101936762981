import { isEmpty } from "../../api/utilities.js";
import Vue from "vue";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  if (!isEmpty(object.backendField) && isEmpty(object.field)) {
    Vue.set(object, "field", object.backendField);
    Vue.set(object, "description", object.backendField);
  }

  if (
    !isEmpty(object.relatedObjectType) &&
    isEmpty(object.relatedObjectField)
  ) {
    Vue.set(object, "relatedObjectField", "Guid");
  }

  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  afterModify(objectConfig, object, null, context, options);
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  Vue.set(object, "type", "string");
  Vue.set(object, "edit", true);
  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.object)) {
    Vue.set(object, "auth", relatedObject.object.auth);
  }

  return object;
};
