<template>
    <div class="relative">

        <v-dialog
            v-model="dialog"
            fullscreen
        >

            <v-card>
                <v-container fluid>
                    <v-row
                        no-gutters
                        class="px-2"
                    >
                        <v-toolbar-title class="font-weight-mediumBold">Edit html</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="iconButton"
                            elevation="0"
                            @click="cancel"
                            class="px-8  mr-2"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            elevation="0"
                            class="px-8 "
                            @click="save"
                        >
                            Save
                        </v-btn>
                    </v-row>
                </v-container>
                <v-container
                    class="full-height"
                    fluid
                    ref="codeContainer"
                >
                    <v-row class="full-height kingSize">
                        <v-col
                            :cols="12"
                            :lg="6"
                            class="full-height"
                        >
                            <v-card
                                flat
                                rounded="xl"
                                style="height: 100%;"
                                class="tileBackgroundColor px-4 py-2"
                            >
                                <v-container
                                    fluid
                                    class="py-6 "
                                >
                                    <v-row
                                        align="center"
                                        justify="space-between"
                                    >
                                        <div>Code</div>
                                        <v-btn
                                            elevation="0"
                                            @click="formatCode"
                                        >Format</v-btn>
                                    </v-row>
                                </v-container>
                                <prism-editor
                                    class="my-editor rounded-lg py-2"
                                    v-model="code"
                                    :highlight="highlighter"
                                    line-numbers
                                ></prism-editor>
                            </v-card>
                        </v-col>
                        <v-col
                            :cols="12"
                            :lg="6"
                            class="full-height relative"
                        >
                            <v-card
                                flat
                                rounded="xl"
                                :style="`height: 100%; overflow: auto;max-width: 100vw; max-height: 100vh;`"
                                class="sideBarColor relative"
                            >
                                <div style="padding:80px; width:fit-content;">
                                    <div
                                        v-html="preview"
                                        :style="`transform: scale(${zoom});transform-origin: top left; `"
                                    >

                                    </div>
                                </div>

                            </v-card>
                            <div class="d-flex align-items gap zoombuttons">
                                <v-btn
                                    class="iconButton mx-1"
                                    icon
                                    @click.stop="zoomIn"
                                >
                                    <v-icon class="configLight">hi-magnifying-glass-plus-outline</v-icon>
                                </v-btn>
                                <v-btn
                                    class="iconButton mx-1"
                                    icon
                                    @click.stop="zoomOut"
                                >
                                    <v-icon class="configLight">hi-magnifying-glass-minus-outline</v-icon>
                                </v-btn>

                            </div>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

// require component
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';


import * as prettier from "prettier/standalone";
import htmlParser from "prettier/plugins/html"
// require styles

import { ref } from 'vue';
import { formatHtmlData, isTrue } from '../api/utilities';
export default {
    components: {
        PrismEditor,
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        dialog: {
            type: Boolean,
            default: false
        },
        onClose: {
            type: Function,
            default: () => { }
        },
        onSave: {
            type: Function,
            default: () => { }
        },
        data: {
            type: Object,
            default: null
        },
        templates: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            code: '',
            preview: '',
            zoom: 1
        }
    },
    watch: {
        dialog(val) {
            if (isTrue(val)) {
                this.code = this.value
                this.formatCode()
            }
        },
        code(val) {
            if (this.data && this.templates) {
                this.preview = formatHtmlData(val, this.data, this.templates)
            } else {
                this.preview = val
            }
        }
    },

    methods: {
        // called when the editor is created
        cancel() {
            this.onClose()
        },
        save() {
            this.$emit('input', this.code)
            this.onSave()
            this.onClose()
        },
        highlighter(code) {
            return highlight(code, languages.js); // languages.<insert language> to return html with markup
        },
        openDialog() {
            this.dialog = true

        },
        async formatCode() {
            this.code = await prettier.format(this.code, {
                parser: 'html',
                plugins: [htmlParser],
                tabWidth: 2,
                htmlWhitespaceSensitivity: "ignore"
            });
        },
        zoomOut() {
            if (this.zoom > 0.5) {
                this.zoom -= 0.1
            }
        },
        zoomIn() {
            if (this.zoom < 1.5) {
                this.zoom += 0.1
            }
        }
    },
}
</script>

<style>
/* required class */
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #111;
    color: #fff;

}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}

.zoombuttons {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 10;
}
</style>