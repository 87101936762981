<template>
  <v-card
    flat
    class="ma-0 pa-0"
  >
    <v-card
      flat
      width="1200px"
      color="tileBackgroundColor"
      height="90vh"
      class="ma-0 pa-5 rounded-lg"
    >
      <v-layout
        row
        wrap
      >
        <!-- <v-flex xs12>
          <v-card
            flat
            color="tileBackgroundColor"
            class="px-4"
          >
            <h2>Results for : " {{ search }} "</h2>
          </v-card>
        </v-flex>
        <v-flex xs12 /> -->
        <v-card flat>
          <viewConfigView
            :search="search"
            viewconfig="DashboardSearchView"
          />
        </v-card>
      </v-layout>
    </v-card>
  </v-card>
</template>
<script>
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      config: null,
      fields: null
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getActions() {
      //  return this.$store.getters.getActions;
      var actions = this.$store.getters.getActionListForActionConfig(
        this.getViewConfig.subViewConfig
      );
      if (actions === undefined || actions === null) {
        return [];
      }
      return actions;
    },

    title() {
      return this.getViewConfig.title;
    },
    currentObject() {
      return this.$store.getters.getCurrentObject;
    }
  },
  methods: {
    launchAction(method) { }
  }
};
</script>
