<template>
  <div class="pa-2 px-3 border1 rounded-lg">
    <label
      class="text-caption gray--text"
      style="font-size: 0.6rem!important;"
    >{{ label }}</label>
    <json-editor-vue
      v-model="value"
      :readOnly="readonly"
      :navigationBar="false"
      :mainMenuBar="false"
      :statusBar="false"
      flattenColumns
      mode="text"
    ></json-editor-vue>
  </div>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import { isEmpty } from '../../api/utilities';
export default {
  components: {
    JsonEditorVue
  },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: "300px"
    },
    readonly: {
      type: Boolean,
      default: true
    },
    container: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: {}
    };
  },
  mounted() {
    this.init()
  },
  watch: {
    value(val) {
      if (isEmpty(val)) {
        this.setValue(val)
      }
      try {

        if (typeof val !== 'object') {
          val = this.convertToJson(val)
        }
        val = JSON.stringify(val)
        this.setValue(this.cleanString(val))

      } catch (error) {
        this.setValue(this.cleanString(val))
      }
    },
    readonly(val) {
      if (val) {
        this.init()
      }

    }
  },
  methods: {
    init() {
      const value = structuredClone(this.object[this.header.value])
      try {
        if (!isEmpty(value)) {
          const json = this.convertToJson(value)
          this.value = json
        }
      } catch (error) {
        console.log(error)
      }
    },
    setValue(value) {
      this.$set(this.object, this.header.value, value)
    },
    convertToJson(value) {
      if (isEmpty(value)) {
        return value
      }
      try {
        if (typeof value === 'object') {
          return value
        } else {
          return this.convertToJson(JSON.parse(value))
        }
      } catch (error) {
        console.log(error)
      }
    },
    cleanString(string) {
      if (isEmpty(string)) {
        return
      }
      var removeNewLines = string.replace(/(\r\n|\n|\r)/gm, "");
      return removeNewLines.trim()
    }
  }
};
</script>

<style>
.snackbar .v-snack__wrapper,
.snackbar .v-snack__content {
  padding: 0;
}

.snackbar .v-alert {
  margin: 0;
}
</style>

