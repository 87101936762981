import * as Constants from "./constants.js";
import * as Utilities from "../api/utilities";

export default {
  state: {},
  mutations: {},
  getters: {},
  actions: {
    /*****************************************
        Save objects
    *****************************************/

    async saveObject({ dispatch, commit, getters }, payload) {
      var payloadNew = {
        objectConfig: payload.objectConfig,
        objects: [payload.object]
      };
      await dispatch(Constants.SAVE_OBJECTS, payloadNew);
    },

    async saveObjects({ dispatch, commit, getters }, payload) {
      var objectConfig = payload.objectConfig;
      var objects = payload.objects;
      if (objects !== undefined && objects.length > 0) {
        await payload.objectConfig
          .saveAll(getters.getDatabase, payload.objects)
          .then(
            result => {
              // If completed we should save the synchronisation state.
            },
            error => {
              // If error we should save the synchronisation state.
              console.log("Error saving fields: ", error);
              Utilities.findSavingError(objects, objectConfig, getters.getDatabase)
            }
          );
      } else {
        console.log("No objects to save");
      }
    },
    /*****************************************
        History
    *****************************************/
    async saveObjectHistory({ dispatch, commit, getters }, payload) {
      var payloadNew = {
        objectConfig: payload.objectConfig,
        objects: [payload.object]
      };
      await dispatch(Constants.SAVE_OBJECTS_HISTORY, payloadNew);
    },
    async saveObjectsHistory({ dispatch, commit, getters }, payload) {
      var objectConfig = payload.objectConfig;
      var objects = payload.objects;
      if (objects !== undefined && objects.length > 0) {
        await payload.objectConfig
          .saveAllHistory(getters.getDatabase, payload.objects)
          .then(
            result => {
              // If completed we should save the synchronisation state.
            },
            error => {
              // If error we should save the synchronisation state.
              console.log("Error saving fields: ", error);
              Utilities.findSavingError(objects, objectConfig, getters.getDatabase)
            }
          );
      } else {
        console.log("No objects to save");
      }
    }
  }
};
