import { fillGuidFieldsForObject } from "../../../api/objectApi";
import { isEmpty } from "../../../api/utilities";
import {
  ATTACHMENT,
  CARD_CONFIG,
  FILTER_CONFIG,
  FILTER_FIELD_CONFIG,
  MENU_ITEM,
  MESSAGE_MAIN,
  TAB_CONFIG,
  VIEW_CONFIG,
  VALUE_LIST,
  VIEW_FIELD,
  VIEW_TYPE_TABS,
} from "../../../store/constants";

export const createCardWithViewForObjectType = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
  payload,
) => {
  var parentViewConfig = object;
  var dependentObjectType = payload.dependentObjectType;
  var mainObjectType = parentViewConfig.objectType;
  var viewType = payload.viewType;
  var auth = parentViewConfig.auth;
  var cardName = mainObjectType.concat(dependentObjectType).concat("Card");
  var viewConfigName = mainObjectType
    .concat(dependentObjectType)
    .concat(viewType)
    .concat("View");
  var queryName = mainObjectType.concat(dependentObjectType).concat("Related");

  var generated = {};
  var configTypes = [
    CARD_CONFIG,
    VIEW_CONFIG,
    FILTER_CONFIG,
    FILTER_FIELD_CONFIG,
    TAB_CONFIG,
    MENU_ITEM,
  ];
  configTypes.forEach((configType) => {
    generated[configType] = [];
  });

  /**
   * CardConfig
   */
  var card = {
    viewConfig: parentViewConfig.subViewConfig,
    cardConfig: cardName,
    subViewConfig: viewConfigName,
    title: "",
    position: 20,
    height: 0,
    flex: 12,
    auth: auth,
  };
  generated[CARD_CONFIG].push(card);

  /**
   * ViewConfig
   */
  var filterQueryRelated = queryName.concat("Query");
  var viewConfigList = {
    viewConfig: viewConfigName,
    objectType: dependentObjectType,
    type: viewType,
    subViewConfig: dependentObjectType.concat("FieldsDefault"),
    queryFilter: filterQueryRelated,
    auth: auth,
  };
  generated[VIEW_CONFIG].push(viewConfigList);

  /**
   * FilterConfig
   */

  var filterFields = queryName.concat("fields");
  var filterConfig = {
    filterConfigId: filterQueryRelated,
    filterFields: filterFields,
    title: dependentObjectType.concat("s"),
    defaultFilter: true,
    filterQuery: filterQueryRelated,
    auth: auth,
  };
  generated[FILTER_CONFIG].push(filterConfig);

  /**
   * FilterFields
   */
  var filterFieldsList = [];
  if (!isEmpty(payload.filterfieldsToCreate)) {
    filterFieldsList = createFilterFieldsfields(
      filterFields,
      payload.filterfieldsToCreate,
      auth,
    );
  } else if (dependentObjectType === ATTACHMENT) {
    filterConfig.online = true;
    filterFieldsList = createFilterFieldsForAttachments(
      filterFields,
      mainObjectType,
      auth,
    );
  } else if (dependentObjectType === MESSAGE_MAIN) {
    filterConfig.online = true;
    viewConfigList.title = "Comments";
    filterFieldsList = createFilterFieldsForMessage(
      filterFields,
      mainObjectType,
      auth,
    );
  }

  generated[FILTER_FIELD_CONFIG] = filterFieldsList;
  await saveGeneratedConfigs(context, generated, configTypes);
};

export const createCardWithTimeObjectType = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
  payload,
) => {
  var parentViewConfig = object;
  var dependentObjectType = payload.dependentObjectType;
  var mainObjectType = parentViewConfig.objectType;
  var viewType = payload.viewType;
  var auth = parentViewConfig.auth;
  var cardName = mainObjectType
    .concat(dependentObjectType)
    .concat("TimeLineCard");
  var viewConfigName = mainObjectType
    .concat(dependentObjectType)
    .concat(viewType)
    .concat("TimeLineView");

  var generated = {};
  var configTypes = [CARD_CONFIG, VIEW_CONFIG];
  configTypes.forEach((configType) => {
    generated[configType] = [];
  });

  /**
   * CardConfig
   */
  var card = {
    viewConfig: parentViewConfig.subViewConfig,
    cardConfig: cardName,
    subViewConfig: viewConfigName,
    title: "",
    position: 100,
    height: 0,
    flex: 12,
    auth: auth,
  };
  generated[CARD_CONFIG].push(card);

  /**
   * ViewConfig
   */
  var viewConfigList = {
    viewConfig: viewConfigName,
    objectType: dependentObjectType,
    type: viewType,
    title: "Timeline",
    subViewConfig: dependentObjectType,
    auth: auth,
  };
  generated[VIEW_CONFIG].push(viewConfigList);
  console.log("Generated", generated);
  await saveGeneratedConfigs(context, generated, configTypes);
};

export const createCardWithStepperType = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
  payload,
) => {
  var parentViewConfig = object;
  var dependentObjectType = payload.dependentObjectType;
  var mainObjectType = parentViewConfig.objectType;
  var viewType = payload.viewType;
  var stepperFields = dependentObjectType.concat("FieldsStepper");
  var auth = parentViewConfig.auth;
  var cardName = mainObjectType
    .concat(dependentObjectType)
    .concat("StepperCard");
  var viewConfigName = mainObjectType
    .concat(dependentObjectType)
    .concat(viewType)
    .concat("StepperView");

  var generated = {};
  var configTypes = [CARD_CONFIG, VIEW_CONFIG, VIEW_FIELD];
  configTypes.forEach((configType) => {
    generated[configType] = [];
  });

  /**
   * CardConfig
   */
  var card = {
    viewConfig: parentViewConfig.subViewConfig,
    cardConfig: cardName,
    subViewConfig: viewConfigName,
    title: "",
    position: 5,
    height: 100,
    flex: 12,
    auth: auth,
  };
  generated[CARD_CONFIG].push(card);

  /**
   * ViewConfig
   */
  var viewConfigList = {
    viewConfig: viewConfigName,
    objectType: dependentObjectType,
    type: viewType,
    subViewConfig: stepperFields,
    auth: auth,
  };
  generated[VIEW_CONFIG].push(viewConfigList);

  /**
   * ViewField
   */
  var field = "Status";
  var viewField = {
    fieldList: stepperFields,
    value: field,
    text: field,
    type: VALUE_LIST,
    auth: auth,
    with: 12,
    position: 10,
    size: 1,
    isList: true,
    isEdit: true,
    isListEdit: true,
    isDisplay: true,
    auth: auth,
  };
  generated[VIEW_FIELD].push(viewField);

  console.log("Generated", generated);

  await saveGeneratedConfigs(context, generated, configTypes);
};

export const createTabWithView = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
  payload,
) => {
  var generated = {};
  var configTypes = [VIEW_CONFIG, TAB_CONFIG];
  configTypes.forEach((configType) => {
    generated[configType] = [];
  });

  var subViewConfig = object.viewConfig.concat("tabs");
  var tabConfigName = object.viewConfig.concat("tab");
  var newView = object.viewConfig.concat("view");
  var viewConfigTab = {
    viewConfig: object.viewConfig,
    type: VIEW_TYPE_TABS,
    subViewConfig: subViewConfig,
    objectType: object.objectType,
    auth: object.auth,
  };

  var tabConfig = {
    viewConfig: subViewConfig,
    tabConfig: tabConfigName,
    position: 10,
    auth: object.auth,
    title: payload.tabName,
    subViewConfig: newView,
  };

  object.viewConfig = newView;

  generated[TAB_CONFIG].push(tabConfig);
  generated[VIEW_CONFIG].push(viewConfigTab);
  generated[VIEW_CONFIG].push(object);

  console.log("Generated", generated);
  await saveGeneratedConfigs(context, generated, configTypes);
};

/**********************************************
 * Save Views
 *********************************************/
const saveGeneratedConfigs = async (context, generated, configTypes) => {
  for (var i = 0; i < configTypes.length; i++) {
    var configType = configTypes[i];
    var configs = generated[configType];
    if (!isEmpty(configs) && Array.isArray(configs)) {
      for (var j = 0; j < configs.length; j++) {
        var config = configs[j];
        if (isEmpty(config.Guid)) {
          fillGuidFieldsForObject(config);
        }
        await context.dispatch("saveObjectForObjectType", {
          objectType: configType,
          object: config,
        });
      }
    }
  }
};

const createFilterFieldsfields = (filterFields, fields, auth) => {
  var newfilterFields = [];
  for (var i = 0; i < fields.length; i++) {
    var field = fields[i];
    if (!isEmpty(field.field)) {
      var newField = {
        filterFields: filterFields,
        field: field.field,
        comparator: "==",
        linkedObjectField: field.linkedObjectField,
        auth: auth,
      };
      newfilterFields.push(newField);
    }
  }
  return newfilterFields;
};

const createFilterFieldsForAttachments = (
  filterFields,
  mainObjectType,
  auth,
) => {
  var filterFieldParent = {
    filterFields: filterFields,
    field: "parent",
    comparator: "==",
    linkedObjectField: "Guid",
    auth: auth,
  };
  var filterFieldParentType = {
    filterFields: filterFields,
    field: "parentType",
    comparator: "==",
    value: mainObjectType,
    auth: auth,
  };

  var filterFieldAccount = {
    filterFields: filterFields,
    field: "account",
    comparator: "==",
    linkedObjectField: "Account",
    auth: auth,
  };

  return [filterFieldParent, filterFieldParentType, filterFieldAccount];
};

const createFilterFieldsForMessage = (filterFields, mainObjectType, auth) => {
  var filterFieldParent = {
    filterFields: filterFields,
    field: "ParentGuid",
    comparator: "==",
    linkedObjectField: "Guid",
    auth: auth,
  };
  var filterFieldParentType = {
    filterFields: filterFields,
    field: "ParentType",
    comparator: "==",
    value: mainObjectType,
    auth: auth,
  };

  return [filterFieldParent, filterFieldParentType];
};
