import * as Constants from "../store/constants";

/**
 * Chart Context
 */

export default {
  state: {},
  mutations: {},
  getters: {
    /*
      Get Chart Configs
    */
    getChartConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.CHART_CONFIG);
    },
    getChartConfigForChartId: (state, getters) => chartId => {
      return getters.getChartConfigs.filter(function(o) {
        return o.chartConfigId === chartId;
      });
    },
    /*
        ChartFieldConfigs
    */
    getChartFieldConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.CHART_FIELD_CONFIG);
    },
    getChartFieldsForChartId: (state, getters) => chartId => {
      return getters.getChartFieldConfigs.filter(function(o) {
        return o.chartId === chartId;
      });
    },
    getChartFieldsForChartIdAndType: (state, getters) => payload => {
      var chartId = payload.chartId;
      var type = payload.type;
      var resultArray = getters.getChartFieldConfigs.filter(function(o) {
        return o.chartId === chartId && o.type === type;
      });
      return resultArray;
    }
  },
  actions: {}
};
