<template>
  <v-card
    flat
    class="ma-0 pa-0 px-2 px-sm-4 transparent"
  >
    <v-card
      v-if="hasActions"
      flat
      class="ma-0 pa-0 transparent"
    >
      <v-row :class="getListClass">
        <v-btn
          v-for="(item, index) in getActions"
          :key="index"
          :ref="item.Guid"
          :dark="dark"
          elevation="0"
          block
          :class="getButtonClass"
          v-on:click="launchAction(item)"
        >

          <v-icon
            left
            v-if="!isEmpty(item.icon)"
          >{{ item.icon }}</v-icon>

          {{ item.title }}

        </v-btn>
      </v-row>
    </v-card>
  </v-card>
</template>
<script>
import Axios from 'axios';
import { mapGetters } from 'vuex';
import { API_BASE_URL } from '../../api/constants.js';
import { isEmpty, isEmptyObj, isTrue } from "../../api/utilities.js";
import { getAccessToken } from '../../store/authorizationContext.js';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from '../../store/constants.js';

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    dark: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    nowrap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null,
      fields: null
    };
  },
  computed: {
    ...mapGetters(['getCurrentEmployeeGuid']),
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
    },
    getActions() {
      console.log(this.getViewConfig.subViewConfig,
        this.currentObject,
        this.getObjectConfig,
        this.getViewConfig)
      var actions = this.$store.getters.getActionListForActionConfig(
        this.getViewConfig.subViewConfig,
        this.currentObject,
        this.getObjectConfig,
        this.getViewConfig
      );
      if (actions === undefined || actions === null) {
        return [];
      }
      return actions;
    },
    getButtonClass() {
      var otherclass = ""
      if (this.nowrap === true) {
        otherclass = " small-magin-x"
      }
      else {
        otherclass = "mymargin"
      }
      return this.color.concat(" ").concat(otherclass)
    },
    getListClass() {
      var otherclass = ""
      if (this.nowrap === true) {
        otherclass = "no-margin"
      }
      else {
        otherclass = "mypadding "
      }
      return "transparent ".concat(otherclass)
    },
    hasActions() {
      return this.getActions.length > 0;
    },
    getDemoActions() {
      var actions = [];
      var action = {
        title: "Generate SplitCards",
        method: "actionCreateSplitCardConfigs"
      };
      actions.push(action);
      var action = {
        title: "send Operation",
        method: "sendOperation"
      };
      actions.push(action);
      var action = {
        title: "Create Fields",
        method: "createCoreFields"
      };
      actions.push(action);
      return actions;
    },
    title() {
      return this.getViewConfig.title;
    },
    currentObject() {
      if (!isEmptyObj(this.object)) {
        return this.object
      }
      return this.$store.getters.getCurrentObject;
    }
  },
  methods: {

    isEmpty,
    launchAction(item) {
      if (isTrue(item.isOnline)) {
        this.launchOnlineAction(item)
      } else {

        var rightCard = this.$refs[item.Guid];
        var rect = rightCard[0].$el.getBoundingClientRect()

        const width = 776
        var btnX = rect.left + (rect.width / 2)
        var btnY = rect.top + (rect.height / 2)
        var centerX = screen.width > 850 ? (screen.width / 2) - (width / 2) : 24
        var centerY = screen.height > 850 ? (screen.height / 2) - (width / 2) : 24
        var distX = (centerX - btnX) / 2
        var distY = (centerY - btnY) / 2
        var x = btnX + distX
        var y = btnY + distY
        var origin = { x, y }

        var objectConfig = this.$store.getters.getObjectConfigForType(
          this.getViewConfig.objectType
        );
        var payload = {
          action: item.method,
          objectConfig: objectConfig,
          object: this.currentObject,
          viewConfig: this.getViewConfig,
          options: { origin }
        };
        this.$store.dispatch("launchActionForObjectAndConfig", payload);
      }
    },
    async launchOnlineAction(item) {
      this.loading = true
      var config = {
        headers: {}
      };
      var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

      if (isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: error
        });
        return;
      }
      config.headers.Authorization = "Bearer ".concat(bearer);
      const objectConfig = this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
      const payload = {
        action: item.method,
        objectConfig,
        object: this.currentObject,
        employee: this.getCurrentEmployeeGuid
      }
      const url = `${API_BASE_URL}action`
      Axios.post(url, payload, config).then(response => {
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_SUCCESS,
          message: response.data && response.data.message
        });
        this.$store.dispatch("synchronizeAllData");
      }, error => {
        console.log('actionError', error)
        const message = error && error.response && error.response.data
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: message
        });
      })
    },
  }
};
</script>

<style scoped>
.no-margin {
  margin: 0px 0px 0px 0px !important;
  flex-wrap: nowrap
}

.mymargin {
  margin: 5px 0px 0px 0px !important;
}

.mypadding {
  margin: 15px 0px 15px 0px !important;
}

.small-magin-x {
  margin: 0px 10px 0px 0px;
  max-width: 30px;
}
</style>
