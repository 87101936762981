import { copyObject, isEmpty, isTrue } from "../../api/utilities.js";
import Vue from "vue";
import {
  ALERT_TYPE_ERROR,
  CARD_CONFIG,
  TAB_CONFIG,
  VIEW_FIELD,
} from "../../store/constants.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  if (!isEmpty(object.tabConfig) && isEmpty(object.subViewConfig)) {
    var subViewConfig = calculateSubViewConfigString(object);
    Vue.set(object, "subViewConfig", subViewConfig);
    if (isEmpty(object.objectType)) {
      var objectType = context.getCurrentSplitCardObjectType();
      Vue.set(object, "objectType", objectType);
    }
  }

  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  return true;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("after create in ViewField");
  Vue.set(object, "position", 100);

  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.auth)) {
    Vue.set(object, "auth", relatedObject.auth);
  }

  return object;
};

/**********************************************
 * Actions
 **********************************************/

const calculateSubViewConfigString = (object) => {
  var subViewConfig = object.tabConfig;
  let lastIndex = subViewConfig.lastIndexOf("Tab");
  if (lastIndex !== -1) {
    subViewConfig =
      subViewConfig.substring(0, lastIndex) +
      "Dynamic" +
      subViewConfig.substring(lastIndex + "Tab".length);
  }
  return subViewConfig;
};

export const moveUp = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  if (isEmpty(object.position) && isEmpty(object.viewConfig)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "Cannot move up because Position is not filled",
    });
    return;
  }
  var viewFieldsBefore = context.getters.getObjectsForFilter(
    TAB_CONFIG,
    (o) => {
      return o.viewConfig === object.viewConfig && o.position < object.position;
    },
  );
  if (viewFieldsBefore.length < 1) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "No Items before",
    });
    return;
  }
  viewFieldsBefore = viewFieldsBefore.sort((a, b) => {
    return b.position - a.position;
  });
  const oldObject = copyObject(object);
  var previousViewField = viewFieldsBefore[0];
  const oldpreviousViewField = copyObject(previousViewField);
  var oldPosition = object.position;
  Vue.set(object, "position", previousViewField.position);
  Vue.set(previousViewField, "position", oldPosition);
  await context.dispatch("saveObjectForObjectType", {
    objectType: TAB_CONFIG,
    object: object,
    oldObject,
  });
  await context.dispatch("saveObjectForObjectType", {
    objectType: TAB_CONFIG,
    object: previousViewField,
    oldObject: oldpreviousViewField,
  });
};

export const moveDown = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  if (isEmpty(object.position) && isEmpty(object.viewConfig)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "Cannot move up because Position is not filled",
    });
    return;
  }
  var viewFieldsBefore = context.getters.getObjectsForFilter(
    TAB_CONFIG,
    (o) => {
      return o.viewConfig === object.viewConfig && o.position > object.position;
    },
  );
  if (viewFieldsBefore.length < 1) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "No Items before",
    });
    return;
  }
  viewFieldsBefore = viewFieldsBefore.sort((a, b) => {
    return a.position - b.position;
  });
  const oldObject = copyObject(object);
  var previousViewField = viewFieldsBefore[0];
  const oldpreviousViewField = copyObject(previousViewField);
  var oldPosition = object.position;
  Vue.set(object, "position", previousViewField.position);
  Vue.set(previousViewField, "position", oldPosition);
  context.dispatch("saveObjectForObjectType", {
    objectType: TAB_CONFIG,
    object: object,
    oldObject,
  });
  context.dispatch("saveObjectForObjectType", {
    objectType: TAB_CONFIG,
    object: previousViewField,
    oldObject: oldpreviousViewField,
  });
};
