<template>
    <div class="relative">
        <v-text-field
            v-model="object[header.value]"
            :label="label"
            :readonly="readonly"
            :dense="dense"
            :solo="solo"
            :class="className"
            hide-details
            filled
            style="min-width: 250px;"
            @change="change"
        />
        <v-btn
            v-if="!readonly"
            class="absolute right top iconButton"
            style="right:8px; top:8px;"
            icon
            @click.native="openDialog"
        >
            <v-icon>hi-code-bracket-square-outline</v-icon>
        </v-btn>

        <html-editor
            v-model="object[header.value]"
            :dialog="dialog"
            :on-close="closeDialog"
        />
    </div>
</template>

<script>

// require componen
import HtmlEditor from './HtmlEditor.vue';
export default {
    components: {
        HtmlEditor,
    },
    props: {
        viewconfig: {
            type: String,
            default: ""
        },
        header: {
            type: Object,
            default: () => ({})
        },
        object: {
            type: Object,
            default: () => ({})
        },
        height: {
            type: String,
            default: "300px"
        },
        readonly: {
            type: Boolean,
            default: true
        },
        container: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        className: {
            type: String,
            default: ""
        },
        change: {
            type: Function,
            default: () => ({})
        },
        solo: {
            type: Boolean,
            default: true
        },
        filled: {
            type: Boolean,
            default: true
        },
        dense: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            dialog: false,
            code: '',
        }
    },
    methods: {
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        }
    },

}
</script>

<style>
/* required class */
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #111;
    color: #fff;

}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}
</style>