import * as Actions from "./constants.js";
import { SPLITCARD_THRESHOLD } from "./constants";
import router from "../router/index";
import {
  ACTIVE_TENANT,
  API_BASE_URL,
  BASE_URL,
  DEVELEPMENT,
  NODE_ENV,
  TENANT,
} from "../api/constants.js";
import axios from "axios";
import { isEmptyArray } from "../api/utilities.js";
import { loadTemplateScripts } from "../customLogic/customObject/customObjectScripts.js";

/**
 * App mutators
 */
export const SET_APP_STATE = "setAppState";

/**
 * App State
 */
export const APP_STATE_INIT = 0;
export const APP_STATE_STARTING = 1;
export const APP_STATE_LOGOUT = 2;
export const APP_STATE_ENTERED = 3;

export const DEVICE_TYPE_BROWSER = "browser";
export const DEVICE_TYPE_IOS = "ios";
export const DEVICE_TYPE_ANDROID = "Android";

export default {
  state: {
    appState: APP_STATE_INIT,
    deviceType: DEVICE_TYPE_BROWSER,
    deviceSize: 1,
    isMobile: false,
    appRouter: null,
    appLoginStatus: 0,
    packageVersion: process.env.PACKAGE_VERSION || "0",
    tenant:
      window &&
      window.localStorage.getItem(ACTIVE_TENANT) &&
      JSON.parse(window.localStorage.getItem(ACTIVE_TENANT)),
  },
  mutations: {
    setAppState(state, newDbState) {
      state.appState = newDbState;
    },
    setAppLoginStatus(state, loginStatus) {
      state.appLoginStatus = loginStatus;
    },
    setDeviceSize(state, width) {
      // console.log(width)
      if (width < SPLITCARD_THRESHOLD) {
        state.deviceSize = 1;
      } else {
        state.deviceSize = 2;
      }
    },
    setMobile(state, value) {
      // console.log(width)
      state.isMobile = value;
    },
    setDeviceType(state, deviceType) {
      state.deviceType = deviceType;
    },
    setAppRouter(state, router) {
      state.appRouter = router;
    },
    setTenant(state, tenant) {
      if (tenant) {
        state.tenant = tenant;
        window.localStorage.setItem(ACTIVE_TENANT, JSON.stringify(tenant));
        if (!isEmptyArray(tenant.templates)) {
          loadTemplateScripts(tenant.templates);
        }
      }
    },
    switchTenant(state, tenant) {
      if (tenant) {
        window.localStorage.setItem(ACTIVE_TENANT, JSON.stringify(tenant));
      } else {
        window.localStorage.removeItem(ACTIVE_TENANT);
      }
      location.reload();
    },
  },
  getters: {
    getVersion(state) {
      return state.packageVersion;
    },
    isDev() {
      return NODE_ENV === DEVELEPMENT;
    },
    getTenant(state) {
      return state.tenant;
    },
    getTenantIcon(state) {
      if (state.tenant && state.tenant.logo) {
        return state.tenant.logo;
      } else return `assets/tenants/swiftio/icon.png`;
    },
    getTenantSplash(state) {
      const name = state.tenant && state.tenant.name;
      if (name) {
        return `assets/tenants/${name}/splash.png`;
      } else return `assets/tenants/swiftio/splash.png`;
    },
    getAppState(state) {
      return state.appState;
    },
    getAppLoginStatus(state) {
      return state.appLoginStatus;
    },
    getShowApplication(state) {
      return state.appState >= APP_STATE_ENTERED;
    },
    getDeviceType(state) {
      return state.deviceType;
    },
    getDeviceSize(state) {
      return state.deviceSize;
    },
    getIsMobile(state) {
      return state.isMobile;
    },
    getAppRouter(state) {
      return state.appRouter;
    },
    getCurrentRoute(state) {
      if (state.appRouter !== undefined && state.appRouter !== null) {
        return state.appRouter.currentRoute.name;
      }
      return null;
    },
  },
  actions: {
    /***********************************
     * Init application
     **********************************/
    async initApplication({ dispatch, commit, getters }) {
      commit(SET_APP_STATE, APP_STATE_STARTING);
      await dispatch(Actions.OPEN_DATABASE);
      if (getters.getDbState < 0) {
        console.log("Error", getters.getDbError());
        return;
      }
      commit("setAppLoginStatus", 2);
      //Status 10;
      // Laad de main objecten.
      await dispatch(Actions.INIT_OBJECT_CONTEXT);
      await dispatch("loadOperationContainers");
      commit("setAppLoginStatus", 100);
      await dispatch(Actions.LOAD_UI);
      commit("setAppLoginStatus", 100);
      setTimeout(() => {
        dispatch(Actions.LOGIN_APPLICATION);
      }, 150);
    },

    async resetApplication({ dispatch, commit, getters }) {
      await dispatch(Actions.OPEN_DATABASE);
      await dispatch(Actions.DELETE_OBJECT_CONTEXT);
    },

    /***********************************
     * Init application
     **********************************/
    changeToRoute({ dispatch, commit, getters }, route) {
      if (getters.getCurrentRoute !== route) {
        router.push(route);
      }
    },

    /*********************************
    Logout / login.
    **********************************/
    loginApplication({ dispatch, commit, getters }) {
      commit(SET_APP_STATE, APP_STATE_ENTERED);
    },
    logoutApplication({ dispatch, commit, getters }) {
      commit("setAppLoginStatus", 0);
      commit(SET_APP_STATE, APP_STATE_LOGOUT);
    },
    /*********************************
    tenants
    **********************************/
    fetchTenants({ commit, getters }) {
      return new Promise(async (resolve, reject) => {
        const url = `${BASE_URL}/tenant?tenant=${TENANT}`;

        axios.get(url).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            this.loading = false;
            commit("showAlert", {
              type: Actions.ALERT_TYPE_ERROR,
              message: error,
            });
            reject(error);
          },
        );
      });
    },
  },
};
