import * as Constants from "./constants.js";
// import testObjects from "../testData/testObjects.js";
// import testData from "../testData/testData.js";
import coreObjects from "../CoreData/CoreData";

/**
 * Sync Mutations
 */
export const SET_SYNC_STATE = "setSyncState";

/**
 * Actions
 */
export const _INIT_FIELDS = "_initFields";
export const _INIT_OBJECTS = "_initObjects";
export const _INIT_CORE = "_initCore";
export const _INIT_CONFIGS = "initConfigs";
/**
 * App State
 */
export const SYNC_STATE_INIT = 0;
export const SYNC_STATE_STARTING = 1;
export const SYNC_STATE_SYNCING = 2;
export const SYNC_STATE_PARSING = 3;
export const SYNC_STATE_COMPLETED = 4;

export default {
  state: { syncState: SYNC_STATE_INIT },
  mutations: {
    setSyncState(state, syncState) {
      state.syncState = syncState;
    }
  },
  getters: {
    getSyncState(state) {
      return state.syncState;
    }
  },
  actions: {
    async initCore({ dispatch, commit, getters }) {
      //Should contain anything.
      var isInitial = true;
      var success = function() {
        console.log("database exist");
        isInitial = false;
      };
      //getters.getDatabase.tableExist(Constants.SYNCHRONISATION);
      if (isInitial === true) {
        await dispatch(_INIT_CONFIGS);
      }
    },
    /**
     * Synchronizes the fields and the objects. (Normally from the server, but temporary from a file.
     * @param {} param0
     */
    async initConfigs({ dispatch, commit, getters }) {
      await dispatch(_INIT_FIELDS);
      await dispatch(_INIT_OBJECTS);
      await dispatch(_INIT_CORE);
    },

    /*
      Init Core.
    */
    async _initCore({ dispatch, commit, getters }) {
      // Init object Config.
      // Here we need the synchronisation. It must be in the core. file.
      for (var i = 0; i < getters.getObjectConfigs.length; i++) {
        var objectConfig = getters.getObjectConfigs[i];
        if (coreObjects[objectConfig.objectType] !== undefined) {
          await objectConfig
            .saveAll(getters.getDatabase, coreObjects[objectConfig.objectType])
            .then(
              result => {
                // If completed we should save the synchronisation state.
              },
              error => {
                // If error we should save the synchronisation state.
                console.log("Error saving fields: ", error);
              }
            );
        }
      }
    },
    /**
     * Create Test fields. It should be loaded from the server and saved in the core.
     * @param {} param0
     */
    async _initFields({ dispatch, commit, getters }) {
      // We need to check if it must be synchronized.
      await getters.getFieldConfig
        .saveAll(getters.getDatabase, coreObjects.fields)
        .then(
          result => {
            // If completed we should save the synchronisation state.
          },
          error => {
            // If error we should save the synchronisation state.
            console.log("Error saving fields: ", error);
          }
        );
    },

    /**
     * Retrieve the objects from the system. If they are already up to date in the database it must not been done.
     * @param {} param0
     */
    async _initObjects({ dispatch, commit, getters }) {
      // We need to check if it must be synchronized.
      // If not we must start a synchronisation
      await getters.getObjectConfig
        .saveAll(getters.getDatabase, coreObjects.objects)
        .then(
          result => {
            // If completed we should save the synchronisation state.
          },
          error => {
            // If error we should save the synchronisation state.
            console.log("Error saving objects: ", error);
          }
        );
    }
  }
};
