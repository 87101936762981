<template>
  <v-container
    class="pa-0 ma-0"
    transparent
    flat
  >
    <v-row
      class="transparent px-5 py-1"
      align="center"
      no-gutters
      flat
    >
      <v-icon class="mr-2">hi-clock-outline</v-icon>
      <h2>
        Recent items
      </h2>
    </v-row>
    <v-list
      v-if="getRecentItems && getRecentItems.length > 0"
      class="px-0"
      dense
    >
      <!-- <v-tooltip
        v-for="item in getRecentItems"
        :key="item.key"
        :nudge-left="8"
        content-class="rounded-lg"
        left
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }"> -->
      <v-list-item
        v-for="item in getRecentItems"
        :key="item.key"
        tag="div"
        class="v-list-item--link rounded-lg px-3"
        style="overflow:hidden"
        @click.native.stop="navigateTo(item)"
      >
        <div v-if="getImage(item) !== null">
          <v-list-item-avatar size="32px">

          </v-list-item-avatar>
        </div>
        <div v-if="getImage(item) === null">
          <v-list-item-avatar size="32px">
            <v-icon
              color="menuItemColorRight"
              class="material-icons-sharp"
            >
              {{ getImageIcon(item) }}</v-icon>
          </v-list-item-avatar>
        </div>
        <v-list-item-content>
          <v-list-item-title :class="getLabelColorOfMenu(item)">
            <label :class="getColorOfMenu(item) + '--text'">
              {{ item.description }}
            </label>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- </template>
        <span class="text-body-2 font-weight-mediumBold">{{ item.description }}</span>
      </v-tooltip> -->
    </v-list>
    <div
      v-if="getRecentItems && getRecentItems.length === 0"
      class="pt-6 py-3"
    >
      <h5 class="text-center">
        No recent items
      </h5>
    </div>
  </v-container>
</template>

<script>
import * as Constants from "../../../store/constants.js";
import demo from "../../../demo/Images/demoImages.js";
import { isEmpty } from "../../../api/utilities";

export default {
  props: {
    isMini: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    demo
  }),
  computed: {
    getRecentItems() {
      return this.$store.getters.getNavigations;
    }
  },

  methods: {
    /**
     * Navigate to the recent Item.
     */
    navigateTo(item) {
      var object = this.$store.getters.getDataObjectForIdAndObjectType({ objectType: item.objectConfig.objectType, objectId: item.object.Guid })
      if (isEmpty(object)) {
        context.commit("showAlert", { type: ALERT_TYPE_ERROR, message: "No navigation for Recent object found" })
        return
      }
      this.$store.dispatch(Constants.SELECT_OBJECT, { splitCardId: item.navigationConfigId, object: object });
    },
    /**
     * First try to find the object of the item. By default we name this Image.
     */
    getImage(item) {
      if (item.object !== undefined && item.object.Image !== null) {
        var image = this.demo[item.object.Image];
        if (image !== undefined && image !== null) {
          return image;
        }
      }
      return null;
    },
    /**
     * If the Image is not loaded we will load the Icon of the objectType
     */
    getImageIcon(item) {
      return item.objectConfig.icon;
    },
    getColorOfMenu(item) {
      return "menuItemColorRight";
    },
    getLabelColorOfMenu(item) {
      return " primary--text ";
    }
  }
};
</script>
