import { isEmptyArray } from "../../../api/utilities";

export default (fields, viewConfig) => {
  return `
  <v-card >
      ${getTitle(fields, viewConfig)}
      ${getSubTitles(fields)}
  </v-card>
  `;
};

const getTitle = (fields, viewConfig) => {
  if (fields && fields[0]) {
    return `
        <div class="text-subtitle-2 ${fields[0].viewFieldGuid &&
          "pointer"} primary--text" onclick="${viewConfig}('${
      fields[0].Guid
    }', '${fields[0].viewFieldGuid}');" >
                ${fields[0].title}
          </div>
        `;
  }
  return "";
};
const getSubTitles = fields => {
  const subtitles = JSON.parse(JSON.stringify(fields));

  if (!isEmptyArray(subtitles)) {
    subtitles.shift();
    var elements = "";
    subtitles.forEach(element => {
      elements += `<div class="text-subtitle-2 ${element.viewFieldGuid &&
        "pointer"} primary--text" >
            ${element.title}
      </div>
    `;
    });
    return elements;
  }
  return "";
};
