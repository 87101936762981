<template>
  <v-card
    class="ma-3 pa-3"
    flat
  >
    <div v-html="getHtmlString"></div>
  </v-card>
</template>

<script>
import { isEmpty } from '../../../api/utilities';
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    fieldsize: {
      type: String,
      default: "1"
    },
  },
  data() {
    return {
      show: true
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: this.fieldsize,
          list: this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    title() {
      if (!isEmpty(this.currentObject)) {
        return this.currentObject.Subject
      }
      "No title"
    },
    currentObject() {
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getMode() {
      return "display";
    },
    getHtmlString() {
      if (!isEmpty(this.currentObject) && !isEmpty(this.currentObject.Body)) {
        return this.currentObject.Body
      }
      return "<h3 style='color:red'>No template</h3>"
    },
  },
  mounted() { },
  methods: {

  }
};
</script>
