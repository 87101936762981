<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="hasSelectionView">
          <h2>Select {{ selectedField.text }}</h2>
          <listSelectionView
            :viewconfig="getViewConfigOfSelection"
            :selectionfunction="selectionFunction"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="cancelSelection()"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="configDialog.visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="configDialog.visible">
          <v-btn
            color="primary"
            icon
            @click="closeConfigDialog()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
          <viewConfigView
            :object="selectedField"
            :editmode="false"
            :dialog="configDialog"
            :key="componentKey"
            :viewconfig="viewConfigOfSelection"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <!--<configurationView
      v-if="isConfigMode"
      :viewconfig="getViewConfig"
      :viewconfigid="viewconfig"
      :type="getConfigType"/> -->
    <v-btn
      v-if="isConfigMode"
      :color="getColorForfield(header, 'isDisplay')"
      icon
      dense
      class=" configLight "
      @click.native="openConfigDialog(header)"
    >
      <v-icon>hi-eye</v-icon>
    </v-btn>
    <v-btn
      v-if="isConfigMode"
      :color="getColorForfield(header, 'isEdit')"
      class=" configLight "
      icon
      dense
      @click.native="openConfigDialog(header)"
    >
      <v-icon>hi-pencil-square-outline</v-icon>
    </v-btn>
    <div v-if="header.type === 'image'">
      <v-flex style="margin: 8px; text-align: center;">
        <v-card
          flat
          class="ma-3"
        >
          <v-avatar size="100px">
            <img
              :src="getImage(header, object)"
              crossorigin="anonymous"
              style="align:center;"
              alt="Avatar"
            >
          </v-avatar>
        </v-card>
      </v-flex>
    </div>
    <div
      v-if="header.type === 'input'"
      class="kingSize"
    >
      <v-text-field
        v-model="object[header.value]"
        :label="header.text"
        :readonly="isReadOnly"
        :class="getClass(header)"
      />
    </div>
    <div
      v-if="getTypeOfField(header) === 'idLink'"
      class="kingSize"
    >
      <v-text-field
        v-model="object[header.value]"
        :label="header.text"
        :readonly="isReadOnly"
        :class="getClass(header)"
        @click:append="clickField(header, object)"
      />
    </div>
    <div
      v-if="getTypeOfField(header) === 'display'"
      class="kingSize"
    >
      <v-text-field
        v-model="object[header.value]"
        :label="header.text"
        :class="getClass(header)"
        readonly
      />
    </div>
    <!-- <div v-if="header.type === 'switch'">
      <v-switch
        v-model="object[header.value]"
        :label="header.text"
        :readonly="isReadOnly"
        :class="getClass(header)"/>
    </div>-->

    <textEditor
      v-if="header.type === 'textEdit'"
      :object="object"
      :header="header"
      :viewconfig="viewconfig"
      :readonly="isReadOnly"
      :height="height"
      :class="getClass(header)"
    />
    <div
      v-if="getTypeOfField(header) === 'link'"
      class="kingSize"
    >
      <v-avatar
        v-if="getImage(header, object) !== null"
        size="36px"
      >
        <img
          :src="getImage(header, object)"
          crossorigin="anonymous"
          alt="Avatar"
        >
      </v-avatar>

      <v-text-field
        :value="getValue(header, object)"
        v-model="object[header.value]"
        :label="header.text"
        :readonly="true"
        :color="getColor(header)"
        :append-icon="getIcon(header)"
        :class="getClass(header)"
        @click="clickField(header, object)"
      />
    </div>
    <div
      v-if="getTypeOfField(header) === 'valueList'"
      class="kingSize"
    >
      <v-select
        :items="getValueListItems(header.dropdown)"
        v-model="object[header.value]"
        :label="header.text"
        :readonly="isReadOnly"
        :menu-props="{ bottom: true, offsetY: true }"
        :class="getClass(header)"
        item-text="descr"
        item-value="value"
      />
    </div>

    <div
      v-if="header.type === 'dateSelect'"
      class="kingSize"
    >
      <v-text-field
        v-model="object[header.value]"
        :label="header.text"
        :class="getClass(header)"
        prepend-icon="event"
        readonly
      />
    </div>

    <div
      v-if="header.type === 'timeSelect'"
      class="kingSize"
    >
      <v-text-field
        v-model="object[header.value]"
        :label="header.text"
        :class="getClass(header)"
        prepend-icon="event"
        readonly
      />
    </div>

    <!--<div v-if="header.type === 'dateSelect'">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y 
        
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="object[header.value]"
            :label="header.text"
            prepend-icon="event"
            readonly
            v-on="on"
          />
        </template>
        <v-date-picker 
          v-model="object[header.value]" 
          @input="menu2 = false"/>
      </v-menu>
    </div>

    <div v-if="header.type === 'timeSelect'">
      <v-menu
        :close-on-content-click="false"
        v-model="menu2"
        :nudge-left="40"
        transition="scale-transition"
        offset-y
        
      >
        <v-text-field
          slot="activator"
          :label="header.text"
          v-model="object[header.value]"
          :class="getClass(header)"
          prepend-icon="access_time"
          readonly
        />
        <v-time-picker
          v-model="object[header.value]" 
          format="24hr" 
          autosave/>
      </v-menu>
    </div>-->
  </div>
</template>

<script>
import demo from "../../demo/Images/demoImages.js";
import * as Constants from "../../store/constants.js";
import listSelectionView from "../splitCard/ListView";
import textEditor from "./textEditor";
import * as UTIL from "../../api/utilities.js";

export default {
  components: { listSelectionView, textEditor },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      menu2: false,
      configDialog: {
        visible: false
      },
      dialog: false,
      menu: false,
      date: null,
      viewConfigOfSelection: null,
      selectionFunction: null,
      selectedField: null,
      demo
    };
  },
  computed: {
    isReadOnly() {
      return UTIL.isTrue(this.readonly);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getViewConfigOfSelection() {
      return this.viewConfigOfSelection;
    },
    hasSelectionView() {
      return (
        this.viewConfigOfSelection !== undefined &&
        this.viewConfigOfSelection !== null
      );
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    }
  },
  methods: {
    getClass(header) {
      var cssClass = "";
      if (this.align === "right") {
        cssClass = cssClass + " alignRight";
      }
      if (this.isLink(header.type)) {
        cssClass = cssClass + " crmlink primary--text";
      }
      return cssClass;
    },
    isLink(type) {
      if (type === "link") {
        return true;
      }
      return false;
    },
    getTypeOfField(field) {
      return field.type;
    },
    getIcon(field) {
      if (field.type === Constants.LINK && this.isReadOnly === false) {
        return "open_in_new";
      }
      return "";
    },
    getImage(field, object) {

      if (
        (field.image !== undefined && field.image !== null) ||
        field.type === "image"
      ) {

        if (object.thumbnail) {
          return object.thumbnail
        } else if (object.image) {
          return object.image
        }
        return this.demo["noImage"]
      }
      return null;
    },
    getItems(header, object) {
      var objects = this.$store.getters.getDataObjectsForObjectType(
        header.relatedObjectType
      );
      return objects;
    },
    getDisplayRelatedObject(field) {
      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== null
      ) {
        return field.displayRelatedObject;
      }
      return "text";
    },
    getValueRelatedObject(field) {
      if (
        field.valueRelatedObject !== undefined &&
        field.valueRelatedObject !== null
      ) {
        return field.valueRelatedObject;
      }
      return "Guid";
    },
    getBooleanValue(field, object) {
      return UTIL.isTrue(object[field.value]);
    },
    getValue(field, object) {
      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== "" &&
        field.displayRelatedObject !== null
      ) {
        var payload = {
          objectType: field.relatedObjectType,
          objectId: object[field.value]
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );

        if (relatedObject !== undefined) {
          //Split
          return UTIL.splitFields(relatedObject, field.displayRelatedObject);
        } else {
          return "";
        }
      }
      if (field.type === "date") {
        var date = object[field.value];
        return UTIL.jsonDateToString(date);
      }
      return object[field.value];
    },
    getColor(field) {
      if (field.type === Constants.LINK) {
        return "primary--text";
      }
      return "normal";
    },
    getValueListItems(listCode) {
      var list = this.$store.getters.getValueListForId(listCode);
      return list;
    },
    clickField: function (field, object) {
      if (!this.isLink(field.type)) {
        return;
      }

      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    /*
      Dialogs
    */
    /**
      Config Dialog
    */
    getColorForfield(field, type) {
      if (UTIL.isTrue(field[type])) {
        return "primary";
      }
      return "gray";
    },
    openConfigDialog(field) {
      this.selectedField = field;
      this.viewConfigOfSelection = "ViewFieldviewConfig2";
      this.configDialog.visible = true;
    },
    closeConfigDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.configDialog.visible = false;
    },
    //Dialog 2
    openDialog(field, object) {
      this.viewConfigOfSelection = field.selectionView;
      this.selectedField = field;
      this.selectionFunction = this.objectSelected;
      this.dialog = true;
    },
    objectSelected(selectedObject) {
      this.object[this.selectedField.value] = selectedObject.objectType;
      this.closeDialog();
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.dialog = false;
    }
  }
};
</script>

<style>
.crmlink {
  cursor: pointer;
}

.crmlink input {
  color: var(--v-primary-base) !important;
}

/*
  Link to input
  color: #1976d2 !important
*/

.crmlink input:hover {
  cursor: pointer;
}
</style>
