/**
 * Alert Context
 */

import { ATTACHMENT_CATEGORY_SIGNATURE } from "./constants";

const initState = {
  showSignature: false,
  signatureObjectType: null,
  signatureObject: null,
  signatureCategory: ATTACHMENT_CATEGORY_SIGNATURE,
};

export default {
  state: initState,
  getters: {
    getSignatureObjectType: (state, getters) => {
      return state.signatureObjectType;
    },
    getSignatureObject: (state, getters) => {
      return state.signatureObject;
    },
    getShowSignature: (state, getters) => {
      return state.showSignature;
    },
    getSignatureCategory: (state, getters) => {
      return state.signatureCategory;
    },
  },
  mutations: {
    showSignature(state, payload) {
      const { objectType, object } = payload;
      state.signatureObjectType = objectType;
      state.signatureObject = object;
      state.showSignature = true;
      state.signatureCategory =
        payload.category || ATTACHMENT_CATEGORY_SIGNATURE;
    },
    hideSignature(state) {
      state.signatureObjectType = null;
      state.showSignature = false;
    },
  },
};
