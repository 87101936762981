<template>
  <v-card
    :color="color"
    :dark="hasParent"
    class="mx-3 pa-5"
    flat
  >
    <v-container
      fluid
      class="pa-0"
    >
      <v-row class="flex-wrap overflow-x">
        <v-btn
          v-if="!hasParent"
          icon
          @click.native="addButton()"
        >
          <v-icon>hi-plus</v-icon></v-btn>
        <v-btn
          v-if="hasParent"
          icon
          @click.native="showCurrentinsideBar()"
        >
          <v-icon>{{ getIconForParent }}</v-icon>
        </v-btn>
        <v-btn
          v-if="hasParent"
          icon
          @click.native="showChildInsideBar()"
        >
          <v-icon>{{ getIconForChild }}</v-icon></v-btn>

        <v-btn
          v-if="hasParent"
          icon
          @click.native="moveUp()"
        >
          <v-icon>hi-arrow-up-outline</v-icon></v-btn>

        <v-btn
          v-if="hasParent"
          icon
          @click.native="moveDown()"
        >
          <v-icon>hi-arrow-down-outline</v-icon></v-btn>
        <actionList
          :editmode="false"
          :object="getViewConfig"
          :dark="false"
          :nowrap="true"
          color="sideBarColor"
          viewconfig="ConfigurationViewActionList"
        />
      </v-row>

    </v-container>

  </v-card>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import detailsView from "./ConfigurationConfigView";
import actionList from "../ActionList.vue"
import * as Utilities from "../../../api/utilities";
import Vue from "vue";

export default {
  components: { detailsView, actionList },
  props: {
    color: {
      type: String,
      default: "clearcolor"
    },
    viewconfigid: {
      type: String,
      default: ""
    },
    viewconfig: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ""
    },
    parentconfig: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      viewConfigOfSelection: "",
      dialog: false,
      savingType: "",
      dialogTitle: "",
      componentKey: 0
    };
  },
  computed: {
    hasParent() {
      return !Utilities.isEmpty(this.parentconfig);
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.viewconfig);
    },
    getViewConfig() {
      console.log("My object", this.viewconfig)
      return this.viewconfig
    },

    getChildConfig() {
      return this.$store.getters.getViewConfigForID(
        this.parentconfig.subViewConfig
      );
    },

    getIconForChild() {
      if (
        Utilities.isEmpty(this.getChildConfig) ||
        Utilities.isEmpty(this.getChildConfig.type)
      ) {
        return "hi-rectangle-stack";
      }
      var listCodes = this.getValueListItems("viewConfigType");
      var listcode = listCodes.find(
        element => element.value === this.getChildConfig.type
      );
      if (listcode && listcode.icon) {
        return listcode.icon;
      }

      return "hi-rectangle-stack";
    },
    getIconForParent() {
      if (this.type === Constants.TAB_CONFIG) {
        return "hi-view-columns";
      }
      return "hi-window";
    }
  },
  methods: {
    getConfigIconForType(type) {
      var objectConfig = this.$store.getters.getObjectConfigForType(type);
      if (objectConfig !== undefined) {
        return objectConfig.objectConfig.icon;
      }
      return "";
    },

    getValueListItems(listCode) {
      var list = this.$store.getters.getValueListForId(listCode);
      return list;
    },

    getObjectConfigForType(type) {
      if (type === "Tabs") {
        return Constants.TAB_CONFIG;
      } else if (type === "Dynamic") {
        return Constants.CARD_CONFIG;
      } else if (type === undefined) {
        return Constants.VIEW_CONFIG;
      } else if (type === "List") {
        return "ListConfig";
      } else if (type === "Chart") {
        return Constants.CHART_CONFIG;
      }
      return type;
    },
    /**
     * Get Tab Config
     */
    getConfigForType(type) {
      var config = this.$store.getters.getObjectConfigForType(type);
      var object = {
        viewConfig: this.viewconfig.subViewConfig
      };
      var payload = {
        config: config,
        object: object
      };
      return payload;
    },

    showChildInsideBar() {
      var viewConfigId = this.parentconfig.subViewConfig;
      this.showConfigurationInsideBar(
        this.getChildConfig,
        viewConfigId,
        Constants.VIEW_CONFIG
      );
    },
    showCurrentinsideBar() {
      if (this.type === Constants.TAB_CONFIG) {
        this.showConfigurationInsideBar(
          this.parentconfig,
          this.parentconfig.tabConfig,
          Constants.TAB_CONFIG
        );
      } else {
        this.showConfigurationInsideBar(
          this.parentconfig,
          this.parentconfig.cardConfig,
          Constants.CARD_CONFIG
        );
      }
    },
    moveUp() {
      var objectConfig = this.$store.getters.getObjectConfigForType(this.type);
      var payload = {
        action: "moveUp",
        objectConfig: objectConfig,
        object: this.viewconfig,
        viewConfig: null,
      };
      this.$store.dispatch("launchActionForObjectAndConfig", payload);
    },
    async moveDown() {
      var objectConfig = this.$store.getters.getObjectConfigForType(this.type);
      var payload = {
        action: "moveDown",
        objectConfig: objectConfig,
        object: this.viewconfig,
        viewConfig: null,
      };
      this.$store.dispatch("launchActionForObjectAndConfig", payload);
    },
    addButton() {
      var configId = "";
      var config = {
        viewConfig: this.viewconfig.subViewConfig
      };
      this.showConfigurationInsideBar(config, configId, this.type);
    },
    showConfigurationInsideBar(viewConfig, viewConfigId, type) {
      var selectedObject = null;
      if (viewConfig === undefined || viewConfig === null) {
        selectedObject = {
          viewConfig: viewConfigId
        };
      } else {
        selectedObject = viewConfig;
        if (
          viewConfig.viewConfig === undefined ||
          viewConfig.viewConfig === null
        ) {
          selectedObject.viewConfig = viewConfigId;
        }
      }
      if (Utilities.isEmpty(selectedObject.Guid)) {
        Vue.set(selectedObject, "Guid", Utilities.getGuid());
        var config = this.$store.getters.getObjectConfigForType(type);
        selectedObject = config.afterCreate(
          selectedObject,
          this.viewconfig,
          this.$store
        );
      }
      var payload = {
        configurationViewConfig: type.concat("ViewDetailsconfig"),
        configuration: selectedObject
      };
      this.$store.dispatch("goToConfiguration", payload);
    }
  }
};
</script>
