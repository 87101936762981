<template>
    <v-dialog
        v-model="getItemSelectionShowDialog"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
        <v-card>

            <v-container fluid>
                <v-row
                    no-gutters
                    class="px-2"
                >
                    <v-toolbar-title class="font-weight-mediumBold">Item Selection</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="iconButton"
                        elevation="0"
                        @click="cancel"
                        class="px-8  mr-2"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        elevation="0"
                        class="px-8 "
                        @click="save"
                    >
                        Save
                    </v-btn>
                </v-row>
            </v-container>
            <v-container fluid>
                <v-row>
                    <v-col
                        cols="8"
                        class="px-4"
                    >
                        <div
                            v-if="getCategories"
                            class="tileBackgroundColor rounded-xl  py-2"
                        >
                            <div class="relative">
                                <v-tabs
                                    :class="isSearch && 'no-pointer opaque-2'"
                                    v-model="selectedCategory"
                                    align-with-title
                                >
                                    <v-tabs-slider color="primary"></v-tabs-slider>

                                    <v-tab
                                        v-for="value in getCategories"
                                        :key="value"
                                    >
                                        {{ getCategoryTitle(value) }}
                                    </v-tab>

                                </v-tabs>
                                <div
                                    class="absolute"
                                    style="top:6px; right:8px;"
                                >
                                    <v-text-field
                                        v-if="(!isEmpty(searchField) || isSearchFull)"
                                        v-model="searchField"
                                        prepend-inner-icon="hi-magnifying-glass"
                                        label="Search"
                                        class="text-field-transparent search mr-2"
                                        dense
                                        solo
                                        style="min-width: 270px; margin-top: 0px; padding-top: 0;"
                                        single-line
                                        hide-details
                                        autofocus
                                        clearable
                                        @blur="blurSearch"
                                    />
                                    <v-btn
                                        v-if="(isEmpty(searchField) && !isSearchFull)"
                                        class="iconButton  mx-1"
                                        icon
                                        @click="toggleSearch"
                                    >
                                        <v-icon class="configLight">hi-magnifying-glass</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                            <v-container
                                v-if="isSearch"
                                class="py-6 transparent"
                            >
                                <v-row>
                                    <div
                                        class="pa-4"
                                        v-for="selectItem in getSearchItems"
                                        :key="selectItem.Guid"
                                    >
                                        <v-card
                                            style="width: 200px; height: 100%;"
                                            class=" d-flex flex-column px-4 py-2 justify-space-between"
                                            rounded="xl"
                                            elevation="0"
                                        >
                                            <div class="text-body-2 text-center pa-4">{{
                                                selectItem &&
                                                selectItem[getItemSelectionSubjectField]
                                            }}</div>

                                            <v-row
                                                no-gutters
                                                align="center"
                                                justify="center"
                                                class="py-6 px-0"
                                                style="flex: 0; position: relative;"
                                            >
                                                <v-btn
                                                    icon
                                                    large
                                                    class="iconButton mx-1"
                                                    style="position: relative; z-index: 1;"
                                                    @click="() => { removeItem(selectItem.Guid) }"
                                                >
                                                    <v-icon small>hi-minus</v-icon>
                                                </v-btn>
                                                <v-spacer />
                                                <div style="position: absolute; left: 0;  width: 100%;">
                                                    <v-text-field
                                                        ref="amountRef"
                                                        :value="getSelectValue(selectItem)"
                                                        :class="getSelectValue(selectItem) > 0 && 'primary--text'"
                                                        class="text-field-transparent amount"
                                                        filled
                                                        solo
                                                        hide-details
                                                        :clearable="false"
                                                        @input="value => change(selectItem.Guid, value)"
                                                    />
                                                </div>
                                                <v-btn
                                                    icon
                                                    large
                                                    class="iconButton  mx-1"
                                                    style="position: relative; z-index: 1;"
                                                    @click="() => { addItem(selectItem.Guid) }"
                                                >
                                                    <v-icon small>hi-plus</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-card>
                                    </div>
                                </v-row>
                            </v-container>
                            <v-tabs-items
                                v-else
                                v-model="selectedCategory"
                                class="transparent"
                            >

                                <v-tab-item
                                    v-for="cat in getCategories"
                                    :key="cat"
                                    class="item-selection-wrap transparent"
                                >
                                    <v-container class="py-6 transparent">
                                        <v-row>
                                            <div
                                                class="pa-4"
                                                v-for="selectItem in getItemsForCategory(cat)"
                                                :key="selectItem.Guid"
                                            >
                                                <v-card
                                                    style="width: 200px; height: 100%;"
                                                    class=" d-flex flex-column px-4 py-2 justify-space-between"
                                                    rounded="xl"
                                                    elevation="0"
                                                >
                                                    <div class="text-body-2 text-center pa-4">{{
                                                        selectItem &&
                                                        selectItem[getItemSelectionSubjectField]
                                                    }}</div>

                                                    <v-row
                                                        no-gutters
                                                        align="center"
                                                        justify="center"
                                                        class="py-6 px-0"
                                                        style="flex: 0; position: relative;"
                                                    >
                                                        <v-btn
                                                            icon
                                                            large
                                                            class="iconButton mx-1"
                                                            style="position: relative; z-index: 1;"
                                                            @click="() => { removeItem(selectItem.Guid) }"
                                                        >
                                                            <v-icon small>hi-minus</v-icon>
                                                        </v-btn>
                                                        <v-spacer />
                                                        <div style="position: absolute; left: 0;  width: 100%;">
                                                            <v-text-field
                                                                ref="amountRef"
                                                                :value="getSelectValue(selectItem)"
                                                                :class="getSelectValue(selectItem) > 0 && 'primary--text'"
                                                                class="text-field-transparent amount"
                                                                filled
                                                                solo
                                                                hide-details
                                                                :clearable="false"
                                                                @input="value => change(selectItem.Guid, value)"
                                                            />
                                                        </div>
                                                        <v-btn
                                                            icon
                                                            large
                                                            class="iconButton  mx-1"
                                                            style="position: relative; z-index: 1;"
                                                            @click="() => { addItem(selectItem.Guid) }"
                                                        >
                                                            <v-icon small>hi-plus</v-icon>
                                                        </v-btn>
                                                    </v-row>
                                                </v-card>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <v-card
                            flat
                            rounded="xl"
                            style="height: 100%;"
                            class="sideBarColor px-4 py-2"
                        >
                            <v-card-title class="font-weight-mediumBold">
                                Selected
                            </v-card-title>
                            <v-list class="transparent">
                                <v-list-item
                                    v-for="item in selected"
                                    :key="item.Guid"
                                    class="card rounded-xl pl-6 mb-2"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-medium">{{ getTitle(item)
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <!-- <v-row
                                            no-gutters
                                            align="center"
                                            justify="space-between"
                                            style="min-width: 150px;"
                                        >
                                            <v-btn
                                                icon
                                                large
                                                class="iconButton  mx-1"
                                                :disabled="item[getItemSelectionAmountField] === 0"
                                                @click="() => { removeItem(item[getItemSelectionItemField]) }"
                                            >
                                                <v-icon small>hi-minus</v-icon>
                                            </v-btn>
                                            <div
                                                class="text-body-1 font-weight-bold"
                                                :class="item[getItemSelectionAmountField] && item[getItemSelectionAmountField] > 0 && 'primary--text'"
                                            >{{
                                                item[getItemSelectionAmountField]
                                            }}</div>
                                            <v-btn
                                                icon
                                                large
                                                class="iconButton  mx-1"
                                                @click="() => { addItem(item[getItemSelectionItemField]) }"
                                            >
                                                <v-icon small>hi-plus</v-icon>
                                            </v-btn>
                                        </v-row> -->
                                        <v-row
                                            no-gutters
                                            align="center"
                                            justify="space-between"
                                            style="min-width: 150px; position: relative;"
                                        >
                                            <v-btn
                                                icon
                                                large
                                                class="iconButton mx-1"
                                                style="position: relative; z-index: 1;"
                                                @click="() => { removeItem(item[getItemSelectionItemField]) }"
                                            >
                                                <v-icon small>hi-minus</v-icon>
                                            </v-btn>
                                            <v-spacer />
                                            <div style="position: absolute; left: 0;  width: 100%;">
                                                <v-text-field
                                                    ref="amountRef"
                                                    v-model="item[getAmountField]"
                                                    class="text-field-transparent amount primary--text"
                                                    filled
                                                    solo
                                                    hide-details
                                                    :clearable="false"
                                                />
                                            </div>
                                            <v-btn
                                                icon
                                                large
                                                class="iconButton  mx-1"
                                                style="position: relative; z-index: 1;"
                                                @click="() => { addItem(item[getItemSelectionItemField]) }"
                                            >
                                                <v-icon small>hi-plus</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

        </v-card>

    </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { newObjectWithGuid } from '../../api/objectApi';
import { isEmpty, isEmptyArray, isNumeric, isTrue } from '../../api/utilities';
import { VALUE_LIST_CONFIG } from '../../store/constants';

export default {
    components: {},
    data() {
        return {
            oldSelected: [],
            selected: [],
            selectedCategory: null,
            objectsToSave: [],
            searchField: '',
            isSearchFull: false
        }
    },
    computed: {
        ...mapGetters(["getItemSelectionShowDialog", "getItemSelectionViewConfig", "getItemSelectionObject", "getItemSelectionParentType", "getItemSelectionObjectType", "getItemSelectionFilter", "getItemSelectionCategory", "getItemSelectionCategories", "getItemSelectionItemField", "getItemSelectionFilterFunction", "getItemSelectionSubjectField", "getItemSelectionAmountField", "getItemSelectionCategoryListCode"]),
        getViewConfig() {
            return this.$store.getters.getViewConfigForID(this.getItemSelectionViewConfig);
        },
        getCategories() {
            if (!isEmptyArray(this.getItemSelectionCategories)) {
                return this.getItemSelectionCategories
            }
            if (this.getItems && this.getItemSelectionCategory) {
                const items = this.getItems.map(item => item[this.getItemSelectionCategory])
                if (items) {
                    const uniques = [... new Set(items)]
                    console.log('uniques', uniques)
                    return uniques
                }
            }
            return null
        },
        getItems() {
            const objects = this.$store.getters.getDataObjectsForObjectType(
                this.getItemSelectionObjectType
            );
            if (this.getItemSelectionFilter) {
                return this.$store.getters.getFilteredObjectsForFilterConfigId({
                    object: this.getItemSelectionObject,
                    objects,
                    filterId: this.getItemSelectionFilter
                })
            }
            return objects


        },
        getAmountField() {
            if (this.getItemSelectionAmountField && !isEmptyArray(this.getItemSelectionAmountField)) {
                return this.getItemSelectionAmountField[0]
            }
            return this.getItemSelectionAmountField
        },
        isSearch() {
            return !isEmpty(this.searchField) && this.searchField.length > 1
        },
        getSearchItems() {
            if (isEmpty(this.searchField)) {
                return null
            }
            var search = this.searchField.toLowerCase()
            return this.getItems.filter(item => {
                const value = item[this.getItemSelectionSubjectField].toLowerCase()
                if (value.search(search) > -1) {
                    console.log(value, search)
                }


                return value.search(search) > -1
            })
        }


    },
    watch: {
        getItemSelectionShowDialog(value) {
            if (isTrue(value)) {
                this.init()
            }
        },
        selected: {
            handler(newValue) {
                this.updateToSave(newValue)
            },
            deep: true
        }

    },
    methods: {
        isEmpty,
        toggleSearch() {
            this.isSearchFull = true
        },
        blurSearch() {
            if (this.searchField === '') {
                this.isSearchFull = false
            }
        },


        updateToSave(newValues) {
            if (isEmptyArray(newValues)) {
                return
            }
            let toSave = []
            const oldItems = new Map(this.oldSelected.map((obj) => [obj.Guid, obj]));
            for (let index = 0, l = newValues.length; index < l; index++) {
                const item = newValues[index];
                if (oldItems.has(item.Guid)) {
                    const oldItem = oldItems.get(item.Guid)
                    if (oldItem && item && JSON.stringify(oldItem) !== JSON.stringify(item)) {
                        toSave.push(item)
                    }
                } else {
                    if (item[this.getAmountField] === 0) {
                        newValues.splice(index, 1);
                    } else {
                        toSave.push(item)
                    }

                }
            }
            console.log('toSave', toSave)
            this.objectsToSave = toSave


        },
        getSelectValue(selectItem) {
            const selectedItem = this.selected.find(item => item[this.getItemSelectionItemField] === selectItem.Guid)
            if (selectedItem) {
                return selectedItem[this.getAmountField]
            }
            return 0
        },
        init() {
            this.oldSelected = structuredClone(this.getFilteredObjects())
            this.selected = structuredClone(this.getFilteredObjects())
        },
        change(itemGuid, value) {
            if (!isNumeric(value)) {
                return
            }
            const selectedItem = this.selected.find(item => item[this.getItemSelectionItemField] === itemGuid)
            if (selectedItem) {
                this.setItem(selectedItem, value)

            } else {
                this.createItem(itemGuid, value)
            }
        },
        addItem(itemGuid) {
            const selectedItem = this.selected.find(item => item[this.getItemSelectionItemField] === itemGuid)
            if (selectedItem) {
                console.log(this.getAmountField)
                const newAmount = selectedItem[this.getAmountField] ? Number(selectedItem[this.getAmountField]) + 1 : 1
                this.setItem(selectedItem, newAmount)
            } else {
                this.createItem(itemGuid)
            }

        },
        setItem(selectedItem, value) {
            if (!isEmptyArray(this.getItemSelectionAmountField)) {
                this.getItemSelectionAmountField.forEach(field => {
                    this.$set(selectedItem, field, value)
                })

            } else if (!isEmpty(this.getItemSelectionAmountField)) {
                this.$set(selectedItem, this.getItemSelectionAmountField, value)
            }
        },
        removeItem(itemGuid) {
            const selectedItem = this.selected.find(item => item[this.getItemSelectionItemField] === itemGuid)
            if (selectedItem) {
                const newAmount = selectedItem[this.getAmountField] ? Number(selectedItem[this.getAmountField]) - 1 : 0
                this.setItem(selectedItem, newAmount)
            }

        },
        createItem(itemGuid, value) {
            var config = this.$store.getters.getObjectConfigForType(
                this.getViewConfig.objectType
            );
            let newObject = newObjectWithGuid()
            var relatedObject = {
                object: this.getItemSelectionObject,
                viewConfig: this.getViewConfig
            };
            newObject = config.afterCreate(newObject, relatedObject, this.$store);
            if (!isEmptyArray(this.getItemSelectionAmountField)) {
                this.getItemSelectionAmountField.forEach(field => {
                    newObject[field] = value || 1
                })

            } else if (!isEmpty(this.getItemSelectionAmountField)) {
                newObject[this.getItemSelectionAmountField] = value || 1
            }

            newObject[this.getItemSelectionItemField] = itemGuid
            this.selected.push(newObject)
        },
        getTitle(item) {
            if (this.getItemSelectionSubjectField) {
                const getItem = this.$store.getters.getDataObjectForIdAndObjectType({
                    objectType: this.getItemSelectionObjectType,
                    objectId: item[this.getItemSelectionItemField]
                })
                if (getItem) {
                    console.log('getItem[this.getItemSelectionSubjectField]', getItem[this.getItemSelectionSubjectField])
                    return getItem[this.getItemSelectionSubjectField]
                }
            }
            return item[this.getItemSelectionItemField]
        },
        getCategoryTitle(value) {
            if (this.getItemSelectionCategoryListCode) {
                const code = this.getItemSelectionCategoryListCode
                const filter = (item) => {
                    return item.valueList == code && item.value == value
                }
                const getItem = this.$store.getters.getObjectsForFilter(
                    VALUE_LIST_CONFIG,
                    filter
                )
                if (getItem && getItem[0]) {
                    return getItem[0].descr
                }
            }
            return value

        },
        getItemsForCategory(cat) {
            if (this.getItems) {
                const items = this.getItems.filter(item => item[this.getItemSelectionCategory] == cat)
                if (items) {
                    return items
                }
            }

            return []
        },
        save() {
            for (let index = 0, l = this.objectsToSave.length; index < l; index++) {
                const object = this.objectsToSave[index];
                const oldObject = this.oldSelected.find(item => item.Guid === object.Guid)
                const payload = {
                    object,
                    objectType: this.getViewConfig.objectType,
                    oldObject
                }
                this.$store.dispatch("saveObjectForObjectType", payload);
            }
            this.$store.commit('hideItemSelectionDialog')
        },
        cancel() {
            this.$store.commit('hideItemSelectionDialog')
        },
        getNeedSaveObjects() {

        },
        getObjects() {
            var items = this.$store.getters.getDataObjectsForObjectType(
                this.getItemSelectionParentType
            );
            return items;
        },
        getFilteredObjects() {
            // if (this.isOnline) {
            //   return this.items
            // }
            var objects = this.getObjects();
            var payload = {
                objects: objects,
                viewConfig: this.getViewConfig,
                object: this.getItemSelectionObject,
                isEdit: false
            };

            var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
                payload
            );
            //Todo filter
            return filteredObject;
        },

    }
};
</script>

<style lang="css" scoped>
.item-selection-wrap {
    height: calc(100vh - 160px);
    overflow: auto;
}
</style>
<style lang="css" >
.v-input.amount input {
    text-align: center !important;
    font-size: 1rem;
}
</style>