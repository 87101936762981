import * as Constants from "./constants.js";
import * as Api from "../api/ui.js";
import * as Api1 from "../api/objectApi.js";
import * as Utilities from "../api/utilities";
import Vue from "vue";
import { getTheme } from "../theme/themeDefault.js";

export const MINI_STATE = "sw_mm";
export const FIXED_STATE = "sw_mf";
export const RIGHT_DRAWER_STATE = "sw_rd";
export const RIGHT_DRAWER_SIZE = "sw_rds";
export const FLUID_UI = "sw_fl";
export const IS_DARK = "sw_id";
/**
 * UI mutators
 */
export const SET_APP_STATE = "setAppState";
export const SET_LEFT_MENU = "setLeftMenu";
export const TOGGLE_MINI = "toggleMini";
/**
 * UI Actions
 */
export const _LOAD_ROUTER = "_loadRouter";

/**
 * App State
 */
export const APP_STATE_INIT = 0;
export const APP_STATE_STARTING = 1;
export const APP_STATE_ENTERED = 2;

export default {
  state: {
    isMobileDevice:
      navigator.standalone ||
      window.matchMedia("(display-mode: standalone)").matches,
    isDark:
      window && window.localStorage.getItem(IS_DARK)
        ? JSON.parse(window.localStorage.getItem(IS_DARK))
        : window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches,
    leftMenu: [],
    isLeftMini:
      window && window.localStorage.getItem(MINI_STATE)
        ? JSON.parse(window.localStorage.getItem(MINI_STATE))
        : true,
    isLeftFixed:
      window && window.localStorage.getItem(MINI_STATE)
        ? JSON.parse(window.localStorage.getItem(MINI_STATE))
        : true,
    rightMenuState:
      window && window.localStorage.getItem(RIGHT_DRAWER_STATE)
        ? JSON.parse(window.localStorage.getItem(RIGHT_DRAWER_STATE))
        : false,
    rightFull:
      window && window.localStorage.getItem(RIGHT_DRAWER_SIZE)
        ? JSON.parse(window.localStorage.getItem(RIGHT_DRAWER_SIZE))
        : false,
    routes: [],
    fluid:
      window && window.localStorage.getItem(FLUID_UI)
        ? JSON.parse(window.localStorage.getItem(FLUID_UI))
        : true,
    currentThemeId: getTheme.myCurrentTheme //"belsol" //"blueLiteDarkBlue1"
  },
  getters: {
    isMobileDevice(state, getters) {
      return state.isMobileDevice;
    },
    isDark(state, getters) {
      return state.isDark;
    },
    getFluid(state, getters) {
      return state.fluid;
    },
    getMenuSettings(state, getters) {
      return getters.getDataObjectsForObjectType(Constants.MENU_SETTING);
    },
    getMenuSettingForMenuDrawer: (state, getters) => menuDrawer => {
      if (
        getters.getMenuSettings === undefined ||
        getters.getMenuSettings === null
      ) {
        return undefined;
      }
      var drawer = getters.getMenuSettings.find(function(o) {
        return (
          o.menuDrawer === menuDrawer && o.deviceType === getters.getDeviceSize
        );
      });
      if (Utilities.isEmpty(drawer)) {
        drawer = getters.getMenuSettings.find(function(o) {
          return o.menuDrawer === menuDrawer;
        });
      }
      return drawer;
    },
    /**
     * Right Drawer
     */
    getRightDrawer(state, getters) {
      return getters.getMenuSettingForMenuDrawer(Constants.MENU_DRAWER_RIGHT);
    },
    getRightMenuState(state) {
      return state.rightMenuState;
    },
    getRightFull(state, getters) {
      return state.rightFull;
    },
    getRightFixed(state, getters) {
      return state.isLeftFixed;
    },
    getRightDrawerStateSize(state, getters) {
      var rightDrawer = getters.getRightDrawer;
      if (rightDrawer.width === Constants.DRAWER_SIZE_BIG) {
        return Constants.DRAWER_STATE_SIZE_BIG;
      }
      return Constants.DRAWER_STATE_SIZE_NORMAL;
    },
    /**
     * Left Drawer
     */
    getLeftDrawer(state, getters) {
      return getters.getMenuSettingForMenuDrawer(Constants.MENU_DRAWER_LEFT);
    },
    getLeftMenu(state) {
      return state.leftMenu;
    },
    getLeftMini(state, getters) {
      return state.isLeftMini;
    },
    getLeftFixed(state, getters) {
      return state.isLeftFixed;
    },
    /**
     * Theme
     */
    getCurrentThemeId(state) {
      return state.currentThemeId;
    },
    getTheme(state, getters) {
      return getTheme(getters.getCurrentThemeId);
    },

    getDomains(state, getters) {
      var domains = getters.getDataObjectsForObjectType(Constants.DOMAIN);
      return domains;
    }
  },
  mutations: {
    toggleDark(state, value) {
      window.localStorage.setItem(IS_DARK, value);
      state.isDark = value;
    },
    setFluid(state, fluid) {
      window.localStorage.setItem(FLUID_UI, fluid);
      state.fluid = fluid;
    },
    setLeftMenu(state, menu) {
      state.leftMenu = menu;
    },
    setRightState(state, isOpen) {
      window.localStorage.setItem(RIGHT_DRAWER_STATE, isOpen);
      state.rightMenuState = isOpen;
    },
    setRightFull(state, isFull) {
      window.localStorage.setItem(RIGHT_DRAWER_SIZE, isFull);
      state.rightFull = isFull;
    },
    setCurrentThemeId(state, theme) {
      state.currentThemeId = theme;
    },
    setMenuActive(state, payload) {
      var menuItem = state.leftMenu.find(
        leftMenuItem => leftMenuItem.Guid === payload.menuItem.Guid
      );
      if (!Utilities.isEmpty(menuItem)) {
        if (
          !Utilities.isEmpty(payload.domain) &&
          Utilities.isTrue(payload.domain.isActive)
        ) {
          Vue.set(menuItem, "isActive", true);
        } else {
          Vue.set(menuItem, "isActive", false);
        }
      }
    },
    setMenuInActive(state, payload) {
      var menuItem = state.leftMenu.find(
        leftMenuItem => leftMenuItem.Guid === payload.menuItem.Guid
      );
      if (!Utilities.isEmpty(menuItem)) {
        Vue.set(menuItem, "isActive", false);
      }
    },
    toggleMini(state, payload) {
      const newMiniState = !state.isLeftMini;
      window.localStorage.setItem(MINI_STATE, newMiniState);
      state.isLeftMini = newMiniState;
    },
    toggleFixed(state, payload) {
      const newFixedState = !state.isLeftFixed;
      window.localStorage.setItem(MINI_STATE, newFixedState);
      state.isLeftFixed = newFixedState;
    }
  },
  actions: {
    async loadUI({ dispatch, commit, getters }) {
      await dispatch(Constants.LOAD_USER);
      await dispatch(Constants.LOAD_PROFILE);
      //await dispatch(Constants.FIND_ALL_DATA_OBJECTS);
      await dispatch(Constants.LOAD_LEFT_MENU);
      await dispatch("_loadFilters");
      await dispatch(_LOAD_ROUTER);
      // await dispatch(Constants.FIND_ALL_SPLIT_CARDS);
    },
    toggleFluid({ dispatch, commit, getters }) {
      commit("setFluid", !getters.getFluid);
    },
    async loadUser({ dispatch, commit, getters }) {
      // TODO
    },
    async loadProfile({ dispatch, commit, getters }) {
      // TODO
    },
    changeTheme({ dispatch, commit, getters }, theme) {
      commit("setCurrentThemeId", theme);
    },
    toggleDark({ dispatch, commit, getters }) {
      var newValue = !getters.isDark;
      if (newValue === null || newValue == undefined) newValue = false;
      commit("toggleDark", newValue);
    },
    async toggleMiniRight({ dispatch, commit, getters }) {
      var rightDrawer = getters.getRightDrawer;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      /*
      var payload = {
        object: rightDrawer,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });
      */
      var newObject = Utilities.copyObject(object);
      newObject.mini = Utilities.toggleBoolean(rightDrawer.mini);
      var payloadCurrent = {};
      payloadCurrent.oldObject = rightDrawer;
      payloadCurrent.newObject = newObject;
      payloadCurrent.config = objectConfig;
      await this.$store.dispatch("changeAndSaveObject", payloadCurrent);
    },
    toggleSizeRight({ dispatch, commit, getters }) {
      const isRightFull = getters.getRightFull;
      const newValue = Utilities.toggleBoolean(isRightFull);
      commit("setRightFull", newValue);
    },
    changeSizeDrawer({ dispatch, commit, getters }, drawer) {
      var rightDrawer = drawer;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payload = {
        object: rightDrawer,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });
    },
    toggleFixedRight({ dispatch, commit, getters }) {
      console.log("toggle fixed");
      var rightDrawer = getters.getRightDrawer;
      rightDrawer.temporary = Utilities.toggleBoolean(rightDrawer.temporary);
      //rightDrawer.temporary = !rightDrawer.temporary;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payload = {
        object: rightDrawer,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });
    },
    async toggleMini({ dispatch, commit, getters }) {
      var leftDrawer = getters.getLeftDrawer;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payloadCurrent = {};
      payloadCurrent.oldObject = leftDrawer;
      var newObject = Utilities.copyObject(leftDrawer);
      newObject.mini = Utilities.toggleBoolean(newObject.mini);
      payloadCurrent.newObject = newObject;
      payloadCurrent.config = objectConfig;
      await dispatch("changeAndSaveObject", payloadCurrent);

      /**var payload = {
        object: leftDrawer,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });*/
    },
    async toggleFixed({ dispatch, commit, getters }) {
      var leftDrawer = getters.getLeftDrawer;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payloadCurrent = {};
      payloadCurrent.oldObject = leftDrawer;
      var newObject = Utilities.copyObject(leftDrawer);
      newObject.permanent = Utilities.toggleBoolean(newObject.permanent);
      newObject.temporary = !newObject.permanent;
      payloadCurrent.newObject = newObject;
      payloadCurrent.config = objectConfig;
      await dispatch("changeAndSaveObject", payloadCurrent);

      /** 
      leftDrawer.permanent = utilities.toggleBoolean(leftDrawer.permanent);
      leftDrawer.temporary = !leftDrawer.permanent;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payload = {
        object: leftDrawer,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });
      */
    },

    /**
     * Build Left Menu
     * @param {} param0
     */
    async loadLeftMenu({ dispatch, commit, getters }) {
      var menus = getters.getDataObjectsForObjectType(Constants.MENU_ITEM);
      Api.buildMenuItems(menus);
      commit(SET_LEFT_MENU, menus);
      await dispatch("calculateMenu");
    },

    async calculateMenu({ dispatch, commit, getters }) {
      console.log("Calculate Menu");
      var menus = getters.getLeftMenu;
      var domains = getters.getDomains;
      var currentUser = getters.getCurrentUser;
      for (var i = 0; i < menus.length; i++) {
        var menuItem = menus[i];

        var foundDomain = domains.find(
          domain => domain.domain === menuItem.menuGroup
        );
        var payload = {
          menuItem: menuItem,
          domain: foundDomain
        };
        // commit("setMenuActive", payload);
      }
    },

    /**
     * Build the router based on the menu
     * @param {} param0
     */
    _loadRouter({ dispatch, commit, getters }) {
      var routes = Api.buildRoutes(getters.getLeftMenu);
      getters.getAppRouter.addRoutes(routes);
    },

    _loadFilters({ dispatch, commit, getters }) {
      var filters = getters.getDataObjectsForObjectType(
        Constants.FILTER_CONFIG
      );
      for (var i = 0; i < filters.length; i++) {
        var defaultFilter = filters[i];
        if (Utilities.isTrue(defaultFilter.defaultFilter)) {
          var payload = {
            filter: defaultFilter,
            viewConfig: defaultFilter.filterQuery
          };
          commit(Constants.SET_CURRENT_FILTER, payload);
        }
      }
    },

    toggleLeftDrawer({ dispatch, commit, getters }) {
      var leftDrawer = getters.getLeftDrawer;
      var newValue = Utilities.toggleBoolean(leftDrawer.drawer);
      var payload = {
        newValue: newValue,
        object: leftDrawer
      };
      dispatch("setValueForDrawer", payload);
    },
    toggleRightDrawer({ dispatch, commit, getters }) {
      var rightDrawer = getters.getRightMenuState;
      var newValue = Utilities.toggleBoolean(rightDrawer);
      commit("setRightState", newValue);
    },
    /**
     * Change the state of the splitCard: 0, 4, 8, 12
     * How to handle: not clear
     * @param {} param0
     * @param {*} object
     */
    setValueForDrawer({ dispatch, commit, getters }, payload) {
      console.log("Change state");
      var newObject = payload.object;
      //var newObject = Api1.copyObject(object);
      newObject.drawer = payload.newValue;
      var objectConfig = getters.getObjectConfigForType(Constants.MENU_SETTING);
      var payload = {
        object: newObject,
        objectConfig: objectConfig
      };
      commit(Constants.SET_OBJECT, { payload, getters });
      if (Utilities.isTrue(newObject.temporary) && newObject.drawer === false) {
        //Show this.
        setTimeout(function() {
          payload.object.drawer = Utilities.toggleBoolean(newObject.drawer);
          commit(Constants.SET_OBJECT, { payload, getters });
        }, 5);
      }
    }
  }
};
