import { FILTER_CONFIG, FILTER_FIELD_CONFIG } from "../../store/constants.js";
import { getGuid, isEmpty } from "../../api/utilities.js";
import Vue from "vue";
import { copyObject } from "../../api/objectApi.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  if (isEmpty(options) || options.fromSync !== true) {
    afterModify(objectConfig, object, null, context, options);
  }
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.object)) {
    Vue.set(object, "auth", relatedObject.object.auth);
  }
  return object;
};

/***
 * Update authorisation
 */
export const updateAllAuthorisation = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  var filterFields = context.getters.getDataObjectsForObjectType(
    FILTER_FIELD_CONFIG
  );
  var filterFieldsToAuth = filterFields.filter(o => {
    return isEmpty(o.auth);
  });
  for (var i = 0; i < filterFieldsToAuth.length; i++) {
    var filterField = filterFieldsToAuth[i];
    updateAuthorisation(
      objectConfig,
      filterField,
      viewconfig,
      context,
      options
    );
  }
};

/***
 * Update authorisation
 */
export const updateAuthorisation = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  if (!isEmpty(object.auth) || isEmpty(object.filterFields)) {
    return;
  }
  var filters = context.getters.getDataObjectsForObjectType(FILTER_CONFIG);
  var relatedFilters = filters.filter(o => {
    return o.filterFields === object.filterFields;
  });
  var auth = null;
  for (var i = 0; i < relatedFilters.length; i++) {
    var filter = relatedFilters[i];
    if (auth !== null && filter.auth !== auth) {
      console.log("Filter auth is different", filter, auth);
      filter = null;
      return;
    }
    auth = filter.auth;
  }

  if (relatedFilters.length === 0) {
    console.log("Deactivated");
    const oldObject = copyObject(object);
    Vue.set(object, "auth", "Deactivated");
    await context.dispatch("saveObjectForObjectType", {
      objectType: FILTER_FIELD_CONFIG,
      object: object,
      oldObject: oldObject
    });
  }

  if (!isEmpty(filter) && !isEmpty(filter.auth)) {
    const oldObject = copyObject(object);
    Vue.set(object, "auth", filter.auth);
    Vue.set(object, "authEdit", filter.auth);
    console.log("Updating to ", filter.auth);
    await context.dispatch("saveObjectForObjectType", {
      objectType: FILTER_FIELD_CONFIG,
      object: object,
      oldObject: oldObject
    });
  }
};
