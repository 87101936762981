/*
import noImage from "./Images/empty_avatar.jpg";
import event from "./Images/event.jpg";
import account from "./Images/account.jpg";
import task from "./Images/task.jpg";
import capsugel from "./Images/capsugel.jpg";
import connected from "./Images/connected.jpg";
import water_icon from "./Images/water_icon.png";
import pippa from "./Images/pippa.jpg";
import electricity from "./Images/electricity.jpg";
import ramon from "./Images/ramon.jpg";
import tess from "./Images/Tessenderlo.jpeg";
import sakura from "./Images/sakura.png";
*/

export default {
  /*
  Nicolas: "https://randomuser.me/api/portraits/women/11.jpg",
  Tom: "https://randomuser.me/api/portraits/men/10.jpg",
  Didier: "https://randomuser.me/api/portraits/men/11.jpg",
  Tom: "https://randomuser.me/api/portraits/men/12.jpg",
  Antonio: "https://randomuser.me/api/portraits/men/13.jpg",
  Russel: "https://randomuser.me/api/portraits/men/14.jpg",
  David: "https://randomuser.me/api/portraits/men/15.jpg",
  Denis: "https://randomuser.me/api/portraits/men/16.jpg",
  Jeroen: "https://randomuser.me/api/portraits/men/17.jpg",
  Tim: "https://randomuser.me/api/portraits/men/18.jpg",
  Giorgio: "https://randomuser.me/api/portraits/men/19.jpg",
  François: "https://randomuser.me/api/portraits/men/20.jpg",
  Jesus: "https://randomuser.me/api/portraits/men/21.jpg",
  Manuel: "https://randomuser.me/api/portraits/men/22.jpg",
  Sten: "https://randomuser.me/api/portraits/men/23.jpg",
  Mark: "https://randomuser.me/api/portraits/men/24.jpg",
  Wauthier: "https://randomuser.me/api/portraits/men/25.jpg",
  Ilse: "https://randomuser.me/api/portraits/women/12.jpg",
  Kerstin: "https://randomuser.me/api/portraits/women/11.jpg",
  expense: "https://photos.app.goo.gl/rxW9ZC5VNrK38h1W8",
  Rukhsar: "https://randomuser.me/api/portraits/women/11.jpg",
  John: "https://randomuser.me/api/portraits/women/11.jpg",
  Dylan: "https://randomuser.me/api/portraits/women/11.jpg",
  Koen: "https://randomuser.me/api/portraits/men/23.jpg",
  Marijke: "https://randomuser.me/api/portraits/women/14.jpg",
  Nick: "https://randomuser.me/api/portraits/men/26.jpg",
  MJTechnics: "https://randomuser.me/api/portraits/men/28.jpg",
  Karen: "https://randomuser.me/api/portraits/women/11.jpg",
  Virendra: "https://randomuser.me/api/portraits/women/13.jpg",
  noImage: noImage,

  "3M B":
    "https://pbs.twimg.com/profile_images/874913477902692352/TVOnZzb-_400x400.jpg",
  Tess: tess,
  noAccount:
    "https://cdn11.bigcommerce.com/s-gho61/stencil/31cc7cb0-5035-0136-2287-0242ac11001b/e/3dad8ea0-5035-0136-cda0-0242ac110004/images/no-image.svg",
  CAPS: capsugel,
  Q8: "https://www.element61.be/sites/default/files/Q8.png",
  Stoo:
    "https://www.foodtruckbestellen.be/Foodtruck_Pictures/8530/f214c597d2c05c5ea76e2a5fa09b9913stoof%2018.jpg",
  Saku: sakura,
  Bara:
    "https://i.pinimg.com/736x/45/5b/46/455b46d5c24ce289cfb969ee26e4f26c.jpg",
  BMW: "https://i.ebayimg.com/00/s/Njg3WDcyOA==/z/S1YAAOSwuaBcie0h/$_84.JPG",
  Engi: "https://jobs.engie.com/media/images/engie_logo_blue.png",
  Amaz: "https://www.pauwr.nl/wp-content/uploads/2019/07/Amazon-logo-1.png",
  Ness: "https://upload.wikimedia.org/wikipedia/commons/4/48/Nespresso.png",
  Prox:
    "https://media.glassdoor.com/sqll/1045153/proximus-squarelogo-1467114716028.png",
  Oran:
    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Orange_logo.svg/532px-Orange_logo.svg.png",
  event: event,
  account_balance: account,
  assignment_turned_in: task,
  account_circle: noImage,
  perm_identity: noImage,
  connected: connected,

  //Categories.
  water_icon: water_icon,
  pippa: pippa,
  electricity: electricity,
  ramon: ramon,
  HVS_5_1:
    "https://res.cloudinary.com/gpceurope/image/upload/w_553,c_fit,c_limit/v1620133911/cejqghwkua1jni1sxcap.png",
  Benq300:
    "https://www.innodura.nl/data/lp/images/zonnepanelen-monteren/zonnepanelen-monteren-bisol_zonnepanelen.jpg",
  Pana325:
    "https://limbotech.be/wp-content/uploads/2019/03/Panasonic-VBHN325SA17-2.jpg",
  belsol:
    "https://dim.mcusercontent.com/cs/ae4cacdb62b022cec8f05c4e5/images/9ba28bc4-be08-778a-7b4b-beb44e503400.jpg?w=1264&dpr=1"
  */
};
