<template>
  <v-container
    class="tileBackgroundColor splitCardHeight"
    fluid
  >
    <v-row
      no-gutters
      style="flex-wrap: nowrap;"
    >
      <v-container>
        <v-row align="center">
          <v-container
            :class="isMobile ? 'mt-0 main-container' : 'mt-16 pt-8'"
            class="ma-0 px-0 pb-12 transparent"
            flat
          >
            <h1 class="my-6">Your settings</h1>
            <v-tabs
              ref="_tabs"
              class="transparent"
              v-model="currentIndex"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab"
                :ripple="false"
              >
                {{ tab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="currentIndex"
              touchless
              class="transparent pa-0"
            >
              <v-tab-item>

                <profile />

              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Profile from './Profile.vue';

export default {
  components: {
    Profile
  },
  data() {
    return {
      dialog: false,
      currentIndex: 0,
      tabs: [
        'Profile',
      ]
    };
  },
  computed: {
    ...mapGetters(["getFluid"]),
    getShowConfigDialog() {
      return this.$store.getters.getShowConfigDialog;
    },
    isMobile() {
      return this.$store.getters.getDeviceSize === 1
    },
    isFluid() {
      return this.isMobile || this.getFluid
    },
  }
};
</script>

<style>
/* .main {
    padding-top: 0px !important;
  } */
</style>
