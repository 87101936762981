// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

/*
  Styles
*/
import "vuetify/dist/vuetify.min.css";
import "./css/customStyles.css";
import "./css/customStylesVuetify.css";
import "./css/customStylesContainer.css";
import "./css/customStylesWeather.css";
import "./css/customStylesEditor.css";
import "vue-resize/dist/vue-resize.css";
// import "@mdi/font/css/materialdesignicons.css";
import "./assets/heroicons-v1.0/heroicons.css";

//import 'material-design-icons-iconfont/dist/material-design-icons.css'

/*
  Libraries
*/
import Vue from "vue";
import Vuetify from "vuetify";
import * as theme from "./theme/themeDefault";
import { sync } from "vuex-router-sync";
import VueResize from "vue-resize";
import { VueEditor } from "vue2-editor";
import * as VueGoogleMaps from "vue2-google-maps";
import { VLazyImagePlugin } from "v-lazy-image";
// import intersectionObserver from "intersection-observer";
import VueObserveVisibility from "vue-observe-visibility";
import { VueCsvImportPlugin } from "vue-csv-import";
import moment from "vue-moment";
import VueSignaturePad from "vue-signature-pad";
import i18n from "./api/locales/i18n";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

Vue.config.devtools = true;
Vue.config.performance = true;

Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: "AIzaSyC7VaTP9e_KiN_OHQYa-wtans8FucoZv7g",
    libraries: "places",
  },
});

/*
  Own Modules
*/
import App from "./App";
import router from "./router";
Vue.use(router);
//import store from "./store";

import store from "./store";
/*

*/

import viewConfigView from "./components/splitCard/viewConfigView.vue";
import chartView from "./components/splitCard/ChartView.vue";
import configurationView from "./components/splitCard/ConfigurationViews/ConfigurationView.vue";
import detailsPage from "./components/splitCard/Details.vue";
import listView from "./components/splitCard/ListView.vue";
import GenericInput from "./components/components/GenericInput.vue";
import configurationButtons from "./components/splitCard/ConfigurationViews/ConfigurationButtons.vue";
import "vueditor/dist/style/vueditor.min.css";
/*
  Use
*/

Vue.config.productionTip = false;
Vue.use(VueResize);
Vue.use(VueEditor);
Vue.use(VLazyImagePlugin);
Vue.use(VueObserveVisibility);
Vue.use(Vuetify);
Vue.use(moment);
Vue.use(VueCsvImportPlugin);
Vue.use(VueSignaturePad);

/*
Components
*/
Vue.component("viewConfigView", viewConfigView);
Vue.component("chartView", chartView);
Vue.component("configurationView", configurationView);
Vue.component("detailsPage", detailsPage);
Vue.component("GenericInput", GenericInput);
Vue.component("configurationButtons", configurationButtons);
Vue.component("listView", listView);

//import "url(https://fonts.googleapis.com/css?family=Nunito&display=swap)";
import "./css/customStyles.css";

const opts = {
  theme: theme.getTheme(),
  icons: {
    iconfont: "hi",
    values: {
      prev: "hi hi-chevron-left-outline",
      next: "hi hi-chevron-right-outline",
      menu: "hi hi-bars-4-outline",
      close: "hi hi-x-mark-outline",
      clear: "hi hi-x-mark-outline",
      expand: "hi hi-chevron-down-outline",
      next: "hi hi-chevron-right-outline",
      prev: "hi hi-chevron-left-outline",
      delimiter: "hi hi-minus-circle-outline",
      dropdown: "hi hi-chevron-down",
      sort: "hi hi-arrow-long-down-outline",
      checkboxOn: "hi hi-check-circle",
      checkboxOff: "hi hi-check-circle-outline",
      alert: "hi hi-exclamation-circle-outline",
      error: "hi hi-exclamation-circle-outline",
      success: "hi hi-check-circle-outline",
      info: "hi hi-information-circle-outline",
      warning: "hi hi-exclamation-triangle-outline",
      checkboxIndeterminate: "hi hi-minus-circle-outline",
      plus: "hi hi-plus-outline",
      minus: "hi hi-minus-outline",
      subgroup: "hi hi-chevron-up",
      delete: "hi hi-x-mark-outline",
    },
  },
  options: {
    customProperties: true,
  },
};

sync(store, router);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  i18n,
  vuetify: new Vuetify(opts),
  components: { App },
  template: "<App/>",
  head: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover",
      },
    ],
  },
});

Vue.config.devtools = process.env.NODE_ENV === "development";
Vue.config.performance = true;
