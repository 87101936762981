import {
  ATTACHMENT,
  MESSAGE_MAIN,
  VIEW_COMMENT_TILE,
  VIEW_TYPE_ATTACHEMENT_UPLOADER,
  VIEW_TYPE_CALENDAR_TILE,
  VIEW_TYPE_DYNAMIC,
  VIEW_TYPE_LIST,
  VIEW_TYPE_STEPPER,
  VIEW_TYPE_TABS,
  VIEW_TYPE_TIMELINE,
} from "../../store/constants.js";
import { isEmpty } from "../../api/utilities.js";
import Vue from "vue";
import {
  createCardWithStepperType,
  createCardWithTimeObjectType,
  createCardWithViewForObjectType,
  createTabWithView,
} from "./coreObjectLib/viewGeneratorLib.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("AfterModify  in ViewConfig");
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  //Default name if empty
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options,
) => {
  console.log("after create in ViewConfig");
  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.auth)) {
    Vue.set(object, "auth", relatedObject.auth);
  }

  return object;
};

/**---------------------------------------------------------------
* Get Fields
---------------------------------------------------------------*/
export const getFieldsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  fields,
  context,
  options,
) => {
  console.log("Get fields ViewConfig");

  return fields;
};

/**---------------------------------------------------------------
* Get Tabs
---------------------------------------------------------------*/
export const getTabsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options,
) => {
  console.log("Get Tabs ViewConfig", tabs);

  for (var i = 0; i < tabs.length; i++) {
    var tab = tabs[i];

    if (tab.tabConfig === "ChartFieldConfigViewConfiglistconfig") {
      if (object.type === "Chart") {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }
    if (tab.tabConfig === "TabConfigViewConfiglistconfig") {
      if (object.type === "Tabs") {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }

    //Cards
    if (tab.tabConfig === "CardConfigViewConfiglistconfig") {
      if (object.type === "Dynamic") {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }

    //Filter Config only for Chart an List
    if (tab.tabConfig === "FilterConfigViewConfiglistconfig") {
      if (
        object.type === "Chart" ||
        object.type === "List" ||
        object.type === "Cart" ||
        object.type === "TileList" ||
        object.type === "Board" ||
        object.type === "detailList" ||
        object.type === "AttachmentUploaderList" ||
        object.type === "Map" ||
        object.type === "CommentTile" ||
        object.type === VIEW_TYPE_CALENDAR_TILE ||
        object.type === "MapInteractive"
      ) {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }

    //ViewFields
    if (tab.tabConfig === "ViewFieldViewConfiglistconfig") {
      if (
        object.type === "Details" ||
        object.type === "detailList" ||
        object.type === "Map" ||
        object.type === "MapInteractive" ||
        object.type === "List" ||
        object.type === "Cart" ||
        object.type === "Board" ||
        object.type === "Image" ||
        object.type === "Stepper" ||
        object.type === "Stepper" ||
        object.type === "AttachmentUploaderList" ||
        object.type === "quotationSolar"
      ) {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }

    //Actions
    if (tab.tabConfig === "ViewConfigActionsTab") {
      if (object.type === "Action") {
        tab.isHidden = false;
      } else {
        tab.isHidden = true;
      }
    }
  }

  return tabs;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options,
) {
  if (
    action === "createAttachmentView" ||
    action === "createMessageView" ||
    action === "createLinkedView" ||
    action === "createChangeHistoryView" ||
    action === "setViewInTab" ||
    action === "createStepperView"
  ) {
    if (object.type !== VIEW_TYPE_DYNAMIC) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "View Type is not correct",
        });
      }
      return false;
    }
  }
  return true;
}

/***
 * Create AttachmentView
 */
export async function createAttachmentView(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var payload = {
    dependentObjectType: ATTACHMENT,
    viewType: VIEW_TYPE_ATTACHEMENT_UPLOADER,
  };
  createCardWithViewForObjectType(
    objectConfig,
    object,
    viewconfig,
    context,
    options,
    payload,
  );
}

/**
 * Create message View
 */
export async function createMessageView(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var payload = {
    dependentObjectType: MESSAGE_MAIN,
    viewType: VIEW_COMMENT_TILE,
  };
  createCardWithViewForObjectType(
    objectConfig,
    object,
    viewconfig,
    context,
    options,
    payload,
  );
}

/**
 * Create message View
 */
export async function createChangeHistoryView(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var payload = {
    dependentObjectType: object.objectType,
    viewType: VIEW_TYPE_TIMELINE,
  };
  createCardWithTimeObjectType(
    objectConfig,
    object,
    viewconfig,
    context,
    options,
    payload,
  );
}

/**
 * Create message View
 */
export async function createStepperView(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var payload = {
    dependentObjectType: object.objectType,
    viewType: VIEW_TYPE_STEPPER,
  };
  createCardWithStepperType(
    objectConfig,
    object,
    viewconfig,
    context,
    options,
    payload,
  );
}

/**
 * Create message View
 */
export async function createLinkedView(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var createViewObject = {
    MainObject: object.objectType,
    relatedObjectType: "",
    MainObjectField: "Guid",
    TargetObjectField: "",
    parentViewConfig: object,
    viewType: VIEW_TYPE_LIST,
  };

  var viewConfig = "CreateViewConfigActionDialog";
  var payload = {
    viewConfig,
    object: createViewObject,
    origin: options.origin,
  };

  context.commit("showActionDialog", payload);
}

export const createLinkedViewConfirm = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) => {
  var payload = {
    dependentObjectType: object.relatedObjectType,
    viewType: object.viewType,
    filterfieldsToCreate: [
      {
        field: object.TargetObjectField,
        comparator: "==",
        linkedObjectField: object.MainObjectField,
      },
    ],
  };

  await createCardWithViewForObjectType(
    objectConfig,
    object.parentViewConfig,
    viewconfig,
    context,
    options,
    payload,
  );

  context.commit("hideActionDialog", payload);
};

/**
 * Create message View
 */
export async function setViewInTab(
  objectConfig,
  object,
  viewconfig,
  context,
  options,
) {
  var payload = {
    tabName: "List",
    viewType: VIEW_TYPE_TABS,
  };
  createTabWithView(
    objectConfig,
    object,
    viewconfig,
    context,
    options,
    payload,
  );
}
