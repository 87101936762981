import Vue from "vue";
import Router from "vue-router";
import Home from "../components/components/Home.vue";
import Account from "../components/mainComponent/Account.vue";
import Tenant from "../router/tenant/createTenant.vue";
import VerifyTenant from "../router/tenant/verifyTenant.vue";
import Splash from "../components/components/Splash.vue";
import GoogleCallback from "../components/mainComponent/GoogleCallback.vue";
import Unsubscribe from "../components/Unsubscribe.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/HomeMains",
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: Splash,
      meta: { hideLayout: true },
    },
    {
      path: "/account",
      name: "Account",
      component: Account,
    },
    {
      path: "/Themes",
      name: "Theme",
      component: Home,
    },
    {
      path: "/get-started",
      name: "getStarted",
      component: Tenant,
      meta: { hideLayout: true },
    },
    {
      path: "/verify",
      name: "VerifyTenant",
      component: VerifyTenant,
      meta: { hideLayout: true },
    },
    {
      path: "/google-callback",
      name: "googleCallback",
      component: GoogleCallback,
      meta: { hideLayout: true },
    },
    {
      path: "/unsubscribe",
      name: "unsubscribe",
      component: Unsubscribe,
      meta: { hideLayout: true },
    },
  ],
});
