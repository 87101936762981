import GenericObjectConfig from "./GenericObjectConfig.js";
import * as Constants from "../store/constants";

/**
 * This Class is part of the core. It represents an object that must be synchronised and saved to the db.
 * It contains:
 *  ID: The Id of the field.
 *  Name: This will be used to create the table.
 *  Description: The obj
 */
export default class ObjectConfig extends GenericObjectConfig {
  constructor(synchronisationObject, synchronisationFieldsConfig) {
    super(Constants.OBJECT_CONFIG, "ObjectConfigFields");
    this.objectConfig = {
      objectType: Constants.OBJECT_CONFIG,
      name: Constants.OBJECT_CONFIG,
      icon: Constants.OBJECT_CONFIG_ICON,
      skipTableCreate: false,
      syncGroup: Constants.SYNC_GROUP_CORE_OBJECTS,
      Guid: "3550c32a-7f9a-21d0-c65b-46194ccbbe20"
    };
  }
  /**
   * OverWritten as this a core object it is not loaded from the database.
   */
  initFields() {
    this.fieldsConfig = [
      {
        backendField: "Guid",
        field: "Guid",
        description: "Guid",
        isId: false,
        type: "string",
        edit: true,
        local: false,
        editMode: false,
        Guid: "5c3a539a-ed03-9e96-4f9d-eb5eb70857c6"
      },
      {
        field: "syncId",
        description: "syncId",
        isId: false,
        type: "string",
        edit: false,
        local: true,
        Guid: "9a7fe837-e306-a8c6-86f5-d47a93decb1f"
      },
      {
        backendField: "objectType",
        field: "objectType",
        description: "objectType",
        isId: true,
        type: "string",
        edit: true,
        Guid: "7be45f45-0475-5bac-1b98-1fb2d4cbc5ed"
      },
      {
        backendField: "name",
        field: "name",
        description: "name",
        isId: false,
        type: "string",
        edit: true,
        Guid: "4fedd847-9575-d218-c6f6-c2acf7e5d945"
      },
      {
        backendField: "icon",
        field: "icon",
        description: "icon",
        isId: false,
        type: "string",
        edit: true,
        Guid: "8fa7925d-c226-addc-6ce6-3d679065e2a9"
      },
      {
        backendField: "skipTableCreate",
        field: "skipTableCreate",
        description: "skipTableCreate",
        isId: false,
        type: "boolean",
        edit: true,
        Guid: "5ef90471-57c3-94e7-e9b5-290749e62680"
      },
      {
        backendField: "syncGroup",
        field: "syncGroup",
        description: "syncGroup",
        isId: false,
        type: "string",
        edit: true,
        Guid: "474baf90-09e7-0879-b11e-e06356244c85"
      },
      {
        backendField: "_id",
        field: "_id",
        description: "_id",
        isId: false,
        type: "string",
        edit: true,
        Guid: "b5532087-84f1-3446-e12e-61e575d55e4e"
      },
      {
        backendField: "auth",
        field: "auth",
        description: "auth",
        isId: false,
        type: "string",
        edit: true,
        Guid: "8ddd893f-c7ff-05a9-6b2e-8cb42bb1a038"
      },
      {
        backendField: "isDeleted",
        field: "isDeleted",
        description: "isDeleted",
        isId: false,
        type: "integer",
        edit: true,
        Guid: "49e2e7bc-033c-3609-2932-0fd95189bc32"
      },
      {
        backendField: "updatedAt",
        field: "updatedAt",
        description: "updatedAt",
        isId: false,
        type: Constants.STRING,
        edit: false,
        Guid: "4ff581bf-a792-5e8b-48ec-223f3ced39bb"
      },
      // Probably other fields need to be added.
    ];
  }
}
