<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row
      align="center"
      :class="isCompactView ? 'px-5' : 'px-6'"
      class="py-6 flex-nowrap"
    >

      <div
        :style="isCompactView && 'overflow: auto;flex-shrink: 0.1;'"
        class="py-0 px-0 pl-3"
      >

        <v-select
          v-model="selectedFilter"
          :items="getFilters"
          item-text="title"
          item-value="filterConfigId"
          class="noline filterTitle"
          hide-details
          return-object
          offset-y
          @change="onFilterChange"
        >
          <template v-slot:prepend-item>
            <v-subheader class="text-body-2 opaque-7">
              Filters
            </v-subheader>
          </template>
          <template
            v-slot:item="{ item, on, attrs }"
            v-if="showFilterButton"
          >
            <v-list-item
              v-bind="attrs"
              class="v-list-item--link"
            >


              <v-list-item-title
                class="pointer kingSize"
                style="height: 48px;line-height: 46px!important;"
                v-on="on"
              >
                {{ item.title }}
              </v-list-item-title>

              <v-list-item-action>
                <div class="d-flex pr-8">
                  <v-btn
                    v-if="isTrue(item.defaultFilter)"
                    icon
                  >
                    <v-icon color="primary ">hi-check-circle</v-icon>
                  </v-btn>


                  <v-tooltip
                    v-else
                    content-class="rounded-lg"
                    color="white"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          v-on="on"
                          @click="makeDefault(item)"
                        >

                          <v-icon
                            v-if="hover"
                            color="primary "
                          >hi-check-circle</v-icon>
                          <v-icon
                            v-else
                            class="opaque-7"
                          >hi-check-circle-outline</v-icon>

                        </v-btn>
                      </v-hover>
                    </template>
                    <span class="text-caption primary--text font-weight-mediumBold">Make default</span>
                  </v-tooltip>


                  <v-btn
                    icon
                    @click="removeDialog(item)"
                  >
                    <v-icon>hi-trash-outline</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-select>



      </div>
      <v-card
        v-if="showFilterButton"
        class="ma-0 pa-0 transparent"
        flat
      >
        <v-btn
          icon
          @click.native="showFilter()"
        >
          <v-badge
            :value="false"
            color="warning"
            dot
            overlap
          >
            <v-icon
              :color="getFilterColor"
              :class="`configLight ${displayFilter ? 'primary--text' : 'gray--text opaque-4'}`"
            >
              {{ displayFilter ? 'hi-funnel' : 'hi-funnel-outline' }}
            </v-icon>
          </v-badge>

        </v-btn>
      </v-card>
      <div
        v-if="hasCustomFilters && !getIsMobile"
        class="px-2"
        al
        no-gutters
      >
        <custom-filter-view
          v-if="hasCustomFilters && !getIsMobile"
          :object="object"
          :viewconfig="viewconfig"
          :customFilters="getCustomFilters"
        />

      </div>
      <v-spacer />



      <v-btn
        v-for="(pinned, index) in getOptionsPinnedActions"
        :key="index"
        class="primary lighten-5 primary--text mx-1"
        text
        @click="clickAction(pinned)"
      >
        {{ pinned.name }}
      </v-btn>

      <v-col
        class="py-0 px-1"
        cols="auto"
      >
        <slot name="extra-filters" />
      </v-col>

      <v-text-field
        v-if="showSearchBar && (!isEmpty(searchField) || isSearchFull)"
        v-model="searchField"
        prepend-inner-icon="hi-magnifying-glass"
        label="Search"
        class="text-field-transparent search mr-2"
        dense
        solo
        style="min-width: 270px; margin-top: 0px; padding-top: 0;"
        single-line
        hide-details
        autofocus
        clearable
        @input="onSearch"
        @blur="blurSearch"
      />
      <v-btn
        v-if="showSearchBar && (isEmpty(searchField) && !isSearchFull)"
        class="iconButton  mx-1"
        icon
        @click="toggleSearch"
      >
        <v-icon class="configLight">hi-magnifying-glass</v-icon>
      </v-btn>
      <ListViewActions
        v-if="showActions"
        :viewconfig="viewconfig"
        :object="object"
        :filter-items="filterItems"
        :issmall="isCompactView"
        :read-only="readOnly"
        :allow-create="allowCreate"
        :dropzone-element="dropzoneElement"
        :pdf-element="pdfElement"
        :refresh-data="refreshData"
      >
        <slot
          v-if="!getIsMobile"
          name="actions"
        />
      </ListViewActions>

    </v-row>

    <v-row
      v-if="getIsMobile"
      justify="center"
      align="center"
      class="kingSize pb-1"
      no-gutters
    >
      <slot name="actions" />

    </v-row>


    <v-row
      v-if="hasCustomFilters && getIsMobile"
      class="px-2"
      no-gutters
    >
      <custom-filter-view
        :object="object"
        :viewconfig="viewconfig"
        :customFilters="getCustomFilters"
      />

    </v-row>
    <v-row
      v-if="displayFilter"
      class="px-2"
      no-gutters
    >
      <filterConfigurationView
        :currentfilter="selectedFilter"
        :objecttype="getViewConfig.objectType"
        :field-list="getViewConfig.subViewConfig"
        viewconfig="FilterFieldConfigSmallList"
        @on-close="closeFilter"
      />

    </v-row>
    <confirm-dialog
      :open="deleteDialog"
      :body-text="`${itemToRemove && itemToRemove.title} filter will be removed`"
      title="Are you sure you want to delete this filter?"
      save-text="Delete"
      save-color="error"
      @on-cancel="deleteDialog = false"
      @on-save="removeFilter"
    />
  </v-container>
</template>
<script>
import * as Constants from "../../store/constants.js";
import filterConfigurationView from "./FilterConfigurationView";
import { isEmpty, getGuid, isTrue, copyObject } from "../../api/utilities.js";
import ListViewActions from "./ListViewActions.vue";
import ConfirmDialog from "../components/ConfirmDialog.vue";
import { EDIT_FILTER } from "../../store/authorizationObjectContext";
import CustomFilterView from './CustomFilterView.vue';
import { mapGetters } from 'vuex';

export default {
  components: { filterConfigurationView, ListViewActions, ConfirmDialog, CustomFilterView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ""
    },
    filterItems: {
      type: Array,
      default: null
    },
    isCompactView: {
      type: Boolean,
      default: true
    },
    onSearch: {
      type: Function,
      default: () => ('')
    },
    removeSearch: {
      type: Boolean,
      default: false
    },
    noActions: {
      type: Boolean,
      default: false
    },
    allowCreate: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    dropzoneElement: {
      type: String,
      default: null
    },
    pdfElement: {
      type: HTMLDivElement,
      default: null
    },
    refreshData: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      selectedFilter: {},
      oldSelectedFilter: null,
      dialog: false,
      displayFilter: false,
      searchField: '',
      isSearchFull: false,
      itemToRemove: null,
      deleteDialog: false,
      viewconfigFilter: 'FilterFieldConfigSmallList',
      stateLoaded: false
    };
  },
  computed: {
    ...mapGetters(['getIsMobile']),
    needSave() {
      var oldFilters = this.getOldFilteredObjects ? JSON.stringify(this.getOldFilteredObjects.map(this.mapItem)) : ''
      if (isEmpty(oldFilters)) return false
      var oldSorting = this.getOldSortingObjects ? JSON.stringify(this.getOldSortingObjects.map(this.mapItem)) : ''
      if (isEmpty(oldSorting)) return false
      var newFilters = this.getFilteredObjects ? JSON.stringify(this.getFilteredObjects.map(this.mapItem)) : ''
      var newSorting = this.getSortingObjects ? JSON.stringify(this.getSortingObjects.map(this.mapItem)) : ''
      return newFilters !== oldFilters || newSorting !== oldSorting
    },
    getFilteredObjects() {
      if (
        this.getCurrentObject === null ||
        this.selectedFilter.filterFields === undefined ||
        this.selectedFilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getFieldsForFilter(
        this.selectedFilter.filterFields
      );
    },
    hasCustomFilters() {
      console.log('this.getCustomFilter', this.getCustomFilters)
      return !isEmpty(this.getCustomFilters)
    },
    getOldFilteredObjects() {
      if (
        this.selectedFilter.filterFields === undefined ||
        this.selectedFilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getOldFieldsForFilter(
        this.selectedFilter.filterFields
      );
    },
    getSortingObjects() {
      if (
        this.selectedFilter.filterFields === undefined ||
        this.selectedFilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getSortingForFilter(
        this.selectedFilter.filterFields
      );
    },
    getOldSortingObjects() {
      if (
        this.selectedFilter.filterFields === undefined ||
        this.selectedFilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getOldSortingForFilter(
        this.selectedFilter.filterFields
      );
    },
    getViewConfigFilterConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfigFilter);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getCustomFilters() {
      return this.getOptions && this.getOptions.customFilters
    },
    getOptionsPinnedActions() {
      if (this.getOptions) {
        return this.getOptions.pinnedActions
      }
      return []
    },
    getFilterIsActive() {
      return this.$store.getters.getFilterActivatedForViewConfig(
        this.viewconfig
      );
    },
    getFilterColor() {
      if (this.getFilterIsActive === true) {
        return "primary";
      }
      return "";
    },

    /**
     * Retrieve the current Filters for the view config.
     */
    getFilterId() {
      return this.getViewConfig.queryFilter;
    },
    getFilters() {
      var filters = this.$store.getters.getFilterConfigsForFilterId(
        this.getFilterId
      );
      if (this.getFilterIsActive === true) {
        var newGuid = getGuid();
        var newFilter = {
          defaultFilter: "false",
          filterConfigId: newGuid,
          filterFields: newGuid + "Fields",
          title: "New Filter",
          filterQuery: this.getFilterId,
          isNew: 1
        };
        filters.push(newFilter);
      }
      return filters;
    },
    showSearchBar() {
      return !this.removeSearch;
    },
    showActions() {
      return !this.noActions;
    },
    filterConfig() {
      return this.$store.getters.getObjectConfigForType(
        Constants.FILTER_CONFIG
      );
    },
    /**
     * Returns the current filter from the store.
     */
    getCurrentFilter() {
      return this.$store.getters.getCurrentFilterForFilterId(this.getFilterId);
    },
    /**
     * Returns the whether this view has a filter id.
     */
    hasFilterId() {
      return (
        this.getFilterId !== undefined &&
        this.getFilterId !== null &&
        this.getFilterId.length > 0
      );
    },
    hasAuthorisationToChangeFilter() {
      var payloadFilter = {
        objectType: this.getViewConfig.objectType,
        action: EDIT_FILTER,
        viewConfig: this.getViewConfig.viewConfig
      }
      return this.$store.getters.userHasAuthorisationForFilter(payloadFilter)
    },
    showFilterButton() {
      if (this.hasFilterId === true && this.hasAuthorisationToChangeFilter) {
        return !isEmpty(this.getViewConfigFilterConfig)
      }
      return false
    }
  },
  /**
   * When the filter started we take the selected value.
   */
  mounted: function () {
    const savedState = this.$store.getters.getViewConfigState(this.viewconfig)
    if (savedState && !isEmpty(savedState.selectedFilter)) {
      this.selectedFilter = savedState.selectedFilter
    } else {
      if (this.getCurrentFilter && this.getCurrentFilter.queryFilter !== undefined) {
        this.selectedFilter = this.getCurrentFilter;
        this.oldSelectedFilter = this.selectedFilter;
        return;
      }
      var defaultFilter = this.$store.getters.getDefaultFilterConfigsForFilterId(
        this.getFilterId
      );
      if (defaultFilter === undefined) {
        defaultFilter = {};
      }
      var payload = {
        filter: defaultFilter,
        viewConfig: this.getFilterId
      };
      this.selectedFilter = defaultFilter;
    }
    setTimeout(this.setStateLoaded, 500)


  },

  watch: {
    selectedFilter(newValue) {
      this.calculatedSelectedFilter();

    },
    search(value) {
      this.searchField = this.search
    }
  },
  methods: {
    isTrue,
    isEmpty,
    setStateLoaded() {
      this.stateLoaded = true
    },
    mapItem(item) {
      delete item.syncId
      delete item.updatedAt
      return item
    },
    onFilterChange(value) {
      if (!this.stateLoaded) {
        return
      }
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          selectedFilter: value,
        }
      })
    },
    clickAction(pinned) {
      if (this[pinned.method]) {
        this[pinned.method](pinned.options)
      }
    },
    /**
     * Calculate the selected filter.
     * When selected the store must be updated. It compares the old value with the new one.
     */
    calculatedSelectedFilter() {
      if (this.oldSelectedFilter !== this.selectedFilter) {
        this.oldSelectedFilter = this.selectedFilter;
        var payload = {
          filter: this.selectedFilter,
          viewConfig: this.getFilterId
        };
        this.$store.commit(Constants.SET_CURRENT_FILTER, copyObject(payload));
      }
    },
    showFilter() {
      var newConfig = false;
      if (
        this.getFilterIsActive === null ||
        this.getFilterIsActive === undefined ||
        this.getFilterIsActive === false
      ) {
        newConfig = true;
      }
      var payload = {
        viewConfig: this.viewconfig,
        activateFilter: newConfig
      };
      this.$store.commit("setActiveFilterForConfigId", payload);
      this.displayFilter = !this.displayFilter
    },
    addFilter() {
      //TODO: this method should add a filter.
    },
    saveFilter() {
      var config = this.$store.getters.getObjectConfigForType(this.savingType);
      var payload = {
        object: this.selectedObject,
        config: config
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
      setTimeout(this.saveOperation, 100);
      this.closeDialog();
    },
    closeFilter() {
      this.displayFilter = false
    },
    saveOperation() {
      this.$store.dispatch(
        Constants.SAVE_OPERATION_CONTAINER,
        this.getCurrentOperationContainer
      );
      this.closeDialog();
    },
    toggleSearch() {
      this.isSearchFull = true
    },
    blurSearch() {
      if (this.searchField === '') {
        this.isSearchFull = false
      }
    },
    removeDialog(item) {
      this.itemToRemove = item
      this.deleteDialog = true
    },
    makeDefault(item) {
      this.getFilters.forEach(filter => {
        if (item.Guid === filter.Guid) {
          var update = JSON.parse(JSON.stringify(filter))
          update.defaultFilter = "true"
          this.saveObject(update, this.filterConfig)
        } else if (isTrue(filter.defaultFilter)) {
          var update = JSON.parse(JSON.stringify(filter))
          update.defaultFilter = "false"
          this.saveObject(update, this.filterConfig)
        }
      })
    },
    saveObject(object, objectConfig) {
      var payloadCurrent = {};
      payloadCurrent.newObject = JSON.parse(JSON.stringify(object));
      payloadCurrent.config = objectConfig;
      this.$store.dispatch("changeAndSaveObject", payloadCurrent);
    },
    removeFilter() {
      if (this.itemToRemove) {
        var removeItem = JSON.parse(JSON.stringify(this.itemToRemove))
        removeItem.isDeleted = 1
        this.saveObject(removeItem, this.filterConfig);
      }
      this.removeDialog = false
    },
    addInbox(options) {

      this.$store.commit('showAddInboxDialog')
    },
    composeMail(options) {
      this.$store.commit('showComposeMailDialog', {
        object: this.object,
        viewconfig: this.viewconfig
      })
    },
    showSelect(options) {
      const option = options || {}
      const payload = {
        viewConfig: this.viewconfig,
        object: this.object,
        parentType: this.getObjectType,
        filterFunction: null,
        categoryListCode: option.categoryListCode || 'ProductCategoryCode',
        category: option.category || 'Category',
        categories: option.categories || null,
        filterGuid: option.filterGuid || null,
        itemObjectType: option.itemObjectType || "Product",
        itemField: option.itemField || "Product",
        itemSubjectField: option.itemSubjectField || "Name",
        itemAmountField: option.itemAmountField || "Amount"
      }
      this.$store.dispatch('showItemSelectionDialog', payload)
    }
  }
};
</script>

<style scoped>
.v-list-item--active {
  /* font-weight: bold; */
  background: var(--v-primary-lighten5);
}
</style>
