<template>
  <v-card>
    test
    <v-text-field 
      v-model="model" 
      :label="label" 
      :readonly="false" />
  </v-card>
</template>

<script>
//TODO This needs to becontinued
export default {
  data() {
    return {
      show: true,
      model: "Empty Field",
      label: "Field"
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
