/**
 * Alert Context
 */

import {
  API_BASE_URL,
  DEVELEPMENT,
  GOOGLE_URL,
  NODE_ENV,
  PRODUCTION,
  STAGING,
} from "../api/constants";
import Axios from "axios";
import { GOOGLE_API_KEY } from "../integrations/google/firebase";
import { getAccessToken } from "./authorizationContext";
import {
  ALERT_TYPE_ERROR,
  ALERT_TYPE_INFO,
  ALERT_TYPE_SUCCESS,
} from "./constants";
import moment from "moment";
import router from "../router/index";

const CLIENT_ID =
  "639169510919-93iefbqqg18jh4jhsr7muo0evdo14f19.apps.googleusercontent.com";
// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
];
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly  https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify https://www.googleapis.com/auth/calendar.events";
export const GOOGLE_CONFIG = {
  apiKey: GOOGLE_API_KEY,
  clientId: CLIENT_ID,
  scope: SCOPES,
  discoveryDocs: DISCOVERY_DOCS,
};

const STATUS_LOADING = "loading";
const STATUS_READY = "ready";

export default {
  state: {
    tokenClient: null,
    status: STATUS_LOADING,
    signedId: null,
    user: null,
    error: null,
  },

  getters: {
    isReady(state) {
      return state.status === STATUS_READY;
    },
    isSignedIn(state) {
      return state.signedId === true;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setTokenClient(state, client) {
      state.tokenClient = client;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setSignedIn(state, signedId) {
      state.signedId = signedId;
    },
    setError(state, error) {
      state.error = error;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async initGoogle({ commit, getters }) {
      var initializeGapiClient = async () => {
        await gapi.client.init({
          apiKey: GOOGLE_API_KEY,
          discoveryDocs: DISCOVERY_DOCS,
        });
      };
      gapi.load("client", initializeGapiClient);
      const redirect_uri =
        NODE_ENV === PRODUCTION
          ? "https://app.swiftio.io"
          : NODE_ENV === STAGING
          ? "https://app-staging.swiftio.io"
          : "http://localhost:8081";
      const tenant = getters.getTenant.handle;
      var client = google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        ux_mode: "redirect",
        access_type: "offline",
        redirect_uri: redirect_uri + "/google-callback",
        state: tenant,
      });
      commit("setTokenClient", client);
    },
    signInGoogle({ state }) {
      if (state.tokenClient) {
        state.tokenClient.requestCode();
      }
    },
    async googleAuthCode({ dispatch, commit, getters }, payload) {
      const { code, scope, state } = payload;
      const tenant = getters.getTenant.handle;

      if (state !== tenant && NODE_ENV !== DEVELEPMENT) {
        const newUrl = `https://${tenant}${
          NODE_ENV === STAGING ? "-staging" : ""
        }.swiftio.io/${window.location.search}`;
        window.location.href = newUrl;
        return;
      }
      const url = GOOGLE_URL + "/auth";

      var bearer = await getAccessToken(getters.getCurrentUser);
      var config = {
        headers: {
          Authorization: "Bearer ".concat(bearer),
        },
      };
      await dispatch("showLoading", {
        message: "Setting up Gmail",
        fullScreen: true,
      });
      Axios.post(url, { code, scope }, config)
        .then((response) => {
          console.log("google", response);
          dispatch("hideLoading");
          dispatch("syncMail");
          dispatch("synchronizeAllData");
          router.push("/");
        })
        .catch((error) => {
          commit("showAlert", {
            type: ALERT_TYPE_ERROR,
            message:
              "Couldn't authenticate user. Code might be invalid or expired.",
          });
          router.push("/");
          dispatch("hideLoading");
        });
    },
    async syncMail({ getters, dispatch, commit }) {
      commit("showAlert", {
        type: ALERT_TYPE_INFO,
        message: "Syncing your Google Mals",
      });
      const url = `${API_BASE_URL}syncAllInbox?from=${moment()
        .subtract(3, "month")
        .valueOf()} `;

      var bearer = await getAccessToken(getters.getCurrentUser);
      var config = {
        headers: {
          Authorization: "Bearer ".concat(bearer),
        },
      };
      Axios.get(url, config).then(
        (response) => {
          console.log("google synced", response);

          commit("showAlert", {
            type: ALERT_TYPE_SUCCESS,
            message: "Google mails are synced",
          });
        },
        (error) => {
          commit("showAlert", {
            type: ALERT_TYPE_ERROR,
            message: error && error.toString(),
          });
        },
      );
    },
    async syncDeltaMail({ getters, commit }) {
      const url = `${API_BASE_URL}/syncAllInbox`;
      var bearer = await getAccessToken(getters.getCurrentUser);
      var config = {
        headers: {
          Authorization: "Bearer ".concat(bearer),
        },
      };
      Axios.get(url, config).then(
        (response) => {
          console.log("google synced", response);
          commit("showAlert", {
            type: ALERT_TYPE_SUCCESS,
            message: "Google mails are synced",
          });
        },
        (error) => {
          commit("showAlert", {
            type: ALERT_TYPE_ERROR,
            message: error && error.toString(),
          });
        },
      );
    },
    async signOut({ commit }) {},
    async disconnect({ commit }) {},
  },
};
