<template>
  <div
    :style="`height: 100%; max-height: 400px}`"
    ref="mapContainer"
  >
    <resize-observer @notify="onResizeComponent" />

    <img
      v-if="isMounted"
      crossorigin="anonymous"
      :src="getImage"
      class="rounded-lg pointer"
      style="width: 100%; height: 100%; object-fit: cover;"
      @click="openMap"
    >

    <v-dialog
      v-model="dialog"
      content-class="rounded-lg white"
    >

      <div
        class="white"
        style="height: 80vh;"
      >
        <div
          :ref="mapRef"
          style="width: 100%;height: 100%;"
        ></div>
      </div>

      <!-- <GmapMap
        v-if="getFilteredObjects && getFilteredObjects.length > 0"
        crossorigin="anonymous"
        :ref="mapRef"
        :center="position"
        :options="getOptions"
        :style="getStyle"
        :zoom="5"
        map-type-id="roadmap"
        class="rounded-lg"
      >
        <GmapMarker
          v-for="(m, i) in getMarkers"
          :key="i"
          :position="m"
          :clickable="true"
          :draggable="false"
          :icon="getSVGMarker"
          @click="toggleInfoWindow(m, i)"
        />
        <GmapInfoWindow
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        ></GmapInfoWindow>

        <GmapCircle
          v-for="(n, indexCircle) in getCircleMarkers"
          :key="indexCircle"
          :center="n"
          :radius="n.radius"
          :visible="true"
          :options="{
            fillColor: getColor,
            strokeColor: getColor,
            strokeOpacity: 0.1,
            fillOpacity: 0.4
          }"
        />
      </GmapMap>
      <div
        style="height: 100%; position: relative;"
        class="d-flex align-center justify-center"
        v-else
      >
        <img
          style="max-height: 450px; border-radius: 12px; object-fit: cover; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0; "
          width="100%"
          :src="mapsImage"
          :lazy-src="mapsImage"
          cover
        />
        <div
          class="text-heading-6"
          style="z-index: 1; position: relative;"
        >
          No data
        </div>

      </div> -->
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as UTIL from "../../api/utilities";
import { SELECT_OBJECT } from '../../store/constants';
import MapsInfoTileVue from './customTiles/MapsInfoTile.js';
import mapsImage from '../../assets/maps.svg'
import { getCurrentTheme, getTheme } from '../../theme/themeDefault';

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    circlemarkers: {
      type: Array,
      default: null
    },
    markers: {
      type: Array,
      default: null
    },
    disableDefaultUi: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false,
    mapsImage,
    update: null,
    bounds: null,
    mapRef: null,
    isMounted: false,
    map: null,
    width: 400,
    height: 600,
    icon: 'https://swiftio.io/map-pin.png',
    mapIds: {
      light: 'bd5d24f13a49bb3d',
      dark: '79759482730334c8'
    },
    position: { lat: 50.88097, lng: 4.699063 },
    options: {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUI: false,
      mapTypeId: 'roadmap',
    },
    zoom: null,
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    infowindow: null,
    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 10,
        height: 0
      }
    },
    anchor: null
  }),
  computed: {
    getImage() {
      this.update;
      const myUrlWithParams = new URL("https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyC7VaTP9e_KiN_OHQYa-wtans8FucoZv7g");
      const markers = this.getMarkers
      let markersParams = `color:0x3661ED|size:tiny|icon:${this.icon}|`
      const size = `${this.width}x${this.width}`
      myUrlWithParams.searchParams.append("size", size);
      myUrlWithParams.searchParams.append("scale", 2);
      myUrlWithParams.searchParams.append("map_id", this.getMapId);
      myUrlWithParams.searchParams.append("maptype", this.getMapytype);
      if (markers.length > 0) {
        const bounds = new google.maps.LatLngBounds()
        var latLong = {}
        var markersArray = markers.map(m => {
          latLong = this.getLatLong(m)
          bounds.extend(latLong)
          return `${latLong.lat},${latLong.lng}`
        })
        markersParams += markersArray.join('|')
        myUrlWithParams.searchParams.append("markers", markersParams);
        if (markers.length === 1) {
          if (this.getMapytype === 'satellite') {
            myUrlWithParams.searchParams.append("zoom", 19);
          } else {
            myUrlWithParams.searchParams.append("zoom", 16);
          }
        } else {
          myUrlWithParams.searchParams.append("zoom", 8);
        }
      }
      else {
        let center = `${this.position.lat},${this.position.lng}`
        myUrlWithParams.searchParams.append("center", center);
        myUrlWithParams.searchParams.append("zoom", 5);
      }

      return myUrlWithParams.href

    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      //FilterOutWithoutLatAndLng
      var filteredLocations = filteredObject.filter(object => ((!UTIL.isEmpty(object.lat) || !UTIL.isEmpty(object.Lat)) && (!UTIL.isEmpty(object.lng) || !UTIL.isEmpty(object.Lng))));
      // this.updateBounds()
      return filteredLocations;
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.getCurrentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.getViewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "edit" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getMapytype() {

      return this.getViewConfig.subViewConfig === 'satellite' ? 'satellite' : 'roadmap'
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getDemoMarkers() {
      return [
        { lat: 50.84097, lng: 4.699063, radius: 10000 },
        { lat: 50.85007, lng: 4.496083, radius: 7000 },
        { lat: 50.28517, lng: 4.699043, radius: 14000 },
        { lat: 50.88027, lng: 4.892067, radius: 3000 },
        { lat: 50.68992, lng: 4.739953, radius: 9000 }
      ];
    },
    getOptions() {
      var mapTypeId = "roadmap";
      if (this.getViewConfig.subViewConfig === 'satellite') {
        // mapTypeId = 'satellite' TODO
      }

      return {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: this.disableDefaultUi,
        mapTypeId: mapTypeId,
        styles: customStyle(this.$vuetify.theme.isDark)
      }
    },
    getCenterPosition() {
      var positions = this.getFilteredObjects
      if (UTIL.isEmptyArray(positions)) {
        var center = { lat: 50.86163981787919, lng: 4.355101576079295 }
        return center
      }
      var maxLat = -1000;
      var minLat = 1000
      var maxLng = -1000;
      var minLng = 1000
      for (var i = 0; i < positions.length; i++) {
        var lat = Number(positions[i].lat);
        var lng = Number(positions[i].lng);
        if (lat < minLat) {
          minLat = lat
        }
        if (lng < minLng) {
          minLng = lng
        }
        if (lat > maxLat) {
          maxLat = lat
        }
        if (lng > maxLng) {
          maxLng = lng
        }
      }
      var centerLat = (maxLat + minLat) / 2
      var centerLng = (maxLng + minLng) / 2
      this.calculateZoom(minLat, maxLat, minLng, maxLng)
      var center = { lat: centerLat, lng: centerLng }
      return center
    },
    getZoom() {
      if (!UTIL.isEmpty(this.zoom)) {
        return this.zoom
      }
      return 6
    },
    getMarkers() {
      return this.getFilteredObjects;
    },
    getCircleMarkers() {
      return this.circlemarkers
    },
    getColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    getStroke() {
      return getCurrentTheme(this.$vuetify.theme.isDark).sideBarColor
    },
    getSVGMarker() {
      var svgMarker = {
        path: "M9.1-0.1c-5-0.1-9,3.9-9.1,8.9C0,8.8,0,8.9,0,9c0.1,3.6,1.7,7.1,4.3,9.6c0.9,0.9,1.9,1.7,2.9,2.5c0.4,0.3,0.7,0.5,1,0.6c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0l0,0c0.2,0.1,0.5,0.1,0.8,0l0,0l0.1,0c0.1,0,0.2-0.1,0.3-0.2c0.2-0.1,0.6-0.4,1-0.6c1-0.7,2-1.6,2.9-2.5c2.6-2.5,4.2-6,4.3-9.6C18,4,14,0,9.1-0.1z M9,12c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3C12.2,10.5,10.8,12,9,12z",
        fillColor: this.getColor,
        fillRule: "evenodd",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: this.getStroke,
        rotation: 0,
        scale: 1,
        anchor: this.anchor,
      };
      return svgMarker
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined && this.height !== 0) {
        return UTIL.addIntToPixelString(this.height, 50);
      } else {
        return "100%";
      }
    },
    getMapId() {
      return this.mapIds[this.$vuetify.theme.isDark ? 'dark' : 'light']
    },
    getStyle() {
      return "overflow: hidden; width: 100%; min-height: 300px; height: " + this.getHeight + ";";
    }
  },
  created() {
    this.mapRef = (Math.random() + 1).toString(36).substring(7);
    let that = this;
    console.log(this.viewconfig)
    window[this.viewconfig] = function (Guid, viewFieldGuid) {
      if (Guid, viewFieldGuid) {
        that.onClickInfo(Guid, viewFieldGuid)
      }
    }
  },
  mounted() {
    this.onResizeComponent()
    this.isMounted = true
  },
  methods: {
    getLatLong(marker) {
      if (UTIL.isEmpty(marker) || (!UTIL.isEmpty(marker) && UTIL.isEmpty(marker.lat || marker.Lat) && UTIL.isEmpty(marker.lng || marker.Lng))) {
        return null
      } else {
        try {
          const lat = parseFloat(marker.lat || marker.Lat)
          const lng = parseFloat(marker.lng || marker.Lng)
          return { lat, lng }
        } catch (error) {
          return null
        }
      }
    },
    onResizeComponent() {

      var container = this.$refs.mapContainer;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;

      }
      this.width = width;
      this.height = height;
      this.update = Math.random()
    },
    openMap() {

      if (this.getMarkers.length > 0) {
        if (this.getMarkers.length === 1 && this.getMapytype === 'satellite') {
          window.open(`http://maps.google.com/?q=${this.getMarkers[0].Location}&t=k`)
        } else {
          this.dialog = true
          this.map = null
          setTimeout(this.showMap, 300)
        }

      }
      // this.updateBounds()
    },
    showMap() {
      if (this.map == null && this.mapRef && this.$refs[this.mapRef]) {
        this.map = new google.maps.Map(this.$refs[this.mapRef], { mapId: this.getMapId, mapTypeControl: false, });
        this.anchor = new google.maps.Point(0, 20);
        this.infowindow = new google.maps.InfoWindow({
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker

        });
        if (this.getFilteredObjects && this.getFilteredObjects.length > 0) {
          const bounds = new google.maps.LatLngBounds()
          var latLong = {}

          for (let m of this.getFilteredObjects) {
            latLong = this.getLatLong(m)
            bounds.extend(latLong)

            const marker = new google.maps.Marker({
              position: latLong,
              icon: this.getSVGMarker,
              map: this.map,
            });
            marker.addListener("click", () => {
              this.toggleInfoWindow(marker, m)
            });
          }

          this.bounds = bounds
          if (this.getFilteredObjects.length === 1) {
            this.map.setZoom(16)
            this.map.setCenter(latLong);
          } else {
            this.map.fitBounds(bounds);
          }

        }

      }
    },

    toggleInfoWindow(marker, object) {
      this.center = marker.position
      this.infoWindowPos = { lat: object.lat || object.Lat, lng: object.lng || object.Lng };
      if (!UTIL.isEmptyArray(this.getFields)) {

        var fields = this.getFields.map(field => {
          const viewFieldGuid = !UTIL.isEmpty(field.navigationId) && !UTIL.isEmpty(field.navigationConfigId) ? field.Guid : null
          const title = !UTIL.isEmpty(field.relatedObjectType) && !UTIL.isEmpty(field.displayRelatedObject) ? this.$store.getters.getRelatedDisplayForViewField({ value: object[field.value], viewfield: field }) : object[field.value]
          return { Guid: object.Guid, title, viewFieldGuid }
        })
        this.infowindow.setContent(MapsInfoTileVue(fields, this.viewconfig));
        setTimeout(() => {
          this.infowindow.open({
            anchor: marker,
            map: this.map,
          });
        }, 100)

      }
    },
    onClickInfo(Guid, viewFieldGuid) {
      console.log(this.getFilteredObjects)
      const object = this.getFilteredObjects.find(item => {
        console.log(item.Guid, Guid)
        return item['Guid'] === Guid
      })
      if (object) {
        const viewField = this.getFields.find(item => item['Guid'] === viewFieldGuid)
        if (viewField) {
          this.navigateTo(object, viewField)
        }
      }
    },
    navigateTo(object, field) {
      if (UTIL.isEmpty(object[field.value])) {
        return;
      }
      this.dialog = false
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(SELECT_OBJECT, payload);

    },
    calculateZoom(minLat, maxLat, minLng, maxLng) {

      var mapDim = {
        height: 1000,
        width: 5000,
      }
      var bounds = {
        ne: {
          lat: maxLat,
          lng: minLng
        },
        sw: {
          lat: minLat,
          lng: maxLng
        }
      }
      this.zoom = this.getBoundsZoomLevel(bounds, mapDim)
    },
    getBoundsZoomLevel(bounds, mapDim) {
      var WORLD_DIM = { height: 256, width: 256 };
      var ZOOM_MAX = 21;

      function latRad(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
      }

      function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
      }

      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();

      var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

      var lngDiff = ne.lng() - sw.lng();
      var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

      var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
      var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

      return Math.min(latZoom, lngZoom, ZOOM_MAX);
    },

    // getBoundsZoomLevel(bounds, mapDim) {
    //   var WORLD_DIM = { height: 1200, width: 4000 };

    //   var ZOOM_MAX = 15;
    //   if (this.getViewConfig.subViewConfig === 'satellite') {
    //     ZOOM_MAX = 18
    //   }

    //   function latRad(lat) {
    //     var sin = Math.sin(lat * Math.PI / 180);
    //     var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    //     return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    //   }

    //   function zoom(mapPx, worldPx, fraction) {
    //     return Math.floor(Math.log(mapPx / fraction) / Math.LN2);
    //   }

    //   function zoomLat(mapPx, worldPx, fraction) {
    //     return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    //   }

    //   var ne = bounds.ne
    //   var sw = bounds.sw

    //   var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

    //   var lngDiff = ne.lng - sw.lng;
    //   var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    //   var latZoom = zoomLat(mapDim.height, WORLD_DIM.height, latFraction);
    //   var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    //   var zoom = Math.min(latZoom, lngZoom, ZOOM_MAX);
    //   if (this.getMarkers && this.getMarkers.length > 1) {
    //     zoom -= 1;
    //   }
    //   return zoom
    // }
  }
};

const customStyle = (isDark) => {

  if (isDark) {
    return [
      {
        "stylers": [
          {
            "hue": "#3661ED"
          },
          {
            "invert_lightness": true
          },
          {
            "saturation": -90
          },
          {
            "lightness": 33
          },
          {
            "gamma": 0.5
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#1b1b1c"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#1b1b1c"
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
          {
            "color": "#1a1d27"
          }
        ]
      }
    ]
    return [
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#d6e2e6"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#cfd4d5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#7492a8"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "lightness": 25
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#282829"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#cfd4d5"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#282829"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#7492a8"
          }
        ]
      },
      {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#282829"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#588ca4"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -100
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a9de83"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#a9de83"
          }
        ]
      },
      {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c6e8b3"
          }
        ]
      },
      {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#bae6a1"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#41626b"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
          {
            "color": "#41626b"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -45
          },
          {
            "lightness": 10
          },
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#626f73"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#626f73"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#1f2324"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#1f2324"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#555555"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -70
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b4cbd4"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#588ca4"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#008cb5"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": -5
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#1b1b1c"
          }
        ]
      }
    ]
  }
  else {
    return [
      {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#d6e2e6"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#cfd4d5"
          }
        ]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#7492a8"
          }
        ]
      },
      {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "lightness": 25
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#dde2e3"
          }
        ]
      },
      {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#cfd4d5"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#dde2e3"
          }
        ]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#7492a8"
          }
        ]
      },
      {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#dde2e3"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#588ca4"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -100
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a9de83"
          }
        ]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#bae6a1"
          }
        ]
      },
      {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c6e8b3"
          }
        ]
      },
      {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#bae6a1"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#41626b"
          }
        ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -45
          },
          {
            "lightness": 10
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#c1d1d6"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
          {
            "color": "#a6b5bb"
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#9fb6bd"
          }
        ]
      },
      {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#ffffff"
          }
        ]
      },
      {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
          {
            "saturation": -70
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#b4cbd4"
          }
        ]
      },
      {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#588ca4"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
          {
            "color": "#008cb5"
          },
          {
            "visibility": "on"
          }
        ]
      },
      {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "saturation": -100
          },
          {
            "lightness": -5
          }
        ]
      },
      {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
          {
            "color": "#a6cbe3"
          }
        ]
      }
    ]
  }
}
</script>
