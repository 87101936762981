import * as Actions from "./constants.js";

/**
 * Calendar State
 */

export default {
  state: {
    calendarConfigs: {
      globalCalendar: {
        dark: false,
        startMenu: false,
        start: "2019-01-12",
        endMenu: false,
        end: "2019-01-27",
        nowMenu: false,
        minWeeks: 1,
        now: null,
        type: "month",
        refCalendar: null
      }
    }
  },

  /**
   * Calendar getters
   */

  getters: {
    getCalendarConfigForConfig: (state, getters) => config => {
      return state.calendarConfigs[config];
    },
    getCalendarForConfig: (state, getters) => config => {
      return state.calendarConfigs[config].refCalendar;
    }
  },

  /**
   * Calendar Mutators
   */

  mutations: {
    /*
        Set the calendar config for the config
        payload.config      => Config string
        payload.calendarConfig   => config object
    */
    setCalendarConfigs(state, payload) {
      console.log("setCalendarConfig For config");
      state.calendarConfigs[payload.config] = payload.calendarConfig;
    },

    /*
        Set the calendar config for the config
        payload.config      => Config string
        payload.type   => the type
    */
    setCalendarType(state, { payload, getters }) {
      var calendarConfig = getters.getCalendarConfigForConfig(payload.config);
      calendarConfig.type = payload.type;
    },

    /*
        Set the ref calendar config for the config
        payload.config      => Config string
        payload.refCalendar   => the type
    */
    setRefCalendarForConfig(state, { payload, getters }) {
      var calendarConfig = getters.getCalendarConfigForConfig(payload.config);
      calendarConfig.refCalendar = payload.refCalendar;
    }
  },

  /**
   * Calendar Actions
   */

  actions: {
    /*
        Set the calendar config for the config
        payload.config      => Config string
        payload.type        => the type
    */
    updateCalendarType({ dispatch, commit, getters }, payload) {
      var payloadGetters = {
        getters: getters,
        payload: payload
      };
      commit("setCalendarType", payloadGetters);
    },

    /*
        update the  ref calendar config for the config
        payload.config      => Config string
        payload.refCalendar   => the type
    */
    updateRefCalendarForConfig({ dispatch, commit, getters }, payload) {
      var payloadGetters = {
        getters: getters,
        payload: payload
      };
      commit("setRefCalendarForConfig", payloadGetters);
    }
  }
};
