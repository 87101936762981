import * as Constants from "../store/constants";
import * as DynamicFilter from "../api/dynamicFilter";
import * as utilities from "../api/utilities";
import Vue from "vue";
import moment from "moment";

/**
 * App State
 */

export default {
  state: {
    currentFilters: {},
    activatedFilters: {},
    selectedItems: {},
    oldFilters: {},
    oldSorting: {},
    customFilters: {},
  },
  mutations: {
    setCurrentFilter(state, payload) {
      Vue.set(state.currentFilters, payload.viewConfig, payload.filter);
    },
    setCustomFilters(state, payload) {
      Vue.set(state.customFilters, payload.viewConfig, {});
      Vue.set(state.customFilters, payload.viewConfig, payload.filter);
    },
    setActiveFilterForConfigId(state, payload) {
      Vue.set(
        state.activatedFilters,
        payload.viewConfig,
        payload.activateFilter,
      );
    },
    setSelectedItemsForViewConfig(state, payload) {
      Vue.set(state.selectedItems, payload.viewconfig, payload.items);
    },
    setOldFiltersForFields(state, payload) {
      const { filterFields, items, reset } = payload;
      if (utilities.isTrue(reset)) {
        delete state.oldFilters[filterFields];
      } else if (utilities.isEmpty(state.oldFilters[filterFields])) {
        const newItems = items && JSON.parse(JSON.stringify(items));
        Vue.set(state.oldFilters, filterFields, newItems);
      }
    },
    setOldSortingForFields(state, payload) {
      const { filterFields, items, reset } = payload;
      if (reset) {
        delete state.oldSorting[filterFields];
      } else if (utilities.isEmpty(state.oldSorting[filterFields])) {
        const newItems = items && JSON.parse(JSON.stringify(items));
        Vue.set(state.oldSorting, filterFields, newItems);
      }
    },
  },
  getters: {
    /*
        CardConfigs
    */
    getFilterFunctionValue: (state, getters) => (func) => {
      if (func) {
        if (func.includes("date_") > 0) {
          const options = func.split("_");
          const operator = options[1];
          const num = options[2];
          const span = options[3];
          if (operator && num && span) {
            try {
              return moment()
                [operator](num, span)
                .format(Constants.DATE_FORMAT);
            } catch (error) {
              commit("showAlert", {
                type: Constants.ALERT_TYPE_WARNING,
                message: error,
              });
            }
          }
          return moment().format(Constants.DATE_FORMAT);
        } else if (func.indexOf("dateTime_") > 0) {
          const options = func.split("_");
        } else {
          switch (func) {
            case "getCurrentEmployeeGuid":
              return getters.getCurrentEmployeeGuid;
            case "getCurrentEmployeeEmail":
              return getters.getCurrentUser.email;
            case "getMyInboxEmails":
              const emails = getters.getMyMailboxEmails(
                getters.getCurrentEmployeeGuid,
              );
              return emails;
            case "getMyInboxes":
              const inboxes = getters.getMyMailboxes(
                getters.getCurrentEmployeeGuid,
              );
              return inboxes;
          }
        }
      }
    },
    getMyMailboxEmails: (state, getters) => (employeeGuid) => {
      const filter = (setting) => {
        return (
          setting.Employee === employeeGuid ||
          utilities.isEmpty(setting.Employee)
        );
      };
      const inboxes = getters.getObjectsForFilter(
        Constants.INBOX_SETTING,
        filter,
      );
      console.log("inboxes", inboxes);
      if (inboxes) {
        return inboxes.map((item) => item.Email);
      }

      return [];
    },
    getMyMailboxes: (state, getters) => (employeeGuid) => {
      const filter = (setting) => {
        return (
          setting.Employee === employeeGuid ||
          utilities.isEmpty(setting.Employee)
        );
      };
      const inboxes = getters.getObjectsForFilter(
        Constants.INBOX_SETTING,
        filter,
      );
      if (inboxes) {
        return inboxes.map((item) => item.Guid);
      }
      return [];
    },
    getFilterConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.FILTER_CONFIG);
    },
    getFilterFieldsConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.FILTER_FIELD_CONFIG);
    },
    getSortingFieldsConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(
        Constants.SORTING_FIELD_CONFIG,
      );
    },
    getAllFilterFieldsConfigs: (state, getters) => {
      return getters.getAllDataObjectsForObjectType(
        Constants.FILTER_FIELD_CONFIG,
      );
    },
    getAllSortingFieldsConfigs: (state, getters) => {
      return getters.getAllDataObjectsForObjectType(
        Constants.SORTING_FIELD_CONFIG,
      );
    },
    getDefaultFilterConfigsForFilterId: (state, getters) => (viewConfig) => {
      if (getters.getIsCacheActive(Constants.FILTER_CONFIG)) {
        var filters = getters.getCachedObjectsForType(
          Constants.FILTER_CONFIG,
          viewConfig,
        );
      } else {
        var filters = getters.getFilterConfigs;
      }

      return filters.find(function (o) {
        return (
          o.filterQuery === viewConfig &&
          (o.defaultFilter === "true" || o.defaultFilter === true)
        );
      });
    },
    getFilterConfigsForFilterId: (state, getters) => (filterQuery) => {
      return getters.getFilterConfigs.filter(function (o) {
        return o.filterQuery === filterQuery;
      });
    },
    getFilterConfigForId: (state, getters) => (id) => {
      return getters.getFilterConfigs.find(function (o) {
        return o.filterConfigId === id;
      });
    },
    getFieldsForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters

      if (getters.getIsCacheActive(Constants.FILTER_FIELD_CONFIG)) {
        return getters.getCachedObjectsForType(
          Constants.FILTER_FIELD_CONFIG,
          FilterfieldsList,
        );
      }
      return getters.getFilterFieldsConfigs.filter(function (o) {
        return o.filterFields === FilterfieldsList;
      });
    },
    getSortingForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters
      return getters.getSortingFieldsConfigs.filter(function (o) {
        return o.sortingFieldList === FilterfieldsList;
      });
    },
    getAllFieldsForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters
      return getters.getAllFilterFieldsConfigs.filter(function (o) {
        return o.filterFields === FilterfieldsList;
      });
    },
    getAllSortingForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters
      return getters.getAllSortingFieldsConfigs.filter(function (o) {
        return o.sortingFieldList === FilterfieldsList;
      });
    },
    getOldFieldsForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters
      return state.oldFilters[FilterfieldsList];
    },

    getOldSortingForFilter: (state, getters) => (FilterfieldsList) => {
      //TODO multiple filters
      return state.oldSorting[FilterfieldsList];
    },
    getSelectedItemsForViewConfig: (state, getters) => (viewConfig) => {
      //TODO multiple filters
      if (state.selectedItems[viewConfig]) {
        return state.selectedItems[viewConfig];
      } else return [];
    },

    getConvertedFilterFields: (state, getters) => (FilterfieldsList) => {
      var filterFields = getters.getFieldsForFilter(FilterfieldsList);
      var filterField = null;
      for (var i = 0; i < filterFields.length; i++) {
        filterField = filterFields[i];
        if (
          filterField !== undefined &&
          filterField.filterFunction !== undefined &&
          filterField.filterFunction !== null &&
          filterField.filterFunction.length > 2
        ) {
          var value = getters.getFilterFunctionValue(
            filterField.filterFunction,
          );
          filterField.value = value;
        }
      }
      return filterFields;
    },
    getFilteredObjectsForFilterConfigId: (state, getters) => (payload) => {
      const { objects, object, filterId } = payload;
      if (filterId === undefined || filterId === null) {
        return objects;
      }

      if (payload.isEdit === true) {
        var filterIdEdit = filterId + "Edit";
        var filterConfig = getters.getCurrentFilterForFilterId(filterIdEdit);
      }

      if (utilities.isEmpty(filterConfig)) {
        filterConfig = getters.getCurrentFilterForFilterId(filterId);
      }

      if (utilities.isEmpty(filterConfig)) {
        filterConfig = getters.getFilterConfigForId(filterId);
      }

      if (utilities.isEmpty(filterConfig)) {
        return objects;
      }
      payload.filterConfig = filterConfig;

      return getters.getFilteredObjectsForFilterId(payload);
    },
    getFilteredObjectsForViewConfig: (state, getters) => (payload) => {
      payload.filterId = payload.viewConfig.queryFilter;

      return getters.getFilteredObjectsForFilterConfigId(payload);
    },
    getFilteredObjectsForFilterId: (state, getters) => (payload) => {
      var objects = payload.objects;
      var object = payload.object;
      var filterConfig = payload.filterConfig;
      var filterFields = getters.getConvertedFilterFields(
        filterConfig.filterFields,
      );
      var sortingFields = getters.getSortingForFilter(
        filterConfig.filterFields,
      ); //Convert with function
      let result = null;
      if (
        filterConfig.filter &&
        filterConfig.parentFilter &&
        filterConfig.parentType
      ) {
        const parentObjects = getters.getDataObjectsForObjectType(
          filterConfig.parentType,
        );
        result = getters.getFilteredObjectsForFilterConfigId({
          objects: parentObjects,
          object,
          filterId: filterConfig.parentFilter,
        });
      }

      let filter = null;
      try {
        if (!utilities.isEmpty(filterConfig.filter)) {
          filter = JSON.parse(filterConfig.filter);
        }
      } catch (error) {
        console.log(error);
      }

      var convertedFields = utilities.isEmptyObj(filter)
        ? DynamicFilter.convertFilters(object, filterFields)
        : filter;
      var filteredObjects = DynamicFilter.applyFilter(
        objects,
        getters,
        convertedFields,
        object,
        result,
        payload.customFilter,
      );
      if (
        !utilities.isEmpty(sortingFields) &&
        !utilities.isEmpty(sortingFields[0]) &&
        !utilities.isEmpty(sortingFields[0].field)
      ) {
        const sort = sortingFields[0];
        const copy = JSON.parse(JSON.stringify(filteredObjects));
        filteredObjects = DynamicFilter.applySorting(copy, sort);
      }
      return filteredObjects;
    },
    getCustomFilterForViewConfig: (state, getters) => (viewConfigId) => {
      return state.customFilters[viewConfigId];
    },
    getCurrentFilterForFilterId: (state, getters) => (filterId) => {
      return state.currentFilters[filterId];
    },
    getFilterActivatedForViewConfig: (state, getters) => (viewConfigId) => {
      return state.activatedFilters[viewConfigId];
    },
  },
};
