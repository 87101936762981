import * as Constants from "../store/constants";

/**
 * Chart Context
 */

export default {
  state: { logs: [], log: "", showLogs: false },
  getters: {
    /*
      Get Logs
    */
    getLogs: (state, getters) => {
      return state.logs;
    },
    getLog: (state, getters) => {
      return state.log;
    },
    getLogState: (state, getters) => {
      return state.showLogs;
    }
  },
  mutations: {
    addLog(state, log) {
      state.logs.push(log);
    },
    log(state, log) {
      state.log = state.log + log;
    },
    logLine(state, log) {
      var logString = JSON.stringify(log);
      state.log = state.log + "\n" + logString;
    },
    toggleLogs(state) {
      state.showLogs = !state.showLogs;
    }
  }
};
