<template>
  <v-card
    flat
    class="ma-3 pa-3"
  >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container
          v-if="hasSelectionView"
          fluid
        >
          <h2>{{ dialogTitle }} test</h2>
          <viewConfigView
            :object="selectedObject"
            :operationid="operationId"
            :editmode="true"
            :dialog="dialog"
            :key="componentKey"
            :viewconfig="getViewConfigForDialog"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="saveObject()"
          >hi-check-circle</v-btn>
          <v-btn
            color="primary"
            flat
            @click="cancelSelection()"
          >hi-x-circle-outline</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout
      row
      wrap
    >
      <v-flex xs10>
        <FilterView :viewconfig="viewconfig" />
      </v-flex>
      <v-btn
        icon
        small
        color="primary"
        @click="saveChartConfig()"
      >
        <v-icon>hi-check-circle</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click="commitChartConfig()"
      >
        <v-icon>hi-arrow-path</v-icon>
      </v-btn>
      <v-flex xs12>
        <v-card class="ma-3 pa-3 rounded-lg">
          <p class="ma-3">X-Axis</p>
          <!--<v-btn  
            icon
            small
            @click.native="addChartFieldConfig('X')"><v-icon>add</v-icon></v-btn>-->
          <v-list
            dense
            class="pa-0"
          >
            <v-list-item
              v-for="item in getXFields"
              :key="item.field"
            >
              <v-list-item-content>
                <v-layout>
                  <v-flex>
                    <v-select
                      :items="getValueListItems('X')"
                      v-model="item.field"
                      item-text="description"
                      item-value="field"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      :items="getValueListChartype()"
                      v-model="item.calculationTypeX"
                      item-text="value"
                      item-value="descr"
                    />
                  </v-flex>

                  <!-- <v-btn  
                    icon
                    small
                    @click.native="editChartFieldConfig(item)"><v-icon>create</v-icon></v-btn> -->
                </v-layout>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-card class="ma-3 pa-3 rounded-lg">
          <p class="ma-3">Color</p>
          <!--<v-btn  
            icon
            small
            @click.native="addChartFieldConfig('C')"><v-icon>add</v-icon></v-btn>-->
          <v-list
            dense
            class="pa-0"
          >
            <v-list-item
              v-for="item in getColorFields"
              :key="item.field"
            >
              <v-list-item-content>
                <v-layout>
                  <v-flex>
                    <v-select
                      :items="getValueListItems('C')"
                      v-model="item.field"
                      item-text="description"
                      item-value="field"
                    />
                  </v-flex>
                  <!-- <v-btn  
                    icon
                    small
                    @click.native="editChartFieldConfig(item)"><v-icon>create</v-icon></v-btn> -->
                </v-layout>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-card class="ma-3 pa-3 rounded-lg">
          <p class="ma-3">Y-Axis</p>
          <!--<v-btn  
            icon
            small
            @click.native="addChartFieldConfig('Y')"><v-icon>add</v-icon></v-btn>-->
          <v-list
            dense
            class="pa-0"
          >
            <v-list-item
              v-for="item in getYFields"
              :key="item.field"
            >
              <v-list-item-content>
                <v-layout>
                  <v-flex>
                    <v-select
                      :items="getValueListItems('Y')"
                      v-model="item.field"
                      item-text="description"
                      item-value="field"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      :items="getValueListYtype()"
                      v-model="item.calculationTypeY"
                      item-text="value"
                      item-value="descr"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      :items="getValueListItems('C')"
                      v-model="item.colorField"
                      item-text="description"
                      item-value="color"
                      label="color"
                    />
                  </v-flex>
                  <!-- <v-btn  
                    icon
                    small
                    @click.native="editChartFieldConfig(item)"><v-icon>create</v-icon></v-btn> -->
                </v-layout>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as theme from "../../theme/themeDefault.js";
import * as Utilities from "../../api/utilities.js";
import FilterView from "./FilterView";
//import detailsView from "../splitCard/Vie";

export default {
  components: { FilterView },
  props: {
    height: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: " "
    },
    viewconfig: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      viewConfigOfSelection: "",
      dialog: false,
      savingType: "",
      dialogTitle: "",
      operationId: null,
      componentKey: 0,
      dialogType: "X"
    };
  },
  computed: {
    getChartId() {
      return this.getViewConfig.subViewConfig;
    },
    getChartConfig() {
      return this.$store.getters.getChartConfigForId(this.getChartId);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getXFields() {
      var list = this.getFieldsForType(Constants.CHART_X);
      if (list.length > 0) {
        return list;
      } else {
        return [
          {
            type: Constants.CHART_X,
            chartId: this.getChartId
          }
        ];
      }
    },
    getYFields() {
      var list = this.getFieldsForType(Constants.CHART_Y);
      if (list.length > 0) {
        return list;
      } else {
        return [
          {
            type: Constants.CHART_Y,
            chartId: this.getChartId
          }
        ];
      }
    },
    getColorFields() {
      var list = this.getFieldsForType(Constants.CHART_C);
      if (list.length > 0) {
        return list;
      } else {
        return [
          {
            type: Constants.CHART_C,
            chartId: this.getChartId
          }
        ];
      }
    },
    hasSelectionView() {
      return this.viewConfigOfSelection.length > 1;
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    },
    getViewConfigForDialog() {
      if (this.dialogType === "Y") {
        return "ChartFieldViewDetailsY";
      }
      if (this.dialogType === "C") {
        return "ChartFieldViewDetailsC";
      }
      if (this.dialogType === "X") {
        return "ChartFieldViewDetailsX";
      }
      return "ChartFieldViewDetailsDefault";
    }
  },
  mounted: function () { },
  methods: {
    getFieldsForType(type) {
      var payload = {
        type: type,
        chartId: this.getChartId
      };
      return this.$store.getters.getChartFieldsForChartIdAndType(payload);
    },
    getValueListItems(type) {
      var objectConfig = this.getObjectConfig;
      return objectConfig.fieldsConfig;
    },
    getValueListChartype() {
      var list = this.$store.getters.getValueListForId("calculationTypeXList");
      return list;
    },
    getValueListYtype() {
      var list = this.$store.getters.getValueListForId("calculationTypeYList");
      return list;
    },
    saveChartConfig() {
      var chartConfig = this.$store.getters.getObjectConfigForType(
        Constants.CHART_FIELD_CONFIG
      );
      var xObject = this.getXFields[0];
      this.saveObject(xObject, chartConfig);
      var ColorObject = this.getColorFields[0];
      this.saveObject(ColorObject, chartConfig);
      var yObject = this.getYFields[0];
      this.saveObject(yObject, chartConfig);
    },

    commitChartConfig() {
      var chartConfig = this.$store.getters.getObjectConfigForType(
        Constants.CHART_FIELD_CONFIG
      );
      var colorObject = this.getColorFields[0];
      this.commitObject(colorObject, chartConfig);
    },

    commitObject(object, objectConfig) {
      var payload = {
        objectConfig: objectConfig,
        object: object
      };
      this.$store.dispatch("commitObject", payload);
    },

    saveObject(object, objectConfig) {
      var payloadCurrent = {};
      payloadCurrent.newObject = object;
      payloadCurrent.config = objectConfig;
      this.$store.dispatch("changeAndSaveObject", payloadCurrent);
    }
  }
};
</script>
