<template>
  <v-container class="ma-0 pa-0 fluid">
    <vue-editor
      id="logeditor"
      v-model="getLog"
      :disabled="isReadOnly"
      height="600px"
    />
    <v-btn 
      text 
      small 
      @click.native="hideLogs()">close</v-btn>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: {},
  data() {
    return {
      content: "<h1>Some initial content</h1>"
    };
  },
  computed: {
    getToolBar() {
      if (this.isReadOnly) {
        return [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "code-block"]
        ];
      }
      return [];
    },
    getLog() {
      return this.$store.getters.getLog;
    },
    isReadOnly() {
      return true;
    }
  },
  methods: {
    hideLogs() {
      this.$store.commit("toggleLogs");
    }
  }
};
</script>

<style>
#editor2 {
  height: 300px;
}
</style>
