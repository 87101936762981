import * as Constants from "../store/constants";
import noImage from "../demo/Images/Images/empty_avatar.jpg";
import demo from "../demo/Images/demoImages";
import { toUnicode } from "punycode";
import Vue from "vue";

/**
 * App State
 */

export default {
  state: { navigations: [], navigationIndex: 1, viewConfigStates: {} },
  mutations: {
    _addNavigation(state, navigation) {
      var index = state.navigations.findIndex(
        (v) => v.object.Guid === navigation.object.Guid,
      );
      if (index >= 0) {
        state.navigations.splice(index, 1);
      }
      state.navigations.push(navigation);
      // state.navigations = state.navigations.sort(function(a, b){return Number(b.key) - Number(a.key)})
      state.navigationIndex = state.navigationIndex + 1;
    },
    saveViewConfigState(state, viewConfigState) {
      Vue.set(
        state.viewConfigStates,
        viewConfigState.viewConfig,
        viewConfigState,
      );
      // state.viewConfigStates[viewConfigState.viewConfig] = viewConfigState;
    },
  },
  getters: {
    /*
        CardConfigs
    */
    getNavigations: (state, getters) => {
      return state.navigations.sort(function (a, b) {
        return Number(b.key) - Number(a.key);
      });
    },
    getNavigationIndex: (state, getters) => {
      return state.navigationIndex;
    },
    getViewConfigState: (state, getters) => (viewConfig) => {
      return state.viewConfigStates[viewConfig];
    },
  },
  actions: {
    addNavigation({ dispatch, commit, getters }, payload) {
      var object = payload.object;
      var navigationConfigId = payload.splitCard;
      var config = payload.config;
      var image;
      var key = getters.getNavigationIndex;
      //TODO
      var description = "No description";
      if (object.Subject !== undefined && object.Subject !== null) {
        description = object.Subject;
      } else if (object.FullName !== undefined && object.FullName !== null) {
        description = object.FullName;
      } else if (object.Name1 !== undefined && object.Name1 !== null) {
        description = object.Name1;
      }

      var transformObject = {
        key: key,
        description: description,
        object: object,
        navigationConfigId: navigationConfigId,
        objectConfig: config.objectConfig,
      };
      commit("_addNavigation", transformObject);
    },
    updateStateViewConfig({ commit, getters }, payload) {
      const { viewConfig, fields } = payload;
      if (viewConfig && fields) {
        let newConfig = { viewConfig };
        if (getters.getViewConfigState(viewConfig)) {
          newConfig = getters.getViewConfigState(viewConfig);
        }
        Object.assign(newConfig, fields);
        commit("saveViewConfigState", newConfig);
      }
    },
  },
};
