<template>
  <v-card
    ref="_details"
    class="ma-0 pa-0 px-1 pb-1 transparent"
    flat
  >
    <resize-observer @notify="onResizeComponent" />
    <v-container
      class="mx-0 my-0"
      fluid
    >
      <v-row
        row
        justify-between
      >
        <v-col
          v-for="field in getFields"
          :key="calculateKey(field)"
          :cols="getWidth(field)"
        >
          <!-- <v-card
            flat
            class="mx-0 my-0"
          > -->
          <genericInput
            :viewconfig="viewconfig"
            :key="calculateKey(field)"
            :object="currentObject"
            :header="field"
            :config="getViewConfig"
            :height="getHeight(field)"
            :readonly="!editmode"
            :dense="dense"
            is-not-list
            align="center"
            @onDoubleClick="onDoubleClicked"
          />
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container
      v-if="editmode"
      fluid
    >
      <v-row>
        <v-col
          v-for="field in getFields"
          :key="calculateKey(field)"
          :cols="getWidth(field)"
        >
    
          <genericInput
            :keyobject="keyObject"
            :object="currentObject"
            :header="field"
            :operationid="operationid"
            :viewconfig="viewconfig"
            :height="getHeight(field)"
            :readonly="false"
            :dense="dense"
            align="center"
          />

        </v-col>
      </v-row>
    </v-container> -->
  </v-card>
</template>
<script>
//import genericDisplay from "../components/GenericDisplay.vue";
import * as UTIL from "../../api/utilities";

export default {
  name: "TileView",
  //components: { genericDisplay },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    editmode: {
      type: Boolean,
      default: undefined
    },
    operationid: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    },
    fieldsize: {
      type: String,
      default: "1"
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null,
      fields: null,
      totalWidth: 0,
      myDetailWidth: 0,
      keyObject: {
        key: 1
      }
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: this.fieldsize,
          list: this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    title() {
      return this.getViewConfig.title;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    currentObject() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.operationid
        );
        if (operationContainer) {
          return operationContainer.newObject;
        }

      }
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getMode() {
      if (this.isReadOnly) {
        return "display";
      }
      return "edit";
    },
    isReadOnly() {
      return !this.hasCurrentOperation;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    onDoubleClicked() {
      this.$emit('onDoubleClick')
    },
    getHeight(field) {
      if (field.type === "image") {
        return 200;
      }
      if (field.type === "textEdit") {
        return UTIL.pixelStringToInt(this.height);
      }
      return 45;
    },

    calculateKey(field) {
      var key = this.keyObject.key.toString().concat(field.Guid); //This must be done to update the view on aftermodify
      if (key.includes("undefined")) {
        console.log("wrong key", field);
      }
      return key;
    },
    calculateSpacer(field) {
      //This does not work
      return " addMargin ";
    },
    getWidth(field) {
      if (this.myDetailWidth < 300) {
        return "12";
      }
      if (field.type === "image" || field.type === "textEdit") {
        return "12";
      }
      if (UTIL.isEmpty(field.width)) {

        if (this.myDetailWidth > 1200) {
          return "3";
        }
        return "6";
      }
      // field width 11 is exception set full width, this so we can set bigger widths in listview for 12 and 11
      if (field.width === 11) {
        return "12"
      }
      return field.width;
    },
    onResizeComponent() {
      var container = this.$refs._details;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.$el.clientWidth;
        height = container.$el.clientHeight;
      }
      this.myDetailWidth = width;
    }
  }
};
</script>

<style>
.addMargin {
  margin-right: 8%;
}
</style>
