<template>
    <div
        style="height: 100%;"
        class="card"
    >

        <v-container
            fluid
            fill-height
        >
            <v-row
                style="height: 100%;"
                class="flex-nowrap px-2"
            >
                <v-col
                    :cols="12"
                    md="5"
                    class="d-flex px-6 flex-column"
                >

                    <div class="d-flex justify-between p">
                        <img
                            :src="getAppLogo"
                            height="32"
                        >
                        <div>
                            <v-select
                                v-model="$i18n.locale"
                                :items="languages"
                                item-text="name"
                                item-value="id"
                                :name="$t('language')"
                                :label="$t('language')"
                                class="text-field-transparent"
                                filled
                                solo
                                dense
                            />
                        </div>
                    </div>
                    <v-container
                        fluid
                        class="flex-1 d-flex "
                    >

                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-card
                                class="rounded-lg transparent elevation-0 kingSize"
                                style="max-width: 400px;"
                            >

                                <v-container v-if="step === 0">
                                    <v-form
                                        ref="form"
                                        class="kingSize"
                                        v-model="valid"
                                    >
                                        <div class="text-h4 font-weight-medium pb-6">{{ $t('welcomeTo') }} <span>{{
                        APP.name }}</span>
                                        </div>
                                        <div class="text-body-1 gray--text pb-6">{{ $t('fillInYourEmaiToGetStarted')
                                            }}
                                        </div>
                                        <v-text-field
                                            v-model="workspace.email"
                                            :rules="[rules.required, rules.email]"
                                            prepend-inner-icon="hi-envelope-outline"
                                            name="email"
                                            :label="$t('workEmail')"
                                            :placeholder="$t('janeDoe')"
                                            class="text-field-transparent"
                                            filled
                                        />

                                        <v-btn
                                            :disabled="!valid"
                                            color="primary"
                                            large
                                            depressed
                                            block
                                            @click.native="() => { step = 1 }"
                                        >{{ $t('next') }}</v-btn>

                                    </v-form>
                                </v-container>
                                <v-container
                                    v-else-if="step == 1"
                                    class="pt-6"
                                >
                                    <!-- <div
                                        class="text-body-2 gray--text"
                                        v-text="`${step}/${steps}`"
                                    /> -->

                                    <v-form
                                        ref="form"
                                        v-model="valid"
                                        v-if="step === 1"
                                    >

                                        <div
                                            class="text-h5 font-weight-medium "
                                            v-text="$t('CreateYourWorkspace')"
                                        />
                                        <v-container class="py-4">
                                            <v-row
                                                align="center"
                                                class="py-4 pb-8 flex-nowrap"
                                            >
                                                <v-card
                                                    class="rounded-lg tileBackgroundColor elevation-2 d-flex align-center justify-center mr-2"
                                                    style="height: 80px; width: 80px;"
                                                >
                                                    <img
                                                        v-if="!isEmpty(workspace.logo)"
                                                        class="rounded-lg"
                                                        :src="workspace.logo"
                                                        crossorigin="anonymous"
                                                        aspect-ratio="1"
                                                        width="80px"
                                                        height="80px"
                                                    />
                                                    <div
                                                        v-else
                                                        class="text-h2 pa-4"
                                                    >{{ getInitial }}</div>
                                                </v-card>
                                                <div>
                                                    <div
                                                        class="text-body-2 font-weight-medium px-2 pt-2"
                                                        v-text="$t('companyLogo')"
                                                    />
                                                    <v-row
                                                        no-gutters
                                                        class="pa-2"
                                                    >

                                                        <vue-dropzone
                                                            id="dropzone"
                                                            ref="myVueDropzone"
                                                            :options="dropzoneOptions"
                                                            :use-custom-slot="true"
                                                            style="min-height: 0;"
                                                            class="pa-0 no-border"
                                                            @vdropzone-sending="sendingMultiple"
                                                        >
                                                            <div class="dropzone-custom-content card">
                                                                <v-btn
                                                                    id="upload-avatar"
                                                                    color="gray"
                                                                    outlined
                                                                    class="primaryText--text rounded-lg mr-3"
                                                                >
                                                                    <v-icon
                                                                        class="mr-1">hi-arrow-up-tray-outline</v-icon>
                                                                    {{ $t('uploadImage') }}
                                                                </v-btn>
                                                            </div>
                                                        </vue-dropzone>
                                                        <v-btn
                                                            :disabled="isEmpty(workspace.logo)"
                                                            color="card"
                                                            class="rounded-lg elevation-0"
                                                            @click="deletePicture"
                                                        >
                                                            {{ $t('delete') }}
                                                        </v-btn>
                                                    </v-row>
                                                </div>
                                            </v-row>
                                        </v-container>

                                        <v-text-field
                                            v-model="workspace.name"
                                            :rules="[rules.required, rules.min, rules.forbidden]"
                                            name="company"
                                            :label="$t('companyName')"
                                            class="text-field-transparent"
                                            filled
                                            @input="updateHandle"
                                        />
                                        <v-text-field
                                            v-model="workspace.handle"
                                            :rules="[rules.required, rules.min, rules.forbidden, rules.alreadyUsed]"
                                            name="Handle"
                                            :label="$t('companyName')"
                                            :suffix="APP.handle"
                                            class="text-field-transparent"
                                            filled
                                            :error-messages="errors"
                                        />
                                        <v-select
                                            v-if="showTemplates"
                                            v-model="workspace.templates"
                                            :items="templates"
                                            item-text="name"
                                            item-value="handle"
                                            name="Starting template"
                                            :label="$t('template')"
                                            class="text-field-transparent"
                                            filled
                                            chips
                                            multiple
                                        />
                                        <v-btn
                                            :loading="loading"
                                            :disabled="!valid"
                                            color="primary"
                                            large
                                            depressed
                                            block
                                            @click.native="createWorkspace"
                                        >{{ $t('create') }}</v-btn>

                                    </v-form>
                                </v-container>
                                <v-container
                                    v-if="step === 2"
                                    fill-height
                                    fluid
                                >
                                    <v-row
                                        align="center"
                                        justify="center"
                                    >
                                        <v-card
                                            class="transparent elevation-0 px-4"
                                            max-width="500"
                                        >
                                            <v-row
                                                class="pl-2 pb-4"
                                                align="center"
                                                justify="center"
                                            >

                                                <LottieAnimation
                                                    :animation-data="successJSON"
                                                    :auto-play="true"
                                                    :loop="false"
                                                    :speed="1"
                                                    ref="anim"
                                                    style="width: 80px; height: 80px; position: absolute; z-index: 1;"
                                                />
                                                <v-icon
                                                    size="144"
                                                    color="primary"
                                                >hi-envelope</v-icon>

                                            </v-row>
                                            <v-card-title class="justify-center text-h5 font-weight-medium ">
                                                {{ $t('weHaveSentYouAnEmail') }}
                                            </v-card-title>
                                            <v-card-text class="text-center text-body-1">
                                                {{ $t('clickOnTheLinkToFinish') }}
                                            </v-card-text>

                                        </v-card>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-row>
                    </v-container>
                    <div>
                        <v-row
                            class="pb-4"
                            align="center"
                            justify="center"
                            no-gutters
                        >
                            <span class="text-caption text-center gray--text"> {{ $t('creatingAccountTerms') }} <a
                                    :href="APP.terms"
                                >{{ $t('termsAndconditions') }}</a> {{ $t('and') }}
                                <a :href="APP.privacyPolicy"> {{ $t('privacyPolicy') }}</a></span>


                        </v-row>
                    </div>
                </v-col>
                <v-col
                    :cols="0"
                    :md="7"
                    class=" d-sm-flex px-4"
                >

                    <v-container
                        :style="{ 'height': '100%', 'overflow': 'hidden' }"
                        class="pa-0 tileBackgroundColor rounded-xl"
                        :class="!isDark && 'darken-1'"
                    >
                        <Splash
                            :step="step"
                            :workspace="workspace"
                        />



                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import logo from '../../assets/logo-swiftio.svg';
import vue2Dropzone from "vue2-dropzone";
import { getGuid, isEmpty } from '../../api/utilities';
import { APP, BASE_URL, REALTIOR } from '../../api/constants';
import axios from 'axios';
import CreateAccount from './createAccount.vue';
import { ALERT_TYPE_ERROR } from '../../store/constants';
import { mapActions, mapGetters } from 'vuex';
import LottieAnimation from "lottie-web-vue"
import successJSON from "../../assets/animations/success.json"
import startedImg from "../../assets/swiftio-started.png"
import Splash from './splash.vue';
import { languages } from '../../api/locales/i18n';



const SAVED_TENANT = 'sw_savTen'

export default {
    components: {
        vueDropzone: vue2Dropzone,
        CreateAccount,
        LottieAnimation,
        Splash
    },

    data() {
        return {
            email: '',
            APP,
            logo,
            languages,
            successJSON,
            startedImg,
            size: '3vh',
            valid: false,
            used: false,
            errors: [],
            rules: {
                email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
                required: value => !!value || "Required.",
                min: v => v.length >= 4 || "Min 4 characters",
                forbidden: v => /[a-z\d\-_]+$/giy.test(v) || 'Only letters and hyphens allowed',
                alreadyUsed: this.isNotUsed
            },
            step: 0,
            steps: 2,
            dropzoneOptions: {
                url: "https://httpbin.org/post",
                maxFilesize: 10.0,
                addRemoveLinks: true,
                clickable: `#upload-avatar`,
                previewsContainer: false,
                // autoProcessQueue: false,
                acceptedFiles: 'image/*',
                maxFiles: 1,
                uploadMultiple: true,
                parallelUploads: 1
                // acceptedFiles: 'image/*,application/pdf'
            },
            templates: [
                // {
                //     name: 'No template',
                //     handle: null
                // },
                {
                    name: 'Crm',
                    handle: 'swiftio'
                },
                {
                    name: 'Solar',
                    handle: 'belsol'
                },
                {
                    name: 'Real Estate',
                    handle: 'realestate'
                },

            ],
            workspace: {
                name: '',
                handle: '',
                logo: '',
                templates: [],
                language: this.$i18n.locale,
                app: APP.id
            },
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['isDark', 'isAuthenticated']),
        getInitial() {
            if (isEmpty(this.workspace.handle)) {
                return 'A'
            }
            return this.workspace.handle.slice(0, 1).toUpperCase()
        },
        isNotUsed() {
            console.log(this.used || 'Is already in use')
            return this.used || 'Is already in use'
        },
        showTemplates() {
            if (APP.id === REALTIOR) {
                return false
            }
            return true
        },
        getAppLogo() {
            if (this.isDark) {
                return APP.logoDark
            } else {
                return APP.logo
            }
        }
    },
    created() {

        if (this.$router.router) {

        }
        const saved = JSON.parse(window.localStorage.getItem(SAVED_TENANT))
        if (saved) {
            this.workspace = JSON.parse(window.localStorage.getItem(SAVED_TENANT))
        }
        console.log('APP', APP)
        if (APP.id === REALTIOR) (
            this.workspace.templates = ['realestate']
        )

    },
    watch: {
        workspace: {
            handler(val) {
                this.checkHandle()
                if (val) {
                    window.localStorage.setItem(SAVED_TENANT, JSON.stringify(val))
                }

            },
            deep: true
        }
    },
    methods: {
        ...mapActions(["logout",]),
        isEmpty,
        updateHandle(value) {
            this.workspace.handle = value.toLowerCase()
        },
        async sendingMultiple(files) {
            if (files) {
                const payload = {
                    file: files,
                    folder: 'tenants',
                    guid: getGuid(),
                    global: true
                }
                this.workspace.logo = await this.$store.dispatch('uploadSingle', payload)
            }
        },
        async checkHandle() {
            let url = new URL(`${BASE_URL}/tenant/checkHandle?handle=${this.workspace.handle}`);
            var config = {
                headers: {},

            };
            axios.get(url, config).then(response => {
                this.errors = response.data ? [] : ['Handle already used']
            }).catch(error => {
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
            })
        },
        deletePicture() {
            this.workspace.logo = ''
        },
        finish() {
            window.localStorage.removeItem(SAVED_TENANT)
            this.$router.push('/')
            this.logout()
        },
        async createWorkspace() {
            this.loading = true
            let url = new URL(`${BASE_URL}/tenant/create`);
            this.workspace.language = this.$i18n.locale
            const data = this.workspace
            console.log(this.workspace)
            axios.put(url, data).then(response => {
                this.step = 2
                this.loading = false
            }).catch(error => {
                this.loading = false
                this.$store.commit("showAlert", {
                    type: ALERT_TYPE_ERROR,
                    message: error
                });
            })

        }
    }
};
</script>
