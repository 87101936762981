<template>
  <v-container
    fluid
    grid-list-xs
    text-xs-center
    class="ma-0 pa-0 rounded-lg"
  >
    <v-card
      flat
      class="ma-0 pa-3 rounded-lg"
    >
      <v-layout row>
        <v-card
          flat
          class="ma-3"
        >
          <h2 text-xs-center>{{ getTitle }}</h2>
        </v-card>
        <v-spacer />
        <v-btn
          icon
          @click.native="toggleConfig()"
        >
          <v-icon color="iconColorLight">hi-cog-6-tooth</v-icon>
        </v-btn>
      </v-layout>
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="showConfig"
          class="xs12 lg4 xl3"
        >
          <v-card
            v-if="showConfigView"
            flat
          >
            <chartConfig
              :height="height"
              :viewconfig="viewconfig"
              title="Title"
            />
          </v-card>
        </v-flex>
        <v-flex :class="getClassChart">
          <v-card flat>
            <chartView
              :height="height"
              :viewconfig="viewconfig"
              title="Title"
            />

          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import chartConfig from "./ChartConfig";
import chartView from "./ChartView";
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities";

export default {
  components: { chartConfig, chartView, },
  props: {
    height: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: " "
    },
    viewconfig: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {
      showConfig: false,
      showConfigView: false
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getChartId() {
      return this.getViewConfig.subViewConfig;
    },
    isDateChart() {
      var fields = this.getFieldsForType(Constants.CHART_X);
      var field = fields[0];
      if (
        !UTIL.isEmpty(field) &&
        (field.calculationTypeX === "lineDate" ||
          field.calculationTypeX === "barDate")
      ) {
        return true;
      }
    },
    getTitle() {
      var config = this.getViewConfig;
      if (config === undefined) {
        return "";
      }
      return this.$store.getters.getViewConfigForID(this.viewconfig).title;
    },
    getClassChart() {
      if (this.showConfig) {
        return "xs12 lg8 xl9";
      }
      return "xs12";
    }
  },

  mounted: function () { },
  methods: {
    toggleConfig() {
      this.showConfig = !this.showConfig;
      if (this.showConfig) {
        setTimeout(this.showView, 10);
      } else {
        this.showConfigView = false;
      }
    },
    showView() {
      this.showConfigView = true;
    },
    /**
     * Fields
     */
    getFieldsForType(type) {
      var payload = {
        type: type,
        chartId: this.getChartId
      };
      return this.$store.getters.getChartFieldsForChartIdAndType(payload);
    }
  }
};
</script>
