import moment from "moment";
import {
  CARD_CONFIG,
  DATE_FORMAT,
  FILTER_CONFIG,
  FILTER_FIELD_CONFIG,
  OPERATION,
  SYNCHRONISATION,
  VALUE_LIST_CONFIG,
  VIEW_CONFIG,
  VIEW_FIELD
} from "./constants";
import { isEmpty } from "../api/utilities";
import { copyObject } from "../api/objectApi";
import Vue from "vue";

const getOldObjectIndex = (objectConfig, list, object) => {
  return objectConfig.getOldObjectAndIndexInList(list, object);
};

const updateObjectInCache = (
  state,
  cacheConfig,
  object,
  objectConfig,
  replace
) => {
  console.log("Set object");
  var newObject = JSON.parse(JSON.stringify(object));
  var keyValue = object[cacheConfig.keyField];
  if (!isEmpty(keyValue)) {
    if (
      state.uiCache[cacheConfig.objectType] === null ||
      state.uiCache[cacheConfig.objectType] === undefined
    ) {
      Vue.set(state.uiCache, cacheConfig.objectType, {});
    }
    var hashMap = state.uiCache[cacheConfig.objectType];
    if (!hashMap[keyValue]) {
      Vue.set(hashMap, keyValue, []);
    }
    //Hier moeten we inserten.
    if (replace === true) {
      var list = hashMap[keyValue];
      var oldObjectInfo = getOldObjectIndex(objectConfig, list, newObject);
      var index = 0;
      if (oldObjectInfo.index >= 0) {
        index = oldObjectInfo.index;
      } else {
        index = list.length;
      }
      if (newObject.isDeleted !== true && newObject.isDeleted !== 1) {
        Vue.set(list, index, newObject);
      } else {
        list.splice(index, 1);
      }
    } else {
      if (newObject.isDeleted !== true && newObject.isDeleted !== 1) {
        hashMap[keyValue].push(newObject);
      }
    }

    if (!isEmpty(cacheConfig.sortField)) {
      hashMap[keyValue].sort(
        (a, b) => a[cacheConfig.sortField] - b[cacheConfig.sortField]
      );
    }
  }
};

export default {
  state: {
    uiCache: {}
  },

  getters: {
    getIsCacheActive: (state, getters) => objectType => {
      if (objectType === FILTER_FIELD_CONFIG) {
        return false;
      }
      return true;
    },
    getUiCacheForObject: (state, getters) => {
      return state.uiCache;
    },
    getCachedObjectsForType: (state, getters) => (objectType, value) => {
      if (isEmpty(state.uiCache[objectType])) {
        return [];
      }
      var list = state.uiCache[objectType][value];
      if (isEmpty(list)) {
        return [];
      }
      return list;
    },
    getCachedObjectTypes: (state, getters) => {
      var cacheObjectsTypes = {};
      cacheObjectsTypes[VALUE_LIST_CONFIG] = {
        objectType: VALUE_LIST_CONFIG,
        keyField: "valueList",
        sortField: "position"
      };
      cacheObjectsTypes[VIEW_FIELD] = {
        objectType: VIEW_FIELD,
        keyField: "fieldList",
        sortField: "position"
      };
      cacheObjectsTypes[VIEW_CONFIG] = {
        objectType: VIEW_CONFIG,
        cached: true,
        keyField: "viewConfig"
      };

      cacheObjectsTypes[FILTER_CONFIG] = {
        objectType: FILTER_CONFIG,
        keyField: "filterQuery",
        sortField: "position"
      };
      cacheObjectsTypes[FILTER_FIELD_CONFIG] = {
        objectType: FILTER_FIELD_CONFIG,
        keyField: "filterFields"
      };
      cacheObjectsTypes[CARD_CONFIG] = {
        objectType: CARD_CONFIG,
        keyField: "viewConfig",
        sortField: "position"
      };
      return cacheObjectsTypes;
    }
  },
  mutations: {
    setObjectsInCacheForObjectType(state, payload) {
      var cacheConfig = payload.cacheConfig;
      for (var i = 0; i < payload.objects.length; i++) {
        var object = payload.objects[i];
        updateObjectInCache(state, cacheConfig, object);
        /*
        var keyValue = object[cacheConfig.keyField];
        if (!isEmpty(keyValue)) {
          if (!hashMap[keyValue]) {
            hashMap[keyValue] = [];
          }
          hashMap[keyValue].push(object);
        }
        */
      }
      //var hashMap = state.uiCache[cacheConfig.objectType];
      /*
      if (!isEmpty(cacheConfig.sortField)) {
        for (const key in hashMap) {
          hashMap[key].sort(
            (a, b) => a[cacheConfig.sortField] - b[cacheConfig.sortField]
          );
        }
      }
      //state.uiCache[cacheConfig.objectType] = hashMap;
      */
    },
    /**
     * @param {*} payload: object + objectConfig
     */
    setObjectInCache(state, { payload, getters }) {
      //If Array does not exist it will be the first object in the store.
      var cacheConfig =
        getters.getCachedObjectTypes[payload.objectConfig.objectType];
      if (isEmpty(cacheConfig)) {
        return;
      }
      updateObjectInCache(
        state,
        cacheConfig,
        payload.object,
        payload.objectConfig,
        true
      );
    }
    //Get the old object in the store to replace.
  },
  actions: {
    async setAllObjectsInFastCache({ dispatch, commit, getters }) {
      console.log("Incache started");
      var cacheObjects = copyObject(getters.getCachedObjectTypes);
      console.log(cacheObjects);
      var keys = Object.keys(cacheObjects);
      console.log("keys", keys);
      for (var i = 0; i < keys.length; i++) {
        var objectType = keys[i];
        var cacheConfig = cacheObjects[objectType];
        var payload = {
          cacheConfig
        };
        if (!isEmpty(cacheConfig) && !isEmpty(cacheConfig.objectType)) {
          var objects = getters.getDataObjectsForObjectType(
            cacheConfig.objectType
          );
          payload.objects = objects;

          commit("setObjectsInCacheForObjectType", payload);
        }
      }
      console.log("Incache completed", getters.getUiCacheForObject);
    }
  }
};
