import * as Constants from "../store/constants";
import * as UTIL from "../api/utilities";
import Vue from "vue"


export default {
  state: { massUpdates: {} },

  mutations: {
    setMassUpdate(state, payload) {
      console.log("update the mass updates")
      Vue.set(state.massUpdates, payload.massUpdateViewConfig, payload.massUpdate);
    },
  },
  getters: {
    isMassUpdateForViewConfig: (state, getters) => viewconfig => {
      return state.massUpdates[viewconfig];
    }
  },
  actions: {
    closeMassUpdateViewDialog({ dispatch, commit, getters }, viewconfig) {
      console.log("Close mass in action")
      var payload = {
        massUpdateViewConfig: viewconfig,
        massUpdate: false
      }
      commit("setMassUpdate", payload);
    },
    goToMassUpdate({ dispatch, commit, getters }, payload) {
      commit("setMassUpdate", payload);
    }
  }
};
