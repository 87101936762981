<template>
  <v-stepper
    :value="currentIndex"
    flat
  >
    <v-stepper-header flat>
      <v-stepper-step
        v-for="stage in getValueListItems()"
        :key="stage.value"
        :step="stage.index"
        :complete="stage.complete"
        :complete-icon="stage.icon"
        :editable="!stage.complete"
        flat
        @click.native="onClickStep(stage)"
      >{{ stage.descr }}</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import * as theme from "../../theme/themeDefault";
import * as UTIL from "../../api/utilities.js";
import Vue from 'vue'

export default {
  //components: { loadingView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentIndex: 0,
      list: null,
      previousValue: null
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: "5",
          list: "edit"
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      return fields;
    },
    getField() {
      var fields = this.getFields;
      if (fields.length > 0) {
        return fields[0];
      }
      return {};
    },
    getCurrentValue() {
      if (UTIL.isEmpty(this.object)) {
        return "";
      }
      return this.object[this.getField.value];
    },
    getType() {
      return this.getField.dropdown; //salesStageCode
    }
  },
  methods: {
    getIcon(stage) {
      return ''
    },
    onClickStep(stage) {
      const oldObject = UTIL.copyObject(this.object)
      if (UTIL.isEmpty(this.getField.value) || UTIL.isEmpty(this.getField.value) || UTIL.isEmpty(this.getViewConfig.objectType)) {
        return
      }
      Vue.set(this.object, this.getField.value, stage.value)
      this.$store.dispatch("saveObjectForObjectType", { object: this.object, objectType: this.getViewConfig.objectType, oldObject })
    },
    getValueListItems() {
      if (
        this.previousValue !== null &&
        this.previousValue === this.getCurrentValue
      ) {
        return this.list;
      }
      this.previousValue = this.getCurrentValue;
      var list = this.$store.getters.getValueListForId(this.getType);

      //Calculate Complete
      var icon = "";
      var complete = true;
      var myList = []
      for (var i = 0; i < list.length; i++) {
        
        var listItem =  structuredClone(list[i]);
        if (UTIL.isEmpty(listItem.value)) {
          continue
        }
        
        listItem.complete = complete;
        if (listItem.value === this.getCurrentValue || i === list.length) {
          complete = false;
          this.currentIndex = i;
        }
      
        listItem.index = i + 1;
        if (complete) {
          icon = "hi-check";
        }
        if (listItem.value === this.getCurrentValue || i === list.length) {
          icon = "hi-arrow-down-circle-outline"
        }
        listItem.icon = icon;
       
        myList.push(listItem)
      }
      this.list = myList;
      console.log("list")
      return this.list;
    }
  }
};
</script>
