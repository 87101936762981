import * as coreScripts from "./coreObject/coreObjectScripts";
import * as customScripts from "./customObject/customObjectScripts";
import * as Constants from "../store/constants";
import * as Utilities from "../api/utilities.js";
import { fieldTypeIsCorrect } from "../api/objectApi";
import { SAVE_ERROR } from "../store/operationContext";

/*
  Before save in general.
  This will define to which scripts it is send. This is done te separate the custom logic from the normal logic.
 */

export const beforeSave = (objectConfig, object, context, options) => {
  var canSave;
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    canSave = coreScripts.beforeSave(objectConfig, object, context, options);
  } else {
    canSave = customScripts.beforeSave(objectConfig, object, context, options);
  }

  if (Utilities.isEmpty(canSave)) {
    canSave = {};
  }
  if (canSave.state === SAVE_ERROR) {
    return canSave;
  }

  var checkFieldType = Utilities.isEmpty(options) || options.fromSync !== true;
  // Fill the display related values
  var field;
  var fields = objectConfig.fieldsConfig;
  for (var i = 0; i < fields.length; i++) {
    field = fields[i];
    if (
      !Utilities.isEmpty(field.relatedObjectField) &&
      Utilities.isEmpty(field.relatedObjectType)
    ) {
      var value = object[field.relatedObjectField];
      if (!Utilities.isEmpty(value)) {
        var fieldId = fields.find(function(element) {
          return element.field === field.relatedObjectField;
        });
        if (!Utilities.isEmpty(fieldId.dropdown)) {
          var list = context.getters.getValueListForId(fieldId.dropdown);
          var value = object[field.relatedObjectField];
          if (!Utilities.isEmpty(list)) {
            var valueListIem = list.find(function(element) {
              return element.value === value;
            });
            if (!Utilities.isEmpty(valueListIem)) {
              object[field.field] = valueListIem.descr;
            } else {
              object[field.field] = "";
            }
          }
        } else {
          object[field.field] = "";
        }
      }
    }
    if ((checkFieldType = true)) {
      var isCorrect = fieldTypeIsCorrect(object, field);
      if (isCorrect === false) {
        var message =
          "Error while saving " +
          objectConfig.objectConfig.objectType +
          ": " +
          field.field +
          ": " +
          object[field.field] +
          " is not a " +
          field.type;
        context.commit("showAlert", {
          type: Constants.ALERT_TYPE_ERROR,
          message
        });
        canSave.state = SAVE_ERROR;
      }
    }
  }

  return canSave;
};

/**
 * After Create General
 * This method will do the general creation.
 * - It will make the link with the current selected object.
 * - It will call the after create based on the type of the object.
 *
 */
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  var newObject = object;
  //We will try to link.

  if (relatedObject !== null && relatedObject.object !== undefined) {
    var relObject = relatedObject.object;
    var filterId = relatedObject.viewConfig.queryFilter;
    var filterConfig = context.getters.getCurrentFilterForFilterId(filterId);

    if (filterConfig !== undefined && filterConfig !== null) {
      var filterFields = context.getters.getFieldsForFilter(
        filterConfig.filterFields
      );
      for (var i = 0; i < filterFields.length; i++) {
        var filterField = filterFields[i];
        if (filterField.comparator === "==") {
          if (!Utilities.isEmpty(filterField.linkedObjectField)) {
            newObject[filterField.field] =
              relObject[filterField.linkedObjectField];
          } else {
            newObject[filterField.field] = filterField.value;
          }
        }
      }
    }
  }

  //We can do here the filter.

  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    newObject = coreScripts.afterCreate(
      objectConfig,
      newObject,
      relatedObject,
      context,
      options
    );
  }
  // We should see with the sync group of CRM.
  else {
    newObject = customScripts.afterCreate(
      objectConfig,
      newObject,
      relatedObject,
      context,
      options
    );
  }
  return newObject;
};

/**
 *
 * @param {A} objectConfig
 * @param {*} object
 * @param {*} context
 * @param {*} options
 */

export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  var newObject = object;
  //We will try to link.

  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    newObject = coreScripts.afterModify(
      objectConfig,
      newObject,
      relatedObject,
      context,
      options
    );
  } else {
    newObject = customScripts.afterModify(
      objectConfig,
      newObject,
      relatedObject,
      context,
      options
    );
  }
  return newObject;
};

/*
   Before save in general.
   This will define to which scripts it is send. This is done te separate the custom logic from the normal logic.
  */

export const getTile = (objectConfig, object, context, options) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.getTile(objectConfig, object, context, options);
  } else {
    return customScripts.getTile(objectConfig, object, context, options);
  }
};

/**
 * Script: Get Fields
 */

export const getFieldsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  fields,
  context,
  options
) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.getFieldsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      fields,
      context,
      options
    );
  } else {
    return customScripts.getFieldsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      fields,
      context,
      options
    );
  }
};

/**
 * Script: Get Tabs
 */
export const getTabsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options
) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.getTabsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  } else {
    return customScripts.getTabsForObjectAndConfig(
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  }
};

/**
 * Script: Get Tabs
 */
export const hasCustomAuthorisation = (
  objectConfig,
  object,
  viewConfig,
  context,
  options
) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.hasCustomAuthorisation(
      objectConfig,
      object,
      viewConfig,
      context,
      options
    );
  } else {
    return customScripts.hasCustomAuthorisation(
      objectConfig,
      object,
      viewConfig,
      context,
      options
    );
  }
};

/**
 * Script: Launch Action
 */
export const launchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options
) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.launchActionForObjectAndConfig(
      action,
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  } else {
    return customScripts.launchActionForObjectAndConfig(
      action,
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  }
};

/**
 * Script: Launch Action
 */
export const canLaunchActionForObjectAndConfig = (
  action,
  objectConfig,
  object,
  viewConfig,
  tabs,
  context,
  options
) => {
  if (
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_FIELDS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_OBJECTS ||
    objectConfig.objectConfig.syncGroup === Constants.SYNC_GROUP_CORE_REST
  ) {
    return coreScripts.canLaunchActionForObjectAndConfig(
      action,
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  } else {
    return customScripts.canLaunchActionForObjectAndConfig(
      action,
      objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options
    );
  }
};
