import Vue from "vue";
import VueI18n from "vue-i18n";

// Import language translations
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import pl from "./pl.json";
Vue.use(VueI18n);

const messages = {
  en,
  es,
  fr,
  pl,
};

export const languages = [
  {
    name: "English",
    id: "en",
  },
  {
    name: "Español",
    id: "es",
  },
  {
    name: "Français",
    id: "fr",
  },
  {
    name: "Polska",
    id: "pl",
  },
];

const i18n = new VueI18n({
  locale: "en", // default language
  messages,
});

export default i18n;
