/*
    Rest configuration to link the tool with The mongo DB backend.
*/

export const url =
  "https://sapd1c020.tessenderlo.com/sap/opu/odata/crmgws/GROUPWARE_SYNC/";

export const buildRestUpdateCE7 = (syncGroup, object, objectConfig) => {
  //Beter to encapsulate this.
  var newObject = {};
  var method = "get";
  objectConfig.fieldsConfig.forEach(field => {
    if (
      field.backendField !== null &&
      field.backendField !== undefined &&
      field.backendField !== "" &&
      object[field.field] !== undefined &&
      object[field.field] !== null &&
      field.edit !== false
    ) {
      newObject[field.backendField] = object[field.field];
    }
  });
  return {
    object: object,
    body: newObject,
    method: method,
    getNeeded: true,
    url:
      url +
      objectConfig.objectConfig.objectType +
      getUpdateEndUrl(objectConfig, object, "get")
  };
};

export const buildRestGetCE7 = (objectConfig, synchronization) => {
  var restConfig = {
    url: url + objectConfig.objectConfig.objectType + getFilter(objectConfig),
    method: "get",
    delta: "",
    headers: {
      Authorization: "Basic TlBFRVQ6U3FsITIwMTk2", //TlBFRVQ6U3FsITIwMTk1
      "Access-Control-Allow-Origin": "*"
    }
  };
  return restConfig;
};

export const getUpdateEndUrl = (objectConfig, object, method) => {
  var endUrl = "Collection(guid'" + object.Guid + "')";
  if (method === "get") {
    endUrl = endUrl + "?$format=json";
  }
  if (method === "fetch") {
    endUrl = endUrl + "?$format=json";
  }
  return endUrl;
};

export const updateSyncObjectAndHeadersForGetCE7 = (
  syncObject,
  config,
  objectConfig
) => {
  config.withCredentials = true;
  config.auth = {
    username: "NPEET",
    password: "Sql!20196"
  };
  config.headers.Authorization = "Basic TlBFRVQ6U3FsITIwMTk2";
};

export const updateSyncObjectAndHeadersCE7 = (
  syncObject,
  config,
  objectConfig,
  response
) => {
  syncObject.url =
    url +
    objectConfig.objectConfig.objectType +
    getUpdateEndUrl(objectConfig, syncObject.object, "post");
  syncObject.method = "post";
  config.headers["content-type"] = "application/json; charset=utf-8";
  config.headers["if-match"] = response.headers.etag;
  config.headers["x-csrf-token"] = response.headers["x-csrf-token"];
};

export const getFilter = objectConfig => {
  var filter = "";
  if ("AccountMain" === objectConfig.objectConfig.objectType) {
    filter = "MySalesOrg";
  }
  if ("ContactMain" === objectConfig.objectConfig.objectType) {
    filter = "";
    //filter = "MyAccounts";
  }
  if ("AppointmentMain" === objectConfig.objectConfig.objectType) {
    filter = "MyCalendar";
  }
  if ("TaskMain" === objectConfig.objectConfig.objectType) {
    filter = "Me";
  }
  if ("EmployeeMain" === objectConfig.objectConfig.objectType) {
    filter = "MySalesOrg";
  }
  if (filter === "") {
    return "Collection";
  }
  return "Collection?$filter=BelongingTo%20eq%20%27" + filter + "%27";
};
