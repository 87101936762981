<template>
  <div
    :style="`height: 100%`"
    class="pa-0"
    ref="mapContainer"
  >
    <!-- <resize-observer @notify="onResizeComponent" /> -->

    <div
      :ref="mapRef"
      style="width: 100%;height: 100%; "
      class="rounded-lg mapContainer"
    ></div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as UTIL from "../../api/utilities";
import { ADDRESS, SELECT_OBJECT, VIEW_FIELD } from '../../store/constants';
import MapsInfoTileVue from './customTiles/MapsInfoTile.js';
import mapsImage from '../../assets/maps.svg'
import { getCurrentTheme } from '../../theme/themeDefault';

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    circlemarkers: {
      type: Array,
      default: null
    },
    markers: {
      type: Array,
      default: null
    },
    disableDefaultUi: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false,
    mapsImage,
    update: null,
    bounds: null,
    mapRef: null,
    isMounted: false,
    shownMarkers: [],
    map: null,
    width: 400,
    height: 600,
    icon: 'https://swiftio.io/map-pin.png',
    mapIds: {
      light: 'bd5d24f13a49bb3d',
      dark: '79759482730334c8'
    },
    position: { lat: 50.88097, lng: 4.699063 },
    options: {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUI: false,
      mapTypeId: 'roadmap',
    },
    zoom: null,
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    infowindow: null,
    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 10,
        height: 0
      }
    },
    anchor: null
  }),
  computed: {
    ...mapGetters(['isDark']),
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {

        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getSavedState() {
      if (this.getOptions && this.getOptions.parentViewconfig) {
        const parentViewconfig = this.$store.getters.getViewConfigState(this.getOptions.parentViewconfig)
        if (parentViewconfig) {
          return parentViewconfig.items
        }

      }
      return null
    },
    getFilteredObjects() {
      const savedState = this.getSavedState

      if (savedState) {
        return savedState
      }
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      //FilterOutWithoutLatAndLng
      var filteredLocations = filteredObject.filter(object => ((!UTIL.isEmpty(object.lat) || !UTIL.isEmpty(object.Lat)) && (!UTIL.isEmpty(object.lng) || !UTIL.isEmpty(object.Lng))));
      // this.updateBounds()
      return filteredLocations;
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.getCurrentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.getViewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "edit" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },

    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getMarkers() {
      return this.getFilteredObjects;
    },
    getCircleMarkers() {
      return this.circlemarkers
    },
    getColor() {
      return this.$vuetify.theme.themes.light.primary;
    },
    getStroke() {
      return getCurrentTheme(this.$vuetify.theme.isDark).sideBarColor
    },
    getSVGMarker() {
      var svgMarker = {
        path: "M9.1-0.1c-5-0.1-9,3.9-9.1,8.9C0,8.8,0,8.9,0,9c0.1,3.6,1.7,7.1,4.3,9.6c0.9,0.9,1.9,1.7,2.9,2.5c0.4,0.3,0.7,0.5,1,0.6c0.1,0.1,0.2,0.1,0.3,0.2l0.1,0l0,0c0.2,0.1,0.5,0.1,0.8,0l0,0l0.1,0c0.1,0,0.2-0.1,0.3-0.2c0.2-0.1,0.6-0.4,1-0.6c1-0.7,2-1.6,2.9-2.5c2.6-2.5,4.2-6,4.3-9.6C18,4,14,0,9.1-0.1z M9,12c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3C12.2,10.5,10.8,12,9,12z",
        fillColor: this.getColor,
        fillRule: "evenodd",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: this.getStroke,
        rotation: 0,
        scale: 1,
        anchor: this.anchor,
      };
      return svgMarker
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined && this.height !== 0) {
        return UTIL.addIntToPixelString(this.height, 50);
      } else {
        return "100%";
      }
    },

    getMapId() {
      return this.mapIds[this.isDark ? 'dark' : 'light']
    },
    getStyle() {
      return "overflow: hidden; width: 100%; min-height: 300px; height: " + this.getHeight + ";";
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
  },
  created() {
    this.mapRef = (Math.random() + 1).toString(36).substring(7);
    let that = this;

    window[this.viewconfig] = function (Guid, viewFieldGuid) {
      if (Guid, viewFieldGuid) {
        that.onClickInfo(Guid, viewFieldGuid)
      }
    }
  },
  mounted() {
    this.isMounted = true
    this.updateMap(this.getFilteredObjects)
  },
  watch: {
    'isDark'(to, from) {
      if (this.map) {
        this.updateMap(this.getFilteredObjects)
      }
    },
    'getFilteredObjects'(to, from) {
      if (this.map) {
        this.updateMap(to)
      }
    },
  },
  methods: {
    getLatLong(marker) {
      if (UTIL.isEmpty(marker) || (!UTIL.isEmpty(marker) && UTIL.isEmpty(marker.lat || marker.Lat) && UTIL.isEmpty(marker.lng || marker.Lng))) {
        return null
      } else {
        try {
          const lat = parseFloat(marker.lat || marker.Lat)
          const lng = parseFloat(marker.lng || marker.Lng)
          return { lat, lng }
        } catch (error) {
          return null
        }
      }
    },
    updateMap(objects) {
      if (this.mapRef && this.$refs[this.mapRef]) {
        if (!this.map) {
          this.map = new google.maps.Map(this.$refs[this.mapRef], { mapId: this.getMapId, mapTypeControl: false, });
        }

        this.anchor = new google.maps.Point(0, 20);
        this.infowindow = new google.maps.InfoWindow({
          content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker

        });
        for (let index = 0; index < this.shownMarkers.length; index++) {
          const marker = this.shownMarkers[index];
          marker.setMap(null);
        }
        this.shownMarkers = []
        if (objects && objects.length > 0) {
          const bounds = new google.maps.LatLngBounds()
          var latLong = {}

          for (let m of objects) {
            latLong = this.getLatLong(m)
            if (latLong) {
              bounds.extend(latLong)

              const marker = new google.maps.Marker({
                position: latLong,
                icon: this.getSVGMarker,
                map: this.map,
              });
              marker.addListener("click", () => {
                this.toggleInfoWindow(marker, m)
              });
              this.shownMarkers.push(marker)
            }

          }

          this.bounds = bounds
          if (objects.length === 1) {
            this.map.setZoom(14)
            this.map.setCenter(latLong);
          } else {
            this.map.fitBounds(bounds);
          }

        }
      } else {
        this.map.setMapTypeId(this.gem)
      }
    },

    toggleInfoWindow(marker, object) {
      this.center = marker.position
      this.infoWindowPos = { lat: object.lat || object.Lat, lng: object.lng || object.Lng };
      if (!UTIL.isEmptyArray(this.getFields)) {
        var fields = null
        const options = this.getOptions
        if (options && options.related && options.related.parentTypeField && options.related.parentGuidField && options.related.subjectField) {
          const related = this.getRelatedObject(object[options.related.parentTypeField], object[options.related.parentGuidField])
          if (related) {

            fields = [
              { Guid: object.Guid, title: related[options.related.subjectField], viewFieldGuid: options.related.viewFieldGuid }
            ]
          }
        }
        if (UTIL.isEmpty(fields)) {
          fields = this.getFields.map(field => {
            const viewFieldGuid = !UTIL.isEmpty(field.navigationId) && !UTIL.isEmpty(field.navigationConfigId) ? field.Guid : null
            const title = !UTIL.isEmpty(field.relatedObjectType) && !UTIL.isEmpty(field.displayRelatedObject) ? this.$store.getters.getRelatedDisplayForViewField({ value: object[field.valueRelatedObject], viewfield: field }) : object[field.value]
            return { Guid: object.Guid, title, viewFieldGuid }
          })

        }
        this.infowindow.setContent(MapsInfoTileVue(fields, this.viewconfig));



        setTimeout(() => {
          this.infowindow.open({
            anchor: marker,
            map: this.map,
          });
        }, 50)

      }
    },
    getRelatedObject(objectType, objectId) {
      return this.$store.getters.getDataObjectForIdAndObjectType({
        objectType,
        objectId
      })

    },
    getViewFieldById(id) {
      return this.getRelatedObject(VIEW_FIELD, id)
    },
    onClickInfo(Guid, viewFieldGuid) {
      const options = this.getOptions
      var object = this.getFilteredObjects.find(item => item['Guid'] === Guid)
      if (options && options.related && options.related.parentTypeField && options.related.parentGuidField) {
        object = this.getRelatedObject(object[options.related.parentTypeField], object[options.related.parentGuidField])
      }

      if (object) {
        const viewField = this.getViewFieldById(viewFieldGuid)
        if (viewField) {
          this.navigateTo(object, viewField)
        }
      }
    },
    navigateTo(object, field) {
      if (UTIL.isEmpty(object[field.value])) {
        return;
      }
      this.dialog = false
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(SELECT_OBJECT, payload);

    },

  }
};


</script>
