<template>
  <v-container
    ref="containerView"
    fluid
    white
    class="ma-0 pa-0"
  >
    <resize-observer @notify="onResizeComponent" />
    <v-card>
      <v-card>
        <v-select
          :items="items"
          v-model="nbItemsPerRow"
        />
        <v-spacer />{{ message }}
      </v-card>
      <v-container
        v-bind="{ [`grid-list-${size}`]: true }"
        fluid
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="n in 9"
            :class="getTileFlexClass"
            :key="n"
          >
            <v-card
              ref="tileCard"
              :class="getClassOfItem(n)"
              :style="getStyleForTile(n)"
              :id="getTileIdForIndex(n)"
              flat
              tile
            >
              <v-card
                :src="getImageForTile(n)"
                height="350px"
                @click="openItem(n)"
              />
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-container
      id="backgroundView"
      ref="backgroundView"
      :style="getBackgroundTranslateStyle"
      :class="getClassOfBackground"
      fluid
    >
      <v-card
        style="text-align:right; background-color:white; height: 1500px;"
        class="ma-0 pa-0"
      />
    </v-container>
    <v-container
      id="detailsView"
      ref="detailsView"
      :style="getTranslateDetailsStyle"
      :class="getDetailsClass"
      fluid
    >
      <v-layout row>
        <v-flex xs12>
          <v-card
            flat
            class="ma-0 pa-0"
          >
            <productIphone />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container
      v-show="closeButtonViewShown"
      :style="getCloseButtonStyle"
      :class="getCloseButtonClass"
      fluid
    >
      <v-layout
        row
        justify-between
      >
        <v-flex xs12>
          <spacer />
        </v-flex>
        <v-flex><v-btn
            round
            small
            @click.stop="closeBackground()"
          >X</v-btn></v-flex>
      </v-layout>
    </v-container>
  </v-container>
</template>

<script>
import image3x from "../../assets/ProductIphone/iphone_large3x.jpg";
import image6 from "../../assets/ProductIphone/iphone6.jpg";
import productIphone from "./ProductIphone.vue";
export default {
  components: { productIphone },
  data() {
    return {
      productList: "MyProducts",
      size: "xs",
      items: [
        { text: "My Products", value: 3 },
        { text: "Teams Products", value: 2 },
        { text: "All Products", value: 1 },
        { text: "All Products 2", value: 4 }
      ],
      nbItemsPerRow: 3,
      selected: -1,
      changeIndex: 0,
      message: "nothing",
      calculatedPosition: -1,
      translateValue: 0,
      closeButtonVisible: false,
      closeButtonViewShown: false,
      showDetails: false,
      detailImage: -1,
      onTop: -1,
      containerView: null,
      containerViewWidth: 0
    };
  },
  computed: {
    isSelected() {
      return this.selected <= 0;
    },
    /*
      BackgroundView
    */
    getBackgroundPosition() {
      var id = "backgroundView";
      var tile = document.getElementById(id);
      var divOffset = this.offset(tile);
      if (divOffset !== undefined) {
        return divOffset.top;
      }
      return 0;
    },
    getBackgroundTranslate() {
      var fromTop = this.getBackgroundPosition;
      return fromTop * -1;
    },
    getBackgroundTranslateStyle() {
      var translateValue = this.translateValue;
      return "-webkit-transform: translateX("
        .concat(translateValue)
        .concat("px ; transform: translateY(")
        .concat(translateValue)
        .concat("px; ");
    },
    /*
      DetailsView
    */
    getTranslateDetailsStyle() {
      var translateValue = 0;
      if (this.showDetails !== true) {
        var height = 1080 + 4000;
        translateValue = -height;
        return "-webkit-transform: translateX("
          .concat(translateValue)
          .concat("% ; transform: translateY(")
          .concat(translateValue)
          .concat("px; ");
      }
      return "-webkit-transform: translateX("
        .concat(translateValue)
        .concat("px ; transform: translateY(")
        .concat(translateValue)
        .concat("px; ");
    },
    getDetailsClass() {
      return "detailsView ma-0 pa-0";
    },
    /*
      CloseButton
    */
    getCloseButtonStyle() {
      if (this.closeButtonVisible === true) {
        return "opacity: 1;";
      }
      return "opacity: 0;";
    },
    getClassOfBackground() {
      return "backgroundView ma-0 pa-0";
    },
    getCloseButtonClass() {
      if (this.closeButtonVisible === true) {
        return "closeButtonView closeButtonViewVisible";
      } else {
        return "closeButtonView";
      }
    },
    /*
      ScreenDetails
    */
    getWidthOfScreen() {
      return this.containerViewWidth; // todo
    },
    getNumberOfTilesPerRow() {
      return this.nbItemsPerRow;
    },
    getSpaceBetweenTiles() {
      return 4;
    },
    getTileFlexClass() {
      // return 'xs4'
      var value = 12 / this.getNumberOfTilesPerRow;
      return "xs".concat(value);
    }
  },
  mounted: function () {
    this.onResizeComponent();
  },
  methods: {
    onResizeComponent() {
      var value = 1920;
      if (this.containerView === undefined || this.containerView === null) {
        this.containerView = this.$refs.containerView;

      }
      if (this.containerView !== undefined) {
        value = this.containerView.clientWidth;
      }
      this.containerViewWidth = value;
    },
    /*
      Item
    */
    getClassOfItem(index) {
      if (index === this.selected) {
        return "animation selected";
      }
      if (index === this.onTop) {
        return "animation selected";
      }
      return "animation";
    },
    getTileIdForIndex(index) {
      return "tile".concat(index);
    },
    getImageForTile(n) {
      if (this.detailImage === n) {
        return image3x;
      } else {
        var number = 10 * n + 1;
        if (n === 5) {
          return image6;
        }
        return "https://unsplash.it/150/300?image=".concat(number);
      }
    },
    getStyleForTile(index) {
      if (index === this.selected) {
        if (this.calculatedPosition !== index) {
          this.message = "index"
            .concat(index)
            .concat(" ")
            .concat(this.calculatedPosition);
          var origin = this.calculateTranslation(index);
          this.message = "Origin ".concat(origin[0]);
          // this.deltaX =  0 - origin[0] + (this.getWidthOfScreen / ( 2 + 2/3 ))
          var n = this.getNumberOfTilesPerRow;

          var halfWidhtOfTile = this.getWidthOfScreen / n / 2;
          var column = (index - 1) % n;
          var centerOfTile =
            halfWidhtOfTile + (this.getWidthOfScreen / n) * column;
          this.deltaX = this.getWidthOfScreen / 2 - centerOfTile;
          /*
          // this.deltaX =  0 - origin[0] + (this.getWidthOfScreen / ( 2 + (n-2)/(n-1) ))
          if (n === 4) {
            this.deltaX = 0 - origin[0] + this.getWidthOfScreen / (2 + 2 / 3)
          }
          if (n === 3) {
            this.deltaX = 0 - origin[0] + this.getWidthOfScreen / 3
          }
          if (n === 2) {
            this.deltaX = 0 - origin[0] + this.getWidthOfScreen / 4
          }
          if (n === 1) {
            this.deltaX = 0 - origin[0]
          }
          */
          this.deltaY = 0 - origin[1] + 70;
          this.calculatedPosition = index;
        }
        var x = this.deltaX;
        var y = this.deltaY;
        var xTranslate = "translateX(".concat(x).concat("px )  ");
        var scaleX = this.getNumberOfTilesPerRow * -1;
        var scale = "scale3d(".concat(scaleX).concat(", 1, 0.7) ");
        var yTranslate = "translateY(".concat(y).concat("px ); ");
        var transform = xTranslate.concat(scale).concat(yTranslate);
        var cssTransform = "-webkit-transform: "
          .concat(transform)
          .concat("transform: ")
          .concat(transform)
          .concat(";");
        return cssTransform;
      }
      return "";
    },
    /*
      Calculations
    */
    offset(el) {
      var rect = el.getBoundingClientRect();
      var scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    calculateTranslation(index) {
      var id = this.getTileIdForIndex(index);
      var tile = document.getElementById(id);
      var divOffset = this.offset(tile);
      if (divOffset !== undefined) {
        return [divOffset.left, divOffset.top];
      }
      return [];
    },
    /*
      Action
    */
    openItem(index) {
      this.selected = index;
      this.setOnTop();
      this.setCloseButtonViewShown();
      this.translateValue = this.getBackgroundTranslate;
      setTimeout(this.setDetailImage, 250);
      setTimeout(this.setDetailsState, 700);
      setTimeout(this.setCloseButton, 400);
    },
    closeBackground() {
      this.setDetailsState();
      setTimeout(this.closeBackgroundDelayed, 400);
    },
    closeBackgroundDelayed() {
      this.setCloseButton();
      this.selected = -1;
      setTimeout(this.setDetailImage, 450);
      setTimeout(this.setCloseButtonViewShown, 250);
      setTimeout(this.setOnTop, 700);
      if (this.translateValue === 0) {
        this.translateValue = -100;
      } else {
        this.translateValue = 0;
      }
    },
    /*
      Setters
    */
    setDetailImage() {
      this.detailImage = this.selected;
    },
    setCloseButton() {
      this.closeButtonVisible = !this.closeButtonVisible;
    },
    setCloseButtonViewShown() {
      this.closeButtonViewShown = !this.closeButtonViewShown;
    },
    setOnTop() {
      this.onTop = this.selected;
    },
    setDetailsState() {
      this.showDetails = !this.showDetails;
    }
  }
};
</script>

<style>
.bigger {
  transform: scale3d(1, 1, 1);
}

.animation {
  transition: all 700ms ease-in-out;
}

.backgroundView {
  height: 1080px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}

.selected {
  z-index: 1;
}

.closeVisible {
  z-index: 2;
  color: red;
}

.detailsView {
  z-index: 0.5;
  position: absolute;
  top: 400px;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.closeButtonViewVisible {
  opacity: 0;
}

.closeButtonView {
  opacity: 0;
  background-color: white;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}</style>
