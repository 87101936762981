import { copyObject, isEmpty, isTrue } from "../../api/utilities.js";
import Vue from "vue";
import { ALERT_TYPE_ERROR, VIEW_FIELD } from "../../store/constants.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  //object.objectType = "OpportunityMain"
  if (
    !isEmpty(object.value) &&
    !isEmpty(object.objectType) &&
    isEmpty(object.text)
  ) {
    var fieldConfig = {}; //TODO Retrieve it.

    var fieldConfig = context.getters.getFields.find(
      field =>
        field.objectType === object.objectType && field.field === object.value
    );

    if (!isEmpty(fieldConfig)) {
      if (!isEmpty(object.description)) {
        Vue.set(object, "text", fieldConfig.description);
      }
      //Determine Type
      var type = "input";
      if (isTrue(fieldConfig.isId)) {
        type = "idLink";
      }
      if (!isEmpty(fieldConfig.dropdown)) {
        type = "valueList";
      }

      Vue.set(object, "type", type);
      if (type === "valueList" || type === "textEdit") {
        Vue.set(object, "isEdit", true);
        Vue.set(object, "isDisplay", false);
      } else {
        Vue.set(object, "isEdit", true);
        Vue.set(object, "isDisplay", true);
      }
      // Nieuwe Config.
      if (!isEmpty(object.relatedObjectType)) {
        Vue.set(object, "relatedObjectType", fieldConfig.relatedObjectType);
      }

      if (!isEmpty(object.relatedObjectField)) {
        Vue.set(object, "relatedObjectField", fieldConfig.relatedObjectField);
      }

      if (!isEmpty(object.dropdown)) {
        Vue.set(object, "dropdown", fieldConfig.dropdown);
      }

      if (
        !isEmpty(object.relatedObjectType) &&
        isEmpty(object.relatedObjectField)
      ) {
        Vue.set(object, "relatedObjectField", "Guid");
      }
    }
  }
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  //Default name if empty
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("after create in ViewField");
  Vue.set(object, "size", 4);
  Vue.set(object, "isEdit", true);
  Vue.set(object, "isDisplay", true);
  Vue.set(object, "isListEdit", true);
  Vue.set(object, "isList", true);
  Vue.set(object, "position", 300);
  Vue.set(object, "width", 6);
  Vue.set(object, "type", "input");

  return object;
};

/**---------------------------------------------------------------
* Get Fields
---------------------------------------------------------------*/
export const getFieldsForObjectAndConfig = (
  objectConfig,
  object,
  viewConfig,
  fields,
  context,
  options
) => {
  for (var i = 0; i < fields.length; i++) {
    console.log("GetFieldsForObjectConfig");
    var field = fields[i];

    if (field.value === "navigationId") {
      if (!isEmpty(object.navigationConfigId)) {
        Vue.set(field, "isHidden", false);
        Vue.set(field, "isDisplay", true);
        Vue.set(field, "isEdit", true);
      } else {
        Vue.set(field, "isDisplay", false);
        Vue.set(field, "isEdit", false);
      }
    }

    if (
      field.value === "relatedObjectType" ||
      field.value === "valueRelatedObject" ||
      field.value === "displayRelatedObject"
    ) {
      if (!isEmpty(object.navigationConfigId)) {
        Vue.set(field, "isHidden", false);
        Vue.set(field, "isDisplay", true);
        Vue.set(field, "isEdit", true);
      } else {
        Vue.set(field, "isDisplay", false);
        Vue.set(field, "isEdit", false);
      }
    }
    if (field.value === "dropdown") {
      if (object.type === "valueList") {
        Vue.set(field, "isHidden", false);
        Vue.set(field, "isDisplay", true);
        Vue.set(field, "isEdit", true);
      } else {
        Vue.set(field, "isDisplay", false);
        Vue.set(field, "isEdit", false);
      }
    }
  }

  return fields;
};

/**
 * Actions
 */

export const filllistDisplayAndEditForViewFields = (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  console.log("Action: Set the listDisplay and listEdit");
  var objects = context.getters.getAllDataObjectsForObjectType(VIEW_FIELD);

  for (var i = 0; i < objects.length; i++) {
    var changeObject = objects[i];
    const oldObject = copyObject(changeObject);
    changeObject.isList = changeObject.isDisplay;
    changeObject.isListEdit = changeObject.isEdit;
    var payload = {
      objectType: VIEW_FIELD,
      object: changeObject,
      oldObject
    };
    context.dispatch("saveObjectForObjectType", payload);
  }
};

export const movedFieldUp = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  if (isEmpty(object.position) && isEmpty(object.fieldList)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "Cannot move up because Position is not filled"
    });
    return;
  }
  var viewFieldsBefore = context.getters.getObjectsForFilter(VIEW_FIELD, o => {
    return o.fieldList === object.fieldList && o.position < object.position;
  });
  if (viewFieldsBefore.length < 1) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "No Items before"
    });
    return;
  }
  viewFieldsBefore = viewFieldsBefore.sort((a, b) => {
    return b.position - a.position;
  });
  const oldObject = copyObject(object);
  var previousViewField = viewFieldsBefore[0];
  const oldpreviousViewField = copyObject(previousViewField);
  var oldPosition = object.position;
  Vue.set(object, "position", previousViewField.position);
  Vue.set(previousViewField, "position", oldPosition);
  await context.dispatch("saveObjectForObjectType", {
    objectType: VIEW_FIELD,
    object: object,
    oldObject
  });
  await context.dispatch("saveObjectForObjectType", {
    objectType: VIEW_FIELD,
    object: previousViewField,
    oldObject: oldpreviousViewField
  });
};

export const moveFieldDown = async (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  if (isEmpty(object.position) && isEmpty(object.fieldList)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "Cannot move down because Position is not filled"
    });
    return;
  }
  var viewFieldsBefore = context.getters.getObjectsForFilter(VIEW_FIELD, o => {
    return o.fieldList === object.fieldList && o.position > object.position;
  });
  if (viewFieldsBefore.length < 1) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "No Items before"
    });
    return;
  }
  viewFieldsBefore = viewFieldsBefore.sort((a, b) => {
    return a.position - b.position;
  });
  const oldObject = copyObject(object);
  var previousViewField = viewFieldsBefore[0];
  const oldpreviousViewField = copyObject(previousViewField);
  var oldPosition = object.position;
  Vue.set(object, "position", previousViewField.position);
  Vue.set(previousViewField, "position", oldPosition);
  context.dispatch("saveObjectForObjectType", {
    objectType: VIEW_FIELD,
    object: object,
    oldObject
  });
  context.dispatch("saveObjectForObjectType", {
    objectType: VIEW_FIELD,
    object: previousViewField,
    oldObject: oldpreviousViewField
  });
};
