<template>

    <v-container
        fill-height
        fluid
    >


        <v-row
            style="height: 100%;"
            class="flex-nowrap"
            justify="center"
            align="center"
        >
            <v-col
                cols="12"
                md="4"
                style="height: 100%; "
            >
                <v-container
                    fill-height
                    class="d-flex flex-column"
                >
                    <div class="flex1 d-flex justify-center align-center">
                        <v-row
                            align="center"
                            justify="center"
                            class="flex-nowrap flex-column"
                        >
                            <img
                                :src="getTenantIcon"
                                crossorigin="anonymous"
                                height="40px"
                                width="40px"
                                cover
                                class="rounded-lg"
                            />
                            <div class="pt-2 text-body-1 font-weight-mediumBold text-capitalize">{{ getTenant.name }}
                            </div>


                        </v-row>
                    </div>

                    <v-container class="d-flex flex-column align-center py-0 justify-center flex1">
                        <create-account />
                    </v-container>
                    <div class="flex1 d-flex justify-end align-end">
                        <span class="text-caption text-center gray--text"> {{ $t('creatingAccountTerms') }} <a
                                :href="APP.terms"
                            >{{ $t('termsAndconditions') }}</a> {{ $t('and') }}
                            <a :href="APP.privacyPolicy"> {{ $t('privacyPolicy') }}</a></span>
                    </div>



                </v-container>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import createAccount from './createAccount.vue'
import { APP } from '../../api/constants';
export default {
    data() {
        return {
            APP
        }
    },
    components: { createAccount },
    computed: {
        ...mapGetters(['getTenant', 'getTenantIcon', 'isDev',]),

    },
    created() {


        return {}
    }
}
</script>

<style
    lang="scss"
    scoped
></style>