<template>
  <v-card flat>
    <v-card
      flat
      class="ma-3"
    >
      <h5 text-xs-center>Connections</h5>
    </v-card>
    <v-card flat>
      <v-img
        :src="getImage"
        crossorigin="anonymous"
      />
    </v-card>
  </v-card>
</template>
<script>
import * as Constants from "../../../../store/constants.js";
import * as Utilities from "../../../../api/utilities.js";
import contextactions from "./contextActions";

import demo from "../../../../demo/Images/demoImages";

export default {
  components: { contextactions },
  props: {
    viewconfig: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogTitle: "Title",
      demo
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    },
    getImage() {
      return demo.connected;
    }
  },
  methods: {
    closeDialog() {
      this.dialog.show = false;
    }
  }
};
</script>
