<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-card
      flat
      class="pa-0 ma-3"
    >
      <v-card flat>
        <v-layout
          row
          wrap
        >
          <v-flex :class="getClassOfFilters('filter')">
            <filterList :viewconfig="viewconfig" />
          </v-flex>
          <v-flex
            v-if="getWidth > '1'"
            :class="getClassOfFilters('spacer')"
          >
            <v-spacer v-if="getWidth > '1'" />
          </v-flex>
          <v-flex>
            <v-layout>
              <v-flex>
                <v-text-field
                  v-model="search"
                  append-icon="hi-magnifying-glass"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-flex>
              <listViewAction :viewconfig="getViewConfig" />
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row>
        <v-container
          ref="myListOuter1"
          class="pa-0 ma-0 removeScroll xs12"
          fluid
        >
          <v-data-table
            :headers="getFields"
            :items="getFilteredObjects"
            :search="search"
            flat
          >
            <template
              slot="headerCell"
              slot-scope="props"
            >
              <v-tooltip bottom>
                <span slot="activator">
                  {{ props.header.text }}
                  <configurationView
                    v-if="isConfigMode"
                    :viewconfig="props.header"
                    type="ViewField"
                  />
                </span>
                <span>
                  {{ props.header.text }}
                </span>
              </v-tooltip>
            </template>
            <template
              slot="items"
              slot-scope="props"
            >
              <tr>
                <td
                  v-for="field in getFields"
                  :key="field.value"
                  :align="getAlign(field.align)"
                  :bgcolor="getCellColor(props.item)"
                >
                  <listCell
                    :header="field"
                    :object="props.item"
                    :viewconfig="viewconfig"
                    :readonly="true"
                    :align="getAlign(field.align)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import filterList from "./FilterList";
import listViewAction from "./ListViewActions";
import * as theme from "../../theme/themeDefault.js";
import listCell from "../components/GenericListCell";

export default {
  components: { listViewAction, filterList, listCell },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      fields: undefined,
      search: "",
      myListWidth: 0
    };
  },
  computed: {
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    //TODO
    getLevels() {
      var levels = 3;
      for (var i = 0; i < levels; i++) {
        var level = {
          size: "1",
          value: "objectType",
          text: "level",
          position: i,
          type: "icon"
        };
      }
      return levels;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getNewObjectForList() {
      return {};
    },
    getListConfig() {
      return {
        search: "",
        items: [],
        pagination: {
          sortBy: "title",
          page: 1,
          rowsPerPage: 7,
          descending: false,
          totalItems: 0
        },
        fields: []
      };
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: this.getWidth
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "right";
        }
        field.sortable = true;
      }
      return fields;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    pages() {
      return this.getListConfig.pagination.rowsPerPage
        ? Math.ceil(
          this.getListConfig.items.length /
          this.getListConfig.pagination.rowsPerPage
        )
        : 0;
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    getClassOfFilters(type) {
      if (type === "spacer") {
        if (this.getWidth === Constants.SMALL_SIZE) {
          return "xs0";
        } else {
          return "";
        }
      }
      if (this.getWidth === Constants.SMALL_SIZE) {
        return "xs12";
      }
      if (this.getWidth === Constants.MEDIUM_SIZE) {
        return "xs5";
      }
      if (this.getWidth === Constants.BIG_SIZE) {
        return "xs4";
      }
      return "xs3";
    },
    setFields(fields) {
      this.fields = fields;
    },
    getValue(field, object) {
      if (field.type === "valueList") {
        var listValues = this.$store.getters.getDataObjectsForObjectType(
          Constants.VALUE_LIST_CONFIG
        );
        if (listValues !== null && listValues !== undefined) {
          var value = listValues.find(function (o) {
            return (
              o.valueList === field.dropdown && o.value === object[field.value]
            );
          });
          if (value !== undefined && value !== null) {
            return value.descr;
          }
        }
      }

      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== "" &&
        field.displayRelatedObject !== null
      ) {
        var payload = {
          objectType: field.relatedObjectType,
          objectId: object[field.value]
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (relatedObject !== undefined) {
          //Split
          return UTIL.splitFields(relatedObject, field.displayRelatedObject);
        } else {
          return "";
        }
      }
      if (field.type === "date") {
        var date = object[field.value];
        return UTIL.jsonDateToString(date);
      }
      return object[field.value];
    },
    clickField: function (field, object) {
      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    getCellColor(object) {
      var currentObject = this.$store.getters.getCurrentObject;
      if (object === currentObject) {
        return theme.getTheme()[theme.ROW_HIGHLIGHTED];
      }
      return theme.getTheme()[theme.ROW_BACKGROUND];
    },
    getAlign(align) {
      if (align === null || align === "" || align === undefined) {
        return "right";
      } else {
        return align;
      }
    },
    getProgressValue(value) {
      if (value === 7) {
        return 100;
      }
      if (value === 3) {
        return 75;
      }
      if (value === 2) {
        return 25;
      }
      return 0;
    },
    isProgress(type) {
      if (type === "progress") {
        return true;
      }
      return false;
    },
    isLink(type) {
      if (type === "link") {
        return true;
      }
      if (type === "idLink") {
        return true;
      }
      return false;
    },
    isLabel(type) {
      if (type === undefined) {
        return true;
      }
      if (type === "progress") {
        return false;
      }
      if (this.isLink(type)) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
