<template>
  <v-card
    flat
    class="transparent full-height"
  >
    <div
      v-if="viewconfig"
      class="full-height"
    >

      <vue-dropzone
        v-if="getOptionsCanCreate"
        id="dropzone"
        ref="myVueDropzone"
        :options="getDropzoneOptions"
        :use-custom-slot="true"
        class="pa-0 no-border transparent full-height"
        @vdropzone-sending-multiple="sendingMultiple"
      >
        <div class="dropzone-custom-content full-height">
          <AttachmentListViewContainer
            :viewconfig="viewconfig"
            :object="object"
            :height="height"
            :search="search"
            dropzone-element="true"
          >
            <v-btn
              :id="viewconfig"
              class="primary white--text ml-2 mr-2"
              icon
            >
              <v-icon dark>
                hi-plus-outline
              </v-icon>
            </v-btn>
          </AttachmentListViewContainer>

          <div class="on-dropzone-overlay">
            <v-icon
              x-large
              color="primary"
            >
              hi-document-plus
            </v-icon>
          </div>
        </div>

        <v-container
          v-if="loading"
          class="fill-height ma-0 py-4"
          style="position: absolute; top:0; height: 100%;"
        >
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
        </v-container>
      </vue-dropzone>
      <div v-else>
        <div class="dropzone-custom-content full-height">
          <AttachmentListViewContainer
            :viewconfig="viewconfig"
            :object="object"
            :height="height"
            :search="search"
          >
          </AttachmentListViewContainer>


        </div>

        <v-container
          v-if="loading"
          class="fill-height ma-0 py-4"
          style="position: absolute; top:0; height: 100%;"
        >
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
        </v-container>
      </div>
      <v-dialog
        v-model="compressLoader"
        max-width="200"
        content-class="rounded-lg"
        persistent
      >
        <v-container class="card">
          <v-row
            class="fill-height ma-0 py-2"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-row>
          <div class="text-body-2 font-weight-mediumBold py-1 text-center">Compressing pdf</div>
          <div class="text-caption text-center">This can take a minute</div>
        </v-container>
      </v-dialog>
    </div>

  </v-card>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ListView from "../splitCard/ListView.vue";
import ListViewContainer from "../splitCard/ListViewContainer.vue";
import AttachmentListViewContainer from "../splitCard/AttachmentListViewContainer.vue";
import { isEmpty } from '../../api/utilities';



export default {
  components: { vueDropzone: vue2Dropzone, ListView, ListViewContainer, AttachmentListViewContainer },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    editmode: {
      type: Boolean,
      default: undefined
    },
    operationid: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://swiftio.io/",
        maxFilesize: 15.0,
        addRemoveLinks: true,
        clickable: `#${this.viewconfig}`,
        previewsContainer: false,
        // autoProcessQueue: false,
        acceptedFiles: 'image/*,application/pdf,application/zip,.xlsx,.xls,.csv,.docx,text/plain',
        uploadMultiple: true,
        parallelUploads: 20
        // acceptedFiles: 'image/*,application/pdf' 
      },
      thumbnail: null,
      loading: false,
      compressLoader: false,
      search: '',
      files: []
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getDropzoneOptions() {
      return this.dropzoneOptions
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getOptionsReadOnly() {
      if (this.getOptions && !isEmpty(this.getOptions.readOnly)) {
        return this.getOptions.readOnly
      }
      return false
    },
    getOptionsCanCreate() {
      if (this.getOptions && !isEmpty(this.getOptions.canCreate)) {
        return this.getOptions.canCreate
      }
      return true
    },
  },
  methods: {
    sendingMultiple(files) {
      if (files) {
        const payload = {
          files,
          object: this.object,
          objectConfig: this.getObjectConfig,
          viewConfig: this.getViewConfig
        }
        this.$store.dispatch('uploadFiles', payload)
      }
    },
  }
};
</script>

<style scoped>
.thumb-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  color: white;
  display: none;
}
</style>
