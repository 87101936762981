<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-card
      flat
      class="pa-0 ma-0"
    >
      <v-card
        class="d-flex justify-space-between mb-0 align-center"
        flat
      >
        <v-card
          v-if="hasFilter"
          flat
        >
          <filterView :viewconfig="viewconfig" />
        </v-card>
      </v-card>
      <v-card
        v-if="!hasFilter"
        class="ma-3 pa-0"
        flat
      >
        <h2>{{ getTitle }}</h2>
      </v-card>
      <v-layout row>
        <v-flex
          xs12
          class="pl-3"
        >
          <v-container
            ref="myListOuter1"
            class="pa-0 ma-0 removeScroll xs12"
            fluid
          >
            <v-card flat>
              <div class="text-center">
                <v-progress-circular
                  :size="getHeight"
                  :width="getWidth"
                  :value="getValue"
                  rotate="-90"
                  color="primary"
                >
                  {{ getValueText }}
                </v-progress-circular>
              </div>
            </v-card>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import * as theme from "../../theme/themeDefault";
import * as UTIL from "../../api/utilities.js";
import filterView from "../splitCard/FilterView";

export default {
  components: { filterView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      currentIndex: 0,
      list: null,
      myListWidth: 0,
      previousValue: null
    };
  },
  computed: {
    getObjects() {
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    hasFilter() {
      return !UTIL.isEmpty(this.getViewConfig.queryFilter);
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getValueText() {
      return "70 / 100";
    },
    getValue() {
      return 70;
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: "5",
          list: "edit"
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      return fields;
    },
    getTitle() {
      var title = this.getViewConfig.title;
      if (title === undefined || title === null) {
        title = "No title";
      }
      return title;
    },
    getField() {
      var fields = this.getFields;
      if (fields.length > 0) {
        return fields[0];
      }
      return {};
    },
    getCurrentValue() {
      if (UTIL.isEmpty(this.object)) {
        return "";
      }
      return this.object[this.getField.value];
    },
    getType() {
      return this.getField.dropdown; //salesStageCode
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height - 50;
      }
      return 300;
    },
    getWidth() {
      return 35; //this.getHeight / 8;
    }
  },
  methods: {
    changeTheme(name) {
    },
    getValueListItems() {
      if (
        this.previousValue !== null &&
        this.previousValue === this.getCurrentValue
      ) {
        return this.list;
      }
      this.previousValue = this.getCurrentValue;

      var list = this.$store.getters.getValueListForId(this.getType);
      //Calculate Complete
      var icon = "";
      var complete = false;
      for (var i = list.length - 1; i >= 0; i--) {
        var listItem = list[i];
        if (listItem.value === this.getCurrentValue || i === 0) {
          complete = true;
          this.currentIndex = i;
        }
        listItem.icon = icon;
        listItem.complete = complete;
        listItem.index = i + 1;
        if (complete) {
          icon = "done";
        }
      }
      this.list = list;
      return this.list;
    },
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    getClassOfFilters(type) {
      if (type === "spacer") {
        if (this.getWidth === Constants.SMALL_SIZE) {
          return "xs0";
        } else {
          return "";
        }
      }
      if (this.getWidth === Constants.SMALL_SIZE) {
        return "xs12";
      }
      if (this.getWidth === Constants.MEDIUM_SIZE) {
        return "xs5";
      }
      if (this.getWidth === Constants.BIG_SIZE) {
        return "xs4";
      }
      return "xs3";
    }
  }
};
</script>
