<template>
  <v-container
    ref="myListOuter1"
    class="pa-0 ma-0"
    flat
    fluid
  >
    <v-container fluid>
      <v-row justify="start">
        <v-menu
          v-for="item in getFilteredObjects"
          :key="item.Guid"
          :close-on-content-click="false"
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs, value }">
            <v-chip
              v-bind="attrs"
              :color="value === true ? 'primary' : 'iconButton'"
              close
              close-icon="hi-x-mark-outline"
              label
              class="ma-1 px-4 py-3 rounded-lg filter-chip font-weight-medium"
              v-on="on"
              @click:close="() => { deleteItem(item) }"
            > {{ item.field }} <span class="px-1 comparator font-weight-normal">{{ item.comparator }}</span> {{
              getValue(item)
            }}</v-chip>
          </template>
          <v-card>
            <v-list>
              <v-list-item class="py-1">
                <genericInput
                  :header="getField('field')"
                  :object="item"
                  :config="getViewConfig"
                  :filterfunction="fieldfilterFunction"
                  :align="getAlign(getField('field').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                  @change="setType('field')"
                />
              </v-list-item>
              <v-list-item class="py-1">
                <genericInput
                  :header="getField('comparator')"
                  :object="item"
                  :config="getViewConfig"
                  :align="getAlign(getField('field').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>
              <v-subheader
                style="height: 36px;"
                class="py-1"
              >
                <v-select
                  :items="options"
                  :value="getOptionValue(item)"
                  label="Value type"
                  class="no-border white"
                  item-text="descr"
                  item-value="value"
                  hide-details
                  style="min-width: 150px; opacity: 0.6;"
                  dense
                  solo
                  @input="option => {

                    changeOption(option, item)
                  }"
                />
              </v-subheader>

              <v-list-item
                v-if="getOptionValue(item) === 'value'"
                id="value"
                class="py-1"
              >
                <genericInput
                  :header="changeField(getField('value'), item)"
                  :object="item"
                  :config="getViewConfig"
                  :align="getAlign(changeField(getField('value'), item).align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>
              <v-list-item
                v-if="getOptionValue(item) === 'linkedObjectField'"
                id="linkedObjectField"
                class="py-1"
              >
                <genericInput
                  :header="getField('linkedObjectField')"
                  :object="item"
                  :config="getViewConfig"
                  :align="getAlign(getField('linkedObjectField').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>
              <v-list-item
                v-if="getOptionValue(item) === 'filterFunction'"
                id="filterFunction"
                class="py-1"
              >
                <genericInput
                  :header="getField('filterFunction')"
                  :object="item"
                  :config="getViewConfig"
                  :align="getAlign(getField('filterFunction').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn
          class="iconButton ma-1"
          icon
          @click.native="addFilterObject()"
        >
          <v-icon>hi-plus</v-icon>

        </v-btn>
        <v-divider
          vertical
          style="width: 2px; max-width: 2px;"
          class="mx-2 iconButton"
        />
        <v-menu
          :close-on-content-click="false"
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs, value }">
            <v-chip
              v-bind="attrs"
              :color="value === true ? 'primary' : 'iconButton'"
              :close="!isEmpty(sortingItem) && !isEmpty(sortingItem.field)"
              close-icon="hi-x-mark-outline"
              label
              class="ma-1 px-4 py-3 rounded-lg filter-chip font-weight-medium"
              v-on="on"
              @click="() => { onAddSorting() }"
              @click:close="() => { deleteItem(sortingItem) }"
            ><v-icon left> {{ sortingItem && !isTrue(sortingItem.isDown) ? 'hi-bars-arrow-up-outline' :
              'hi-bars-arrow-down-outline' }}</v-icon>
              <span
                style="opacity: .6;"
                class="font-weight-normal mr-1"
              >
                Sort: </span>
              {{ !isEmpty(sortingItem) && !isEmpty(sortingItem.field) ? sortingItem.field : 'none'
              }}</v-chip>
          </template>
          <v-card>
            <v-list v-if="sortingItem">
              <v-list-item class="py-1">
                <genericInput
                  :header="getField('field')"
                  :object="sortingItem"
                  :config="getViewConfig"
                  :filterfunction="fieldfilterFunction"
                  :align="getAlign(getField('field').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>
              <v-list-item class="py-1">
                <genericInput
                  :header="getField('isDown')"
                  :object="sortingItem"
                  :config="getViewConfig"
                  :align="getAlign(getField('isDown').align)"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-list-item>

            </v-list>
          </v-card>
        </v-menu>

        <v-chip
          label
          color="iconButton"
          class="ma-1 px-4 py-3 rounded-lg filter-chip font-weight-medium"
          :class="getCurrentObject.online && 'primary--text'"
          @click="() => { onlineDialog = true }"
        ><v-icon :color="getCurrentObject.online ? 'primary' : 'primaryText'"> {{ getCurrentObject.online ?
          'hi-wifi-outline' :
          'hi-signal-slash-outline' }}</v-icon>
        </v-chip>

        <v-menu
          v-model="isAdvanced"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              label
              color="iconButton"
              class="ma-1 px-4 py-3 rounded-lg filter-chip font-weight-medium"
              :class="getCurrentObject.online && 'primary--text'"
              @click="openAdvanced"
            ><v-icon :color="isAdvanced ? 'primary' : 'primaryText'">hi-code-bracket
              </v-icon>
            </v-chip>
          </template>

          <v-card class="pa-2">
            <v-container class="pa-4">
              <v-row class="py-1">
                <div>Advanced filter</div>
              </v-row>

              <v-row class="py-1">

                <genericInput
                  :header="getFilterField('filter')"
                  :object="currentfilter"
                  :config="getViewConfig"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-row>
              <v-row class="py-1">
                <genericInput
                  :header="getFilterField('parentType')"
                  :object="currentfilter"
                  :config="getViewConfig"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-row>
              <v-row class="py-1">
                <genericInput
                  :header="getFilterField('parentFilter')"
                  :object="currentfilter"
                  :config="getViewConfig"
                  :readonly="false"
                  no-border
                  solo
                  dense
                />
              </v-row>

            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                text
                @click="cancelAdvanced"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="saveAdvanced"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>


        <v-btn
          v-show="needSave"
          elevation="0"
          class="secondary ma-1"
          @click="reset"
        >
          <v-icon left>hi-arrow-uturn-left</v-icon>
          Reset
        </v-btn>
        <v-btn
          v-show="needSave && hasFullChangeAuthorisation"
          elevation="0"
          class="primary ma-1"
          @click="save"
        >
          <v-icon left>hi-check-outline</v-icon>
          Save
        </v-btn>
        <v-btn
          v-show="needSave && hasFullChangeAuthorisation"
          elevation="0"
          class="primary lighten-5 primary--text ma-1"
          @click="openSaveAsDialog"
        >
          <v-icon left>hi-clipboard-document-check-outline</v-icon>
          Save as
        </v-btn>

      </v-row>
    </v-container>
    <v-dialog
      v-model="onlineDialog"
      persistent
      max-width="300"
      rounded="lg"
    >

      <v-card rounded="lg">
        <v-card-title class="text-h5">
          Change this to an {{ getCurrentObject.online ? 'offline' : 'online' }} filter?
        </v-card-title>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="px-6"
            text
            @click="cancelOnline"
          >
            Cancel
          </v-btn>

          <v-btn
            color="primary"
            class="px-6"
            elevation="0"
            @click="saveOnline"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="saveAsDialog"
      persistent
      max-width="300"
      rounded="lg"
    >

      <v-card rounded="lg">
        <v-card-title class="text-h5">
          Save filter as
        </v-card-title>
        <v-container>
          <v-text-field
            v-model="filterName"
            :readonly="false"
            :rules="[rules.required, rules.min]"
            class="text-field-transparent"
            filled
            label="Filter Name"
          />
        </v-container>
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn
            class="px-6"
            text
            @click="cancelSaveAs"
          >
            cancel
          </v-btn>

          <v-btn
            :disabled="filterName.length < 3"
            color="primary"
            class="px-6"
            elevation="0"
            @click="saveAsNew"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import { isEmpty, isTrue, isEmptyArray, copyObject, getGuid } from "../../api/utilities.js";
import filterList from "./FilterList";
import listViewAction from "./ListViewActions";
import * as theme from "../../theme/themeDefault.js";
import filterManager from "./FilterManager";
import filterSettingsManager from "./Filters/FilterSettingsManager";
import JsonEditorVue from 'json-editor-vue'

export default {
  components: { listViewAction, filterList, filterManager, filterSettingsManager, JsonEditorVue },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    currentfilter: {
      type: Object,
      default: null
    },
    objecttype: {
      type: String,
      default: ""
    },
    fieldList: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      fields: undefined,
      search: "",
      myListWidth: 0,
      viewConfigOfSelection: "",
      dialog: false,
      isAdvanced: false,
      onlineDialog: false,
      savingType: "",
      selectedObject: {},
      oldCurrentFilter: {},
      addMenu: false,
      menu: false,
      saveAsDialog: false,
      filterName: '',
      needReset: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 2 || "Min 2 characters"
      },
      options: [
        { value: 'value', descr: 'Value' },
        { value: 'linkedObjectField', descr: 'Related Value' },
        { value: 'filterFunction', descr: 'Computed Value' }
      ]
    };
  },

  computed: {
    needSave() {
      var newFilters = this.getFilteredObjects ? JSON.stringify(this.getFilteredObjects.map(this.mapItem)) : ''
      var oldFilters = this.getOldFilteredObjects ? JSON.stringify(this.getOldFilteredObjects.map(this.mapItem)) : ''
      var newSorting = this.getSortingObjects ? JSON.stringify(this.getSortingObjects.map(this.mapItem)) : ''
      var oldSorting = this.getOldSortingObjects ? JSON.stringify(this.getOldSortingObjects.map(this.mapItem)) : ''

      return newFilters !== oldFilters || newSorting !== oldSorting
    },
    isReadOnly() {
      return false;
    },
    hasFullChangeAuthorisation() {
      return (this.$store.getters.isConfigAdmin || this.$store.getters.isClientAdmin)
    },
    getFilter() {
      return this.currentfilter;
    },
    getCurrentFilterFields() {
      return "FilterConfigFieldsDefault";
    },
    getCurrentObject() {
      return this.currentfilter;
    },
    filterConfig() {
      return this.$store.getters.getObjectConfigForType(
        Constants.FILTER_CONFIG
      );
    },
    filterFieldConfig() {
      return this.$store.getters.getObjectConfigForType(
        Constants.FILTER_FIELD_CONFIG
      );
    },
    sortingFieldConfig() {
      return this.$store.getters.getObjectConfigForType(
        Constants.SORTING_FIELD_CONFIG
      );
    },
    getFilteredObjects() {
      if (
        this.getCurrentObject === null ||
        this.currentfilter.filterFields === undefined ||
        this.currentfilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getFieldsForFilter(
        this.currentfilter.filterFields
      );
    },
    getOldFilteredObjects() {
      return this.$store.getters.getOldFieldsForFilter(
        this.currentfilter.filterFields
      );
    },
    getSortingObjects() {
      if (
        this.getCurrentObject === null ||
        this.currentfilter.filterFields === undefined ||
        this.currentfilter.filterFields === null
      ) {
        return [];
      }
      return this.$store.getters.getSortingForFilter(
        this.currentfilter.filterFields
      );
    },
    getOldSortingObjects() {
      return this.$store.getters.getOldSortingForFilter(
        this.currentfilter.filterFields
      );
    },
    sortingItem() {
      if (this.getSortingObjects && !isEmptyArray(this.getSortingObjects)) {
        return this.getSortingObjects[0]
      } else return null
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getNewObjectForList() {
      return {};
    },
    getListConfig() {
      return {
        search: "",
        items: [],
        pagination: {
          sortBy: "title",
          page: 1,
          rowsPerPage: 7,
          descending: false,
          totalItems: 0
        },
        fields: []
      };
    },
    getFilterFields() {
      var fields = [];
      var payload = {
        fieldList: this.getCurrentFilterFields,
        size: 4,
        list: "listEdit"
      };
      // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
      fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      this.changeFields(fields);
      return fields;
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "listEdit"
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }

      this.changeFields(fields);
      return fields;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    }
  },
  watch: {
    // Note: only simple paths. Expressions are not supported.
    'currentfilter.filterFields'(newValue) {
      this.saveOldFiltersAndSorting()
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.saveOldFiltersAndSorting()
  },

  methods: {
    isTrue,
    isEmpty,
    setType(item, fieldName) {
      const field = this.getField(fieldName)
      const value = item[field.value]
      const fieldConfig = this.$store.getters.getObjectsForFilter(Constants.FIELD_CONFIG, {

      })
    },
    mapItem(item) {
      delete item.syncId
      delete item.updatedAt
      return item
    },
    cancelOnline() {
      this.onlineDialog = false
    },
    openAdvanced() {
      this.oldCurrentFilter = structuredClone(this.currentfilter)
      this.isAdvanced = true
    },
    cancelAdvanced() {
      this.currentfilter = this.oldCurrentFilter
      this.isAdvanced = false
    },
    saveAdvanced() {
      console.log('saveCurrentFIlter', this.getCurrentObject)
      const oldObject = structuredClone(this.getCurrentObject)
      var payload = {
        objectType: Constants.FILTER_CONFIG,
        object: this.getCurrentObject,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.isAdvanced = false
    },
    saveOnline() {
      const oldObject = structuredClone(this.getCurrentObject)
      if (isTrue(oldObject.online)) {
        this.$set(this.getCurrentObject, 'online', false)
      } else {
        this.$set(this.getCurrentObject, 'online', true)
      }

      var payload = {
        objectType: Constants.FILTER_CONFIG,
        object: this.getCurrentObject,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.onlineDialog = false
    },
    saveOldFiltersAndSorting(reset) {
      if (!isEmpty(this.currentfilter.filterFields)) {
        this.$store.commit('setOldFiltersForFields', {
          filterFields: this.currentfilter.filterFields,
          items: this.getFilteredObjects,
          reset: reset
        })
        this.$store.commit('setOldSortingForFields', {
          filterFields: this.currentfilter.filterFields,
          items: this.getSortingObjects,
          reset: reset
        })
      }


    },
    save() {
      // this.saveFilter()
      this.saveFilterFields()
      this.saveSortingFields()
      this.saveOldFiltersAndSorting(true)
      this.$emit('on-close');
    },
    openSaveAsDialog() {
      this.saveAsDialog = true
    },
    cancelSaveAs() {
      this.filterName = ''
      this.saveAsDialog = false
    },
    reset() {
      if (!isEmpty(this.getOldFilteredObjects)) {
        const objectConfig = this.filterFieldConfig
        this.getFilteredObjects.forEach(item => {
          item.isDeleted = 1
          this.commitObject(JSON.parse(JSON.stringify(item)), objectConfig)
        })
        this.getOldFilteredObjects.forEach(item => {
          this.commitObject(JSON.parse(JSON.stringify(item)), objectConfig)
        })

      }
      if (!isEmpty(this.getOldSortingObjects)) {
        const objectConfig = this.sortingFieldConfig
        this.getSortingObjects.forEach(item => {
          item.isDeleted = 1
          this.commitObject(JSON.parse(JSON.stringify(item)), objectConfig)
        })
        this.getOldSortingObjects.forEach(item => {
          this.commitObject(JSON.parse(JSON.stringify(item)), objectConfig)
        })
      }

    },
    deleteItem(item) {
      if (item) {
        this.$set(item, 'isDeleted', 1)
      }
    },
    onAddSorting() {
      if (isEmpty(this.sortingItem)) {
        this.addSortingObject()
      }
    },
    getValue(item) {

      const option = this.getOptionValue(item)

      if (option) {
        if (option === 'value') {

          const descr = this.getDescr(item.field, item.value)
          if (descr) return descr
        }
        if (option === 'filterFunction') {
          const newItem = this.getItemFromValueList(this.getField('filterFunction'), item.filterFunction)
          if (newItem && newItem.descr) return newItem.descr
        }
        return item[option]
      }
      return item.value

    },
    changeOption(value, item) {
      if (value === 'value') {
        this.$set(item, 'linkedObjectField', null)
        this.$set(item, 'filterFunction', null)
        this.$set(item, 'value', '')
      } else if (value === 'linkedObjectField') {
        this.$set(item, 'value', null)
        this.$set(item, 'filterFunction', null)
        this.$set(item, 'linkedObjectField', 'Account')
      } else if (value === 'filterFunction') {
        this.$set(item, 'linkedObjectField', null)
        this.$set(item, 'value', null)
        this.$set(item, 'filterFunction', 'test')
      }
    },
    getOptionValue(item) {
      if (item.filterFunction) {
        return 'filterFunction'
      } else if (item.linkedObjectField) {
        return 'linkedObjectField'
      }
      return 'value'
    },
    changeFields(fields) {
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (isEmpty(field.align)) {
          field.align = "left";
        }
        field.sortable = true;
      }
    },
    getField(name) {
      var newField = null
      if (this.getFields) {
        newField = this.getFields.find(function (o) {
          return o.value === name
        });
      }
      return newField;
    },
    getFilterField(name) {
      var newField = null
      if (this.getFields) {
        newField = this.getFilterFields.find(function (o) {
          return o.value === name
        });
      }
      console.log('newField', newField, this.getFilterFields)
      return newField;
    },
    getFilterFunction(field, object) {
      if (field.value === "field") {
        return this.fieldfilterFunction;
      }
      return null;
    },
    getViewField(fieldValue) {
      // find the viewField to determine the type. We should maybe add here a field list.
      var viewField = null
      var viewFields = this.$store.getters.getViewFields;
      var filterQuery = this.fieldList

      if (!isEmpty(filterQuery)) {
        viewField = viewFields.find(function (o) {
          return o.value === fieldValue && o.fieldList === filterQuery
        });
      } else {
        viewField = viewFields.find(function (o) {
          return o.value === fieldValue
        });
      }
      return viewField
    },
    getDescr(fieldValue, value) {
      if (fieldValue) {
        const viewfield = this.getViewField(fieldValue)
        if (viewfield && viewfield.dropdown) {
          const item = this.getItemFromValueList(viewfield, value)
          if (item && item.descr) return item.descr
        }
      }
      return null
    },
    getItemFromValueList(header, value) {
      if (header && header.dropdown) {
        const list = this.getValueListItems(header)
        if (list) {
          const newValue = list.find(o => o.value === value)
          return newValue
        }
      }
      return null
    },
    getValueListItems(header) {
      let list = structuredClone(this.$store.getters.getValueListForId(header.dropdown))
      if (!header.isMandatory && Array.isArray(list)) {
        list.unshift({ value: null, descr: '' })
      }
      return list;
    },
    getItems(header, item) {
      if (header.type === 'valueList') {
        return this.getValueListItems(header)
      } else if (header.type === 'link' || header.type === 'idLink') {

        var filteredObjects = this.$store.getters.getDataObjectsForObjectType(
          header.relatedObjectType
        );
        if (!isEmpty(this.getFilterFunction(header, item))) {
          filteredObjects = this.fieldfilterFunction(header, item, filteredObjects);
        }
        // filter fix
        if (header.selectionView && !isEmpty(header.selectionView)) {
          var selectionView = item[header.selectionView]
          if (selectionView) {
            filteredObjects = filteredObjects.filter(item => item[header.selectionView] === selectionView)
          }
        }
        return filteredObjects;
      }

    },
    getTypeOfField(field) {
      if (field.type === "link" && !isEmpty(field.dropdown)) {
        return "valueList";
      }
      return field.type;
    },

    /**
     * This method is specific for the filter field.
     * It will change the value list depending on the selected field.
     */
    changeField(field, object) {
      //We need to take a copy otherwise it is the same for all the fields
      var newField = copyObject(field);

      //Check the current selected field
      if (object === undefined) {
        return newField;
      }
      var fieldValue = object.field;
      if (fieldValue === undefined || fieldValue === null) {
        return newField;
      }

      // find the viewField to determine the type. We should maybe add here a field list.
      var viewField = this.getViewField(fieldValue)

      if (viewField === undefined || viewField === null) {
        return newField;
      }

      // We found the viewField so we copy the properties.
      newField.dropdown = "";
      newField.type = "";
      newField.relatedObjectType = "";
      newField.displayRelatedObject = "";
      newField.valueRelatedObject = "";
      newField.filterRelatedObject = "";

      newField.dropdown = viewField.dropdown;
      newField.type = viewField.type;
      newField.relatedObjectType = viewField.relatedObjectType;
      newField.displayRelatedObject = viewField.displayRelatedObject;
      newField.valueRelatedObject = viewField.valueRelatedObject;
      newField.filterRelatedObject = viewField.filterRelatedObject;
      return newField;
    },
    fieldfilterFunction(header, object, objects) {
      var objectType = this.objecttype;
      var filteredObjects = objects.filter(function (o) {
        return o.objectType === objectType;
      });
      return filteredObjects;
    },

    /***************************************
      Cell properties
  ****************************************/

    getCellColor(object) {
      var currentObject = this.$store.getters.getCurrentObject;
      if (object === currentObject) {
        return theme.getTheme()[theme.ROW_HIGHLIGHTED];
      }
      return theme.getTheme()[theme.ROW_BACKGROUND];
    },
    getAlign(align) {
      if (align === null || align === "" || align === undefined) {
        return "right";
      } else {
        return align;
      }
    },

    /***************************************
      Object manipulation
    ****************************************/
    addFilterObject() {
      const objectConfig = this.filterFieldConfig
      const object = {
        _id: null,
        Guid: getGuid(),
        field: null,
        filterFields: this.currentfilter.filterFields,
        comparator: "==",
        value: null,
        linkedObjectField: null,
        filterFunction: null,
        isDeleted: 0
      };
      this.commitObject(object, objectConfig);
    },
    addSortingObject() {
      const objectConfig = this.sortingFieldConfig
      const object = {
        _id: null,
        Guid: getGuid(),
        field: null,
        sortingFieldList: this.currentfilter.filterFields,
        isDown: "true",
        sortingFunction: null,
        isDeleted: 0
      };
      this.commitObject(object, objectConfig);
    },
    commitObject(object, objectConfig) {
      const payload = {
        objectConfig: objectConfig,
        object: object
      };
      this.$store.dispatch("commitObject", payload);
    },
    saveFilter() {
      this.saveObject(this.currentfilter, this.filterConfig);
    },
    saveFilterFields() {
      const allItems = JSON.parse(JSON.stringify(this.$store.getters.getAllFieldsForFilter(this.currentfilter.filterFields)))
      const newItems = JSON.parse(JSON.stringify(this.getFilteredObjects))
      const oldItems = JSON.parse(JSON.stringify(this.getOldFilteredObjects))
      const differences = this.findDifferences(allItems, oldItems, newItems)
      if (!isEmptyArray(differences)) {
        differences.forEach(item => {
          this.saveObject(item, this.filterFieldConfig);
        })
      }
    },
    saveSortingFields() {
      const allItems = JSON.parse(JSON.stringify(this.$store.getters.getAllSortingForFilter(this.currentfilter.filterFields)))
      const newItems = JSON.parse(JSON.stringify(this.getSortingObjects))
      const oldItems = JSON.parse(JSON.stringify(this.getOldSortingObjects))
      const differences = this.findDifferences(allItems, oldItems, newItems)
      if (!isEmptyArray(differences)) {
        differences.forEach(item => {
          this.saveObject(item, this.sortingFieldConfig);
        })
      }
    },
    saveObject(object, objectConfig) {
      var payloadCurrent = {};
      payloadCurrent.newObject = JSON.parse(JSON.stringify(object));
      // remove filterfunction values before save
      if (!isEmpty(payloadCurrent.newObject.filterFunction)) {
        payloadCurrent.newObject.value = null
      }
      payloadCurrent.config = objectConfig;
      this.$store.dispatch("changeAndSaveObject", payloadCurrent);
    },
    findDifferences(allArray, oldArray, newArray) {
      var result = []
      var mappedNew = {}
      newArray.forEach(item => {
        mappedNew[item.Guid] = item
      })
      var mappedOld = {}
      oldArray.forEach(item => {
        mappedOld[item.Guid] = item
      })
      let allItem = null
      for (var i = 0; i < allArray.length; i++) {
        allItem = allArray[i];
        if (!(isEmpty(mappedOld[allItem.Guid]) && isEmpty(mappedNew[allItem.Guid]))) {
          if (isEmpty(mappedOld[allItem.Guid]) || isEmpty(mappedNew[allItem.Guid]) || JSON.stringify(allItem) !== JSON.stringify(mappedOld[allItem.Guid])) {
            result.push(allItem)
          }
        }
      }
      return result
    },
    saveAsNew() {
      // Nieuwe filter maken
      var newGuid = getGuid();
      const newFilterField = newGuid + "Fields"
      const filterFields = JSON.parse(JSON.stringify(this.getFilteredObjects));
      const sortingFields = JSON.parse(JSON.stringify(this.getSortingObjects));
      this.reset()
      var newFilter = {
        Guid: getGuid(),
        defaultFilter: "false",
        filterConfigId: newGuid,
        filterFields: newFilterField,
        title: this.filterName,
        filterQuery: this.currentfilter.filterQuery,
      };
      var filterConfig = this.filterConfig
      this.saveObject(newFilter, filterConfig);

      //Copy the filterFields

      var newfilterField = null
      for (var i = 0; i < filterFields.length; i++) {
        newfilterField = filterFields[i];
        newfilterField.Guid = getGuid()
        newfilterField.filterFields = newFilterField;
        this.saveObject(newfilterField, this.filterFieldConfig);
      }
      //Copy the sortingObject

      for (var i = 0; i < sortingFields.length; i++) {
        newfilterField = sortingFields[i];
        newfilterField.Guid = getGuid()
        newfilterField.sortingFieldList = newFilterField;
        this.saveObject(newfilterField, this.sortingFieldConfig);
      }

      this.saveAsDialog = false
    },
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
