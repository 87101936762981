<template>
  <v-btn
    icon
    @click.native="synchronize()"
  >
    <v-progress-circular
      :rotate="-90"
      :size="32"
      :width="3"
      :value="getSyncProgress"
      :indeterminate="getIsUpdating"
      color="primary"
    >
      <v-icon
        small
        v-if="getIcon"
      >
        {{ getIcon }}
      </v-icon>
      <div v-else>
        {{ getNumberOfOperationsToSend }}
      </div>
    </v-progress-circular>
  </v-btn>
</template>

<script>
import { SET_SYNC_PROGRESS } from '../../store/constants';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOnline: true
    };
  },
  computed: {
    ...mapGetters(["getSyncProgress", "getNumberOfOperationsToSend", "getIsUpdating", "isSynchronizing"]),
    getIcon() {
      if (this.getNumberOfOperationsToSend === 0) {
        return this.isOnline ? 'hi-check-outline' : 'hi-signal-slash'
      } return null
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine
    },
    synchronize() {
      this.$store.commit(SET_SYNC_PROGRESS, 0);
      setTimeout(this.startSynchronize, 250);
    },
    startSynchronize() {
      this.$store.dispatch("synchronizeAllData");
    },
  }
};
</script>

<style>
/* .main {
    padding-top: 0px !important;
  } */
</style>
