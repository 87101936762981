<template>
  <v-footer
    :inset="true"
    :height="getHeightOfBar"
    style="pointer-events:none;"
    color="transparent"
    class="footer"
    fixed
    app
  >
    <v-row
      style="nowrap;"
      align="center"
      justify="center"
    >



      <v-container :style="`max-width:${getCurrentDetailsState === 0 ? 58 : 323}px;`">
        <v-row
          justify="start"
          align="center"
        >
          <v-card
            flat
            class="pa-1 rounded-pill background-blur primary bottombar"
            style="pointer-events: all;"
          >

            <v-btn
              v-if="getCurrentDetailsState !== 0"
              color="transparent"
              class="py-0 px-2 ma-1"
              style="min-width:120px;"
              elevation="0"
              dark
              @click.native="changeView(getCurrentDetailsState >= 4 ? getCurrentDetailsState - 4 : 0)"
            >
              <v-icon
                left
                class="material-icons-sharp mr-1"
              >hi-arrow-left
              </v-icon>
              back
            </v-btn>

            <v-btn
              color="primary"
              fab
              dark
              small
              class="py-0 px-0 rounded-xl"
              :class="{ 'lighten-1': getCurrentDetailsState !== 0 }"
              elevation="0"
              @click="sheet = !sheet"
            >
              <v-icon class="material-icons-sharp white--text">{{ getSelectedItem.icon }}
              </v-icon>
            </v-btn>

            <v-btn
              v-if="getCurrentDetailsState === 4"
              color="transparent"
              class="py-2 px-2 ma-1"
              style="min-width:120px;"
              elevation="0"
              dark
              @click.native="changeView(8)"
            >

              details
              <v-icon
                right
                class="material-icons-sharp ml-1"
              >hi-arrow-right
              </v-icon>
            </v-btn>
          </v-card>
        </v-row>
        <!-- <v-row>
              <span
                :class="`caption text-capitalize menuItemColor--text text-truncate`"
                style="max-width: 60px;"
              >{{
              getSelectedItem.title }}</span>
            </v-row> -->
      </v-container>

      <v-bottom-sheet
        v-model="sheet"
        overlay-opacity="0"
        transition="dialog-bottom-transition"
        content-class="rounded-xl background-blur sideBarColor bottombar"
      >
        <v-sheet class="text-center pa-4 transparent">
          <v-container>
            <v-row>
              <v-col
                v-for="item in getMenuItems"
                :key="item.viewConfig"
              >
                <v-btn
                  :color="isSelected(item) ? 'menuColorHighlightBackground' : 'transparent'"
                  class="py-2 px-4"
                  value="recent"
                  elevation="0"
                  style="max-width: 80px; height: auto;"
                  @click="goToItem(item.href)"
                >
                  <v-container>
                    <v-row justify="center">
                      <v-icon
                        :color="getColorOfMenu(item)"
                        class="material-icons-sharp"
                      >{{ item.icon }}
                      </v-icon>
                    </v-row>
                    <v-row>
                      <span
                        :class="`caption text-capitalize ${getColorOfMenu(item)}--text text-truncate`"
                        style="max-width: 60px;"
                      >
                        {{ item.title }}
                      </span>
                    </v-row>
                  </v-container>
                </v-btn>

              </v-col>
            </v-row>
            <!-- <v-row
              align="center"
              class="tileBackgroundColor rounded-xl flex-nowrap"
            >

              <v-text-field
                slot="activator"
                class="text-field-transparent search mr-1"
                filled
                dense
                prepend-inner-icon="hi-magnifying-glass"
                hide-details="true"
                placeholder="Search for Leads, Contacts, Tasks,..."
                @click="onSearchClick"
              />
              <v-spacer />
              <settings-menu
                :on-click-item="onClickMenu"
                is-mobile
              />
              <v-btn
                class="mr-1"
                icon
                @click.native="synchronize()"
              >
                <v-progress-circular
                  :rotate="-90"
                  :size="32"
                  :width="3"
                  :value="getSyncState"
                  color="primary"
                >
                  {{ getNumberOfOperationsToSend }}
                </v-progress-circular>
              </v-btn>
            </v-row> -->
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
      <!-- <v-dialog
        v-model="showSearch"
        fullscreen
        rounded="lg"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-container class="tileBackgroundColor pa-0">
          <v-card
            flat
            class="pa-2"
          >
            <v-row
              no-gutters
              align="center"
              class="flex-nowrap"
            >

              <v-text-field
                ref="search"
                v-model="search"
                class="text-field-transparent search transparent"
                style="width:100%;"
                filled
                dense
                clearable
                autofocus
                prepend-inner-icon="hi-magnifying-glass"
                hide-details="true"
                placeholder="Search for Leads, Contacts, Tasks,..."
              />

              <v-btn
                icon
                @click="showSearch = false"
              >
                <v-icon>hi-x-mark</v-icon>
              </v-btn>

            </v-row>
          </v-card>
          <v-card flat>
            <SearchView :search="search" />
          </v-card>

        </v-container>
      </v-dialog> -->

    </v-row>
  </v-footer>
</template>

<script>
import * as Api from "../../api/objectApi.js";
import * as Utilities from "../../api/utilities";

import { mapGetters, mapMutations, mapActions } from "vuex";
import SearchView from "../splitCard/SearchView/SearchView.vue";
import demo from "../../demo/Images/demoImages.js";
import { SET_SYNC_PROGRESS } from '../../store/constants.js';
import SettingsMenu from "./SettingsMenu.vue";

export default {
  components: { SearchView, SettingsMenu },
  props: {
    dialog: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      detailState: "",
      sheet: false,
      showSearch: false,
      search: "",
      showMenu: false,
      demo
    };
  },
  computed: {
    ...mapGetters(["getCurrentSplitcard", "getSplitCards", "getLeftMenu", "getCurrentUser", "getRightDrawer"]),
    getCurrentObject() {
      var config = this.$store.getters["ui/getConfig"];
      return this.$store.getters["ui/getCurrentObject"](config);
    },
    getCurrentSplitcard: function () {
      const splitcard = Utilities.isEmpty(this.dialog) ? this.$store.getters.getCurrentSplitcard : this.$store.getters.getDialogSplitcard(this.dialog);
      return splitcard;
    },
    getCurrentDetailsState: function () {
      var currentDetailState = this.$store.getters.getStateForSplitCard(this.getCurrentSplitcard.splitCard);
      if (currentDetailState !== undefined) {
        return currentDetailState
      }
      return 0;
    },
    getSyncState() {
      return this.$store.getters.getSyncProgress;
    },
    getNumberOfOperationsToSend() {
      return this.$store.getters.getNumberOfOperationsToSend;
    },
    getLogState() {
      return this.$store.getters.getLogState;
    },
    getHeightOfBar() {
      var toppadding = getComputedStyle(document.documentElement).getPropertyValue("--sab");
      return Utilities.addIntToPixelString(toppadding, 80);
    },
    drawerState() {
      var leftDrawer = this.$store.getters.getLeftDrawer;
      return leftDrawer;
    },
    getMenuItems() {
      var menuItems = this.getLeftMenu;
      var menuItemsFiltered = menuItems.filter(function (o) {
        return o.menuGroup !== "core" && o.menuGroup !== "D006" && o.menuGroup !== "D100" && Utilities.isTrue(o.isActive);
      });
      return menuItemsFiltered.sort(function (a, b) { return a.position - b.position });
    },
    getSelectedItem() {
      var items = this.getMenuItems
      var currentRoute = this.$store.getters["getCurrentRoute"]
      var selectedItem = null
      items.forEach(item => {
        if (item.viewConfig === currentRoute) {
          selectedItem = item
          return;
        }
      });
      if (selectedItem) {
        return selectedItem
      } else return {
        icon: 'hi-squares-2x2-outline'
      }
    },
    getAvatar() {
      var user = this.$store.getters.getCurrentUser;
      var image = this.demo[user.image];
      if (image === undefined || image === null) {
        image = this.demo["noImage"];
      }
      return image;
    }
  },
  watch: {
    '$route'(to, from) {
      this.showSearch = false;
    }
  },
  methods: {
    onClickMenu() {
      this.sheet = false;
    },
    changeView(buttonNumber) {
      this.$store.dispatch("changeViewState", buttonNumber);
    },
    getBottomSheetColor: function (buttonNumber) {
      if (this.getCurrentDetailsState === buttonNumber) {
        return "primary";
      }
      return "bottomBarItem";
    },

    getColorOfMenu(item) {
      if (item === null) {
        return "menuItemColor";
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      if (item.viewConfig === currentRoute) {
        return "menuColorHighlight";
      } else {
        return "menuItemColor";
      }
    },
    isSelected(item) {
      if (item === null) {
        return false;
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      return item.viewConfig === currentRoute;
    },
    synchronize() {
      this.$store.commit(SET_SYNC_PROGRESS, 0);
      setTimeout(this.startSynchronize, 250);
    },
    startSynchronize() {
      this.$store.dispatch("synchronizeAllData");
    },
    goToItem(href) {
      this.sheet = false;
      this.$store.dispatch('hidePinnedDialog')
      setTimeout(() => {
        this.$router.push(href.slice(1))
      }, 100)

    },
    onSearchClick() {
      this.sheet = false
      this.showSearch = true
    },

  }
};
</script>

<style>
.androidSafe .background-blur.bottombar {
  /* background-color: var(); */
}

.isAndroid .background-blur.bottombar {
  /* background-color: #ffffff; */
}
</style>
