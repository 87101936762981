<template>
    <div>

    </div>
</template>

<script>
import Axios from 'axios';
import { API_BASE_URL, TENANT } from '../api/constants';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from '../store/constants';
export default {
    mounted() {
        if (this.$route.query.email) {
            this.unsubscribe(this.$route.query.email)
        }
    },
    methods: {
        unsubscribe(email) {
            this.$store.dispatch("showLoading");
            const baseUrl = API_BASE_URL + "unsubscribe";
            let url = new URL(baseUrl)
            url.searchParams.append('tenant', TENANT)
            url.searchParams.append('email', email)
            Axios.get(url)
                .then((response) => {
                    console.log("google", response);
                    this.$store.dispatch("hideLoading");
                    this.$store.commit("showAlert", {
                        type: ALERT_TYPE_SUCCESS,
                        message:
                            `${email} was successfully unsubscribed!`,
                        duration: 30000
                    });

                })
                .catch((error) => {
                    this.$store.commit("showAlert", {
                        type: ALERT_TYPE_ERROR,
                        message:
                            error,
                    });
                    this.$store.dispatch("hideLoading");
                });
        }
    }
}
</script>

<style
    lang="scss"
    scoped
></style>