<template>
  <v-row
    no-gutters
    class="flex-nowrap"
  >
    <v-btn
      v-for="pinned in getPinnedItems"
      :key="pinned.id"
      :ripple="false"
      icon
      @click="() => { pinItemClicked(pinned) }"
      class="transparent "
      large
    >
      <v-icon
        class="configLight"
        icon
      >{{ pinned.icon }}</v-icon>
    </v-btn>
    <!-- <v-card
        flat
        class="transparent"
        v-for="pinned in getPinnedItems"
        :key="getCurrentPinnedItem.id"
      >
      </v-card> -->

    <v-dialog
      v-model="showDialog"
      transition="dialog-bottom-transition"
      fullscreen
      persistent
      @input="(value) => { changeCurrentDialog(getCurrentPinnedItem, value) }"
    >

      <template v-slot:default="">
        <!-- <top-bar  :is-mobile="false" /> -->
        <div
          v-if="getCurrentPinnedItem"
          style="position: relative; height: 100%;"
          class="tileBackgroundColor "
        >
          <v-toolbar
            color="tileBackgroundColor"
            :class="getCurrentPinnedItem.splitCard && 'absolute top z-index1 kingSize'"
            elevation="0"
          >
            <v-row
              v-if="getCurrentPinnedItem.splitCard && getCurrentObject(getCurrentPinnedItem.viewConfig) != null"
              no-gutters
              :style="isMobile && 'max-width: calc(100% - 140px);'"
              :class="isMobile && 'flex-column'"
              :align="isMobile ? 'start' : 'center'"
            >


              <v-btn
                v-if="!isMobile"
                class="iconButton ml-2 mr-4 "
                icon
                small
                @click.stop="onBack"
              >
                <v-icon
                  small
                  class="configLight"
                >hi-arrow-left-outline</v-icon>
              </v-btn>
              <h1 class="gray--text text-md-body-1 text-sm-subtitle-2 text-caption font-weight-mediumBold">{{
                getCurrentPinnedItem.name
              }}
              </h1>
              <v-icon
                v-if="!isMobile"
                size="small"
                style="padding-top: 2px;"
                class="gray--text px-4"
              >hi-chevron-right</v-icon>
              <h1
                class=" text-body-1 font-weight-mediumBold text-truncate"
                :class="isMobile && 'kingSize'"
              >{{ getCurrentObject(getCurrentPinnedItem.viewConfig) }}</h1>
            </v-row>
            <v-row
              class="pl-4"
              no-gutters
              v-else
            >
              <v-icon class="mr-2">{{ getCurrentPinnedItem.icon }}</v-icon>
              <v-toolbar-title>{{ getCurrentPinnedItem.name }}</v-toolbar-title>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn
              v-for="(pinned, index) in getOptionsPinnedActions"
              :key="index"
              class="primary lighten-5 primary--text mx-1"
              text
              @click="clickAction(pinned)"
            >
              {{ pinned.name }}
            </v-btn>
            <v-toolbar-items>
              <v-btn
                text
                @click="close(pinned)"
              >
                <v-icon
                  class="configLight"
                  icon
                >hi-x-mark-outline</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card
            class="blur tranparent"
            elevation="0"
          >

            <div>
              <div v-if="getCurrentPinnedItem.splitCard">
                <split-card :dialog="getCurrentPinnedItem.viewConfig">
                  <!-- <viewConfigView
                    :height="getheight"
                    :object="null"
                    :viewconfig="getCurrentPinnedItem.viewConfig"
                    :editmode="false"
                    :search="null"
                    :in-card="false"
                  /> -->
                </split-card>
                <bottom-bar
                  :dialog="getCurrentPinnedItem.viewConfig"
                  v-if="!isMobile"
                />
                <bottom-bar-mobile
                  :dialog="getCurrentPinnedItem.viewConfig"
                  v-if="isMobile"
                />
              </div>
              <viewConfigView
                v-else
                :height="getheight"
                :object="null"
                :viewconfig="getCurrentPinnedItem.viewConfig"
                :editmode="false"
                :search="null"
                :in-card="false"
              />
            </div>

            <!-- <div
                v-else
                class="centered-element text-center"
              >
                <v-progress-circular
                  :size="100"
                  :width="6"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div> -->
          </v-card>
        </div>
      </template>
    </v-dialog>

  </v-row>
</template>

<script>
import * as Api from "../../../api/objectApi.js";
import * as UTIL from "../../../api/utilities";

import { mapGetters, mapMutations, mapActions } from "vuex";
import SplitCard from '../../splitCard/SplitCard.vue';
import TopBar from '../TopBar.vue';
import BottomBarMobile from '../BottomBarMobile.vue';
import BottomBar from '../BottomBar.vue';
export default {
  components: { SplitCard, TopBar, BottomBarMobile, BottomBar },
  data() {
    return {
      isFirstTime: true,
      showContent: false,
      loadContent: false
    };
  },
  computed: {
    ...mapGetters(['getCurrentPinnedItem', 'getShowPinnedDialog', 'getCurrentDialog']),
    isMobile() {
      return this.$store.getters.getDeviceSize === 1
    },
    getPinnedItems() {
      const items = this.$store.getters.getPinnedItems;
      if (this.isMobile) {
        return items.filter(item => item.isMobile === true)
      }
      return items
    },

    isActive() {
      return this.showDialog.value;
    },
    showDialog: {
      get() {
        return this.getShowPinnedDialog
      },
      set(value) {
        // this.store.dispatch('hidePinnedDialog')
      }
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.getCurrentPinnedItem.viewconfig);
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getOptionsPinnedActions() {
      if (this.getOptions) {
        return this.getOptions.pinnedActions
      }
      return []
    },
  },
  watch: {
    getShowPinnedDialog(value) {
      console.log('newdialog', value)
      this.showDialog = value
    }
  },
  methods: {
    changeCurrentDialog(pinned, value) {
      const viewConfig = pinned && pinned.viewConfig
      this.$store.commit('setCurrentDialog', UTIL.isTrue(value) ? viewConfig : null)
    },
    onBack() {
      this.$store.dispatch("changeViewState", 0);
    },
    getCurrentObject(splitcard) {
      if (this.$store.getters.getCurrentSplitcard) {
        var currentDetailState = this.$store.getters.getStateForSplitCard(splitcard);
        if (currentDetailState > (this.isMobile ? 3 : 4)) {

          var currentObject = this.$store.getters["getCurrentObject"]
          if (currentObject) {
            if (!UTIL.isEmpty(currentObject.Subject)) return currentObject.Subject
            if (!UTIL.isEmpty(currentObject.Company)) return currentObject.Company
            if (!UTIL.isEmpty(currentObject.FullName)) return currentObject.FullName
          }
        }
      }
      return null
    },
    close(item) {
      this.$store.dispatch('hidePinnedDialog', item)
    },
    pinItemClicked(item) {
      this.$store.dispatch('showPinnedDialog', item)
    },

    getColor(item) {
      return this.showDialog.value ? "primary" : null;
    },
    showTheContent() {
      this.showContent = true;
    },
    getheight() {
      return "calc(100vh - 64px)"
    },
    loadTheContent() {
      this.isFirsTime = false;
      this.loadContent = true;
    }
  }
};
</script>

<style>
.centered-element {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}
</style>
