import * as Constants from "./constants";
import * as UTIL from "../api/utilities.js";
import Vue from "vue";

/**
 * ViewConfig
 */

export default {
  state: { customSizes: [], editModes: [] },
  mutations: {
    /**
     * Payload: viewconfig, size
     */
    toggleCustomSize(state, viewconfig) {
      var customSize = state.customSizes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
      if (UTIL.isEmpty(customSize)) {
        customSize = {
          viewconfig: viewconfig,
          size: 1000
        };
        state.customSizes.push(customSize);
      } else {
        if (customSize.size === 0) {
          Vue.set(customSize, "size", 1000);
        } else {
          Vue.set(customSize, "size", 0);
        }
      }
    },
    /**
     * Payload: viewconfig, size
     */
    changeEditMode(state, payload) {
      // Payload is a list of views with state
      var newEditMode = payload;
      var editMode = state.editModes.find(function(o) {
        return o.viewconfig === newEditMode.viewconfig;
      });
      if (UTIL.isEmpty(editMode)) {
        newEditMode.operationIds = [];
        state.editModes.push(newEditMode);
      } else {
        Vue.set(editMode, "operationId", newEditMode.operationId);
        if (UTIL.isEmpty(editMode.operationId)) {
          // reset the operations.
          Vue.set(editMode, "operationIds", []);
        }
      }
    },
    addContainerToEditMode(state, payload) {
      var viewconfig = payload.viewconfig;
      var operationId = payload.operationId;
      var editMode = state.editModes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
      if (!UTIL.isEmpty(editMode)) {
        editMode.operationIds.push(operationId);
      }
    }
  },
  getters: {
    getViewFields: (state, getters) => {
      var viewFields = getters.getDataObjectsForObjectType(
        Constants.VIEW_FIELD
      );
      viewFields.sort(function compare(a, b) {
        return a.position - b.position;
      });
      return viewFields;
    },
    getViewConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.VIEW_CONFIG);
    },
    getViewConfigForID: (state, getters) => viewConfig => {
      if (getters.getIsCacheActive(Constants.VIEW_CONFIG) ) {
        return getters.getCachedObjectsForType(
          Constants.VIEW_CONFIG,
          viewConfig
        )[0];
      }
      return getters.getViewConfigs.find(o => {
        return o.viewConfig === viewConfig;
      });
    },
    getEditMode: (state, getters) => viewconfig => {
      var editMode = state.editModes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
      if (!UTIL.isEmpty(editMode)) {
        return !UTIL.isEmpty(editMode.operationId);
      }
      return false;
    },
    getOperationIdForViewConfig: (state, getters) => viewconfig => {
      var editMode = state.editModes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
      if (!UTIL.isEmpty(editMode)) {
        return editMode.operationId;
      }
      return null;
    },
    getOperationIdsForConfig: (state, getters) => viewconfig => {
      var editMode = state.editModes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
      if (!UTIL.isEmpty(editMode)) {
        return editMode.operationIds;
      }
      return [];
    },
    getOperationIdForObjectAndConfig: (state, getters) => payload => {
      var payloadOperationId = {
        object: payload.object,
        operationId: getters.getOperationIdForViewConfig(payload.viewconfig)
      };
      return getters.getOperationIdForObjectAndOperationId(payloadOperationId);
    },
    getOperationIdForObjectAndOperationId: (state, getters) => payload => {
      var object = payload.object;
      var operationId = payload.operationId;
      return operationId + "_" + object.syncId;
    },

    getCustomSize: (state, getters) => viewconfig => {
      return state.customSizes.find(function(o) {
        return o.viewconfig === viewconfig;
      });
    },
    getFieldsForViewFieldListAndSize: (state, getters) => payload => {
      if (getters.getViewFields === undefined) {
        return [];
      }
      if (getters.getIsCacheActive(Constants.VIEW_FIELD,) ) {
        var allFields = getters.getCachedObjectsForType(
          Constants.VIEW_FIELD,
          payload.fieldList
        );
      } else {
        allFields = getters.getViewFields.filter(function(o) {
          return o.fieldList === payload.fieldList;
        });
      }

      //If in config mode we will show all the fields.
      if (getters.isConfigMode({ viewConfig: "dummy" })) {
        return allFields;
      }

      if (!UTIL.isEmpty(payload.object)) {
        payload.objectConfig.getFieldsForObjectAndConfig(
          payload.object,
          payload.viewConfig,
          allFields,
          null,
          "display"
        );
      }

      // if the display we show only the display

      if (payload.list === "listDisplay") {
        return allFields.filter(function(o) {
          return (
            o.fieldList === payload.fieldList &&
            o.size <= payload.size &&
            !UTIL.isTrue(o.isHidden) &&
            (UTIL.isTrue(o.isList) ||
              (UTIL.isEmpty(o.isList) && UTIL.isTrue(o.isDisplay)))
          );
        });
      }

      if (payload.list === "display") {
        return allFields.filter(function(o) {
          return (
            o.fieldList === payload.fieldList &&
            o.size <= payload.size &&
            !UTIL.isTrue(o.isHidden) &&
            UTIL.isTrue(o.isDisplay)
          );
        });
      }
      //
      if (payload.list === "edit") {
        return allFields.filter(function(o) {
          return (
            o.fieldList === payload.fieldList &&
            o.size <= payload.size &&
            !UTIL.isTrue(o.isHidden) &&
            UTIL.isTrue(o.isEdit)
          );
        });
      }
      if (payload.list === "listEdit") {
        return allFields.filter(function(o) {
          return (
            o.fieldList === payload.fieldList &&
            o.size <= payload.size &&
            !UTIL.isTrue(o.isHidden) &&
            UTIL.isTrue(o.isListEdit)
          );
        });
      }
    }
  },
  actions: {}
};
