import GenericObjectConfig from "./GenericObjectConfig.js";
import * as Constants from "../store/constants";

/**
 * This Class is part of the core. It represents an object that must be synchronised and saved to the db.
 * It contains:
 *  ID: The Id of the field.
 *  Name: This will be used to create the table.
 *  Description: The obj
 */
export default class FieldConfig extends GenericObjectConfig {
  constructor() {
    super(Constants.FIELD_CONFIG, null);
    //Added to not create the object twice.
    this.objectConfig = {
      objectType: Constants.FIELD_CONFIG,
      name: Constants.FIELD_CONFIG,
      icon: Constants.FIELD_CONFIG_ICON,
      skipTableCreate: false,
      syncGroup: Constants.SYNC_GROUP_CORE_FIELDS,
      editMode: false,
      Guid: "09a33e6b-f60d-f380-1eb5-970019dafee7"
    };
  }
  /**
   * OverWritten as this a core object it is not loaded from the database.
   */
  initFields() {
    this.fieldsConfig = [
      {
        objectType: Constants.FIELD_CONFIG,
        field: "syncId",
        description: "syncId",
        isId: false,
        type: "string",
        edit: false,
        local: true,
        editMode: false,
        Guid: "0837da99-fe2b-a78b-335f-42cdc695c18c"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "Guid",
        field: "Guid",
        description: "Guid",
        isId: true,
        type: "string",
        edit: true,
        local: false,
        editMode: false,
        Guid: "c883e72c-1d29-912f-4efd-d6a1f0863110"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "backendField",
        field: "backendField",
        description: "backendField",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "2d686b46-4bb9-378b-3940-9d3e62e39c0a"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "field",
        field: "field",
        description: "field",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "0886f33b-b4cd-bada-ccf0-8081fb68d3f5"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "description",
        field: "description",
        description: "description",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "f171a14b-edb0-7329-6128-6a1f0378e2e4"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "isId",
        field: "isId",
        description: "isId",
        isId: false,
        type: "boolean",
        edit: true,
        editMode: false,
        Guid: "c4ae32aa-f8f4-58b8-2996-6f4c97bdd9dd"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "isLogicalKey",
        field: "isLogicalKey",
        description: "isLogicalKey",
        isId: false,
        type: "boolean",
        edit: true,
        editMode: false,
        Guid: "f7de16aa-f8f4-k8b8-2a06-9a2d9bbddkdd"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "type",
        field: "type",
        description: "type",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "ac979019-6d5a-6934-8d16-2610ac2f26be"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "edit",
        field: "edit",
        description: "edit",
        isId: false,
        type: "boolean",
        edit: true,
        editMode: false,
        Guid: "220e00ba-144c-6c70-ade6-9378eddcfd06"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "local",
        field: "local",
        description: "local",
        isId: false,
        type: "boolean",
        edit: true,
        editMode: false,
        Guid: "1cb58797-1340-a911-26f5-c84d30a1a525"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "objectType",
        field: "objectType",
        description: "objectType",
        relatedObjectType: Constants.OBJECT_CONFIG,
        relatedObjectField: "objectType",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "71160e81-df1c-de51-8319-b20e41baf951"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "relatedObjectType",
        field: "relatedObjectType",
        description: "relatedObjectType",
        relatedObjectType: Constants.OBJECT_CONFIG,
        relatedObjectField: "objectType",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "0f576b9e-6e2b-9a09-2142-8d0b1730c01b"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "relatedObjectField",
        field: "relatedObjectField",
        description: "relatedObjectField",
        relatedObjectType: Constants.FIELD_CONFIG,
        relatedObjectField: "field",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "c043ab37-ad9c-71d5-d198-cb777a966c8f"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "selectionView",
        field: "selectionView",
        description: "selectionView",
        relatedObjectType: Constants.VIEW_CONFIG,
        relatedObjectField: "viewConfig",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "5e7ff382-908b-0c27-8132-41cf122d8f0a"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "dropdown",
        field: "dropdown",
        description: "dropdown",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "814de603-d500-ef9b-7f2e-9f25518ca21a"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "convertor",
        field: "convertor",
        description: "convertor",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "85fbb62c-99bc-9bcf-8e32-a3195d8eebab"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "isDeleted",
        field: "isDeleted",
        description: "isDeleted",
        isId: false,
        type: "integer",
        edit: true,
        editMode: false,
        Guid: "c221c507-45f4-32e5-6872-9edc386a781b"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "_id",
        field: "_id",
        description: "_id",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "7e991dcd-acde-49d4-f011-157eeb28d154"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "auth",
        field: "auth",
        description: "auth",
        isId: false,
        type: "string",
        edit: true,
        editMode: false,
        Guid: "a0baa956-3109-b658-2aad-5a420c052c36"
      },
      {
        objectType: Constants.FIELD_CONFIG,
        backendField: "updatedAt",
        field: "updatedAt",
        description: "updatedAt",
        isId: false,
        type: Constants.STRING,
        edit: false,
        Guid: "cb05813b-12a8-42f7-57dd-e0e3054a06a0"
      },
    ];
    for (var field in this.fieldsConfig) {
      this.fieldsConfig[field].objectType = this.objectType;
    }
  }
}
