<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="360"
    rounded="lg"
  >

    <v-card rounded="lg">
      <v-card-title
        v-if="title"
        class="text-h5"
      >
        {{ title }}
      </v-card-title>
      <v-card-text v-if="bodyText">
        {{ bodyText }}
      </v-card-text>
      <slot name="body" />
      <v-card-actions class="pa-4">
        <v-spacer />
        <v-btn
          class="px-6"
          text
          @click="cancel"
        >
          {{ cancelText? cancelText: 'Cancel' }}
        </v-btn>

        <v-btn
          :color="saveColor ? saveColor : 'primary'"
          class="px-6"
          elevation="0"
          @click="save"
        >
          {{ saveText? saveText: 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    bodyText: {
      type: String,
      default: null
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    saveText: {
      type: String,
      default: 'Save'
    },
    saveColor: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    cancel() {
      this.$emit('on-cancel')
    },
    save() {
      this.$emit('on-save')
    },
  }
};
</script>

