<template>
  <v-footer
    :inset="true"
    absolute
    :height="getHeightOfBar"
    style="pointer-events:none;"
    color="transparent"
    class="footer transparent"
    fixed
    app
  >
    <v-row
      style="nowrap;"
      align="center"
      justify="center"
    >

      <v-card
        flat
        class="py-0 px-2 transparent rounded-xl blur outlined"
        style="pointer-events:all;"
        elevation="0"
        color="white"
      >
        <v-btn
          :ripple="false"
          icon
          @click.native="changeView(0)"
        >
          <v-icon :color="getBottomSheetColor(0)">hi-list-bullet</v-icon>
        </v-btn>
        <v-btn
          :ripple="false"
          icon
          @click.native="changeView(4)"
        >
          <v-icon :color="getBottomSheetColor(4)">hi-book-open-outline</v-icon>
        </v-btn>
        <v-btn
          :ripple="false"
          icon
          @click.native="changeView(8)"
        >
          <v-icon :color="getBottomSheetColor(8)">hi-view-columns-outline</v-icon>
        </v-btn>
        <v-btn
          :ripple="false"
          icon
          @click.native="changeView(12)"
        >
          <v-icon :color="getBottomSheetColor(12)">hi-rectangle-group-outline</v-icon>
        </v-btn>
      </v-card>
    </v-row>
  </v-footer>
</template>

<script>
import * as UTIL from "../../api/utilities"

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {},
  props: {
    dialog: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      detailState: ""
    };
  },
  computed: {
    ...mapGetters(["getSplitCards"]),
    getCurrentObject() {
      var config = this.$store.getters["ui/getConfig"];
      return this.$store.getters["ui/getCurrentObject"](config);
    },
    getCurrentSplitcard: function () {
      const splitcard = UTIL.isEmpty(this.dialog) ? this.$store.getters.getCurrentSplitcard : this.$store.getters.getDialogSplitcard(this.dialog);
      return splitcard;
    },
    getCurrentDetailsState: function () {
      var currentDetailState = this.$store.getters.getStateForSplitCard(this.getCurrentSplitcard.splitCard);
      if (currentDetailState !== undefined) {
        return currentDetailState
      }
      return 0;
    },
    getHeightOfBar() {
      var toppadding = getComputedStyle(document.documentElement).getPropertyValue("--sab");
      return UTIL.addIntToPixelString(toppadding, 60);
    }
  },
  methods: {
    getBottomSheetColor: function (buttonNumber) {
      if (this.getCurrentDetailsState === buttonNumber) {
        return "primary";
      }
      return "bottomBarItem";
    },
    changeView(buttonNumber) {
      this.$store.dispatch("changeViewState", buttonNumber);
    }

  }
};
</script>
