<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-dialog
      v-model="dialog.visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="dialog.visible">
          <v-btn
            color="primary"
            icon
            @click="cancelSelection()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
          <viewConfigView
            :object="selectedField"
            :editmode="false"
            :dialog="dialog"
            :key="componentKey"
            :viewconfig="viewConfigOfSelection"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <!-- <v-row
      flat
      class="pa-1 ma-0 flex-nowrap"
    >
      <v-container
        class="pa-0 ma-0"
        fluid
      >
        <FilterView
          :viewconfig="viewconfig"
          :is-compact-view="isCompactView"
          :object="object"
          remove-search
          read-only
        />
      </v-container>
    </v-row> -->
    <v-card
      flat
      class="pa-0 ma-0 transparent"
    >
      <v-layout row>
        <v-container
          ref="myListOuter1"
          class="pa-0 ma-0 removeScroll xs12"
          fluid
        >

          <v-list class="transparent">
            <TileItemView
              v-for="item in getFilteredObjects"
              :key="item.Guid"
              :viewconfig="viewconfig"
              :object="item"
            />
          </v-list>
        </v-container>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import listViewAction from "./ListViewActions";
import FilterView from "./FilterView.vue";
import ViewConfigView from "./viewConfigView.vue";
import TileItemView from "../components/customTiles/TileItemView.vue";


export default {
  components: { listViewAction, FilterView, ViewConfigView, TileItemView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    ismass: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: undefined,
      hover: false,
      myListWidth: 0,
      showFilter: true,
      counter: 0,
      componentKey: "myListViewComponentKey",
      viewConfigOfSelection: null,
      selectedField: null,
      selected: [],
      keyObject: {
        key: 1
      },
      dialog: {
        visible: false
      }
    };
  },
  computed: {
    //do no know what this does.
    getHeight() {
      return "300px";
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getClassOfHeader() {
      if (this.isConfigMode) {
        return "normalCell";
      }
      return "lowCell";
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
    getWidthSize() {
      var customSize = this.$store.getters.getCustomSize(this.viewconfig);
      if (!UTIL.isEmpty(customSize)) {
        if (customSize.size > 0) {
          return customSize.size;
        }
      }
      return this.getWidth;
    },
    getFieldListType() {
      if (this.isEditMode) {
        return "listEdit";
      }
      return "listDisplay";
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: this.getWidthSize,
          list: this.getFieldListType
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.filter(element => element.type !== "Title");
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "left"; //right
        }
        field.sortable = true;
      }
      return fields;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getRowHeight() {
      return 48;
    },
    getNumberOfRows() {
      var height = UTIL.pixelStringToInt(this.height);
      if (height > 200) {
        var numberOfRows = (height - 120) / this.getRowHeight;
        return Math.floor(numberOfRows);
      }
      return 5;
    }
  },
  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    setFields(fields) {
      this.fields = fields;
    },
    getOperationIdForItem(item) {
      var payload = {
        object: item,
        viewconfig: this.viewconfig
      };
      return this.$store.getters.getOperationIdForObjectAndConfig(payload); //todo.
    },
    getItem(item) {
      var operationId = this.getOperationIdForItem(item);
      var operationContainer = this.$store.getters.getOperationContainerForId(
        operationId
      );
      if (!UTIL.isEmpty(operationContainer)) {
        return operationContainer.newObject;
      }
      return item;
    },

    getAlign(align) {
      if (align === null || align === "" || align === undefined) {
        return "right";
      } else {
        return align;
      }
    },
    openDialog(field) {
      this.selectedField = field;
      this.viewConfigOfSelection = "ViewFieldviewConfig2";
      this.dialog.visible = true;
    },
    getColorForfield(field, type) {
      if (UTIL.isTrue(field[type])) {
        return "primary";
      }
      return "gray";
    },
    objectSelected(selectedObject) {
      //this.object[this.selectedField.value] = selectedObject.objectType;
      this.closeDialog();
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.dialog.visible = false;
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}

.lowCell {
  height: 5px !important;
}

.normalCell {
  height: 30px !important;
}
</style>
