import { copyObject, isTrue } from "../api/utilities";
import Vue from "vue";
export const NOTIFICATION_MAIN = "NotificationMain";

export default {
  state: { massUpdates: {} },

  mutations: {
    setMassUpdate(state, payload) {
      console.log("update the mass updates");
      Vue.set(
        state.massUpdates,
        payload.massUpdateViewConfig,
        payload.massUpdate
      );
    }
  },
  getters: {
    getNotifications: (state, getters) => {
      var notifications = getters.getDataObjectsForObjectType(
        NOTIFICATION_MAIN
      );
      notifications.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      return notifications;
      /*
      if (a.createdAt > b.createdAt){
        return 1
      } else if (a.createdAt < b.createdAt){
        return -1
      }
      return 0
      */
    },
    getCurrentEmployeeNotifications: (state, getters) => {
      const guid = getters.getCurrentEmployeeGuid;
      console.log("EmployeeGuid", guid);
      if (guid) {
        return getters.getNotifications.filter(o => {
          return o.Employee === guid;
        });
      }
      return [];
    },
    getNotificationsList: (state, getters) => {
      var unReadNotifications = getters.unReadNotifications;
      var latestReadNotifications = getters.getLatestReadNotifications;
      return unReadNotifications.pushAll(latestReadNotifications);
    },
    getUnreadNotifications: (state, getters) => {
      var allNofifications = getters.getNotifications;
      return allNofifications.filter(o => {
        return !isTrue(o.IsRead);
      });
    },
    getLatestReadNotifications: () => {
      var allNofifications = getters.getNotifications;
      var readNotification = allNofifications.filter(o => {
        return isTrue(o.IsRead);
      });
      if (readNotification.length > 5) {
        return readNotification.slice(0, 5);
      }
      return readNotification;
    }
  },
  actions: {
    readNotification({ dispatch, commit, getters }, notification) {
      if (!isTrue(notification.isRead)) {
        const oldObject = copyObject(notification);
        Vue.set(notification, "IsRead", true);
        var now = new Date().toJSON();
        Vue.set(notification, "DateRead", now);
        dispatch("saveObjectForObjectType", {
          object: notification,
          objectType: NOTIFICATION_MAIN,
          oldObject
        });
      }
    }
  }
};
