import * as Api from "../api/objectApi";
import * as ObjectScripts from "../customLogic/coreScripts";
import * as Utilities from "../api/utilities.js";
import { toJSON } from "./DBWebWorker";

export default class GenerecObjectConfig {
  constructor(objectType, fieldsConfig, objectConfig) {
    this.objectType = objectType;
    this.fieldsConfig = fieldsConfig; // list of fields
    this.objectConfig = objectConfig;
    this.initFields(); // Overwrite the fields
  }
  initFields() {
    // TODO the fields must be loaded.
  }
  /*
      Properties and Id"s
  */
  getIds() {
    var list = this.fieldsConfig.filter(function (o) {
      return o.isId === true || o.isId === 1 || o.isId === "true";
    });
    var ids = [];
    for (var i = 0; i < list.length; i++) {
      ids.push(list[i].field);
    }
    return ids;
  }
  getIdOfObject(object) {
    var ids = this.getIds();
    var id = "";
    var point = "";
    for (var i = 0; i < ids.length; i++) {
      if (point !== "") {
        id = id.concat(object[ids[i]]);
        point = ".";
      } else {
        id = id.concat(point).concat(object[ids[i]]);
      }
    }
    return id;
  }
  /*
    Find objects in lists
  */
  getOldObjectAndIndexInList(list, object) {
    var id = this.getIdOfObject(object);
    var oldObject = this.getObjectForIdsInList(list, object);
    if (oldObject !== undefined) {
      var index = list.indexOf(oldObject);
      //oldObject.version = oldObject.version + 1;
      list[index] = object;
    } else {
      index = -1;
    }
    var payload = {
      oldObject: oldObject,
      object: object,
      index: index,
    };
    return payload;
  }
  getObjectForIdsInList(list, object) {
    var idFields = this.getIds();
    /*
    var idNumber;
    if (isNaN(idField)) {
      idNumber = id;
    } else {
      idNumber = Number(id);
    }
    */
    var object = list.find(function (o) {
      var idField;
      for (var i = 0; i < idFields.length; i++) {
        idField = idFields[i];
        if (o[idField] !== object[idField]) {
          return false;
        }
      }
      return true;
    });
    return object;
  }
  getObjectForIdInList(list, id) {
    var idField = this.getIds()[0];
    var idNumber;
    if (isNaN(idField)) {
      idNumber = id;
    } else {
      idNumber = Number(id);
    }
    var object = list.find(function (o) {
      return o[idField] === idNumber;
    });
    return object;
  }
  /*
    compare Objects
  */
  isSameObject(object1, object2) {
    var properties = this.fieldsConfig;
    if (Utilities.isEmpty(object1) && !Utilities.isEmpty(object2)) {
      return false;
    }
    if (Utilities.isEmpty(object2) && !Utilities.isEmpty(object1)) {
      return false;
    }
    // Properties
    for (var i = 0; i < properties.length; i++) {
      var property = properties[i];
      if (object1[property.field] !== object2[property.field]) {
        if (property.field !== "syncId") {
          return false;
        }
      }
    }
    return true;
  }
  /******************************************
    Scripts
  *******************************************/
  onCreate(object, actionConfig, context, options) {
    var newObject = Api.copyObject(object);
    //Find the actionConfig which is the filter to fill.
    return newObject;
  }

  afterCreate(object, relatedObject, context, options) {
    var _objectConfig = this;
    ObjectScripts.afterCreate(
      _objectConfig,
      object,
      relatedObject,
      context,
      options,
    );
    return object;
  }

  beforeSave(object, context, options) {
    // To be implemented if needed.
    var _objectConfig = this;
    return ObjectScripts.beforeSave(_objectConfig, object, context, options);
    //return object;
  }

  afterModify(object, relatedObject, context, options) {
    var _objectConfig = this;
    ObjectScripts.afterModify(
      _objectConfig,
      object,
      relatedObject,
      context,
      options,
    );
    return object;
  }

  getFieldsForObjectAndConfig(object, viewConfig, fields, context, options) {
    var _objectConfig = this;
    ObjectScripts.getFieldsForObjectAndConfig(
      _objectConfig,
      object,
      viewConfig,
      fields,
      context,
      options,
    );
    return fields;
  }

  getTabsForObjectAndConfig(object, viewConfig, tabs, context, options) {
    var _objectConfig = this;
    ObjectScripts.getTabsForObjectAndConfig(
      _objectConfig,
      object,
      viewConfig,
      tabs,
      context,
      options,
    );
    return tabs;
  }

  hasCustomAuthorisation(object, viewConfig, context, options) {
    var _objectConfig = this;
    return ObjectScripts.hasCustomAuthorisation(
      _objectConfig,
      object,
      viewConfig,
      context,
      options,
    );
  }

  async launchActionForObjectAndConfig(
    action,
    object,
    viewConfig,
    context,
    options,
  ) {
    var _objectConfig = this;
    return await ObjectScripts.launchActionForObjectAndConfig(
      action,
      _objectConfig,
      object,
      viewConfig,
      context,
      options,
    );
  }

  canLaunchActionForObjectAndConfig(
    action,
    object,
    viewConfig,
    context,
    options,
  ) {
    var _objectConfig = this;
    return ObjectScripts.canLaunchActionForObjectAndConfig(
      action,
      _objectConfig,
      object,
      viewConfig,
      context,
      options,
    );
  }

  /*******************************************
   * Convert
   *******************************************/

  convertObjects(objects) {
    var convertedObjects = [];
    var properties = this.fieldsConfig;
    objects.forEach((object) => {
      var newObject = {};
      properties.forEach((property) => {
        if ([object[property.backendField] !== undefined]) {
          newObject[property.field] = object[property.backendField];
        }
      });
      convertedObjects.push(newObject);
    });
    return convertedObjects;
  }

  convertObjectsToDBObjects(objects) {
    var convertedObjects = [];
    var properties = this.fieldsConfig;
    objects.forEach((object) => {
      var newObject = {};
      properties.forEach((property) => {
        if (object[property.field] === undefined) {
          newObject[property.field] = Utilities.getDefaultValueForType(
            property.type,
          );
        } else {
          if (property.type == "array") {
            newObject[property.field] = JSON.stringify(object[property.field]);
          } else {
            newObject[property.field] = object[property.field];
          }
        }
      });
      convertedObjects.push(newObject);
    });
    return convertedObjects;
  }

  convertFromDatabase(objects) {
    var properties = this.fieldsConfig;
    objects.forEach((object) => {
      //var newObject = {};
      properties.forEach((property) => {
        if (property.type === "boolean") {
          if (Utilities.isTrue(object[property.field])) {
            object[property.field] = true;
          } else {
            object[property.field] = false;
          }
        }
        if (property.type == "array") {
          try {
            object[property.field] = JSON.parse(object[property.field]);
          } catch (error) {
            console.log(error);
          }
        }
      });
      //convertedObjects.push(newObject);
    });
    return objects;
  }
  /*
    Database
  */
  /**
   * This method will return search for all the object in the database. It returns a resolve.
   * @param {*} dbHelper: The dehelper must be given.
   * @param {*} successFunction: The function that will be called on success.
   */

  findObjectHistoryForSyncId(dataBase, syncId) {
    var tableName = this.objectType.concat("History");
    return new Promise(function (resolve, reject) {
      var sqlStatement = "SELECT * FROM "
        .concat(tableName)
        .concat(" Where syncId = ?");
      dataBase.query(
        sqlStatement,
        [syncId],
        function (results) {
          resolve(results);
        },
        function () {
          var errorLog = "FindAll failed:" + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  findAll(dataBase) {
    var tableName = this.objectType;
    var __this = this;
    return new Promise(function (resolve, reject) {
      var sqlStatement = "SELECT * FROM ".concat(tableName);
      dataBase.query(
        sqlStatement,
        [],
        function (results) {
          var converted = __this.convertFromDatabase(results);
          resolve(converted);
        },
        function () {
          var errorLog = "FindAll failed:" + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  findAllHistory(dataBase) {
    var tableName = this.objectType + "History";
    var __this = this;
    return new Promise(function (resolve, reject) {
      var sqlStatement = "SELECT * FROM ".concat(tableName);
      dataBase.query(
        sqlStatement,
        [],
        function (results) {
          var converted = __this.convertFromDatabase(results);
          resolve(converted);
        },
        function () {
          var errorLog = "FindAll failed:" + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  /**
   * Save all method. This does a bulk upsert.
   * @param {} dbHelper
   * @param {*} objects
   * @param {*} successFunction
   */
  saveAll(dataBase, objects, synchronisation) {
    //Here we will need to transform the objects.
    var tableName = this.objectType;
    var keyFields = this.getIds();
    var __this = this;
    //Convert all objects
    var dbObjects = this.convertObjectsToDBObjects(objects);

    return new Promise(function (resolve, reject) {
      dataBase.bulkUpsert(
        tableName,
        dbObjects,
        keyFields,
        function () {
          resolve(synchronisation);
        },
        function (sql, params, error) {
          var errorLog = "Bulkupsert failed:" + tableName;
          __this.findQueryError(sql, params, error);
          reject(errorLog);
        },
      );
    });
  }
  /**
   * Save all method. This does a bulk upsert.
   * @param {} dbHelper
   * @param {*} objects
   * @param {*} successFunction
   */
  saveAllHistory(dataBase, objects) {
    var tableName = this.objectType.concat("History");
    var keyFields = ["syncId"];
    var __this = this;
    var dbObjects = this.convertObjectsToDBObjects(objects); // convertObjectsToDBObjects
    return new Promise(function (resolve, reject) {
      dataBase.bulkUpsert(
        tableName,
        dbObjects,
        keyFields,
        function () {
          resolve();
        },
        function (sql, params, error) {
          var errorLog =
            "Bulkupsert history failed:" + tableName + " objects: " + dbObjects;
          console.log(
            "Error in object Config: ",
            error,
            " sql: ",
            sql,
            " params: ",
            params,
          );
          __this.findQueryError(sql, params, error);
          reject(errorLog);
        },
      );
    });
  }
  /**
   * Method to delete the table of the table
   * @param {*} dataBase
   */
  deleteTable(dataBase) {
    var tableName = this.objectType;
    var sqlStatement = "DROP TABLE IF EXISTS ".concat(tableName);
    console.log(sqlStatement);
    var _this = this;
    return new Promise(function (resolve, reject) {
      dataBase.exec(
        sqlStatement,
        null,
        function () {
          resolve();
        },
        function () {
          var errorLog =
            "Delete table failed:" + tableName + " sql: " + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  /**
   * Method do delete the history table.
   * @param {*} dataBase
   */
  deleteTableHistory(dataBase) {
    var tableName = this.objectType.concat("History");
    var sqlStatement = "DROP TABLE IF EXISTS ".concat(tableName);
    var _this = this;
    return new Promise(function (resolve, reject) {
      dataBase.exec(
        sqlStatement,
        null,
        function () {
          resolve();
        },
        function () {
          var errorLog =
            "Delete table failed:" + tableName + " sql: " + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  clearItemsInDbForQuery(database, query) {
    //Here we need to delete the items.
    var tableName = this.objectType;
    var sqlStatement = `DELETE FROM ${tableName} WHERE ${query}`;
    return new Promise(function (resolve, reject) {
      database.query(
        sqlStatement,
        null,
        function (result) {
          resolve(result);
        },
        function () {
          var errorLog = "delete failed:" + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  /**
   * Method to create the Table for the objects. This method will call the method to create the History table to
   */
  createTable(dataBase) {
    var shouldCreate = false;
    var propertiesString = "( ";
    var properties = this.fieldsConfig;
    var tableName = this.objectType;
    var comma = "";
    var ids = this.getIds();
    // Properties
    for (var i = 0; i < properties.length; i++) {
      var property = properties[i];
      propertiesString = propertiesString
        .concat(property.field)
        .concat(" ")
        .concat(Api.getTypeOfProperty(property.type))
        .concat(", ");
    }
    propertiesString = propertiesString.concat(" PRIMARY KEY( ");
    ids.forEach((id) => {
      propertiesString = propertiesString.concat(comma).concat(id);
      comma = ", ";
      shouldCreate = true;
    });
    propertiesString = propertiesString.concat(" ) )");
    var sqlStatement = "CREATE TABLE IF NOT EXISTS "
      .concat(tableName)
      .concat(propertiesString);
    // Return function
    var _this = this;
    if (!shouldCreate) {
      console.log("Fields missing Table should not be created", tableName);
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
    return new Promise(function (resolve, reject) {
      dataBase.exec(
        sqlStatement,
        null,
        function () {
          resolve();
        },
        function () {
          var errorLog = "Create failed:" + tableName + " sql: " + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }
  /**
   * Method to create the Table for the objects. This method will call the method to create the History table to
   */
  createTableHistory(dataBase) {
    var shouldCreate = false;
    var propertiesString = "( ";
    var properties = this.fieldsConfig;
    var tableName = this.objectType.concat("History");
    var comma = "";
    // Properties
    for (var i = 0; i < properties.length; i++) {
      var property = properties[i];
      propertiesString = propertiesString
        .concat(property.field)
        .concat(" ")
        .concat(Api.getTypeOfProperty(property.type))
        .concat(", ");
      if (property.field === "syncId") {
        shouldCreate = true;
      }
    }
    propertiesString = propertiesString.concat(" PRIMARY KEY( syncId ) )");
    var sqlStatement = "CREATE TABLE IF NOT EXISTS "
      .concat(tableName)
      .concat(propertiesString);

    if (!shouldCreate) {
      console.log("Fields missing Table should not be created", tableName);
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
    return new Promise(function (resolve, reject) {
      dataBase.exec(
        sqlStatement,
        null,
        function () {
          resolve();
        },
        function () {
          var errorLog =
            "Create history failed:" + tableName + " sql: " + sqlStatement;
          reject(errorLog);
        },
      );
    });
  }

  findQueryError(sql, query, params) {
    var result = sql.split("(");
    var sqlFieldsString = result[1].split(")")[0];
    var properties = this.fieldsConfig;
    var sqlFields = sqlFieldsString.split(",");
    sqlFields.forEach((field) => {
      var trimmedField = field.trim();
      var found = null;
      found = properties.find(function (property) {
        return property.field === trimmedField;
      });
      if (Utilities.isEmpty(found)) {
        console.log("Problem with field", trimmedField);
        return trimmedField;
      }
    });
    return null;
  }

  getDataBaseInfo(dataBase) {
    console.log("Get Database info");
    var sqlStatement = "SELECT * FROM sqlite_master WHERE type = 'table'"; // name NOT LIKE 'sqlite\\_%' escape '\\' AND name NOT LIKE '\\_%' escape '\\'"
    return new Promise(function (resolve, reject) {
      dataBase.exec(
        sqlStatement,
        null,
        function (response) {
          var result = [];
          if (response && response[0]) {
            result = toJSON(response)[0];
          }
          resolve(result);
        },
        function (errorLog) {
          console.log("getDatabaseInfo error :", errorLog);
          reject(errorLog);
        },
      );
    });
  }
}
