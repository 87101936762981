import * as Constants from "./constants.js";
import * as Api from "../api/objectApi.js";
import * as UTIL from "../api/utilities.js";
import * as SyncApi from "../api/synchronizationApi.js";
import axios from "axios";
import Vue from "vue";
import { getAccessToken, isTokenValid } from "./authorizationContext.js";
import moment from "moment";
import { SYNC_GROUP } from "./synchronisationContext.js";

/**
 * App mutators
 */
export const _SET_OPERATION_CONTAINER = "_setOperationContainer";
export const _CANCEL_OPERATION_CONTAINER = "_cancelOperationContainer";
export const _SAVE_OPERATION_CONTAINER = "_saveOperationContainer";
export const _SET_GENERAL_OPERATION_STATUS = "_setGeneralOperationStatus";

export const OPERATION_STATUS_INITIAL = "Initial";
export const OPERATION_STATUS_SAVED = "Saved";
export const OPERATION_STATUS_SENT = "Sent";
export const OPERATION_STATUS_FAILED = "Failed";
export const OPERATION_STATUS_SUCCESS = "Completed";
export const OPERATION_STATUS_CANCELLED = "Cancelled";

export const SAVE_TYPE_LOCAL = "SaveTypeLocal";
export const SAVE_TYPE_REMOTE = "SaveTypeRemote";

export const SAVE_ERROR = 2;
export const SAVE_WARNING = 1;
export const SAVE_OK = 0;

/**
 * App State
 */
export default {
  state: {
    operations: [],
    currentOperationContainer: null,
    operationContainers: [],
    operationContainerForIDs: {},
    generalOperationStatus: OPERATION_STATUS_INITIAL,
  },

  mutations: {
    _setOperationContainer(state, operationContainer) {
      state.currentOperationContainer = operationContainer;
    },

    _cancelOperationContainer(state) {
      state.currentOperationContainer = null;
    },

    _saveOperationContainer(state, operationContainer) {
      state.operationContainers.push(operationContainer);
      state.currentOperationContainer = null;
    },

    _addOperationContainer(state, operationContainer) {
      state.operationContainers.push(operationContainer);
    },

    _setOperationContainerForId(state, payload) {
      Vue.set(
        state.operationContainerForIDs,
        payload.id,
        payload.operationContainer,
      );
    },
    setNewObjectForContainerId(state, payload) {
      Vue.set(
        state.operationContainerForIDs[payload.id],
        "newObject",
        payload.newObject,
      );
    },
    _saveOperationContainerForId(state, id) {
      if (
        state.operationContainerForIDs[id] !== null &&
        state.operationContainerForIDs[payload.id] !== undefined
      ) {
        console.log("Error: Trying to save empty operation Container", id);
        return;
      }
      state.operationContainers.push(state.operationContainerForIDs[id]);
    },
    _cancelOperationContainerForId(state, id) {
      Vue.set(state.operationContainerForIDs, id, null);
    },
    _setGeneralOperationStatus(state, generalOperationStatus) {
      state.generalOperationStatus = generalOperationStatus;
    },
    _setValueForFieldForOperationId(state, payload) {
      console.log(
        "object: ",
        state.operationContainerForIDs[payload.operationId]["newObject"],
      );
      Vue.set(
        state.operationContainerForIDs[payload.operationId],
        "newObject",
        payload.value,
      );
      Vue.set(
        state.operationContainerForIDs[payload.operationId],
        "isDirty",
        1,
      );
    },
  },
  getters: {
    generateOperationId: (state, getters) => {
      return UTIL.getGuid();
    },
    getGeneralOperationStatus: (state) => {
      return state.generalOperationStatus;
    },
    getOperationContainers: (state) => {
      return state.operationContainers;
    },
    getOperations: (state, getters) => {
      console.log("Get CurrentEmployee");
      var currentEmployee = getters.getCurrentEmployeeGuid;
      console.log("Get CurrentEmployee", currentEmployee);
      var operations = getters.getDataObjectsForObjectType(Constants.OPERATION);
      /*
      operations = operations.filter(function(o) {
        return o.employee === currentEmployee;
      });
      */
      return operations;
    },
    getOperationsToSend: (state, getters) => {
      return state.operationContainers.filter(function (o) {
        return (
          o.operation.status !== OPERATION_STATUS_SUCCESS &&
          o.operation.status !== OPERATION_STATUS_CANCELLED &&
          o.operation.status !== OPERATION_STATUS_INITIAL &&
          o.operation.objectType !== "Operation"
        );
      });
    },
    getNumberOfOperationsToSend: (state, getters) => {
      return getters.getOperationsToSend.length;
    },
    getCurrentOperationContainer: (state, getters) => {
      return state.currentOperationContainer;
    },
    getOperationContainerForIds: (state, getters) => {
      return state.operationContainerForIDs;
    },
    getOperationContainerForId: (state, getters) => (Id) => {
      return state.operationContainerForIDs[Id];
    },
    hasCurrentOperationContainer: (state, getters) => {
      return !(
        state.currentOperationContainer === null ||
        state.currentOperationContainer == undefined
      );
    },
  },
  actions: {
    /***
     * completeTask
     */
    cancelFailedOperations({ dispatch, commit, getters }) {
      const operations = getters.getOperations;
      if (operations) {
        for (let index = 0, l = operations.length; index < l; index++) {
          const operation = operations[index];
          if (operation.status === OPERATION_STATUS_FAILED) {
            Vue.set(operation, "status", OPERATION_STATUS_CANCELLED);
            operation.response = "";
            var payloadLead = {
              objectType: Constants.OPERATION,
              object: operation,
              saveType: SAVE_TYPE_LOCAL,
            };
            dispatch("saveObjectForObjectType", payloadLead);
            return;
          }
        }
      }
    },
    updateValueForFieldForOperationId({ dispatch, commit, getters }, payload) {
      var operationId = payload.operationId;
      var operationId = payload.field;
      var operationId = payload.value;
      commit("_setValueForFieldForOperationId", payload);
    },
    async loadOperationContainers({ dispatch, commit, getters }) {
      console.log("Loading operation Containers");
      if (getters.getOperationContainers.length > 0) {
        return;
      }
      var operations = getters.getOperations;
      for (var i = 0; i < operations.length; i++) {
        var operation = operations[i];
        var operationContainer = await dispatch(
          "loadOperationContainerForOperation",
          operation,
        );
        commit("_addOperationContainer", operationContainer);
      }
    },
    async loadOperationContainerForOperation(
      { dispatch, commit, getters },
      operation,
    ) {
      var operationContainer = {};

      var objectConfig = getters.getObjectConfigForType(operation.objectType);
      var oldObjectPayload = {
        syncId: operation.oldId,
        objectConfig: objectConfig,
      };
      await dispatch("findHistoryWithSyncID", oldObjectPayload);

      var newObjectPayload = {
        syncId: operation.newId,
        objectConfig: objectConfig,
      };
      await dispatch("findHistoryWithSyncID", newObjectPayload);

      operationContainer.oldObject = oldObjectPayload.result;
      operationContainer.newObject = newObjectPayload.result;
      operationContainer.operation = operation;
      operationContainer.objectConfig = objectConfig;
      return operationContainer;
    },

    /**
     * Save Operation for operation guid.
     * @param {*} param0
     * @param {*} operationId
     */
    async saveOperationContainerForId(
      { dispatch, commit, getters },
      operationId,
    ) {
      var operationContainer = getters.getOperationContainerForId(operationId);
      if (operationContainer !== null && operationContainer !== undefined) {
        if (
          operationContainer.objectConfig.isSameObject(
            operationContainer.newObject,
            operationContainer.oldObject,
          )
        ) {
          // Check if there are changes.
          commit("_cancelOperationContainerForId", operationId);
          return true;
        } else {
          return await dispatch("saveOperationContainer", operationContainer);
        }
      }
    },
    /**
     * Save the operation Container. It saves the old object in the history table, the new object in the data table and the operation.
     * Afterwards the lists are updated.
     * @param {*} param0
     * @param {*} operationContainer
     */
    async saveOperationContainer(
      { dispatch, commit, getters },
      operationContainer,
    ) {
      // Save the old Object in the history table.
      var store = { dispatch, commit, getters };
      var canSave = operationContainer.objectConfig.beforeSave(
        operationContainer.newObject,
        store,
      );
      if (
        !UTIL.isEmpty(canSave) &&
        canSave.state !== undefined &&
        canSave.state !== null &&
        canSave.state >= SAVE_ERROR
      ) {
        //0: OK, 1:Info, 2:Warning:  3: Error
        var hasError = true;
        console.log("We gaan niet verder met Saven");
        return false;
      }

      // if the old object is the current object we must replace the current with the new
      if (
        operationContainer.oldObject !== null &&
        operationContainer.oldObject !== undefined
      ) {
        var oldCurrentObject1 = getters.getCurrentObject;
        if (
          !UTIL.isEmpty(oldCurrentObject1) &&
          operationContainer.oldObject.Guid !== null &&
          operationContainer.oldObject.Guid !== undefined &&
          oldCurrentObject1.Guid === operationContainer.newObject.Guid // TODO we still must update this.
        ) {
          var splitCard = getters.getCurrentSplitcard;
          var setObjectPayload = {
            object: operationContainer.newObject,
            splitCard: splitCard.splitCard,
          };

          //This must not be done anymore
          await commit("setCurrentObjectForSplitCard", setObjectPayload);
        }
      }

      if (
        operationContainer.oldObject !== null &&
        operationContainer.oldObject !== undefined &&
        operationContainer.oldObject !== {}
      ) {
        var oldObjectPayload = {
          objectConfig: operationContainer.objectConfig,
          object: operationContainer.oldObject,
        };
        await dispatch(Constants.SAVE_OBJECT_HISTORY, oldObjectPayload);
      }
      // Save the new Object in the normal table
      var newObjectPayload = {
        objectConfig: operationContainer.objectConfig,
        object: operationContainer.newObject,
      };
      await dispatch(Constants.SAVE_OBJECT_HISTORY, newObjectPayload);
      await dispatch(Constants.SAVE_OBJECT, newObjectPayload);

      // Save the opeartion in the operation table.
      var objectConfigOperation = getters.getObjectConfigForType(
        Constants.OPERATION,
      );
      if (operationContainer.saveType === SAVE_TYPE_LOCAL) {
        operationContainer.operation.status = OPERATION_STATUS_SUCCESS;
      } else {
        operationContainer.operation.status = OPERATION_STATUS_SAVED;
      }
      var operationPayload = {
        objectConfig: objectConfigOperation,
        object: operationContainer.operation,
      };

      var setOperationPayload = {
        getters: getters,
        payload: operationPayload,
      };

      await commit(Constants.SET_OBJECT, setOperationPayload);
      await dispatch(Constants.SAVE_OBJECT, operationPayload);

      // Set the Current Object
      var newObjectPayload1 = {
        objectConfig: operationContainer.objectConfig,
        object: operationContainer.newObject,
      };
      var payload2 = {
        getters: getters,
        payload: newObjectPayload1,
      };
      await commit(Constants.SET_OBJECT, payload2);
      await commit(_SAVE_OPERATION_CONTAINER, operationContainer);
      if (operationContainer.operation.objectType !== SYNC_GROUP) {
        dispatch("resetAutoSync", 8000);
      }
      return true;
    },
    /**
     * Cancel operation
     */
    async commitObject({ dispatch, commit, getters }, payload) {
      var payload2 = {
        getters: getters,
        payload: payload,
      };
      await commit(Constants.SET_OBJECT, payload2);
    },
    /**
     * Cancel operation
     */
    cancelOperationContainer({ dispatch, commit, getters }, payload) {
      commit(_CANCEL_OPERATION_CONTAINER);
    },

    /**
     *
     *  Change and Save Object
     *  The newObject must be a copy of the old one.
     */
    async changeAndSaveObject({ dispatch, commit, getters }, payload) {
      var oldObject = payload.oldObject;
      var newObject = payload.newObject;
      var config = payload.config;
      var operationId = UTIL.getGuid();
      var payloadCreate = {
        object: oldObject,
        config: config,
        id: operationId,
      };
      await dispatch(Constants.CREATE_OPERATION_CONTAINER, payloadCreate);
      var operationContainer = getters.getOperationContainerForId(operationId);
      if (operationContainer !== null && operationContainer !== undefined) {
        operationContainer.newObject;
        //Apply changes for the newObject.
        operationContainer.newObject = newObject;
        operationContainer.newObject.syncId =
          operationContainer.operation.newId;
        //AfterModify.
        await dispatch("saveOperationContainerForId", operationId);
      }
    },

    /**
     * Create operation. The operation ID is provided.
     */
    createOperationContainer({ dispatch, commit, getters }, payload) {
      console.log("createOperation Container");
      var id = payload.id; //TODO normally

      var operation = {};
      var newObject = null;
      var oldObject = null;
      if (payload.object !== null && payload.object !== undefined) {
        newObject = Api.copyObject(payload.object);
        if (!UTIL.isEmpty(payload.oldObject)) {
          oldObject = Api.copyObject(payload.oldObject, true);
        } else {
          oldObject = Api.copyObject(payload.object, true);
        }
        operation.oldId = payload.object.syncId;
      } else {
        var store = { dispatch, commit, getters };
        newObject = payload.config.onCreate(
          {},
          payload.viewConfig,
          store,
          null,
        );
        //newObject = Api.copyObject({});
      }

      //Save the id.
      if (id !== null && id !== undefined) {
        newObject.syncId = id;
      }
      operation.Guid = UTIL.getGuid();
      operation.newId = newObject.syncId;
      operation.objectType = payload.config.objectType;
      operation.syncGroup = payload.config.syncGroup;
      operation.status = OPERATION_STATUS_INITIAL;
      operation.employee = getters.getCurrentEmployeeGuid;
      operation.createTime = moment().valueOf();
      var objectConfig = getters.getObjectConfigForType(
        payload.config.objectType,
      );

      if (payload.saveType) {
        var saveType = payload.saveType;
      } else {
        saveType = SAVE_TYPE_REMOTE;
      }

      var operationContainer = {
        objectConfig: objectConfig,
        operation: operation,
        newObject: newObject,
        oldObject: oldObject,
        currentObject: payload.object,
        saveType: saveType,
      };
      /**
      var payload = {
        object: operation,
        objectType: Constants.OPERATION
      };
      */

      if (id !== null && id !== undefined) {
        var newPayload = {
          id: id,
          operationContainer: operationContainer,
        };
        commit("_setOperationContainerForId", newPayload);
      } else {
        commit(_SET_OPERATION_CONTAINER, operationContainer); //TODO Should be removed
      }
    },

    //createOperationContainers
    createOperationContainersForObjects(
      { dispatch, commit, getters },
      payload,
    ) {
      console.log("createOperationContainersForObjects");
      var objects = payload.objects;
      var operationId = payload.operationId;
      var config = payload.config;
      var viewconfig = payload.viewconfig;
      for (var i = 0; i < objects.length; i++) {
        var oldObject = objects[i];
        var payloadOperationId = {
          object: oldObject,
          operationId: operationId,
        };
        var newOperationId =
          getters.getOperationIdForObjectAndOperationId(payloadOperationId);
        var payloadCreate = {
          object: oldObject,
          config: config,
          id: newOperationId,
        };
        dispatch(Constants.CREATE_OPERATION_CONTAINER, payloadCreate);

        var payloadAddOperation = {
          operationId: newOperationId,
          viewconfig: viewconfig,
        };
        commit("addContainerToEditMode", payloadAddOperation);
      }
    },
    async saveOperationsforOperationListId(
      { dispatch, commit, getters },
      payload,
    ) {
      console.log("saveOperationContainersforOperationId777");
      var operationId = payload.operationId;
      var viewconfig = payload.viewconfig;
      var operationsIds = getters.getOperationIdsForConfig(viewconfig);
      for (var i = 0; i < operationsIds.length; i++) {
        var operationId = operationsIds[i];
        console.log("Saving operationidddd");
        await dispatch("saveOperationContainerForId", operationId);
      }
    },

    /*
      Save the object.
      Object + objectType.
    */
    async saveObjectForObjectType({ dispatch, commit, getters }, payload) {
      console.log("saveObjectForObjectType");
      var object = payload.object;
      var oldObject = payload.oldObject;
      var objectType = payload.objectType;
      var config = getters.getObjectConfigForType(objectType);
      var payloadCreate = {
        object: object,
        config: config,
        saveType: payload.saveType,
        oldObject,
      };
      dispatch(Constants.CREATE_OPERATION_CONTAINER, payloadCreate);
      var currentOperationContainer = getters.getCurrentOperationContainer;
      dispatch(Constants.SAVE_OPERATION_CONTAINER, currentOperationContainer);
    },

    /*
      Send all Operations
    */
    async sendOperations({ dispatch, commit, getters }, payload) {
      //can be synchronisation groups.
      console.log("Send Operations....");
      var operationsToSend = getters.getOperationsToSend;
      var result = {};
      if (operationsToSend.length > 0) {
        //Otherwise no sending
        commit(_SET_GENERAL_OPERATION_STATUS, OPERATION_STATUS_SENT);
      }

      for (
        var i = 0;
        i < operationsToSend.length &&
        getters.getGeneralOperationStatus === OPERATION_STATUS_SENT;
        i++
      ) {
        var operationContainer = operationsToSend[i];
        result = await dispatch(
          Constants.SEND_OPERATION_CONTAINER,
          operationContainer,
        );
      }
    },

    async saveResultOperationContainer({ dispatch, commit, getters }, payload) {
      var operationContainer = payload;
      var operationConfig = getters.getObjectConfigForType(Constants.OPERATION);
      var operationPayload = {
        objectConfig: operationConfig,
        object: operationContainer.operation,
      };
      console.log("Save the operation");
      await dispatch(Constants.SAVE_OBJECT, operationPayload);
      // commit(Constants.SET_OBJECT, { payload: operationPayload, getters });
    },
    /**
     * Send OperationContainer
     */

    async sendAllOperations({ dispatch, commit, getters }) {
      console.log("Send all operations");
      var operationsToSend = getters.getOperationsToSend;
      if (operationsToSend.length > 0) {
        //Otherwise no sending
        commit(_SET_GENERAL_OPERATION_STATUS, OPERATION_STATUS_SENT);
      } else return;

      const syncObject = SyncApi.buildRestUpdate(operationsToSend);
      var config = {
        headers: {},
      };
      var bearer = await getAccessToken(getters.getCurrentUser);

      if (UTIL.isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        context.commit("showAlert", {
          type: Constants.ALERT_TYPE_ERROR,
          message: "problem with bearer During Operation.",
        });
        return;
      }

      config.headers.Authorization = "Bearer ".concat(bearer);

      await axios[syncObject.method](syncObject.url, syncObject.body, config)
        .then((response) => {
          // flattening the response
          console.log("Before parsing", response.data);
          // Change the synchronisation state.
          const operations = response.data.result;
          let operationMapped = {};
          const warnings = response.data.warnings;
          if (!UTIL.isEmptyArray(warnings)) {
            for (let index = 0, l = warnings.length; index < l; index++) {
              const warning = warnings[index];
              commit("showAlert", {
                type: Constants.ALERT_TYPE_WARNING,
                message: warning,
              });
            }
          }

          for (let index = 0, l = operations.length; index < l; index++) {
            const { operation } = operations[index];
            operationMapped[operation.Guid] = operation;
          }

          for (let index = 0, l = operationsToSend.length; index < l; index++) {
            const operationContainer = operationsToSend[index];
            const resultOperation =
              operationMapped[operationContainer.operation.Guid];
            if (resultOperation) {
              Vue.set(operationContainer, "operation", resultOperation.$set);
              dispatch("saveResultOperationContainer", operationContainer);
            }
          }
        })
        .catch(function (error) {
          // handle error
          commit(_SET_GENERAL_OPERATION_STATUS, OPERATION_STATUS_FAILED);
          console.log(error);
          commit("setIsUpdating", false);
          if (error && error.message === "Network Error") {
            commit("showAlert", {
              type: "error",
              message:
                "Problem connecting to database. Make sure you have internet connection",
            });
            const operationContainers = operationsToSend;
            for (
              let index = 0, l = operationContainers.length;
              index < l;
              index++
            ) {
              let { operation } = operationContainers[index];
              Vue.set(operation, "status", OPERATION_STATUS_FAILED);
              dispatch(
                "saveResultOperationContainer",
                operationContainers[index],
              );
            }
          }
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            commit("showAlert", {
              type: "error",
              message: error.response.data.message
                ? error.response.data.message
                : error.response.data,
            });
          }
          const operations = error.response.data.result;
          for (let index = 0, l = operations.length; index < l; index++) {
            const { operation } = operations[index];
            dispatch("saveResultOperationContainer", operation);
          }
        })
        .then(function () {
          // always executed
        }); // you can also make a chain.
    },
    async sendAllOperationsSingle({ dispatch, commit, getters }) {
      console.log("Send all operations");
      var operationsToSend = getters.getOperationsToSend;
      if (operationsToSend.length > 0) {
        //Otherwise no sending
        commit(_SET_GENERAL_OPERATION_STATUS, OPERATION_STATUS_SENT);
      } else return;

      for (let index = 0, l = operationsToSend.length; index < l; index++) {
        const opsToSend = [operationsToSend[index]];

        const syncObject = SyncApi.buildRestUpdate(opsToSend);
        var config = {
          headers: {},
        };
        var bearer = await getAccessToken(getters.getCurrentUser);

        if (UTIL.isEmpty(bearer)) {
          var error = "problem with bearer During Operation.";
          context.commit("showAlert", {
            type: Constants.ALERT_TYPE_ERROR,
            message: "problem with bearer During Operation.",
          });
          return;
        }

        config.headers.Authorization = "Bearer ".concat(bearer);

        await axios[syncObject.method](syncObject.url, syncObject.body, config)
          .then((response) => {
            // flattening the response
            console.log("Before parsing", response.data);
            // Change the synchronisation state.
            const operations = response.data.result;
            let operationMapped = {};
            for (let index = 0, l = operations.length; index < l; index++) {
              const { operation } = operations[index];
              operationMapped[operation.Guid] = operation;
            }

            for (let index = 0, l = opsToSend.length; index < l; index++) {
              const operationContainer = opsToSend[index];
              const resultOperation =
                operationMapped[operationContainer.operation.Guid];
              if (resultOperation) {
                Vue.set(operationContainer, "operation", resultOperation.$set);
                dispatch("saveResultOperationContainer", operationContainer);
              }
            }
          })
          .catch(function (error) {
            // handle error
            commit(_SET_GENERAL_OPERATION_STATUS, OPERATION_STATUS_FAILED);
            console.log(error);
            commit("setIsUpdating", false);
            if (error && error.message === "Network Error") {
              commit("showAlert", {
                type: "error",
                message:
                  "Problem connecting to database. Make sure you have internet connection",
              });

              for (let index = 0, l = opsToSend.length; index < l; index++) {
                let { operation } = opsToSend[index];
                Vue.set(operation, "status", OPERATION_STATUS_FAILED);
                dispatch("saveResultOperationContainer", opsToSend[index]);
              }
            }
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              commit("showAlert", {
                type: "error",
                message: error.response.data.message
                  ? error.response.data.message
                  : error.response.data,
              });
            }
            const operations = error.response.data.result;
            for (let index = 0, l = operations.length; index < l; index++) {
              const { operation } = operations[index];
              dispatch("saveResultOperationContainer", operation);
            }
          })
          .then(function () {
            // always executed
          });
      } // you can also make a chain.
    },

    async sendOperationContainer(
      { dispatch, commit, getters },
      operationContainer,
    ) {
      console.log("Send Operation Container");
      var objectConfig = operationContainer.objectConfig;
      var object = operationContainer.newObject;
      commit("logLine", "Send operation:");

      var syncObject = SyncApi.buildRestUpdate(object, objectConfig);

      if (UTIL.isEmpty(syncObject)) {
        var payloadFailureEmpty = {
          operationContainer: operationContainer,
          error: "Problem: Empty body!!",
        };
        console.log("Response error get", payloadFailureEmpty.error);
        await dispatch("saveResultOperationContainer", payloadFailureEmpty);
        return;
      }

      var config = {};

      var result = {};
      operationContainer.operation.body = JSON.stringify(syncObject.body);
      operationContainer.operation.method = syncObject.method;
      operationContainer.operation.url = syncObject.url;
      operationContainer.operation.sendTime = Date.now();

      /**
       * If needed do a get Before the post.
       */
      if (syncObject.getNeeded === true) {
        config = {
          // headers: {
          //   "x-csrf-token": "Fetch",
          //   "Access-Control-Allow-Origin": "*"
          // }
          //withCredentials: true,
          headers: {
            Authorization: "Basic TlBFRVQ6U3FsITIwMTk2",
            "x-csrf-token": "Fetch",
          },
        };

        SyncApi.updateSyncObjectAndHeadersForGet(
          syncObject,
          config,
          objectConfig,
        );
        console.log("Get Update", syncObject.url, syncObject.body, config);
        axios.defaults.withCredentials = true;
        await axios[syncObject.method](syncObject.url, {}, config)
          .then((response) => {
            var payloadsuccess = {
              operationContainer: operationContainer,
              response: response,
            };

            console.log("Response get", response);
            commit("logLine", "response after Get:");
            commit("logLine", response);
            SyncApi.updateSyncObjectAndHeaders(
              syncObject,
              config,
              objectConfig,
              response,
            );
            if (response && response.data && response.data.warning) {
              commit("showAlert", {
                type: "warning",
                message: response.data.message,
              });
            }
            //dispatch("saveResultOperationContainer", payloadsuccess);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            var payloadFailure = {
              operationContainer: operationContainer,
              error: error,
            };
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              commit("showAlert", {
                type: "error",
                message: error.response.data.message
                  ? error.response.data.message
                  : error.response.data,
              });
            }
            console.log("Response error get", error);
            dispatch("saveResultOperationContainer", payloadFailure);
            return;
          })
          .then(function () {
            // always executed
          }); // you can also make a chain.
      }
      console.log("send the post method.", syncObject, config);

      /**
       * Final Post Call;
       */

      if (config.headers === undefined) {
        config.headers = {};
      }

      var bearer = await getAccessToken(getters.getCurrentUser);

      if (UTIL.isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        var payloadFailureBearer = {
          operationContainer: operationContainer,
          error: error,
        };
        dispatch("saveResultOperationContainer", payloadFailureBearer);
      }

      config.headers.Authorization = "Bearer ".concat(bearer);

      await axios[syncObject.method](syncObject.url, syncObject.body, config)
        .then((response) => {
          // flattening the response
          console.log("Before parsing", response);
          // Change the synchronisation state.
          operationContainer.operation.responseTime =
            Date.now() - operationContainer.operation.sendTime;
          var payloadsuccess = {
            operationContainer: operationContainer,
            response: response,
          };
          console.log("Response Post", response);
          dispatch("saveResultOperationContainer", payloadsuccess);
          if (response && response.data && response.data.warning) {
            commit("showAlert", {
              type: "warning",
              message: response.data.message,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          var payloadFailure = {
            operationContainer: operationContainer,
            error: error,
          };
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            commit("showAlert", {
              type: "error",
              message: error.response.data.message
                ? error.response.data.message
                : error.response.data,
            });
          }
          console.log("Response error Post dagaa", error.data);
          console.log("Response error Post", error);
          dispatch("saveResultOperationContainer", payloadFailure);
        })
        .then(function () {
          // always executed
        }); // you can also make a chain.
    },
  },
};
