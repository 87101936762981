<template>
  <div>
    <v-menu
      :rounded="'lg'"
      v-model="showMenu"
      content-class="menu-elevation"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
          slot="activator"
          :class="showMenu ? 'primary--text' : ''"
          large
          icon
          v-on="on"
        >
          <v-avatar
            v-if="getImage"
            class="rounded-xl"
            size="32px"
          >
            <img
              :src="getImage"
              crossorigin="anonymous"
              alt="avatar"
            >
          </v-avatar>
          <v-icon
            color="gray"
            v-else
          >{{ showMenu ? 'hi-user-circle' : 'hi-user-circle-outline' }}</v-icon>

        </v-btn>
      </template>
      <v-list
        dense
        style="max-height: 90vh; overflow-y: auto;"
      >


        <v-list-item>
          <v-list-item-title>
            <v-chip
              dark
              color="primary"
              text-color="white"
              class="ma-1"
            >
              <v-avatar left>
                <v-icon>hi-user-circle</v-icon>
              </v-avatar>
              {{ getCurrentEmployee ? getCurrentEmployee.UserId : '' }}
            </v-chip>
          </v-list-item-title>
        </v-list-item>

        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <v-subheader class="px-4 text-caption text-capitalize">{{ item.title }}</v-subheader>
          <v-list-item
            v-if="item.title === 'Actions'"
            class="justify-center"
          >
            <v-btn-toggle
              v-if="isConfigAdmin"
              :value="getConfigMode"
              color="primary"
              rounded
              dense
              borderless
            >
              <v-btn
                :value="0"
                class="px-3"
                @click="setConfigMode(0)"
              >
                Off
              </v-btn>

              <v-btn
                :value="1"
                @click="setConfigMode(1)"
              >
                Config Mode
              </v-btn>

              <v-btn
                :value="2"
                class="px-3"
                @click="setConfigMode(2)"
              >
                Expert
              </v-btn>
            </v-btn-toggle>
          </v-list-item>
          <v-list-item
            v-for="(subItem, i) in getItems(item.items)"
            :key="i"
            @click="clickMenu(subItem)"
          >

            <v-list-item-icon><v-icon>{{ subItem.icon }}</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="pr-8">{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="subItem.isToggle === true">
              <v-switch
                :input-value="subItem.value"
                value
                small
                inset
              />
            </v-list-item-action>

          </v-list-item>
        </div>
        <div class="kingSize text-caption gray--text py-1 text-center">
          Swiftio v{{ getVersion }}
        </div>

      </v-list>
    </v-menu>
    <v-dialog
      v-model="clearDialog"
      max-width="400"
      content-class="rounded-lg"
    >
      <v-card class="py-2 rounded-lg">
        <v-card-title class="text-h5">
          Are you sure you want to clear your offline database?
        </v-card-title>
        <v-card-text>
          All your local saved changes will be lost
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="clearDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="clearData"
          >
            Clear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isBase64, isEmpty, isValidHttpUrl } from '../../api/utilities';
import { OBJECT_CONFIG } from '../../store/constants';

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    onClickItem: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      showMenu: false,
      clearDialog: false
    }
  },
  computed: {
    ...mapGetters(["getVersion", "getCurrentEmployee", "getRightMenuState", "getFluid", "getConfigMode", "getRightMenuState", "getNumberOfOperationsToSend", "isDark", "getAutoSync", "getBulkUpdate"]),
    items() {
      return [
        {
          title: 'Settings',
          items: [
            { title: "Profile", icon: 'hi-user-outline', action: this.goToAccount },
            // { title: "Wide Screen", icon: 'hi-arrows-pointing-out-outline', action: this.toggleFluid, isToggle: true, value: this.getFluid },
            // { title: "Side Menu", icon: 'hi-arrow-left-on-rectangle-outline', action: this.toggleRightDrawer, isToggle: true, value: this.getRightMenuState },
            { title: "Dark Mode", icon: 'hi-moon-outline', action: this.toggleDarkMode, isToggle: true, value: this.isDark },
            { title: "Auto Sync", icon: 'hi-arrow-path-rounded-square-outline', action: this.toggleAutoSync, isToggle: true, value: this.getAutoSync },
            { title: "Bulk Update", icon: 'hi-arrow-up-on-square-stack-outline', action: this.toggleBulkUpdate, isToggle: true, value: this.getBulkUpdate },
            // { title: "Config Mode", icon: 'hi-wrench-outline', action: this.toggleConfigMode, isToggle: true, value: this.getConfigMode === 1 },
            // { title: "Config Mode Expert", icon: 'hi-wrench-screwdriver-outline', action: this.toggleConfigModeExpert, isToggle: true, value: this.getConfigMode === 2 },
          ],
        },
        {
          title: 'Links',
          items: [
            { title: "Guides", icon: 'hi-book-open-outline', action: this.goToGuides },
            { title: "Operations", icon: 'hi-inbox-stack-outline', action: this.goToOperations },
            { title: "Synchronisations", icon: 'hi-chart-bar-outline', action: this.goToSynchronisations },
          ]
        },
        {
          title: 'Actions',
          items: [
            { title: "Force Synchronize All", icon: 'hi-arrow-path-rounded-square-outline', action: this.forceFullSynchronisation },
            { title: "Cancel Failed Operations", icon: 'hi-trash-outline', action: this.cancelFailedOperations },
            { title: "Clear Offline Data", icon: 'hi-archive-box-x-mark-outline', action: this.clearDataPopup },
            { title: "Update Swiftio", icon: 'hi-arrow-down-circle-outline', action: this.hardReload },
            { title: "Logout", icon: 'hi-arrow-right-on-rectangle-outline', action: this.logoutApp }
          ]
        },

      ]
    },
    isConfigAdmin() {
      return this.$store.getters.isConfigAdmin
    },
    getImage() {

      if (this.getCurrentEmployee && this.getCurrentEmployee.thumbnail) {
        if (isBase64(this.getCurrentEmployee.thumbnail)) {
          return this.getCurrentEmployee.thumbnail
        }
      } else if (this.getCurrentEmployee && this.getCurrentEmployee.Image) {
        if (isValidHttpUrl(this.getCurrentEmployee.Image)) {
          return this.getCurrentEmployee.Image
        }
      }
      return null;
    },
  },

  methods: {
    ...mapActions(["logout", "toggleMini", "toggleLeftDrawer", "toggleRightDrawer", "toggleFluid", "toggleDark", "toggleAutoSync", "toggleBulkUpdate"]),
    toggleDarkMode() {

      this.$vuetify.theme.dark = !this.isDark;
      const color = this.$vuetify.theme.themes[this.$vuetify.theme.dark ? 'dark' : 'light'].tileBackgroundColor
      document.querySelector('meta[name="theme-color"]').setAttribute('content', color);
      this.$store.dispatch('toggleDark')
    },
    cancelFailedOperations() {
      this.$store.dispatch('cancelFailedOperations')
    },
    getItems(items) {
      return items.filter(o => (o.mobileOnly === undefined || (o.mobileOnly === true && this.isMobile)));
    },
    clickMenu(item) {
      item.action();
      if (this.onClickItem) {
        this.onClickItem()
      }
    },
    setConfigMode(mode) {
      this.$store.commit('setConfigMode', mode)
    },
    getValue(item) {
      if (item.title === 'Fluid') {
        return this.getFluid
      }
      if (item.title === 'Config Mode') {
        return this.getConfigMode === 1
      }
      if (item.title === 'Config Mode Expert') {
        return this.getConfigMode === 2
      }

      return false
    },
    forceFullSynchronisation() {
      /***
        * Force full Synchronisation for all objectConfigs
      */
      var objectConfig = this.$store.getters.getObjectConfigForType(OBJECT_CONFIG)
      var payload = {
        action: "forceSynchronizeAllObjectObjects",
        objectConfig: objectConfig,
        object: null,
        viewConfig: this.getViewConfig,
      };
      this.$store.dispatch("launchActionForObjectAndConfig", payload);
    },
    clearDataPopup() {
      this.clearDialog = true
    },
    async clearData() {
      this.clearDialog = false
      await this.$store.dispatch("deleteObjectContext");
      this.logoutApp()
    },
    async logoutApp() {
      this.$store.dispatch("showLoading", { message: 'Logging out', fullScreen: true });
      setTimeout(this.reload, 1000)

      this.logout()
    },
    profile() {
      //TODO
    },
    toggleConfigMode() {
      this.$store.dispatch("toggleConfigMode");
    },
    toggleConfigModeExpert() {
      this.$store.dispatch("toggleConfigModeExpert");
    },
    reload() {
      location.reload()
    },
    hardReload() {

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (let registration of registrations) {
            registration.unregister();
          }
        });
      }
      localStorage.setItem('updating', true)
      location.reload(true)
    },
    toggleLogs() {
      this.dialog = true;
      this.$store.commit("toggleLogs");
    },
    goToThemes() {
      this.$store.dispatch("changeToRoute", "Themes");
    },
    goToAccount() {
      this.$store.dispatch("changeToRoute", "account");
    },
    goToSynchronisations() {
      this.$store.dispatch("changeToRoute", "Synchronisations");
    },
    goToOperations() {
      this.$store.dispatch("changeToRoute", "Operations");
    },
    goToDomains() {
      this.$store.dispatch("changeToRoute", "Domains");
    },
    goToGuides() {
      this.$store.dispatch("changeToRoute", "Guides");
    },

  }
};
</script>
