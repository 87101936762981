<template>

  <div>
    <v-breadcrumbs
      :items="items"
      class="px-4 py-2"
      divider="/"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :key="item.text"
          :href="item.href"
          :disabled="item.disabled"
          class="white px-4 py-2 rounded-lg"
        >
          <v-icon
            v-if="item.icon"
            class="mr-2"
          >{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import * as Constants from "../../store/constants.js";

export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      items: [{
        icon: 'hi-home',
        text: 'home',
        href: 'homeMains',
        disabled: true
      }, {
        text: 'home',
        href: 'homeMains',
        disabled: false
      }]
    }
  },
  computed: {
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
  },
  mounted() {
    this.viewconfig
  },
  methods: {
    displayComponent(type) {
      return this.getSubviewConfig === type;
    }
  }
};
</script>

