<template>
  <div>
    <v-container>
      <v-row class="pt-4">
        <div
          v-for="item in getAttachments"
          :key="item.Guid"
        >
          <img
            :src="item.url"
            :lazy-src="item.thumbnail"
            :eager="true"
            crossorigin="anonymous"
            contain
            style="height:200px; max-width: 100%; aspect-ratio: 1;"
          />
        </div>
      </v-row>
    </v-container>
    <v-btn
      v-if="getNumOfAttachments === 0"
      color="primary"
      dark
      elevation="0"
      @click.stop="openDialog"
    >
      <v-icon>hi-pencil-square-outline</v-icon>Sign
    </v-btn>
  </div>
</template>

<script>
import * as objectApi from "../../api/objectApi"
import { ATTACHMENT, ATTACHMENT_CATEGORY_SIGNATURE, GET_VIEW_CONFIG_FOR_ID, QUOTATION } from "../../store/constants";

export default {

  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
    }
  },
  computed: {
    getViewConfig() {
      return this.$store.getters[GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        ATTACHMENT
      );
    },
    getAttachments() {
      var attachments = this.$store.getters.getDataObjectsForObjectType(ATTACHMENT);
      var guid = this.object.Guid
      var signatures = attachments.filter(function (o) {
        return o.parent === guid && o.parentType === QUOTATION && o.category === ATTACHMENT_CATEGORY_SIGNATURE;
      });
      return signatures
    },
    getNumOfAttachments() {
      if (this.getAttachments && Array.isArray(this.getAttachments)) {
        return this.getAttachments.length
      } else return 0
    }
  },
  methods: {
    openDialog() {
      const viewconfig = this.$store.gett
      this.$store.commit('showSignature', { objectType: QUOTATION, object: this.object })
    },
  }
};
</script>

<style scoped>
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  color: white;
  display: none;
}
</style>

