<template>
  <v-dialog
    v-model="getShowMassDialog"
    max-width="1500px"
    content-class="rounded-lg"
    hide-overlay
    persistent
  >
    <v-card
      v-if="getShowMassDialog"
      color="tileBackgroundColor"
    >
      <v-card
        color="primary darken-1"
        dark
        class="buttonbottom px-2 fixed"
        height="40px"
        style="position:relative; "
      >
        <v-row
          no-gutters
          align="center"
        >
          <v-spacer />
          <v-btn
            icon
            @click.native="closeMassUpdate()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
        </v-row>
      </v-card>
      <v-card
        class="ma-0 pa-3"
        flat
      >
        <v-card
          background-color="blue"
          class="ma-0 pa-3"
          flat
        >
          <viewConfigView
            :viewconfig="getViewConfigForMass"
            :object="filterObject"
            :editmode="true"
            :key="componentKey"
            :operationid="getOperationIdDetails"
            hide-actions
          />
          <v-row
            class="pa-2"
            no-gutters
            align="center"
          >
            <!-- <v-btn
              class="ma-3 pa-0"
              @click.native="simulate()"
            >simulate
            </v-btn> -->
            <!-- <v-text-field
              v-model="searchField"
              append-icon="hi-magnifying-glass"
              label="Search"
              class="text-field-transparent search mr-2"
              dense
              solo
              style="min-width: 270px; margin-top: 0px; padding-top: 0;"
              single-line
              hide-details
            /> -->
            <v-spacer />
            <v-btn
              class="ma-1 py-0 px-8"
              text
              @click.native="clearSimulation()"
            >Cancel
            </v-btn>
            <v-btn
              elevation="0"
              color="primary"
              class="ma-1 py-0 px-8"
              @click.native="saveOperations()"
            >Save
            </v-btn>
          </v-row>

        </v-card>

        <v-card class="ma-0 pa-3 rounded-lg">
          <listView
            :object="object"
            :search="search"
            :operationid="operationId"
            :list="getCurrentObjects"
            :ismass="true"
            :viewconfig="viewconfig"
            :selectable="false"
            height="0"
          />
        </v-card>

      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import * as Utilities from "../../../api/utilities";


export default {
  props: {
    viewconfig: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      filterObject: {},
      operationIdDetails: "operationIdMassUpdateDetails",
      operationId: "operationIdList",
      componentKey: 0,
      showDialog: false,
      list: null,
      object: null,
      search: "",
      simulatedObjects: null,
      isSimulationActive: false,
      searchField: ""
    };
  },
  computed: {
    getOperationIdDetails() {
      var operationContainer = this.$store.getters.getOperationContainerForId(
        this.operationIdDetails
      );
      if (Utilities.isEmpty(operationContainer)) {
        var payload = {
          object: null,
          config: this.getObjectConfig,
          id: this.operationIdDetails
        };
        this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
      }
      return this.operationIdDetails
    },
    getShowMassDialog() {
      return this.$store.getters.isMassUpdateForViewConfig(this.viewconfig);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getObjectType
      );
    },
    getFilterObject() {
      var operationContainer = this.$store.getters.getOperationContainerForId(
        this.operationIdDetails
      );
      return operationContainer.newObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfigForMass() {
      var viewConfig = this.getViewConfig.objectType.concat(
        "ViewDetailsconfig"
      );
      return viewConfig
    },
    getObjects() {
      if (!Utilities.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getCurrentObjects() {
      const selected = this.$store.getters.getSelectedItemsForViewConfig(this.viewconfig)
      if (selected && !Utilities.isEmptyArray(selected)) return selected
      if (this.isSimulationActive === true) {
        return this.filterLists(this.simulatedObjects, this.searchField)
      } else {
        return this.filterLists(this.getFilteredObjects, this.searchField)
      }
    }
  },
  methods: {
    closeMassUpdate() {
      this.$store.dispatch("closeMassUpdateViewDialog", this.viewconfig);
      var payload = {
        object: null,
        config: this.getObjectConfig,
        id: this.operationIdDetails
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
    },
    launchUpdate(objects, isSave) {
      var fields = this.getObjectConfig.fieldsConfig

      for (var i = 0; i < objects.length; i++) {
        var adapted = false
        var object = objects[i]
        const oldObject = Utilities.copyObject(object)
        for (var j = 0; j < fields.length; j++) {
          var field = fields[j]
          if (!Utilities.isEmpty(this.getFilterObject[field.field]) && field.field !== "syncId" && field.field !== "Guid" && field.field !== "_id") {
            if (object[field.field] !== this.getFilterObject[field.field]) {
              object[field.field] = this.getFilterObject[field.field]
              adapted = true
            }
          }
        }
        if (isSave === true && adapted === true) {
          var payload = {
            objectType: this.getObjectType,
            object: object,
            oldObject
          };
          this.$store.dispatch("saveObjectForObjectType", payload);
        }
      }
    },
    filterLists(list, searchField) {
      if (this.searchField.length < 3) {
        return list
      }
      var fields = this.getObjectConfig.fieldsConfig
      var filteredList = list.filter(function (o) {
        for (var j = 0; j < fields.length; j++) {
          var field = fields[j]
          if (!Utilities.isEmpty(o[field.field]) && (typeof o[field.field] === 'string' || o[field.field] instanceof String) && o[field.field].includes(searchField)) {
            return true
          }
        }
        return false;
      })
      return filteredList
    },
    simulate() {
      this.simulatedObjects = JSON.parse(JSON.stringify(this.getFilteredObjects));
      this.isSimulationActive = true;
      this.launchUpdate(this.getCurrentObjects, false)

      //operatie op uitvoeren.
    },
    clearSimulation() {
      this.isSimulationActive = false;
      this.simulatedObjects = null
      this.closeMassUpdate()
    },
    saveOperations() {
      this.isSimulationActive = false;
      this.launchUpdate(this.getCurrentObjects, true)
      this.clearSimulation()
      this.$store.commit('setSelectedItemsForViewConfig', { viewconfig: this.viewconfig, items: [] })
      this.closeMassUpdate()
    }
  }
};
</script>

