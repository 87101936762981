import DBHelperClass from "../core/DBHelper.js";
import * as Api from "../api/objectApi";
import * as Utilities from "../api/utilities";

/**
 * Database mutators
 */
export const SET_DATABASE = "setDatabase";
export const SET_DB_STATE = "setDbState";
export const SET_OBJECT_CONFIGS = "setDbState";

/**
  Database State
*/
export const DB_STATE_INIT = 0;
export const DB_STATE_START = 1;
export const DB_STATE_OPENED = 2;
export const DB_STATE_OBJECT_TABLE_CREATED = 3;
export const DB_STATE_FIELD_TABLE_CREATED = 4;
export const DB_STATE_DATA_TABLES_CREATED = 5;
export const DB_STATE_DATA_TABLES_DELETED = 6;

/**
 * Private Actions
 */
export const _CREATE_FIELD_TABLE = "_createFieldTable";
export const _CREATE_OBJECT_TABLE = "_createObjectTable";
export const _CREATE_DATA_TABLE = "_createDataTable";
export const _CREATE_DATA_TABLE_HISTORY = "_createDataTableHistory";
export const _DELETE_FIELD_TABLE = "_deleteFieldTable";
export const _DELETE_OBJECT_TABLE = "_deleteObjectTable";
export const _DELETE_DATA_TABLE = "_deleteDataTable";
export const _DELETE_DATA_TABLE_HISTORY = "_deleteDataTableHistory";

export default {
  state: { database: null, dbState: 0 },
  mutations: {
    setDatabase(state, database) {
      state.database = database;
    },
    setDbState(state, newDbState) {
      state.dbState = newDbState;
    }
  },
  getters: {
    getDbState(state) {
      return state.dbState;
    },
    getDbError(state) {
      var error = "Error: ".concat(state.dbState);
      return error;
    },
    getDatabase(state) {
      return state.database;
    }
  },
  actions: {
    /*********************************************
     *  Open Database
     **********************************************/

    /**
     * Open database
     * @param {} param0
     */
    async openDatabase({ dispatch, commit, getters }) {
      console.log(getters.getCurrentUser);
      var dbName = getters.getCurrentUser.uid;
      var dbHelper = new DBHelperClass(dbName);
      var newState = 1;
      var deviceType = getters.getDeviceType;
      console.log("Device Type ", deviceType);
      commit(SET_DATABASE, dbHelper);
      await dbHelper.openDatabase(deviceType).then(
        response => {
          console.log("Database Opened");
          commit(SET_DB_STATE, DB_STATE_OPENED);
          return;
        },
        error => {
          commit(SET_DB_STATE, DB_STATE_OPENED * -1);
          console.log("error", error);
        }
      );
    },

    /*********************************************
     *  Create Tables
     **********************************************/

    /**
     * Create the core tables: Object table and fields table.
     * @param {*} param0
     */
    async createCoreTables({ dispatch, commit, getters }) {
      await dispatch(_CREATE_OBJECT_TABLE);
      await dispatch(_CREATE_FIELD_TABLE);
    },
    /**
     * Create the object table
     * @param {*} param0
     */
    async _createObjectTable({ dispatch, commit, getters }) {
      await getters.getObjectConfig.createTable(getters.getDatabase).then(
        result => {
          console.log("TableCreated");
          commit(SET_DB_STATE, DB_STATE_OBJECT_TABLE_CREATED);
        },
        error => {
          commit(SET_DB_STATE, OBJECT_CONF_STATE_OBJECTS_TABLE * -1);
          console.log("Error: ", error);
        }
      );
    },

    /**
     * Create the field Table
     * @param {} param0
     */
    async _createFieldTable({ dispatch, commit, getters }) {
      await getters.getFieldConfig.createTable(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_FIELD_TABLE_CREATED);
        },
        error => {
          commit(SET_DB_STATE, OBJECT_CONF_STATE_FIELDS_TABLE * -1);
          console.log("Error: ", error);
        }
      );
    },

    /**
     * Create the field Table
     * @param {} param0
     */
    async createDataTables({ dispatch, commit, getters }) {
      for (var i = 0; i < getters.getObjectConfigs.length; i++) {
        var objectConfig = getters.getObjectConfigs[i];
        if (objectConfig.fieldsConfig.length > 1){
          await dispatch(_CREATE_DATA_TABLE, objectConfig);
          await dispatch(_CREATE_DATA_TABLE_HISTORY, objectConfig);
        }
      }
      commit(SET_DB_STATE, DB_STATE_DATA_TABLES_CREATED);
    },

    async _createDataTable({ dispatch, commit, getters }, objectConfig) {
      if (Utilities.isTrue(objectConfig.objectConfig.skipTableCreate)) {
        console.log("TableCreation skipped");
        return;
      } else {
        await objectConfig.createTable(getters.getDatabase).then(
          result => {
            commit(SET_DB_STATE, DB_STATE_DATA_TABLES_CREATED);
          },
          error => {
            commit(SET_DB_STATE, DB_STATE_DATA_TABLES_CREATED * -1);
            console.log("Error: ", error);
          }
        );
      }
    },
    async _createDataTableHistory({ dispatch, commit, getters }, objectConfig) {
      if (Utilities.isTrue(objectConfig.objectConfig.skipTableCreate)) {
        return;
      }
      await objectConfig.createTableHistory(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_CREATED);
        },
        error => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_CREATED * -1);
          console.log("Error: ", error);
        }
      );
    },

    /*********************************************
     * Delete Tables
     **********************************************/

    async deleteDataTables({ dispatch, commit, getters }) {
      for (var i = 0; i < getters.getObjectConfigs.length; i++) {
        var objectConfig = getters.getObjectConfigs[i];
        await dispatch(_DELETE_DATA_TABLE, objectConfig);
        await dispatch(_DELETE_DATA_TABLE_HISTORY, objectConfig);
      }
    },

    async _deleteDataTable({ dispatch, commit, getters }, objectConfig) {
      await objectConfig.deleteTable(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_DELETED);
        },
        error => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_DELETED * -1);
          console.log("Error: ", error);
        }
      );
    },
    async _deleteDataTableHistory({ dispatch, commit, getters }, objectConfig) {
      await objectConfig.deleteTableHistory(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_DELETED);
        },
        error => {
          commit(SET_DB_STATE, DB_STATE_DATA_TABLES_DELETED * -1);
          console.log("Error: ", error);
        }
      );
    },
    /**
     * Create the core tables: Object table and fields table.
     * @param {*} param0
     */
    async deleteCoreTables({ dispatch, commit, getters }) {
      await dispatch(_DELETE_OBJECT_TABLE);
      await dispatch(_DELETE_FIELD_TABLE);
    },

    /**
     * delete the object table
     * @param {*} param0
     */
    async _deleteObjectTable({ dispatch, commit, getters }) {
      await getters.getObjectConfig.deleteTable(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_OBJECT_TABLE_CREATED);
        },
        error => {
          commit(SET_DB_STATE, OBJECT_CONF_STATE_OBJECTS_TABLE * -1);
          console.log("Error: ", error);
        }
      );
    },

    /**
     * Delete the field Table
     * @param {} param0
     */
    async _deleteFieldTable({ dispatch, commit, getters }) {
      await getters.getFieldConfig.deleteTable(getters.getDatabase).then(
        result => {
          commit(SET_DB_STATE, DB_STATE_FIELD_TABLE_CREATED);
        },
        error => {
          commit(SET_DB_STATE, OBJECT_CONF_STATE_FIELDS_TABLE * -1);
          console.log("Error: ", error);
        }
      );
    }
  }
};
