<template>
  <div class="kingSize">
    <v-card
      flat
      color="transparent"
    >
      <v-avatar
        class="elevation-2"
        style="width: 100%; height:100%; aspect-ratio:1; border-radius:10px;"
        @click="openImagePreviewModel"
      >
        <img
          v-if="getAttachmentType === 'application/pdf'"
          :src="pdfIcon"
          alt="pdf-icon"
          class="pa-2"
        >
        <img
          v-else-if="getAttachmentType === 'application/zip'"
          :src="zipIcon"
          alt="zip-icon"
          class="pa-2"
        >

        <img
          v-else
          :src="getAttachment"
          crossorigin="anonymous"
          class="avatarImg"
          style="align:center;"
          alt="Avatar"
        >
      </v-avatar>
    </v-card>
    <v-dialog
      v-model="imagePreviewModal"
      content-class="rounded-lg"
    >
      <v-card
        v-if="getAttachmentType === 'application/pdf'"
        style="height:90vh;"
      >
        <div class="iframe-loader">
          <v-progress-circular
            indeterminate
            color="primary"
          />
        </div>
        <iframe
          v-if="pdfDataUrl"
          :src="`${pdfDataUrl}`"
          height="100%"
          width="100%"
          style="border:none; position: relative; z-index: 1;"
        />
      </v-card>
      <v-card
        v-else
        flat
        color="black"
        class="d-flex"
        style="height:90vh;"
      >
        <img
          :src="getAttachment"
          crossorigin="anonymous"
          style="max-height:90vh; width: 100%; object-fit: contain;"
        >

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import pdfIcon from "../../assets/Icons/types/pdf.svg"
import JpgIcon from "../../assets/Icons/types/jpg.svg"
import zipIcon from "../../assets/Icons/types/zip.svg"
import { isBlob, isEmpty, isValidHttpUrl } from '../../api/utilities';
import Axios from 'axios';

export default {
  props: {
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      imagePreviewModal: false,
      pdfIcon,
      JpgIcon,
      zipIcon,
      pdfDataUrl: null
    };
  },
  computed: {
    getAttachment() {
      if (!isEmpty(this.object.url)) {
        return this.object.url
      }
      return this.object.file
    },
    getAttachmentType() {
      if (!isEmpty(this.object.mimeType)) {
        return this.object.mimeType
      }
      return this.object.attachmentType
    }
  },
  methods: {
    openImagePreviewModel() {
      console.log(this.object)
      // if (this.getAttachmentType === 'application/zip') {
      //   window.open(this.object.url, "_blank");
      //   return
      // }
      // this.imagePreviewModal = true
      // this.getPdf()
    },
    async getPdf() {
      const pdfUrl = this.object.url
      if (pdfUrl) {

        const response = await Axios.get(pdfUrl, {
          responseType: 'blob',
        })
        if (response && response.data) {
          this.pdfDataUrl = URL.createObjectURL(response.data)
        }

      }
    },
  }
};
</script>

<style></style>

