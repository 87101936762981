<template>
  <div ref="outerListContainerCard">
    <resize-observer @notify="onResizeComponent" />
    <CheckListItemTile
      v-if="displayComponent('CheckListItemTile')"
      :object="object"
      :is-compact-view="isCompactView"
    />
  </div>
</template>

<script>
import CheckListItemTile from "./CheckListItemTile.vue";
import * as Constants from "../../../store/constants.js";

export default {
  components: { CheckListItemTile },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      myListWidth: 0,
    };
  },
  computed: {
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD - 80) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    onResizeComponent() {
      var container = this.$refs.outerListContainerCard;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    displayComponent(type) {
      return this.getSubviewConfig === type;
    }
  }
};
</script>

