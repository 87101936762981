<template>
  <v-card
    class="mx-0 pa-0 kingSize transparent"
    flat
    :class="container && 'ma-4'"
  >
    <label v-show="showHeader"> {{ header.text }}</label>
    <v-card
      class="transparent readOnly"
      v-if="isReadOnly"
      flat
    >
      <vue-editor
        v-model="object[header.value]"
        :editor-toolbar="emptyToolBar"
        :disabled="true"
        :style="getStyle"
      />
    </v-card>

    <div v-else>
      <div v-if="isNotList">
        <v-card
          class="transparent editMode"
          v-if="!isReadOnly"
          flat
        >
          <vue-editor
            v-model="object[header.value]"
            :editor-toolbar="toolbar"
            :placeholder="label"
            :disabled="false"
            :style="getStyle"
          />

        </v-card>
      </div>
      <div v-else>
        <v-text-field
          v-model="object[header.value]"
          :label="label"
          :readonly="readonly"
          :dense="dense"
          :solo="solo"
          :class="className"
          hide-details
          filled
          style="min-width: 250px;"
          @change="change"
        />
        <v-btn
          v-if="!readonly"
          class="absolute right top iconButton"
          style="right:2px; top:2px;"
          icon
          @click.native="openDialog"
        >
          <v-icon>hi-pencil-square-outline</v-icon>
        </v-btn>
        <v-dialog
          v-model="dialog"
          fullscreen
          content-class="card"
        >
          <card style="height: 100%;">
            <v-container fluid>
              <v-row
                no-gutters
                class="px-2"
              >
                <v-toolbar-title class="font-weight-mediumBold">Edit</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="iconButton"
                  elevation="0"
                  @click="cancel"
                  class="px-8  mr-2"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  elevation="0"
                  class="px-8 "
                  @click="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-container>
            <vue-editor
              v-model="object[header.value]"
              :editor-toolbar="toolbar"
              :placeholder="label"
              :disabled="false"
              style="height: 100%;"
            />
          </card>
        </v-dialog>

      </div>
    </div>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: String,
      default: "300px"
    },
    readonly: {
      type: Boolean,
      default: true
    },
    container: {
      type: Boolean,
      default: false
    },
    isNotList: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    solo: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialog: false,
      oldValue: '',
      content: "<h1>Some initial content</h1>",
      toolbar:
        [
          // [{ 'font': [] }],
          [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
          // [{ 'header': 1 }, { 'header': 2 }],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          // [{ 'color': [] }, { 'background': [] }],
          ['link'],
          // [{ 'direction': 'rtl' }],
          // ['clean'],
        ]
    };
  },
  computed: {

    emptyToolBar() {
      return [[], [], []];
    },
    isReadOnly() {
      return this.readonly;
    },
    showHeader() {
      return (
        this.header.text === null ||
        this.header.text === undefined ||
        this.header.text === ""
      );
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height - 20;
      }
      return 300;
    },
    getStyle() {
      return "height: ".concat(this.getHeight.toString().concat("px; "));
    },
    getId() {
      var id = "editor" + this.getHeight;
      return id;
    }
  },
  methods: {
    openDialog() {
      this.oldValue = structuredClone(this.object[this.header.value])
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    cancel() {
      this.object[this.header.value] = this.oldValue
      this.closeDialog()
    },
    save() {

      this.closeDialog()
    },
  }
};
</script>

<style>
.ql-snow {
  padding-top: 4px !important;
}

.ql-container {
  height: 95% !important;
  min-height: 150px;
}

.greenColorBack {
  background-color: green !important;
}
</style>
