<template>
  <v-container
    fluid
    flat
    class="ma-0 pa-0 px-4"
  >
    <v-card
      class="transparent"
      flat
    >

      <Bar
        v-if="getChartType === 'bar' || getChartType === 'barDate'"
        id="my-chart-id"
        :style="{ height: getHeight, width: '100%' }"
        :options="getOptions"
        :data="getChartData"
      />
      <Doughnut
        v-if="getChartType === 'donut'"
        id="my-chart-id"
        :style="{ height: getHeight, width: '100%' }"
        :options="getOptions"
        :data="getChartData"
      />
      <LineChartGenerator
        v-if="getChartType === 'line' || getChartType === 'lineDate'"
        id="my-chart-id"
        :style="{ height: getHeight, width: '100%' }"
        :options="getOptions"
        :data="getChartData"
      />

    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as theme from "../../theme/themeDefault.js";
import { Bar, Doughnut, Line as LineChartGenerator } from 'vue-chartjs'
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS, Title, Tooltip, Legend, BarElement, LinearScale, TimeScale, ArcElement, CategoryScale,
  PointElement, LineElement
} from 'chart.js'
import { addIntToPixelString, isEmpty, isEmptyArray } from '../../api/utilities.js';
import moment from 'moment';

ChartJS.register(Title, Tooltip, Legend, BarElement, LinearScale, ArcElement, CategoryScale,
  PointElement, LineElement, TimeScale)


export default {
  components: { Bar, Doughnut, LineChartGenerator },
  props: {
    height: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: ""
    },
    viewconfig: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      labels: [],
    };
  },
  computed: {
    getOptions() {

      let options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {

            grid: {
              display: false
            },
            border: {
              display: false,
            },
            ticks: {
              display: this.getChartType !== 'donut',
              maxRotation: 0, // Disable rotation
              maxLines: 2,

              // Allow up to 2 lines for label text
            },
          },
          y: {
            ticks: {
              display: this.getChartType !== 'donut',
            },
            grid: {
              display: this.getChartType !== 'donut',
              color: this.currentTheme.line || 'gray'
              // tickBorderDashOffset: 1
            },
            border: {
              display: false,
              dash: [4, 5],
            }
          }
        },
        plugins: {
          tooltip: {
            backgroundColor: this.currentTheme.sideBarColor || 'black',
            titleColor: this.currentTheme.primaryText || 'white',
            bodyColor: this.currentTheme.primaryText || 'white',
            cornerRadius: 10,
            boxPadding: 2,
            // borderColor: this.currentTheme.line || 'gray',
            // borderWidth: 1,
            padding: 12,
            caretSize: 0
          }
        }
      }
      if (this.isDateChart) {
        options.scales.x.type = 'time'
        options.scales.x.time = {
          unit: "day",
        }
        options.interaction = {
          intersect: false,
          mode: 'x',

        }
        options.plugins.tooltip.callbacks = {
          title: (val, index) => {
            const value = val && val[0]
            if (value && value.parsed && value.parsed.x) {
              return moment(value.parsed.x).format('MMM DD YYYY')
            }
            return value.label
          },
          label: function (context) {
            return context.parsed.y;
          }

        }
      }
      if (this.getChartType === 'bar') {
        options.scales.x.ticks.callback = (val, index) => {
          const l = this.labels.length / 1.2
          const max = Number(50 / l)

          return this.labels[index].slice(0, max)
        };
      }
      if (isEmpty(this.colorField) && this.getChartType !== 'donut') {
        options.plugins.legend = false
        if (options.plugins.tooltip.callbacks) {
          options.plugins.tooltip.callbacks.label = function (context) {
            return context.parsed.y;
          }
        } else {
          options.plugins.tooltip.callbacks = {
            label: function (context) {
              return context.parsed.y;
            }
          }
        }

      }
      return options
    },
    isDateChart() {
      return this.getChartType === 'lineDate' || this.getChartType === 'barDate'
    },
    getChartId() {
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjects() {
      return this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
    },
    getChartData() {
      const options = {}
      if (this.getChartType === 'lineDate') {
        options.stepped = true
        options.pointRadius = 5,
          options.pointHoverRadius = 10
      }
      if (this.getChartType === 'lineDate' || this.getChartType === 'line') {
        options.borderColor = this.currentTheme.primary
      }
      var data = this.getData(this.getFilteredObjects, this.xField, this.yField, this.colorField, options)
      this.labels = data.labels
      return data
    },
    getColorValues() {
      if (isEmpty(this.colorField)) {
        return null
      }
      let uniqueValues = {}
      let values = []
      for (let index = 0, l = this.getFilteredObjects.length; index < l; index++) {
        const value = this.getFilteredObjects[index][this.colorField];
        if (!uniqueValues[value]) {
          values.push(value)
        }
      }
      return values
    },
    getCalculationType() {
      return this.getYFields && this.getYFields[0] && this.getYFields[0].calculationTypeY;
    },
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    getFilteredObjects() {
      var payload = {
        objects: this.getObjects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      if (isEmpty(filteredObject)) {
        filteredObject = []
      }
      if (!isEmpty(this.getXFields[0])) {
        var xField = this.getXFields[0];
        filteredObject = filteredObject.sort(function (a, b) {
          if (a[xField.field] < b[xField.field]) {
            return -1;
          }
          if (a[xField.field] > b[xField.field]) {
            return 1;
          }
          return 0;
        });
      }

      return filteredObject;
    },
    getHeight() {
      if (
        this.height === "NaNpx" ||
        this.height === undefined ||
        this.height === "-100px" ||
        this.height === "px"
      ) {
        return "400px";
      }
      return addIntToPixelString(this.height, -30);
    },

    getChartType() {
      var xfields = this.getXFields;
      if (xfields !== undefined && xfields.length > 0) {
        var calculationTypeX = xfields[0].calculationTypeX;
        if (calculationTypeX) {
          return calculationTypeX
        }
      }
      return "bar";
    },
    xField() {
      return this.getXFields && this.getXFields[0] && this.getXFields[0].field;
    },
    yField() {
      return this.getYFields && this.getYFields[0] && this.getYFields[0].field;
    },
    colorField() {
      return this.getColorFields && this.getColorFields[0] && this.getColorFields[0].field;
    },
    getXFields() {
      return this.getFieldsForType(Constants.CHART_X);
    },
    getYFields() {
      return this.getFieldsForType(Constants.CHART_Y);
    },
    getColorFields() {
      return this.getFieldsForType(Constants.CHART_C);
    },
    currentTheme() {
      return theme.getCurrentTheme(this.$vuetify.theme.isDark)
    }
  },
  mounted: function () {
    //setTimeout(this.showChart, 50);
    /** filter */

  },
  methods: {
    getFieldsForType(type) {
      var payload = {
        type: type,
        chartId: this.getChartId
      };
      return this.$store.getters.getChartFieldsForChartIdAndType(payload);
    },
    getData(objects, xField, yField, cField, options = {}) {
      let data = {}
      let colorValues = {}
      for (let index = 0, l = objects.length; index < l; index++) {
        const object = objects[index];
        var label = object[xField] || 'empty'
        var colorField = cField && object[cField]
        colorValues[colorField] = colorField || null
        if (!isEmpty(data[label])) {
          if (isEmpty(cField)) {
            data[label] = this.getCalcValue(data[label], object[yField])
          } else {
            data[label][colorField] = this.getCalcValue(data[label][colorField], object[yField])
          }
        } else if (!isEmpty(cField)) {
          data[label] = {
            [colorField]: this.getCalcValue(null, object[yField])
          }

        } else {
          data[label] = this.getCalcValue(null, object[yField])
        }
      }

      var objectType = this.getObjectType
      var objectConfig = this.$store.getters.getObjectConfigForType(objectType);
      var fields = objectConfig.fieldsConfig
      const labels = Object.keys(data)
      let labelDescr = this.getDescriptionForCategories(structuredClone(labels), xField, fields, objectType)

      const colorvalues = Object.keys(colorValues)
      const colorObjs = this.getColorForCategories(isEmpty(cField) ? xField : cField, fields, objectType)
      let dataSets = {
        labels: labelDescr,
        datasets: []
      }
      for (let index = 0, l = colorvalues.length; index < l; index++) {
        const colorValue = colorvalues[index];
        let dataSetData = []
        for (let index = 0, l = labels.length; index < l; index++) {
          var label = labels[index]
          const valueObj = colorValue ? data[label][colorValue] : data[label];
          if (valueObj) {
            dataSetData.push(this.getFinalValue(valueObj))
          } else {
            dataSetData.push(0)
          }
        }
        const colorObj = colorObjs && colorObjs[colorValue]
        let color = (colorObj && colorObj.color) && this.currentTheme[colorObj.color] || this.currentTheme.primary
        const descr = colorObj && colorObj.descr

        if (isEmpty(cField) && !isEmpty(colorObjs)) {
          color = labels.map(item => {

            const col = colorObjs && colorObjs[item] && colorObjs[item].color
            return this.currentTheme[col || 'primary']
          })
        }

        let dataSet = {
          backgroundColor: color,
          data: dataSetData
        }
        dataSet.label = descr || colorValue || xField
        dataSet.borderRadius = 7 / l;
        dataSet.borderColor = this.currentTheme.card
        Object.assign(dataSet, options)
        dataSets.datasets.push(dataSet)
      }

      return dataSets

    },
    shorten(labels) {
      const l = labels.length / 1.2
      const max = Number(50 / l)
      return labels.map(label => label.slice(0, max))
    },
    getDescriptionForCategories(categories, xfield, fields, objectType) {

      var field = fields.find(function (o) {
        return o.objectType === objectType && o.field === xfield
      })

      if (isEmpty(field)) {
        return categories
      }

      if (!isEmpty(field.dropdown)) {
        return this.getDescriptionForDropdown(categories, field)
      }

      if (!isEmpty(field.relatedObjectType)) {
        return this.getDescriptionForObject(categories, field)
      }
      return categories
    },
    getDescriptionForDropdown(categories, field) {
      var list = this.$store.getters.getValueListForId(field.dropdown);
      if (isEmpty(list)) {
        return categories
      }
      for (var i = 0; i < categories.length; i++) {
        var value = categories[i]
        var listvalue = list.find(function (o) {
          return o.value === value;
        });
        if (!isEmpty(listvalue)) {

          categories[i] = listvalue.descr
        }
      }
      return categories
    },

    getDescriptionForObject(categories, field) {
      for (var i = 0; i < categories.length; i++) {
        var category = categories[i]
        var payload = {
          objectType: field.relatedObjectType,
          objectId: category
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (!isEmpty(relatedObject)) {
          if (!isEmpty(relatedObject.FullName)) {
            categories[i] = relatedObject.FullName
          } else if (!isEmpty(relatedObject.Name)) {
            categories[i] = relatedObject.Name
          } else if (!isEmpty(relatedObject.Description)) {
            categories[i] = relatedObject.Description
          }
        }
      }
      return categories;
    },
    getColorForCategories(xfield, fields, objectType) {
      if (isEmpty(xfield)) {
        return null
      }

      var field = fields.find(function (o) {
        return o.objectType === objectType && o.field === xfield
      })
      if (isEmpty(field)) {
        return null
      }
      if (isEmpty(field.dropdown)) {
        return null
      }
      var list = this.$store.getters.getValueListForId(field.dropdown);
      if (isEmptyArray(list)) {
        return null
      }
      var values = {}
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        values[item.value] = item
      }
      return values
    },
    getFinalValue(valueObj) {
      let type = this.getCalculationType && this.getCalculationType.toLowerCase()
      if (type === 'mean') {
        return valueObj.total / valueObj.count
      }

      return valueObj[type]
    },
    getCalcValue(singleData, currentValue) {
      let total = this.getvalue(currentValue)

      let count = 1
      if (singleData) {
        if (singleData.total) {
          singleData.total += total
        } else {
          singleData.total = total
        }
        if (singleData.count) {
          singleData.count += count
        } else {
          singleData.count = count
        }

      } else {
        singleData = {
          total,
          count
        }
      }
      return singleData
    },
    getvalue(value) {
      if (this.isNumber(value)) {
        return parseFloat(value)
      }
      return 0
    },
    isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
  }
};
</script>

<style>
.CRMlightColor {
  opacity: 0.6;
}
</style>
