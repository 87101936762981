<template>
  <v-sheet
    color="tileBackgroundColor"
    fluid
    width="100%"
  >
    <svg
      id="Group_325"
      data-name="Group 325"
      xmlns="http://www.w3.org/2000/svg"
      width="1803.264"
      height="1983.629"
      viewBox="0 0 1803.264 1983.629"
    >
      <path
        id="Path_815"
        data-name="Path 815"
        d="M2.872,819.077c68.452-160.966,73.52-210.852,185.71-360.2S577.09,177.06,651.9,1.9c0,0-282.439-32.558-444.7,171.736S-14.355,627.96,2.872,819.077Z"
        transform="matrix(-0.921, -0.391, 0.391, -0.921, 1252.256, 1133.261)"
        fill="#ed7d2b"
      />
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M-11072.337-2783.112h0c7.051-37.082,17.833-75.821,32.964-118.431,13.207-37.2,28.068-72.656,41.18-103.942l.017-.039,0-.006c21.672-51.711,40.388-96.37,44.919-131.532,2.392-18.559.708-33.459-5.147-45.553a53.534,53.534,0,0,0-5.555-9.052,56.784,56.784,0,0,0-7.52-8.076,68.509,68.509,0,0,0-9.612-7.077,90.621,90.621,0,0,0-11.832-6.058,77.6,77.6,0,0,0-17.889-4.979,135.137,135.137,0,0,0-21.567-1.589c-13.151,0-28.44,1.5-45.441,4.455-15.895,2.764-33.681,6.869-52.865,12.2-34.205,9.508-73.879,23.188-117.922,40.661-80.619,31.984-160.89,70.656-214.031,97.467,12.251-6.424,24.724-13,37.071-19.542,40.625-20.577,80.589-40.092,118.781-58,48.78-22.878,94.5-43.048,135.874-59.95,107.063-43.733,185.542-65.908,233.256-65.908a116.869,116.869,0,0,1,18.474,1.342,65.08,65.08,0,0,1,15.084,4.175,133.475,133.475,0,0,1,20.438,10.8,102.756,102.756,0,0,1,16.107,12.894,89.042,89.042,0,0,1,12.1,14.811,89.118,89.118,0,0,1,8.407,16.55,105.709,105.709,0,0,1,6.06,24.742,148.439,148.439,0,0,1,.723,27.143,222.421,222.421,0,0,1-3.83,29.107c-1.868,9.57-4.425,19.877-7.6,30.635-5.56,18.841-13.2,39.812-23.367,64.111-9.246,22.1-19.413,44.227-29.035,64.994.328-.189.659-.377.989-.566l.79-.45c-17.9,11.95-34.878,24.447-50.459,37.147a530.759,530.759,0,0,0-47,43.076c-7.507,7.8-14.686,15.809-21.338,23.8-6.909,8.3-13.448,16.823-19.437,25.332-6.205,8.816-11.989,17.86-17.191,26.879C-11063.123-2802.216-11068.033-2792.65-11072.337-2783.112Zm-402.737-275.041v0l1.219-.637Zm1.22-.637v0c3.719-1.944,7.479-3.913,11.178-5.853l.02-.01.019-.009C-11466.3-3062.74-11470.076-3060.765-11473.854-3058.79Z"
        transform="translate(11524.804 3568.337)"
        fill="#ed7d2b"
      />
      <path
        id="Path_817"
        data-name="Path 817"
        d="M1984.46,1722.31s223.728-114.645,328.971-180.517,127.627-286.21,317.688-266.61,221.32,180.316,197.616,405.776c0,0-121.672,48.644-223.57,106.949,54.434-117.492,111.9-248.035,0-295.786S1984.46,1722.31,1984.46,1722.31Z"
        transform="translate(-1984.46 -1186.336)"
        fill="#233862"
        opacity="1"
      />
      <path
        id="Path_818"
        data-name="Path 818"
        d="M2842.863,2278.033s-666.447-177.878-874.845-348-89.042-382.7,13.526-500.761c-23.006,122.644-128.012,238.4,127.8,439.206S2842.863,2278.033,2842.863,2278.033Z"
        transform="translate(-1039.599 -307.717)"
        fill="#233862"
      />
      <path
        id="Path_819"
        data-name="Path 819"
        d="M3198.177,3242.421s-637.362-128.184-973.462-379.369-377.272-357.234-370.934-625.377,250.434-400.546,464.791-457.987,394.961,29.436,611.5-520.9c-1.121,584.453-205.838,761.85-474.019,978.883s-393.295,465.908-137.48,666.719S3198.177,3242.421,3198.177,3242.421Z"
        transform="translate(-1416.191 -1258.792)"
        fill="#699dee"
      />
    </svg>
  </v-sheet>
</template>

<script>
export default {};
</script>
