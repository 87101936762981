import "../extensions/ArrayExtension.js";
import * as UTIL from "../api/utilities";
import { insertSQL, updateSQL } from "./DBUtils.js";
import { execBulk, execQuery, openDatabase, toJSON } from "./DBWebWorker.js";
/***************************************************************************
       SynchronisationObject
****************************************************************************/
export default class DBHelper {
  /************************************************
      Constructor
  *************************************************/
  constructor(databaseName) {
    this.dbName = databaseName;
    this.dataBase = null;
    this.transaction = null;
  }

  /************************************************
      Open Database
  *************************************************/
  openDatabaseBrowser() {
    var _this = this;
    return new Promise(async function(resolve, reject) {
      await openDatabase(_this.dbName);
      _this.transaction = resolve();
    });
  }

  openDatabaseBrowser2() {
    var _this = this;
    return new Promise(function(resolve, reject) {
      if (!window.openDatabase) {
        reject("Error opening database");
      } else {
        var shortName = _this.dbName;
        var version = "1.0";
        var displayName = _this.dbName;
        var maxSize = 2 * 1024 * 1024; // in bytes
        _this.dataBase = window.openDatabase(
          shortName,
          version,
          displayName,
          maxSize
        );
        resolve();
      }
    });
  }

  openDatabaseCordova2() {
    var _this = this;
    return new Promise(function(resolve, reject) {
      // _this.dataBase = window.sqlitePlugin.openDatabase(
      //   _this.dbName,
      //   "1.0",
      //   "",
      //   1
      // );
      if (!window.sqlitePlugin) {
        reject("Error opening database");
      } else {
        _this.dataBase = window.sqlitePlugin.openDatabase({
          name: _this.dbName,
          location: "default"
        });
        resolve();
      }
    });
  }

  openDataBaseCordova(devType) {
    var location;
    if (devType === "windows") {
      location = "default";
    } else {
      location = 2;
    }
    return new Promise(function(resolve, reject) {
      var _this = this;
      _this.dataBase = window.sqlitePlugin.openDatabase(
        { name: _this.dbName, location: location },
        function() {
          resolve("Database opened");
        },
        function() {
          reject("Error opening database");
        }
      );
    });
  }
  openDatabase(devType) {
    console.log("Open database");
    if (devType === "browser") {
      return this.openDatabaseBrowser();
    } else {
      return this.openDatabaseCordova2();
    }
  }

  onError(sql, params, error) {
    console.log("Error: ", error, " sql: ", sql, " params: ", params);
  }

  /**
   * Perform a query and return results
   * @param sql The sql statement
   * @param params Parameters for sql statement
   * @param onSuccess Function that handle the response. Expects an array or item collections,
   * the actual rows
   */
  query(sql, params, onSuccess, onFail) {
    var self = this;
    var result = [];
    var fn = function(response) {
      if (response && response[0]) {
        result = toJSON(response)[0];
      }
      onSuccess(result);
    }; // end fn

    self.exec(sql, params, fn, onFail);

    return result;
  }

  tableExist(table, successFunction) {
    var self = this;
    var sql =
      "SELECT name FROM sqlite_master WHERE type=table AND name= " + table;
    var params = [];
    self.exec(sql, params, successFunction);
  }

  regexIndexOf(string, searchText, startpos) {
    var regex = new RegExp("\\s" + searchText + "\\s|\\s" + searchText + ",");
    var indexOf = string.search(regex);
    if (indexOf < 1) {
      console.log("ERROR: INdex not found indexof ", indexOf);
    }
    return indexOf;
  }

  /**
   * Executes a query. It may return data, but must be handled in response.rows.item format
   * @param sql The sql Statement
   * @param params The parameters
   * @param onSuccess The callback function
   */
  exec(sql, params, onSuccess, onFail) {
    var self = this;
    var originalSql = sql;
    if (params === null || params === "undefined") {
      params = [];
    }
    var keys = Object.keys(params);
    if (keys.length > 0) {
      if (isNaN(keys[0])) {
        // Here is assoc
        // Sql Statement must contain :
        if (sql.indexOf(":") === -1) {
          // throw new DbException('No parameters in sql statement')
        }
        var i = 0;
        var j = 0;
        // var count = keys.length
        var newParams = [];
        var newParam = {};
        var positions = [];
        var position;
        for (i = 0; i < keys.length; i++) {
          position = self.regexIndexOf(originalSql, ":" + keys[i], 0);
          positions.push(position);
          sql = sql.replace(":" + keys[i] + ",", "?,");
          sql = sql.replace(":" + keys[i] + " ", "? ");
          //sql = sql.replace(":" + keys[i], "?");
          newParam = {};
          newParam.position = position;
          newParam.value = params[keys[i]];
          newParams.push(newParam);
        } // end for
        var fnSort = function(a, b) {
          return a - b;
        };
        positions = positions.sort(fnSort);
        var finalParams = [];
        for (i = 0; i < positions.length; i++) {
          for (j = 0; j < newParams.length; j++) {
            if (newParams[j].position === positions[i]) {
              finalParams.push(newParams[j].value);
            }
          }
        }
        params = finalParams;
      } else {
        // Here is just list
        // Sql statement must contains ?
        if (sql.indexOf("?") === -1) {
          // TODO throw new DbException('No parameters in sql statement')
        } // end if sql.indexOf
      } // end if else NaN(keys[0])
    } // end if keys.lenght > 0

    var errorfunction = function(error) {
      if (!UTIL.isEmpty(onFail)) {
        console.log("fail:", sql, params, error);
        onFail(sql, params, error);
      } else {
        self.onError(sql, params, error);
      }
    };

    execQuery(sql, params, onSuccess, errorfunction);

    // var fn = function(tran) {
    //   tran.executeSql(sql, params, onSuccess, errorfunction);
    // };

    // self.dataBase.transaction(fn, errorfunction, self.onSuccess);
  }

  /**
   * Execute a bulk upsert
   * @param tableName Name of table
   * @param data Collection of assoc arrays
   * @param keyFields Array with the name of the fields that are keys
   */
  bulkUpsert(tableName, data, keyFields, successFunction, failFunction) {
    var len = data.length;
    var i;
    var counter = 0;
    if (len < 1) {
      var response = [];
      successFunction(response);
    }

    var updateCounter = function(response) {
      counter = counter + 0.5;
      if (counter >= len) {
        successFunction(response);
      }
    };

    // this.dataBase.transaction(
    //   trans => {

    // execQuery(sql, params, onSuccess, errorfunction);

    let updateSql = {
      sql: "",
      params: []
    };

    let insertSql = {
      sql: "",
      params: []
    };

    // for (i = 0; i < len; i++) {
    //   // check if exist
    //   const item = data[i];

    //   const { sqlUpdate } = updateSQL(item, tableName, keyFields);

    //   sqls.push(sqlUpdate);

    //   const { sqlInsert } = insertSQL(item, tableName);

    //   sqls.push(sqlInsert);

    // }

    for (i = 0; i < len; i++) {
      // check if exist
      const item = data[i];

      const { sqlUpdate, dataUpdate } = updateSQL(item, tableName, keyFields);
      updateSql.sql = sqlUpdate;
      updateSql.params.push(dataUpdate);

      const { sqlInsert, dataInsert } = insertSQL(item, tableName);
      insertSql.sql = sqlInsert;
      insertSql.params.push(dataInsert);
    }
    execBulk(
      updateSql.sql,
      updateSql.params,
      result => {
        execBulk(
          insertSql.sql,
          insertSql.params,
          result => {
            successFunction(result);
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * Perform an upsert on a database table
   * @param tableName The table name
   * @param tableData The assoc array with the data, field => value
   * @param keyFields An array with the fields that are key
   */
  upsert(tableName, tableData, keyFields, successfunction, failFunction) {
    var self = this;
    var sql = "";
    var where = "";
    var values = "";
    var fields = "";
    var i;
    var len;
    var keys;
    var whereParams = {};
    if (tableData == null) {
      // throw new DbException('No data to insert')
      return;
    }
    var and = "";
    if (keyFields != null) {
      if (Array.isArray(keyFields)) {
        len = keyFields.length;
        for (i = 0; i < len; i++) {
          where += and + " " + keyFields[i] + " = :" + keyFields[i];
          whereParams[keyFields[i]] = tableData[keyFields[i]];
          and = " AND";
        } // end for
      } // end if array
    } // end if keyfields not null
    // Prepare count
    sql = "SELECT COUNT(*) AS cont FROM @tableName WHERE @where ";
    sql = sql.replace("@tableName", tableName);
    sql = sql.replace("@where", where);

    var fnCountOk = function(response) {
      var row = response[0];
      var count = 0;
      if (row !== undefined) {
        count = row["cont"];
      }
      if (count > 0) {
        // Here is update
        // Create the values

        keys = Object.keys(tableData);
        len = keys.length;
        for (i = 0; i < len; i++) {
          if (where !== "") {
            if (!keyFields.contains(keys[i])) {
              // Here there is no key
              if (values === "") {
                values += keys[i] + " = :" + keys[i];
              } else {
                values += ", " + keys[i] + " = :" + keys[i];
              } // end if then else values = ''
            } // end if indexOf keyFiels
          } // end if where != ''
        } // end for each key
        // Prepare update
        sql = "UPDATE @tableName SET @values WHERE @where ";
        sql = sql.replace("@tableName", tableName);
        sql = sql.replace("@values", values);
        sql = sql.replace("@where", where);
        // console.log("updateSQL", sql);
        var fnUpdateOk = function(tran, response) {
          if (successfunction != null) {
            successfunction(response, tran);
          }
        };
        self.exec(sql, tableData, fnUpdateOk);
      } else {
        // Here is INSERT
        // Create the values
        keys = Object.keys(tableData);
        len = keys.length;
        for (i = 0; i < len; i++) {
          if (values === "") {
            values += ":" + keys[i];
            fields += keys[i];
          } else {
            values += ", :" + keys[i];
            fields += "," + keys[i];
          } // end if then else values = ''
        } // end for each key
        // Prepare update
        sql = "INSERT INTO @tableName ( @fields ) VALUES ( @values ) ";
        sql = sql.replace("@tableName", tableName);
        sql = sql.replace("@values", values);
        sql = sql.replace("@fields", fields);
        var fnInsertOk = function(tran, response) {
          // console.log('Insert OK')
          if (successfunction !== null) {
            successfunction(response, tran);
          }
        };
        self.exec(sql, tableData, fnInsertOk, failFunction);
      }
    };
    self.query(sql, whereParams, fnCountOk);
  } // end function upsert
}
