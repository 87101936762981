<template>
  <v-container
    ref="myListOuter"
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-row
      :style="`min-width: ${getActions.length * (isNotList ? 0 : 44) + 16}px; max-w`"
      align="center"
      justify-content="start"
      class="px-2"
    >

      <div
        v-for="action in getActions"
        :key="action.method"
        class="pa-2 px-2"
      >

        <v-tooltip
          v-if="!isNotList || isCompactView"
          :open-delay="100"
          content-class="rounded-lg"
          bottom
          light
          color="white"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              small
              icon
              dark
              class="primary "
              v-on="on"
              @click="launchAction(action)"
            >
              <v-icon
                small
                dark
              > {{ getIcon(action) }} </v-icon>
            </v-btn>
          </template>
          <span class="text-body-2 primary--text font-weight-mediumBold">{{ getTitle(action) }}</span>

        </v-tooltip>
        <v-btn
          v-else
          rounded
          dense
          dark
          elevation="0"
          class="primary"
          @click="launchAction(action)"
        >
          <v-icon left> {{ getIcon(action) }} </v-icon>
          {{ getTitle(action) }}

        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import Axios from 'axios';
import { API_BASE_URL } from '../../../api/constants';
import * as utilities from "../../../api/utilities";
import { getAccessToken } from '../../../store/authorizationContext';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, EXTRA_SMALL_SIZE_THRESHOLD, KING_SIZE, MEDIUM_SIZE, MEDIUM_SIZE_THRESHOLD, MINI_SIZE_THRESHOLD, SMALL_SIZE, SMALL_SIZE_THRESHOLD } from "../../../store/constants";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    viewconfig: {
      type: String,
      default: ""
    },
    isNotList: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      myListWidth: 0,
    }
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
    },
    getActions() {
      var actions = this.$store.getters.getActionListForActionConfig(
        this.header.selectionView,
        this.object,
        this.getObjectConfig,
        this.getViewConfig
      );
      if (utilities.isEmpty(actions)) {
        return [];
      }
      return actions;
    },
    isCompactView() {
      return this.myListWidth <= EXTRA_SMALL_SIZE_THRESHOLD
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    getTitle(action) {
      return action.title;
    },
    getIcon(action) {
      if (utilities.isEmpty(action.icon)) {
        return 'hi-bolt-outline'
      }
      return action.icon;
    },
    launchAction(action) {
      if (utilities.isTrue(action.isOnline)) {
        this.launchOnlineAction(action)
      } else {
        var objectConfig = this.$store.getters.getObjectConfigForType(
          this.getViewConfig.objectType
        );
        var payload = {
          action: action.method,
          objectConfig: objectConfig,
          object: this.object,
          viewConfig: this.getViewConfig,
        };
        this.$store.dispatch("launchActionForObjectAndConfig", payload);
      }
    },
    onResizeComponent() {
      var container = this.$refs.myListOuter;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
        // if (width < 200) {
        //   return;
        // }
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    async launchOnlineAction(item) {
      this.loading = true
      var config = {
        headers: {}
      };
      var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

      if (utilities.isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: error
        });
        return;
      }
      config.headers.Authorization = "Bearer ".concat(bearer);
      const objectConfig = this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
      const payload = {
        action: item.method,
        objectConfig,
        object: this.currentObject,
        employee: this.getCurrentEmployeeGuid
      }
      const url = `${API_BASE_URL}action`
      Axios.post(url, payload, config).then(response => {
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_SUCCESS,
          message: response.data && response.data.message
        });
        this.$store.dispatch("synchronizeAllData");
      }, error => {
        console.log('actionError', error)
        const message = error && error.response && error.response.data
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: message
        });
      })
    },
  }
};
</script>
