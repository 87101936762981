<template>
  <v-card flat>
    <weather
      api-key="0343b4bd38feb09ade2652bb70ca9602"
      title="Leuven"
      latitude="50.9052669"
      longitude="4.7357654"
      language="en"
      units="si"
    />
  </v-card>
</template>

<script>
import VueWeatherWidget from "vue-weather-widget";
import "vue-weather-widget/dist/css/vue-weather-widget.css";

export default {
  components: {
    weather: VueWeatherWidget
  }
};
</script>

<style scoped></style>
