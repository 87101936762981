/**
 * Alert Context
 */

import { getGuid, isEmpty, isEmptyObj } from "../api/utilities";
import { CREATE_OPERATION_CONTAINER } from "./constants";

const initState = {
  title: "",
  showDialog: false,
  viewConfig: "",
  splitcardId: "",
  object: null,
  relatedObject: null,
  saveFunction: null,
  cancelFunction: null,
  startPosition: null,
  operationId: null,
  origin: null,
  dialog: {
    show: null,
  },
};

export default {
  state: initState,
  getters: {
    getViewConfigForViewDialog: (state, getters) => {
      return state.viewConfig;
    },
    getSplitCardForViewDialog: (state, getters) => {
      if (!isEmpty(state.splitcardId)) {
        var splitCard = getters.getSplitCardForViewConfig(state.splitcardId);
        return splitCard;
      }
      return null;
    },
    getObjectTypeForViewDialog: (state, getters) => {
      const splitcard = getters.getSplitCardForViewDialog;
      if (!isEmptyObj(splitcard)) {
        return splitcard.objectType;
      }
      return null;
    },
    getSplitCardIdForViewDialog: (state, getters) => {
      return state.splitcardId;
    },
    getObjectForViewDialog: (state, getters) => {
      return state.object;
    },
    getViewShowDialog: (state, getters) => {
      return state.dialog;
    },
    getOperationIdForViewDialog: (state, getters) => {
      return state.operationId;
    },
    getOriginForViewDialog: (state, getters) => {
      return state.origin;
    },
  },
  mutations: {
    showViewDialog(state, payload) {
      const { viewConfig, object, operationId, origin, splitCardId } = payload;
      state.viewConfig = viewConfig;
      state.object = object;
      state.dialog.show = true;
      state.operationId = operationId;
      state.origin = origin;
      state.splitcardId = splitCardId;
    },
    hideViewDialog(state) {
      state.viewConfig = null;
      state.object = null;
      state.dialog.show = false;
      state.operationId = null;
      state.origin = null;
      state.splitcardId = null;
    },
  },
  actions: {
    async showViewDialog({ dispatch, commit, getters }, payload) {
      commit("showViewDialog", payload);
    },
  },
};
