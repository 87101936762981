import { isEmpty } from "../api/utilities";
import { SYNC_GROUP_CORE_SYNC_GROUP } from "./constants";

export function mustSyncSyncGroup(getters, syncGroup){
  if (isEmpty(syncGroup) || isEmpty(syncGroup.lastChangeObjectDate) || isEmpty(syncGroup.lastSync) ){
    return true
  }

  if (syncGroup.syncGroup === SYNC_GROUP_CORE_SYNC_GROUP){
    return true;
  }

  if (syncGroupHasOperations(getters, syncGroup)){
    return true
  }

  var mustSync = syncGroup.lastChangeObjectDate >= syncGroup.lastSync
  return mustSync
}


function syncGroupHasOperations(getters, syncGroup){
  var operations = getters.getOperationsToSend
  var operationsForGroup = operations.filter(function(o) {
    return o.objectConfig.objectConfig.syncGroup === syncGroup.syncGroup;
  });
  var hasOperations = operationsForGroup.length > 0;
  return 
}