const { GOOGLE_API_KEY } = require("./firebase");

// TODO(developer): Set to client ID and API key from the Developer Console
const CLIENT_ID =
  "639169510919-93iefbqqg18jh4jhsr7muo0evdo14f19.apps.googleusercontent.com";
const API_KEY = GOOGLE_API_KEY;

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest";

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES =
  "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/calendar.events";

let tokenClient;
let gapiInited = false;
let gisInited = false;

export function initGapi() {
  gapiLoaded();
  gisLoaded();
}

var value = null;
/**
 * Callback after api.js is loaded.
 */
function gapiLoaded() {
  gapi.load("client", initializeGapiClient);
  console.log("GOOGLE_API");
}

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
async function initializeGapiClient() {
  await gapi.client.init({
    apiKey: API_KEY,
    discoveryDocs: [DISCOVERY_DOC]
  });
  gapiInited = true;
  maybeEnableButtons();
}

/**
 * Callback after Google Identity Services are loaded.
 */
function gisLoaded() {
  tokenClient = google.accounts.oauth2.initCodeClient({
    client_id: CLIENT_ID,
    scope: SCOPES,
    ux_mode: "redirect",
    redirect_uri: "http://localhost:8081",
    state: value
    // callback: tokenResponse => {
    //   console.log("GOOGLE", tokenResponse);
    //   if (tokenResponse && tokenResponse.access_token) {
    //     // gapi.client.setApiKey("YOUR_API_KEY");
    //     // gapi.client.load("calendar", "v3", listUpcomingEvents);
    //   }
    // }
  });
  gisInited = true;
  maybeEnableButtons();
}

/**
 * Enables user interaction after all libraries are loaded.
 */
function maybeEnableButtons() {
  //   if (gapiInited && gisInited) {
  //     document.getElementById("authorize_button").style.visibility = "visible";
  //   }
}

/**
 *  Sign in the user upon button click.
 */
export function handleAuthClick() {
  //   tokenClient.callback = async resp => {
  //     if (resp.error !== undefined) {
  //       throw resp;
  //     }
  //     console.log("GOOGLE", resp);
  //     document.getElementById("signout_button").style.visibility = "visible";
  //     document.getElementById("authorize_button").innerText = "Refresh";
  //     await listLabels();
  //   };

  if (gapi.client.getToken() === null) {
    // Prompt the user to select a Google Account and ask for consent to share their data
    // when establishing a new session.
    tokenClient.requestCode();
  } else {
    // Skip display of account chooser and consent dialog for an existing session.
    tokenClient.requestCode();
  }
}

/**
 *  Sign out the user upon button click.
 */
function handleSignoutClick() {
  const token = gapi.client.getToken();
  if (token !== null) {
    google.accounts.oauth2.revoke(token.access_token);
    gapi.client.setToken("");
    document.getElementById("content").innerText = "";
    document.getElementById("authorize_button").innerText = "Authorize";
    document.getElementById("signout_button").style.visibility = "hidden";
  }
}

/**
 * Print all Labels in the authorized user's inbox. If no labels
 * are found an appropriate message is printed.
 */
async function listLabels() {
  let response;
  try {
    response = await gapi.client.gmail.users.labels.list({
      userId: "me"
    });
  } catch (err) {
    document.getElementById("content").innerText = err.message;
    return;
  }
  const labels = response.result.labels;
  if (!labels || labels.length == 0) {
    document.getElementById("content").innerText = "No labels found.";
    return;
  }
  // Flatten to string to display
  const output = labels.reduce(
    (str, label) => `${str}${label.name}\n`,
    "Labels:\n"
  );
  document.getElementById("content").innerText = output;
}
