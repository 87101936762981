<template>
  <v-card
    flat
    class="ma-3 pa-3"
  >
    <h2>Setting Manager</h2>
    <viewConfigView
      :object="getFilter"
      viewconfig="FilterConfigSettingsList"
      height="600"
    />
    <v-card
      flat
      class="d-flex align-end"
    >
      <v-card
        flat
        class="ml-auto"
      >
        <v-btn
          color="primary"
          text
          @click="closeSettingManager()"
        >Close</v-btn>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
export default {
  props: {
    dialogcontroller: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      testVar: null
    };
  },
  computed: {
    getViewConfig() {
      //AutoAdd
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFilter() {
      return this.filter;
    },
    currentObject() {
      //AutoAdd
      return this.$store.getters.getCurrentObject;
    }
  },
  methods: {
    closeSettingManager() {
      this.dialogcontroller.dialog = false;
    }
  }
};
</script>
