<template>
  <v-card>
    <v-dialog
      v-model="getShowConfigDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card color="tileBackgroundColor">
        <v-card
          color="orange darken-2"
          width="full-width"
          dark
          class="buttonbottom"
          height="80px"
        >
          <v-btn
            icon
            @click.native="closeConfiguration()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
        </v-card>

        <configurationBar />
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import * as Utilities from "../../../api/utilities";
import configurationBar from "./ConfigurationBar";

export default {
  components: { configurationBar },
  props: {},
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    getShowConfigDialog() {
      return this.$store.getters.getShowConfigDialog;
    },

    isConfigMode() {
      return this.$store.getters.isConfigMode(this.viewconfig);
    }
  },
  methods: {
    openConfiguration() {
      this.dialog = true;
    },
    closeConfiguration() {
      this.$store.dispatch("closeConfigViewDialog");
    },

    showConfigurationInsideBar(viewConfig, viewConfigId, type) {
      var selectedObject = null;
      if (viewConfig === undefined || viewConfig === null) {
        selectedObject = {
          viewConfig: viewConfigId
        };
      } else {
        selectedObject = viewConfig;
        if (
          viewConfig.viewConfig === undefined ||
          viewConfig.viewConfig === null
        ) {
          selectedObject.viewConfig = viewConfigId;
        }
      }
      var payload = {
        configurationViewConfig: type.concat("ViewDetailsconfig"),
        configuration: selectedObject
      };
      this.$store.dispatch("goToConfiguration", payload);
    }
  }
};
</script>

<style>
.buttonbottom {
  vertical-align: text-bottom;
}
</style>
