import moment from "moment";
import { DATE_FORMAT, OPERATION, SYNCHRONISATION } from "./constants";

export const CLEARING_DATE = "sw_cd";

export default {
  state: {
    lastClearingDate:
      window && window.localStorage.getItem(CLEARING_DATE)
        ? JSON.parse(window.localStorage.getItem(CLEARING_DATE))
        : moment()
            .subtract(1, "day")
            .unix()
  },

  getters: {
    getlastClearingDate: (state, getters) => {
      return state.lastClearingDate;
    }
  },
  mutations: {
    setNexClearingDate(state) {
      const newDate = moment().unix();
      window.localStorage.setItem(CLEARING_DATE, JSON.stringify(newDate));
      state.lastClearingDate = newDate; //Add  1 day.
    }
  },
  actions: {
    async clearUnnecessaryItems({ dispatch, commit, getters }) {
      //Check if there are unsync operations

      const operations = getters.getDataObjectsForObjectType(OPERATION);
      const sychronisation = getters.getDataObjectsForObjectType(
        SYNCHRONISATION
      );
      if (getters.getNumberOfOperationsToSend > 0) {
        console.log("Not possible to clear while operations pending");
        return;
      }

      if (
        moment()
          .subtract(4, "hours")
          .isBefore(moment.unix(getters.getlastClearingDate))
      ) {
        console.log("Wait for next date");
        return;
      }

      // *** Delete the operations that are completed and which are old. ***
      const past = moment()
        .subtract(1, "day")
        .valueOf();
      await dispatch("clearRows", {
        objectType: OPERATION,
        query: `createTime <= ${past} AND status = 'Completed'`,
        filter: item => item.createTime > past && item.status === "Completed"
      });

      // *** Delete the synchronisations that are completed which are old. ***
      await dispatch("clearRows", {
        objectType: SYNCHRONISATION,
        query: `createTime <= ${past} AND status = 'Completed'`,
        filter: item => item.createTime > past && item.status === "Completed"
      });

      commit("setNexClearingDate");
    },
    async clearRows({ dispatch, commit, getters }, payload) {
      const { objectType, query, filter } = payload;
      // deleting from db
      var database = getters.getDatabase;
      if (database) {
        var objectConfig = getters.getObjectConfigForType(objectType);
        await objectConfig.clearItemsInDbForQuery(database, query);
        // deleting from store
        const operations = getters.getDataObjectsForObjectType(objectType);
        var filtered = operations.filter(filter);
        commit("setDataObjects", {
          objectConfig,
          objects: filtered
        });
      }
    }
  }
};
