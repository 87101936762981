import { isEmpty } from "../api/utilities";

/**
 * Chart Context
 */

const initState = {
  loadingDialog: false,
  isLoading: false,
  message: null,
  fullScreen: false,
};

export default {
  state: {
    loadingDialog: false,
    isLoading: false,
    message: null,
    fullScreen: false,
  },
  getters: {
    /*
      Get Logs
    */
    getLoadingDialog: (state) => {
      return state.loadingDialog;
    },
    getIsLoading: (state) => {
      return state.isLoading;
    },
    getLoadingMessage: (state) => {
      return state.message;
    },
    getFullScreen: (state) => {
      return state.fullScreen;
    },
  },
  mutations: {
    setShowLoadingDialog(state, value) {
      state.loadingDialog = value;
    },
    setLoadingDialog(state, value) {
      state.isLoading = value;
    },
    setMessage(state, value) {
      state.message = value;
    },
    setFullscreen(state, value) {
      state.fullScreen = value;
    },
    resetLoadingScreen(state) {
      state.loadingDialog = initState.loadingDialog;
      setTimeout(() => {
        state.fullScreen = initState.fullScreen;
        state.message = initState.message;
        state.isLoading = initState.isLoading;
      }, 500);
    },
  },
  actions: {
    showLoading({ commit }, payload) {
      commit(
        "setMessage",
        payload && !isEmpty(payload.message) ? payload.message : null,
      );
      commit("setShowLoadingDialog", true);
      commit("setFullscreen", payload && payload.fullScreen);
    },
    hideLoading({ commit }) {
      commit("resetLoadingScreen");
    },
  },
};
