import * as Constants from "../store/constants";
export default {
  objects: [
    {
      objectType: Constants.OBJECT_CONFIG,
      name: Constants.OBJECT_CONFIG,
      icon: Constants.OBJECT_CONFIG_ICON,
      skipTableCreate: false,
      syncGroup: Constants.SYNC_GROUP_CORE_OBJECTS,
      Guid: "3550c32a-7f9a-21d0-c65b-46194ccbbe20"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      name: Constants.FIELD_CONFIG,
      icon: Constants.FIELD_CONFIG_ICON,
      skipTableCreate: false,
      syncGroup: Constants.SYNC_GROUP_CORE_FIELDS,
      Guid: "09a33e6b-f60d-f380-1eb5-970019dafee7"
    },
    {
      objectType: Constants.OPERATION,
      name: Constants.OPERATION,
      icon: Constants.OPERATION_ICON,
      syncGroup: Constants.SYNC_GROUP_CORE_REST,
      Guid: "71a0e400-9780-809a-2148-b93f3d4fec98"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      name: Constants.SYNCHRONISATION,
      icon: Constants.SYNCHRONISATION_ICON,
      syncGroup: Constants.SYNC_GROUP_CORE_REST,
      skipTableCreate: false,
      Guid: "518f4519-09aa-46f4-a909-d6d57a916936"
    }
  ],
  /**********************
   * Fields
   */
  fields: [
    /**********************************************
     * objectConfig
     **********************************************/
    {
      objectType: Constants.OBJECT_CONFIG,
      field: "syncId",
      description: "syncId",
      isId: false,
      type: "string",
      edit: false,
      local: true,
      Guid: "9a7fe837-e306-a8c6-86f5-d47a93decb1f"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "Guid",
      field: "Guid",
      description: "Guid",
      isId: false,
      type: "string",
      edit: true,
      Guid: "5c3a539a-ed03-9e96-4f9d-eb5eb70857c6"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "objectType",
      field: "objectType",
      description: "objectType",
      isId: true,
      type: "string",
      edit: true,
      Guid: "7be45f45-0475-5bac-1b98-1fb2d4cbc5ed"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "name",
      field: "name",
      description: "name",
      isId: false,
      type: "string",
      edit: true,
      Guid: "4fedd847-9575-d218-c6f6-c2acf7e5d945"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "icon",
      field: "icon",
      description: "icon",
      isId: false,
      type: "string",
      edit: true,
      Guid: "8fa7925d-c226-addc-6ce6-3d679065e2a9"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "skipTableCreate",
      field: "skipTableCreate",
      description: "skipTableCreate",
      isId: false,
      type: "boolean",
      edit: true,
      Guid: "5ef90471-57c3-94e7-e9b5-290749e62680"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "syncGroup",
      field: "syncGroup",
      description: "syncGroup",
      isId: false,
      type: "string",
      edit: true,
      Guid: "474baf90-09e7-0879-b11e-e06356244c85"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "isDeleted",
      field: "isDeleted",
      description: "isDeleted",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "49e2e7bc-033c-3609-2932-0fd95189bc32"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "_id",
      field: "_id",
      description: "_id",
      isId: false,
      type: "string",
      edit: true,
      Guid: "b5532087-84f1-3446-e12e-61e575d55e4e"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "auth",
      field: "auth",
      description: "auth",
      isId: false,
      type: "string",
      edit: true,
      Guid: "8ddd893f-c7ff-05a9-6b2e-8cb42bb1a038"
    },
    {
      objectType: Constants.OBJECT_CONFIG,
      backendField: "updatedAt",
      field: "updatedAt",
      description: "updatedAt",
      isId: false,
      type: Constants.STRING,
      edit: false,
      Guid: "4ff581bf-a792-5e8b-48ec-223f3ced39bb"
    },

    /**********************************************
     * FieldConfig
     **********************************************/
    {
      objectType: Constants.FIELD_CONFIG,
      field: "syncId",
      description: "syncId",
      isId: false,
      type: "string",
      edit: false,
      local: true,
      Guid: "0837da99-fe2b-a78b-335f-42cdc695c18c"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "Guid",
      field: "Guid",
      description: "Guid",
      isId: true,
      type: "string",
      edit: true,
      Guid: "c883e72c-1d29-912f-4efd-d6a1f0863110"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "backendField",
      field: "backendField",
      description: "backendField",
      isId: false,
      type: "string",
      edit: true,
      Guid: "2d686b46-4bb9-378b-3940-9d3e62e39c0a"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "field",
      field: "field",
      description: "field",
      isId: false,
      type: "string",
      edit: true,
      Guid: "0886f33b-b4cd-bada-ccf0-8081fb68d3f5"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "description",
      field: "description",
      description: "description",
      isId: false,
      type: "string",
      edit: true,
      Guid: "f171a14b-edb0-7329-6128-6a1f0378e2e4"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "isId",
      field: "isId",
      description: "isId",
      isId: false,
      type: "boolean",
      edit: true,
      Guid: "c4ae32aa-f8f4-58b8-2996-6f4c97bdd9dd"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "isLogicalKey",
      field: "isLogicalKey",
      description: "isLogicalKey",
      isId: false,
      type: "boolean",
      edit: true,
      Guid: "f7de16aa-f8f4-k8b8-2a06-9a2d9bbddkdd"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "type",
      field: "type",
      description: "type",
      isId: false,
      type: "string",
      edit: true,
      Guid: "ac979019-6d5a-6934-8d16-2610ac2f26be"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "relatedObjectType",
      field: "relatedObjectType",
      description: "relatedObjectType",
      isId: false,
      type: "string",
      edit: true,
      Guid: "0f576b9e-6e2b-9a09-2142-8d0b1730c01b"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "relatedObjectField",
      field: "relatedObjectField",
      description: "relatedObjectField",
      isId: false,
      type: "string",
      edit: true,
      Guid: "c043ab37-ad9c-71d5-d198-cb777a966c8f"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "selectionView",
      field: "selectionView",
      description: "selectionView",
      relatedObjectType: Constants.VIEW_CONFIG,
      relatedObjectField: "viewConfig",
      selectionView: "",
      isId: false,
      type: "string",
      edit: true,
      Guid: "5e7ff382-908b-0c27-8132-41cf122d8f0a"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "dropdown",
      field: "dropdown",
      description: "dropdown",
      isId: false,
      type: "string",
      edit: true,
      Guid: "814de603-d500-ef9b-7f2e-9f25518ca21a"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "convertor",
      field: "convertor",
      description: "convertor",
      isId: false,
      type: "string",
      edit: true,
      Guid: "85fbb62c-99bc-9bcf-8e32-a3195d8eebab"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "edit",
      field: "edit",
      description: "edit",
      isId: false,
      type: "boolean",
      edit: true,
      Guid: "220e00ba-144c-6c70-ade6-9378eddcfd06"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "isDeleted",
      field: "isDeleted",
      description: "isDeleted",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "c221c507-45f4-32e5-6872-9edc386a781b"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "local",
      field: "local",
      description: "local",
      isId: false,
      type: "boolean",
      edit: true,
      Guid: "1cb58797-1340-a911-26f5-c84d30a1a525"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "objectType",
      field: "objectType",
      description: "objectType",
      relatedObjectType: "ObjectConfig",
      relatedObjectField: "objectType",
      isId: false,
      type: "string",
      edit: true,
      Guid: "71160e81-df1c-de51-8319-b20e41baf951"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "_id",
      field: "_id",
      description: "_id",
      isId: false,
      type: "string",
      edit: true,
      Guid: "7e991dcd-acde-49d4-f011-157eeb28d154"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "auth",
      field: "auth",
      description: "auth",
      isId: false,
      type: "string",
      edit: true,
      Guid: "a0baa956-3109-b658-2aad-5a420c052c36"
    },
    {
      objectType: Constants.FIELD_CONFIG,
      backendField: "updatedAt",
      field: "updatedAt",
      description: "updatedAt",
      isId: false,
      type: Constants.STRING,
      edit: false,
      Guid: "cb05813b-12a8-42f7-57dd-e0e3054a06a0"
    },

    /**********************************************
     * Synchronisation
     **********************************************/
    {
      objectType: Constants.SYNCHRONISATION,
      field: "syncId",
      description: "SyncId",
      isId: false,
      type: "string",
      edit: false,
      local: true,
      Guid: "f0d38322-0165-9018-edc4-94c635a811a1"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "parent",
      field: "parent",
      description: "parent",
      isId: false,
      type: "string",
      edit: true,
      Guid: "50b6a6fc-f290-dde3-83bc-acc48b1d7213"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "type",
      field: "type",
      description: "type",
      isId: false,
      type: "string",
      edit: true,
      Guid: "028d8242-2795-dfce-8c91-49f2f09dabec"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "objectType",
      field: "objectType",
      description: "objectType",
      relatedObjectType: "ObjectConfig",
      relatedObjectField: "objectType",
      isId: false,
      type: "string",
      edit: true,
      Guid: "41af32bc-81c3-3db0-ba45-3c9f11148561"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "method",
      field: "method",
      description: "method",
      isId: false,
      type: "string",
      edit: true,
      Guid: "24256dea-12ff-2354-348b-0e3e4485f306"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "mode",
      field: "mode",
      description: "mode",
      isId: false,
      type: "string",
      edit: true,
      Guid: "50416575-0584-2818-40ca-b594a8dee02f"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "status",
      field: "status",
      description: "status",
      isId: false,
      type: "string",
      edit: true,
      Guid: "13751faa-a418-53c8-61e7-91db6365fd3d"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "createTime",
      field: "createTime",
      description: "createTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "1610c7e6-efe7-ec44-0fbf-c4e993169ac2"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "sendTime",
      field: "sendTime",
      description: "sendTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "770888c7-f43a-37f2-1b1e-38574233545c"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "responseTime",
      field: "responseTime",
      description: "responseTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "08145761-cb34-6682-c2c1-4635cdafba3a"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "parsingTime",
      field: "parsingTime",
      description: "parsingTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "3975a04c-839f-8d7d-9e6d-ad3385faba68"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "savingTime",
      field: "savingTime",
      description: "savingTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "2b47eb5f-faae-eff4-c1e5-3c77b9a9e303"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "completionTime",
      field: "completionTime",
      description: "completionTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "8a19422b-0ad7-7eaf-b4c6-2b895552bb63"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "loglevel",
      field: "loglevel",
      description: "loglevel",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "b0eae4ec-4f5b-5707-9729-f6e30a6dc1cb"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "response",
      field: "response",
      description: "response",
      isId: false,
      type: "string",
      edit: true,
      Guid: "e17f21ad-ae16-6ef4-33fb-5fabeb71a3a4"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "numberOfObjects",
      field: "numberOfObjects",
      description: "numberOfObjects",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "2d1086d9-2c3c-2c39-82f5-3072ad96801b"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "user",
      field: "user",
      description: "user",
      isId: false,
      type: "string",
      edit: true,
      Guid: "b6efd34e-f21e-2502-12b0-6def75d0f85a"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "device",
      field: "device",
      description: "device",
      isId: false,
      type: "string",
      edit: true,
      Guid: "7f667514-acf4-a68e-3996-43ba75d72632"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "isDeleted",
      field: "isDeleted",
      description: "isDeleted",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "f1f71733-25bd-72d4-103c-c5361dc86921"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "Guid",
      field: "Guid",
      description: "Guid",
      isId: true,
      type: Constants.STRING,
      edit: true,
      Guid: "a4379b63-e6e7-b2d6-b873-ec8eb7d5d89c"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "auth",
      field: "auth",
      description: "auth",
      isId: false,
      type: "string",
      edit: true,
      Guid: "1667c15f-f714-e27d-8b4d-4307c997d68e"
    },
    {
      objectType: Constants.SYNCHRONISATION,
      backendField: "updatedAt",
      field: "updatedAt",
      description: "updatedAt",
      isId: false,
      type: Constants.STRING,
      edit: false,
      Guid: "6b03e722-5a07-f64d-bacf-8f707bf70d51"
    },
    /**********************************************
     * Operation
     *********************************************/
    {
      objectType: Constants.OPERATION,
      field: "syncId",
      description: "SyncId",
      isId: false,
      type: "string",
      edit: false,
      local: true,
      Guid: "515389d0-8337-3949-c712-69a60562458b"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "oldId",
      field: "oldId",
      description: "oldId",
      isId: false,
      type: "string",
      edit: true,
      Guid: "948ce3b5-9a5a-e769-22cf-e76c9ac3acb6"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "newId",
      field: "newId",
      description: "newId",
      isId: false,
      type: "string",
      edit: true,
      Guid: "5d941368-74ed-8125-e596-ba9aaeece9a1"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "objectType",
      field: "objectType",
      description: "objectType",
      relatedObjectType: "ObjectConfig",
      relatedObjectField: "objectType",
      isId: false,
      type: "string",
      edit: true,
      Guid: "6a898a1b-5c33-2a4f-607e-f2aca0c0997a"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "employee",
      field: "employee",
      description: "employee",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "6387dbb6-e626-4b09-b94f-9ccba193eaba"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "oldObject",
      field: "oldObject",
      description: "oldObject",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "e1ab6d1c-df04-44b7-94e7-520895ca0d40"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "newObject",
      field: "newObject",
      description: "newObject",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "5dd1019a-1845-48fa-afb0-223002477068"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "delta",
      field: "delta",
      description: "delta",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "f779349b-94b1-488a-a438-13c343928277"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "status",
      field: "status",
      description: "status",
      isId: false,
      type: "string",
      edit: true,
      Guid: "2da4566d-ea60-98c8-e993-a8b9afb895d8"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "isDeleted",
      field: "isDeleted",
      description: "isDeleted",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "60ebe226-5265-b312-4656-ce1dd20916b9"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "createTime",
      field: "createTime",
      description: "createTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "89898a17-40d5-96d7-43fe-706e37bd348d"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "sendTime",
      field: "sendTime",
      description: "sendTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "bc2c30b8-492a-7f8a-8724-ca29fe49bf03"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "responseTime",
      field: "responseTime",
      description: "responseTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "0965d594-4251-f38f-a111-79c91cc74176"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "completionTime",
      field: "completionTime",
      description: "completionTime",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "f44dbd42-7fb2-61df-3307-de947ee093e7"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "method",
      field: "method",
      description: "method",
      isId: false,
      type: "string",
      edit: true,
      Guid: "cb4b059c-9fe7-274b-a086-495904c0e3bf"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "loglevel",
      field: "loglevel",
      description: "loglevel",
      isId: false,
      type: "integer",
      edit: true,
      Guid: "5a3c6118-ac6c-ca36-9934-6fceacdbe38f"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "response",
      field: "response",
      description: "response",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "5c5028a4-a2a6-cc48-63e4-94537bb5634e"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "body",
      field: "body",
      description: "body",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "aabfe14d-f2ca-17ff-9ea6-0099a3e4273b"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "url",
      field: "url",
      description: "url",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "3959e2c4-c2a3-a1b2-a394-4f62fee60b72"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "Guid",
      field: "Guid",
      description: "Guid",
      isId: true,
      type: Constants.STRING,
      edit: true,
      Guid: "eb386b95-ccb0-4f5d-fb89-40cdeeec19b3"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "auth",
      field: "auth",
      description: "auth",
      isId: false,
      type: "string",
      edit: true,
      Guid: "9d9e115a-bac0-3610-51d9-f7f46448df2b"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "updatedAt",
      field: "updatedAt",
      description: "updatedAt",
      isId: false,
      type: Constants.STRING,
      edit: false,
      Guid: "7f9c37d4-ec9b-6d78-6e42-119b455ccd1a"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "target",
      field: "target",
      description: "target",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "5c24fb9d-07cb-a535-ace2-e16c699edc76"
    },
    {
      objectType: Constants.OPERATION,
      backendField: "syncGroup",
      field: "syncGroup",
      description: "syncGroup",
      isId: false,
      type: Constants.STRING,
      edit: true,
      Guid: "37f2eb02-cdab-0cda-9b57-0684d4cb30f8"
    }
  ]
};
