<template>
  <v-container
    fill-height
    class="pa-0 ma3"
    fluid
  >
    <v-layout
      row
      wrap
      full-with
      fill-height
    >
      <v-flex
        v-for="image in images"
        :key="image.name"
        xs12
        md-7
        lg-6
      >
        <v-card
          flat
          tile
        >
          <video
            :height="getHeight"
            controls
          >
            <source
              :src="getVideoForViewConfig"
              type="video/mp4"
            >
            Your browser does not support the video tag.
          </video>
          <!--<Canvas-video :src="image.src" :autoplay="true"></Canvas-video>-->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import EVBase from "../../assets/Products/Video/EVBase.mp4";
import EVWall from "../../assets/Products/Video/EVWall.mp4";
import WhySmappie from "../../assets/Products/Video/WhySmappie.mp4";
import * as utilities from "../../api/utilities"

export default {
  props: {
    viewconfig: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      image1: false,
      image2: false,
      image3: false,
      image4: false,
      image5: false,
      images: [
        {
          src: "",
          type: "video",
          name: "video1",
          height: "800px",
          width: "1013px"
        }
      ]
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getVideoForViewConfig() {
      var viewConfig = this.getViewConfig
      if (!utilities.isEmpty(viewConfig) && !utilities.isEmpty(viewConfig.subViewConfig)) {
        switch (viewConfig.subViewConfig) {
          case "EVBase":
            return EVBase
          case "EVWall":
            return EVWall
          case "WhySmappie":
            return WhySmappie
          default:
            return null
        }
      }
      return null
    }

  },
  mounted() { },
  methods: {
    getCardClassForImage(image) {
      if (this[image.name] === true) {
        return "imageVisible";
      }
      return "imageInvisible";
    },
    visibilityChanged(isVisible, entry, image) {
      this[image.name] = true;
    },
    getHeight() {
      return 350;
    }
  }
};
</script>

<style>
.imageVisible {
  opacity: 1;
  transition-delay: 1s;
  -webkit-transition-delay: 1s;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
}

.imageInvisible {
  opacity: 0;
  transition-delay: 1s;
  -webkit-transition-delay: 1s;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
}

video {
  /* override other styles to make responsive */
  width: 100% !important;
}
</style>
