<template>
  <div :style="`max-height: ${isEmpty(height) || height === 'NaNpx' ? '80vh' : 'calc(100% - 60px)'}; overflow-y: auto;`">
    <v-row
      v-if="loading"
      class="py-8 px-4"
      align="center"
      justify="center"
      style="height: 100%"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </v-row>
    <v-timeline
      v-else
      align-top
      dense
    >
      <v-timeline-item
        v-for="(item, i) in getItems"
        :key="i"
        :color="item.isObject ? 'primary' : 'iconButton'"
        :icon="getIcon(item)"
        :icon-color="item.isObject ? 'white' : 'primaryText'"
        :small="!item.isObject"
        fill-dot
      >
        <div>
          <div class="text-body-2 gray--text">
            <b class="font-weight-medium primaryText--text">{{ getEmployeeName(item.employee) }}</b> {{ getTitle(item) }}
            <b
              v-if="isNavigationable(item)"
              @click="goTo(item)"
              class="font-weight-medium pointer primary--text"
            >{{ getRelatedObject(item) }}</b><b
              v-else
              class="font-weight-medium primaryText--text"
            >{{ getRelatedObject(item) }}</b><b class="font-weight-medium primaryText--text">{{ getUpdate(item) }}</b>
          </div>
          <p class="text-caption opaque-7">
            {{ getTime(item) }}
          </p>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { isEmpty, isEmptyArray, isEmptyObj, isNumeric } from '../../api/utilities';
import { ALERT_TYPE_ERROR, GET_VIEW_CONFIG_FOR_ID, SELECT_OBJECT } from '../../store/constants';
import Axios from 'axios';
import { getAccessToken } from '../../store/authorizationContext';
import { API_BASE_URL } from '../../api/constants';


export default {

  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getCurrentEmployeeGuid', 'getObjectsForFilter', 'getLeftMenu']),
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getItems() {

      if (this.object && this.object.Guid) {

        var guid = this.object.Guid
        const options = this.getOptions
        var hasCreated = false
        var activity = this.items.reduce((acc, item) => {
          const delta = item.delta ? JSON.parse(item.delta) : {}
          const newObject = item.newObject ? JSON.parse(item.newObject) : {}
          let isObject = item.objectType === this.getMainObjectType && newObject.Guid === guid
          const type = isEmptyObj(delta) ? 'CREATED' : 'UPDATED'
          if (options && options.noUpdates && type == 'UPDATED') {
            return acc
          }
          if (isObject && type == 'CREATED') {
            if (hasCreated) {
              return acc
            }
            hasCreated = true
          }

          acc.push({
            type,
            newObject,
            delta,
            isObject,
            createTime: item.createTime,
            employee: item.employee,
            objectType: item.objectType
          })
          return acc
        }, [])
        return activity.sort((a, b) => b.createTime - a.createTime)
      }
      return []

    },

    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getMainObjectType() {
      return this.$store.getters.getCurrentSplitcard.objectType
    },
    getViewConfig() {
      return this.$store.getters[GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          return null
        }
      }
      return null
    },

  },
  mounted() {
    if (this.object && this.object.Guid) {
      this.fetchItems()
    }
  },
  watch: {
    object(value) {
      this.fetchItems()
    }
  },
  methods: {
    isEmpty,
    async fetchItems() {
      if (isEmpty(this.object) || isEmpty(this.object.Guid)) {
        return
      }
      this.loading = true
      var config = {
        headers: {}
      };
      var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

      if (isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: error
        });
        return;
      }
      config.headers.Authorization = "Bearer ".concat(bearer);
      let id = this.object.Guid
      if (this.getSubviewConfig && this.object[this.getSubviewConfig]) {
        id = this.object[this.getSubviewConfig]
      }
      const url = `${API_BASE_URL}operations?objectType=${this.getObjectType}&id=${id}`
      Axios.get(url, config).then(response => {
        this.loading = false
        this.items = response.data
      }, error => {
        this.loading = false
        // this.$store.commit("showAlert", {
        //   type: ALERT_TYPE_ERROR,
        //   message: error
        // });
      })
    },
    isNavigationable(item) {
      const elements = this.getLeftMenu.filter(menu => menu.viewConfig === (item.objectType + 's'))
      return !isEmptyArray(elements)
    },
    goTo(item) {
      if (item.isObject === false) {
        var payload = {
          objectType: item.objectType,
          objectId: item.newObject.Guid
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        var payload = {
          splitCardId: item.objectType + 's',
          object: navigationObject
        };
        this.$store.dispatch(SELECT_OBJECT, payload);
      }
    },
    getIcon(item) {
      var objectConfigs = this.$store.getters.getDataObjectsForObjectType("ObjectConfig");
      var objectConfig = objectConfigs.find(config => config.objectType === item.objectType)
      if (!isEmpty(objectConfig)) {
        return objectConfig.icon
      }
      return "hi-cube-outline"
    },
    getTitle(item) {
      if (item.type === 'CREATED') {
        return `created`
      } else {
        return `updated`
      }
    },
    getUpdate(item) {
      if (item.type === 'CREATED') {
        return item.isObject ? 'this' : ``
      } else {
        return `${!item.isObject ? ': ' : ''}${item.delta && Object.keys(item.delta).join(', ')}`
      }
    },
    getRelatedObject(item) {
      if (!item.isObject) {
        return `${this.getFormattedObjectType(item.objectType)}: ${this.getSubject(item)}`
      } else return ''

    },
    getSubject(item) {
      if (!isEmpty(item.newObject.Subject)) return item.newObject.Subject
      else if (!isEmpty(item.newObject.Name)) return item.newObject.Name
      else if (!isEmpty(item.newObject.FullName)) return item.newObject.FullName
      else ''
    },
    getFormattedObjectType(type) {
      if (type) {
        return type.replace(/Main/g, '');
      }
      return type
    },
    getTime(item) {
      const date = item.createTime
      const twoWeeks = moment().subtract(1, 'weeks')
      let newDate = date
      if (isNumeric(date)) {
        newDate = Number(date)
      }
      if (moment(newDate).isBefore(twoWeeks)) {
        return moment(newDate).fromNow()
      }
      return moment(newDate).calendar()
    },


    getEmployeeName(employeeGuid) {
      var employees = this.$store.getters.getDataObjectsForObjectType("EmployeeMain");
      const employee = employees.find(o => o.Guid === employeeGuid);
      if (employee) {
        return employee.FirstName
      }
      return 'An employee'
    },
  }
};
</script>

