<template>
  <v-card
    flat
    class="ma-0 pa-0"
  >
    <v-row color="tileBackgroundColor">
      <v-card
        class="ma-0 pa-0"
        color="tileBackgroundColor"
        width="100%"
        flat
      >
        <viewConfigView
          :object="currentObject"
          :viewconfig="getDetailConfig"
        />
      </v-card>
    </v-row>
    <v-row>
      <v-card
        class="ma-0 pa-0"
        color="tileBackgroundColor"
        width="100%"
        flat
      >
        <viewConfigView
          :object="currentObject"
          :viewconfig="getExtendedConfig"
        />
      </v-card>
    </v-row>
  </v-card>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import * as UTIL from "../../../api/utilities";
export default {
  props: {
    /** 
    object: {
      type: Object,
      default: () => ({})
    },
    */
    /** 
    editmode: {
      type: Boolean,
      default: false
    }
    */
  },
  data() {
    return {
      config: null,
      fields: null
    };
  },
  computed: {
    viewconfig() {
      return this.$store.getters.getConfigurationViewConfig;
    },

    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    title() {
      return this.getViewConfig.title;
    },
    currentObject() {
      var currentConfiguration = this.$store.getters.getConfiguration;
      return currentConfiguration;
    },
    getDetailConfig() {
      if (UTIL.isEmpty(this.getViewConfig)) {
        return "";
      }
      return this.getViewConfig.objectType + "viewConfig2";
    },
    getExtendedConfig() {
      if (UTIL.isEmpty(this.getViewConfig)) {
        return "";
      }
      return this.getViewConfig.objectType + "Extended";
    }
  },
  methods: {
    getObjectConfigForType(type) {
      if (type === "Tabs") {
        return Constants.TAB_CONFIG;
      } else if (type === "Dynamic") {
        return Constants.CARD_CONFIG;
      } else if (type === undefined) {
        return Constants.VIEW_CONFIG;
      }
      return type;
    }
  }
};
</script>
