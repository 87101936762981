<template>
  <v-navigation-drawer
    v-model="rightMenuState"
    :clipped="true"
    :permanent="false"
    :stateless="false"
    :dark="false"
    :disable-route-watcher="false"
    :mini-variant-width="60"
    :temporary="false"
    :width="getWidth"
    :hide-overlay="false"
    :touchless="false"
    :fixed="true"
    disable-resize-watcher
    class="tileBackgroundColor navigationDrawer right-drawer border-left"
    right
    app
    @update:mini-variant="updateMini"
  >
    <v-row class="px-6">
      <v-spacer />
      <v-btn
        icon
        @click="toggleRightDrawer"
      >
        <v-icon>hi-chevron-double-right-outline</v-icon>
      </v-btn>
    </v-row>
    <div
      class="py-2"
      v-if="rightMenuState"
    >
      <v-card
        v-if="isConfigMode"
        transparent
        class="ma-4 pa-3 rounded-lg"
      >
        <configurationBar />
      </v-card>

      <v-card
        class="ma-0 pa-0 transparent"
        flat
      >
        <recentItems />
      </v-card>

      <v-card
        class="ma-0 pa-0 transparent"
        transparent
        flat
      >
        <contextView />
      </v-card>
      <!-- <v-card
      flat
      transparent
      class="ma-0 pl-2 pr-2 opaque-4"
    >
      <v-btn
        icon
        class="float-left"
        @click.native="toggleFixed()"
      >
        <v-icon :color="getColorOfMenu(null)">{{ getPinIcon }}</v-icon>
      </v-btn>
    </v-card> -->

      <!-- <v-card
        v-show="!isMini"
        flat
        class="ma-0 pl-2 pr-2 transparent opaque-4"
      >
        <v-btn
          icon
          class="float-left"
          @click.native="toggleMini()"
        >
          <v-icon :color="getColorOfMenu(null)">{{ getMiniIcon }}</v-icon>
        </v-btn>
      </v-card> -->
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as Api from "../../api/objectApi.js";
import * as Utilities from "../../api/utilities";
import * as Constants from "../../store/constants";
import recentItems from "./Menus/RecentItems";
import * as THEME from "../../theme/themeDefault";
import contextView from "../splitCard/viewComponents/context/contextView";
import configurationBar from "../splitCard/ConfigurationViews/ConfigurationBar.vue";
export default {
  components: { recentItems, contextView, configurationBar },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMini: true
    }
  },
  computed: {
    ...mapGetters({ drawerState: "getRightDrawer", getRightMenuState: 'getRightMenuState', getRightFull: 'getRightFull' }),
    rightMenuState: {
      get() {
        return this.getRightMenuState
      },
      set(value) {
        this.$store.commit('setRightState', value)
      }
    },
    isFixed() {
      return this.drawerState.permanent;
    },
    getMiniIcon() {
      if (this.getRightFull &&
        this.getRightFull === true
      ) {
        return "hi-chevron-double-right";
      }
      return "hi-chevron-double-left";
    },
    getPinIcon() {
      if (Utilities.isTrue(this.isFixed)) {
        return "hi-arrow-down-on-square";
      }
      return "hi-arrow-down-on-square-outline";
    },
    isConfigMode() {
      return this.$store.getters.hasConfiguration;
    },
    getWidth() {
      if (this.isMobile) {
        if (window.innerWidth < 400) {
          return window.innerWidth
        } else return 400

      } else {
        if (window.innerWidth * 0.2 < 400) {
          return 400
        } else return window.innerWidth * 0.2;
      }
    },
  },
  methods: {
    ...mapActions(["toggleRightDrawer"]),
    toggleMini() {
      this.$store.dispatch("toggleSizeRight");
    },
    updateMini(isMini) {
    },
    toggleFixed() {
      this.$store.dispatch("toggleFixedRight");
    },
    setMini() { },
    isTrue(value) {
      return Utilities.isTrue(value);
    },
    getColorOfMenu(item) {
      if (item === null) {
        return THEME.MENU_ITEM_COLOR_RIGHT;
      }
      var currentRoute = this.$store.getters["getCurrentRoute"];
      if (item.viewConfig === currentRoute) {
        return THEME.MENU_ITEM_COLOR_HIGHLIGHT_RIGHT;
      } else {
        return THEME.MENU_ITEM_COLOR_RIGHT;
      }
    }
  }
};
</script>

<style >
.navigationDrawer.right-drawer {
  top: 0px !important;
  max-height: 100vh !important;
}

.navigationDrawer.right-drawer .v-navigation-drawer__content {
  padding-top: 80px;
}


.navigationDrawer.right-drawer .v-navigation-drawer__border {
  background-color: rgba(0, 0, 0, .04) !important;
  width: 2px;
  max-height: 100vh !important;
}
</style>

