<template>
  <v-card
    flat
    color="white"
    class="ma-3 pa-3"
  >
    <!-- <div ref="googleLoginBtn" /> -->
    <v-container>
      <v-row justify="start">
        <google-drive-auth />
        <!-- <google-calendar-auth /> -->
      </v-row>
    </v-container>
  </v-card>
</template>

<script>

import GoogleDriveAuth from './GoogleDriveAuth.vue';

export default {
  components: { GoogleDriveAuth }
}
</script>
