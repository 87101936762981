<template>
  <v-card 
    height="400" 
    flat>
    <v-container 
      fluid 
      grid-list-xl 
      flat>
      <v-layout 
        align-center 
        wrap>
        <v-flex 
          xs12 
          sm6>
          <v-select
            v-model="reasonValue"
            :items="reasonItems"
            attach
            chips
            label="Reasons"
            multiple
          />
        </v-flex>
        <v-flex 
          xs12 
          sm6>
          <v-select
            v-model="productValue"
            :items="productItems"
            attach
            item-color="green"
            chips
            label="Products"
            multiple
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    productItems: [
      "Iphone",
      "Apple TV",
      "MacBook",
      "Ipad",
      "Streaming Service",
      "Server Parks",
      "Apple Watch"
    ],
    productValue: ["MacBook"],
    reasonItems: [
      "Prospecting",
      "Negotiation",
      "Demand/forecast",
      "Market Intelligence",
      "Technical Support",
      "Marketing Support",
      "Customer Follow up",
      "Complaint:"
    ],
    reasonValue: ["Prospecting", "Negotiation", "Market Intelligence"]
  })
};
</script>
