<template>
  <v-container
    class="pa-0 ma-0"
    ref="outerListContainerCard"
    fluid
  >
    <v-row
      flat
      class="pa-1 ma-0 flex-nowrap"
      align="center"
    >
      <v-container
        class="pa-0 ma-0"
        fluid
      >
        <filterView
          :viewconfig="viewconfig"
          :on-search="onSearch"
          :object="object"
        >
          <template v-slot:extra-filters>
            <v-row
              align="center"
              class="pr-2"
            >
              <v-btn
                class="mx-1"
                :class="`${limited ? 'iconButton' : 'primary white--text'}`"
                icon
                @click="toggleLimited"
              >
                <v-icon class="configLight">{{ limited ? 'hi-eye-slash-outline' : 'hi-eye-outline' }}</v-icon>
              </v-btn>
              <v-select
                v-if="!isMobile"
                v-model="selectedProperty"
                :items="getProperties"
                :menu-props="{ bottom: true, offsetY: true, auto: true }"
                item-text="description"
                item-value="field"
                class="text-field-transparent no-border mx-1"
                hide-details
                dense
                solo
                return-object
                offset-y
              />
            </v-row>
          </template>
        </filterView>
      </v-container>

    </v-row>
    <div class="px-2 mb-2">
      <v-select
        v-if="isMobile"
        v-model="selectedProperty"
        :items="getProperties"
        :menu-props="{ bottom: true, offsetY: true, auto: true }"
        item-text="description"
        item-value="field"
        class="text-field-transparent no-border"
        hide-details
        dense
        solo
        return-object
        offset-y
      />
    </div>

    <v-card
      class="py-1 px-1 px-sm-2 transparent"
      flat
    >
      <kanbanView
        v-if="showView"
        :viewconfig="viewconfig"
        :selectionfunction="selectionfunction"
        :selectedproperty="selectedProperty"
        :search="search"
        :height="height"
        :limited="limited"
        @changeLimited="changeLimited"
      />
    </v-card>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import filterView from "./FilterView";
import listViewAction from "./ListViewActions";
import kanbanView from "./KanbanView";

export default {
  components: { listViewAction, filterView, kanbanView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    height: {
      type: String,
      default: "200px"
    }
  },
  data() {
    return {
      fields: undefined,
      search: "",
      selectedProperty: {},
      limited: true,
      /*{
      field: "Stage",
        description: "Stage",
        dropdown: "OrderStageCode"
      
      },
      */
      myListWidth: 0,
      showFilter: true
    };
  },
  computed: {
    getCurrentObject() {
      return this.$store.getters.getCurrentObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFilterIsActive() {
      return this.$store.getters.getFilterActivatedForViewConfig(
        this.viewconfig
      );
    },
    getProperties() {
      var objectConfig = this.$store.getters.getObjectConfigForType(
        this.getObjectType
      );
      if (UTIL.isEmpty(objectConfig)) {
        return [{}];
      }
      var valueFields = objectConfig.fieldsConfig.filter(function (o) {
        return !UTIL.isEmpty(o.dropdown);
      });

      this.setSelectedProperty(valueFields);

      return valueFields;
      return [
        {
          field: "ActiveStatusID",
          description: "Status",
          dropdown: "taskStatusCode"
        },
        {
          field: "CategoryID",
          description: "Category",
          dropdown: "CategoryCode"
        }
      ];
    },
    isMobile() {
      return this.$store.getters.getDeviceSize === 1
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    showView() {
      return !UTIL.isEmpty(this.selectedProperty.field);
    },
    getOptionsDefaultField() {
      if (this.getOptions) {
        return this.getOptions.defaultField
      }
      return null
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
  },

  /**
    We set the default filter for the viewConfig
   */
  methods: {
    changeLimited(value) {
      this.limited = value
    },
    onSearch(input) {
      this.search = input
    },
    toggleLimited() {
      this.limited = !this.limited
    },
    setSelectedProperty(valueFields) {
      if (
        !UTIL.isEmpty(valueFields) &&
        valueFields.length > 0 &&
        UTIL.isEmpty(this.selectedProperty.field)
      ) {
        var stageValue = null
        const defaultField = this.getOptionsDefaultField
        if (defaultField) {
          stageValue = valueFields.find(function (o) {
            return o.field === defaultField;
          });
        }

        if (!UTIL.isEmpty(stageValue)) {
          this.selectedProperty = stageValue;
        } else {
          this.selectedProperty = valueFields[0];
        }
      }
    },
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
