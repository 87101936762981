<template>
  <v-card
    class="ma-0 pa-0 transparent"
    flat
  >
    <v-card
      flat
      class="ma-0 pa-0 transparent"
    >
      <v-row
        class="transparent px-5 py-1"
        align="center"
        no-gutters
        flat
      >
        <v-icon class="mr-2">hi-squares-2x2-outline</v-icon>
        <h2>
          Menu
        </h2>
      </v-row>
      <viewConfigView
        :in-card="false"
        viewconfig="RightMenuView"
      />
    </v-card>
  </v-card>
</template>
<script>


import * as Constants from "../../../../store/constants.js";
import * as Utilities from "../../../../api/utilities.js";
import contextactions from "./contextActions";
import contextconnections from "./contextConnections";
import contextnavigations from "./contextNavigations";

export default {
  components: { contextactions, contextconnections, contextnavigations },
  props: {
    viewconfig: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogTitle: "Title"
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    }
  },
  methods: {
    closeDialog() {
      this.dialog.show = false;
    }
  }
};
</script>
