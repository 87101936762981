import Hello from "../components/Hello";
import SplitCard from "../components/splitCard/SplitCard.vue";
import Home from "../components/components/Home.vue";
import DynamicCard from "../components/splitCard/DynamicCard.vue";
import Calendar from "../components/calendar/CalendarContainer.vue";

/**
 * Build menu Items
 * @param {} menuItems
 */

export const buildMenuItems = (menuItems) => {
  menuItems.forEach((menuItem) => {
    buildMenuItem(menuItem);
  });
};

export const buildMenuItem = (menuItem) => {
  menuItem.href = "/" + menuItem.viewConfig;
  menuItem.router = true;
};

export const buildRoutes = (menuItems) => {
  var routes = [];
  menuItems.forEach((menuItem) => {
    routes.push(buildRoute(menuItem));
  });
  return routes;
};

export const buildRoute = (menuItem) => {
  var path = "/" + menuItem.viewConfig;
  var component = getComponent(menuItem.component);
  var route = {
    path: path,
    name: menuItem.viewConfig,
    component: component,
  };
  return route;
};

export const getComponent = (component) => {
  var components = {
    Hello: Hello,
    SplitCard: SplitCard,
    Home: Home,
    DynamicCard: DynamicCard,
    Calendar: Calendar,
  };
  return components[component];
};
