<template>
  <v-card
    ref="_details"
    class="ma-0 pa-0 transparent"
    flat
  >
    <v-container
      class="ma-0 pa-0"
      fluid
    >

      <v-row
        row
        justify-between
        no-gutters
      >
        <v-col
          v-for="field in getFields"
          :key="calculateKey(field)"
          :cols="getWidth(field)"
          class="py-1"
        >
          <GenericInputSimple
            :object="currentObject"
            :header="field"
            :viewconfig="viewconfig"
            :showlabel="false"
            :readonly="true"
            type="kanban"
            solo
            dense
            navigateidlink
          />
        </v-col>
      </v-row>

      <!-- <v-row class="pb-1">
        <v-card
          v-if="currentObject && currentObject.Subject"
          class="text-subtitle-2 font-weight-mediumBold transparent"
          plain
          flat
          @click="clickField(getField('Subject'), currentObject)"
        >
          {{ currentObject.Subject }}
        </v-card>
      </v-row>
      <v-row class="pt-2 pb-1">
        <v-card
          v-if="currentObject && currentObject.AccountGuid"
          class="text-subtitle-2 font-weight-mediumBold transparent"
          plain
          flat
          @click="clickField(getField('AccountGuid'), currentObject)"
        >
          <v-container
            class="mx-0 my-0"
            fluid
          >
            <v-row
              align="center"
              class="flex-nowrap"
            >

              <v-avatar
                v-if="getImage(getField('AccountGuid'), currentObject) !== null || getIcon(getField('AccountGuid')) !== null"
                size="16px"
                class="mr-1"
              >
                <img
                  v-if="getImage(getField('AccountGuid'), currentObject) !== null"
                  :src="getImage(getField('AccountGuid'), currentObject)"
                  alt="Avatar"
                >
                <v-icon
                  v-else
                  small
                  color="primary"
                >
                  {{ getIcon(getField('AccountGuid')) }}
                </v-icon>
              </v-avatar>
              <span class="text-caption font-weight-medium primary--text ml-1">
                {{ getValue(getField('AccountGuid'), currentObject) }}
              </span>
            </v-row>
          </v-container>
        </v-card>
        <v-chip
          v-if="showStatus === true"
          :color="getColor('Status')"
          class="mb-1"
          dark
          x-small
        >
          {{ getDescr('Status') }}
        </v-chip>
      </v-row>

      <v-row align="center">

        <v-chip
          v-if="getUrgency !== null"
          :color="getUrgency"
          small
        >
          <v-icon
            small
            class="mr-1"
          >hi-calendar-days-outline</v-icon>
          {{ getUrgency === ALERT_TYPE_WARNING ? 'Today' : getDueDate | moment("from", "now") }}
        </v-chip>
        <v-row
          v-else
          align="center"
          class="text-caption ma-0"
        >
          <v-icon
            small
            class="mr-1"
          >hi-calendar-days-outline</v-icon>
          {{ getDueDate | moment("from", "now") }}</v-row>
        <v-spacer />
        <v-card
          v-if="currentObject && currentObject.Processor"
          class="text-subtitle-2 font-weight-mediumBold transparent"
          plain
          flat
          @click="clickField(getField('Processor'), currentObject)"
        >
          <v-container
            class="ma-0 pa-0"
            fluid
          >
            <v-avatar
              v-if="getImage(getField('Processor'), currentObject) !== null || getIcon(getField('Processor')) !== null"
              size="16px"
              class=""
            >
              <img
                v-if="getImage(getField('Processor'), currentObject) !== null"
                :src="getImage(getField('Processor'), currentObject)"
                alt="Avatar"
              >
              <v-icon
                v-else
                small
                color="primary"
              >
                {{ getIcon(getField('Processor')) }}
              </v-icon>
            </v-avatar>
            <span class="text-caption primary--text ">
              {{ getValue(getField('Processor'), currentObject) }}
            </span>
          </v-container>
        </v-card>
      </v-row> -->

    </v-container>
  </v-card>
</template>
<script>
import * as UTIL from "../../../api/utilities";
import { ALERT_TYPE_WARNING, ALERT_TYPE_ERROR, DATE_FORMAT, SELECT_OBJECT, TASK_MAIN, TASK_COMPLETED, TASK_CANCELED, ORDER_MAIN } from '../../../store/constants';
import moment from 'moment';
import GenericInputSimple from "../GenericInputSimple.vue";


export default {
  name: "KabanView",
  components: { GenericInputSimple },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    fields: {
      type: Array,
      default: [] //() => ({})
    },
    editmode: {
      type: Boolean,
      default: undefined
    },
    operationid: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    },
    fieldsize: {
      type: String,
      default: "1"
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null,
      totalWidth: 0,
      myDetailWidth: 0,
      keyObject: {
        key: 1
      },
      ALERT_TYPE_WARNING,
      ALERT_TYPE_ERROR
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
    },
    getFields() {
      return this.fields;
    },
    title() {
      return this.getViewConfig.title;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    currentObject() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(this.operationid);
        return operationContainer.newObject;
      }
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getMode() {
      if (this.isReadOnly) {
        return "display";
      }
      return "edit";
    },
    isReadOnly() {
      return !this.hasCurrentOperation;
    },
    getUrgency() {
      const date = this.getDueDate;
      const today = moment().format(DATE_FORMAT);
      if (this.getViewConfig.objectType === TASK_MAIN && (this.currentObject.ActiveStatusID === TASK_COMPLETED || this.currentObject.ActiveStatusID === TASK_CANCELED))
        return null;
      if (moment(date, DATE_FORMAT).isAfter(today, "day")) {
        return null;
      }
      else if (moment(date, DATE_FORMAT).isSame(today, "day")) {
        return ALERT_TYPE_WARNING;
      }
      return ALERT_TYPE_ERROR;
    },
    showStatus() {
      if (this.getViewConfig.objectType === ORDER_MAIN && !UTIL.isEmpty(this.currentObject.Status)) {
        return true;
      }
      return null;
    },
    getDueDate() {
      if (!UTIL.isEmpty(this.currentObject.StageDate))
        return moment(this.currentObject.StageDate).endOf("day");
      return moment(this.currentObject.Due).endOf("day");
    },
  },
  methods: {
    getIcon(field) {
      if (field && !UTIL.isEmpty(field.icon))
        return field.icon;
      return null;
    },
    getWidth(field) {
      // if (this.myDetailWidth < 300) {
      //   return "12";
      // }
      // if (field.type === "image" || field.type === "textEdit") {
      //   return "12";
      // }
      // if (UTIL.isEmpty(field.width)) {

      //   if (this.myDetailWidth > 1200) {
      //     return "3";
      //   }
      //   return "6";
      // }
      // // field width 11 is exception set full width, this so we can set bigger widths in listview for 12 and 11
      // if (field.width === 11) {
      //   return "12"
      // }
      return field.width;
    },
    getValue(field, item) {
      var filteredObjects = this.$store.getters.getDataObjectsForObjectType(field.relatedObjectType);
      if (!UTIL.isEmpty(filteredObjects)) {
        const object = filteredObjects.find(o => o.Guid === item[field.value]);
        if (object && object[field.displayRelatedObject]) {
          return object[field.displayRelatedObject];
        }
      }
      return null;
    },
    getValueListItem(field) {
      const listCode = this.getField(field).dropdown;
      const value = this.currentObject["Status"];
      return this.$store.getters.getValueListForId(listCode).find(item => item.value === value);
    },
    getDescr(field) {
      const item = this.getValueListItem(field);
      if (item && item.descr) {
        return item.descr;
      }
      return null;
    },
    getValueListIcon(field) {
      const item = this.getValueListItem(field);
      if (item && item.icon) {
        return item.icon;
      }
      return null;
    },
    getColor(field) {
      const item = this.getValueListItem(field);
      if (item && item.color) {
        return item.color;
      }
      return null;
    },
    getField(field) {
      var index = this.getFields.map(item => item.value).indexOf(field);
      if (index > -1) {
        return this.getFields[index];
      }
      return {};
    },
    clickField: function (field, object) {
      if (!this.isLink(field)) {
        return;
      }
      if (!this.isReadOnly) {
        return;
      }
      var navigationObject = object;
      if (field.relatedObjectType !== undefined &&
        field.navigationId !== null) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(payload);
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(SELECT_OBJECT, payload);
    },
    isLink(field) {
      if (!UTIL.isEmpty(field.navigationConfigId) && this.readonly === true) {
        return true;
      }
      if (field.type === "link") {
        return true;
      }
      if (field.type === "idLink") {
        return true;
      }
      return false;
    },
    getHeight(field) {
      if (field.type === "image") {
        return 200;
      }
      if (field.type === "textEdit") {
        return UTIL.pixelStringToInt(this.height);
      }
      return 45;
    },
    hasImage(header) {
      if (header.text === "FirstName") {
      }
      return !UTIL.isEmpty(header.image);
    },
    getImage(field, object) {
      if ((field.image !== undefined && field.image !== null) ||
        field.type === "image") {
        if (object.thumbnail) {
          return object.thumbnail;
        }
        else if (object.image) {
          return object.image;
        }
      }
      return null;
    },
    calculateKey(field) {
      var key = this.keyObject.key.toString().concat(field.Guid); //This must be done to update the view on aftermodify
      if (key.includes("undefined")) {
        console.log("wrong key", field);
      }
      return key;
    },
    calculateSpacer(field) {
      //This does not work
      return " addMargin ";
    },
  },
  components: { GenericInputSimple }
};
</script>

<style>
.addMargin {
  margin-right: 8%;
}
</style>
