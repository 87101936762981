<template>
  <v-card flat>
    <!-- {{ calculatedSelectedFilter() }} -->
    <v-select
      :items="getFilters"
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
      v-model="selectedFilter"
      item-text="title"
      item-value="filterConfigId"
      class="noline filterTitle"
      return-object
    />
  </v-card>
</template>
<script>
// import * as Constants from "../../store/constants.js";
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedFilter: {},
      oldSelectedFilter: null
    };
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    /**
     * Retrieve the current Filters for the view config.
     */
    getFilterId() {
      return this.getViewConfig.queryFilter;
    },
    getFilters() {
      return this.$store.getters.getFilterConfigsForFilterId(this.getFilterId);
    },
    /**
     * Returns the current filter from the store.
     */
    getCurrentFilter() {
      return this.$store.getters.getCurrentFilterForFilterId(this.getFilterId);
    }
  },
  /**
   * When the filter started we take the selected value.
   */
  mounted: function () {
    return;
    if (this.getCurrentFilter.queryFilter !== undefined) {
      this.selectedFilter = this.getCurrentFilter;
      this.oldSelectedFilter = this.selectedFilter;
      return;
    }
    var defaultFilter = this.$store.getters.getDefaultFilterConfigsForFilterId(
      this.getFilterId
    );
    if (defaultFilter === undefined) {
      defaultFilter = {};
    }
    var payload = {
      filter: defaultFilter,
      viewConfig: this.getFilterId
    };
    this.selectedFilter = defaultFilter;
    this.calculatedSelectedFilter();
  },
  methods: {
    /**
     * Calculate the selected filter.
     * When selected the store must be updated. It compares the old value with the new one.
     
    calculatedSelectedFilter() {
      if (this.oldSelectedFilter !== this.selectedFilter) {
        this.oldSelectedFilter = this.selectedFilter;
        var payload = {
          filter: this.selectedFilter,
          viewConfig: this.getFilterId
        };
        this.$store.commit(Constants.SET_CURRENT_FILTER, payload);
      }
    }
    */
  }
};
</script>
