<template>
  <div :style="height && `height: 100%`">
    <!-- <embed
      src="https://scribehow.com/embed/Guide_How_to_Create_an_Installation_for_OrderMains__zzdgyBarSEGmeoVc7pySXA?as=scrollable"
      width="640"
      height="640"
      allowfullscreen
      crossorigin="anonymous"
      frameborder="0"
    ></embed> -->
    <iframe
      :src="getUrl"
      width="100%"
      height="100%"
      allowfullscreen
      allow="cross-origin-isolated"
      crossorigin="anonymous"
      credentialless
      frameborder="0"
    ></iframe>

  </div>
</template>

<script>
import axios from 'axios';
import { isEmpty, isTrue } from '../../api/utilities';
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: () => ({})
    },
    height: {
      type: String,
      default: null
    },
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    currentObject() {
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {

        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getUrl() {
      var options = this.getOptions
      if (!isEmpty(options)){
        var params = options.params
        var url = options.url
        if (isEmpty(url)){
          return this.object && this.object.Url
        }
        for (var i = 0; i < params.length; i++)
        {
          var param = params[i]
          if (!isEmpty(param.field) && !isEmpty(param.id)){
            var value = null
            if (!isEmpty(this.currentObject))
            value = this.currentObject[param.field]
            if (isEmpty(value) && isTrue(param.mandatory)){
              return ""
            } else {
              url = url.replace(param.id, value)
            }
          }
        }
        return url
      } else {
        return this.currentObject && this.currentObject.Url
      }
    }
  },

}
</script>

<style lang="scss" scoped></style>