<template>
  <v-dialog
    v-model="getLoadingDialog"
    :content-class="`w-auto rounded-lg ${getFullScreen && 'w-screen h-screen'}`"
    :fullscreen="getFullScreen"
    transition="false"
  >
    <v-card
      id="loadingview"
      class="d-flex justify-center align-center px-8 py-6 rounded-lg"
      :class="getFullScreen && 'w-screen h-screen'"
      flat
    >
      <div class="d-flex flex-column gap justify-center align-center">
        <v-progress-circular
          :width="4"
          background-color="sidebarColor"
          color="primary"
          indeterminate
        >

        </v-progress-circular>
        <span
          v-if="getLoadingMessage"
          class="text-body-2 py-1"
        >{{ getLoadingMessage }}</span>
      </div>
      <!-- <div class="text-center text-body-2 gray--text opaque-5 py-2 font-weight-medium"> {{ getProgress }} %</div> -->
    </v-card>
    <!-- <v-card>
      <v-card-title class="text-h5">
        Are you sure you want to reset?
      </v-card-title>
      <v-card-text>
        All your local saved changes will be lost
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="clearDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="error"
          @click="clearData"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card> -->
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isResetButton: false,
      clearDialog: false
    };
  },
  computed: {
    ...mapGetters(['getLoadingDialog', 'getLoadingMessage', 'getFullScreen']),
  },
  mounted() {

  },
  methods: {

  }
};
</script>
<style scoped>
.progress-circular-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottomInfo {
  position: absolute;
  bottom: 60px;
}</style>
