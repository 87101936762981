import { isEmpty } from "../../api/utilities.js";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import {
  OBJECT_CONFIG,
  SYNC_GROUP_CORE_REST,
  SYNC_GROUP_CORE_FIELDS,
  SYNC_GROUP_CORE_OBJECTS,
  SYNCHRONISATION,
  OPERATION,
  TRANSPORT_MAIN,
  TRANSPORT_DOMAIN_ITEM,
  TRANSPORT_ITEM,
  CLIENT_ATTRIBUTES,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR,
  VALUE_LIST_CONFIG,
  AUTHORISATION,
  AUTH_GROUP
} from "../../store/constants";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  var title = object.objectType + object.authorisation;
  Vue.set(object, "title", title);
  afterModify(objectConfig, object, null, context, options);
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  Vue.set(object, "isActive", true);
  return object;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options
) {
  return true;
}

/***
 * createAuthorisationsForCRMObjects
 */
export async function createAuthorisationsForCRMObjects(
  objectConfig,
  object,
  viewconfig,
  context,
  options
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "createAuthorisationsForCRMObjects",
      objectConfig,
      object,
      viewconfig,
      context,
      options
    )
  ) {
    return;
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Starting Creating Authorisations..."
  });
  //Find object and create Transport
  var objectConfigConfig = context.getters.getObjectConfigForType(
    OBJECT_CONFIG
  );
  var objects = context.getters.getDataObjectsForObjectType(OBJECT_CONFIG);
  var crmObjects = objects.filter(function(o) {
    return (
      o.syncGroup === "SYNC_GROUP_CRM" || o.syncGroup === "SYNC_GROUP_SALES"
    );
  });
  for (var i = 0; i < crmObjects.length; i++) {
    var crmObject = crmObjects[i];
    await context.dispatch("launchActionForObjectAndConfig", {
      action: "createAuthorisationForCRMObject",
      objectConfig: objectConfigConfig,
      object: crmObject,
      context: context,
      options: { showErrorMessage: false }
    });
  }

  //Update The Status of the object
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Authorisations Created."
  });
}

/***
 * createAuthorisationsForAuthorisations
 */
export async function createAuthorisationsForAuthorisations(
  objectConfig,
  object,
  viewconfig,
  context,
  options
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "createAuthorisationsForCRMObjects",
      objectConfig,
      object,
      viewconfig,
      context,
      options
    )
  ) {
    return;
  }
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Starting Creating Authorisations..."
  });
  var authorisations = context.getters.getDataObjectsForObjectType(
    AUTHORISATION
  );
  var authorisationConfig = context.getters.getObjectConfigForType(
    AUTHORISATION
  );
  var valueLists = context.getters.getDataObjectsForObjectType(
    VALUE_LIST_CONFIG
  );
  var authList = valueLists.filter(function(o) {
    return o.valueList === "AuthorisationsCode";
  });
  for (var i = 0; i < authList.length; i++) {
    var auth = authList[i];

    var authorisationExists = authorisations.find(function(o) {
      return o.object === auth.value;
    });
    if (!isEmpty(authorisationExists)) {
      //authorisation already exist
      continue;
    }
    var authorisation = ObjectApi.newObjectWithGuid();
    authorisationConfig.afterCreate(authorisation, object, context, {
      objectType: AUTH_GROUP
    });
    Vue.set(authorisation, "object", auth.value);
    Vue.set(authorisation, "domain", "ConfigDomain");
    await context.dispatch("saveObjectForObjectType", {
      objectType: AUTHORISATION,
      object: authorisation
    });
  }
  //Update The Status of the object
  context.commit("showAlert", {
    type: ALERT_TYPE_INFO,
    message: "Authorisations Created."
  });
}
