<template>
  <v-container
    v-if="isConfigMode"
    class="ma-0 pa-0 fluid"
  >
    <v-container
      class="ma-0 pa-0 fluid"
      fluid
    >
      <p class="text--disabled ">
        <v-btn
          icon
          class=" configLight "
          @click.native="editConfiguration()"
          @dblclick.native="goToConfiguration()"
        >
          <v-icon>{{ getConfigIcon }}</v-icon>
        </v-btn>
        <v-btn
          icon
          class=" configLight "
          @click.native="addCardConfiguration()"
        >
          <v-icon>hi-cog-6-tooth-outline</v-icon>
        </v-btn>
        <v-btn
          icon
          class=" configLight "
          @click.native="goToConfiguration()"
          @dblclick.native="goToConfiguration()"
        >
          <v-icon>hi-arrow-up-right</v-icon>
        </v-btn>

        <v-btn
          v-if="showAddButton"
          class=" configLight "
          icon
          @click.native="addConfiguration()"
        >
          <v-icon>hi-plus</v-icon>
        </v-btn>
        {{ getTitle }}
      </p>
    </v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-container
          v-if="hasSelectionView"
          fluid
        >
          <v-card
            class="d-flex justify-space-between ma-0 pa-3"
            color="primary"
          >
            <h1>{{ dialogTitle }}</h1>
            <v-btn
              icon
              dense
              @click.native="cancelSelection()"
            >
              <v-icon>hi-x-mark</v-icon>
            </v-btn>
            <!--<v-card 
              flat
              color="primary"
              class="ma-0 pa-3 float-right">
              
            </v-card>-->
          </v-card>
          <detailsView
            :viewconfig="viewConfigOfSelection"
            :object="selectedObject"
            :editmode="true"
            :key="componentKey"
          />
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import * as Constants from "../../../store/constants.js";
import detailsView from "./ConfigurationConfigView";
import * as Utilities from "../../../api/utilities";
import { newObjectWithGuid } from '../../../api/objectApi.js';

export default {
  components: { detailsView },
  props: {
    viewconfigid: {
      type: String,
      default: ""
    },
    viewconfig: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      viewConfigOfSelection: "",
      dialog: false,
      savingType: "",
      dialogTitle: "",
      componentKey: 0
    };
  },
  computed: {
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.viewconfig);
    },
    getConfigIcon() {
      var objectConfig = this.$store.getters.getObjectConfigForType(this.type);
      if (objectConfig !== undefined) {
        return objectConfig.objectConfig.icon;
      }
      return "";
    },
    getObjectId() {
      if (this.type === Constants.VIEW_CONFIG) {
        return this.viewconfig.viewConfig;
      }
      if (this.type === Constants.TAB_CONFIG) {
        return this.viewconfig.tabConfig;
      }
      if (this.type === Constants.CARD_CONFIG) {
        return this.viewconfig.cardConfig;
      }
      return "";
    },
    getTitle() {
      if (this.type === Constants.VIEW_CONFIG) {
        return this.viewconfig.type + " : " + this.viewconfig.viewConfig;
      }
      return this.getObjectId;
    },
    showAddButton() {
      var objectType = this.getObjectConfigForType(this.viewconfig.type);
      if (this.viewconfig.viewConfig === "ContactMainActivitiesRelated") {
        console.log("type", objectType, this.type);
      }
      if (
        this.type === Constants.VIEW_CONFIG &&
        (objectType === Constants.CARD_CONFIG ||
          objectType === Constants.TAB_CONFIG)
      ) {
        return true;
      }
      return false;
    },
    hasSelectionView() {
      return this.viewConfigOfSelection.length > 1;
    },
    getCurrentOperationContainer() {
      return this.$store.getters.getCurrentOperationContainer;
    }
  },
  methods: {
    goToConfiguration() {
      var payload = {
        splitCardId: this.type + "s",
        object: this.viewconfig
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    getObjectConfigForType(type) {
      if (type === "Tabs") {
        return Constants.TAB_CONFIG;
      } else if (type === "Dynamic") {
        return Constants.CARD_CONFIG;
      } else if (type === undefined) {
        return Constants.VIEW_CONFIG;
      }
      return type;
    },
    /**
     * Get Tab Config
     */
    getConfigForType(type) {
      var config = this.$store.getters.getObjectConfigForType(type);
      var object = {
        viewConfig: this.viewconfig.subViewConfig
      };
      var payload = {
        config: config,
        object: object
      };
      return payload;
    },
    /*
    addViewConfig() {
      var config = this.$store.getters.getObjectConfigForType(
        Constants.VIEW_CONFIG
      );
      var object = {
        viewConfig: this.viewconfigid
      };
      var payload = {
        config: config,
        object: object
      };
      return payload;
    },
    */
    editConfiguration() {
      if (this.type === Constants.VIEW_FIELD) {
        this.editConfigurationViewField();
        return;
      }
      this.editConfigurationViewConfig();
    },
    editConfigurationViewField() {
      this.selectedObject = this.viewconfig; //the viewField
      this.savingType = this.type;
      this.viewConfigOfSelection = this.savingType.concat("ViewDetailsconfig");
      this.dialogTitle = "Edit ".concat(this.savingType);
      this.dialog = true;
    },
    editConfigurationViewConfig() {
      this.showConfigurationInsideBar();
      return;
    },
    showConfigurationInsideBar() {
      if (this.viewconfig === undefined || this.viewconfig === null) {
        this.viewconfig;
        this.selectedObject = {
          viewConfig: this.viewconfigid
        };
      } else {
        this.selectedObject = this.viewconfig;
        if (
          this.viewconfig.viewConfig === undefined ||
          this.viewconfig.viewConfig === null
        ) {
          this.selectedObject.viewConfig = this.viewconfigid;
        }
      }
      var payload = {
        configurationViewConfig: this.type.concat("ViewDetailsconfig"),
        configuration: this.selectedObject
      };
      this.$store.dispatch("goToConfiguration", payload);
    },
    /**
     * Open Dialog
     */
    addConfiguration() {
      this.componentKey = this.componetKey = Utilities.getGuid();
      this.savingType = this.getObjectConfigForType(this.viewconfig.type);
      this.viewConfigOfSelection = this.savingType.concat("ViewDetailsconfig");
      this.dialogTitle = "Add ".concat(this.savingType);
      var newObject = newObjectWithGuid()
      Vue.set(newObject, "viewConfig", this.viewconfig.subViewConfig)
      this.selectedObject = savingType.afterCreate(newObject, null, this.$store);

      this.dialog = true;
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      // this.selectedField = null;
      // this.viewConfigOfSelection = null;
      // this.selectionFunction = null;
      // this.dialog = false;
    }
  }
};
</script>
