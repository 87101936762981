import * as Constants from "./constants";
import * as Api from "../api/objectApi.js";
import * as ViewConfigGenerator from "../api/viewConfigGenerator.js";
import * as Util from "../api/utilities.js";

import Vue from "vue";

/**
 * App State
 */

export default {
  state: {
    states: {},
    status: 0,
    currentObjects: {},
    splitCardStates: {},
    currentDialog: null,
  },
  mutations: {
    setCurrentObject(state, object) {
      var splitCard = getters.getCurrentRoute;
      Vue.set(state.currentObjects, splitCard, object);
    },
    setCurrentObjectForSplitCard(state, payload) {
      Vue.set(state.currentObjects, payload.splitCard, payload.object);
    },
    /**
     * Set the splitcardState for the value
     * @param {*} state
     * @param {*} payload : splitCardId, value
     */
    setStateForSplitCard(state, payload) {
      Vue.set(state.splitCardStates, payload.splitCardId, payload.value);
    },
    setCurrentDialog(state, payload) {
      state.currentDialog = payload;
    },
  },
  getters: {
    getSplitCards: (state, getters) => {
      return getters.getDataObjectsForObjectType(Constants.SPLIT_CARD);
    },
    getCurrentDialog: (state, getters) => {
      return state.currentDialog;
    },
    getCurrentObject: (state, getters) => {
      var splitCard = JSON.parse(JSON.stringify(getters.getCurrentRoute));
      if (!Util.isEmpty(getters.getCurrentDialog)) {
        var dialogSplitCardstate = getters.getStateForSplitCard(
          getters.getCurrentDialog,
        );
        console.log(
          "dialogSplitCardstate",
          dialogSplitCardstate,
          state.currentObjects[dialogSplitCardstate],
        );
        if (
          dialogSplitCardstate &&
          dialogSplitCardstate > 0 &&
          !Util.isEmpty(state.currentObjects[getters.getCurrentDialog])
        ) {
          splitCard = getters.getCurrentDialog;
        }
      }
      // var splitCard = Util.isEmpty(getters.getCurrentDialog)
      //   ? JSON.parse(JSON.stringify(getters.getCurrentRoute))
      //   : getters.getCurrentDialog;
      const id = splitCard === "ObjectConfigs" ? "ObjectType" : "Guid";
      var guid =
        state.currentObjects[splitCard] && state.currentObjects[splitCard][id];
      if (guid) {
        if (!Util.isEmpty(splitCard)) {
          splitCard = splitCard.substring(0, splitCard.length - 1);
        }
        const object = getters.getDataObjectForIdAndObjectType({
          objectType: splitCard,
          objectId: guid,
        });
        console.log("currentObject", guid, splitCard, object);
        return object;
      }
      return state.currentObjects[splitCard];
    },

    getObjectForSplitcard: (state, getters) => (splitcard) => {
      return state.currentObjects[splitcard];
    },
    getSplitCardForViewConfig: (state, getters) => (splitCard) => {
      return getters.getSplitCards.find(function (o) {
        return o.splitCard === splitCard;
      });
    },
    getCurrentSplitcard(state, getters) {
      if (getters.getCurrentDialog) {
        return getters.getDialogSplitcard(getters.getCurrentDialog);
      }
      var viewConfig = getters.getCurrentRoute;
      if (viewConfig === undefined || getters.getSplitCards === undefined) {
        return {};
      }
      return getters.getSplitCards.find(function (o) {
        return o.splitCard === viewConfig;
      });
    },
    getDialogSplitcard: (state, getters) => (dialog) => {
      var viewConfig = dialog;
      if (viewConfig === undefined || getters.getSplitCards === undefined) {
        return {};
      }
      return getters.getSplitCards.find(function (o) {
        return o.splitCard === viewConfig;
      });
    },
    getCurrentSplitCardObjectType(state, getters) {
      if (
        getters.getCurrentSplitcard &&
        getters.getCurrentSplitcard.objectType
      ) {
        return getters.getCurrentSplitcard.objectType;
      }
      return null;
    },
    getGeneratedSplitCard: (state, getters) => (objectType) => {
      console.log("Generate for objectType: ", objectType);
      var objectConfig = getters.getObjectConfigForType(objectType);
      var generated = ViewConfigGenerator.splitCardGenerator(objectConfig);
      console.log("generated: ", generated);
      return generated;
    },
    getStateForSplitCard: (state, getters) => (splitCardId) => {
      if (Util.isEmpty(state.splitCardStates[splitCardId])) {
        return 0;
      }
      return state.splitCardStates[splitCardId];
    },
  },
  actions: {
    /**
     * Change the state of the splitCard: 0, 4, 8, 12
     * How to handle: not clear
     * @param {} param0
     * @param {*} object
     */
    changeViewState({ dispatch, commit, getters }, value) {
      var splitCard = Util.isEmpty(getters.getCurrentDialog)
        ? getters.getCurrentSplitcard
        : getters.getDialogSplitcard(getters.getCurrentDialog);
      if (splitCard === undefined) {
        return;
      }
      var objectConfig = getters.getObjectConfigForType(Constants.SPLIT_CARD);
      var payload = {
        splitCardId: splitCard.splitCard,
        value: value,
      };
      commit("setStateForSplitCard", payload);
      //commit(Constants.SET_OBJECT, { payload, getters });
    },
    /**
     * Select to object and navigate to this view.
     * @param {} param0
     * @param {*} payload
     */
    selectObject({ dispatch, commit, getters }, payload) {
      var splitCard;
      if (payload.splitCardId === undefined) {
        splitCard = getters.currentSplitCard;
      } else {
        splitCard = getters.getSplitCardForViewConfig(payload.splitCardId);
      }
      var object = payload.object;
      var objectConfig = getters.getObjectConfigForType(splitCard.objectType);
      var newSplitCard = Api.copyObject(splitCard);
      var list = getters.getDataObjectsForObjectType(splitCard.objectType);
      //var object = objectConfig.getObjectForIdInList(list, objectId);
      var value = 0;
      if (getters.getStateForSplitCard(newSplitCard.splitCard) === 0) {
        if (getters.getDeviceSize === 1) {
          value = 4;
        } else {
          value = 12;
        }
        var newSplitCardState = {
          splitCardId: newSplitCard.splitCard,
          value: value,
        };
        commit("setStateForSplitCard", newSplitCardState);
      }
      var splitCardConfig = getters.getObjectConfigForType(
        Constants.SPLIT_CARD,
      );
      var payload = {
        object: newSplitCard,
        objectConfig: splitCardConfig,
      };
      commit(Constants.SET_OBJECT, { payload, getters });
      var setObjectPayload = {
        object: object,
        splitCard: splitCard.splitCard,
        config: objectConfig,
      };
      dispatch("addNavigation", setObjectPayload);
      commit("setCurrentObjectForSplitCard", setObjectPayload);
      if (Util.isEmpty(splitCard.dialog)) {
        dispatch("changeToRoute", splitCard.splitCard);
        dispatch("hidePinnedDialog");
      } else {
        dispatch("changeToRouteDialog", splitCard.dialog);
      }
      //
    },
    /**
     * Create the inite view config to set the db_object config.
     * @param {*} param0: nothing to be passed.
     */
    async createInitViewConfig({ dispatch, commit, getters }) {
      var config = Constants.OBJECT_CONFIG;
      await dispatch("createSplitCardConfigs", config);
    },

    /**
     * Create all objects needed for the splitcard.
     */
    async createSplitCardConfigs({ dispatch, commit, getters }, payload1) {
      // Check if the basic config already exist.
      var config = payload1;
      console.log("createSplitCard", payload1);
      var generatedConfigs = getters.getGeneratedSplitCard(config); //todo replace
      var objectTypes = [
        Constants.SPLIT_CARD,
        Constants.VIEW_CONFIG,
        Constants.VIEW_FIELD,
        Constants.CARD_CONFIG,
        Constants.TAB_CONFIG,
        Constants.ACTION_CONFIG,
        Constants.MENU_ITEM,
        Constants.FILTER_CONFIG,
        Constants.FILTER_FIELD_CONFIG,
      ];
      var objectConfig;
      var objectType = "";
      for (var i = 0; i < objectTypes.length; i++) {
        objectType = objectTypes[i];
        objectConfig = getters.getObjectConfigForType(objectType);
        //Operation maken.
        var objects = generatedConfigs[objectConfig.objectType];
        for (var j = 0; j < objects.length; j++) {
          var object = objects[j];
          var payload = {
            object: object,
            config: objectConfig,
          };
          await dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);
          await dispatch(
            Constants.SAVE_OPERATION_CONTAINER,
            getters.getCurrentOperationContainer,
          );
        }
        dispatch("loadLeftMenu");
      }
    },
  },
};
