
import { getGuid, isEmpty } from "../../api/utilities.js";
import Vue from "vue";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  if (isEmpty(options) || options.fromSync !== true) {
    afterModify(objectConfig, object, null, context, options);
  }
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  Vue.set(object, "filterConfigId", getGuid());
  Vue.set(object, "filterFields", getGuid());
  if (!isEmpty(relatedObject) && !isEmpty(relatedObject.object)) {
    Vue.set(object, "auth", relatedObject.object.auth);
  }
  return object;
};

