import { isEmpty } from "../../api/utilities.js";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import {
  OBJECT_CONFIG,
  SYNC_GROUP_CORE_REST,
  SYNC_GROUP_CORE_FIELDS,
  SYNC_GROUP_CORE_OBJECTS,
  SYNCHRONISATION,
  OPERATION,
  TRANSPORT_MAIN,
  TRANSPORT_DOMAIN_ITEM,
  TRANSPORT_ITEM,
  CLIENT_ATTRIBUTES,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR
} from "../../store/constants";

export const TRANSPORT_ITEM_STATUS_OPEN = "S001";
export const TRANSPORT_ITEM_STATUS_SENT = "S002";
export const TRANSPORT_ITEM_STATUS_SUCCESS = "S003";
export const TRANSPORT_ITEM_STATUS_FAILED = "S004";
export const TRANSPORT_ITEM_STATUS_CANCELLED = "S005";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  afterModify(objectConfig, object, null, context, options);

  if (isEmpty(options) || options.fromSync !== true) {
    updateSubject(objectConfig, object, null, context, { disableSave: true });
  }

  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("After create transportItem");
  if (
    !isEmpty(relatedObject) &&
    !isEmpty(options) &&
    options.objectType === TRANSPORT_DOMAIN_ITEM
  ) {
    Vue.set(object, "transport", relatedObject.transport);
    Vue.set(object, "targetSystem", relatedObject.targetSystem);
    Vue.set(object, "transportEnv", relatedObject.transportEnv);
  }
  Vue.set(object, "status", TRANSPORT_ITEM_STATUS_OPEN);
  return object;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options
) {
  // console.log("Can Launch action for action", action);
  if (action === "transportItem") {
    if (object.status !== TRANSPORT_ITEM_STATUS_OPEN) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Item is not in the correct Status to execute.."
        });
      }
      return false;
    }
  }
  return true;
}

/***
 * TransportItem
 */
export async function transportItem(
  objectConfig,
  object,
  viewconfig,
  context,
  options
) {
  if (
    !canLaunchActionForObjectAndConfig(
      "transportItem",
      objectConfig,
      object,
      viewconfig,
      context,
      options
    )
  ) {
    return;
  }

  //Find object and create Transport
  var objects = context.getters.getDataObjectsForObjectType(object.objectType);
  var transportObject = objects.find(function(o) {
    return o.Guid === object.objectGuid;
  });
  if (isEmpty(transportObject)) {
    context.commit("showAlert", {
      type: ALERT_TYPE_ERROR,
      message: "No object found."
    });
    return;
  }
  //Update The Status of the object
  Vue.set(object, "status", TRANSPORT_ITEM_STATUS_SENT);
  await context.dispatch("saveObjectForObjectType", {
    objectType: TRANSPORT_ITEM,
    object: object
  });
}

/***
 * TransportItem
 */
export async function updateSubject(
  objectConfig,
  object,
  viewconfig,
  context,
  options
) {
  if (
    isEmpty(object.subject) &&
    !isEmpty(object.objectGuid) &&
    !isEmpty(object.objectType)
  ) {
    //Try best subject
    var titles = [
      "fieldList",
      "cardConfig",
      "tabConfig",
      "viewConfig",
      "subject",
      "description",
      "title",
      "text",
      "field",
      "value"
    ];
    var value = null;
    var linkedObjects = context.getters.getDataObjectsForObjectType(
      object.objectType
    );
    var linkedObject = linkedObjects.find(function(o) {
      return o.Guid === object.objectGuid;
    });
    var i = 0;
    while (isEmpty(value) && i < titles.length) {
      var title = titles[i];
      value = linkedObject[title];
      i++;
    }
    if (isEmpty(value)) {
      value = "No Subject";
    }
    Vue.set(object, "subject", value);
  }
}
