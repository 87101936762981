<template>
  <v-container
    class="ma-0  py-0 tileBackgroundColor"
    :class="isMobile ? 'px-0' : 'px-0'"
    fluid
  >
    <v-container
      ref="mySplitCard"
      class="ma-0 pa-0 splitCardHeight tileBackgroundColor"
      fluid
    >
      <resize-observer @notify="onResizeComponent" />
      <v-container v-show="false">{{ getCurrentSplitcard }}{{ detailsState }}</v-container>
      <v-container
        :class="getClass('OuterContainer')"
        class="tileBackgroundColor"
        fluid
      >
        <v-row
          no-gutters
          style="flex-wrap: nowrap;"
          class="px-0"
        >
          <div
            :style="getTranslateStyle('left')"
            :class="getClassLeft()"
          >
            <v-container :fluid="isFluid">

              <v-row align="center">
                <v-container
                  :fluid="isFluid"
                  class="ma-0 pa-0 mt-16 px-0 px-sm-4 pt-0 pt-sm-2 "
                  flat
                >
                  <v-card
                    :class="`${getClassSize('left')} ${!isMobile && 'border-right'}`"
                    flat
                  >

                    <viewConfigView :viewconfig="getCurrentSplitcard.viewConfig1" />
                  </v-card>
                </v-container>
              </v-row>
            </v-container>
          </div>
          <div
            ref="middleCard"
            :style="getTranslateStyle('middle')"
            :class="getClassMiddle()"
          >
            <v-container :fluid="isFluid">
              <v-row align="center">
                <v-container
                  ref="middleCard1"
                  :fluid="isFluid"
                  :class="`ma-0 pa-0 mt-16 pb-16 px-sm-4 pt-4 pt-sm-3  ${!isMobile && detailsState > 4 && 'pl-sm-6 pr-sm-0'}`"
                  flat
                >
                  <v-card
                    ref="middleCards"
                    :class="getClassSize('left')"
                    flat
                  >
                    <viewConfigView
                      v-if="detailsLoaded"
                      :viewconfig="getCurrentSplitcard.viewConfig2"
                    />
                  </v-card>
                </v-container>
              </v-row>
            </v-container>
          </div>
          <div
            ref="rightCard"
            :style="getTranslateStyle('right')"
            :class="getClassRight()"
          >
            <v-container :fluid="isFluid">
              <v-row align="center">
                <v-container
                  :fluid="isFluid"
                  class="ma-0 pa-0 mt-16 px-0 px-sm-4 pt-0 pt-sm-2 pb-0"
                  flat
                >
                  <v-card
                    :class="getClassSize('right')"
                    flat
                  >
                    <viewConfigView
                      v-if="detailsLoaded"
                      :viewconfig="getCurrentSplitcard.viewConfig3"
                    />
                  </v-card>
                </v-container>
              </v-row>
            </v-container>
          </div>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>

import { SPLITCARD_THRESHOLD } from "../../store/constants"
import { mapGetters } from "vuex";
import { isEmpty, isTrue } from '../../api/utilities';
export default {
  props: {
    dialog: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      splitCardWidth: 1528,
      myContainerHeight: 800,
      splitView: null,
      message: "nothing",
      // Classes
      classOuterContainer: this.getClassSize("outer") + " alphaLow ",
      classLeft: this.getClassSize("left") + " enable-scroll no-horizontal-scroll ",
      classMiddle: this.getClassSize("middle") + " enable-scroll no-horizontal-scroll",
      classRight: this.getClassSize("right") + " enable-scroll no-horizontal-scroll",
      classAnimation: " animationType no-horizontal-scroll",
      sizeLeft: "kingSize",
      sizeRight: "smallSize",
      sizeMiddle: "mediumSize",
      translateValueLeft: 0,
      translateValueMiddle: 0,
      translateValueRight: 0,
      bufferDetailState: 0,
      bufferedSplitCard: "",
      detailsLoaded: false
    };
  },
  computed: {

    getWidthOfView() {
      return this.myContainerWidth;
    },
    getHeightOfView() {
      return this.myContainerHeight;
    },
    ...mapGetters(["getSplitCards", "getFluid", "getLeftMenu"]),
    getTitle() {
      var route = this.$store.getters["getCurrentRoute"]
      if (route) {
        var currentRoute = this.getLeftMenu.find(function (o) {
          return o.viewConfig === route && o.menuGroup !== "core" && isTrue(o.isActive);
        });
        if (currentRoute) {
          return currentRoute.title
        }
      }

      return null
    },
    getCurrentSplitcard() {
      const currentSplitCard = isEmpty(this.dialog) ? this.$store.getters.getCurrentSplitcard : this.$store.getters.getDialogSplitcard(this.dialog);

      // var currentSplitCard = this.$store.getters.getCurrentSplitcard;
      if (this.bufferedSplitCard !== currentSplitCard.splitCard) {
        this.setBufferedSplitCardAndDisableAnimation(currentSplitCard);
      }

      return currentSplitCard;
    },
    detailsState() {
      var currentDetailState = this.$store.getters.getStateForSplitCard(this.getCurrentSplitcard.splitCard);
      if (currentDetailState !== this.bufferDetailState) {
        this.action(currentDetailState);
      }
      // optimise, load right splitcards later if state is 0
      if (currentDetailState === 0) {
        this.detailsLoaded = false
        setTimeout(() => {
          this.detailsLoaded = true
        }, 1500)
      } else {
        this.detailsLoaded = true
      }
      return currentDetailState;
    },
    isMobile() {
      return this.$store.getters.getDeviceSize === 1;
    },
    isFluid() {
      return this.isMobile || this.getFluid
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
      this.init();
      this.onResizeComponent();
    });
  },
  methods: {
    showConfig(num) {
      if (this.isMobile) return this.detailsState === num
      if (this.detailsState === 8) return true
      if (this.detailsState === 12 && num === 4) return true
      return Math.abs(this.detailsState - num) <= 4
    },
    setBufferedSplitCardAndDisableAnimation(splitCard) {
      this.bufferedSplitCard = splitCard.splitCard;
      this.classAnimation = " animationTypeNo no-horizontal-scroll ";
      this.$nextTick(() => {
        this.updateClasses();
      });
      setTimeout(this.setAnimation, 100);
    },
    setAnimation() {
      this.classAnimation = " no-horizontal-scroll ";
    },
    getWidthType() {
      // TODO
      if (this.$store.getters.getDeviceSize === 1) {
        return "Tiny";
      }
      return "Big";
    },
    /*
      Componend specific calculations
    */
    init() {
      setTimeout(this.fadeViewIn, 1);
      var card = this.$refs["middleCard"];
      var rightCard = this.$refs["rightCard"];
      if (card !== undefined) {
        card.addEventListener("transitionend", this.updateClasses);
        rightCard.addEventListener("transitionend", this.updateClasses);
      }
      else {
      }
      this.updateClasses();
    },

    onResizeComponent() {
      var container = this.$refs.mySplitCard;
      var width = 0;
      var height = 0;
      if (container !== undefined && container !== null) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      if (this.myContainerWidth !== width &&
        width !== undefined &&
        width !== 0) {
        //Write to store?
        this.myContainerWidth = width - 25;
        this.myContainerHeight = height;
        // this.$store.commit("setDeviceSize", this.myContainerWidth);
      }
      this.updateClasses();
    },
    getClassSize(type) {
      if (this.$store.getters.getDeviceSize === 1) {
        return " ma-0 pa-0 ";
      }
      else if (type === "outer") {
        return " ma-0 pa-0 ";
      }
      return " ma-0 pa-0 ";
      2;
    },
    fadeViewIn() {
      this.classOuterContainer = this.getClassSize("outer") + " alphaHigh ";
    },
    getValueTranlated() {
      if (this.getWidthType() === "Tiny") {
        return this.myContainerWidth + 25;
      }
      return this.myContainerWidth / 3 + 7;
    },
    action(detailState) {
      if (this.getWidthType() === "Tiny") {
        this.actionTiny(detailState);
      }
      this.actionBig(detailState);
    },
    actionTiny(detailState) {
      var difference = Math.floor((this.bufferDetailState - detailState) / 4);
      this.translateValueLeft =
        this.translateValueLeft + difference * this.getValueTranlated();
      this.translateValueMiddle =
        this.translateValueMiddle + difference * this.getValueTranlated();
      this.translateValueRight =
        this.translateValueRight + difference * this.getValueTranlated();
      this.bufferDetailState = detailState;
    },
    actionBig(detailState) {
      var cardStatus = detailState;
      var difference = Math.floor((this.bufferDetailState - detailState) / 4);
      this.classAnimation = "animationType";
      var translatedValue = this.getValueTranlated(); // Translation value
      // 0 -> 12
      if (this.bufferDetailState === 0 && detailState === 12) {
        this.translateValueLeft =
          this.translateValueLeft - 0 * this.getValueTranlated();
        this.translateValueMiddle =
          this.translateValueMiddle - 4 * this.getValueTranlated();
        this.translateValueRight =
          this.translateValueRight - 4 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 0 -> 8
      if (this.bufferDetailState === 0 && detailState === 8) {
        this.translateValueMiddle = -3 * this.getValueTranlated();
        this.translateValueRight = -3 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 8 -> 12
      if (this.bufferDetailState === 8 && detailState === 12) {
        this.translateValueMiddle =
          this.translateValueMiddle - 1 * this.getValueTranlated();
        this.translateValueRight =
          this.translateValueRight - 1 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 12 -> 8
      if (this.bufferDetailState === 12 && detailState === 8) {
        this.translateValueLeft =
          this.translateValueLeft + 1 * this.getValueTranlated();
        this.translateValueMiddle =
          this.translateValueMiddle + 1 * this.getValueTranlated();
        this.translateValueRight =
          this.translateValueRight + 1 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 12 -> 4
      if (this.bufferDetailState === 12 && detailState === 4) {
        this.translateValueLeft =
          this.translateValueLeft + 1 * this.getValueTranlated();
        this.translateValueMiddle =
          this.translateValueMiddle + 3 * this.getValueTranlated();
        this.translateValueRight =
          this.translateValueRight + 3 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 4 -> 12
      if (this.bufferDetailState === 4 && detailState === 12) {
        this.translateValueLeft =
          this.translateValueLeft - 1 * this.getValueTranlated();
        this.translateValueMiddle =
          this.translateValueMiddle - 3 * this.getValueTranlated();
        this.translateValueRight =
          this.translateValueRight - 3 * this.getValueTranlated();
        this.bufferDetailState = detailState;
        return;
      }
      // 8 -> 4
      if (detailState === 8 && this.bufferDetailState === 4) {
        difference = difference * 2;
      }
      // 4 -> 8
      if (detailState === 4 && this.bufferDetailState === 8) {
        difference = difference * 2;
      }
      this.translateValueMiddle =
        this.translateValueMiddle + difference * this.getValueTranlated();
      this.translateValueRight =
        this.translateValueRight + difference * this.getValueTranlated();
      this.bufferDetailState = detailState;
    },
    /*
      Translation Style
    */
    getTranslateStyle(component) {
      var translateValue = this.translateValueLeft;
      if (component === "right") {
        translateValue = this.translateValueRight;
      }
      else if (component === "middle") {
        translateValue = this.translateValueMiddle;
      }
      var transform = "-webkit-transform: translateX("
        .concat(translateValue)
        .concat("px ; transform: translateX(")
        .concat(translateValue)
        .concat("px; ")
        // .concat(" height: ")
        // .concat(this.getHeightOfView)
        .concat("px; ");
      return transform;
    },
    /*
     Class Getters
    */
    getClass(component) {
      if (component === "OuterContainer") {
        return this.classOuterContainer;
      }
      return "";
    },
    getClassLeft() {
      return this.classAnimation
        .concat(this.classLeft)
        .concat(" splitCardHeight ")
        .concat(this.sizeLeft)
        .concat(this.getFixedWidth());
    },
    getClassRight() {
      return this.classAnimation
        .concat(this.classRight)
        .concat(" splitCardHeight ")
        .concat(this.sizeRight)
        .concat(this.getFixedWidth());
    },
    getClassMiddle() {
      return this.classAnimation
        .concat(this.classMiddle)
        .concat(" splitCardHeight ")
        .concat(this.sizeMiddle)
        .concat(this.getFixedWidth());
    },
    getFixedWidth() {
      return " fixedWidth tileBackgroundColor ";
    },
    /*
      Update the classes after something changed
      Depending on the size of the screen: It will call big or tiny.
    */
    updateClasses() {
      var widthType = this.getWidthType();
      if (widthType === "Tiny") {
        this.updateClassesTiny();
      }
      else {
        this.updateClassesBig();
      }
    },
    updateClassesBig() {
      var cardStatus = this.detailsState;
      // this.message = "Updated".concat(this.cardStatus);
      this.classAnimation = " ";
      if (cardStatus === 8) {
        this.sizeLeft = " smallSize ";
        this.sizeMiddle = " smallSize ";
        this.sizeRight = " mediumSize ";
        this.translateValueleft = 0;
        this.translateValueMiddle = 0;
        this.translateValueRight = 0;
      }
      if (cardStatus === 12) {
        this.sizeLeft = " smallSize ";
        this.sizeMiddle = " smallSize ";
        this.sizeRight = " bigSize ";
        this.translateValueLeft = -1 * this.getValueTranlated();
        this.translateValueMiddle = -1 * this.getValueTranlated();
        this.translateValueRight = -1 * this.getValueTranlated();
      }
      if (cardStatus === 4) {
        this.sizeLeft = "bigSize";
        this.sizeMiddle = "smallSize";
        this.sizeRight = "smallSize";
        this.translateValueMiddle = 0;
        this.translateValueRight = 0;
        this.translateValueLeft = 0;
      }
      if (cardStatus >= 16) {
        this.sizeLeft = " smallSize ";
        this.sizeMiddle = " smallSize ";
        this.sizeRight = " kingSize ";
        this.translateValueLeft = -2 * this.getValueTranlated();
        this.translateValueMiddle = 0;
        this.translateValueRight = 0;
      }
      if (cardStatus <= 0) {
        this.sizeLeft = " kingSize ";
        this.sizeMiddle = " smallSize ";
        this.sizeRight = " smallSize ";
        this.translateValueMiddle = 0;
        this.translateValueRight = 0;
        this.translateValueLeft = 0;
      }
    },
    updateClassesTiny() {
      var cardStatus = this.detailsState;
      this.classAnimation = " ";
      if (cardStatus === 4) {
        this.sizeLeft = "kingSize";
        this.sizeMiddle = "kingSize";
        this.sizeRight = "kingSize";
      }
      if (cardStatus >= 8) {
        this.sizeLeft = " kingSize ";
        this.sizeMiddle = " kingSize ";
        this.sizeRight = " kingSize ";
      }
      if (cardStatus <= 0) {
        this.sizeLeft = " kingSize ";
        this.sizeMiddle = " kingSize ";
        this.sizeRight = " kingSize ";
        this.translateValueMiddle = 0;
        this.translateValueRight = 0;
        this.translateValueLeft = 0;
      }
    }
  }
};
</script>
