<template>
  <v-container
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <v-dialog
      v-model="dialog.visible"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="dialog.visible">
          <v-btn
            color="primary"
            icon
            @click="cancelSelection()"
          >
            <v-icon>hi-x-mark</v-icon>
          </v-btn>
          <viewConfigView
            :object="selectedField"
            :editmode="false"
            :dialog="dialog"
            :key="componentKey"
            :viewconfig="viewConfigOfSelection"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <v-card
      flat
      class="pa-0 ma-0 transparent"
    >

      <v-container
        ref="myListOuter1"
        class="pa-2 pt-5 ma-0 removeScroll xs12"
        fluid
      >
        <v-row
          no-gutters
          style="margin-left: -8px;margin-right: -4px;"
        >

          <v-data-table
            v-model="selected"
            :headers="getFields"
            :items="getFilteredObjects"
            :search="search"
            :disable-pagination="disablePagination"
            :hide-default-footer="hidePagination"
            :footer-props="{
      itemsPerPageOptions: [6, 10, 20, 32],
    }"
            :options="{
      itemsPerPage: getNumberOfRows
    }"
            :page="page"
            :single-select="false"
            :group-by="groupBy"
            :show-select="selectable"
            :mobile-breakpoint="0"
            :loading="loading"
            style="width:100%"
            class="transparent"
            flat
            item-key="Guid"
            @current-items="onChangeCurrentItems"
            @update:page="onPage"
            @update:items-per-page="onItemPerPage"
          >

            <template
              v-slot:top
              v-if="selected.length > 0 && selectable"
            >
              <v-container
                style="position:absolute; top:6px; z-index: 1;"
                fluid
              >
                <v-row
                  class="primary px-4 py-2 ma-0 rounded-lg elevation-2"
                  align="center"
                  no-gutters
                >
                  <div class="text-body-2 white--text">
                    <!-- <v-simple-checkbox
                      v-if="field.value === 'data-table-select'"
                      :style="{ opacity: isSelected ? 1 : .6 }"
                      :value="isSelected"
                      class="v-data-table__checkbox"
                      color="primary"
                      style="width: 24px; max-width: 24px; min-width: 0;display: block;"
                      @input="select($event)"
                    /> -->
                  </div>
                  <div class="text-body-2 white--text">
                    <b>{{ selected.length }}</b> {{ `/${getFilteredObjects.length} selected` }}
                  </div>
                  <v-divider
                    class="mx-2 opaque-4"
                    dark
                    vertical
                  />
                  <v-btn
                    class="primary darken-1"
                    elevation="0"
                    small
                    dark
                    @click="deselect"
                  >
                    {{ allSelected ? 'Deselect' : 'Select all' }}
                  </v-btn>

                  <v-btn
                    class="primary darken-1 ml-2"
                    elevation="0"
                    small
                    left
                    dark
                    @click="massUpdate"
                  >
                    <v-icon
                      dark
                      small
                      class="mr-1"
                    >hi-pencil-square-outline</v-icon>
                    Update
                  </v-btn>
                  <v-btn
                    v-if="selected.length === 1"
                    class="primary ml-2 darken-1"
                    elevation="0"
                    small
                    left
                    dark
                    @click="duplicate"
                  >
                    <v-icon
                      dark
                      small
                      class="mr-1"
                    >hi-document-duplicate-outline
                    </v-icon>
                    Duplicate
                  </v-btn>
                </v-row>
              </v-container>
            </template>

            <template v-slot:item="{ item, headers, isSelected, select }">
              <tr>

                <td
                  v-for="field in headers"
                  :key="field.Guid"
                  :align="getAlign(field.align)"
                  :bgcolor="getCellColor(item)"
                  class="px-4"
                  :class="{ 'tileBackgroundColor': isSelected, 'px-6': field.value === 'data-table-select' }"
                  :style="{ 'min-width': getMinWidth(field), 'width': field.value === 'data-table-select' ? '40px' : 'auto' }"
                  scope="col"
                  role="cell"
                >
                  <v-simple-checkbox
                    v-if="field.value === 'data-table-select'"
                    :style="{ opacity: isSelected ? 1 : 1 }"
                    :value="isSelected"
                    class="v-data-table__checkbox gray--text mx-2"
                    color="primary"
                    style="width: 24px; max-width: 24px; min-width: 0;display: block;"
                    @input="select($event)"
                  />
                  <div
                    v-else
                    :style="{ 'max-width': field.type === 'image' ? '40px' : 'none' }"
                  >
                    <GenericInputSimple
                      v-if="!isListEditMode(item)"
                      :keyobject="keyObject"
                      :object="getItem(item)"
                      :header="field"
                      :viewconfig="viewconfig"
                      :height="getRowHeight"
                      :showlabel="false"
                      :readonly="true"
                      solo
                      dense
                      navigateidlink
                      @onDoubleClick="onDoubleClicked"
                    />
                    <genericInput
                      v-else
                      :keyobject="keyObject"
                      :object="getItem(item)"
                      :header="field"
                      :operationid="getOperationIdForItem(item)"
                      :viewconfig="viewconfig"
                      :height="getRowHeight"
                      :showlabel="false"
                      :readonly="false"
                      solo
                      dense
                      navigateidlink
                    />
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:[`group.header`]="{ isOpen, toggle, groupBy, items }">
              <th
                colspan="100%"
                :class="`white--text sideBarColor`"
                style="border-radius: 0px!important; text-align:start!important; opacity: 1;"
                @click="toggle"
              >
                <v-container fluid>
                  <v-row>
                    <v-icon>{{ isOpen ? 'hi-minus-outline' : 'hi-plus-outline' }}
                    </v-icon>
                    <div>
                      <genericInput
                        :keyobject="keyObject"
                        :object="getItem(items[0])"
                        :header="getHeader(groupBy[0])"
                        :operationid="getOperationIdForItem(items[0])"
                        :viewconfig="viewconfig"
                        :height="getRowHeight"
                        :showlabel="false"
                        class="white--text"
                        readonly
                        solo
                        dense
                        navigateidlink
                      />
                    </div>
                  </v-row>
                </v-container>
                <!-- {{ group }} -->
              </th>
            </template>
            <template v-slot:no-data>
              <p
                style="opacity: 0.5;"
                class="mb-0 text-center"
              >No data found</p>
            </template>

            <template v-slot:no-results>
              No results found
            </template>

          </v-data-table>
        </v-row>
      </v-container>
      <slot name="footer" />
    </v-card>
    <massUpdatingView
      v-if="!ismass"
      :viewconfig="viewconfig"
    />
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import filterView from "./FilterView";
import listViewAction from "./ListViewActions";
import { mapGetters } from "vuex";
import * as theme from "../../theme/themeDefault.js";
import massUpdatingView from "./MassUpdatingViews/MassUpdatingView"
import GenericInputSimple from "../components/GenericInputSimple.vue";
import { parseJSON } from "../../api/synchronizationApi";
import { _DELETE_DATA_TABLE, _DELETE_DATA_TABLE_HISTORY } from "../../store/dbContext";


export default {
  components: { listViewAction, filterView, massUpdatingView, GenericInputSimple },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    ismass: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: String,
      default: null
    },
    selectable: {
      type: Boolean,
      default: true
    },
    onCurrentItems: {
      type: Function,
      default: () => (null)
    },
  },
  data() {
    return {
      fields: undefined,
      hover: false,
      myListWidth: 0,
      showFilter: true,
      counter: 0,
      componentKey: "myListViewComponentKey",
      viewConfigOfSelection: null,
      selectedField: null,
      keyObject: {
        key: 1
      },
      dialog: {
        visible: false
      },
      currentItems: [],
      page: 1,
      itemsPerPage: 10,
      stateLoaded: false,
      items: [],
      initLoaded: false,
      lastSaved: null,
      loading: false,
      currentObject: null
    };
  },
  computed: {
    ...mapGetters(['isDark', 'getIsUpdating']),
    //do no know what this does.
    selected: {
      get() {
        return this.$store.getters.getSelectedItemsForViewConfig(this.viewconfig)
      }, set(items) {
        this.$store.commit('setSelectedItemsForViewConfig', { viewconfig: this.viewconfig, items })
      }
    },
    allSelected() {
      return this.selected.length === this.getFilteredObjects.length
    },
    getHeight() {
      return "300px";
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getCurrentFilter() {
      if (UTIL.isTrue(this.isEditMode)) {
        return this.$store.getters.getCurrentFilterForFilterId(this.getViewConfig.queryFilter + "Edit");
      } else {
        return this.$store.getters.getCurrentFilterForFilterId(this.getViewConfig.queryFilter);
      }
    },
    getObjects() {
      if (!UTIL.isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getCustomFilter() {
      return this.$store.getters.getCustomFilterForViewConfig(this.viewconfig)
    },
    getFilteredObjects() {

      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject,
        isEdit: this.isEditMode,
        customFilter: this.getCustomFilter
      };

      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },

    isOnline() {
      return !UTIL.isEmpty(this.getCurrentFilter) && UTIL.isTrue(this.getCurrentFilter.online)
    },
    getClassOfHeader() {
      if (this.isConfigMode) {
        return "normalCell";
      }
      return "lowCell";
    },
    hidePagination() {
      return this.disablePagination === true || this.isEditMode === true
    },
    disablePagination() {
      if (this.noPagination === true) return true;
      if (!(this.getFilteredObjects && this.getFilteredObjects.length > this.getNumberOfRows)) return true
      return false
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    getWidthSize() {
      var customSize = this.$store.getters.getCustomSize(this.viewconfig);
      if (!UTIL.isEmpty(customSize)) {
        if (customSize.size > 0) {
          return customSize.size;
        }
      }
      return this.getWidth;
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getFieldListType() {
      if (this.isEditMode) {
        return "listEdit";
      }
      return "listDisplay";
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: this.getWidthSize,
          list: this.getFieldListType
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.filter(element => element.type !== "Title");
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "left"; //right
        }
        field.sortable = true;
      }
      return fields;
    },
    getAllFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: 12,
          list: this.getFieldListType
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      return fields;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getRowHeight() {
      return 48;
    },
    getNumberOfRows() {
      if (this.groupBy) return 20
      var height = UTIL.pixelStringToInt(this.height);
      if (height > 200) {
        var numberOfRows = (height - 140) / this.getRowHeight;
        return Math.floor(numberOfRows);
      }
      return this.itemsPerPage;
    },
    getParentObjectType() {
      if (!UTIL.isEmpty(this.$store.getters.getObjectTypeForViewDialog)) {
        return this.$store.getters.getObjectTypeForViewDialog;
      }
      return this.$store.getters.getCurrentSplitCardObjectType
    },
    limit() {
      return this.getOptions && this.getOptions.limit
    },
  },
  /**
    We set the default filter for the viewConfig
   */
  created: function () {
    const savedState = this.$store.getters.getViewConfigState(this.viewconfig)
    if (savedState) {
      if (!UTIL.isEmpty(savedState.page)) {
        this.page = savedState.page
      }
      if (!UTIL.isEmpty(savedState.itemsPerPage)) {
        this.itemsPerPage = savedState.itemsPerPage
      }
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
    this.stateLoaded = true
    this.fetchItems()
    this.currentObject = this.object && this.object.Guid
  },
  watch: {
    getCurrentFilter(value) {
      console.log('getCurrentFilter', value)
      if (this.initLoaded) {
        this.fetchItems(false)
      }
    },
    getCustomFilter: {
      handler(val) {
        // do stuff
        console.log('getCurrentFilter', val)
        if (this.initLoaded) {
          this.fetchItems(false)
        }
      },
      deep: true
    },
    object(value) {

      if (value && value.Guid !== this.currentObject) {
        this.fetchItems(false)
        this.currentObject = value.Guid
      }
    },
    getIsUpdating(value) {
      if (this.initLoaded && value === false) {
        this.fetchItems(false)
      }
    }
  },
  methods: {
    onChangeCurrentItems(items) {
      this.onCurrentItems(items)
      // if (UTIL.isTrue(this.isOnline) && !UTIL.isEmptyArray(items) && this.lastSaved !== JSON.stringify(items)) {
      //   const payload = {
      //     objects: items,
      //     objectConfig: this.getObjectConfig
      //   }
      //   this.$store.dispatch('updateDataStore', payload)
      //   this.lastSaved = JSON.stringify(items)
      // }
    },
    saveItems(items) {
      if (UTIL.isTrue(this.isOnline) && !UTIL.isEmptyArray(items) && this.lastSaved !== JSON.stringify(items)) {
        const payload = {
          objects: items,
          objectConfig: this.getObjectConfig,
          noCache: true
        }
        this.$store.dispatch('updateDataStore', payload)
        this.lastSaved = JSON.stringify(items)
      }
    },
    async fetchItems(force) {
      if (this.getCurrentFilter && UTIL.isTrue(this.getCurrentFilter.online)) {
        this.loading = true

        const payload = {
          objectType: this.getObjectType,
          filterGuid: this.getCurrentFilter.Guid,
          relatedGuid: this.getCurrentObject && this.getCurrentObject.Guid,
          relatedType: this.getParentObjectType,
          customFilter: this.getCustomFilter,
          limit: this.limit,
          force
        }
        // await this.deleteCachedData()
        const objects = await this.$store.dispatch('fetchObjectsForFilter', payload)
        this.initLoaded = true
        this.loading = false
        this.$store.commit('clearDataObjects', this.getObjectType)
        // if (force) {
        //   this.$store.commit('clearDataObjects', this.getObjectType)
        // }
        if (!UTIL.isEmptyArray(objects)) {
          this.items = parseJSON(objects, this.getObjectConfig, this.$store, this.getObjectConfig.syncGroup)
          this.saveItems(this.items)
        }
      }
    },
    onPage(value) {
      if (!this.stateLoaded) {
        return
      }
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          page: value,
        }
      })
    },
    async deleteCachedData() {
      const objectConfig = this.$store.getters.getObjectConfigForType(
        this.getObjectType
      );
      await this.$store.dispatch(_DELETE_DATA_TABLE, objectConfig);
      await this.$store.dispatch(_DELETE_DATA_TABLE_HISTORY, objectConfig);
    },
    onItemPerPage(value) {
      if (!this.stateLoaded) {
        return
      }
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          itemsPerPage: value
        }
      })
    },
    getMinWidth(field) {
      if (field.type ===
        "actions" || field.type ===
        "progressBar" || field.type ===
        "currency") {
        return 0
      }
      if (field.width === 12) {
        return '250px'
      }
      if (field.width === 11) {
        return '220px'
      }
      if (field.width < 11 && field.width >= 6) {
        return '100px'
      }
      return 0
    },
    onDoubleClicked() {
      this.$emit('onDoubleClick')
    },
    isListEditMode(item) {
      const operationId = this.getOperationIdForItem(item)
      return this.isEditMode && !UTIL.isEmpty(this.$store.getters.getOperationContainerForId(operationId))
    },
    isEmptyArray(value) {
      return UTIL.isEmptyArray(value)
    },
    getFieldType(field) {
      return field
    },
    deselect() {
      if (this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.getFilteredObjects
      }
    },
    massUpdate() {
      var payload = {
        massUpdateViewConfig: this.viewconfig,
        massUpdate: true
      };
      this.$store.dispatch(
        "goToMassUpdate",
        payload
      );
    },
    onResizeComponent() {
      var container = this.$refs.myListOuter1;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      this.myListWidth = width;
      this.myListHeight = height;
    },
    setFields(fields) {
      this.fields = fields;
    },
    getkey(item) {
      var objectConfig = this.getObjectConfig;
      var keys = this.getObjectConfig.getIds();

      var itemKey = "";
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        itemKey = itemKey.concat(item[key]);
      }
      return itemKey;
    },
    /*
    getValue(field, object) {
      if (field.type === "valueList") {
        var listValues = this.$store.getters.getDataObjectsForObjectType(
          Constants.VALUE_LIST_CONFIG
        );
        if (listValues !== null && listValues !== undefined) {
          var value = listValues.find(function(o) {
            return (
              o.valueList === field.dropdown && o.value === object[field.value]
            );
          });
          if (value !== undefined && value !== null) {
            return value.descr;
          }
        }
      }

      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== "" &&
        field.displayRelatedObject !== null
      ) {
        var payload = {
          objectType: field.relatedObjectType,
          objectId: object[field.value]
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (relatedObject !== undefined) {
          //Split
          return UTIL.splitFields(relatedObject, field.displayRelatedObject);
        } else {
          return "";
        }
      }
      if (field.type === "date") {
        var date = object[field.value];
        return UTIL.jsonDateToString(date);
      }
      return object[field.value];
    },
    clickField: function(field, object) {
      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    */
    getCellColor(object) {
      var currentObject = this.$store.getters.getCurrentObject;
      if (object === currentObject) {
        return theme.getTheme()[theme.ROW_HIGHLIGHTED];
      }
      return theme.getTheme()[theme.ROW_BACKGROUND];
    },

    getOperationIdForItem(item) {
      var payload = {
        object: item,
        viewconfig: this.viewconfig
      };
      return this.$store.getters.getOperationIdForObjectAndConfig(payload); //todo.
    },
    getItem(item) {
      var operationId = this.getOperationIdForItem(item);
      var operationContainer = this.$store.getters.getOperationContainerForId(
        operationId
      );
      if (!UTIL.isEmpty(operationContainer)) {
        return operationContainer.newObject;
      }
      return item;
    },
    getHeader(value) {
      return this.getAllFields.find(item => item.value === value);
    },

    getAlign(align) {
      if (align === null || align === "" || align === undefined) {
        return "right";
      } else {
        return align;
      }
    },
    duplicate() {
      var item = this.selected[0]
      var config = this.getObjectConfig;
      // Get the viewConfig
      this.viewConfigOfSelection = this.getViewConfig.objectType.concat(
        "ViewDetailsconfig"
      );

      var newObject = structuredClone(this.selected[0])
      newObject.Guid = UTIL.getGuid()
      delete newObject._id
      // Create the operation object
      var operationId = UTIL.getGuid();
      var payload = {
        object: newObject,
        config: config,
        id: operationId
      };
      this.$store.dispatch(Constants.CREATE_OPERATION_CONTAINER, payload);



      var payload = {
        viewconfig: this.viewConfigOfSelection,
        operationId: operationId
      };
      this.$store.commit("changeEditMode", payload);


      /*
        New
      */

      var button = this.$refs[item.reference];

      if (!UTIL.isEmpty(button)) {
        const width = 850
        var rect = button[0].$el.getBoundingClientRect()
        var btnX = rect.left + (rect.width / 2)
        var btnY = rect.top + (rect.height / 2)
        var centerX = screen.width > 850 ? (screen.width / 2) - (width / 2) : 24
        var centerY = screen.height > 850 ? (screen.height / 2) - (width / 2) : 24
        var distX = (centerX - btnX) / 2
        var distY = (centerY - btnY) / 2
        var x = btnX + distX
        var y = btnY + distY
        var origin = { x, y }

      }

      var _this = this


      console.log(_this.viewConfigOfSelection, newObject, _this.getViewConfig.objectType, operationId)
      this.$store.dispatch("showActionDialog", {
        viewConfig: _this.viewConfigOfSelection,
        object: newObject,
        objectType: _this.getViewConfig.objectType,
        origin: origin,
        operationId: operationId
      });

      /*
      this.dialogWidth = 800;
      this.hasSelectionView = true;
      this.dialog.show = true;
      this.componentKey += 1;
      */

    },
    /*
    getProgressValue(value) {
      if (value === 7) {
        return 100;
      }
      if (value === 3) {
        return 75;
      }
      if (value === 2) {
        return 25;
      }
      return 0;
    },
    isProgress(type) {
      if (type === "progress") {
        return true;
      }
      return false;
    },

    isLink(type) {
      if (type === "link") {
        return true;
      }
      if (type === "idLink") {
        return true;
      }
      return false;
    },
    isLabel(type) {
      if (type === undefined) {
        return true;
      }
      if (type === "progress") {
        return false;
      }
      if (this.isLink(type)) {
        return false;
      }
      return true;
    },
    */
    /**
      Dialog
    */
    openDialog(field) {
      this.selectedField = field;
      this.viewConfigOfSelection = "ViewFieldviewConfig2";
      this.dialog.visible = true;
    },
    getColorForfield(field, type) {
      if (UTIL.isTrue(field[type])) {
        return "primary";
      }
      return "gray";
    },
    objectSelected(selectedObject) {
      //this.object[this.selectedField.value] = selectedObject.objectType;
      this.closeDialog();
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.dialog.visible = false;
    }
  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}

.lowCell {
  height: 5px !important;
}

.normalCell {
  height: 30px !important;
}

.v-data-table__progress .v-progress-linear {
  height: 3px !important;
  opacity: 0.2;
}
</style>
