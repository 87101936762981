<template>
  <v-dialog
    :max-width="maxWidth"
    :absolute="true"
    :origin=getCoordinates
    v-model="getActionShowDialog.show"
    content-class="rounded-lg actionDialog"
    persistent
    transition="scale-transition"
  >
    <v-card
      :max-width="maxWidth"
      class="pa-3 pa-3 no-scroll rounded-lg"
    >
    <v-btn
        class="absolute sideBarColor"
        icon
        elevation="0"
        style="right: 20px; top: 20px; z-index: 50"
        @click="closeWindow()"
      ><v-icon>hi-x-mark</v-icon></v-btn>


      <viewConfigView
        v-if="getActionShowDialog.show"
        :viewconfig="getViewConfigForActionDialog"
        :object="getObjectForActionDialog"
        :operationid="getOperationIdForActionDialog"
        :editmode="true"
        :dialog="getActionShowDialog"
        :key="componentKey"
      />
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from "vuex";
import { isEmpty } from '../../api/utilities';
export default {
  components: {},
  data() {
    return {
      dialog: {
        show: null
      },
      componentKey: 0,
      maxWidth: 800
    }
  },
  computed: {
    ...mapGetters(["getViewConfigForActionDialog", "getObjectForActionDialog", "getActionShowDialog", "getOperationIdForActionDialog", "getOriginForActionDialog"]),

    getCoordinates() {
      var origin = this.getOriginForActionDialog
      if (!isEmpty(origin) && !isEmpty(origin.x) && !isEmpty(origin.y)) {
        var originString = origin.x.toString() + "px " + origin.y.toString() + "px"
        return originString
      }
      return ""
    }
  },
  methods: {

    closeWindow(){
      this.$store.commit("hideActionDialog");
    }
  }
};
</script>
  