if (typeof Array.prototype.contains === "undefined") {
  Array.prototype.contains = function(value) {
    var self = this;
    var result = false;
    var fn = function(elem, index, self) {
      if (elem === value) {
        result = true;
      } // end if value
    }; // end function fn

    self.forEach(fn);
    return result;
  }; // end function where
} // end if not array.prototype.contains

if (!Array.prototype.last) {
  Array.prototype.last = function() {
    if (this.length > 0) {
      return this[this.length - 1];
    } else {
      return undefined;
    }
  };
}
if (!Array.prototype.pushAll) {
  Array.prototype.pushAll = function(newArray) {
    for (var i = 0; i < newArray.length; i++) {
      this.push(newArray[i]);
    }
  };
}
