<template>
  <div>
    <v-snackbar
      v-for="(message, index) in getMessages"
      :key="message.id"
      :value="true"
      :color="message.type"
      :timeout="null"
      :style="`transform: translateY(${index * 60}px);`"
      elevation="2"
      rounded="lg"
      top
      class="snackbar"
    >
      <v-alert
        :type="message.type"
        rounded="lg"
      >
        <v-row align="center">
          <v-col class="grow">
            {{ message.message }}
          </v-col>

          <v-col
            v-if="message.link"
            class="shrink"
          >
            <v-btn
              :class="`${message.type} darken-1`"
              elevation="0"
              @click="click(message)"
            >{{ message.link.title || 'View' }}</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <template
        v-slot:action="{ attrs }"
        v-if="dissmissable(message)"
      >
        <v-btn
          v-bind="attrs"
          icon
          @click="close(message)"
        >
          <v-icon>hi-x-mark-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ALERT_TYPE_ERROR, ALERT_TYPE_WARNING } from '../../store/constants';

export default {
  data() {
    return {
      showAlert: true,
      message: 'Hello this is a message',
      type: ALERT_TYPE_ERROR,
      timer: null
    };
  },
  computed: {
    ...mapGetters(['getMessages']),

  },
  methods: {
    dissmissable(item) {
      return item.type === ALERT_TYPE_ERROR || item.type === ALERT_TYPE_WARNING
    },
    setTimer() {
      this.timer = setTimeout(this.close, 2000)
    },
    close(item) {
      this.$store.commit('hideAlert', item.id)
    },
    click(item) {
      if (item && item.link && item.link.action) {
        item.link.action()
        this.$store.commit('hideAlert', item.id)
      }
    }
  }
};
</script>

<style>
.snackbar .v-snack__wrapper,
.snackbar .v-snack__content {
  padding: 0;
}

.snackbar .v-alert {
  margin: 0;
}
</style>

