import { CARD_CONFIG } from "../store/constants";

/**
 * App State
 */

export default {
  state: {},
  mutations: {},
  getters: {
    /*
        CardConfigs
    */
    getCardConfigs: (state, getters) => {
      return getters.getDataObjectsForObjectType(CARD_CONFIG);
    },
    getCardConfigsForViewConfig: (state, getters) => viewConfig => {
      if (getters.getIsCacheActive(CARD_CONFIG)) {
        return getters.getCachedObjectsForType(CARD_CONFIG, viewConfig);
      }
      return getters.getCardConfigs.filter(o => {
        return o.viewConfig === viewConfig;
      });
    },
    getCardForCardConfigID: (state, getters) => cardConfigID => {
      return getters.getCardConfigs.find(function(o) {
        return o.cardConfig === cardConfigID;
      });
    }
  },
  actions: {}
};
