<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      rounded="lg"
    >
      <v-card rounded="lg">
        <v-card-title class="text-h5">
          Sign
        </v-card-title>
        <v-container>
          <VueSignaturePad
            v-if="showSignature"
            ref="signaturePad"
            :options="{
              maxWidth: 4,
            }"
            class="rounded-lg tileBackgroundColor"
            width="100%"
            height="500px"
          />
        </v-container>

        <v-card-actions class="pa-4">
          <v-spacer />

          <v-btn
            icon
            @click="undo"
          >
            <v-icon>hi-arrow-uturn-down</v-icon>
          </v-btn>
          <v-btn
            class="px-6"
            text
            @click="cancel"
          >
            cancel
          </v-btn>

          <v-btn
            color="primary"
            class="px-6"
            elevation="0"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
        <div
          id="image"
          class="image"
        />
        <div class="canvas" />
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import * as objectApi from "../../api/objectApi"
import { ATTACHMENT, ATTACHMENT_CATEGORY_SIGNATURE } from "../../store/constants";
import imageCompression from 'browser-image-compression';
import { b64toBlob } from '../../api/utilities';


export default {

  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      showSignature: false
    }
  },
  computed: {
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    dialog() {
      this.setShowSignature(this.$store.getters.getShowSignature)
      return this.$store.getters.getShowSignature
    },
    getObjectType() {
      return this.$store.getters.getSignatureObjectType
    },
    object() {
      return this.$store.getters.getSignatureObject
    },
    category() {
      return this.$store.getters.getSignatureCategory
    },
    getAttachments() {
      var attachments = this.$store.getters.getDataObjectsForObjectType(ATTACHMENT);
      var guid = this.object.Guid
      const objectType = this.getObjectType
      var signatures = attachments.filter(function (o) {
        return o.parent === guid && o.parentType === objectType && o.category === ATTACHMENT_CATEGORY_SIGNATURE;
      });
      return signatures
    },
    getNumOfAttachments() {
      if (this.getAttachments && Array.isArray(this.getAttachments)) {
        return this.getAttachments.length
      } else return 0
    }
  },
  methods: {
    setShowSignature(dialog) {
      if (dialog) {
        setTimeout(() => {
          this.showSignature = true
        }, 100);
      } else this.showSignature = false
    },
    cancel() {
      this.$refs.signaturePad.clearSignature();
      this.$store.commit('hideSignature')
      this.showSignature = false
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (data && this.object) {
        var attachment = objectApi.newObjectWithGuid()

        attachment.parent = this.object.Guid;
        attachment.parentType = this.getObjectType
        attachment.account = this.object.Account
        attachment.name = `${this.object.Subject}-signature${this.getNumOfAttachments + 1}.png`;
        attachment.attachmentType = 'image/png'
        attachment.category = this.category || ATTACHMENT_CATEGORY_SIGNATURE
        // var relatedObject = {
        //   object: this.object,
        //   viewConfig: this.getViewConfig
        // };
        // attachment = objectConfig.afterCreate(
        //   attachment,
        //   relatedObject,
        //   this.$store,
        // );
        const blob = b64toBlob(data)
        const file = new File([blob], attachment.name, {
          type: 'image/png'
        });
        const payload = {
          file,
          attachment
        }
        await this.$store.dispatch('upload', payload)
        this.$refs.signaturePad.clearSignature();
        this.$store.commit('hideSignature')
      }
    },
  }
};
</script>

<style scoped>
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  color: white;
  display: none;
}
</style>

