import * as utilities from "../../api/utilities.js";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import {
  OBJECT_CONFIG,
  SYNC_GROUP_CORE_REST,
  SYNC_GROUP_CORE_FIELDS,
  SYNC_GROUP_CORE_OBJECTS,
  SYNCHRONISATION,
  OPERATION,
  TRANSPORT_MAIN,
  TRANSPORT_DOMAIN_ITEM,
  TRANSPORT_ITEM,
  CLIENT_ATTRIBUTES
} from "../../store/constants";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  afterModify(objectConfig, object, null, context, options);

  //Default name if empty
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("After create order");
  if (
    !utilities.isEmpty(relatedObject) &&
    !utilities.isEmpty(options) &&
    options.objectType === TRANSPORT_MAIN
  ) {
    Vue.set(object, "transport", relatedObject.Guid);
    Vue.set(object, "sourceSystem", relatedObject.sourceSystem);
    Vue.set(object, "targetSystem", relatedObject.targetSystem);
    Vue.set(object, "transportEnv", relatedObject.transportEnv);
  }
  Vue.set(object, "status", "S001");
  Vue.set(object, "nbOfItems", 0);
  return object;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/

/***
 * Force full Synchronisation for object
 */
export async function findObjectsForDomain(
  objectConfig,
  object,
  viewconfig,
  context,
  options
) {
  console.log("getAllDomainItems");
  var allObjects = context.getters.getDataObjectsForObjectType(
    object.objectType
  );
  var lastChangeDate = object.startDate;
  var lastChangedObjects = allObjects.filter(function(o) {
    return o.updatedAt >= lastChangeDate;
  });

  for (var i = 0; i < lastChangedObjects.length; i++) {
    var lastChangedObject = lastChangedObjects[i];
    var items = context.getters.getDataObjectsForObjectType(TRANSPORT_ITEM);
    var foundItem = items.find(function(o) {
      return (
        o.transport === object.transport &&
        o.objectType === object.objectType &&
        o.objectGuid === lastChangedObject.Guid
      );
    });
    if (utilities.isEmpty(foundItem)) {
      var item = ObjectApi.newObjectWithGuid();
      var transportItemConfig = context.getters.getObjectConfigForType(
        TRANSPORT_ITEM
      );
      transportItemConfig.afterCreate(item, object, context, {
        objectType: TRANSPORT_DOMAIN_ITEM
      });
      Vue.set(item, "objectType", object.objectType);
      Vue.set(item, "objectGuid", lastChangedObject.Guid);
      var payload = {
        objectType: TRANSPORT_ITEM,
        object: item
      };
      await context.dispatch("saveObjectForObjectType", payload);
    }
  }
}
