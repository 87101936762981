import * as Constants from "../store/constants";
import Vue from "vue";
import * as utilities from "../api/utilities";

/**
 * App State
 */

export default {
  state: {},
  mutations: {},
  getters: {
    /*
        Get EmployeeGuid
    */
    getCurrentEmployeeGuid: (state, getters) => {
      var currentUserId = getters.getCurrentUser.email;
      currentUserId = utilities.isEmpty(currentUserId)
        ? currentUserId
        : currentUserId.toLowerCase();
      if (!utilities.isEmpty(currentUserId)) {
        var employees = getters.getDataObjectsForObjectType("EmployeeMain");
        var employee = employees.find(function (o) {
          return (
            !utilities.isEmpty(o.UserId) &&
            o.UserId.toLowerCase() === currentUserId
          );
        });
        if (!utilities.isEmpty(employee)) {
          return employee.Guid;
        }
      }
      return "";
    },
    getCurrentEmployee: (state, getters) => {
      var currentUserId = getters.getCurrentUser.email;
      currentUserId = utilities.isEmpty(currentUserId)
        ? currentUserId
        : currentUserId.toLowerCase();
      if (!utilities.isEmpty(currentUserId)) {
        var employees = getters.getDataObjectsForObjectType("EmployeeMain");
        var employee = employees.find(function (o) {
          return (
            !utilities.isEmpty(o.UserId) &&
            o.UserId.toLowerCase() === currentUserId
          );
        });
        return employee;
      }
      return "";
    },
    getEmployeeFromGuid: (state, getters) => (id) => {
      if (!utilities.isEmpty(id)) {
        var employees = getters.getDataObjectsForObjectType("EmployeeMain");
        var employee = employees.find(function (o) {
          return !utilities.isEmpty(o.Guid) && o.Guid === id;
        });
        return employee;
      }
      return null;
    },
  },
  actions: {},
};
