/*
    Rest configuration to link the tool with The mongo DB backend.
*/

export const filterOrSortDataObjects = payload => {
  var objects = payload.objects;
  if (payload.objectConfig.objectType === "ViewField") {
    objects.sort(function(a, b) {
      return a.position - b.position;
    });
  }
  return objects;
};
