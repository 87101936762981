<template>
  <v-dialog
    :max-width="getMaxWidth"
    :absolute="true"
    :origin="getCoordinates"
    v-model="getViewShowDialog.show"
    content-class="rounded-lg actionDialog no-scroll"
    transition="scale-transition"
    @change="onChange"
  >
    <v-card
      :max-width="getMaxWidth"
      class="pa-3 no-scroll rounded-lg relative"
    >
      <v-btn
        class="absolute sideBarColor"
        icon
        elevation="0"
        style="right: 20px; top: 20px; z-index: 50"
        @click="closeWindow()"
      ><v-icon>hi-x-mark</v-icon></v-btn>

      <v-row v-if="!isSmallSize">
        <v-col cols="4">
          <div :style="getStyle">
            <viewConfigView
              v-if="getViewShowDialog.show"
              :viewconfig="getViewConfig"
              :object="getObjectForViewDialog"
              :operationid="getOperationIdForViewDialog"
              :editmode="false"
              :dialog="getViewShowDialog"
              :key="componentKey + 4"
            />
          </div>
        </v-col>
        <v-col cols="8">
          <div :style="getStyle">
            <viewConfigView
              v-if="getViewShowDialog.show && isSplitCard"
              :viewconfig="getViewConfigExtedend"
              :object="getObjectForViewDialog"
              :operationid="getOperationIdForViewDialog"
              :editmode="false"
              :dialog="getViewShowDialog"
              :key="componentKey + 3"
            />
          </div>
        </v-col>
      </v-row>

      <v-card
        v-if="isSmallSize"
        flat
      >
        <v-card
          color="primary"
          dark
          flat
        >
          <v-tabs
            v-model="tab"
            background-color="primary"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab key="details"> Details </v-tab>
            <v-tab key="extended"> Extended </v-tab>
          </v-tabs>
        </v-card>



        <v-tabs-items v-model="tab">
          <v-tab-item key="details">
            <div :style="getStyle">
              <viewConfigView
                v-if="getViewShowDialog.show"
                :viewconfig="getViewConfig"
                :object="getObjectForViewDialog"
                :operationid="getOperationIdForViewDialog"
                :editmode="false"
                :dialog="getViewShowDialog"
                :key="componentKey + 2"
              />
            </div>
          </v-tab-item>
          <v-tab-item key="extended">
            <div :style="getStyle">
              <viewConfigView
                v-if="getViewShowDialog.show && isSplitCard"
                :viewconfig="getViewConfigExtedend"
                :object="getObjectForViewDialog"
                :operationid="getOperationIdForViewDialog"
                :editmode="false"
                :dialog="getViewShowDialog"
                :key="componentKey + 1"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { isEmpty, isEmptyObj } from "../../api/utilities";
export default {
  components: {},
  data() {
    return {
      dialog: {
        show: null,
      },
      tab: 0,
      componentKey: 0,
      maxWidth: 800,
    };
  },
  computed: {
    ...mapGetters([
      "getViewConfigForViewDialog",
      "getObjectForViewDialog",
      "getViewShowDialog",
      "getOperationIdForViewDialog",
      "getOriginForViewDialog",
    ]),

    getViewConfig() {
      var splitCard = this.$store.getters.getSplitCardForViewDialog
      if (!isEmptyObj(splitCard)) {
        return splitCard.viewConfig2;
      } else {
        return this.getViewConfigForViewDialog;
      }
    },
    getMaxWidth() {
      if (this.isSplitCard) {
        return 2000;
      }
      return 1000;
    },

    getStyle() {
      var css = "overflow: auto; max-height: 100vh";
      console.log("getStyle: ", css);
      return css;
    },
    getViewConfigExtedend() {
      var splitCard = this.$store.getters.getSplitCardForViewDialog
      if (!isEmptyObj(splitCard)) {
        return splitCard.viewConfig3;
      }
      return "";
    },
    getCoordinates() {
      var origin = this.getOriginForViewDialog;
      if (!isEmpty(origin) && !isEmpty(origin.x) && !isEmpty(origin.y)) {
        var originString =
          origin.x.toString() + "px " + origin.y.toString() + "px";
        console.log("originString", originString);
        return originString;
      }
      return "";
    },
    isSplitCard() {
      return !isEmpty(this.$store.getters.getSplitCardIdForViewDialog);
    },
    isSmallSize() {
      return this.$store.getters.getDeviceSize === 1;
    },
  },
  methods: {
    closeWindow() {
      this.$store.commit("hideViewDialog");
    },
    onChange(value) {
      if (value === false) {
        this.$store.commit("hideViewDialog");
      }
    }
  },
};
</script>
