<template>
  <v-card
    flat
    class="ma-0 pa-0"
  >
    <v-card
      class="pa-3"
      flat
    >
      <vue-dropzone
        v-if="headers.length <= 0"
        id="dropzone"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        class="pa-0 no-border transparent full-height"
        @vdropzone-file-added="onFileAdded"
      >
        <div class="dropzone-custom-content full-height">
          <v-container
            fill-height
            fluid
            class="full-height flex-column align-center justify-center py-8 pb-12 opaque-7"
          >
            <v-icon
              x-large
              color="primary"
              class="opaque-3 mb-4 "
            >hi-document-plus-outline</v-icon>
            <div class="text-body-2 gray--text">Drag your file here</div>
            <div class="text-caption opaque-5 gray--text">Only CSV files are accepted (max 10mb)</div>

          </v-container>
          <div class="on-dropzone-overlay">
            <v-icon
              x-large
              color="primary"
            >
              hi-document-plus
            </v-icon>
          </div>
        </div>

      </vue-dropzone>

      <v-card
        rounded="lg"
        elevation="0"
        class="tileBackgroundColor pa-6"
        v-if="headers.length > 0"
      >
        <div class="pb-4 subtitle-2 font-weight-bold">Keys (used for matching with existing items)</div>
        <v-select
          v-model="keys"
          :items="keyFields"
          attach
          chips
          filled
          solo
          class="text-field-transparent"
          label="Keys"
          multiple
        ></v-select>
        <div class="pb-4 subtitle-2 font-weight-bold">Mapping</div>
        <div class="d-flex align-center gap-1 flex-wrap justify-space-between">

          <div
            v-for="field in  getFields "
            :key="field"
          >
            <div class="d-flex align-center gap-1">
              <div style="min-width: 120px;">{{ field }}</div>
              <div style="min-width: 150px;"> <v-select
                  v-model="mapping[field]"
                  :items="headers"
                  filled
                  solo
                  dense
                  hide-details
                  class="text-field-transparent kingSize"
                  :label="field"
                ></v-select></div>
            </div>
          </div>
        </div>
        <div class="pt-4 d-flex kingSize align-center gap-1">

          <v-btn
            color="primary"
            elevation="0"
            class="px-8"
            :disabled="getError != null"
            @click="calculate"
          >
            Calculate
          </v-btn>
          <div
            v-if="getError"
            class="error--text"
          >
            {{ getError }}
          </div>
          <div
            v-else-if="getWarning"
            class="warning--text"
          >
            {{ getWarning }}
          </div>

        </div>
      </v-card>
      <div class="pt-4 d-flex kingSize align-center">
        <div class="subtitle-2 pl-4">{{ getTitle }}</div>
        <div class="flex1"></div>
        <v-btn
          color="iconButton"
          elevation="0"
          @click="close"
          class="px-8  mr-2"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="px-8 "
          @click="save"
          :disabled="this.elements.length <= 0"
        >
          Save
        </v-btn>

      </div>


    </v-card>

    <v-card
      v-show="hasItems"
      flat
      class="ma-3 pa-3"
    >
      <listView
        :list="elements"
        :viewconfig="getListConfig"
        height="400px"
      />
    </v-card>
  </v-card>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import listView from "../splitCard/ListViewContainer";
import * as UTIL from "../../api/utilities";
import csv from 'csvtojson'

export default {
  components: { vueDropzone: vue2Dropzone, listView },
  props: {
    viewconfig: {
      type: String,
      default: "",
    },
    object: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: "",
    },
    dialog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://swiftio.io/",
        maxFilesize: 20.0,
        addRemoveLinks: true,
        clickable: true,
        previewsContainer: false,
        // autoProcessQueue: false,
        acceptedFiles: '.csv',
        uploadMultiple: false,
        parallelUploads: 1
      },
      csv: [],
      elements: [],
      hasHeaders: true,
      csvConfiguration: {},
      headers: [],
      mapping: {},
      keys: [],
      keyFields: [],
      error: null
    };
  },
  computed: {
    getError() {
      if (!UTIL.isEmptyArray(this.keys)) {
        for (let index = 0; index < this.keys.length; index++) {
          const key = this.keys[index];
          console.log('key', key, this.mapping[key])
          if (UTIL.isEmpty(this.mapping[key])) {
            return `Please select a mapping for key '${key}'`
          }
        }
        return null
      }
    },
    getWarning() {
      if (UTIL.isEmptyArray(this.keys)) {
        return 'Warning: No keys selected, this may result in creating duplicates'
      }
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    currentObject() {
      return this.$store.getters.getCurrentObject;
    },
    hasItems() {
      return this.csv.length > 0;
    },
    getObjectFields() {
      var mapping = this.getCSVConfiguration
      return mapping.mapping
    },
    getMapping() {
      return [{ field: "Email", csv: "EmailAddress" }]
    },
    getFields() {
      var fields = [];
      // return this.getObjectFields
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getObjectType + "FieldsDefault",
          size: 5,
          list: "edit",
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        return field1.position > field2.position;
      });

      console.log("fields", fields);
      var allFields = []
      var fieldValues = fields.map(function (item) {
        const field = item["value"]
        if (!UTIL.isEmpty(field)) {
          allFields.push(field)
        }
        return field;
      });

      console.log("fieldValues", allFields);

      return allFields;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getListConfig() {
      return this.getObjectType + "List"
    },
    getCSVConfiguration() {
      return this.csvConfiguration
    },
    getTitle() {
      if (this.csv.length > 0) {

        return this.elements.length + " out of " + this.csv.length
      }
      return ""
    }
  },
  async created() {
    this.csvConfiguration = await this.setCSVConfiguration()
  },
  methods: {
    onFileAdded(file) {
      const reader = new FileReader();
      var that = this
      reader.onload = function (event) {
        const text = event.target.result;
        that.processData(text);
      };
      reader.readAsText(file);


    },
    async processData(csvData) {
      let needsMapping = true
      if (this.getCSVConfiguration && this.getCSVConfiguration.mapping) {
        this.mapping = this.getCSVConfiguration.mapping
        needsMapping = false
      }
      const json = await csv({ delimiter: 'auto' }).fromString(csvData)
      if (json && json.length > 0) {
        this.csv = json
        this.headers = Object.keys(json[0])
        if (needsMapping) {
          for (let index = 0; index < this.headers.length; index++) {
            const header = this.headers[index];
            const mapped = this.getFields.find(field => {
              if (field && field.toLowerCase() === header.toLowerCase()) {
                return true
              }
              return false
            })
            if (mapped) {
              this.mapping[header] = mapped
            }
          }
        } else {
          let mappingFields = Object.keys(this.mapping)
          for (let index = 0; index < mappingFields.length; index++) {
            const mappingField = this.mapping[mappingFields[index]];
            const mapped = this.headers.find(header => {
              if (mappingField === header) {
                return true
              }
              return false
            })
            if (UTIL.isEmpty(mapped)) {
              this.mapping[mappingFields[index]] = null

            }
          }

        }
        this.keyFields = Object.keys(this.mapping)
        if (this.getCSVConfiguration && this.getCSVConfiguration.key) {
          this.keys = this.getCSVConfiguration.key
        }

        console.log('csvConfiguration', JSON.parse(JSON.stringify(this.getCSVConfiguration)))
        console.log('mapping', JSON.parse(JSON.stringify(this.mapping)))
        console.log("result", json);
        console.log("keys", JSON.parse(JSON.stringify(this.keys)));
      }
    },
    async setCSVConfiguration() {
      var method = "getCSVUploaderConfiguration"
      var object = this.object;
      var objectConfig = this.$store.getters.getObjectConfigForType(this.getViewConfig.objectType);
      var payload = {
        action: method,
        objectConfig: objectConfig,
        object: object,
        viewConfig: this.getViewConfig,
      };
      var result = await this.$store.dispatch("launchActionForObjectAndConfig", payload);
      return result
    },
    mapElement(element) {
      let newElement = {}
      Object.keys(this.mapping).map(key => {
        const mapped = this.mapping[key]
        if (element[mapped]) {
          newElement[key] = element[mapped]
        }
      })
      return newElement
    },
    async calculate() {
      //ObjectConfig
      var csvConfiguration = this.getCSVConfiguration
      var keys = this.keys
      var mapping = this.mapping
      var newElements = [];
      var newElement = {};
      var objectConfig = this.getObjectConfig;
      for (let index = 0; index < this.csv.length; index++) {
        const csvEl = this.csv[index];
        const element = this.mapElement(csvEl)
        console.log("element", element)
        // Get based on reference
        var objects = this.$store.getters.getDataObjectsForObjectType(
          this.getObjectType
        );
        var object = objects.find(function (a) {
          var returnValue = false
          for (var i = 0; i < keys.length; i++) {
            returnValue = true
            var keyField = keys[i]
            if (!UTIL.isSame(a[keyField], element[keyField])) {
              returnValue = false
              return returnValue
            }
          }
          return returnValue

        });

        if (UTIL.isEmpty(object)) {
          // Find if it already exists.
          newElement = {};
          if (csvConfiguration.disableDefaultScripts !== true) {
            newElement = objectConfig.afterCreate(element, null, this.$store);
            newElement = objectConfig.afterModify(element, this.$store);
            var canSave = objectConfig.beforeSave(newElement, this.$store);
          } else {
            // custom actions

            newElement = element

          }
          newElements.push(newElement);
        }
      }
      this.elements = newElements;
    },
    async save() {
      var csvConfiguration = this.getCSVConfiguration
      for (let index = 0; index < this.elements.length; index++) {
        const element = this.elements[index];
        if (!UTIL.isEmpty(csvConfiguration.action)) {
          var payload = {
            action: csvConfiguration.action,
            objectConfig: this.getObjectConfig,
            object: element,
            viewConfig: csvConfiguration.viewconfig,
          };
          await this.$store.dispatch("launchActionForObjectAndConfig", payload);
        } else {
          var payload = {
            newObject: element,
            config: this.getObjectConfig,
          };
          this.$store.dispatch("changeAndSaveObject", payload)
        }
      }
      this.close()
    },
    close() {
      this.dialog.show = false;
    },
  },
};
</script>
