/**
 * Alert Context
 */

import { ALERT_TYPE_INFO } from "./constants";

const initState = {
  title: "",
  showDialog: false,
  viewConfig: null,
  object: null,
  parentType: null,
  filterFunction: null,
  category: null,
  categories: null,
  categoryListCode: null,
  itemObjectType: null,
  filterGuid: null,
  itemField: "Product",
  itemSubjectField: "Subject",
  itemAmountField: "Amount",
};

export default {
  state: initState,
  getters: {
    getItemSelectionShowDialog: (state) => {
      return state.showDialog;
    },
    getItemSelectionViewConfig: (state) => {
      return state.viewConfig;
    },
    getItemSelectionObject: (state) => {
      return state.object;
    },
    getItemSelectionFilter: (state) => {
      return state.filterGuid;
    },
    getItemSelectionParentType: (state) => {
      return state.parentType;
    },
    getItemSelectionItemObjectType: (state) => {
      return state.itemObjectType;
    },
    getItemSelectionCategory: (state) => {
      return state.category;
    },
    getItemSelectionCategories: (state) => {
      return state.categories;
    },
    getItemSelectionCategoryListCode: (state) => {
      return state.categoryListCode;
    },
    getItemSelectionFilterFunction: (state) => {
      return state.filterFunction;
    },
    getItemSelectionSubjectField: (state) => {
      return state.itemSubjectField;
    },
    getItemSelectionItemField: (state) => {
      return state.itemField;
    },
    getItemSelectionObjectType: (state) => {
      return state.itemObjectType;
    },
    getItemSelectionAmountField: (state) => {
      return state.itemAmountField;
    },
  },
  mutations: {
    showItemSelectionDialog(state, payload) {
      const {
        viewConfig,
        object,
        parentGuid,
        parentType,
        itemObjectType,
        filterFunction,
        category,
        filterGuid,
        categories,
        itemSubjectField,
        itemField,
        itemAmountField,
        categoryListCode,
      } = payload;
      state.viewConfig = viewConfig;
      state.object = object;
      state.parentGuid = parentGuid;
      state.parentType = parentType;
      state.itemType = itemObjectType;
      state.filterFunction = filterFunction;
      state.category = category;
      state.filterGuid = filterGuid;
      state.categories = categories;
      state.itemObjectType = itemObjectType;
      state.itemField = itemField;
      state.itemSubjectField = itemSubjectField;
      state.itemAmountField = itemAmountField;
      state.categoryListCode = categoryListCode;
      state.showDialog = true;
    },
    hideItemSelectionDialog(state, payload) {
      state.viewConfig = initState.viewConfig;
      state.object = initState.object;
      state.parentGuid = initState.parentGuid;
      state.parentType = initState.parentType;
      state.itemType = initState.itemType;
      state.filterFunction = initState.filterFunction;
      state.category = initState.category;
      state.filterGuid = initState.filterGuid;
      state.categories = initState.categories;
      state.itemObjectType = initState.itemObjectType;
      state.itemField = initState.itemField;
      state.itemSubjectField = initState.itemSubjectField;
      state.categoryListCode = initState.categoryListCode;
      state.showDialog = false;
    },
  },
  actions: {
    async showItemSelectionDialog({ commit }, payload) {
      commit("showItemSelectionDialog", payload);
    },
  },
};
