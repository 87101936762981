<template>
  <div>
    generlicListCell
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-container v-if="hasSelectionView">
          <h2>Select {{ selectedField.text }}</h2>
          <listSelectionView
            :viewconfig="getViewConfigOfSelection"
            :selectionfunction="selectionFunction"
          />
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="cancelSelection()"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="header.type === 'input' || header.type === 'dateSelect'">
      <label class="normalLabel">
        {{ object[header.value] }}
      </label>
    </div>
    <div v-if="header.type === 'idLink'">
      <label
        class="link primary--text"
        @click="clickField(header, object)"
      >
        {{ object[header.value] }}
      </label>
      <v-btn
        icon
        @click.native="openDialog(header, object)"
      >
        <v-icon>visibility</v-icon>
      </v-btn>
    </div>
    <div v-if="header.type === 'display'">
      <v-text-field
        v-model="object[header.value]"
        :single-line="solo"
        :class="getClass(header)"
        placeholder=""
        readonly
      />
    </div>
    <div v-if="header.type === 'switch'">
      <v-checkbox
        v-model="object[header.value]"
        :readonly="isReadOnly"
        :single-line="solo"
        color="primary"
        placeholder=""
        height="20"
      />
    </div>
    <div v-if="header.type === 'textEdit'">
      <textEditor
        :object="object"
        :header="header"
        :viewconfig="viewconfig"
      />
    </div>
    <div v-if="getTypeOfField(header) === 'link1'">
      <v-text-field
        :value="getValue(header, object)"
        v-model="object[header.value]"
        :readonly="isReadOnly"
        :color="getColor(header)"
        :append-icon="getIcon(header)"
        :single-line="solo"
        :class="getClass(header)"
        placeholder=""
        class="link primary--text noline"
        @click:append="clickField(header, object)"
      />
    </div>
    <div v-if="getTypeOfField(header) === 'image'">
      <v-layout row>
        <v-flex style="margin-top: 8px; margin-right: 4px">
          <div v-if="getImage(header, object) !== null">
            <v-avatar size="32px">
              <v-lazy-image
                :src="getImage(header, object)"
                alt="Avatar"
              />
            </v-avatar>
          </div>
          <div v-if="getImage(header, object) === null">
            <v-icon
              color="menuItemColor"
              class="material-icons-sharp"
            >
              {{ getImageIcon(header, object) }}</v-icon>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div v-if="getTypeOfField(header) === 'link'">
      <v-layout row>
        <v-flex style="margin-top: 8px; margin-right: 4px">
          <v-avatar
            v-if="getImage(header, object) !== null"
            size="36px"
          >
            <v-lazy-image
              :src="getImage(header, object)"
              alt="Avatar"
            />
          </v-avatar>
        </v-flex>
        <v-flex>
          <label
            :class="getClass(header)"
            @click="clickField(header, object)"
          >{{ object[header.value] }}
          </label>
        </v-flex>
      </v-layout>
    </div>
    <div v-if="getTypeOfField(header) === 'valueList'">
      <v-label :class="getClass(header)">{{ object[header.value] }}</v-label>
    </div>
  </div>
</template>

<script>
import demo from "../../demo/Images/demoImages.js";
import * as Constants from "../../store/constants.js";
import listSelectionView from "../splitCard/ListView";
import textEditor from "./textEditor";
import * as UTIL from "../../api/utilities.js";

export default {
  components: { listSelectionView, textEditor },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    header: {
      type: Object,
      default: () => ({})
    },
    object: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: true
    },
    solo: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: "right"
    },
    filterfunction: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      menu: false,
      viewConfigOfSelection: null,
      selectionFunction: null,
      selectedField: null,
      demo
    };
  },
  computed: {
    isReadOnly() {
      return this.readonly;
    },
    getViewConfigOfSelection() {
      return this.viewConfigOfSelection;
    },
    hasSelectionView() {
      return (
        this.viewConfigOfSelection !== undefined &&
        this.viewConfigOfSelection !== null
      );
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    }
  },
  methods: {
    getClass(header) {
      var cssClass = "";
      if (this.align === "right") {
        cssClass = cssClass + " alignRight";
      }
      if (this.isLink(header)) {
        cssClass = cssClass + " link " + "primary--text";
      }
      cssClass = cssClass + " noline";
      return cssClass;
    },
    isLink(header) {
      if (
        header.type === "link" &&
        header.navigationConfigId !== undefined &&
        header.navigationConfigId !== null
      ) {
        return true;
      }
      if (header.type === "idLink" && header.navigationConfigId !== undefined) {
        return true;
      }
      return false;
    },
    clickField: function (field, object) {
      if (!this.isLink(field)) {
        return;
      }
      if (
        this.selectionfunction !== null &&
        this.selectionfunction !== undefined
      ) {
        this.selectionfunction(object);
        return;
      }
      var navigationObject = object;
      if (
        field.relatedObjectType !== undefined &&
        field.navigationId !== null
      ) {
        var navigationId = object[field.value];
        if (field.navigationId !== undefined && field.navigationId !== null) {
          navigationId = object[field.navigationId];
        }
        var payload = {
          objectType: field.relatedObjectType,
          objectId: navigationId
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
      }
      var payload = {
        splitCardId: field.navigationConfigId,
        object: navigationObject
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    openView() { },
    getTypeOfField(field) {
      if (
        field.type === "link" &&
        field.dropdown !== undefined &&
        field.dropdown !== null
      ) {
        return "valueList";
      }
      return field.type;
    },
    getIcon(field) {
      if (field.type === Constants.LINK && this.isReadOnly === false) {
        return "open_in_new";
      }
      return "";
    },
    getImage(field, object) {
      if (
        (field.image !== undefined && field.image !== null) ||
        field.type === "image"
      ) {
        var imageObject = object;
        if (
          field.relatedObjectType !== null &&
          field.relatedObjectType !== undefined
        ) {
          var payload = {
            objectType: field.relatedObjectType,
            objectId: object[field.value]
          };
          var imageObject = this.$store.getters.getDataObjectForIdAndObjectType(
            payload
          );
        }
        if (imageObject !== undefined) {
          //Split
          var image = this.demo[imageObject[field.image]];
          if (image === undefined || image === null) {
            image = null;
          }
          return image;
        }
      }
      return null;
    },
    getImageIcon(field, object) {
      return this.getObjectConfig.objectConfig.icon;
    },
    getItems(header, object) {
      var objects = this.$store.getters.getDataObjectsForObjectType(
        header.relatedObjectType
      );
      var filteredObjects = [];
      if (
        false &&
        header.filterRelatedObject !== null &&
        header.filterRelatedObject !== undefined &&
        header.filterRelatedObject !== ""
      ) {
        var payload = {
          objects: objects,
          filterConfig: { filterFields: header.filterRelatedObject },
          object: object
        };
        filteredObjects = this.$store.getters.getFilteredObjectsForFilterId(
          payload
        );
      } else {
        filteredObjects = objects;
      }

      if (this.filterfunction !== undefined && this.filterfunction !== null) {
        filteredObjects = this.filterfunction(header, object, objects);
      }

      //with filter
      return filteredObjects;
    },
    getValue(field, object) {
      if (
        field.displayRelatedObject !== undefined &&
        field.displayRelatedObject !== "" &&
        field.displayRelatedObject !== null
      ) {
        var payload = {
          objectType: field.relatedObjectType,
          objectId: object[field.value]
        };
        var relatedObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (relatedObject !== undefined) {
          //Split
          return UTIL.splitFields(relatedObject, field.displayRelatedObject);
        } else {
          return "";
        }
      }
      if (field.type === "date") {
        var date = object[field.value];
        return UTIL.jsonDateToString(date);
      }
      return object[field.value];
    },
    getColor(field) {
      if (field.type === Constants.LINK && this.isReadOnly === true) {
        return "primary";
      }
      return "normal";
    },
    getValueListItems(listCode) {
      var list = this.$store.getters.getValueListForId(listCode);
      return list;
    },
    /*
    editField: function(field, object) {
      if (this.isReadOnly !== true) {
        this.openDialog(field, object);
        return;
      }
      var navConfigId = field.navigationId;
      var payload = {
        splitCardId: field.navigationConfigId,
        object: object
      };
      this.$store.dispatch(Constants.SELECT_OBJECT, payload);
    },
    */
    openDialog(field, object) {
      this.viewConfigOfSelection = field.selectionView;
      this.selectedField = field;
      this.selectionFunction = this.objectSelected;
      this.dialog = true;
    },

    objectSelected(selectedObject) {
      this.object[this.selectedField.value] = selectedObject.objectType;
      this.closeDialog();
    },
    cancelSelection() {
      this.closeDialog();
    },
    closeDialog() {
      this.selectedField = null;
      this.viewConfigOfSelection = null;
      this.selectionFunction = null;
      this.dialog = false;
    }
  }
};
</script>
