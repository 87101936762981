import { isEmpty } from "../../api/utilities.js";
import * as ObjectApi from "../../api/objectApi";
import Vue from "vue";
import {
  OBJECT_CONFIG,
  SYNC_GROUP_CORE_REST,
  SYNC_GROUP_CORE_FIELDS,
  SYNC_GROUP_CORE_OBJECTS,
  SYNCHRONISATION,
  OPERATION,
  TRANSPORT_MAIN,
  TRANSPORT_DOMAIN_ITEM,
  TRANSPORT_ITEM,
  CLIENT_ATTRIBUTES,
  ALERT_TYPE_INFO,
  ALERT_TYPE_ERROR
} from "../../store/constants";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  related,
  context,
  options
) => {
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (objectConfig, object, context, options) => {
  var title = "";
  if (!isEmpty(object.objectType)) {
    title = title + object.objectType;
  }
  if (!isEmpty(object.object)) {
    title = title + object.object;
  }
  Vue.set(object, "title", title);
  afterModify(objectConfig, object, null, context, options);
  return object;
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  Vue.set(object, "isActive", true);
  Vue.set(object, "canRead", true);
  Vue.set(object, "canEdit", true);
  Vue.set(object, "canCreate", true);
  Vue.set(object, "canDelete", true);
  return object;
};

/**---------------------------------------------------------------
* Actions
---------------------------------------------------------------*/
export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options
) {
  return true;
}
