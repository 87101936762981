import { isEmpty } from "../api/utilities";

/**
 * PinnedItemContext
 */

export default {
  state: {
    pinnedItems: [
      {
        id: "inboxDialog",
        icon: "hi-envelope-outline",
        name: "Inbox",
        viewConfig: "InboxMains",
        expanded: false,
        splitCard: true,
        isMobile: true
      },
      {
        id: "Dashboard",
        icon: "hi-view-columns-outline",
        name: "Pinned",
        viewConfig: "OrderTFSView",
        expanded: false,
        isMobile: false
      }
    ],
    currentPinnedItem: null,
    showPinnedDialog: false
  },
  mutations: {
    setShowPinnedDialog(state, value) {
      state.showPinnedDialog = value;
    },
    setCurrentPinnedItem(state, value) {
      state.currentPinnedItem = value;
    },
    closeActiveDialog(state) {
      state.currentPinnedItem = null;
    }
  },
  getters: {
    getShowPinnedDialog: state => {
      return state.showPinnedDialog;
    },
    getPinnedItems: state => {
      return state.pinnedItems;
    },
    getCurrentPinnedItem: state => {
      return state.currentPinnedItem;
    }
  },
  actions: {
    showPinnedDialog({ commit }, pinned) {
      if (pinned) {
        commit("setCurrentPinnedItem", pinned);
        commit("setShowPinnedDialog", true);
        const viewConfig = pinned.splitCard ? pinned.viewConfig : null;
        commit("setCurrentDialog", viewConfig);
        // setTimeout(() => {
        //   commit("setCurrentDialog", viewConfig);
        // }, 50);
      }
    },
    hidePinnedDialog({ commit }, pinned) {
      commit("setCurrentDialog", null);
      // commit("setCurrentPinnedItem", null);
      commit("setShowPinnedDialog", false);
    },
    changeToRouteDialog({ commit, getters, dispatch }, payload) {
      console.log("changeToRouteDialog", payload);
      if (isEmpty(payload)) {
        return;
      }
      const pinned = getters.getPinnedItems.find(item => item.id === payload);
      console.log("pinned", pinned);
      if (pinned) {
        dispatch("showPinnedDialog", pinned);
      }
    }
  }
};
