<template>
    <div class="d-flex pa-4 tileBackgroundColor full-height">
        <v-container
            v-if="step === 0"
            fill-height
            fluid
            class="d-flex full-height p-4 gap-2"
        >
            <div class="flex1 max-w-50  d-flex flex-column full-height gap-2">
                <div
                    class="full-height rounded-xl card bg-cover "
                    :style="`background-image: url(${APP.signup.img1})`"
                >

                </div>
                <div class="full-height rounded-xl  px-8 py-6 primary lighten-4 primary--text">
                    <h2 class="text-h4 pt-4 font-weight-medium">{{ $t(APP.signup.info[0].title) }}</h2>
                    <h5 class="text-h5 pt-2">{{ $t(APP.signup.info[0].subTitle) }}</h5>
                    <div class="text-body-1 opaque-7 py-8">{{ $t(APP.signup.info[0].text) }}</div>
                </div>
            </div>
            <div class="flex1 max-w-50 full-height">
                <div
                    class="flex1 full-height rounded-xl card bg-cover"
                    :style="`background-image: url(${APP.signup.img2})`"
                >

                </div>
            </div>

        </v-container>
        <v-container
            v-else-if="step >= 1"
            fill-height
            fluid
        >
            <v-row
                align="center"
                justify="center"
                style="position: relative;"
            >
                <div class="started-img-wrap">
                    <div class="started-img-text d-flex align-center">
                        <v-card
                            class=" elevation-2 d-flex align-center justify-center mr-2"
                            :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                        >
                            <img
                                v-if="!isEmpty(workspace.logo)"
                                :style="{ 'height': size, 'width': size, 'border-radius': '25%' }"
                                :src="workspace.logo"
                                crossorigin="anonymous"
                                aspect-ratio="1"
                            />
                            <div
                                v-else
                                style="{'font-size': size}"
                            >{{ getInitial }}</div>

                        </v-card>
                        <div class="black--text started-workspace">{{ isEmpty(workspace.name) ?
                'Workspace'
                :
                workspace.name }}</div>
                    </div>
                    <img
                        :src="startedImg"
                        height="100%"
                        width="auto"
                        cover
                        class="started-img rounded-xl v-sheet elevation-2"
                    />
                </div>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { isEmpty } from '../../api/utilities';
import { APP, REALTIOR } from '../../api/constants'
import startedImg from "../../assets/swiftio-started.png"


export default {
    name: 'Splash',
    props: {
        step: {
            type: Number,
            default: 0
        },
        workspace: {
            type: Object,
            default: () => ({})
        },

    },
    data() {
        return {
            APP,
            startedImg,
            isEmpty,
            size: '3vh',
            config: {
                [REALTIOR]: {
                    logo: '',
                    name: REALTIOR,
                    terms: '',
                    privacy: '',

                }
            }
        }
    },
    computed: {
        getInitial() {
            if (isEmpty(this.workspace.name)) {
                return 'A'
            }
            return this.workspace.name.slice(0, 1).toUpperCase()
        },
    }
    // Component options go here
}
</script>

<style scoped>
.started-img-wrap {
    height: 70vh;
    position: absolute;
    left: 20%;
}

.started-img-text {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1.5vh;
}

.started-workspace {
    max-width: 9vh;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5vh;

}

.started-img {
    max-height: 100%;
}
</style>