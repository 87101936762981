<template>
  <v-container
    fluid
    color="tileBackgroundColor"
    class="ma-0 pa-4"
  >
    <v-card
      flat
      color="tileBackgroundColor"
      class="ma-3 pa-3"
    >
      <h1>Themes</h1>
    </v-card>
    <v-card>
      <v-row
        no-gutters
        style="flex-wrap: nowrap;"
      >
        <v-col
          v-for="theme in getThemes"
          :key="theme.name"
          cols="1"
          style="min-width: 100px; max-width: 100%;"
          class="flex-grow-1 flex-shrink-0"
        >
          <v-card
            :color="getColorOfTile(theme)"
            class="pa-1 ma-3"
            flat
          >
            <v-card class="ma-0 pa-1">
              <h2>{{ theme.name }}</h2>
              <v-img
                :src="theme.image"
                @click="changeTheme(theme.id)"
              />
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!--<v-card 
      flat
      color="tileBackgroundColor"
      class="ma-3 pa-3">     
      <v-btn @click="changeTheme('blueWhiteHard')">Blue White</v-btn>
      <v-btn @click="changeTheme('pinkWhiteHard')">Pink White</v-btn>
      <v-btn @click="changeTheme('orangeWhiteHard')">Orange White</v-btn>
      <v-btn @click="changeTheme('orangeLite')">OrangeLite</v-btn>
      <v-btn @click="changeTheme('orangeLiteDark')">OrangeLite Dark</v-btn>
      <v-btn @click="changeTheme('blueLiteDark')">BlueLite Dark</v-btn>
      <v-btn @click="changeTheme('blueLiteDarkBlue')">BlueLite DarkBlue</v-btn>
    </v-card>-->
    <v-card
      color="tileBackgroundColor"
      flat
    >
      <viewConfigView
        :editmode="false"
        viewconfig="ExpenseMainListOverviewTab"
      />
    </v-card>
  </v-container>
</template>

<script>
import * as theme from "../../theme/themeDefault";
import blueDark from "../../assets/Themes/BlueDark.png";
import orangeDark from "../../assets/Themes/OrangeBlack.png";
import pinkWhite from "../../assets/Themes/BlueSoft.png";
import pinkDark from "../../assets/Themes/PinkDark.png";
import engieTheme from "../../assets/Themes/EngieTheme.png";

export default {
  //components: { loadingView },
  data() {
    return {
      currentTheme: "blueLiteDarkBlue"
    };
  },
  computed: {
    getThemes() {
      return [
        {
          id: "blueLiteDarkBlue",
          name: "Blue Dark",
          image: blueDark
        },
        {
          id: "orangeLiteDark",
          name: "Orange Dark",
          image: orangeDark
        },
        {
          id: "blueLiteDarkBlue1",
          name: "Blue Soft",
          image: pinkWhite
        },
        {
          id: "engieTheme",
          name: "Engie",
          image: engieTheme
        },
        {
          id: "pinkLiteDark",
          name: "Pink Dark",
          image: pinkDark
        }
      ];
    },
    getTheme() {
      return this.$store.getters.getCurrentThemeId;
    }
  },
  methods: {
    changeTheme(name) {
      this.currentTheme = name;
      this.$store.dispatch("changeTheme", name);
      var newTheme = theme.getThemeColors(name);
      this.$vuetify.theme.themes.light = newTheme.themes.light;
    },
    changeColor() {
      this.$vuetify.theme.themes.light.primary = "#4caf50";
    },
    getColorOfTile(theme) {
      if (theme.id === this.getTheme) {
        return "primary";
        return this.$vuetify.theme.light.primary;
      }
      return "tileBackgroundColor";
      return this.$vuetify.theme.light.tileBackgroundColor;
    }
  }
};
</script>
