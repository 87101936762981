export const translateHTML = htmlString => {
  var tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  var images = tempElement.getElementsByTagName("img");
  for (var i = images.length - 1; i >= 0; i--) {
    images[i].parentNode.removeChild(images[i]);
  }
  var translatedString = tempElement.textContent || tempElement.innerText;
  tempElement = null;
  return translatedString;
};
