<template>
  <v-container
    fluid
    class="pt-2 pb-2"
  >
    <v-layout>
      <v-flex>
        <v-spacer />
      </v-flex>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="1400px"
      >
        <v-card
          class="ma-0 pa-3"
          flat
        >
          <v-card
            class="ma-3 pa-3"
            flat
          >
            <v-text-field
              v-model="filterName"
              :readonly="false"
              label="Filter Name"
            />
          </v-card>
          <v-card
            flat
            class="d-flex align-end"
          >
            <v-card
              flat
              class="ml-auto"
            >
              <v-btn
                color="primary"
                text
                @click="savePopup()"
              >save</v-btn>
              <v-btn
                color="primary"
                text
                @click="closePopup()"
              >Cancel</v-btn>
            </v-card>
          </v-card>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogSettingManager.dialog"
        persistent
        max-width="1400px"
      >
        <v-card
          class="ma-0 pa-3"
          height="800px"
          flat
        >
          <filterSettingsManager
            :dialogcontroller="dialogSettingManager"
            :filter="filter"
          />
        </v-card>
      </v-dialog>

      <v-layout
        row
        class="noFlex"
      >
        <v-btn
          :disabled="isNew"
          color="primary"
          text
          @click="saveClick()"
        >save</v-btn>
        <v-btn
          color="primary"
          text
          @click="saveAsClick()"
        >Save As</v-btn>
        <v-btn
          color="primary"
          text
          @click="resetClick()"
        >Reset</v-btn>
        <v-btn
          icon
          @click="showManager()"
        >
          <v-icon>hi-wrench-screwdriver</v-icon>
        </v-btn>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import * as Constants from "../../store/constants.js";
import * as Utilities from "../../api/utilities";
import filterSettingsManager from "./Filters/FilterSettingsManager";

export default {
  components: { filterSettingsManager },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    filterfields: {
      type: Array,
      default: () => [],
    },
    onClose: {
      type: Function,
      default: () => { () => { } },
    }
  },
  data() {
    return {
      dialog: false,
      filterName: "",
      dialogSettingManager: {
        dialog: false,
      },
    };
  },
  computed: {
    isNew() {
      return this.filter.isNew === 1;
    },
    getFilters() {
      return this.viewconfig;
    },
  },
  methods: {
    showManager() {
      this.dialogSettingManager.dialog = true;
    },

    resetClick() {
      //Reset Click: set to defaults.
      if (this.isNew) {
        this.filterfields = [];
      }
    },
    savePopup() {
      this.saveAsObjects();
      this.dialog = false;
    },
    closePopup() {
      this.dialog = false;
    },
    saveClick() {
      //Should save all the related objects.
      this.saveFilter();
      this.saveFilterFields();
      this.$emit('on-close');
      //Save the filterField configs.
    },
    saveAsClick() {
      //Should present a popup.
      this.filterName = this.filter.title;
      this.dialog = true;

    },
    saveAsObjects() {
      // Nieuwe filter maken
      var newGuid = Utilities.getGuid();
      var newFilter = {
        defaultFilter: "false",
        filterConfigId: newGuid,
        filterFields: newGuid + "Fields",
        title: this.filterName,
        filterQuery: this.filter.filterQuery,
      };
      var filterConfig = this.$store.getters.getObjectConfigForType(
        Constants.FILTER_CONFIG
      );
      this.saveObject(newFilter, filterConfig);

      //Copy the filterFields
      var filterFieldConfig = this.$store.getters.getObjectConfigForType(
        Constants.FILTER_FIELD_CONFIG
      );
      var filterFields = this.filterfields;
      for (var i = 0; i < filterFields.length; i++) {
        var filterField = filterFields[i];
        var newfilterField = Utilities.copyObject(filterField);
        newfilterField.filterFields = newFilter.filterFields;
        this.saveObject(newfilterField, filterFieldConfig);
      }
    },
    saveFilter() {
      //this.filter.isNew === 2;
      var filter = this.filter;
      var filterConfig = this.$store.getters.getObjectConfigForType(
        Constants.FILTER_CONFIG
      );
      this.saveObject(filter, filterConfig);
    },
    saveFilterFields() {
      var filterFields = this.filterfields;
      var filterFieldConfig = this.$store.getters.getObjectConfigForType(
        Constants.FILTER_FIELD_CONFIG
      );
      var filterField = null;
      for (var i = 0; i < filterFields.length; i++) {
        filterField = filterFields[i];
        this.saveObject(filterField, filterFieldConfig);
      }
    },
    saveObject(object, objectConfig) {
      //Example to change something.
      var payloadCurrent = {};
      payloadCurrent.newObject = object;
      payloadCurrent.config = objectConfig;
      this.$store.dispatch("changeAndSaveObject", payloadCurrent);
    },
  },
};
</script>
