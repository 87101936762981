import Vue from "vue";
import {
  OPERATION_STATUS_CANCELLED,
  OPERATION_STATUS_FAILED,
  OPERATION_STATUS_SAVED,
  SAVE_TYPE_LOCAL
} from "../../store/operationContext.js";
import { OPERATION } from "../../store/constants.js";
import { copyObject, isEmpty } from "../../api/utilities.js";

/**---------------------------------------------------------------
* AfterModify
---------------------------------------------------------------*/
export const afterModify = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("AfterModify  in Operation");
  return object;
};

/**---------------------------------------------------------------
* Before Save
---------------------------------------------------------------*/
export const beforeSave = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  //Default name if empty
};

/**---------------------------------------------------------------
* After Create
---------------------------------------------------------------*/
export const afterCreate = (
  objectConfig,
  object,
  relatedObject,
  context,
  options
) => {
  console.log("after create in Operation");

  return object;
};

/*****************************************************************
* Actions
---------------------------------------------------------------*/

export function canLaunchActionForObjectAndConfig(
  action,
  objectConfig,
  object,
  viewConfig,
  context,
  options
) {
  // console.log("Can Launch action for action", object);
  if (isEmpty(object)) {
    return false;
  }
  if (action === "cancelOperation") {
    if (
      object.status !== OPERATION_STATUS_FAILED &&
      object.status !== OPERATION_STATUS_SAVED
    ) {
      if (!isEmpty(options) && options.showErrorMessage === true) {
        context.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: "Operation not in correct Status"
        });
      }
      return false;
    }
  }
  return true;
}

/***
 * completeTask
 */
export const cancelOperation = (
  objectConfig,
  object,
  viewconfig,
  context,
  options
) => {
  if (
    object.status === OPERATION_STATUS_FAILED ||
    object.status === OPERATION_STATUS_SAVED
  ) {
    const oldObject = copyObject(object);
    Vue.set(object, "status", OPERATION_STATUS_CANCELLED);
    var payloadLead = {
      objectType: OPERATION,
      object: object,
      oldObject
    };
    context.dispatch("saveObjectForObjectType", payloadLead);
  }
};
