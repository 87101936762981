/*******************************************************************
  View Generator
*******************************************************************/

import * as Constants from "../store/constants";
import * as Utilities from "./utilities";
import * as ObjectApi from "./objectApi";

/**
 * Method to generate viewsConfigs.
 * @param {*} object
 */
export const splitCardGenerator = (object) => {
  var auth = object.objectConfig.auth;
  var objectType = object.objectType;

  var generated = {};
  generated[Constants.VIEW_CONFIG] = [];
  generated[Constants.SPLIT_CARD] = [];
  generated[Constants.CARD_CONFIG] = [];
  generated[Constants.VIEW_FIELD] = [];
  generated[Constants.TAB_CONFIG] = [];
  generated[Constants.ACTION_CONFIG] = [];
  generated[Constants.MENU_ITEM] = [];
  generated[Constants.FILTER_CONFIG] = [];
  generated[Constants.FILTER_FIELD_CONFIG] = [];
  generated[Constants.FIELD_LIST_CONFIG] = [];
  var viewConfig = objectType.concat("s");

  /**
   * SplitCards
   */
  var splitCard = {
    splitCard: viewConfig,
    objectType: objectType,
    state: 0,
    viewConfig1: objectType.concat("LeftView"),
    viewConfig2: objectType.concat("MiddleView"),
    viewConfig3: objectType.concat("Extended"),
    auth: auth,
  };
  ObjectApi.fillGuidFieldsForObject(splitCard);
  generated[Constants.SPLIT_CARD].push(splitCard);
  /**
   * SplitCards
   */
  var menuItem = {
    viewConfig: splitCard.splitCard,
    title: splitCard.splitCard,
    icon: object.objectConfig.icon,
    component: Constants.SPLIT_CARD,
    menuGroup: "sales",
    auth: auth,
  };
  ObjectApi.fillGuidFieldsForObject(menuItem);
  generated[Constants.MENU_ITEM].push(menuItem);
  /**
   * ViewConfigs
   */
  //Dynamic left
  var viewConfig1 = {
    viewConfig: splitCard.viewConfig1,
    objectType: objectType,
    type: "Dynamic",
    subViewConfig: objectType.concat("List"),
    queryfilter: "",
    auth: auth,
  };
  /**
   * List + Filter
   */
  var viewConfig1a = {
    viewConfig: viewConfig1.subViewConfig,
    objectType: objectType,
    type: "List",
    subViewConfig: objectType.concat("FieldsDefault"),
    queryFilter: objectType.concat("Default"),
    auth: auth,
  };
  var filterConfigAll = {
    filterConfigId: objectType.concat("All"),
    filterFields: objectType.concat("FilterFieldsAll"),
    title: "All ".concat(objectType),
    defaultFilter: true,
    filterQuery: viewConfig1a.queryFilter,
    auth: auth,
  };

  ObjectApi.fillGuidFieldsForObject(filterConfigAll);
  generated[Constants.FILTER_CONFIG].push(filterConfigAll);
  /**
   * Details
   */
  var viewConfig2 = {
    viewConfig: splitCard.viewConfig2,
    objectType: objectType,
    type: "Dynamic",
    subViewConfig: splitCard.viewConfig2.concat("Cards"),
    queryFilter: objectType.concat("Default"),
    auth: auth,
  };
  var viewConfig3 = {
    viewConfig: splitCard.viewConfig3,
    objectType: objectType,
    type: "Tabs",
    subViewConfig: objectType.concat("Extended"),
    queryFilter: objectType.concat("Default"),
    auth: auth,
  };
  var viewConfigDetails = {
    viewConfig: objectType.concat("ViewDetailsconfig"),
    objectType: objectType,
    type: "Details",
    subViewConfig: objectType.concat("FieldsDefault"),
    queryFilter: objectType.concat("Default"),
    auth: auth,
  };

  var viewConfigActions = {
    viewConfig: objectType.concat("ViewActionsview"),
    objectType: objectType,
    type: "Action",
    subViewConfig: objectType.concat("ActionsDefault"),
    queryFilter: objectType.concat("Default"),
    auth: Constants.AUTH_CONFIG_MANIPULATION,
  };

  ObjectApi.fillGuidFieldsForObject(viewConfig1);
  ObjectApi.fillGuidFieldsForObject(viewConfig1a);
  ObjectApi.fillGuidFieldsForObject(viewConfig2);
  ObjectApi.fillGuidFieldsForObject(viewConfig3);
  ObjectApi.fillGuidFieldsForObject(viewConfigDetails);
  ObjectApi.fillGuidFieldsForObject(viewConfigActions);
  generated[Constants.VIEW_CONFIG] = [
    viewConfig1,
    viewConfig1a,
    viewConfig2,
    viewConfig3,
    viewConfigDetails,
    viewConfigActions,
  ];
  /*
   * ViewFields
   */
  generated[Constants.VIEW_FIELD] = viewFieldConfigGenerator(
    object,
    viewConfigDetails.subViewConfig,
    splitCard.splitCard,
  );
  /**
   * CardConfig
   */
  var card1a = {
    viewConfig: viewConfig1.subViewConfig,
    cardConfig: viewConfig1a.viewConfig,
    subViewConfig: viewConfig1a.viewConfig,
    title: viewConfig1a.viewConfig,
    position: 2,
    flex: 12,
    auth: auth,
  };
  var card1 = {
    viewConfig: viewConfig2.subViewConfig,
    cardConfig: viewConfigDetails.viewConfig,
    subViewConfig: viewConfigDetails.viewConfig,
    title: viewConfigDetails.viewConfig,
    position: 1,
    flex: 12,
    auth: auth,
  };

  var card2 = {
    viewConfig: viewConfig2.subViewConfig,
    cardConfig: viewConfigActions.viewConfig,
    subViewConfig: viewConfigActions.viewConfig,
    title: viewConfigActions.viewConfig,
    position: 1,
    flex: 12,
    auth: Constants.AUTH_CONFIG_MANIPULATION,
  };

  ObjectApi.fillGuidFieldsForObject(card1a);
  ObjectApi.fillGuidFieldsForObject(card1);
  ObjectApi.fillGuidFieldsForObject(card2);
  generated[Constants.CARD_CONFIG].push(card1a);
  generated[Constants.CARD_CONFIG].push(card1);
  generated[Constants.CARD_CONFIG].push(card2);

  /**
   * TabsConfig
   */
  var tab1 = {
    viewConfig: viewConfig3.subViewConfig,
    tabConfig: viewConfigDetails.viewConfig,
    title: "Overview",
    subViewConfig: objectType + "OverviewDynamic",
    auth: auth,
  };
  ObjectApi.fillGuidFieldsForObject(tab1);
  generated[Constants.TAB_CONFIG].push(tab1);

  var viewConfigOverViewDynamic = {
    viewConfig: tab1.subViewConfig,
    objectType: objectType,
    type: "Dynamic",
    subViewConfig: tab1.subViewConfig.concat("Cards"),
    auth: auth,
  };
  generated[Constants.VIEW_CONFIG].push(viewConfigOverViewDynamic);

  return generated;
};
/**
 * generate the ViewFields for the default view.
 * @param {} object
 * @param {*} listId
 * @param {*} splitCard
 */
export const viewFieldConfigGenerator = (object, listId, splitCard) => {
  var auth = object.objectConfig.auth;
  var viewField;
  var viewFields = [];
  var position = 0;
  object.fieldsConfig.forEach((field) => {
    position = position + 10;
    viewField = ObjectApi.newObjectWithGuid();
    viewField.auth = auth;
    viewField.position = position;
    viewField.fieldList = listId;
    viewField.size = 4;
    viewField.width = 6;
    viewField.text = field.field;
    viewField.isEdit = true;
    viewField.isDisplay = true;
    viewField.isListEdit = true;
    viewField.isList = true;
    viewField.value = field.field;

    if (
      viewField.value === "syncId" ||
      viewField.value === "Guid" ||
      viewField.value === "_id"
    ) {
      viewField.isEdit = false;
      viewField.isDisplay = false;
      viewField.isListEdit = false;
      viewField.isList = false;
      viewField.size = 5;
    }

    viewField = viewFieldType(field, viewField, object.objectConfig.objectType);

    if (viewField.value === "isDeleted") {
      viewField.type = Constants.SWITCH;
      viewField.size = 5;
      viewField.width = 12;
      viewField.position = 500;
    }

    if (field.field !== "syncId" || Utilities.isTrue(field.isId)) {
      viewFields.push(viewField);
    }
  });
  return viewFields;
};

export const viewFieldType = (field, viewField, objectType) => {
  viewField.type = "input";
  if (field.type === Constants.LONGSTRING) {
    viewField.type = "textEdit";
  }
  if (field.type === Constants.STRING) {
    viewField.type = "input";
    if (Utilities.isTrue(field.isId) || field.field === "Subject") {
      viewField.type = "idLink";
      viewField.navigationId = "Guid";
      viewField.valueRelatedObject = "Guid";
      viewField.relatedObjectType = objectType;
      viewField.navigationConfigId = objectType.concat("s");
      viewField.position = 5;
    }
    if (!Utilities.isEmpty(field.relatedObjectType)) {
      viewField.navigationConfigId = field.relatedObjectType.concat("s");
      viewField.relatedObjectType = field.relatedObjectType;
      viewField.valueRelatedObject = "Guid";
      viewField.navigationId = field.field;
      viewField.type = "link";
      if (
        field.field === "Account" ||
        field.field === "Employee" ||
        field.field === "Contact"
      ) {
        viewField.displayRelatedObject = "FullName";
      } else {
        viewField.displayRelatedObject = "Guid";
      }
    }

    if (!Utilities.isEmpty(field.dropdown)) {
      if (viewField.type !== "link") {
        viewField.type = Constants.VALUE_LIST;
      }
      viewField.dropdown = field.dropdown;
    }

    if (field.field === "Notes") {
      viewField.type = Constants.TEXT_EDIT;
      viewField.width = 12;
      viewField.isListEdit = false;
      viewField.isList = false;
      viewField.position = 490;
    }
    if (field.field === "Phone") {
      viewField.type = Constants.PHONE;
    }
    if (field.field === "Email") {
      viewField.type = Constants.EMAIL;
      viewField.width = 12;
    }
    if (field.field === "Start" || field.field === "End") {
      viewField.type = Constants.DATE_SELECT;
    }
    if (field.field === "Due") {
      viewField.type = Constants.RELATIVE_DATE_CHIP;
    }
    if (field.field === "updatedAt") {
      viewField.type = Constants.RELATIVE_DATE;
      viewField.width = 12;
      viewField.position = 480;
    }
  }
  if (field.type === Constants.BOOLEAN) {
    viewField.type = Constants.SWITCH;
  }
  return viewField;
};
