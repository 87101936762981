<template>
  <div
    ref="outerListContainerCard"
    class="pa-0"
  >
    <div class="pa-1 pt-0">
      <FilterView
        :viewconfig="viewconfig"
        :is-compact-view="true"
        :object="object"
        remove-search
      >
        <template v-slot:actions>

          <v-row
            no-gutters
            justify="center"
            align="center"
          >
            <v-btn-toggle
              v-model="view"
              color="primary"
              class="rounded-lg mx-1"
              borderless
              dense
              @change="selectedView"
            >
              <v-btn
                :value="'week'"
                class=" px-2"
                :class="view == 'week' ? 'sideBarColor' : 'iconButton'"
              >
                <v-icon :class="view == 'week' ? 'primary--text' : 'configLight'">hi-calendar-outline</v-icon>
              </v-btn>

              <v-btn
                :value="'month'"
                class="px-2"
                :class="view == 'month' ? 'sideBarColor' : 'iconButton'"
              >
                <v-icon :class="view == 'month' ? 'primary--text' : 'configLight'">hi-calendar-days-outline</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-btn
              class="iconButton mx-1 flat rounded-lg"
              elevation="0"
              @click="onClickTodayButton"
            >
              Today
            </v-btn>
            <v-btn
              class="iconButton mx-1"
              icon
              @click="onClickPrev()"
            >
              <v-icon
                light
                class="configLight"
              >hi-chevron-left-outline</v-icon>
            </v-btn>
            <v-btn
              class="iconButton mx-1"
              icon
              @click="onClickNext()"
            >
              <v-icon
                light
                class="configLight"
              >hi-chevron-right-outline</v-icon>
            </v-btn>
          </v-row>
        </template>
      </FilterView>
    </div>
    <!-- <v-row
      v-if="isCompactView"
      class="pb-4 px-4 justify-center align-center"
      no-gutters
    >
      <v-btn-toggle
        v-model="view"
        color="primary"
        class="rounded-lg mx-1"
        borderless
        dense
        @change="selectedView"
      >
        <v-btn
          :value="'week'"
          class=" px-2"
          :class="view == 'week' ? 'sideBarColor' : 'iconButton'"
        >
          <v-icon :class="view == 'week' ? 'primary--text' : 'configLight'">hi-calendar-outline</v-icon>
        </v-btn>

        <v-btn
          :value="'month'"
          class="px-2"
          :class="view == 'month' ? 'sideBarColor' : 'iconButton'"
        >
          <v-icon :class="view == 'month' ? 'primary--text' : 'configLight'">hi-calendar-days-outline</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-btn
        class="iconButton mx-1 flat rounded-lg"
        elevation="0"
        @click="onClickTodayButton"
      >
        Today
      </v-btn>
      <v-btn
        class="iconButton mx-1"
        icon
        @click="onClickMoveButton(-1)"
      >
        <v-icon
          light
          class="configLight"
        >hi-chevron-left-outline</v-icon>
      </v-btn>
      <v-btn
        class="iconButton mx-1"
        icon
        @click="onClickMoveButton(1)"
      >
        <v-icon
          light
          class="configLight"
        >hi-chevron-right-outline</v-icon>
      </v-btn>

    </v-row> -->
    <Calendar
      ref="calendar"
      :viewconfig="viewconfig"
      :object="object"
      :height="height"
    />
  </div>
</template>

<script>
import FilterView from "../splitCard/FilterView.vue";
import calendarconfig from "./CalendarConfig.vue";
import Calendar from './Calendar.vue'
import { SMALL_SIZE, SMALL_SIZE_THRESHOLD } from '../../store/constants';



export default {
  components: { calendarconfig, Calendar, FilterView, FilterView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    dark: "true",
    view: 'week',
    clientWidth: 1000
  }),
  computed: {
    isCompactView() {

      return this.clientWidth < SMALL_SIZE_THRESHOLD;
    },
  },
  mounted() {
    console.log('clientwidth', this.$refs.outerListContainerCard && this.$refs.outerListContainerCard.clientWidth)
    this.clientWidth = this.$refs.outerListContainerCard && this.$refs.outerListContainerCard.clientWidth
  },
  methods: {
    onClickPrev() {
      if (this.$refs && this.$refs.calendar && this.$refs.calendar.onClickPrev) {
        this.$refs.calendar.onClickPrev()
      }
    },
    onClickNext() {
      if (this.$refs && this.$refs.calendar && this.$refs.calendar.onClickNext) {
        this.$refs.calendar.onClickNext()
      }
    },
    onClickTodayButton() {
      if (this.$refs && this.$refs.calendar && this.$refs.calendar.onClickTodayButton) {
        this.$refs.calendar.onClickTodayButton()
      }
    },
    selectedView(newView) {
      if (this.$refs && this.$refs.calendar && this.$refs.calendar.selectedView) {
        this.$refs.calendar.selectedView(newView)
      }
    },
  }
};
</script>
