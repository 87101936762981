<template>
  <div class="kingSize full-height">

    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
      class="mb-4 mt-n2 kingSize opaque-2 absolute"
      height="3"
    ></v-progress-linear>

    <v-container
      v-if="!isEmptyArray(getFilteredObjects)"
      class="pb-6 px-8 pt-2"
      fluid
    >


      <v-row>
        <v-col
          v-for="(item, index) in getFilteredObjects"
          :key="item.Guid"
          class="grid-item"
          style="max-width:120px; min-width:120px; position: relative;"
        >


          <v-btn
            v-if="canEdit"
            icon
            small
            class="grid-delete absolute sideBarColor z-index1 top right pointer"
            @click="() => { showDelete(item) }"
          ><v-icon small>hi-x-mark</v-icon></v-btn>
          <GenericInputSimple
            :keyobject="keyObject"
            :object="getItem(item)"
            :header="headerField"
            :viewconfig="viewconfig"
            :height="getRowHeight"
            :showlabel="false"
            :readonly="true"
            :solo="true"
            :dense="true"
            :navigateidlink="true"
            :images="getFilteredObjects"
            :index="index"
          />
          <v-tooltip
            :open-delay="0"
            content-class="rounded-lg elevation-8 sideBarColor"
            bottom
            light
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                v-if="getItem(item)['name']"
                v-bind="attrs"
                v-on="on"
                class="text-caption pt-1"
              >{{ getName(getItem(item)['name']) }}</p>
            </template>
            <span
              v-if="getItem(item)['name']"
              class="text-body-2 primaryText--text"
            >{{ getItem(item)['name'] }}</span>

          </v-tooltip>
        </v-col>
      </v-row>
      <v-dialog
        v-model="deleteDialog"
        max-width="400"
        content-class="rounded-lg"
      >
        <v-card class="py-2 rounded-lg">
          <v-card-title class="text-h5">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-text v-if="itemToDelete">
            {{ getName(getItem(itemToDelete)['name']) }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="deleteDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click="deleteObject"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <slot
      v-else
      name="no-items"
    />
  </div>
</template>

<script>
import CheckListItemTile from "../components/customTiles/CheckListItemTile.vue";
import * as Constants from "../../store/constants.js";
import { isEmpty, isEmptyArray, isTrue } from "../../api/utilities.js";
import GenericInputSimple from "../components/GenericInputSimple.vue";
import { parseJSON } from '../../api/synchronizationApi';
import { mapGetters } from 'vuex';
import { CAN_EDIT } from '../../store/authorizationObjectContext';

export default {
  components: { CheckListItemTile, GenericInputSimple },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: () => []
    },
    headerField: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      keyObject: {
        key: 1
      },
      deleteDialog: false,
      loading: false,
      items: [],
      initLoaded: false,
      lastSaved: null,
      itemToDelete: null
    };
  },
  computed: {
    ...mapGetters(['getIsUpdating']),
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getRowHeight() {
      return 64;
    },
    getObjects() {
      if (!isEmpty(this.list)) {
        return this.list;
      }
      var items = this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
      return items;
    },
    getParentObjectType() {
      if (!isEmpty(this.$store.getters.getObjectTypeForViewDialog)) {
        return this.$store.getters.getObjectTypeForViewDialog;
      }
      return this.$store.getters.getCurrentSplitCardObjectType
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getCurrentFilter() {
      return this.$store.getters.getCurrentFilterForFilterId(this.getViewConfig.queryFilter)
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(this.getObjectType);
    },
    getFilteredObjects() {
      if (this.list && this.list.length > 0) return this.list

      var payload = {
        objects: this.getObjects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      return filteredObject;


    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    canEdit() {
      return !this.readOnly && !this.getOptionsReadOnly && this.canEditFromAuthorisations(CAN_EDIT);
    },

    getOptionsReadOnly() {
      if (this.getOptions && !isEmpty(this.getOptions.readOnly)) {
        return this.getOptions.readOnly
      }
      return false
    },
    getOptionsCanCreate() {
      if (this.getOptions && !isEmpty(this.getOptions.canCreate)) {
        return this.getOptions.canCreate
      }
      return true
    },
    isOnline() {
      return !isEmpty(this.getCurrentFilter) && isTrue(this.getCurrentFilter.online)
    },
  },
  mounted() {
    this.fetchItems()
  },
  watch: {

    getCurrentFilter(value) {
      console.log('getCurrentFilter', value)
      if (this.initLoaded) {
        this.fetchItems()
      }
    },
    getCurrentObject(value) {
      if (value) {
        this.fetchItems()
      }
    },
    getIsUpdating(value) {
      if (this.initLoaded && value === false) {
        this.fetchItems()
      }
    }
  },
  methods: {
    isEmptyArray,
    canEditFromAuthorisations(action) {
      var payload = {
        objectType: this.getViewConfig.objectType,
        action: action,
        viewConfig: this.viewconfig,
        object: this.getCurrentObject
      };
      return this.$store.getters.userHasAuthorisation(payload);
    },
    storeItems(items) {
      if (isTrue(this.isOnline) && !isEmptyArray(items) && this.lastSaved !== JSON.stringify(items)) {
        const payload = {
          objects: items,
          objectConfig: this.getObjectConfig
        }
        this.$store.dispatch('updateDataStore', payload)
        this.lastSaved = JSON.stringify(items)
      }
    },
    getName(name) {
      const maxlength = 14
      if (name) {
        const index = name.lastIndexOf('.')
        if (index && index > maxlength) {
          return name.slice(0, maxlength) + '..' + name.slice(index - 2, name.length)
        } return name
      } return
    },
    showDelete(item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },
    deleteObject() {
      const oldObject = structuredClone(this.itemToDelete)
      this.$set(this.itemToDelete, 'isDeleted', 1)
      this.$store.dispatch("saveObjectForObjectType", {
        object: this.itemToDelete,
        objectType: this.getViewConfig.objectType,
        oldObject: oldObject
      });
      this.deleteDialog = false
      setTimeout(() => {
        this.itemToDelete = null
      }, 500)

    },
    async fetchItems() {
      if (isEmpty(this.getCurrentObject) || isEmpty(this.getCurrentObject.Guid)) {
        return
      }
      if (this.getCurrentFilter && isTrue(this.getCurrentFilter.online)) {
        this.loading = true
        const payload = {
          objectType: this.getObjectType,
          filterGuid: this.getCurrentFilter.Guid,
          relatedGuid: this.getCurrentObject && this.getCurrentObject.Guid,
          relatedType: this.getParentObjectType
        }
        const objects = await this.$store.dispatch('fetchObjectsForFilter', payload)
        this.initLoaded = true
        this.loading = false
        if (!isEmptyArray(objects)) {
          this.items = parseJSON(objects, this.getObjectConfig, this.$store, this.getObjectConfig.syncGroup)
          this.storeItems(this.items)
        }
      }
    },

    getItem(item) {
      // var operationId = this.getOperationIdForItem(item);
      // var operationContainer = this.$store.getters.getOperationContainerForId(
      //   operationId
      // );
      // if (!isEmpty(operationContainer)) {
      //   return operationContainer.newObject;
      // }
      return item;
    },
    getOperationIdForItem(item) {
      var payload = {
        object: item,
        viewconfig: this.viewconfig
      };
      return this.$store.getters.getOperationIdForObjectAndConfig(payload); //todo.
    },
  }
};
</script>

<style scoped>
.grid-delete {
  /* display: none; */
  transform: scale(0);
  transition: transform .3s ease;
  transition-delay: 0.1s;
}

.grid-item:hover .grid-delete {
  display: block;
  transform: scale(1);
}
</style>
