export const insertSQL = (data, tableName) => {
  var sql = "";
  var values = "";
  var fields = "";
  var i;

  var keys = Object.keys(data);
  var len = keys.length;
  var updateData = [];
  for (i = 0; i < len; i++) {
    if (values === "") {
      values += "?";
      fields += keys[i];
    } else {
      values += ", ?";
      fields += "," + keys[i];
    }
    updateData.push(data[keys[i]]);
    // end if then else values = ''
  }
  sql += "INSERT OR IGNORE INTO @tableName ( @fields ) VALUES ( @values )";
  sql = sql.replace("@tableName", tableName);
  sql = sql.replace("@values", values);
  sql = sql.replace("@fields", fields);

  return { sqlInsert: sql, dataInsert: updateData };
};

export const updateSQL = (data, tableName, keyFields) => {
  var sql = "";
  var values = "";
  var i;
  var keys = Object.keys(data);
  var len = keys.length;
  var where = "";
  var and = "";
  var updateData = [];
  len = keys.length;
  for (i = 0; i < len; i++) {
    if (!keyFields.includes(keys[i])) {
      // Here there is no key
      if (values === "") {
        values += keys[i] + " = ?";
      } else {
        values += ", " + keys[i] + " = ?";
      }
      updateData.push(data[keys[i]]);
      // end if then else values = ''
    } // end if indexOf keyFiels
    // end if where != ''
  }
  if (keyFields != null) {
    if (Array.isArray(keyFields)) {
      len = keyFields.length;
      for (i = 0; i < len; i++) {
        where += and + " " + keyFields[i] + " = ?";
        and = " AND";
        updateData.push(data[keyFields[i]]);
      } // end for
    } // end if array
  } // end for each ke// end for each key
  // Prepare update
  sql += "UPDATE OR IGNORE @tableName SET @values WHERE @where";
  sql = sql.replace("@tableName", tableName);
  sql = sql.replace("@values", values);
  sql = sql.replace("@where", where);

  return { sqlUpdate: sql, dataUpdate: updateData };
};
