<template>
  <div class="full-height d-flex flex-column">
    <v-container fluid style="margin-top: -58px">
      <v-row align="center" no-gutters>
        <v-spacer />
        <div class="d-flex gap align-center">
          <v-btn class="iconButton mx-1" icon @click.stop="openDialog">
            <v-icon class="configLight">hi-pencil-square-outline</v-icon>
          </v-btn>
          <v-btn class="sideBarColor" @click.stop="generate" elevation="0">
            Generate
          </v-btn>
        </div>
      </v-row>
    </v-container>

    <html-editor
      v-model="template"
      :dialog="editDialog"
      :onClose="closeDialog"
      :onSave="saveTemplate"
      :data="data"
      :templates="getTemplates"
    />
    <v-container fluid class="flex1 rounded-md">
      <div class="pa-4" v-html="getBody" />

      <!-- <iframe
                v-if="pdfDataUrl"
                :src="`${pdfDataUrl}`"
                height="100%"
                width="100%"
                style="border:none; position: relative; z-index: 1;"
            /> -->
    </v-container>
  </div>
</template>

<script>
import { TEMPLATE } from "../store/constants";
import HtmlEditor from "./HtmlEditor.vue";
import jsPDF from "jspdf";
import {
  formatHtmlData,
  isEmpty,
  isEmptyObj,
  replaceImageSrcWithDataURL,
} from "../api/utilities";
import { mapGetters } from "vuex";
export default {
  components: { HtmlEditor },
  props: {
    viewconfig: {
      type: String,
      default: "",
    },
    object: {
      type: Object,
      default: null, //() => ({})
    },
    operationid: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: "300px",
    },
  },
  data() {
    return {
      body: null,
      editDialog: false,
      template: null,
      data: {},
    };
  },
  computed: {
    ...mapGetters(["getIsUpdating"]),
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getTemplateId() {
      if (this.object && this.object.TemplateId) {
        return this.object.TemplateId;
      }
      if (this.getViewConfig) {
        return this.getViewConfig.subViewConfig;
      }
      return null;
    },
    getLanguage() {
      if (this.object && !isEmpty(this.object.Language)) {
        return this.object.Language;
      }
      return null;
    },
    getTemplate() {
      if (this.object && this.object.TemplateId) {
        return this.object;
      }
      var language = this.getLanguage;
      var templateId = this.getTemplateId;
      console.log("Get templateId");
      if (!isEmpty(language)) {
        var languageTemplateId = templateId + "_" + language;
        var templates = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
        var templateLanguage = templates.find((o) => {
          return o.TemplateId === languageTemplateId;
        });
        if (!isEmptyObj(templateLanguage)) {
          return templateLanguage;
        }
      }
      var templates = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
      return templates.find((o) => {
        return o.TemplateId === templateId;
      });

      return null;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getTemplateObjectType() {
      return this.getTemplate ? this.getTemplate.ObjectType : null;
    },
    getTemplates() {
      const objects = this.$store.getters.getDataObjectsForObjectType(TEMPLATE);
      if (objects) {
        return objects;
      }
      return [];
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log("options", this.getViewConfig.options);
        try {
          const options = JSON.parse(this.getViewConfig.options);
          return options;
        } catch (error) {
          console.error(error);
          return null;
        }
      }
      return null;
    },
    getObject() {
      if (this.object) {
        return this.object;
      } else {
        var template = this.getTemplates.find(
          (item) => item.Guid === this.selectedTemplate,
        );
        if (template) {
          return template.Body;
        }
      }
      return "";
    },
    getBody() {
      const body = this.getTemplate ? this.getTemplate.Body : null;
      if (body) {
        const templates = structuredClone(this.getTemplates);
        console.log("this.data", this.data);
        return formatHtmlData(structuredClone(body), this.data, templates);
      }
      return "";
    },
  },
  mounted() {
    if (this.object) {
      this.updateData();
    }
  },
  watch: {
    object(value) {
      if (!isEmpty(value)) {
        this.updateData();
      }
    },
    getIsUpdating(value) {
      this.updateData();
    },
  },

  methods: {
    isEmpty,
    closeDialog() {
      this.editDialog = false;
      // saveobjec
    },
    async updateData() {
      if (!isEmpty(this.object)) {
        let guid = this.object.Guid;
        if (this.getObjectType === TEMPLATE && this.object.ObjectGuid) {
          guid = this.object.ObjectGuid;
        }
        const object = this.$store.getters.getDataObjectForIdAndObjectType({
          objectId: guid,
          objectType: this.getTemplateObjectType,
        });
        // if (object) {
        var objectConfig = this.$store.getters.getObjectConfigForType(
          this.getObjectType === TEMPLATE
            ? this.getTemplateObjectType
            : this.getObjectType,
        );
        var payload = {
          action: "getTemplateData",
          objectConfig: objectConfig,
          object,
          viewConfig: this.getViewConfig,
          options: {
            templateId: this.getTemplateId,
          },
        };
        var result = await this.$store.dispatch(
          "launchActionForObjectAndConfig",
          payload,
        );
        if (result) {
          this.data = result;
          return;
        } else {
          let readable = null;
          if (!isEmptyObj(object)) {
            readable = this.$store.getters.getReadableObjects(
              this.getTemplateObjectType,
              object,
            );
          }
          this.data = readable || object;
          return;
        }

        // }
      }
      this.data = this.object;
    },
    openDialog() {
      this.template = this.getTemplate ? this.getTemplate.Body : "";
      this.editDialog = true;
    },
    saveTemplate() {
      const oldObject = this.getTemplate;
      const object = structuredClone(oldObject);
      object.Body = this.template;
      const payload = {
        oldObject,
        object,
        objectType: TEMPLATE,
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.editDialog = false;
      // saveobjec
    },

    async generate() {
      if (this.getBody) {
        var that = this;
        var doc = new jsPDF("p", "pt", [793.706, 1122.52]);
        const body = structuredClone(this.getBody);
        const formatted = await replaceImageSrcWithDataURL(body);
        doc.html(formatted, {
          callback: async function (doc) {
            doc.save();
            // const pdfBlob = doc.output('blob');
            // const pdfUrl = URL.createObjectURL(pdfBlob);
            // that.pdfDataUrl = pdfUrl
            // console.log(that.pdfDataUrl)
            // doc.save();
          },
        });
      }
    },
  },
};
</script>

<style scoped></style>
