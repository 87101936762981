<template>
  <v-container
    class="flat pa-0 ma-0"
    flat
    fluid
  >
    <v-sheet :height="height">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        :type="type"
        :events="getEvents"
        @mousedown:event="clickAppointement"
        @click:time="addObject"
      >
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
        <template v-slot:event="{ event }">
          <div class="pa-1">
            <div class="d-flex justify-between">
              <div class="font-weight-bold flex-1 text-ellipsis">{{ event.name }}</div>
              <v-avatar style="min-width:0px!important; width: auto; height: auto; border-radius: 6px;">
                <img
                  v-if="getImage(event.Employee)"
                  :src="getImage(event.Employee)"
                  color="gray"
                  crossorigin="anonymous"
                  style="width:16px!important; height: 16px;"
                  alt="Avatar"
                >

                <div
                  v-else
                  size="16px"
                  class="avatar iconButton text-caption pa-1 rounded-lg font-weight-medium"
                  style="width:16px!important; height: 16px;"
                  color="primary"
                >
                  {{ getInitials(event.Employee.FullName) }}
                </div>
              </v-avatar>
            </div>
            <div class="d-flex justify-between">
              <div>
                {{ event.StartTime }} - {{ event.EndTime }}
              </div>
            </div>
          </div>

        </template>
      </v-calendar>
      <!-- <Calendar
      ref="calendar"
      :view="view"
      :events="getEvents"
      :theme="getCalendarTheme"
      :week="options && options.week"
      :style="`height:${height}`"
      :template="template"
      @afterRenderSchedule="onAfterRenderSchedule"
      @beforeCreateSchedule="onBeforeCreateSchedule"
      @beforeDeleteSchedule="onBeforeDeleteSchedule"
      @beforeUpdateSchedule="onBeforeUpdateSchedule"
      @clickDayname="onClickDayname"
      @clickSchedule="onClickSchedule"
      @clickTimezonesCollapseBtn="onClickTimezonesCollapseBtn"
    /> -->
    </v-sheet>
  </v-container>
</template>

<script>
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { getCurrentTheme } from '../../theme/themeDefault';
import moment from 'moment';
import { CREATE_OPERATION_CONTAINER, SELECT_OBJECT } from '../../store/constants';
import { getGuid, getInitials, isBase64 } from '../../api/utilities';
export default {
  components: { Calendar },
  props: {
    viewconfig: { type: String, default: "" },
    object: { type: Object, default: null },
    height: { type: String, default: "" },
    view: { type: String, default: "week" },
  },
  data() {
    return {
      value: '',
      ready: false,
      canAdd: true,
      type: 'week',
      types: ['month', 'week', 'day'],
      options: {
        defaultView: 'week',
        week: {
          startDayOfWeek: 1,
          taskView: false,
          eventView: ['time']
        }
      },
      template: {
        milestone: function (schedule) {
          return `<span style="color:red;">${schedule.title}</span>`;
        },
        milestoneTitle: function () {
          return 'MILESTONE';
        },
      },

    };
  },
  computed: {
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    currentTheme() {
      return getCurrentTheme(this.$vuetify.theme.isDark)
    },
    getCalendarTheme() {
      return {
        common: {
          // border: this.currentTheme.lineColor,
          backgroundColor: this.currentTheme.card,
          holiday: { color: this.currentTheme.gray },
          saturday: { color: this.currentTheme.gray },
          // dayName: { color: '#333' },
          // today: { color: '#009688' },
          // gridSelection: {
          //   backgroundColor: 'rgba(19, 93, 230, 0.1)',
          //   border: '1px solid #135de6',
          // },
        }
      }
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjects() {
      return this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        console.log('options', this.getViewConfig.options)
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          return null
        }
      }
      return null
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          object: this.currentObject,
          objectConfig: this.getObjectConfig,
          viewConfig: this.viewConfig,
          fieldList: this.getViewConfig.subViewConfig,
          size: 1,
          list: "display" //this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      return fields;
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.object
      };
      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );
      //Todo filter
      console.log('filteredObject', filteredObject)
      return filteredObject;
    },
    getEvents() {
      const events = this.getFilteredObjects.reduce((acc, item) => {
        const object = structuredClone(item)
        let event = this.$store.getters.getReadableObjects(this.getObjectType, object)
        event.object = object
        try {
          var startDateTime = null
          var endDateTime = null
          if (this.options && this.options.startDateTime && this.options.endDateTime) {
            startDateTime = moment(item[this.options.startDateTime])
            endDateTime = moment(item[this.options.endDateTime])
          } else {
            const startDate = this.options && this.options.startDate ? item[this.options.startDate] : item.StartDate
            var startTime = this.options && this.options.startTime ? item[this.options.startTime] : item.StartTime
            const endDate = this.options && this.options.endDate ? item[this.options.endDate] : item.EndDate
            var endTime = this.options && this.options.endTime ? item[this.options.endTime] : item.EndTime
            if (startDate) {
              const sDateTime = new Date(`${startDate} ${startTime || '12:00'}:00`)
              startDateTime = moment(sDateTime)
            }
            if (endDate) {
              const eDateTime = new Date(`${startDate} ${endTime || startTime || '12:15'}:00`)
              endDateTime = moment(eDateTime)
            }
          }
          if (startDateTime) {
            event.start = startDateTime.toDate();
            event.end = (endDateTime && endDateTime.toDate()) || startDateTime.add(30, 'min').toDate();
            event.name = item[this.options.subject] || item.Subject || item.subject || item.Title
            event.timed = true;
            event.employee = item.Employee
            event.guid = item.Guid
            acc.push(event)
          }
          return acc
        } catch (error) {
          return acc
        }
      }, [])
      console.log('events', events)
      return events
    },
    cal() {
      return this.ready ? this.$refs.calendar : null
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },

  },
  mounted() {
    this.ready = true
    this.scrollToTime()
    this.updateTime()
  },
  methods: {
    getInitials,
    getImage(object) {
      if (
        (object)
      ) {
        if (object.thumbnail) {
          if (isBase64(object.thumbnail)) {
            return object.thumbnail
          }
        } else if (object[field.image]) {
          if (isValidHttpUrl(object[field.image])) {
            return object[field.image]
          }
        } else if (object.image) {
          if (isValidHttpUrl(object.image)) {
            return object.image
          }
        }
        else if (object.url) {
          if (isValidHttpUrl(object.url)) {
            return object.url
          }
        }
      }
      return null;
    },
    clickAppointement(event) {
      this.canAdd = false
      console.log('event', event)
      this.$store.dispatch(SELECT_OBJECT, { splitCardId: this.getObjectType + 's', object: event.event.object });
      let that = this
      setTimeout(() => {
        that.canAdd = true
      }, 1000)
    },
    onClickTodayButton() {
      this.value = ''
    },
    onClickPrev(offset) {
      this.$refs.calendar.prev()
    },
    onClickNext(offset) {
      this.$refs.calendar.next()
    },
    selectedView(newView) {
      this.type = newView
    },
    addObject(event) {
      if (!this.canAdd) {
        return
      }
      console.log('eventDate', event)
      var config = this.getObjectConfig;
      // Get the viewConfig
      this.viewConfigOfSelection = this.getViewConfig.objectType.concat(
        "ViewDetailsconfig"
      );

      // Create the operation object
      this.operationId = getGuid();
      var payload = {
        object: null,
        config: config,
        id: this.operationId
      };
      this.$store.dispatch(CREATE_OPERATION_CONTAINER, payload);

      // var retrieve the object from the operation and do the modifications.
      var newObject = this.$store.getters.getOperationContainerForId(
        this.operationId
      ).newObject;

      var relatedObject = {
        object: this.object,
        viewConfig: this.getViewConfig
      };

      var payload = {
        viewconfig: this.viewConfigOfSelection,
        operationId: this.operationId
      };
      this.$store.commit("changeEditMode", payload);
      newObject = config.afterCreate(newObject, relatedObject, this.$store);

      newObject.StartDate = event.date
      newObject.EndDate = event.date
      newObject.StartTime = `${event.hour < 10 ? '0' : ''}${event.hour}:00`
      newObject.EndTime = `${event.hour < 10 ? '0' : ''}${event.hour}:30`

      console.log(newObject)
      /*
        New
      */

      var _this = this

      this.$store.dispatch("showActionDialog", {
        viewConfig: _this.viewConfigOfSelection,
        object: newObject,
        objectType: _this.getViewConfig.objectType,
        origin: origin,
        operationId: this.operationId
      });

      /*
      this.dialogWidth = 800;
      this.hasSelectionView = true;
      this.dialog.show = true;
      this.componentKey += 1;
      */
    },
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime() {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
  }
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
