/**
 * App State
 */
import * as Constants from "../store/constants";

export default {
  state: {},
  mutations: {},
  getters: {
    getActions: (state, getters) => {
      var actions = getters.getDataObjectsForObjectType(
        Constants.ACTION_CONFIG,
      );
      return actions;
    },
    getActionListForActionConfig:
      (state, getters) => (actionConfig, object, objectConfig, viewConfig) => {
        var actions = getters.getActions.filter(function (o) {
          return o.viewConfig === actionConfig;
        });
        var availableActions = [];
        for (var i = 0; i < actions.length; i++) {
          var action = actions[i];
          if (
            objectConfig.canLaunchActionForObjectAndConfig(
              action.method,
              object,
              viewConfig,
              { getters },
              null,
            )
          ) {
            availableActions.push(action);
          }
        }
        availableActions = availableActions.sort(function (action1, action2) {
          if (action2.position > action1.position) {
            return -1;
          }
          if (action1.position > action2.position) {
            return 1;
          }
          return 0;
        });
        return availableActions;
      },
  },
  actions: {
    async actionCreateSplitCardConfigs({ dispatch, commit, getters }, payload) {
      dispatch("createSplitCardConfigs", payload.object.objectType);
    },

    //payload: object
    async launchActionForObjectAndConfig(context, payload) {
      var objectConfig = payload.objectConfig;
      var result = await objectConfig.launchActionForObjectAndConfig(
        payload.action,
        payload.object,
        payload.viewConfig,
        context,
        payload.options,
      );
      return result;
    },

    async sendOperation({ dispatch, commit, getters }, payload) {
      var object = payload.object;
      dispatch("sendOperationContainer", payload);
    },
    async actionSendObjects({ dispatch, commit, getters }, payload) {
      var objectType = payload.object.objectType;
      var objectConfig = getters.getObjectConfigForType(objectType);
      var objects = getters.getDataObjectsForObjectType(objectType);
      for (var j = 0; j < objects.length; j++) {
        var object = objects[j];
        var payloadContainer = {
          object: object,
          config: objectConfig,
        };
        await dispatch(Constants.CREATE_OPERATION_CONTAINER, payloadContainer);
        await dispatch(
          Constants.SAVE_OPERATION_CONTAINER,
          getters.getCurrentOperationContainer,
        );
      }
    },
  },
};
