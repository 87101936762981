export const EMPLOYEE_MAIN = "EmployeeMain";
export const TEAM_TECHNICAL = "T004";
export const TEAM_BACKOFFICE = "T002";

//Employee beforesave
export const beforeSave = (objectConfig, object, context, options) => {
  //To add the image.
  // object.Image = object.FirstName;
  object.FullName = object.FirstName + " " + object.LastName;
  return object;
};
