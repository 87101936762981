<template>
  <div
    style="max-width: 424px;"
    class="flex-column flex1 justify-self-center"
  >
    <div v-if="success === false">
      <v-container>
        <v-btn
          style="text-transform: initial; letter-spacing: normal; font-size: 1rem;"
          text
          elevation="0"
          class="pl-2 "
          @click="onBack"
        ><v-icon left>hi-arrow-left</v-icon>back</v-btn>
        <v-form>
          <div
            class="text-h4 font-weight-medium py-4"
            v-text="'Forgot password?'"
          />
          <div
            class="text-body-2 pb-6"
            v-text="`Don't worry, it happens. Please enter the email associated with the account.`"
          />

          <v-text-field
            v-model="email"
            :rules="[rules.required]"
            prepend-inner-icon="hi-envelope-outline"
            name="email"
            label="email"
            class="text-field-transparent no-border"
            filled
          />
        </v-form>
      </v-container>
      <v-card-actions>
        <v-layout
          class="px-4"
          align-center
          justify-center="true"
          row
          wrap
        >
          <v-btn
            :loading="isLoading"
            color="primary"
            large
            depressed
            block
            @click.native="resetPassword()"
          >Reset Password</v-btn>
        </v-layout>
      </v-card-actions>
    </div>
    <div v-if="success === true">
      <v-container class="pt-6">
        <v-row
          align="center"
          justify="start"
          no-gutters
        >
          <div style="position:relative;">
            <v-icon
              size="60"
              color="primary"
            >hi-envelope</v-icon>
            <v-icon
              style="position:absolute; top: -6px; right: -16px;"
              class="mr"
              size="32"
              color="success"
            >hi-check-circle</v-icon>
          </div>

        </v-row>
        <v-form>

          <div
            class="text-h4 font-weight-medium pb-2"
            v-text="'Email has been sent!'"
          />
          <div
            class="text-body-2 pb-6"
            v-text="`Please check your inbox for a reset password link. If you can't find the email, be sure to check your spam folder.`"
          />


        </v-form>
      </v-container>
      <v-card-actions>
        <v-layout
          class="px-4"
          align-center
          justify-center="true"
          row
          wrap
        >
          <v-btn
            :loading="isLoading"
            color="tileBackgroundColor"
            large
            depressed
            block
            @click.native="onBack"
          >Back to login</v-btn>
        </v-layout>
      </v-card-actions>
    </div>
  </div>
</template>
<script>
import { INVALID_PASSWORD, USER_NOT_FOUND } from '../../store/authorizationContext';
export default {
  props: {
    onBack: {
      type: Function,
      default: null
    },
  },
  data() {
    return {

      email: "",
      success: false,
      isLoading: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters"
      },
    };
  },
  methods: {
    resetPassword() {
      this.isLoading = true;
      this.$store.dispatch('resetPasswordFromEmail', { email: this.email }).then(success => {
        this.success = true;
        this.isLoading = false;
      }, error => {
        console.log(error)
        let errorMessage = `Something went wrong: ${error.code}`;
        if (
          error.code === USER_NOT_FOUND
        ) {
          errorMessage = "User was not found. Please verify the spelling and try again";
        }
        this.$store.commit("showAlert", { type: "error", message: errorMessage });
        this.isLoading = false;
      });
    }
  }
}
</script>
<style lang="">
    
</style>