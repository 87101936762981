<template>
    <v-container fluid>
        <v-row
            align="center"
            class="py-4"
        >
            <v-form
                ref="form"
                class="kingSize"
                v-model="valid"
            >

                <div class="text-h4 font-weight-medium pb-6">{{ $t('almostThere') }}
                </div>
                <div class="text-body-1 gray--text pb-6">{{ $t('finishSettingUpYourAccount')
                    }}
                </div>
                <v-text-field
                    v-model="getTenant.email"
                    :rules="[rules.required]"
                    name="email"
                    label="Email"
                    class="text-field-transparent"
                    filled
                    disabled
                />
                <div class="d-flex gap-1">
                    <v-text-field
                        v-model="user.firstName"
                        :rules="[rules.required]"
                        name="first-name"
                        :label="$t('firstName')"
                        class="text-field-transparent"
                        filled
                    />
                    <v-text-field
                        v-model="user.lastName"
                        :rules="[rules.required]"
                        name="last-name"
                        :label="$t('lastName')"
                        class="text-field-transparent"
                        filled
                    />
                </div>

                <v-text-field
                    v-model="user.password"
                    :append-icon="showPassword ? 'hi-eye-slash' : 'hi-eye'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    label="Password"
                    hint="At least 8 characters"
                    class="text-field-transparent"
                    filled
                    @click:append="showPassword = !showPassword"
                />
                <v-btn
                    :loading="loading"
                    :disabled="!valid"
                    color="primary"
                    large
                    depressed
                    block
                    @click.native="createUser"
                >Create account</v-btn>

            </v-form>

        </v-row>
    </v-container>
</template>

<script>
import logo from '../../assets/logo-swiftio.svg';

import { isEmpty } from '../../api/utilities';
import { BASE_URL } from '../../api/constants';
import axios from 'axios';
import { mapGetters } from 'vuex';


export default {


    data() {
        return {
            logo,
            valid: false,
            showPassword: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length > 3 || "Min 4 characters",
                email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            },
            user: {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            },
            loading: false,
        };
    },
    computed: {
        ...mapGetters(['getTenant',]),

    },
    methods: {
        isEmpty,
        createUser() {
            this.loading = true
            let url = new URL(`${BASE_URL}/tenant/verify`);
            const tenant = this.getTenant.handle
            const data = {
                tenant,
                user: this.user
            }
            axios.put(url, data).then(response => {
                console.log(response)
                this.$store.commit("setTenant", response.data);
                this.$store.dispatch('getAccessTokenToken', { username: response.data.email, password: this.user.password }).then(success => {
                    window.location.href = '/'
                    this.loading = false;
                }, error => {
                    this.loading = false;
                });
            }, (error) => {
                this.loading = false;
            })

        }
    }
};
</script>