<template>
  <v-layout
    fill-height
    white
  >
    <div
      id="someid"
      class="fluid"
    >
      <v-container
        fluid
        white
        class="pb-0"
      >
        <v-flex xs12>
          <v-container
            fluid
            white
            class="pb-0"
          >
            <v-layout
              v-for="i in getNbRows"
              :key="i"
              :class="getClassForLayout(i)"
              row
              full-with
              fill-height
            >
              <v-flex
                v-for="j in getNbElementsForI(i)"
                :key="j"
                :class="classForFlex(i, j)"
              >
                <v-card
                  :class="getClassForElement(getElementAt(i, j))"
                  flat
                  tile
                  @click.stop="selectCard(i, j)"
                >
                  <v-card-media
                    :src="getImageAt(i, j)"
                    :height="getHeight(i, j)"
                    :class="getImageClass()"
                    flat
                    @click.stop="selectCard(i, j)"
                  >
                    <v-flex
                      xs12
                      align-end
                      flexbox
                    >
                      <span class="headline">
                        <h4
                          color="white"
                          class="alignLeftTitle"
                        > {{ getNameAt(i, j) }} </h4>
                      </span>
                    </v-flex>
                  </v-card-media>
                  <v-flex>
                    <v-card
                      :height="getHeightForCard(getElementAt(i, j))"
                      class="elementTile"
                      flat
                    >
                      <v-card-actions :class="getClassForContent(getElementAt(i, j))">
                        <v-btn
                          :class="getClassForContent(getElementAt(i, j))"
                          flat
                          color="orange"
                        >Share</v-btn>
                        <v-btn
                          :class="getClassForContent(getElementAt(i, j))"
                          flat
                          color="orange"
                        >Explore</v-btn>
                      </v-card-actions>
                      <productIphone v-if="showProductDetails" />
                    </v-card>
                  </v-flex>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container
            ref="backgroundView"
            :style="getTranslateStyle()"
            :class="getClassOfBackground"
            fluid
          >
            <v-card style="text-align:right; background-color:black;">
              <v-btn
                :class="getCloseButtonClass"
                round
                small
                @click.stop="closeBackground()"
              >X</v-btn>
            </v-card>
          </v-container>
        </v-flex>
      </v-container>
    </div>
  </v-layout>
</template>

<script>
import productIphone from "./ProductIphone.vue";
import image6 from "../../assets/ProductIphone/iphone7.jpg";
// import backgroundImage from '../../assets/bgImage1920.jpg'

export default {
  components: { productIphone },
  data() {
    return {
      selected: {},
      selectedRow: 0,
      translateValue: 0,
      productDetailVisible: false,
      closeButtonVisible: false,
      elements: [
        {
          name: "Friends",
          image: "https://source.unsplash.com/uAgLGG1WBd4/900x900"
        },
        {
          name: "Iphone X",
          image: image6
        },
        {
          name: "Explore",
          image: "https://source.unsplash.com/7BjmDICVloE/900x900"
        },
        {
          name: "Vast",
          image: "https://source.unsplash.com/WLUHO9A_xik/900x900"
        },
        {
          name: "Playful",
          image: "https://source.unsplash.com/b2-fBVrfx0o/900x900"
        },
        {
          name: "Friends1",
          image: "https://source.unsplash.com/uAgLGG1WBd4/900x900"
        },
        {
          name: "Free3",
          image: "https://source.unsplash.com/Cp-LUHPRpWM/900x900"
        },
        {
          name: "Explore3",
          image: "https://source.unsplash.com/7BjmDICVloE/900x900"
        },
        {
          name: "Vast3",
          image: "https://source.unsplash.com/WLUHO9A_xik/900x900"
        }
      ]
    };
  },
  computed: {
    getSearchedElements() {
      return this.elements;
    },
    getNbItemsPerRow() {
      return 3;
    },
    getNbRows() {
      return Math.ceil(this.getSearchedElements.length / this.getNbItemsPerRow);
    },
    showBackgrond() {
      if (this.selected === undefined) {
        return false;
      }
      return this.selected.name !== undefined;
    },
    hasElementSelected() {
      if (this.selected === undefined) {
        return false;
      }
      return this.selected.name !== undefined;
    },
    getClassOfBackground() {
      if (this.selected.name !== undefined) {
        this.setTranslateValue(0);
        return "details backgroundInAnimation";
      } else {
        this.setTranslateValue(100);
        return "details backgroundOutAnimation";
      }
    },
    getCloseButtonClass() {
      if (this.closeButtonVisible === true) {
        return "closeVisible closeButton";
      } else {
        return "closeInvisible closeButton";
      }
    },
    showProductDetails() {
      return this.closeButtonVisible;
    }
  },
  mounted: function () {
    var backgroundView = this.$refs["backgroundView"];
    if (backgroundView !== undefined) {
      backgroundView.addEventListener(
        "transitionend",
        this.updateBackgroundView
      );
    }
  },
  methods: {
    setTranslateValue(value) {
      this.translateValue = value;
    },
    updateBackgroundView() {
      if (this.hasElementSelected === true) {
        this.closeButtonVisible = true;
      } else {
        this.closeButtonVisible = false;
      }
    },
    selectCard(i, j) {
      var element = this.getElementAt(i, j);
      this.selectedRow = i;
      this.selected = element;
    },
    closeBackground() {
      this.selectedRow = 0;
      this.selected = {};
      this.closeButtonVisible = false;
    },
    getTranslateStyle() {
      var translateValue = this.translateValue;
      return "-webkit-transform: translateX("
        .concat(translateValue)
        .concat("px ; transform: translateY(")
        .concat(translateValue)
        .concat("%; ");
    },
    getClassForLayout(i) {
      if (i < this.selectedRow) {
        return "layoutTransition noHeight";
      }
      return "layoutTransition";
    },
    getClassForElement(element) {
      if (element === this.selected) {
        return "frontElement";
      }
      return "normalElement";
    },
    classForFlex(i, j) {
      var element = this.getElementAt(i, j);
      if (this.hasElementSelected === true) {
        if (element === this.selected) {
          return "xs12 elementTile";
        }
        if (i === this.selectedRow) {
          return "elementTileNoDelay verySmallAndHidden";
        }
      }
      return "xs4 elementTileNoDelay";
    },
    showTile(element) {
      return true;
    },
    getHeightForCard(element) {
      if (this.hasElementSelected === true) {
        if (element === this.selected) {
          return "700px";
        }
        return "0px";
      }
      return "0px";
    },
    getClassForContent(element) {
      if (this.hasElementSelected === true) {
        if (element === this.selected) {
          return "cardVisible";
        }
        return "cardInVisible";
      }
      return "cardInVisible";
    },
    getClassDetailsForContent(element) {
      if (this.hasElementSelected === true) {
        if (element === this.selected) {
          return "detailsCardVisible";
        }
        return "detailsCardInVisible";
      }
      return "detailsCardInVisible";
    },
    getNbElementsForI(i) {
      if (i === this.getNbRows) {
        return this.getSearchedElements.length % this.getNbItemsPerRow;
      }
      return this.getNbItemsPerRow;
    },
    getElementAt(i, j) {
      var newI = i - 1;
      var newJ = j - 1;
      var index = newI * this.getNbItemsPerRow + newJ;
      if (this.getSearchedElements.length > 0) {
        return this.getSearchedElements[index];
      }

      return {};
    },
    getImageAt(i, j) {
      var element = this.getElementAt(i, j);
      if (element !== undefined) {
        return element.image;
      }
    },
    getNameAt(i, j) {
      var element = this.getElementAt(i, j);
      if (element !== undefined) {
        return element.name;
      }
      return "";
    },
    getHeight(i, j) {
      if (i < this.selectedRow) {
        return "0px";
      }
      return "250px";
    },
    getImageClass() {
      return "layoutTransition";
    }
  }
};
</script>

<style>
.details {
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
}

.closeVisible {
  opacity: 1;
  z-index: 6;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.closeButton {
  top: 20px;
  margin-right: 16px;
}

.closeInvisible {
  opacity: 0;
  z-index: 0;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.noHeight {
  height: 0px;
  min-height: 0px;
}

.layoutTransition {
  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
}

.nofull_screen {
  top: 1000px;
}

.fullscreen {
  top: 20px;
}

.frontElement {
  z-index: 4;
}

.normalElement {
  z-index: 0;
  transition-delay: 0.8s;
  -webkit-transition-delay: 0.8s;
  transition: z-index 1s ease-in-out;
  -webkit-transition: z-index 1s ease-in-out;
}

.verySmallAndHidden {
  flex-basis: 0;
  max-width: 0px;
}

.elementTileNoDelay {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
}

.elementTile {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  transition: all 0.8s ease-in-out;
  -webkit-transition: all 0.8s ease-in-out;
}

.elementTileOut {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  transition: all 0.8s ease-in;
  -webkit-transition: all 0.8s ease-in;
}

.backgroundInAnimation {
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  transition: all 0.6s ease-out;
  -webkit-transition: all 0.6s ease-out;
}

.backgroundOutAnimation {
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  transition: all 0.6s ease-in;
  -webkit-transition: all 0.6s ease-in;
}

.cardInVisible {
  opacity: 0;
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.cardVisible {
  opacity: 1;
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

.detailsCardInVisible {
  opacity: 0;
  transition-delay: 1.5s;
  -webkit-transition-delay: 1.5s;
  transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
}

.detailsCardVisible {
  opacity: 1;
  transition-delay: 0.6s;
  -webkit-transition-delay: 0.6s;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}

#someid {
  margin-bottom: 120px;
  padding: 0 10px;
  position: relative;
  -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(255,
        255,
        255,
        0.25) 0%, transparent 55%, transparent 100%);
  height: 530px;
  width: 100%;
}

.alignLeftTitle {
  text-align: left;
  margin-left: 15px;
}

/*
      #someid:before {
        background: -moz-linear-gradient(center top, #FFFFFF, #FFFFFF 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.7)) repeat scroll 0 0 padding-box, -moz-element(#someid) no-repeat scroll 0 -127px content-box rgba(0, 0, 0, 0);
        content: "";
        height: 140px;
        left: 0;
        padding: 1px 0;
        position: absolute;
        top: 277px;
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        width: 360px;
      }
      */
</style>
