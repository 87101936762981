import * as Constants from "../store/constants";
import * as UTIL from "../api/utilities";

/**
 * App State
 */
export const CONFIGRATION_DRAWER_RIGHT = "configurationDrawerRight";

export default {
  state: { configuration: null, configMode: 0, configurationViewConfig: null },

  mutations: {
    setConfiguration(state, configuration) {
      state.configuration = configuration;
    },
    setConfigurationViewConfig(state, configurationViewConfig) {
      state.configurationViewConfig = configurationViewConfig;
    },
    setConfigMode(state, configMode) {
      state.configMode = configMode;
    }
  },
  getters: {
    isConfigMode: (state, getters) => config => {
      // We have 3 types of states: FullConfig: 2, config: 1 and normal 0.
      // If it is a config view we have config 1.
      var result = null;
      if (
        config !== undefined &&
        config !== null &&
        !UTIL.isEmpty(config.objectType)
      ) {
        var payload = {
          objectType: "ObjectConfig",
          objectId: config.objectType
        };
        var coreObject = getters.getDataObjectForIdAndObjectType(payload);
        if (
          UTIL.isEmpty(config.viewConfig) ||
          UTIL.isEmpty(coreObject) || 
          UTIL.isEmpty(coreObject.syncGroup) ||
          !coreObject.syncGroup.includes("SYNC_GROUP_CORE"))
        {
          result = state.configMode > 0;
        } else {
          result = state.configMode > 1;
        }
      }
      if (result === null) {
        result = state.configMode > 1;
      }
      return result;
    },
    getIsSideConfigurationMode() {
      return false;
    },

    getShowConfigDialog(state, getters) {
      return (
        getters.hasConfiguration &&
        state.configMode > 0 &&
        !getters.getIsSideConfigurationMode
      );
    },

    hasConfiguration(state, getters) {
      return !UTIL.isEmpty(getters.getConfigurationViewConfig);
    },
    getConfigurationViewConfig(state, getters) {
      return state.configurationViewConfig;
    },
    getConfiguration(state, getters) {
      return state.configuration;
    },
    getConfigMode(state, getters) {
      return state.configMode;
    },
    /**
     * Right Drawer
     */
    getConfigurationDrawer(state, getters) {
      return getters.getMenuSettingForMenuDrawer(CONFIGRATION_DRAWER_RIGHT);
    }
  },
  actions: {
    closeConfigViewDialog({ dispatch, commit, getters }) {
      commit("setConfiguration", null);
      commit("setConfigurationViewConfig", null);
    },

    /*
    Open and Close the drawers when in configuration Mode in Bi
    */
    openConfigView({ dispatch, commit, getters }) {
      var configDrawer = getters.getConfigurationDrawer;
      var payload = {
        newValue: true,
        object: configDrawer
      };
      dispatch("setValueForDrawer", payload);
    },
    closeConfigView({ dispatch, commit, getters }) {
      var configDrawer = getters.getConfigurationDrawer;
      var payload = {
        newValue: false,
        object: configDrawer
      };
      dispatch("setValueForDrawer", payload);
    },
    /**
     * Toggle different config modes
     */
    toggleConfigMode({ dispatch, commit, getters }) {
      var configMode = getters.getConfigMode;
      if (configMode === 0) {
        //Open Config Mode
        commit("setConfigMode", 1);
        //dispatch("openConfigView");
      } else {
        //Close Config Mode
        commit("setConfigMode", 0);
        commit("setConfiguration", null);
        commit("setConfigurationViewConfig", null);
        //dispatch("closeConfigView");
      }
    },
    toggleConfigModeExpert({ dispatch, commit, getters }) {
      console.log("toggle");
      var configMode = getters.getConfigMode;
      if (configMode !== 2) {
        commit("setConfigMode", 2);
      } else {
        commit("setConfigMode", 1);
      }
    },

    goToConfiguration({ dispatch, commit, getters }, payload) {
      var configurationViewConfig = payload.configurationViewConfig;
      var configuration = payload.configuration;
      commit("setConfiguration", configuration);
      commit("setConfigurationViewConfig", configurationViewConfig);
    },
    exitConfiguration({ dispatch, commit, getters }) {
      commit("setConfiguration", null);
      commit("setConfigurationViewConfig", null);
    }
  }
};
