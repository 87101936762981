<template>
  <v-list-item class="transparent">
    <v-list-item-action>
      <v-checkbox
        :input-value="isTrue(object.ChecklistItemStatus)"
        :readonly="isTrue(object.EvidenceNeeded)"
        @change="saveChecklistItem"
      />
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title
        v-if="object.Subject"
        class="font-weight-medium"
      >{{ object.Subject }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-action-text>
      <v-chip
        v-show="isTrue(object.EvidenceNeeded)"
        :v-if="isTrue(object.EvidenceNeeded)"
        :color="isTrue(object.ChecklistItemStatus) ? 'primary' : 'orange'"
        class="ma-2"
        text-color="white"
      >
        <v-icon v-if="isCompactView">
          {{ isTrue(object.ChecklistItemStatus) ? 'hi-document-check' : 'hi-document-plus' }}
        </v-icon>
        <div v-if="!isCompactView">{{ isTrue(object.ChecklistItemStatus) ? 'Documents Added' : 'Documents required' }}
        </div>
      </v-chip>
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
import { copyObject, isTrue } from '../../../api/utilities';



export default {
  components: {},
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null
    },
    isCompactView: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    isTrue,
    saveChecklistItem(value) {
      const oldObject = copyObject(this.object)
      this.object.ChecklistItemStatus = value
      var payload = {
        objectType: "ChecklistItem",
        object: this.object,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
    }
  }
};
</script>


