<template>
  <v-card 
    flat 
    color="white" 
    class="ma-3 pa-3">
    <vue-dropzone
      id="dropzone"
      ref="myVueDropzone"
      :options="dropzoneOptions"
    />
    <v-btn 
      text 
      color="primary" 
      @click.native="saveFile">Load</v-btn>
  </v-card>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: { vueDropzone: vue2Dropzone },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    editmode: {
      type: Boolean,
      default: undefined
    },
    operationid: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      currentTheme: "blueLiteDarkBlue",
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 10.0,
        headers: { "My-Awesome-Header": "header value" }
      }
    };
  },
  computed: {
    getHeight() {
      return this.height;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: "5",
          list: this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function(a, b) {
        a.position > b.position;
      });
      return fields;
    },
    title() {
      return this.getViewConfig.title;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    currentObject() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.operationid
        );
        return operationContainer.newObject;
      }
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getMode() {
      if (this.isReadOnly) {
        return "display";
      }
      return "edit";
    },
    isReadOnly() {
      return !this.hasCurrentOperation;
    },
    hasTitle() {
      return !UTIL.isEmpty(this.getViewConfig.title);
    },
    getTitle() {
      return this.getViewConfig.title;
    }
  },
  methods: {
    saveFile() {
      var files = this.$refs.myVueDropzone.getAcceptedFiles();
      var file = files[0].dataURL;
      this.currentObject.Image = file;
    },
    uploadFile() {
      var files = this.$refs.myVueDropzone.getAcceptedFiles();
      var file = files[0].dataURL;
      this.currentObject.Image = file;

      //await uploadFolderToBucket('/tmp/aws-s3-test/test_folder/', my_buckt, 'test_folder2');
    }
  }
};
</script>
