// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  setAnalyticsCollectionEnabled
} from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  setPersistence,
  indexedDBLocalPersistence
} from "firebase/auth";
import { TENANT } from "../../api/constants";
export const GOOGLE_API_KEY = "AIzaSyC7VaTP9e_KiN_OHQYa-wtans8FucoZv7g";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: GOOGLE_API_KEY,
  authDomain: "swiftio-io.firebaseapp.com",
  projectId: "swiftio-io",
  messagingSenderId: "639169510919",
  appId: "1:639169510919:web:a3730113107a7a4bd9f4a2",
  measurementId: "G-H20HBBZ8ML"
};

export const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
setPersistence(auth, indexedDBLocalPersistence);
export const provider = new GoogleAuthProvider();

var googleAnalytics = getAnalytics(firebaseApp);
if (TENANT === "swiftio") {
  setAnalyticsCollectionEnabled(googleAnalytics, false);
}

export const analytics = googleAnalytics;

export const firebaseAuth = auth;
