<template>
  <v-card flat>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-card flat>
          <h2 v-if="hasTitle">{{ getTitle }}</h2>
          <v-container
            fluid
            style="width:100%;"
          >
            <!-- <v-skeleton-loader
              v-bind="attrs"
              :loading="loading"
              type="image"
            /> -->

            <img
              v-for="field in getFields"
              :key="getImage(field)"
              :src="getImage(field)"
              crossorigin="anonymous"
              :eager="true"
              contain
              aspect-ratio="1"
              style="width:100%;"
            >

          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import genericDisplay from "../components/GenericDisplay.vue";
import * as UTIL from "../../api/utilities";
import demo from "../../demo/Images/demoImages.js";
import noImage from "../../demo/Images/Images/empty_avatar.jpg";
import { consoleError } from "vuetify/lib/util/console";

export default {
  name: "DetailsImage",
  components: { genericDisplay },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    object: {
      type: Object,
      default: null //() => ({})
    },
    editmode: {
      type: Boolean,
      default: undefined
    },
    operationid: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data() {
    return {
      config: null,
      fields: null,
      keyObject: {
        key: 1
      },
      demo,
      noImage,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      loading: true
    };
  },
  computed: {
    getHeight() {
      return this.height;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: "5",
          list: this.getMode
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (a, b) {
        a.position > b.position;
      });
      return fields;
    },
    title() {
      return this.getViewConfig.title;
    },
    hasCurrentOperation() {
      return this.operationid !== null;
    },
    currentObject() {
      if (this.hasCurrentOperation) {
        var operationContainer = this.$store.getters.getOperationContainerForId(
          this.operationid
        );
        return operationContainer.newObject;
      }
      if (this.object !== null) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getMode() {
      if (this.isReadOnly) {
        return "display";
      }
      return "edit";
    },
    isReadOnly() {
      return !this.hasCurrentOperation;
    },
    hasTitle() {
      return !UTIL.isEmpty(this.getViewConfig.title);
    },
    getTitle() {
      return this.getViewConfig.title;
    }
  },
  methods: {
    calculateKey(field) {
      var key = this.keyObject.key.toString().concat(field.value);
      if (key.includes("undefined")) {
        console.log("wrong key", field);
      }
      return key;
    },
    getImage(field) {
      if (!UTIL.isEmpty(this.currentObject)) {
        if (!UTIL.isEmpty(this.currentObject[field.value])) {
          return this.currentObject[field.value];
        }
        if (!UTIL.isEmpty(this.currentObject['file'])) {
          return this.currentObject['file']
        }
      }
      return "";
    },
    getThumbnail() {
      if (!UTIL.isEmpty(this.currentObject)) {
        if (!UTIL.isEmpty(this.currentObject['thumbnail'])) {
          return this.currentObject['thumbnail'];
        }
      }
      return "";
    },
  }
};
</script>
